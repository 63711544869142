import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// TransportLocationsAdminPage
import TransportLocationsAdminPage from '../TransportLocationsAdminPage/TransportLocationsAdminPage';
import routeTransportLocationsAdminPage from '../TransportLocationsAdminPage/route';

// Consts
import page from './page';

const TransportLocationsAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeTransportLocationsAdminPage()}
        component={TransportLocationsAdminPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default TransportLocationsAdminPages;
