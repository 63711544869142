import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {charcoal} = colors;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overlow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${charcoal};
`;

export default Container;
