import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Text} from 'doorson-ui';

import Container from './components/Container';
import Content from './components/Content';
import LogoContainer from './components/LogoContainer';
import LogoContent from './components/LogoContent';
import DoorsonLogo from './components/DoorsonLogo';
import DoorserviceLogo from './components/DoorserviceLogo';
import Box from './components/Box';
import Page from './components/Page';
import Documentation from './components/Documentation';
import Vehicle from './components/Vehicle';
import DocumentIconContainer from './components/DocumentIconContainer';
import DocumentIcon from './components/DocumentIcon';
import OpenRefresh from './components/OpenRefresh';
import ClosedRefresh from './components/ClosedRefresh';
import Versions from './components/Versions';
import Version from './components/Version';

const Navigation = ({
  production,
  navigationOpen,
  hideMenu,
  page,
  pages,
  documentationLabel,
  documentationUrl,
  licensePlate,
  vehicleLabel,
  apiVersion,
  appVersion,
  refresh,
  onSlide,
  onVehicle,
}) => (
  <Container open={navigationOpen} alternateBackground={!production}>
    <Content visible={navigationOpen}>
      <LogoContainer onClick={onSlide}>
        <LogoContent>
          <DoorsonLogo logo="doorson" />
          <DoorserviceLogo logo="doorservice" />
        </LogoContent>
      </LogoContainer>
      {!hideMenu && (
        <Box>
          {pages.map((p, index) => (
            <Page
              to={p.url}
              key={p.url}
              alternatecolor={!production ? 1 : 0}
              selected={p.highlight.includes(page)}
              last={index + 1 === pages.length ? 1 : 0}
            >
              {p.name}
            </Page>
          ))}
          <Vehicle onClick={onVehicle}>
            {vehicleLabel}{' '}
            <Text color={production ? 'orange' : 'black'}>{licensePlate}</Text>
          </Vehicle>
          <Documentation to={documentationUrl}>
            {documentationLabel}
          </Documentation>
          <OpenRefresh onClick={refresh}>
            <i className="mdi mdi-refresh" />
          </OpenRefresh>
          <Versions alternateColor={!production}>
            <Version>{apiVersion}</Version>
            <Version>{appVersion}</Version>
          </Versions>
        </Box>
      )}
    </Content>
    <Content visible={!navigationOpen} small>
      <LogoContainer small onClick={onSlide}>
        <LogoContent>
          <DoorsonLogo logo="d" nomargin={1} />
        </LogoContent>
      </LogoContainer>
      {!hideMenu && (
        <Box>
          {pages.map((p, index) => (
            <Page
              to={p.url}
              key={p.url}
              selected={p.highlight.includes(page)}
              last={index + 1 === pages.length ? 1 : 0}
              small={1}
            >
              {p.name.charAt(0)}
            </Page>
          ))}
          <ClosedRefresh onClick={refresh}>
            <i className="mdi mdi-refresh" />
          </ClosedRefresh>
          <DocumentIconContainer to={documentationUrl}>
            <DocumentIcon icon="document" />
          </DocumentIconContainer>
        </Box>
      )}
    </Content>
  </Container>
);

Navigation.propTypes = {
  production: PropTypes.bool,
  navigationOpen: PropTypes.bool,
  hideMenu: PropTypes.bool,
  page: PropTypes.string,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      highlight: PropTypes.array.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  documentationLabel: PropTypes.string,
  documentationUrl: PropTypes.string,
  licensePlate: PropTypes.string,
  vehicleLabel: PropTypes.string,
  apiVersion: PropTypes.string,
  appVersion: PropTypes.string,
  refresh: PropTypes.func,
  onSlide: PropTypes.func,
  onVehicle: PropTypes.func,
};

Navigation.defaultProps = {
  pages: [],
};

export default Navigation;
