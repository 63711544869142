import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Components
import Container from './components/Container';
import Header from './components/Header';
import Content from './components/Content';

const ContentLayout = ({scroller, header, children, dispatch}) => (
  <Container>
    <Header>{header}</Header>
    <Content ref={(scroller) => dispatch(setAct({scroller}))}>
      {!!scroller && children}
    </Content>
  </Container>
);

ContentLayout.propTypes = {
  scroller: PropTypes.object,
  header: PropTypes.node,
  children: PropTypes.node,
  dispatch: PropTypes.func,
};

export default connect((state) => ({scroller: state.layout.scroller}))(
  ContentLayout
);
