import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';

import Container from './components/Container';
import Image from './components/Image';
import Actions from './components/Actions';
import Action from './components/Action';

// Libs
import preview from '../../lib/preview.lib.file';

// Types
import {byteSize, date as dateType} from '../../../types';
import Percent from "./components/Percent";

const Attachments = ({
                       deletable,
                       columns,
                       files,
                       sort,
                       updating,
                       onSort,
                       onPreview,
                       onDownload,
                       onRemove,
                     }) => (
  <Container>
    <Table columns={columns} sort={sort} onSort={onSort}>
      {(TableRow) =>
        files.map((file) => (
          <TableRow key={file.id}>
            {(TableCol) => [
              <TableCol key="image">
                <Image
                  type={file.mimeType}
                  src={preview(file.preview)}
                  onClick={onPreview(file.id)}
                />
              </TableCol>,
              <TableCol key="name">{file.name}</TableCol>,
              <TableCol key="size">
                {byteSize(file.sizeBytes).format()}
              </TableCol>,
              <TableCol key="dateModified">
                {dateType(file.dateModified).format()}
              </TableCol>,
              <TableCol key="actions">
                {[...updating].find(i => i.id === file.id) ? (
                  <Actions>
                    <Action className="mdi mdi-loading mdi-spin"><Percent>{[...updating].find(i => i.id === file.id).percent}</Percent></Action>
                  </Actions>
                ) : (
                  <Actions>
                    <Action
                      className="mdi mdi-download"
                      onClick={onDownload(file.id)}
                    />
                    {/* <Action
                      className="mdi mdi-pencil"
                      onClick={onEdit(file.id)}
                    /> */}
                    {deletable && (
                      <Action
                        className="mdi mdi-delete"
                        onClick={onRemove(file.id)}
                      />
                    )}
                  </Actions>
                )}
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
  </Container>
);

Attachments.propTypes = {
  deletable: PropTypes.bool,
  columns: PropTypes.array,
  files: PropTypes.array,
  updating: PropTypes.array,
  sort: PropTypes.object,
  onDownload: PropTypes.func,
  onPreview: PropTypes.func,
  onRemove: PropTypes.func,
  onSort: PropTypes.func,
};

Attachments.defaultProps = {
  deletable: true,
};

export default Attachments;
