import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Content from './components/Content';
import DoorsonLogo from './components/DoorsonLogo';
import DoorserviceLogo from './components/DoorserviceLogo';
import Heading from './components/Heading';
import InputContainer from './components/InputContainer';
// import Select from './components/Select';
import ActionContainer from './components/ActionContainer';
import ButtonContainer from './components/ButtonContainer';

import {Form} from 'doorson-ui';
import {SquareInput as Input} from 'doorson-ui';
import {Button} from 'doorson-ui';

const SignIn = ({
  loading,
  username,
  password,
  keepLoggedIn,
  heading,
  usernameLabel,
  passwordLabel,
  keepLoggedInLabel,
  type,
  types,
  loginLabel,
  onChange,
  onSignIn,
}) => (
  <Container>
    <Content>
      <Form onSubmit={onSignIn}>
        <DoorsonLogo logo="doorson" />
        <DoorserviceLogo logo="doorservice" />
        <Heading>{heading}</Heading>
        <InputContainer>
          {/* <Select
            value={type}
            onChange={(e) => onChange(e.target.value, 'type')}
          >
            {types.map((t) => (
              <option key={t} value={t}>
                {t}
              </option>
            ))}
          </Select> */}
          <Input
            value={username}
            onChange={(val) => onChange(val, 'username')}
            disabled={loading}
            light
          >
            {usernameLabel}
          </Input>
        </InputContainer>
        <InputContainer>
          <Input
            type="password"
            value={password}
            onChange={(val) => onChange(val, 'password')}
            disabled={loading}
            light
          >
            {passwordLabel}
          </Input>
        </InputContainer>
        <ActionContainer>
          <ButtonContainer>
            <Button postIcon="arrow-right" theme="orange" loading={loading}>
              {loginLabel}
            </Button>
          </ButtonContainer>
        </ActionContainer>
      </Form>
    </Content>
  </Container>
);

SignIn.propTypes = {
  loading: PropTypes.bool,
  username: PropTypes.string,
  password: PropTypes.string,
  keepLoggedIn: PropTypes.bool,
  heading: PropTypes.string,
  usernameLabel: PropTypes.string,
  passwordLabel: PropTypes.string,
  loginLabel: PropTypes.string,
  keepLoggedInLabel: PropTypes.string,
  type: PropTypes.string,
  types: PropTypes.array,
  onChange: PropTypes.func,
  onSignIn: PropTypes.func,
};

export default SignIn;
