import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Input} from 'doorson-ui';
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';

const SendContractModal = ({
  title,
  children,
  emailLabel,
  saveLabel,
  visible,
  loading,
  receiver,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal title={title} visible={visible} onClose={onClose} size="small">
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>{children}</Column>
      </Row>
      <Row margin>
        <Column>
          <Input
            type="email"
            value={receiver}
            onChange={onChange}
            disabled={loading}
          >
            {emailLabel}
          </Input>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

SendContractModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  emailLabel: PropTypes.string,
  saveLabel: PropTypes.string,

  visible: PropTypes.bool,
  loading: PropTypes.bool,
  receiver: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default SendContractModal;
