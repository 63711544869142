import {isDate, toDate, format as formatDate} from 'date-fns';

import type from '../type';

const format = (value) => {
  if (!value) return '';
  const dateObj = isDate(value) ? value : toDate(value);
  return formatDate(dateObj, 'HH:mm');
};

const validate = (value) => /^\d{1,2}:{1}\d{2}$/.test(value);

export default type({type: 'date', format, validate});
