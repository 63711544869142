import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import AssignUsersContainer from '../../../user/containers/AssignUsersContainer/AssignUsersContainer';

// TODO: I18n
const InterventionAssignPage = ({match}) => (
  <SubPage page={match.path}>
    <AssignUsersContainer />
  </SubPage>
);

InterventionAssignPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(InterventionAssignPage);
