import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';
import {Info} from 'doorson-ui';
import {AsyncInput} from 'doorson-ui';
import {CheckBox} from 'doorson-ui';
import Loader from './components/Loader';
import NoResults from './components/NoResults';
import Material from './components/Material';

const NewMaterialModal = ({
  title,
  codeLabel,
  nameLabel,
  unitLabel,
  hasGuaranteeLabel,
  saveLabel,
  searchLabel,
  startSearchLabel,
  noResultsLabel,
  visible,
  loading,
  searching,
  editMode,
  materials,
  code,
  name,
  unit,
  search,
  has_guarantee,
  onChange,
  onClose,
  onSave,
  onSearch,
  onBack,
  onMaterial,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="medium">
    {!editMode ? (
      <Fragment>
        <Row margin>
          <Column>
            <AsyncInput value={name} onChange={onSearch} disabled={loading}>
              {searchLabel}
            </AsyncInput>
          </Column>
        </Row>
        {searching && (
          <Loader>
            <i className="mdi mdi-loading mdi-spin" />
          </Loader>
        )}
        {!searching && !materials.length && (
          <NoResults>
            {search.trim().length >= 3 ? noResultsLabel : startSearchLabel}
          </NoResults>
        )}
        {!searching &&
          search.trim().length >= 3 &&
          !!materials.length &&
          materials.map((material, index) => (
            <Material
              key={`${material.material.code}${index}`}
              imported={material.imported}
              onClick={onMaterial(material)}
            >
              {material.material.code} {material.material.name}{' '}
              {material.material.unit}
            </Material>
          ))}
      </Fragment>
    ) : (
      <Form onSubmit={onSave}>
        <Row margin>
          <Column>
            <Input value={name} onChange={onChange('name')} disabled={loading}>
              {nameLabel}
            </Input>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <Info label={codeLabel}>{code}</Info>
          </Column>
          <Column size={1 / 2}>
            <Info label={unitLabel}>{unit}</Info>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <CheckBox
              selected={has_guarantee}
              onSelect={onChange('has_guarantee')}
              disabled={loading}
            >
              {hasGuaranteeLabel}
            </CheckBox>
          </Column>
        </Row>
        <Row>
          <Column>
            <Button loading={loading}>{saveLabel}</Button>
          </Column>
        </Row>
      </Form>
    )}
  </Modal>
);

NewMaterialModal.propTypes = {
  title: PropTypes.string,
  codeLabel: PropTypes.string,
  nameLabel: PropTypes.string,
  unitLabel: PropTypes.string,
  hasGuaranteeLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  searchLabel: PropTypes.string,
  startSearchLabel: PropTypes.string,
  noResultsLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  searching: PropTypes.bool,
  editMode: PropTypes.bool,
  materials: PropTypes.array,
  code: PropTypes.string,
  name: PropTypes.string,
  unit: PropTypes.string,
  search: PropTypes.string,
  hasGuarantee: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onSearch: PropTypes.func,
  onBack: PropTypes.func,
  onMaterial: PropTypes.func,
};

export default NewMaterialModal;
