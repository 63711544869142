import getTodaysDriverByVehicle from "../../vehicle/lib/getTodaysDriversByVehicle.lib.vehicle";

const getWarehouseName = ({warehouse, vehicles = []}) => {
  if (!warehouse) return;
  const vehicle = [...vehicles].find(v => v.warehouseId === warehouse.id);
  const rental = vehicle && getTodaysDriverByVehicle({vehicle});
  const driverLabel = !!rental ? `${rental.commuter.firstName} ${rental.commuter.lastName} driving ` : '';

  return warehouse.name + (!!vehicle ? ` - ${driverLabel}${vehicle.licensePlate}` : `${warehouse.address ? ' - ' + warehouse.address : ''}`);
}
export default getWarehouseName;
