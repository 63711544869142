import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import getInterventionByIDApi from '../../api/getByID.api.intervention';

// Alertify
import {alertify} from 'doorson-ui';

// Actions
import {set as setAct} from '../../redux/actions';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import InterventionLoader from '../../components/InterventionLoader/InterventionLoader';
import InterventionsHeader from '../../components/InterventionsHeader/InterventionsHeader';
import InterventionInfo from '../../components/InterventionInfo/InterventionInfo';
import InterventionTabs from '../../components/InterventionTabs/InterventionTabs';

// Libs
import getName from '../../../customer/lib/getName.lib.customer';
import isWorklogComplete from '../../../interventionDoor/lib/isWorklogComplete.lib.interventionDoor';
import isResolved from '../../lib/isResolved.lib.intervention';
import status from '../../lib/status.lib.intervention';

// Types
import {date as dateType} from '../../../types';

// Routes
import interventionsRoute from '../../pages/InterventionsPage/route';
import customerRoute from '../../pages/InterventionCustomerPage/route';
import doorRoute from '../../pages/InterventionDoorPage/route';
import assignRoute from '../../pages/InterventionAssignPage/route';
import workLogRoute from '../../pages/InterventionWorkLogPage/route';
import confirmationRoute from '../../pages/InterventionConfirmationPage/route';
import attachmentsRoute from '../../pages/InterventionAttachmentsPage/route';
import actionsRoute from '../../pages/InterventionActionsPage/route';

// Roles
import {
  CHIEF_SERVICE_TECHNICIAN,
  SERVICE_TECHNICIAN,
  SYSTEM,
} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

// Helpers
const routes = {
  [customerRoute()]: customerRoute,
  [doorRoute()]: doorRoute,
  [assignRoute()]: assignRoute,
  [workLogRoute()]: workLogRoute,
  [confirmationRoute()]: confirmationRoute,
  [attachmentsRoute()]: attachmentsRoute,
  [actionsRoute()]: actionsRoute,
};

class InterventionContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    interventionID: PropTypes.string,
    page: PropTypes.string,
    history: PropTypes.object,
    intervention: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.mounted = true;
    this.getIntervention();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getIntervention = async () => {
    const {interventionID, history, dispatch} = this.props;
    // TODO: I18N
    try {
      const intervention = await getInterventionByIDApi(interventionID);
      if (!this.mounted) return;
      // TODO: Check if closed
      dispatch(setAct({intervention}));
      this.setState({loading: false});
    } catch (error) {
      if (!this.mounted) return;
      dispatch(setAct({intervention: null}));
      alertify.error('Intervention does not exist');
      history.replace(interventionsRoute());
    }
  };

  tabs = () => {
    // TODO: I18N
    const {intervention, user} = this.props;
    return [
      {
        id: customerRoute(),
        label: 'Customer Info',
        done: true,
      },
      {
        id: doorRoute(),
        label: 'Door Info',
        done: true,
      },
      {
        id: assignRoute(),
        label: 'Assign',
        done: !!intervention.assignees.length,
      },
      {
        id: workLogRoute(),
        label: 'Work Log',
        done: isWorklogComplete(intervention),
      },
      {
        id: confirmationRoute(),
        label: 'Final Confirmation',
        done: isResolved(intervention),
      },
      {
        id: attachmentsRoute(),
        label: 'Attachments',
        done: false,
        icon: 'paperclip',
      },
      hasPermission({
        user,
        roles: [CHIEF_SERVICE_TECHNICIAN, SERVICE_TECHNICIAN, SYSTEM],
      }) && {
        id: actionsRoute(),
        // label: 'Other',
        done: false,
        icon: 'dots-horizontal',
      },
    ].filter((tab) => !!tab);
  };

  onTab = (tab) => {
    // TODO: I18n
    const {history, intervention} = this.props;
    const {loading} = this.state;
    if (loading) return;
    if (tab === workLogRoute() && !intervention.assignees.length) {
      alertify.info('Assign somebody to this intervention first');
      return history.replace(assignRoute(intervention.id));
    }
    if (tab === confirmationRoute() && !isWorklogComplete(intervention)) {
      alertify.info('Complete the worklog first');
      return history.replace(workLogRoute(intervention.id));
    }
    history.push(routes[tab](intervention.id));
  };

  renderHeader = () => (
    <Fragment>
      <InterventionsHeader>
        {getName(this.props.intervention.customer)}
      </InterventionsHeader>
      <InterventionTabs
        tab={this.props.page}
        tabs={this.tabs()}
        onTab={this.onTab}
      />
    </Fragment>
  );

  render() {
    const {children, intervention} = this.props;
    const {loading} = this.state;
    return loading || !intervention ? (
      <InterventionLoader />
    ) : (
      <Fragment>
        <ContentLayout header={this.renderHeader()}>
          <InterventionInfo
            incidentStatusLabel="Incident status"
            incidentCodeLabel="Incident code"
            invoiceNumberLabel="Invoice number"
            incidentCreatedLabel="Incident created"
            workIssuedLabel="Work order issued by"
            incidentResolvedLabel="Incident resolved by"
            status={status(intervention)}
            code={intervention.code}
            invoiceNumber={intervention.invoiceNumber || '/'}
            created={dateType(intervention.dc).format()}
            issuedBy={
              !!intervention.createdBy
                ? `${intervention.createdBy.firstName} ${intervention.createdBy.lastName}`
                : ''
            }
            resolvedBy={
              !!intervention.resolvedBy
                ? `${intervention.resolvedBy.firstName} ${intervention.resolvedBy.lastName}`
                : ''
            }
          />
          {children}
        </ContentLayout>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  page: state.layout.subpage,
  intervention: state.intervention.intervention,
  user: state.auth.user,
}))(withRouter(InterventionContainer));
