import styled from 'styled-components';

const Container = styled.div`
  cursor: pointer;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Container;
