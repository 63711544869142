import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 15px;
  z-index: 5;
`;

export default Container;
