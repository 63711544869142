import React, {Component} from 'react';
import {connect} from 'react-redux';

// Api
import listInterventionsApi from '../../../intervention/api/list.api.intervention';
import listMaintenancesApi from '../../../maintenance/api/list.api.maintenance';
import listDevicesApi from '../../../device/api/list.api.device';

// Constants
import googleMapsApiKey from '../../constants/googleApiKey.const.maps';

// Components
import Maps from '../../components/Maps/Maps';
import MapLoader from '../../components/MapLoader/MapLoader';
import MapLegend from '../../components/MapLegend/MapLegend';
import DisplaySelection from '../../components/DisplaySelection/DisplaySelection';
import Marker from '../../components/Marker/Marker';
import VehicleMarker from '../../components/VehicleMarker/VehicleMarker';

// Containers
import InterventionQuickViewContainer from '../../../intervention/containers/InterventionQuickViewContainer/InterventionQuickViewContainer';
import MaintenanceQuickViewContainer from '../../../maintenance/containers/MaintenanceQuickViewContainer/MaintenanceQuickViewContainer';
import DeviceQuickViewContainer from '../../../device/containers/DeviceQuickViewContainer/DeviceQuickViewContainer';

// Libs
import getTodaysVehicleByDriver from '../../../vehicle/lib/getTodaysVehicleByDriver.lib.vehicle';
import deviceLabel from '../../../device/lib/deviceLabel.lib.device';

// Style
import styles from '../../mapStyles/wy';

class MapsContainer extends Component {
  static MAP_PROPS = {
    center: [46.1464904, 14.8257981],
    zoom: 8.7,
  };

  state = {
    loading: true,
    interventions: [],
    maintenances: [],
    devices: [],
    display: ['interventions', 'maintenances', 'devices'],
    selected: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiID = null;

  init = async () => {
    await Promise.all([
      this.getInterventions(),
      this.getMaintenances(),
      this.getDevices(),
    ]);
    if (!this.mounted) return;
    this.setState({loading: false});
  };

  getInterventions = async () => {
    try {
      const interventions = await listInterventionsApi({
        filter: 'dateResolved:ISNULL',
        rel: ['customer', 'customerBranch'],
        offset: 0,
        limit: 30
      });
      if (!this.mounted) return;
      this.setState({
        interventions: [...interventions]
          .filter(this.filterObject)
          .map(this.brandObject('interventions')),
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({interventions: []});
    }
  };

  getMaintenances = async () => {
    try {
      const maintenances = await listMaintenancesApi({
        filter: 'dateResolved:ISNULL',
        rel: ['customer', 'customerBranch'],
      });
      if (!this.mounted) return;
      this.setState({
        maintenances: [...maintenances]
          .filter(this.filterObject)
          .map(this.brandObject('maintenances')),
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({maintenances: []});
    }
  };

  getDevices = async () => {
    const {vehicles} = this.props;
    try {
      const rawDevices = await listDevicesApi();
      if (!this.mounted) return;
      const filteredDevices = [...rawDevices]
        .filter(
          ({lastDeviceLocation, lastUser}) =>
            !!lastUser &&
            !!lastUser.id &&
            !!lastDeviceLocation &&
            !!lastDeviceLocation.longitude &&
            !!lastDeviceLocation.latitude
        )
        .map(this.brandObject('devices'));
      const devices = [...filteredDevices].map((device) => ({
        ...device,
        vehicle: getTodaysVehicleByDriver({
          vehicles,
          driverId: device.lastUser.id,
        }),
      }));
      this.setState({devices});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({devices: []});
    }
  };

  brandObject = (type) => (object) => ({...object, objectType: type});

  filterObject = ({customerBranch}) =>
    !!customerBranch &&
    !!customerBranch.branchLocation &&
    !!customerBranch.branchLocation.geo &&
    !!customerBranch.branchLocation.geo.longitude &&
    !!customerBranch.branchLocation.geo.latitude;

  getMarkers = () => {
    const {display, interventions, maintenances, devices} = this.state;
    return [
      display.includes('devices') ? [...devices] : [],
      display.includes('interventions') ? [...interventions] : [],
      display.includes('maintenances') ? [...maintenances] : [],
    ].flat();
  };

  priorityColor = ({priority = 0} = {}) => {
    const p = this.priorities().find(({key}) => key === priority);
    return !!p ? p.color : 'red';
  };

  // TODO: I18n
  priorities = () => [
    {
      key: 3,
      color: 'orange',
      label: 'High',
    },
    {
      key: 2,
      color: 'blue',
      label: 'Medium',
    },
    {
      key: 1,
      color: 'green',
      label: 'Low',
    },
  ];

  // TODO: I18n
  displayOptions = () => [
    {
      value: 'interventions',
      label: 'Interventions',
    },
    {
      value: 'maintenances',
      label: 'Maintenances',
    },
    {
      value: 'devices',
      label: 'Technicians',
    },
  ];

  onDisplay = (display) => () => {
    const newDisplay = this.state.display.includes(display)
      ? [...this.state.display].filter((d) => d !== display)
      : [...this.state.display, display];
    this.setState({display: newDisplay});
  };

  onSelect = (key) => {
    const [type, id] = key.split('::');
    if (!type || !id) return;
    const select = [...this.state[type]].find((obj) => obj.id === id);
    if (!select) return;
    this.setState({
      selected: {
        ...select,
        objectKey: type,
      },
    });
  };

  onPreviewClose = (type) => (object) => {
    object.objectType = type;
    const objects = [...this.state[type]].map((o) =>
      o.id === object.id ? object : o
    );
    this.setState({[type]: objects, selected: null});
  };

  renderMarkers = () =>
    this.getMarkers().map((incident) =>
      incident.objectType === 'devices' ? (
        <VehicleMarker
          key={`${incident.objectType}::${incident.id}`}
          obsolete={incident.lastSeenObsolete}
          selected={
            !!this.state.selected &&
            this.state.selected.objectType === incident.objectType &&
            this.state.selected.id === incident.id
          }
          lat={incident.lastDeviceLocation.latitude}
          lng={incident.lastDeviceLocation.longitude}
        >
          {deviceLabel(incident)}
        </VehicleMarker>
      ) : (
        <Marker
          key={`${incident.objectType}::${incident.id}`}
          color={this.priorityColor(incident)}
          selected={
            !!this.state.selected &&
            this.state.selected.objectType === incident.objectType &&
            this.state.selected.id === incident.id
          }
          lat={incident.customerBranch.branchLocation.geo.latitude}
          lng={incident.customerBranch.branchLocation.geo.longitude}
        />
      )
    );

  render() {
    const {loading, display, selected} = this.state;
    return (
      <Maps
        {...this.constructor.MAP_PROPS}
        apiKey={googleMapsApiKey()}
        styles={styles}
        markers={this.renderMarkers()}
        onClick={this.onSelect}
      >
        <InterventionQuickViewContainer
          visible={!!selected && selected.objectType === 'interventions'}
          intervention={
            !!selected && selected.objectType === 'interventions'
              ? selected
              : null
          }
          onClose={this.onPreviewClose('interventions')}
        />
        <MaintenanceQuickViewContainer
          visible={!!selected && selected.objectType === 'maintenances'}
          maintenance={
            !!selected && selected.objectType === 'maintenances'
              ? selected
              : null
          }
          onClose={this.onPreviewClose('maintenances')}
        />
        <DeviceQuickViewContainer
          visible={!!selected && selected.objectType === 'devices'}
          device={
            !!selected && selected.objectType === 'devices' ? selected : null
          }
          onClose={this.onPreviewClose('devices')}
        />
        <MapLoader visible={loading} />
        <DisplaySelection
          visible={!loading}
          display={display}
          onChange={this.onDisplay}
          options={this.displayOptions()}
        />
        <MapLegend priorities={this.priorities()} priorityLabel="Priority" />
      </Maps>
    );
  }
}

export default connect((state) => ({vehicles: state.vehicle.vehicles}))(
  MapsContainer
);
