import styled from 'styled-components';

const DescriptionContainer = styled.div`
  position: relative;
  float: left;
  width: 180px;
  font-size: 16px;
  user-select: none;
`;

export default DescriptionContainer;
