import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Api
import updateImageApi from '../../api/updateImage.api.documentation';

// Alerts
import {alertify} from 'doorson-ui';

// Components
import EditDocumentationImageModal from '../../components/EditDocumentationImageModal/EditDocumentationImageModal';

// Lib
import toBase64 from '../../../file/lib/toBase64.lib.file';

class EditDocumentationImageContainer extends Component {
  static propTypes = {
    folder: PropTypes.object,
    visible: PropTypes.bool,
    onComplete: PropTypes.func,
    onClose: PropTypes.func,
  };

  static INITIAL_STATE = {
    image: null,
  };

  state = {
    loading: false,
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => this.setState({...this.constructor.INITIAL_STATE});

  change = (key) => (value) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  close = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  save = async ({image = this.state.image} = {}) => {
    // TODO: I18n
    const {folder, onComplete, visible} = this.props;
    const {loading} = this.state;

    if (loading || !visible) return;

    this.setState({loading: true});

    try {
      const actualImage = !!image ? await toBase64(image) : null;
      const newFolder = await updateImageApi(folder.id, actualImage);
      onComplete(newFolder);
    } catch (error) {
      alertify.error('Could not update folder image');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {visible, folder} = this.props;
    const {loading, image} = this.state;
    return (
      <EditDocumentationImageModal
        visible={visible}
        loading={loading}
        title="Edit Image"
        imageLabel="Folder Image"
        saveLabel="Save"
        removeLabel="Remove"
        folder={folder}
        image={image}
        onClose={this.close}
        onChange={this.change}
        onSave={this.save}
      />
    );
  }
}

export default EditDocumentationImageContainer;
