import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// ManufacturersAdminPage
import ManufacturersAdminPage from '../ManufacturersAdminPage/ManufacturersAdminPage';
import routeManufacturersAdminPage from '../ManufacturersAdminPage/route';

// ManufacturerAdminPage
import ManufacturerAdminPage from '../ManufacturerAdminPage/ManufacturerAdminPage';
import routeManufacturerAdminPage from '../ManufacturerAdminPage/route';

// ProductAdminPage
import ProductAdminPage from '../../../product/pages/ProductAdminPage/ProductAdminPage';
import routeProductAdminPage from '../../../product/pages/ProductAdminPage/route';

// Consts
import page from './page';

const ManufacturersAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeManufacturersAdminPage()}
        component={ManufacturersAdminPage}
        exact
      />
      <Route
        path={routeManufacturerAdminPage()}
        component={ManufacturerAdminPage}
        exact
      />
      <Route
        path={routeProductAdminPage()}
        component={ProductAdminPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default ManufacturersAdminPages;
