import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Input, DatePicker, Row, Column, Button, Info} from 'doorson-ui';
import ButtonContainer from './components/ButtonContainer';

// Libs
import validateSerialNumberInput from '../../../layout/lib/validateSerialNumberInput.lib.layout';

const renderEditContent = ({loading, label, value, type, onChange}) => {
  switch (type) {
    case 'date':
      return (
        <Info label={label} noBorder>
          <DatePicker value={value} onChange={onChange} />
        </Info>
      );

    case 'serial':
      return (
        <Input
          value={value}
          onChange={onChange}
          disabled={loading}
          onKeyDown={validateSerialNumberInput}
        >
          {label || ''}
        </Input>
      );

    default:
      return (
        <Input value={value} onChange={onChange} disabled={loading}>
          {label || ''}
        </Input>
      );
  }
};

const EditDoorInfoModal = ({
  label,
  title,
  saveLabel,

  visible,
  loading,
  value,
  type,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose}>
    <Row margin>
      <Column>
        {renderEditContent({loading, label, value, type, onChange})}
      </Column>
    </Row>
    <Row>
      <Column>
        <ButtonContainer>
          <Button loading={loading} onClick={onSave}>
            {saveLabel}
          </Button>
        </ButtonContainer>
      </Column>
    </Row>
  </Modal>
);

EditDoorInfoModal.propTypes = {
  label: PropTypes.node,
  title: PropTypes.node,
  saveLabel: PropTypes.node,

  visible: PropTypes.bool,
  loading: PropTypes.bool,
  value: PropTypes.any,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default EditDoorInfoModal;
