import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isEmail} from 'validator';
import onClickOutside from 'react-onclickoutside';
import $ from 'jquery';

// Alertify
import {alertify} from 'doorson-ui';

// Components
import Container from './components/Container';
import Content from './components/Content';
import DropDown from './components/DropDown';
import Option from './components/Option';
import OptionValue from './components/OptionValue';
import OptionAction from './components/OptionAction';
import Input from './components/Input';

class Invitees extends Component {
  static propTypes = {
    invitees: PropTypes.array,
    users: PropTypes.array,
    onChange: PropTypes.func,
  };

  state = {
    visible: false,
    email: '',
  };

  input = null;

  onInput = (input) => {
    this.input = input;
  };

  add = () => {
    const {users, invitees, onChange} = this.props;
    const {email} = this.state;
    if (!isEmail(email)) return alertify.warning('Insert valid email');
    if (!![...invitees].find((invitee) => invitee.email === email))
      return alertify.warning('User already invited');
    const user = [...users].find((usr) => usr.email === email);
    const invitee = {email};
    if (!!user) invitee.userId = user.id;
    const newInvitees = [...invitees, invitee];
    onChange(newInvitees);
    this.setState({email: ''});
  };

  remove = (email) => () => {
    const {invitees, onChange} = this.props;
    const newInvitees = [...invitees].filter(
      (invitee) => invitee.email !== email
    );
    onChange(newInvitees);
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.add();
      return;
    }
  };

  onChange = ({target: {value}}) => this.setState({email: value.toLowerCase()});

  show = () => {
    this.setState({visible: true, email: ''});
    if (!this.input) return;
    setTimeout(() => {
      $(this.input).focus();
    }, 205);
  };

  hide = () => {
    this.setState({visible: false});
  };

  handleClickOutside = () => this.hide();

  render() {
    const {invitees} = this.props;
    const {visible, email} = this.state;
    return (
      <Container>
        <Content onClick={this.show} inserted={!!invitees.length}>
          {!invitees.length
            ? 'Add Person'
            : invitees.length === 1
            ? invitees[0].email
            : `${invitees.length} persons added`}
        </Content>
        <DropDown visible={visible}>
          {invitees.map(({email}) => (
            <Option key={email}>
              <OptionValue>{email}</OptionValue>
              <OptionAction
                className="mdi mdi-close"
                onClick={this.remove(email)}
              />
            </Option>
          ))}
          <Option border={!!invitees.length}>
            <OptionValue>
              <Input
                ref={this.onInput}
                value={email}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                placeholder="Insert email"
              />
            </OptionValue>
            <OptionAction className="mdi mdi-check" onClick={this.add} />
          </Option>
        </DropDown>
      </Container>
    );
  }
}

export default connect((state) => ({users: state.user.users}))(
  onClickOutside(Invitees)
);
