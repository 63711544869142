import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui/lib/Grid/Grid';
import {Select, Button, CheckBox, Input} from 'doorson-ui';

// Local components
import ButtonContainer from './ButtonContainer';

// Libs
import validateSerialNumberInput from '../../../../layout/lib/validateSerialNumberInput.lib.layout';

const ManufacturerInfo = ({
  error,
  loading,
  scan,
  manufacturers,
  products,
  productTypes,
  serviceItem,
  manufacturerLabel,
  scanLabel,
  productIdLabel,
  productTypeLabel,
  redundancyLabel,
  doorSerialLabel,
  electronicsSerialLabel,
  motorSerialLabel,
  manufactureDateLabel,
  installationDateLabel,
  leafInfoLabel,
  onChange,
  toggleScan,
}) => (
  <Row>
    <Column m={1}>
      <Row margin>
        <Column size={2 / 3}>
          <Select
            value={serviceItem.manufacturerId}
            options={manufacturers}
            onChange={onChange('manufacturerId')}
            error={error.manufacturerId}
            disabled={loading}
            required
          >
            {manufacturerLabel}
          </Select>
        </Column>
        <Column size={1 / 3}>
          {serviceItem && (
            <ButtonContainer>
              <Button
                size="normal"
                theme="blue"
                preIcon="qrcode"
                disabled={scan}
                outline={true}
                onClick={() => toggleScan(!scan)}
              >
                {scanLabel}
              </Button>
            </ButtonContainer>
          )}
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 3}>
          <Select
            value={serviceItem.productId}
            options={products}
            onChange={onChange('productId')}
            error={error.productId}
            disabled={loading}
            required
          >
            {productIdLabel}
          </Select>
        </Column>
        <Column size={1 / 3}>
          <Select
            value={serviceItem.productTypeId}
            options={productTypes}
            onChange={onChange('productTypeId')}
            error={error.productTypeId}
            disabled={loading}
            required={
              !serviceItem.productId ||
              (!!serviceItem.productId &&
                !(productTypes.length === 1 && productTypes[0].value === ''))
            }
          >
            {productTypeLabel}
          </Select>
        </Column>
        <Column size={1 / 3}>
          <CheckBox
            selected={!!serviceItem.redundancy}
            onSelect={onChange('redundancy', true)}
            disabled={loading}
            large
          >
            {redundancyLabel}
          </CheckBox>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 3}>
          <Input
            type="text"
            value={serviceItem.doorSerial}
            onChange={onChange('doorSerial', true)}
            error={error.doorSerial}
            disabled={loading}
            onKeyDown={validateSerialNumberInput}
            required
          >
            {doorSerialLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Input
            type="text"
            value={serviceItem.electronicsSerial}
            onChange={onChange('electronicsSerial', true)}
            error={error.electronicsSerial}
            onKeyDown={validateSerialNumberInput}
            disabled={loading}
            required
          >
            {electronicsSerialLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Input
            type="text"
            value={serviceItem.motorSerial}
            onChange={onChange('motorSerial', true)}
            error={error.motorSerial}
            onKeyDown={validateSerialNumberInput}
            disabled={loading}
            required
          >
            {motorSerialLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 2}>
          <Input
            type="text"
            value={serviceItem.manufactureDateStr}
            onChange={onChange('manufactureDateStr', true)}
            error={error.manufactureDateStr}
            disabled={loading}
          >
            {manufactureDateLabel}
          </Input>
        </Column>
        <Column size={1 / 2}>
          <Input
            type="text"
            value={serviceItem.installationDateStr}
            onChange={onChange('installationDateStr', true)}
            error={error.installationDateStr}
            disabled={loading}
          >
            {installationDateLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 3}>
          <Input
            value={serviceItem.leafInfo}
            onChange={onChange('leafInfo', true)}
            error={error.leafInfo}
            disabled={loading}
          >
            {leafInfoLabel}
          </Input>
        </Column>
      </Row>
    </Column>
  </Row>
);

ManufacturerInfo.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  scan: PropTypes.bool,
  manufacturers: PropTypes.array,
  products: PropTypes.array,
  productTypes: PropTypes.array,
  serviceItem: PropTypes.object,
  manufacturerLabel: PropTypes.string,
  scanLabel: PropTypes.string,
  productIdLabel: PropTypes.string,
  productTypeLabel: PropTypes.string,
  redundancyLabel: PropTypes.string,
  doorSerialLabel: PropTypes.string,
  electronicsSerialLabel: PropTypes.string,
  motorSerialLabel: PropTypes.string,
  manufactureDateLabel: PropTypes.string,
  installationDateLabel: PropTypes.string,
  leafInfoLabel: PropTypes.string,
  onChange: PropTypes.func,
  toggleScan: PropTypes.func,
};

export default ManufacturerInfo;
