import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';

// Components
import NotFound from '../../../layout/pages/NotFound/NotFound';

// Interventions
import InterventionsPage from '../../../intervention/pages/InterventionsPage/InterventionsPage';
import routeInterventionsPage from '../../../intervention/pages/InterventionsPage/route';

// Intervention
import InterventionPage from '../../../intervention/pages/InterventionPage/InterventionPage';
import routeInterventionPage from '../../../intervention/pages/InterventionPage/route';

// New Intervention
import NewInterventionPage from '../../../intervention/pages/NewInterventionPage/NewInterventionPage';
import routeNewInterventionPage from '../../../intervention/pages/NewInterventionPage/route';

// Maintenances
import MaintenancesPage from '../../../maintenance/pages/MaintenancesPage/MaintenancesPage';
import routeMaintenancesPage from '../../../maintenance/pages/MaintenancesPage/route';

import ServiceItemsPage from '../../../door/pages/ServiceItemsPage/ServiceItemsPage';
import routeServiceItemsPage from '../../../door/pages/ServiceItemsPage/route';

import ServiceItemPage from '../../../door/pages/ServiceItemPage/ServiceItemPage';
import routeServiceItemPage from '../../../door/pages/ServiceItemPage/route';

import WarehousesPage from '../../../warehouse/pages/WarehousesPage/WarehousesPage';
import routeWarehousesPage from '../../../warehouse/pages/WarehousesPage/route';

import WarehousePage from '../../../warehouse/pages/WarehousePage/WarehousePage';
import routeWarehousePage from '../../../warehouse/pages/WarehousePage/route';

import WarehouseOrdersPage from '../../../warehouseOrder/pages/WarehouseOrdersPage/WarehouseOrdersPage';
import routeWarehouseOrdersPage from '../../../warehouseOrder/pages/WarehouseOrdersPage/route';

import WarehouseOrderPage from '../../../warehouseOrder/pages/WarehouseOrderPage/WarehouseOrderPage';
import routeWarehouseOrderPage from '../../../warehouseOrder/pages/WarehouseOrderPage/route';

import InventoryPage from '../../../inventory/pages/InventoryPage/InventoryPage';
import routeInventoryPage from '../../../inventory/pages/InventoryPage/route';

// Maintenance
import MaintenancePage from '../../../maintenance/pages/MaintenancePage/MaintenancePage';
import routeMaintenancePage from '../../../maintenance/pages/MaintenancePage/route';

// NewMaintenance
import NewMaintenancePage from '../../../maintenance/pages/NewMaintenancePage/NewMaintenancePage';
import routeNewMaintenancePage from '../../../maintenance/pages/NewMaintenancePage/route';

// Documentation
import DocumentationPage from '../../../documentation/pages/DocumentationPage/DocumentationPage';
import routeDocumentationPage from '../../../documentation/pages/DocumentationPage/route';

// DocumentationSub
import DocumentationSubPage from '../../../documentation/pages/DocumentationSubPage/DocumentationSubPage';
import routeDocumentationSubPage from '../../../documentation/pages/DocumentationSubPage/route';

// Activities
import ActivitiesPage from '../../../activity/pages/ActivitiesPage/ActivitiesPage';
import routeActivitiesPage from '../../../activity/pages/ActivitiesPage/route';

// Reminders
import RemindersPage from '../../../reminder/pages/RemindersPage/RemindersPage';
import routeRemindersPage from '../../../reminder/pages/RemindersPage/route';

// Maps
import MapsPage from '../../../maps/pages/MapsPage/MapsPage';
import routeMapsPage from '../../../maps/pages/MapsPage/route';

// Stats
import routeStatsPage from '../../../stats/pages/StatsPage/route';

// Admin
import AdminPage from '../../../admin/pages/AdminPage/AdminPage';
import routeAdminPage from '../../../admin/pages/AdminPage/route';

// Profile
import ProfilePage from '../../../profile/pages/ProfilePage/ProfilePage';
import routeProfilePage from '../../../profile/pages/ProfilePage/route';

// Containers
import Layout from '../../../layout/containers/LayoutContainer/LayoutContainer';
import StatsPage from "../../../stats/pages/StatsPage/StatsPage";

const Routes = () => (
  <ScrollContext>
    <Layout>
      <Switch>
        <Route path="/" component={MapsPage} exact/>
        <Route
          path={routeInterventionsPage()}
          component={InterventionsPage}
          exact
        />
        <Route
          path={routeNewInterventionPage()}
          component={NewInterventionPage}
          exact
        />
        <Route path={routeInterventionPage()} component={InterventionPage}/>
        <Route
          path={routeMaintenancesPage()}
          component={MaintenancesPage}
          exact
        />
        <Route
          path={routeNewMaintenancePage()}
          component={NewMaintenancePage}
          exact
        />
        <Route path={routeMaintenancePage()} component={MaintenancePage}/>
        <Route
          path={routeServiceItemsPage()}
          component={ServiceItemsPage}
          exact
        />
        <Route path={routeServiceItemPage()} component={ServiceItemPage}/>
        <Route
          path={routeInventoryPage()}
          component={InventoryPage}
          exact
        />
        <Route
          path={routeWarehousesPage()}
          component={WarehousesPage}
          exact
        />
        <Route
          path={routeWarehousePage()}
          component={WarehousePage}
          exact
        />
        <Route
          path={routeWarehouseOrdersPage()}
          component={WarehouseOrdersPage}
          exact
        />
        <Route
          path={routeWarehouseOrderPage()}
          component={WarehouseOrderPage}
          exact
        />
        <Route
          path={routeDocumentationPage()}
          component={DocumentationPage}
          exact
        />
        <Route
          path={routeDocumentationSubPage()}
          component={DocumentationSubPage}
          exact
        />
        <Route path={routeActivitiesPage()} component={ActivitiesPage} exact/>
        <Route path={routeMapsPage()} component={MapsPage} exact/>
        <Route path={routeStatsPage()} component={StatsPage} exact/>
        <Route path={routeRemindersPage()} component={RemindersPage} exact/>
        <Route path={routeAdminPage()} component={AdminPage}/>
        <Route path={routeProfilePage()} component={ProfilePage}/>
        <Route component={NotFound}/>
      </Switch>
    </Layout>
  </ScrollContext>
);

export default Routes;
