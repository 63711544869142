import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Header = styled.div`
  width: 100%;
  line-height: 44px;
  text-align: center;
  pointer-events: none;
  background: ${white};
  margin-bottom: 2px;
  font-size: 15px;
`;

export default Header;
