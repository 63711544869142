import styled from 'styled-components';

const Addon = styled.div`
  width: 180px;
  max-width: 180px;
  min-width: 180px;
  height: 40px;
  display: block;
  margin: 0px 0px 20px 20px;
  overflow: hidden;
`;

export default Addon;
