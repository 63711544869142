const types = [
  'string',
  'number',
  'boolean',
  'object',
  'array',
  'any',
  'date',
];
export default types;
