import styled from 'styled-components';

const Menu = styled.div`
  position: relative;
  padding-right: 30px;
  font-size: 15px;
  cursor: pointer;
`;

export default Menu;
