import styled from 'styled-components';
import {transitions} from 'polished';

// Colors
import {colors} from 'doorson-ui';
const {white, darkGrey} = colors;

const DropDown = styled.div`
  border: 1px solid ${darkGrey};
  background: ${white};
  position: absolute;
  top: 100%;
  left: 0px;
  opacity: ${({visible}) => (visible ? 1 : 0)};
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  transform: ${({visible}) => (visible ? 'none' : 'translateY(10px)')};
  ${transitions(
    'opacity .2s ease-in-out, visibility .2s ease-in-out, transform .2s ease-in-out'
  )}
`;

export default DropDown;
