import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Components
import {CheckBox} from 'doorson-ui';
import Container from './components/Container';
import TopBar from './components/TopBar';
import TopRight from './components/TopRight';
import CheckContainer from './components/CheckContainer';
import Input from './components/Input';
import Content from './components/Content';
import NotFound from './components/NotFound';
import Scroller from './components/Scroller';
import Branches from './components/Branches';
import Branch from './components/Branch';
import NewBranch from './components/NewBranch';

class BranchSelection extends Component {
  static propTypes = {
    branches: PropTypes.array,
    selected: PropTypes.array,
    editable: PropTypes.bool,
    selectable: PropTypes.bool,
    onSelect: PropTypes.func,
    onNewBranch: PropTypes.func,
    getCountryByCode: PropTypes.func,

    branchesLabel: PropTypes.string,
    noBranchesLabel: PropTypes.string,
    selectAllLabel: PropTypes.string,
    searchLabel: PropTypes.string,
    noBranchesFoundLabel: PropTypes.string,
    newBranchLabel: PropTypes.string,
  };

  static defaultProps = {
    editable: false,
    selectable: false,
    selected: [],
    onSelect: () => null,
  };

  state = {
    search: '',
  };

  scroller = null;

  onScrollerRef = (ref) => {
    this.scroller = ref;
  };

  onSearch = (e) => {
    this.setState({search: e.target.value});
    if (!this.scroller) return;
    $(this.scroller).scrollLeft(0);
  };

  branches = () => {
    const {branches} = this.props;
    const {search: rawSearch} = this.state;
    const search = rawSearch.toLowerCase();
    return !!search.trim().length
      ? [
          ...branches,
        ].filter(({branchName, branchLocation: {address, zip, city}}) =>
          [branchName, address, zip, city].some((attr) =>
            attr.toLowerCase().includes(search)
          )
        )
      : branches;
  };

  areAllSelected = () =>
    [...this.props.branches].every(({id}) => this.props.selected.includes(id));

  selectAll = () => {
    const {branches, onSelect} = this.props;
    const branchIDs = [...branches].map(({id}) => id);
    onSelect(branchIDs);
  };

  unselectAll = () => this.props.onSelect([]);

  onSelect = (branch) => () => {
    const {selectable, selected, onSelect} = this.props;
    if (!selectable) return onSelect(branch);
    const newSelected = selected.includes(branch.id)
      ? [...selected].filter((id) => branch.id !== id)
      : [...selected, branch.id];
    onSelect(newSelected);
  };

  render() {
    const {
      selectable,
      branchesLabel,
      noBranchesLabel,
      selected,
      selectAllLabel,
      searchLabel,
      noBranchesFoundLabel,
      newBranchLabel,
      onNewBranch,
    } = this.props;
    const {search} = this.state;
    const branches = this.branches();
    const areAllSelected = this.areAllSelected();
    return (
      <Fragment>
        <Container>
          <TopBar>
            {branchesLabel}
            <TopRight>
              <Input
                value={search}
                onChange={this.onSearch}
                placeholder={searchLabel}
              />
              {selectable && (
                <CheckContainer>
                  <CheckBox
                    selected={areAllSelected}
                    onSelect={
                      areAllSelected ? this.unselectAll : this.selectAll
                    }
                  >
                    {selectAllLabel}
                  </CheckBox>
                </CheckContainer>
              )}
              {!!onNewBranch && !!newBranchLabel && (
                <NewBranch onClick={onNewBranch}>{newBranchLabel}</NewBranch>
              )}
            </TopRight>
          </TopBar>
          <Content>
            {!branches.length ? (
              <NotFound>
                {!!search.trim().length
                  ? noBranchesFoundLabel
                  : noBranchesLabel}
              </NotFound>
            ) : (
              <Scroller ref={this.onScrollerRef}>
                <Branches>
                  {branches.map((branch) => (
                    <Branch
                      key={branch.id}
                      selected={selected.includes(branch.id)}
                      onClick={this.onSelect(branch)}
                    >
                      {branch.branchName}
                      <br />
                      {branch.branchLocation.address}
                      <br />
                      {branch.branchLocation.zip} {branch.branchLocation.city}
                      <br />
                      {(this.props.getCountryByCode(branch.branchLocation.countryCode)||{}).name}
                    </Branch>
                  ))}
                </Branches>
              </Scroller>
            )}
          </Content>
        </Container>
      </Fragment>
    );
  }
}

export default BranchSelection;
