import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Api
import assignApi from '../../api/assign.api.maintenance';
import unassignApi from '../../api/unassign.api.maintenance';

// Alert
import {alertify} from 'doorson-ui';

// Actions
import {
  getMaintenances as getMaintenancesAct,
  getMoreMaintenances as getMoreMaintenancesAct,
  updateMaintenance as updateMaintenanceAct,
} from '../../redux/actions';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import MaintenancesSearch from '../../components/MaintenancesSearch/MaintenancesSearch';
import MaintenancesHeader from '../../components/MaintenancesHeader/MaintenancesHeader';
import MaintenancesFilter from '../../components/MaintenancesFilter/MaintenancesFilter';
import Maintenances from '../../components/Maintenances/Maintenances';

// Containers
import CloseMaintenanceContainer from '../CloseMaintenanceContainer/CloseMaintenanceContainer';

// Libs
import isClosed from '../../lib/isClosed.lib.maintenance';
import isResolved from '../../lib/isResolved.lib.maintenance';

// Routes
import maintenanceRoute from '../../pages/MaintenancePage/route';
import newMaintenanceRoute from '../../pages/NewMaintenancePage/route';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SERVICE_TECHNICIAN, SYSTEM,} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';
import getMaintenanceByIdApi from '../../api/getByID.api.maintenance';

class MaintenancesContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    more: PropTypes.bool,
    search: PropTypes.string,
    maintenances: PropTypes.array,
    filter: PropTypes.string,
    sort: PropTypes.array,
    user: PropTypes.object,
    users: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    assigning: [],
    closing: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.getMaintenances();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  syncMaintenance = (maintenance) =>
    this.props.dispatch(updateMaintenanceAct(maintenance));

  filterAssigning = (maintenanceID) =>
    [...this.state.assigning].filter((a) => a !== maintenanceID);

  onSearch = (search) => this.getMaintenances({search});

  onFilter = (filter) => {
    const defaultSort = filter === 'RESOLVED' ? 'dateResolved' : (filter === 'CLOSED' ? 'dateClosed' : 'priority');
    this.getMaintenances({filter, sort: [{key: defaultSort, direction: 'desc'}, {key: 'dateScheduledStart', direction: 'desc'}]});
  }

  onSort = (sort) => this.getMaintenances({sort});

  onMaintenance = (maintenance) =>
    this.props.history.push(maintenanceRoute(maintenance.id));

  assign = async ({userID, maintenanceID}) => {
    // TODO: I18n
    this.setState({assigning: [...this.state.assigning, maintenanceID]});

    try {
      const maintenance = await assignApi(maintenanceID, userID);
      this.syncMaintenance(maintenance);
      if (!this.mounted) return;
      this.setState({assigning: this.filterAssigning(maintenanceID)});
    } catch (error) {
      alertify.error('Could not assign');
      if (!this.mounted) return;
      this.setState({assigning: this.filterAssigning(maintenanceID)});
    }
  };

  unassign = async ({maintenanceID}) => {
    const {user} = this.props;
    // TODO: I18n
    this.setState({assigning: [...this.state.assigning, maintenanceID]});

    try {
      const maintenance = await unassignApi(maintenanceID, user.id);
      this.syncMaintenance(maintenance);
      if (!this.mounted) return;
      this.setState({assigning: this.filterAssigning(maintenanceID)});
    } catch (error) {
      alertify.error('Could not unassign');
      if (!this.mounted) return;
      this.setState({assigning: this.filterAssigning(maintenanceID)});
    }
  };

  onClose = (maintenance) => {
    this.syncMaintenance(maintenance);
    this.setState({closing: false});
  };

  onAction = (action, {maintenanceID, userID, maintenance}) => {
    const {assigning} = this.state;
    if (assigning.includes(maintenanceID)) return;

    switch (action) {
      case 'assign':
        return this.assign({maintenanceID, userID});
      case 'unassign':
        return this.unassign({maintenanceID, userID});
      case 'close':
        return this.showClose(maintenance);
      default:
        return;
    }
  };

  showClose = async (closingMaintenance) => {
    try {
      const maintenance = await getMaintenanceByIdApi(closingMaintenance.id, {rel: ['customer', 'customerBranch', 'doors', 'door', 'materials']});
      this.setState({closing: maintenance});
    } catch (error) {
      alertify.error('Unable to show close maintenance window at the moment');
    }
  }

  hideClose = () => this.setState({closing: null});

  close = (maintenance) => {
    this.syncMaintenance(maintenance);
    this.setState({closing: null});
  };

  getMaintenances = async (data) =>
    this.props.dispatch(getMaintenancesAct(data));

  onMore = () => this.props.dispatch(getMoreMaintenancesAct());

  maintenances = () =>
    [...this.props.maintenances].map((maintenance) => ({
      ...maintenance,
      resolved: isResolved(maintenance),
      closed: isClosed(maintenance),
    }));

  filters = () =>
    [
      {
        id: 'OPENED',
        label: 'Open',
      },
      {
        id: 'ASSIGNEDTOME',
        label: 'Assigned to me',
      },
      {
        id: 'ASSIGNED',
        label: 'Assigned',
      },
      {
        id: 'RESOLVED',
        label: 'Resolved',
      },
      hasPermission({
        user: this.props.user,
        roles: [CHIEF_SERVICE_TECHNICIAN, SERVICE_TECHNICIAN, SYSTEM],
      }) && {
        id: 'CLOSED',
        label: 'Closed',
      },
    ].filter((act) => !!act);

  columns = (filter) => [
    {
      key: 'priority',
      label: 'Priority',
      sortable: true,
    },
    {
      key: 'code',
      label: 'Task code',
      span: 1.5,
    },
    {
      key: filter === 'RESOLVED' ? 'dateResolved' : (filter === 'CLOSED' ? 'dateClosed' : 'dateScheduledStart'),
      label: filter === 'RESOLVED' ? 'Date Resolved' : (filter === 'CLOSED' ? 'Date Closed' : 'Scheduled for'),
      span: 1.5,
      sortable: true,
    },
    {
      key: 'customer',
      label: 'Customer',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'location',
      label: 'Location',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'doorType',
      label: 'Door Type',
      span: 3,
    },
  ];

  renderHeader = () => (
    <Fragment>
      <MaintenancesHeader>Doorson Maintenance</MaintenancesHeader>
      <MaintenancesFilter
        newLabel="New"
        newUrl={newMaintenanceRoute()}
        filter={this.props.filter}
        filters={this.filters()}
        onFilter={this.onFilter}
      />
    </Fragment>
  );

  render() {
    const {loading, user, users, search, sort, filter, scroller, more} = this.props;
    const {assigning, closing} = this.state;
    const dateColumn = filter === 'RESOLVED' ? 'dateResolved' : (filter === 'CLOSED' ? 'dateClosed' : 'scheduledFor');
    // TODO I18N
    return (
      <Fragment>
        <MaintenancesSearch search={search} onSearch={this.onSearch}/>
        <ContentLayout header={this.renderHeader()}>
          <Maintenances
            loading={loading}
            more={more}
            scroller={scroller}
            assigning={assigning}
            user={user}
            users={users}
            sort={Array.isArray(sort) ? sort[0] : sort}
            dateColumn={dateColumn}
            columns={this.columns(filter)}
            maintenances={this.maintenances()}
            onMaintenance={this.onMaintenance}
            onSort={this.onSort}
            onAction={this.onAction}
            onMore={this.onMore}
          />
        </ContentLayout>
        <CloseMaintenanceContainer
          visible={!!closing}
          maintenance={closing}
          onClose={this.hideClose}
          onDone={this.close}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  users: state.user.users,
  scroller: state.layout.scroller,
  ...state.maintenance,
}))(withRouter(MaintenancesContainer));
