import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Container, Row, Select, SimpleTable, Textarea} from 'doorson-ui';
import Content from './components/Content';

// Libs
import DataBlock from "../../../layout/components/DataBlock/DataBlock";
import WarehouseOrderLoader from "../WarehouseOrderLoader/WarehouseOrderLoader";
import {date as dateType, time as timeType} from '../../../types';
import Right from "../../../interventionDoor/components/Workinglog/components/Right";
import ButtonContainer from "./components/ButtonContainer";

const WarehouseOrder = ({
                          loading,
                          saving,
                          error,
                          order,
                          inReceiving,
                          editEnabled,

                          saveLabel,
                          backLabel,
                          orderLabel,
                          orderItemsLabel,
                          orderDispatchLabel,
                          orderReceiveLabel,
                          orderNumberLabel,
                          statusLabel,
                          sourceWarehouseLabel,
                          targetWarehouseLabel,
                          requesterPersonLabel,
                          senderPersonLabel,
                          receiverPersonLabel,
                          collectTimeLabel,
                          carrierNameLabel,
                          carrierTrackingCodeLabel,
                          senderCommentLabel,
                          receiverCommentLabel,
                          createdDateLabel,
                          createdTimeLabel,

                          statuses,
                          saveOptions,
                          sourceWarehouses,
                          targetWarehouses,

                          orderItemsComponent,

                          onChange,
                          onBack,
                          onSave
                        }) =>
  !order ? (
    <WarehouseOrderLoader/>
  ) : (
    <Content>
      <Container>
        <DataBlock label={orderLabel}>
          <Row>
            <Column size={1 / 2}>
              <SimpleTable
                values={[
                  {
                    key: statusLabel,
                    value: ([...statuses].find(s => s.value === order.status) || {}).label,
                  },
                  {
                    key: orderNumberLabel,
                    value: order.id,
                  },
                  {
                    key: requesterPersonLabel,
                    value: order.requesterPersonName,
                  },
                  {
                    key: createdDateLabel,
                    value: dateType(order.dc).format(),
                  },
                  {
                    key: createdTimeLabel,
                    value: timeType(order.dc).format(),
                  },
                ].filter((v) => !!v)}
              />
            </Column>
            <Column size={1 / 2}>
              <Row>
                <Column>
                  <Select
                    value={order.sourceWarehouseId}
                    options={sourceWarehouses}
                    onChange={onChange('sourceWarehouseId')}
                    error={error.sourceWarehouseId}
                    disabled={loading || !editEnabled}
                    required>
                    {sourceWarehouseLabel}
                  </Select>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Select
                    value={order.targetWarehouseId}
                    options={targetWarehouses}
                    onChange={onChange('targetWarehouseId')}
                    error={error.targetWarehouseId}
                    disabled={loading || !editEnabled}
                    required>
                    {targetWarehouseLabel}
                  </Select>
                </Column>
              </Row>
              {!inReceiving && <Row>
                <Column>
                  <Textarea
                    value={order.receiverComment}
                    onChange={onChange('receiverComment')}
                    error={error.receiverComment}
                    resize="vertical"
                    maxRows={8}
                    disabled={loading || !editEnabled}
                  >
                    {receiverCommentLabel}
                  </Textarea>
                </Column>
              </Row>}
            </Column>
          </Row>
        </DataBlock>
        <DataBlock label={orderDispatchLabel}>
          <Row>
            <Column size={2 / 6}>
              <SimpleTable
                values={[
                  {
                    key: senderPersonLabel,
                    value: order.senderPersonName,
                  },
                  {
                    key: collectTimeLabel,
                    value: dateType(order.collectEstTime).format(),
                  },
                  {
                    key: carrierNameLabel,
                    value: order.carrierName,
                  },
                  {
                    key: carrierTrackingCodeLabel,
                    value: order.carrierTrackingCode,
                  },
                ].filter((v) => !!v)}
              />
            </Column>
            <Column size={1 / 6}>
            </Column>
            <Column size={1 / 2}>
              <Textarea
                value={order.senderComment}
                onChange={onChange('senderComment')}
                error={error.senderComment}
                resize="vertical"
                maxRows={8}
                disabled={true}
              >
                {senderCommentLabel}
              </Textarea>
            </Column>
          </Row>
        </DataBlock>
        <DataBlock label={orderReceiveLabel}>
          <Row>
            <Column size={2 / 6}>
              <SimpleTable
                values={[
                  {
                    key: receiverPersonLabel,
                    value: order.receiverPersonName,
                  }
                ].filter((v) => !!v)}
              />
            </Column>
            <Column size={1 / 6}/>
            {inReceiving && <Column size={1 / 2}>
              <Textarea
                value={order.receiverComment}
                onChange={onChange('receiverComment')}
                error={error.receiverComment}
                resize="vertical"
                maxRows={8}
                disabled={loading || order.status !== 'COMPLETED' || order.status !== 'REJECTED'}
              >
                {receiverCommentLabel}
              </Textarea>
            </Column>}
          </Row>
        </DataBlock>
        <DataBlock label={orderItemsLabel}>
        </DataBlock>
      </Container>
      <Container>
        <Row>
          <Column>
            {orderItemsComponent}
          </Column>
        </Row>
      </Container>
      <Container>
        <Row>
          <Column>
            <Right>
              <ButtonContainer>
                {!order.sourceWarehouseId || order.status !== 'DRAFT' || <Button
                  size="big"
                  outline
                  loading={loading}
                  disabled={saving}
                  onClick={onBack}>
                  {backLabel}
                </Button>}
              </ButtonContainer>
              <ButtonContainer>
                <Button size="big"
                        theme="orange"
                        options={saveOptions}
                        loading={saving}
                        disabled={saving || saveOptions.length === 0}
                        onClick={onSave}>
                  {saveLabel}
                </Button>
              </ButtonContainer>
            </Right>
          </Column>
        </Row>
      </Container>
    </Content>
  );

WarehouseOrder.propTypes = {
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  error: PropTypes.object,
  order: PropTypes.object,
  inReceiving: PropTypes.bool,
  editEnabled: PropTypes.bool,

  saveLabel: PropTypes.node,
  backLabel: PropTypes.node,
  orderLabel: PropTypes.node,
  orderItemsLabel: PropTypes.node,
  orderDispatchLabel: PropTypes.node,
  orderReceiveLabel: PropTypes.node,
  orderNumberLabel: PropTypes.node,
  statusLabel: PropTypes.node,
  sourceWarehouseLabel: PropTypes.node,
  targetWarehouseLabel: PropTypes.node,
  requesterPersonLabel: PropTypes.node,
  senderPersonLabel: PropTypes.node,
  receiverPersonLabel: PropTypes.node,
  collectTimeLabel: PropTypes.node,
  carrierNameLabel: PropTypes.node,
  carrierTrackingCodeLabel: PropTypes.node,
  senderCommentLabel: PropTypes.node,
  receiverCommentLabel: PropTypes.node,
  createdDateLabel: PropTypes.node,
  createdTimeLabel: PropTypes.node,

  statuses: PropTypes.array,
  saveOptions: PropTypes.array,
  sourceWarehouses: PropTypes.array,
  targetWarehouses: PropTypes.array,

  orderItemsComponent: PropTypes.node,

  onChange: PropTypes.func,
  onBack: PropTypes.func,
  onSave: PropTypes.func,
};

export default WarehouseOrder;
