import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {v4} from 'uuid';

// Alertify
import {alertify} from 'doorson-ui';

// Components
import GeneralTranslationsTable from '../../components/GeneralTranslationsTable/GeneralTranslationsTable';
import GeneralTranslationModal from '../../components/GeneralTranslationModal/GeneralTranslationModal';

// Query
import setQuery from '../../../api/lib/query.lib.api';

class GeneralTranslationsContainer extends Component {
  static propTypes = {
    listApi: PropTypes.func.isRequired,
    updateApi: PropTypes.func.isRequired,
    translationAttribute: PropTypes.string.isRequired,
    displayAttribute: PropTypes.string.isRequired,
    idAttribute: PropTypes.string.isRequired,
    scroller: PropTypes.object,
    languages: PropTypes.array,
  };

  static LIMIT = 30;

  state = {
    loading: false,
    updating: false,
    language: null,
    translations: [],
    more: false,
    updateModalVisible: false,
    translation: null,
    sort: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiID = null;

  init = () => {
    const {languages} = this.props;
    const [language] = languages;
    this.setState({language: language.code});
    this.getTranslations({sort: {key: 'key', direction: 'asc'}});
  };

  languages = () =>
    [...this.props.languages].map(({code, name}) => ({
      value: code,
      label: name,
    }));

  order = (sort) => {
    const {displayAttribute} = this.props;
    return !!sort ? {...sort, key: displayAttribute} : null;
  };

  getTranslations = async ({sort = this.state.sort} = {}) => {
    const apiID = v4();
    this.apiID = apiID;

    const {listApi} = this.props;

    const {LIMIT} = this.constructor;

    this.setState({
      loading: true,
      offset: 0,
      more: true,
      translations: [],
      sort,
    });

    try {
      const query = setQuery({
        order: this.order(sort),
        offset: 0,
        limit: LIMIT,
      });
      const translations = await listApi(query);
      if (this.apiID !== apiID) return;

      this.setState({
        translations,
        loading: false,
        more: translations.length === LIMIT,
        offset: LIMIT,
      });
    } catch (error) {
      this.setState({loading: false, translations: []});
    }
  };

  getMoreTranslations = async () => {
    const apiID = this.apiID;
    const {listApi} = this.props;
    const {
      loading,
      sort,
      offset,
      more,
      translations: oldTranslations,
    } = this.state;

    const {LIMIT} = this.constructor;

    if (!more || loading) return;

    this.setState({loading: true});

    try {
      const query = setQuery({
        order: this.order(sort),
        offset,
        limit: LIMIT,
      });
      const translations = await listApi(query);
      if (this.apiID !== apiID) return;
      this.setState({
        translations: [...oldTranslations, ...translations],
        loading: false,
        offset: LIMIT + offset,
        more: translations.length === LIMIT,
      });
    } catch (error) {
      this.setState({loading: false});
    }
  };

  onSort = (sort) => this.getTranslations({sort});

  // TODO: I18n
  columns = () => [
    {
      key: 'key',
      label: 'Translation',
      span: 2,
      sortable: true,
    },
    {
      key: 'translations',
      label: 'Number Of Translations',
      span: 1,
    },
  ];

  onMore = () => this.getMoreTranslations();

  showTranslation = (translation) => () => {
    const {translationAttribute} = this.props;
    const {updating, language} = this.state;
    if (updating) return;
    const translate = [...translation.languages].find(
      (t) => t.language === language
    ) || {language, [translationAttribute]: ''};
    this.setState({
      translation: {...translation, translate},
      updateModalVisible: true,
    });
  };

  hideTranslation = () => {
    if (this.state.updating) return;
    this.setState({updateModalVisible: false});
  };

  onTranslationContentChange = (content) => {
    const {translationAttribute} = this.props;
    const {updating, translation} = this.state;
    if (updating || !translation) return;
    this.setState({
      translation: {
        ...translation,
        translate: {...translation.translate, [translationAttribute]: content},
      },
    });
  };

  onLanguage = (language) => {
    const {translationAttribute} = this.props;
    const {updating, translation} = this.state;
    if (updating || !translation) return;
    const translate = [...translation.languages].find(
      (t) => t.language === language
    ) || {language, [translationAttribute]: ''};
    this.setState({language, translation: {...translation, translate}});
  };

  update = async () => {
    const {updateApi, translationAttribute, idAttribute} = this.props;
    const {updating, translation} = this.state;

    if (updating || !translation) return;
    const {translate} = translation;

    if (!translate || !translate[translationAttribute].trim().length)
      return alertify.warning('Insert content');

    this.setState({updating: true});

    try {
      const newTranslation = await updateApi({
        languageCode: translate.language,
        translationKey: translation[idAttribute],
        translation: {
          [translationAttribute]: translate[translationAttribute],
          version: translate.version || 0,
        },
      });
      translation.translate = {...newTranslation};
      translation.languages = [...translation.languages].map((transLang) =>
        transLang.language === newTranslation.language
          ? newTranslation
          : transLang
      );
      const translations = [...this.state.translations].map((t) =>
        t[idAttribute] === translation[idAttribute] ? translation : t
      );
      this.setState({
        translations,
        updating: false,
        translation: {...translation},
      });
    } catch (error) {
      alertify.error('Could not update all the translations');
      this.setState({updating: false});
    }
  };

  render() {
    const {scroller, translationAttribute, displayAttribute} = this.props;
    const {
      loading,
      sort,
      translations,
      language,
      more,
      updating,
      translation,
      updateModalVisible,
    } = this.state;
    return (
      <Fragment>
        <GeneralTranslationsTable
          loading={loading}
          more={more}
          scroller={scroller}
          sort={sort}
          columns={this.columns()}
          translations={translations}
          displayAttribute={displayAttribute}
          onTranslation={this.showTranslation}
          onMore={this.onMore}
          onSort={this.onSort}
        />
        <GeneralTranslationModal
          loading={updating}
          visible={updateModalVisible}
          language={language}
          languages={this.languages()}
          displayAttribute={displayAttribute}
          translationAttribute={translationAttribute}
          translation={translation}
          onChange={this.onTranslationContentChange}
          onClose={this.hideTranslation}
          onSave={this.update}
          onLanguage={this.onLanguage}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  scroller: state.layout.scroller,
  languages: state.language.languages,
}))(GeneralTranslationsContainer);
