import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Button} from 'doorson-ui';
import {Select} from 'doorson-ui';

import Container from './components/Container';
import Loader from './components/Loader';

const PriorityModal = ({
  loading,
  visible,
  title,
  buttonLabel,
  createDisabled,
  priorityLabel,
  priorities,
  priority,
  assignees,
  assigneeLabel,
  assigneeId,
  showAssignee,
  onPriorityChange,
  onAssigneeChange,
  onCreate,
  onClose,
}) => (
  <Modal title={title} visible={visible} size="small" onClose={onClose}>
    {loading ? (
      <Container>
        <Loader className="mdi mdi-loading mdi-spin" />
      </Container>
    ) : (
      <Fragment>
          <Select
            value={priority}
            options={priorities}
            onChange={onPriorityChange}
            disabled={loading}
          >
            {priorityLabel}
          </Select>
          {showAssignee && (<Select
            value={assigneeId}
            options={assignees}
            onChange={onAssigneeChange}
            disabled={loading}
          >
            {assigneeLabel}
          </Select>)}
              <Button
                theme="orange"
                size="big"
                onClick={onCreate}
                loading={loading}
                disabled={createDisabled}
                postIcon="arrow-right"
              >
                {buttonLabel}
              </Button>
          </Fragment>
    )}
  </Modal>
);

PriorityModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  title: PropTypes.string,
  priority: PropTypes.number,
  buttonLabel: PropTypes.string,
  createDisabled: PropTypes.bool,
  assigneeLabel: PropTypes.string,
  assigneeId: PropTypes.string,
  assignees: PropTypes.array,
  showAssignee: PropTypes.bool,
  priorityLabel: PropTypes.string,
  priorities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  onPriorityChange: PropTypes.func,
  onAssigneeChange: PropTypes.func,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
};

export default PriorityModal;
