class AuthToken {
  constructor() {
    this.token = null;
  }

  set(token) {
    this.token = token;
  }

  get() {
    return this.token;
  }

  remove() {
    this.token = null;
  }
}

const authToken = new AuthToken();

export default authToken;
