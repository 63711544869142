const prepareForUpdate = (door) => ({
  productId: door.product.id,
  productTypeId: !!door.productType ? door.productType.id : null,
  customerBranchId: door.customerBranchId,
  indoorLocation: door.indoorLocation,
  buildingFloor: door.buildingFloor,
  doorSerial: door.doorSerial,
  electronicsSerial: door.electronicsSerial,
  motorSerial: door.motorSerial,
  manufactureDate: door.manufactureDate,
  installationDate: door.installationDate,
  lastMaintenanceDate: door.lastMaintenanceDate,
  warrantyValidTillDate: door.warrantyValidTillDate,
  redundancy: door.redundancy,
  leafInfo: door.leafInfo,
  version: door.version,
});
export default prepareForUpdate;
