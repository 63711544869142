import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const sendContractApi = (maintenanceDoorID, contractReceivers, query = {}) =>
  f3tch(`/maintenance-doors/${maintenanceDoorID}/send-contract`)
    .profile(profile())
    .query(query)
    .body({contractReceivers})
    .put();
export default sendContractApi;
