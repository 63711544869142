// components
import Button from 'doorson-ui/lib/Button/Button';
import Column from 'doorson-ui/lib/Grid/Column/Column';
import Input from 'doorson-ui/lib/Input/Input';
import Modal from 'doorson-ui/lib/Modal/Modal';
import Row from 'doorson-ui/lib/Grid/Row/Row';
import SimpleSignature from 'doorson-ui/lib/SimpleSignature/SimpleSignature';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment, useState} from 'react';

const SignMultipleItemsModal = ({
  customerEmail,
  suggestionEmails,
  customerSignature,
  generatingPreview,
  onChange,
  onClose,
  onPdf,
  onSign,
  signatoryFirstName,
  signatoryLastName,
  signing,
  technicianEmail,
  technicianSignature,
  visible,
}) => {
  const [signatureBox, setSignatureBox] = useState(null);

  return (
    <Modal
      title="Sign for items"
      visible={visible}
      onClose={onClose}
      size="large"
    >
      {!!signatureBox ? (
        <Row>
          <SimpleSignature
            confirm="Confirm"
            clear="Cancel"
            onSign={(signature) => {
              onChange(signatureBox)(signature);
              setSignatureBox(null);
            }}
            onClear={(signature) => {
              onChange(signatureBox)(null);
              setSignatureBox(null);
            }}
          />
        </Row>
      ) : (
        <Fragment>
          <Row margin>
            <Column size={1 / 2}>
              <Button
                size="big"
                theme={!!technicianSignature ? 'green' : 'grey'}
                preIcon={!!technicianSignature ? 'check' : null}
                onClick={() => setSignatureBox('technicianSignature')}
              >
                Technician Signature
              </Button>
            </Column>
            <Column size={1 / 2}>
              <Button
                size="big"
                theme={!!customerSignature ? 'green' : 'grey'}
                preIcon={!!customerSignature ? 'check' : null}
                onClick={() => setSignatureBox('customerSignature')}
              >
                Customer Signature
              </Button>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}></Column>
            <Column size={1 / 2}>
              <Row>
                <Column size={1 / 2}>
                  <Input
                    value={signatoryFirstName}
                    onChange={onChange('signatoryFirstName')}
                    loading={signing}
                    required
                  >
                    First Name
                  </Input>
                </Column>
                <Column size={1 / 2}>
                  <Input
                    value={signatoryLastName}
                    onChange={onChange('signatoryLastName')}
                    loading={signing}
                    required
                  >
                    First Name
                  </Input>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <Input
                value={technicianEmail}
                onChange={onChange('technicianEmail')}
                disabled={signing}
              >
                Technician Email
              </Input>
            </Column>
            <Column size={1 / 2}>
              <Input
                value={customerEmail}
                onChange={onChange('customerEmail')}
                disabled={signing}
                suggestions={suggestionEmails}
                onSuggestion={(email) => onChange('customerEmail')(email)}
                displaySuggestions={() => true}
                required
              >
                Customer Email
              </Input>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 3}>
              <Button
                size="big"
                theme="blue"
                preIcon="file-find"
                onClick={onPdf}
                disabled={signing}
                loading={generatingPreview}
              >
                Preview PDF report
              </Button>
            </Column>
            <Column size={1 / 3}></Column>
            <Column size={1 / 3}>
              <Button
                size="big"
                loading={signing}
                disabled={generatingPreview}
                onClick={onSign}
                theme="orange"
              >
                Resolve
              </Button>
            </Column>
          </Row>
        </Fragment>
      )}
    </Modal>
  );
};

SignMultipleItemsModal.propTypes = {
  customerEmail: PropTypes.string,
  suggestionEmails: PropTypes.array,
  customerSignature: PropTypes.string,
  generatingPreview: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onPdf: PropTypes.func,
  onSign: PropTypes.func,
  signatoryFirstName: PropTypes.string,
  signatoryLastName: PropTypes.string,
  signing: PropTypes.bool,
  technicianEmail: PropTypes.string,
  technicianSignature: PropTypes.string,
  visible: PropTypes.bool,
};

export default SignMultipleItemsModal;
