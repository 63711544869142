import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import PreLoader from '../../../layout/components/PreLoader/PreLoader';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';

import Container from './components/Container';

// Libs
import getCustomerName from '../../../customer/lib/getName.lib.customer';

// Types
import {date} from '../../../types';

// TODO: I18N
const MaintenanceContracts = ({
                                openLabel,
                                closedLabel,
                                loading,
                                more,
                                scroller,
                                sort,
                                columns,
                                maintenanceContracts,
                                onMaintenanceContract,
                                onSort,
                                onMore,
                              }) => (
  <Container>
    <PreLoader
      scroller={scroller}
      loading={loading}
      showLoader={!!maintenanceContracts.length}
      more={more}
      onMore={onMore}
    >
      <Table columns={columns} loading={loading} sort={sort} onSort={onSort}>
        {(TableRow) =>
          maintenanceContracts.map((maintenanceContract) => (
            <TableRow
              key={maintenanceContract.id}
              onClick={onMaintenanceContract(maintenanceContract)}
            >
              {(TableCol) => [
                <TableCol smallPadding key="customer">
                  {getCustomerName(maintenanceContract.customer)}
                  <br/>
                  {maintenanceContract.customer.location.address}
                  <br/>
                  {maintenanceContract.customer.location.zip}{' '}
                  {maintenanceContract.customer.location.city}
                  <br/>
                  {maintenanceContract.customer.location.countryCode}
                </TableCol>,
                <TableCol smallPadding key="status">
                  {maintenanceContract.status ? openLabel : closedLabel}
                </TableCol>,
                <TableCol smallPadding key="period">
                  {date(maintenanceContract.periodStart).format()} -{' '}
                  {date(maintenanceContract.periodEnd).format()}
                </TableCol>,
                <TableCol smallPadding key="valid">
                  {date(maintenanceContract.validFrom).format()} -{' '}
                  {!!maintenanceContract.validTill
                    ? date(maintenanceContract.validTill).format()
                    : '/'}
                </TableCol>,
              ]}
            </TableRow>
          ))
        }
      </Table>
    </PreLoader>
    {!loading && !maintenanceContracts.length && (
      <NoDataFound>No maintenance contracts found</NoDataFound>
    )}
  </Container>
);

MaintenanceContracts.propTypes = {
  openLabel: PropTypes.string,
  closedLabel: PropTypes.string,
  loading: PropTypes.bool,
  more: PropTypes.bool,
  scroller: PropTypes.object,
  sort: PropTypes.object,
  columns: PropTypes.array,
  maintenanceContracts: PropTypes.array,
  onMaintenanceContract: PropTypes.func,
  onSort: PropTypes.func,
  onMore: PropTypes.func,
};

export default MaintenanceContracts;
