import {isDate, parse, format as formatDate} from 'date-fns';

import type from '../type';

const format = (value) => {
  if (!value) return '';
  const dateObj = isDate(value) ? value : parse(value);
  return formatDate(dateObj, 'd.M. HH:mm');
};

const validate = (value) =>
  /^\d{1,2}\.\d{1,2}\s{1}\d{1,2}:{1}\d{2}$/.test(value);

export default type({type: 'string', format, validate});
