import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.vehicle';

// Components
import AdminVehicles from '../../components/AdminVehicles/AdminVehicles';
import UpdateVehicleModal from '../../components/UpdateVehicleModal/UpdateVehicleModal';

// Lib
import sortByString from '../../../lib/sortByString';
import listWarehousesApi from "../../../warehouse/api/get.api.warehouse";

class VehiclesContainer extends Component {
  static propTypes = {
    vehicles: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    updateID: null,
    make: '',
    model: '',
    licensePlate: '',
    label: '',
    warehouseId: '',
    disabled: false,
    warehouses: [],
    sort: {key: 'label', direction: 'asc'},
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'make',
      label: 'Make',
      span: 1,
      sortable: true,
    },
    {
      key: 'model',
      label: 'Model',
      span: 1,
      sortable: true,
    },
    {
      key: 'licensePlate',
      label: 'License Plate',
      span: 1,
      sortable: true,
    },
    {
      key: 'label',
      label: 'Label',
      span: 1,
      sortable: true,
    },
    {
      key: 'warehouse',
      label: 'Warehouse',
      span: 1,
    },
    {
      key: 'disabled',
      label: 'Disabled',
      span: 1,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  componentDidMount() {
    this.getWarehouses();
  }

  getWarehouses = async () => {
    try {
      const warehouses = await listWarehousesApi({offset: 0, limit: 300});
      this.setState({warehouses, loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  }

  showUpdate = (vehicle) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: vehicle.id,
      make: vehicle.make,
      model: vehicle.model,
      licensePlate: vehicle.licensePlate,
      label: vehicle.label,
      warehouseId: vehicle.warehouseId,
      disabled: vehicle.disabled,
      actions: vehicle.actions,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSort = (sort) => this.setState({sort});

  update = async () => {
    const {vehicles} = this.props;
    const {
      loading,
      updateID,
      make,
      model,
      licensePlate,
      warehouseId,
      label,
      disabled,
    } = this.state;
    const vehicleObject = [...vehicles].find(({id}) => id === updateID);
    if (loading || !updateID || !vehicleObject) return;

    if (!make.trim().length) return alertify.warning('Insert the make');
    if (!model.trim().length) return alertify.warning('Insert the model');
    if (!licensePlate.trim().length)
      return alertify.warning('Insert the license plate');
    if (!label.trim().length) return alertify.warning('Insert the label');

    this.setState({loading: true});

    try {
      const vehicle = await updateApi(updateID, {
        id: updateID,
        make,
        model,
        licensePlate,
        label,
        warehouseId: warehouseId || null,
        disabled,
        version: vehicleObject.version,
      });
      const {dispatch, vehicles} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          vehicles: [...vehicles].map((r) =>
            r.id === vehicle.id ? vehicle : r
          ),
        })
      );
    } catch (error) {
      alertify.error('Vehicle could not be updated');
      this.setState({loading: false});
    }
  };

  warehouses = () =>
    [{value: null, label: ''},
      ...[...this.state.warehouses].map(({id, name}) => ({
        value: id,
        label: name,
      }))];

  data = () => {
    const {vehicles} = this.props;
    const {sort, warehouses} = this.state;
    const sorted = !!sort ? [...vehicles].sort(sortByString(sort)) : vehicles;

    return [...sorted].map(vh => ({
      ...vh,
      warehouse: vh.warehouseId && ([...warehouses].find(wh => wh.id === vh.warehouseId) || {}).name
    }));
  };

  render() {
    const {
      loading,
      sort,
      updateID,
      make,
      model,
      licensePlate,
      warehouseId,
      label,
      disabled,
    } = this.state;
    return (
      <Fragment>
        <AdminVehicles
          columns={this.columns()}
          sort={sort}
          vehicles={this.data()}
          onUpdate={this.showUpdate}
          onSort={this.onSort}
        />
        <UpdateVehicleModal
          title="Update Vehicle"
          makeLabel="Make"
          modelLabel="Model"
          licensePlateLabel="License Plate"
          labelLabel="Label"
          warehouseLabel="Warehouse"
          disabledLabel="Disabled"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          make={make}
          model={model}
          licensePlate={licensePlate}
          label={label}
          warehouseId={warehouseId}
          disabled={disabled}
          warehouses={this.warehouses()}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.vehicle}))(VehiclesContainer);
