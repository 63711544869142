import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  toDate,
  isEqual,
  format,
  parse,
  getTime,
  setMinutes,
  setHours,
  getMinutes,
  getHours,
  isAfter,
} from 'date-fns';
import $ from 'jquery';

// Alert
import {alertify} from 'doorson-ui';

// Components
import Container from './components/Container';
import Label from './components/Label';
import To from './components/To';
import Input from './components/Input';

// Layout
import validateTimeInput from '../../../layout/lib/validateTimeInput.lib.layout';

class Time extends Component {
  static propTypes = {
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    onStartDateChange: PropTypes.func,
    onEndDateChange: PropTypes.func,
  };

  state = {
    display: null,
    date: null,
  };

  showEdit = (display) => () => {
    this.setState({
      display,
      date: format(toDate(this.props[display]), 'HH:mm'),
    });
  };

  stopEdit = () => this.setState({display: null});

  onChange = ({target: {value: date}}) => this.setState({date: date.trim()});

  onConfirm = () => {
    const {display, date} = this.state;

    const showAlert = () =>
      alertify.warning('Insert valid time. Format: Hours:minutes');

    if (!/^\d{2}:{1}\d{2}$/.test(date)) return showAlert();

    try {
      const dateObject = parse(date, 'HH:mm', new Date());
      if (dateObject === 'Invalid Date') return showAlert();

      const execute = {
        startDate: () => this.onStartDate(dateObject),
        endDate: () => this.onEndDate(dateObject),
        default: () => alert.warning('Unknown action'),
      };

      const exec = execute[display] || execute.default;
      exec();
    } catch (error) {
      showAlert();
    }
  };

  onStartDate = (date) => {
    const {onStartDateChange, startDate: previousDate, endDate} = this.props;
    const previousDateObject = toDate(previousDate);
    const endDateObject = toDate(endDate);
    if (!date) return;
    const newDate = setHours(
      setMinutes(previousDateObject, getMinutes(date)),
      getHours(date)
    );
    if (isEqual(newDate, endDateObject) || isAfter(newDate, endDateObject))
      return alertify.warning('Start date can not be after end date');
    onStartDateChange(getTime(newDate));
    this.stopEdit();
  };

  onEndDate = (date) => {
    const {onEndDateChange, endDate: previousDate, startDate} = this.props;
    const previousDateObject = toDate(previousDate);
    const startDateObject = toDate(startDate);
    if (!date) return;
    const newDate = setHours(
      setMinutes(previousDateObject, getMinutes(date)),
      getHours(date)
    );
    if (isEqual(startDateObject, newDate) || isAfter(startDateObject, newDate))
      return alertify.warning('End date can not be before start date');
    onEndDateChange(getTime(newDate));
    this.stopEdit();
  };

  onKeyDown = (e) => {
    validateTimeInput(e);
    if (e.key === 'Enter') {
      e.preventDefault();
      this.onConfirm();
      return;
    }
  };

  onInput = (input) => $(input).focus();

  render() {
    const {startDate, endDate} = this.props;
    const {display, date} = this.state;
    return (
      <Container>
        {display === 'startDate' ? (
          <Input
            ref={this.onInput}
            value={date}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onConfirm}
          />
        ) : (
          <Label onDoubleClick={this.showEdit('startDate')}>
            {format(toDate(startDate), 'HH:mm')}
          </Label>
        )}
        <To>to</To>
        {display === 'endDate' ? (
          <Input
            ref={this.onInput}
            value={date}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onConfirm}
          />
        ) : (
          <Label onDoubleClick={this.showEdit('endDate')}>
            {format(toDate(endDate), 'HH:mm')}
          </Label>
        )}
      </Container>
    );
  }
}

export default Time;
