import isClosed from './isClosed.lib.maintenance';
import isResolved from './isResolved.lib.maintenance';

// TODO: I18n
const status = (intervention) =>
  isClosed(intervention)
    ? 'Closed'
    : isResolved(intervention)
    ? 'Resolved'
    : 'In Progress';
export default status;
