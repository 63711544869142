import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const getRepairStats = (query = {}) =>
  f3tch('/statistics/repairs')
    .profile(profile())
    .query(query)
    .get();
export default getRepairStats;
