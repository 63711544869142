import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  addDays,
  format,
  isSameDay,
  setHours,
  setMinutes,
  toDate,
  addHours,
  areIntervalsOverlapping,
} from 'date-fns';
import $ from 'jquery';

// Components
import Entry from '../Entry/Entry';
import Wrap from './components/Wrap';
import Container from './components/Container';
import DayLabelContainer from './components/DayLabelContainer';
import DayLabel from './components/DayLabel';
import HoursColumn from './components/HoursColumn';
import Hour from './components/Hour';
import DaysContainer from './components/DaysContainer';
import DaysColumn from './components/DaysColumn';
import DayHour from './components/DayHour';

// Constants
import HOUR_HEIGHT from '../../constants/hourHeight.constant.reminder';

// Lib
// import timeToNumber from '../../lib/timeToNumber.lib.reminder';
import fillArrayWithNumbers from '../../../lib/fillArrayWithNumbers';

class Reminders extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    reminders: PropTypes.array,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    highlight: PropTypes.object,
    updating: PropTypes.array,
    removing: PropTypes.array,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
  };

  state = {
    reminder: null,
  };

  isLoading = (id) =>
    [...this.props.updating, ...this.props.removing].includes(id);

  onReminder = (reminder) => () => this.showReminder(reminder);

  showReminder = (reminder) => {
    if (this.isLoading(reminder.id)) return;
    this.setState({reminder});
  };

  hideReminder = () => {
    const {reminder} = this.state;
    if (!reminder) return;
    this.props.onUpdate(reminder);
    this.setState({reminder: null});
  };

  updateReminder = (reminder) => this.setState({reminder});

  onColumnClick = (date, hour) => async (e) => {
    const {onCreate} = this.props;
    const {reminder: selectedReminder} = this.state;
    if (!!selectedReminder) return;
    const column = $(e.target);
    const y = e.pageY - column.offset().top;
    const determinationLine = HOUR_HEIGHT / 2;
    const minutes = y > determinationLine ? 30 : 0;
    const startDate = setHours(setMinutes(date, minutes), hour);
    const endDate = addHours(startDate, 1);
    const reminder = await onCreate({
      title: 'New Reminder',
      startDate,
      endDate,
    });
    if (!reminder) return;
    this.setState({reminder});
  };

  onDelete = () => {
    const {reminder} = this.state;
    if (!reminder) return;
    this.setState({reminder: null});
    this.props.onDelete(reminder.id);
  };

  onSelectedReminderChange = (reminder) => {
    this.setState({reminder});
  };

  overlapping = (reminder) =>
    [...this.props.reminders].filter(({startDate, endDate}) =>
      areIntervalsOverlapping(
        {start: toDate(reminder.startDate), end: toDate(reminder.endDate)},
        {start: toDate(startDate), end: toDate(endDate)}
      )
    );

  entries = (date, day) =>
    [...this.props.reminders]
      .filter((reminder) => isSameDay(date, toDate(reminder.startDate)))
      .map((currentReminder) => {
        const selectedReminder = this.state.reminder;
        const reminder =
          !!selectedReminder && selectedReminder.id === currentReminder.id
            ? selectedReminder
            : currentReminder;
        return (
          <Entry
            key={reminder.id}
            loading={this.isLoading(reminder.id)}
            reminder={reminder}
            selected={
              !!this.state.reminder && this.state.reminder.id === reminder.id
            }
            overlapping={this.overlapping(reminder)}
            left={day >= 4}
            onChange={this.onSelectedReminderChange}
            onClick={this.onReminder(reminder)}
            onClose={this.hideReminder}
            onDelete={this.onDelete}
          ></Entry>
        );
      });

  renderDayLabel = (day) => {
    const {startDate, highlight} = this.props;
    const actualDay = addDays(startDate, day);
    return (
      <DayLabel
        key={day}
        highlight={isSameDay(highlight, actualDay)}
        height={HOUR_HEIGHT}
      >
        {format(actualDay, 'eee d.M')}
      </DayLabel>
    );
  };

  renderDays = (day) => {
    const {startDate} = this.props;
    const hours = fillArrayWithNumbers(0, 23);
    const actualDay = addDays(startDate, day);
    return (
      <DaysColumn key={day}>
        {hours.map((hour) => (
          <DayHour
            key={hour}
            height={HOUR_HEIGHT}
            onDoubleClick={this.onColumnClick(actualDay, hour)}
          />
        ))}
        {this.entries(actualDay, day)}
      </DaysColumn>
    );
  };

  render() {
    const days = fillArrayWithNumbers(0, 6);
    const hours = fillArrayWithNumbers(0, 23);
    return (
      <Wrap>
        <DayLabelContainer>{days.map(this.renderDayLabel)}</DayLabelContainer>
        <Container>
          <HoursColumn height={HOUR_HEIGHT}>
            {hours.map((hour) => (
              <Hour key={hour} height={HOUR_HEIGHT}>
                {hour}:00
              </Hour>
            ))}
          </HoursColumn>
          <DaysContainer height={HOUR_HEIGHT}>
            {days.map(this.renderDays)}
          </DaysContainer>
        </Container>
      </Wrap>
    );
  }
}

export default Reminders;
