import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Modal, Row} from 'doorson-ui';

const TransferIdentVehicleModal = ({
                                     title,
                                     children,
                                     transferLabel,
                                     visible,
                                     loading,
                                     onClose,
                                     onAction,
                                   }) => (
  <Modal visible={visible} title={title} onClose={onClose} size="small">
    <Row margin>
      <Column>{children}</Column>
    </Row>
    <Row>
      <Column>
        <Button onClick={onAction} disabled={loading} loading={loading}>
          {transferLabel}
        </Button>
      </Column>
    </Row>
  </Modal>
);

TransferIdentVehicleModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  transferLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
};

export default TransferIdentVehicleModal;
