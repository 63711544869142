import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// ReportedFaultsAdminPage
import FaultsAdminPage from '../FaultsAdminPage/FaultsAdminPage';
import routeFaultsAdminPage from '../FaultsAdminPage/route';

// Consts
import page from './page';

const FaultsAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route path={routeFaultsAdminPage()} component={FaultsAdminPage} exact />
    </Switch>
  </SubPage>
);

export default FaultsAdminPages;
