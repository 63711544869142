// Action constants
import {SET_TOKEN} from './constants';

const DEFAULT_STATE = {
  initialized: false,
  token: null,
  user: null,
};

/**
 * @description :: Reducer for authorization
 * @param {Object} state :: The current state
 * @param {String} type :: The type of action
 * @param {Any} data :: The data bind to the action
 * @return {Object} :: New state
 */
const reducer = (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET_TOKEN: {
      return {
        ...state,
        initialized: true,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
