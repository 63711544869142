import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateMaterialTranslation = ({languageCode, translationKey, translation}) =>
  f3tch(`/i18n/materials/${languageCode}/${translationKey}`)
    .profile(profile())
    .body(translation)
    .put();
export default updateMaterialTranslation;
