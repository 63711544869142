import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createInterventionDoorFaultApi = (interventionDoorID, fault) =>
  f3tch(`/intervention-doors/${interventionDoorID}/faults`)
    .profile(profile())
    .body(fault)
    .post();
export default createInterventionDoorFaultApi;
