import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateRepairApi =  (id, repair) =>
  f3tch(`/repairs/${id}`)
    .profile(profile())
    .body(repair)
    .put();
export default updateRepairApi;
