import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Modal, Row} from 'doorson-ui';

const DeleteMaintenanceDoorModal = ({
                                      visible,
                                      loading,
                                      children,
                                      title,
                                      deleteLabel,
                                      onDelete,
                                      onClose,
                                    }) => (
  <Modal title={title} visible={visible} onClose={onClose} size="small">
    <Row margin>
      <Column>{children}</Column>
    </Row>
    <Row>
      <Column>
        <Button theme="red" loading={loading} onClick={onDelete}>
          {deleteLabel}
        </Button>
      </Column>
    </Row>
  </Modal>
);

DeleteMaintenanceDoorModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.node,
  deleteLabel: PropTypes.node,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
};

export default DeleteMaintenanceDoorModal;
