import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';
import url from '../../api/constants/warehouseUrl.const.api';

const deleteWarehouseOrderApi = (id) =>
  f3tch(`/erp/warehouse-order-items/${id}`)
    .profile(profile({url}))
    .delete();
export default deleteWarehouseOrderApi;
