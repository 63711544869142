import styled from 'styled-components';
import {transitions} from 'polished';

const Container = styled.div`
  width: 100%;
  position: relative;
  padding-top: 90px;
  padding-left: ${({open}) => (open ? '280px' : '72px')};
  ${transitions('padding .3s ease-in-out')};
`;

export default Container;
