import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  float: left;
  width: 150px;
  margin: 10px 0;
  padding: 0 10px 0 5px;
  user-select: none;
`;

export default Container;
