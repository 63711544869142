import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import Container from './components/Container';
import Icon from './components/Icon';

const AdminLanguages = ({
  columns,
  sort,
  languages,
  onUpdate,
  onDelete,
  onSort,
}) => (
  <Container>
    <Table columns={columns} sort={sort} onSort={onSort}>
      {(TableRow) =>
        languages.map((language) => (
          <TableRow key={language.code}>
            {(TableCol) => [
              <TableCol smallPadding key="code">
                {language.code}
              </TableCol>,
              <TableCol smallPadding key="name" span={2}>
                {language.name}
              </TableCol>,
              <TableCol smallPadding key="disabled">
                {language.disabled && <i className="mdi mdi-check" />}
              </TableCol>,
              <TableCol smallPadding key="actions">
                <Icon
                  className="mdi mdi-circle-edit-outline"
                  onClick={onUpdate(language)}
                />
                {/* <Icon
                  className="mdi mdi-delete"
                  onClick={onDelete(language.code)}
                /> */}
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
  </Container>
);

AdminLanguages.propTypes = {
  columns: PropTypes.array,
  languages: PropTypes.array,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AdminLanguages;
