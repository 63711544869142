import React from 'react';
import PropTypes from 'prop-types';

// Components
import {InfoDropdown} from 'doorson-ui';
import {Text} from 'doorson-ui';
import {Row, Column} from 'doorson-ui';

import Container from './components/Container';
import Content from './components/Content';
import Space from './components/Space';

const MaintenanceInfo = ({
  incidentStatusLabel,
  incidentCodeLabel,
  invoiceNumberLabel,
  incidentCreatedLabel,
  workIssuedLabel,
  incidentResolvedLabel,

  status,
  code,
  invoiceNumber,
  created,
  issuedBy,
  resolvedBy,
}) => (
  <Container>
    <InfoDropdown>
      <Content>
        <Row>
          <Column size={1 / 3}>
            {incidentStatusLabel}: <Text weight="bold">{status}</Text>
          </Column>
          <Column size={1 / 3}>
            {incidentCodeLabel}: <Text weight="bold">{code}</Text>
          </Column>
          <Column size={1 / 3}>
            {invoiceNumberLabel}: <Text weight="bold">{invoiceNumber}</Text>
          </Column>
        </Row>
        <Space />
        <Row>
          <Column size={1 / 3}>
            {incidentCreatedLabel}: <Text weight="bold">{created}</Text>
          </Column>
          <Column size={1 / 3}>
            {workIssuedLabel}: <Text weight="bold">{issuedBy}</Text>
          </Column>
          <Column size={1 / 3}>
            {incidentResolvedLabel}: <Text weight="bold">{resolvedBy}</Text>
          </Column>
        </Row>
      </Content>
    </InfoDropdown>
  </Container>
);

MaintenanceInfo.propTypes = {
  incidentStatusLabel: PropTypes.node,
  incidentCodeLabel: PropTypes.node,
  invoiceNumberLabel: PropTypes.node,
  incidentCreatedLabel: PropTypes.node,
  workIssuedLabel: PropTypes.node,
  incidentResolvedLabel: PropTypes.node,

  status: PropTypes.string,
  code: PropTypes.string,
  invoiceNumber: PropTypes.string,
  created: PropTypes.string,
  issuedBy: PropTypes.string,
  resolvedBy: PropTypes.string,
};

export default MaintenanceInfo;
