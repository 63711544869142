import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Alert
// Actions
import {
  getMoreServiceItems as getMoreServiceItemsAct,
  getServiceItems as getServiceItemsAct
} from '../../redux/actions';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';

// Libs
// Routes
import serviceItemRoute from '../../pages/ServiceItemPage/route';

// Roles
import ServiceItems from "../../components/ServiceItems/ServiceItems";
import ServiceItemsSearch from "../../components/ServiceItemsSearch/ServiceItemsSearch";
import ServiceItemsHeader from "../../components/ServiceItemsHeader/ServiceItemsHeader";
import {alertify} from "doorson-ui";
import getLabelRefApi from "../../../labelRef/api/getById.api.labelRef";
import parseServerFault from "../../../api/lib/parseServerFault.lib.api";
import QrScanModal from "../../components/QrScanModal/QrScanModal";

class ServiceItemsContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    more: PropTypes.bool,
    search: PropTypes.string,
    serviceItems: PropTypes.array,
    filter: PropTypes.string,
    sort: PropTypes.array,
    user: PropTypes.object,
    users: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    scan: false,
    scanLoading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getServiceItems();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onDistanceSort = async (sort) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => this.getServiceItems({
        sort,
        currentPosition: position.coords.latitude + ':' + position.coords.longitude
      }));
    } else {
      alertify.warn('Unable to get current geo position');
    }
  }

  onSearch = (search) => this.getServiceItems({search});

  onSort = (sort) => {
    if (sort && sort.key === 'distance') {
      this.onDistanceSort(sort);
    } else {
      this.getServiceItems({sort})
    }
  };

  onServiceItem = (serviceItem) =>
    this.props.history.push(serviceItemRoute(serviceItem.id));

  getServiceItems = async (data) =>
    this.props.dispatch(getServiceItemsAct(data));

  onMore = () => this.props.dispatch(getMoreServiceItemsAct());

  serviceItems = () => [...this.props.serviceItems];

  columns = (hasGeo) => [
    {
      key: 'doorSerial',
      label: 'Serial number',
      sortable: false,
    },
    {
      key: 'doorType',
      label: 'Door type',
      sortable: false,
    },
    {
      key: 'customer',
      label: 'Customer',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'customerBranch',
      label: 'Customer Branch',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'indoorLocation',
      label: 'Indoor Location',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'lastMaintenanceDate',
      label: 'Last Maintenance',
      sortable: true,
    },
    {
      key: 'distance',
      label: 'Distance',
      sortable: true,
    }
  ];

  scan = (scan) => this.setState({scan, scanLoading: scan && this.state.scanLoading})

  onScan = async (content) => {
    try {
      if (!content) return;
      this.setState({scanLoading: true})

      const friendlyId = new URL(content).pathname.split('/').filter(Boolean).pop()
      const labelRef = await getLabelRefApi(friendlyId);
      if (labelRef.context !== 'SERVICE_ITEM') {
        alertify.error('This QR code is not assigned to the door.');
      }
      const doorId = labelRef.refId;
      if (!doorId) {
        alertify.error('Door cannot be found');
      } else {
        alertify.info('Found door with QR code');
        this.onServiceItem({id: doorId})
      }
    } catch (error) {
      console.error(error);
      alertify.error('Unable to find door using QR code. ' + parseServerFault(error));
    }
    this.scan(false);
  }

  renderHeader = () => (
    <Fragment>
      <ServiceItemsHeader scanLabel={this.state.scan ? "Scanning..." : "Search by QR"}
                          scan={this.scan.bind(this, !this.state.scan)}
                          disabled={this.state.scan}>Door catalog</ServiceItemsHeader>
    </Fragment>
  );

  render() {
    const {loading, search, sort, scroller, more} = this.props;
    const {currentPosition} = this.state;
    // TODO I18N
    return (
      <Fragment>
        <ServiceItemsSearch search={search} onSearch={this.onSearch}/>
        <ContentLayout header={this.renderHeader()}>
          <ServiceItems
            loading={loading}
            more={more}
            scroller={scroller}
            sort={Array.isArray(sort) ? sort[0] : sort}
            columns={this.columns(currentPosition)}
            serviceItems={this.serviceItems()}
            onServiceItem={this.onServiceItem}
            onSort={this.onSort}
            onAction={this.onAction}
            onMore={this.onMore}
          />
        </ContentLayout>
        <QrScanModal
          onScan={this.onScan}
          loading={this.state.scanLoading}
          onClose={this.scan.bind(this, false)}
          visible={this.state.scan}/>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  scroller: state.layout.scroller,
  ...state.serviceItem,
}))(withRouter(ServiceItemsContainer));
