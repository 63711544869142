import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import DoorInfoContainer from '../../../interventionDoor/container/DoorInfoContainer/DoorInfoContainer';

// TODO: I18n
const InterventionDoorPage = ({intervention, dispatch, user, match}) => (
  <SubPage page={match.path}>
    <DoorInfoContainer dispatch={dispatch} user={user} intervention={intervention} />
  </SubPage>
);

InterventionDoorPage.propTypes = {
  intervention: PropTypes.object,
  dispatch: PropTypes.func,
  user: PropTypes.object,
};

export default connect((state) => ({
  intervention: state.intervention.intervention,
  user: state.auth.user
}))(withRouter(InterventionDoorPage));
