import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// Components
import Container from './components/Container';
import Content from './components/Content';
import Priority from './components/Priority';
import Icon from './components/Icon';
import DropDown from './components/DropDown';
import PriorityItem from './components/PriorityItem';
import PriorityLabel from './components/PriorityLabel';

class EntryPriorities extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    color: PropTypes.string,
    priorities: PropTypes.array,
    onPriority: PropTypes.func,
    onShow: PropTypes.func,
    onClose: PropTypes.func,
  };

  state = {
    hover: null,
  };

  hover = (hover) => () => this.setState({hover});

  blur = () => this.setState({hover: null});

  handleClickOutside = () => {
    const {visible, onClose} = this.props;
    if (!visible) return;
    onClose();
    this.setState({hover: null});
  };

  render() {
    const {visible, color, priorities, onPriority, onShow} = this.props;
    const {hover} = this.state;
    return (
      <Container>
        <Content onClick={onShow}>
          <Priority color={color} />
          <Icon className="mdi mdi-chevron-down" selected={visible} />
        </Content>
        {visible && (
          <DropDown>
            {priorities.map((priority) => (
              <PriorityItem
                key={priority.value}
                hovered={hover === priority.value}
                onClick={onPriority(priority.value)}
                onMouseEnter={this.hover(priority.value)}
                onMouseLeave={this.blur}
              >
                <Priority color={priority.color} />
                <PriorityLabel>{priority.label}</PriorityLabel>
              </PriorityItem>
            ))}
          </DropDown>
        )}
      </Container>
    );
  }
}

export default onClickOutside(EntryPriorities);
