import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import MaintenanceCustomerInfo from '../../../customer/components/MaintenanceCustomerInfo/MaintenanceCustomerInfo';

// MaintenanceItems
import itemsRoute from '../../pages/MaintenanceItemsPage/route';

class MaintenanceCustomerContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
  };

  render() {
    const {maintenance} = this.props;
    return (
      <MaintenanceCustomerInfo
        title="Customer Info"
        registrationNumberLabel="Registration number"
        firstNameLabel="First name"
        lastNameLabel="Last name"
        companyLabel="Company name"
        addressLabel="Address"
        zipLabel="ZIP Code"
        cityLabel="City"
        phoneLabel="Telephone"
        emailLabel="Email"
        nextLabel="Next"
        type={maintenance.customer.type}
        registrationNumber={maintenance.customer.registrationNumber}
        company={maintenance.customer.company}
        firstName={maintenance.customer.firstName}
        lastName={maintenance.customer.lastName}
        address={maintenance.customer.location.address}
        zip={maintenance.customer.location.zip}
        city={maintenance.customer.location.city}
        phone={maintenance.customer.phone || ''}
        email={maintenance.customer.email || ''}
        nextUrl={itemsRoute(maintenance.id)}
      />
    );
  }
}

export default MaintenanceCustomerContainer;
