export const stringToMinutes = (dateStr) => {
  if (!dateStr || dateStr.length < 3 || dateStr.length > 5) return null;
  let split = dateStr.split(":");
  if (split.length !== 2 || split[0].length > 2 || split[1].length > 2) return null;
  return parseInt(split[0]) * 60 + parseInt(split[1]);
};

export const minutesToString = (minutes) => {
  if (!minutes) return '00:00';
  const num = parseInt(minutes);
  if (!num || num < 0) return '00:00';
  const hours = ('' + Math.floor(num / 60)).padStart(2, '0');
  const mins = ('' + (num % 60)).padStart(2, '0');
  return hours + ":" + mins;
};
