import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Route, Switch} from 'react-router-dom';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import SpecifyInterventionSubpageContainer from '../../containers/SpecifyInterventionSubpageContainer/SpecifyInterventionSubpageContainer';
import InterventionContainer from '../../containers/InterventionContainer/InterventionContainer';

// Consts
import page from './page';

// InterventionsCustomer
import InterventionCustomerPage from '../InterventionCustomerPage/InterventionCustomerPage';
import routeInterventionCustomer from '../InterventionCustomerPage/route';

// InterventionsDoor
import InterventionDoorPage from '../InterventionDoorPage/InterventionDoorPage';
import routeInterventionDoor from '../InterventionDoorPage/route';

// InterventionsAssign
import InterventionAssignPage from '../InterventionAssignPage/InterventionAssignPage';
import routeInterventionAssign from '../InterventionAssignPage/route';

// InterventionsWorkLog
import InterventionWorkLogPage from '../InterventionWorkLogPage/InterventionWorkLogPage';
import routeInterventionWorkLog from '../InterventionWorkLogPage/route';

// InterventionsConfirmation
import InterventionConfirmationPage from '../InterventionConfirmationPage/InterventionConfirmationPage';
import routeInterventionConfirmation from '../InterventionConfirmationPage/route';

// InterventionsAttachments
import InterventionAttachmentsPage from '../InterventionAttachmentsPage/InterventionAttachmentsPage';
import routeInterventionAttachments from '../InterventionAttachmentsPage/route';

// InterventionsAttachments
import InterventionActionsPage from '../InterventionActionsPage/InterventionActionsPage';
import routeInterventionActions from '../InterventionActionsPage/route';

const InterventionPage = ({match}) => (
  <Page page={page}>
    <InterventionContainer interventionID={match.params.id}>
      <Switch>
        <Route
          path={routeInterventionCustomer()}
          component={InterventionCustomerPage}
          exact
        />
        <Route
          path={routeInterventionDoor()}
          component={InterventionDoorPage}
          exact
        />
        <Route
          path={routeInterventionAssign()}
          component={InterventionAssignPage}
          exact
        />
        <Route
          path={routeInterventionWorkLog()}
          component={InterventionWorkLogPage}
          exact
        />
        <Route
          path={routeInterventionConfirmation()}
          component={InterventionConfirmationPage}
          exact
        />
        <Route
          path={routeInterventionAttachments()}
          component={InterventionAttachmentsPage}
          exact
        />
        <Route
          path={routeInterventionActions()}
          component={InterventionActionsPage}
          exact
        />
        <Route component={SpecifyInterventionSubpageContainer} />
      </Switch>
    </InterventionContainer>
  </Page>
);

InterventionPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(InterventionPage);
