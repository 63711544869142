import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.fault';
import deleteApi from '../../api/delete.api.fault';

// Components
import AdminFaults from '../../components/AdminFaults/AdminFaults';
import UpdateFaultModal from '../../components/UpdateFaultModal/UpdateFaultModal';
import DeleteFaultModal from '../../components/DeleteFaultModal/DeleteFaultModal';

// Lib
import sortByString from '../../../lib/sortByString';

class FaultsContainer extends Component {
  static propTypes = {
    faults: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    updateID: null,
    name: '',
    version: 0,
    removeID: null,
    sort: {key: 'name', direction: 'asc'},
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'name',
      label: 'Name',
      span: 3,
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  showUpdate = (fault) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: fault.id,
      name: fault.name,
      version: fault.version,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSort = (sort) => this.setState({sort});

  update = async () => {
    const {loading, updateID, name, version} = this.state;
    if (loading || !updateID) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const fault = await updateApi(updateID, {name, version});
      const {dispatch, faults} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          faults: [...faults].map((r) => (r.id === fault.id ? fault : r)),
        })
      );
    } catch (error) {
      alertify.error('Fault could not be updated');
      this.setState({loading: false});
    }
  };

  remove = async () => {
    const {loading, removeID} = this.state;
    if (loading || !removeID) return;

    this.setState({loading: true});

    try {
      await deleteApi(removeID);
      const {dispatch, faults} = this.props;
      this.setState({loading: false, removeID: null});
      dispatch(setAct({faults: [...faults].filter((r) => r.id !== removeID)}));
    } catch (error) {
      alertify.error('Fault could not be removed');
      this.setState({loading: false});
    }
  };

  data = () => {
    const {faults} = this.props;
    const {sort} = this.state;
    return !!sort ? [...faults].sort(sortByString(sort)) : faults;
  };

  render() {
    const {loading, sort, updateID, name, removeID} = this.state;
    return (
      <Fragment>
        <AdminFaults
          columns={this.columns()}
          sort={sort}
          faults={this.data()}
          onUpdate={this.showUpdate}
          onDelete={this.showDelete}
          onSort={this.onSort}
        />
        <UpdateFaultModal
          title="Update Fault"
          nameLabel="Name"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          name={name}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
        <DeleteFaultModal
          title="Delete Fault"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this fault?
        </DeleteFaultModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.fault}))(FaultsContainer);
