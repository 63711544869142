import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import getWarehouseName from '../../lib/name.lib.warehouse';

// Actions
import listWarehousesApi from "../../api/get.api.warehouse";
import ContentLayout from "../../../layout/components/ContentLayout/ContentLayout";
import InventoryOption from "../../../inventory/components/InventoryOption/InventoryOption";
import WarehousesHeader from "../../components/WarehousesHeader/WarehousesHeader";
import WarehousesLoader from "../../components/WarehousesLoader/WarehousesLoader";
import warehouseRoute from "../../pages/WarehousePage/route";

import warehouseIcon from '../../assets/warehouse-icon.png';
import warehouseVehicleIcon from '../../assets/warehouse-vehicle-icon.png';
import {alertify} from "doorson-ui";

// Alerts

class WarehousesContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
    warehouses: [],
  };

  componentDidMount() {
    this.getWarehouses();
  }

  getWarehouses = async () => {
    try {
      const warehouses = await listWarehousesApi({offset: 0, limit: 300});
      this.setState({warehouses, loading: false});
    } catch (error) {
      alertify.error('Unable to load warehouses');
      this.setState({loading: false});
    }
  }

  items = () => {
    const {warehouses} = this.state;
    const {vehicles} = this.props;
    return [...warehouses].map((wh) => ({
      id: wh.id,
      title: getWarehouseName({warehouse: wh, vehicles}),
      image: wh.type === 'DEPOT' ? warehouseIcon : warehouseVehicleIcon
    }));
  };

  onItem = (item) => () => this.props.history.push(warehouseRoute(item.id));

  renderHeader = () => (
    <Fragment>
      <WarehousesHeader>Select warehouse</WarehousesHeader>
    </Fragment>
  );

  render() {
    const {loading} = this.state;

    return loading ? (
      <WarehousesLoader/>
    ) : (
      <Fragment>
        <ContentLayout header={this.renderHeader()}>
          <InventoryOption
            items={this.items()}
            onItem={this.onItem}
          />
        </ContentLayout>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  vehicles: state.vehicle.vehicles
}))(withRouter(WarehousesContainer));
