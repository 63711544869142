import React, {Component} from 'react';

// Components
import Container from './components/Container';
import Loader from './components/Loader';
import Image from './components/Image';

// Libs
import preview from '../../../file/lib/preview.lib.file';

class FullScreenImage extends Component {

  render() {
    const {loading, image, onClose} = this.props;
    return (
      <Container onClick={onClose}>
        {loading && (
          <Loader>
            <i className='mdi mdi-loading mdi-spin' />
          </Loader>
        )}
        {!!image && (
          <Image src={preview(image.preview)} />
        )}
      </Container>
    );
  }
}

export default FullScreenImage;
