import styled from 'styled-components';

// colors
import {orange} from 'doorson-ui/lib/style/colors';

const Icon = styled.i`
  padding-left: 12px;
  font-size: 24px;

  :hover {
    color: ${orange};
  }
`;

export default Icon;
