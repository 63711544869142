import f3tch from 'f3tch';
import url from '../../api/constants/warehouseUrl.const.api';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const listWarehousesApi = (query = {}) =>
  f3tch('/erp/warehouses')
    .profile(profile({url}))
    .query(query)
    .get();
export default listWarehousesApi;
