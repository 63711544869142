import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey, greyer, darkerGrey, orange} = colors;

const DayLabel = styled.div`
  width: calc(100% / 7);
  height: ${({height}) => `${height}px`};
  min-width: calc(100% / 7);
  max-width: calc(100% / 7);
  border-style: none none solid solid;
  border-width: 1px;
  border-color: ${greyer};
  background: ${grey};
  color: ${({highlight}) => (highlight ? orange : darkerGrey)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export default DayLabel;
