// Action constants
import {SET} from './constants';

// Constants
import MENU_KEY from '../constants/menuKey.const.layout';

// Libs
import * as localStorage from '../../lib/localStorage';

// Helpers
const initialNavigationOpen = localStorage.get(MENU_KEY);

const DEFAULT_STATE = {
  navigationOpen:
    initialNavigationOpen === null ? false : initialNavigationOpen === 'true',
  page: null,
  subpage: null,
  scroller: null,
};

const reducer =  (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
