import styled from 'styled-components';
import {Link} from 'react-router-dom';

const DocumentIconContainer = styled(Link)`
  display: block;
  cursor: pointer;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 23px;
`;

export default DocumentIconContainer;
