import {isDate, parse, format as formatDate} from 'date-fns';

import type from '../type';

const format = (value) =>
  !value ? '' : formatDate(isDate(value) ? value : parse(value), 'yyyy-MM-dd');

const validate = (value) => /^\d{4}-\d{2}-\d{2}$/.test(value);

export default type({type: 'date', format, validate});
