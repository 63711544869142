import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {v4} from 'uuid';
import {withRouter} from 'react-router-dom';

// Api
import updateDoorApi from '../../api/update.api.maintenanceDoor';
import updateCheckApi from '../../../maintenanceDoorCheck/api/update.api.maintenanceDoorCheck';
import createMaterialApi from '../../api/createMaterial.api.maintenanceDoor';
import updateMaterialApi from '../../api/updateMaterial.api.maintenanceDoor';
import deleteMaterialApi from '../../api/deleteMaterial.api.maintenanceDoor';
import createRepairApi from '../../api/createRepair.api.maintenanceDoor';
import updateRepairApi from '../../api/updateRepair.api.maintenanceDoor';
import deleteRepairApi from '../../api/deleteRepair.api.maintenanceDoor';

// Actions
import {set as setAct} from '../../../maintenanceDoor/redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Libs
import isAssigned from '../../../maintenance/lib/isAssigned.lib.maintenance';
import isResolved from '../../lib/isResolved.lib.maintenanceDoor';
import isDoorComplete from '../../lib/isDoorComplete.lib.maintenanceDoor';

// Components
import WorkingLog from '../../components/Workinglog/Workinglog';

// Routes
import confirmationRoute from '../../pages/MaintenanceDoorConfirmationPage/route';
import listDoorMaterials from '../../../door/api/listMaterial.api.door';
import {dateTime as dateTimeType} from '../../../types';
import parseServerFault from '../../../api/lib/parseServerFault.lib.api';
import createLabelRefApi from '../../../labelRef/api/create.api.labelRef';
import QrScanModal from '../../../door/components/QrScanModal/QrScanModal';

class WorklogContainer extends Component {
  static propTypes = {
    maintenanceDoor: PropTypes.object,
    repairs: PropTypes.array,
    materials: PropTypes.array,
    user: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    scan: false,
    scanLoading: false,
    door: null,
    updatingChecks: [],
    updatingRepairs: [],
    updatingMaterials: [],

    doorMaterialHistory: [],
  };

  componentDidMount = async () => {
    const {maintenanceDoor} = this.props;
    this.mounted = true;
    const door = {
      ...this.props.maintenanceDoor,
      noMaterials:
        isDoorComplete(maintenanceDoor) && !maintenanceDoor.materials.length,
      noRepairs:
        isDoorComplete(maintenanceDoor) && !maintenanceDoor.repairs.length,
      error: {},
    };
    this.setState({door});

    await this.getDoorMaterialHistory((door.door || {}).id);
  };

  componentWillUnmount() {
    this.mounted = false;
    this.syncMaintenanceDoorOnUnmount();
  }

  syncMaintenanceDoorOnUnmount = () => {
    const {door} = this.state;

    if (door.id) {
      this.syncMaintenanceDoor();
    }
  };

  syncMaintenanceDoor = () => {
    const {dispatch} = this.props;
    const {error, noMaterials, noRepairs, ...door} = this.state.door;
    dispatch(setAct({maintenanceDoor: {...door}}));
  };

  getDoorMaterialHistory = async (doorId) => {
    const doorMaterialHistory = !!doorId ? await listDoorMaterials(doorId) : [];
    this.setState({doorMaterialHistory});
  };

  onChange = (key) => (value) => {
    const {loading, door} = this.state;
    if (loading) return;
    this.setState({
      door: {
        ...door,
        [key]: value,
        error: Object.entries(door.error).reduce(
          (combined, [errorKey, value]) =>
            key === errorKey ? combined : {...combined, [errorKey]: value},
          {}
        ),
      },
    });
  };

  onCheck = (id) => async (status) => {
    const {loading, door, updatingChecks} = this.state;
    const currentCheck = [...door.checks].find((check) => check.id === id);
    if (loading || !currentCheck || updatingChecks.includes(id)) return;

    const undoUpdating = () =>
      [...this.state.updatingChecks].filter((uc) => uc !== id);

    const checks = [...door.checks].map((check) =>
      check.id === id ? {...check, status} : check
    );
    this.setState({
      updatingChecks: [...updatingChecks, id],
      door: {
        ...door,
        checks: checks,
      },
    });

    try {
      const newCheck = await updateCheckApi(currentCheck.id, {
        status,
        version: currentCheck.version,
      });
      this.setState({
        door: {
          ...this.state.door,
          checks: [...this.state.door.checks].map((check) =>
            check.id === newCheck.id ? newCheck : check
          ),
        },
        updatingChecks: undoUpdating(),
      });
    } catch (error) {
      this.setState({
        updatingChecks: undoUpdating(),
        door: {
          ...this.state.door,
          checks: [...this.state.door.checks].map((check) =>
            check.id === currentCheck.id ? currentCheck : check
          ),
        },
      });
    }
  };

  availableRepairs = () => {
    const {repairs} = this.props;
    const {door} = this.state;
    const selectedRepairs = [...door.repairs]
      .filter(({repair}) => !!repair)
      .map(({repair: {id}}) => id);
    return [...repairs].filter(
      (repair) => !selectedRepairs.includes(repair.id)
    );
  };

  addOption = (option, object) => ({
    ...this.state.door,
    [option]: [...this.state.door[option], object],
  });

  switchOption = (option, oldID, object) => ({
    ...this.state.door,
    [option]: [...this.state.door[option]].map((opt) =>
      opt.id === oldID ? object : opt
    ),
  });

  removeOption = (option, objectID) => ({
    ...this.state.door,
    [option]: [...this.state.door[option]].filter(({id}) => id !== objectID),
  });

  // TODO: I18n
  repairOptions = () => [
    ...[...this.availableRepairs()].map((repair) => ({
      value: repair.id,
      label: repair.description,
    })),
    {
      value: 'other',
      label: 'Other',
    },
  ];

  selectedRepairs = () => {
    const {door} = this.state;
    return [...door.repairs].map(
      ({id, description, repair, updated = false}) => ({
        value: id,
        disabled: !!repair,
        label: !!repair && !updated ? repair.description : description,
      })
    );
  };

  addRepair = async (repairID) => {
    // TODO: I18n
    const {loading, updatingRepairs, door} = this.state;
    const repair = [...this.availableRepairs()].find((r) => r.id === repairID);
    if (
      loading ||
      (repairID !== 'other' && !repair) ||
      updatingRepairs.includes(repairID)
    )
      return;

    const doorRepairID = v4();

    const doorRepair =
      repairID === 'other'
        ? {
            description: 'other',
          }
        : {
            repairId: repair.id,
          };

    await this.setState({
      updatingRepairs: [...this.state.updatingRepairs, doorRepairID],
      door: this.addOption('repairs', {
        id: doorRepairID,
        description: doorRepair.description || repair.description,
        repair,
      }),
    });

    try {
      const newRepair = await createRepairApi(door.id, doorRepair);
      if (!this.mounted) return;
      await this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== doorRepairID
        ),
        door: this.switchOption('repairs', doorRepairID, newRepair),
      });
      this.syncMaintenanceDoor();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== doorRepairID
        ),
        door: this.removeOption('repairs', doorRepairID),
      });
    }
  };

  changeRepair = (repairID, key) => (value) => {
    const {loading, updatingRepairs} = this.state;
    if (loading || updatingRepairs.includes(repairID)) return;
    this.setState({
      door: {
        ...this.state.door,
        repairs: [...this.state.door.repairs].map((repair) =>
          repair.id === repairID
            ? {...repair, [key]: value, updated: true}
            : repair
        ),
      },
    });
  };

  updateRepair = (repairID) => async () => {
    // TODO: I18n
    const {loading, updatingRepairs, door} = this.state;
    const rawRepair = door.repairs.find((r) => r.id === repairID);
    if (loading || updatingRepairs.includes(repairID) || !rawRepair) return;

    const {description, repair: repairObj, updated} = rawRepair;

    const repair = {
      description: updated ? description : null,
      repairId: updated || !repairObj ? null : repairObj.id,
      version: rawRepair.version,
    };

    this.setState({
      updatingRepairs: [...this.state.updatingRepairs, repairID],
    });

    try {
      const newRepair = await updateRepairApi(door.id, repairID, repair);
      if (!this.mounted) return;
      await this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== repairID
        ),
        door: this.switchOption('repairs', repairID, newRepair),
      });
      this.syncMaintenanceDoor();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== repairID
        ),
      });
    }
  };

  removeRepair = (repairID) => async () => {
    // TODO: I18n
    const {loading, updatingRepairs, door} = this.state;
    if (loading || updatingRepairs.includes(repairID)) return;

    this.setState({
      updatingRepairs: [...this.state.updatingRepairs, repairID],
    });

    try {
      await deleteRepairApi(door.id, repairID);
      if (!this.mounted) return;
      await this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== repairID
        ),
        door: this.removeOption('repairs', repairID),
      });
      this.syncMaintenanceDoor();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== repairID
        ),
      });
    }
  };

  availableMaterials = () => {
    const {materials} = this.props;
    const {door} = this.state;
    const selectedMaterials = [...door.materials]
      .filter(({material}) => !!material)
      .map(({material: {id}}) => id);
    return [...materials].filter(
      (material) => !selectedMaterials.includes(material.id)
    );
  };

  // TODO: I18n
  materialOptions = () => [
    ...[...this.availableMaterials()].map((material) => ({
      value: material.id,
      label: material.name,
    })),
    {
      value: 'other',
      label: 'Other',
    },
  ];

  warrantyTitle = (material) => {
    const {doorMaterialHistory} = this.state;
    const {maintenance} = this.props;

    if (!material) return '';

    const materialHist = [...doorMaterialHistory]
      .filter((d) => d.maintenanceId !== maintenance.id)
      .filter((d) => d.materialId === material.id)
      .sort((a, b) => (a.date > b.date ? -1 : 1));
    const txt = !!materialHist.find(
      (d) => !!d.warrantyTill && d.warrantyTill > Date.now()
    )
      ? 'warranty still valid from'
      : 'last change ';

    return !!materialHist.length
      ? ' (' + txt + ' ' + dateTimeType(materialHist[0].date).format() + ')'
      : '';
  };

  selectedMaterials = () => {
    const {door} = this.state;
    return [...door.materials].map(
      ({id, name, quantity, material, booked, updated = false}) => ({
        value: id,
        label:
          (!!material && !updated ? material.name : name) +
          this.warrantyTitle(material),
        quantity,
        unit: !!material ? material.unit : 'pc',
        guarantee: !!material ? !!material.warrantyDuration : false,
        booked,
      })
    );
  };

  addMaterial = async (materialID) => {
    // TODO: I18n
    const {loading, updatingMaterials, door} = this.state;
    const material = [...this.availableMaterials()].find(
      (r) => r.id === materialID
    );
    if (
      loading ||
      (materialID !== 'other' && !material) ||
      updatingMaterials.includes(materialID)
    )
      return;

    const doorMaterialID = v4();

    const doorMaterial =
      materialID === 'other'
        ? {
            name: 'other',
            quantity: 1,
          }
        : {
            quantity: 1,
            materialId: material.id,
          };

    await this.setState({
      updatingMaterials: [...this.state.updatingMaterials, doorMaterialID],
      door: this.addOption('materials', {
        id: doorMaterialID,
        name: doorMaterial.name || material.name,
        quantity: 1,
        material,
      }),
    });

    try {
      const newMaterial = await createMaterialApi(door.id, doorMaterial);
      if (!this.mounted) return;
      await this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== doorMaterialID
        ),
        door: this.switchOption('materials', doorMaterialID, newMaterial),
      });
      this.syncMaintenanceDoor();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== doorMaterialID
        ),
        door: this.removeOption('materials', doorMaterialID),
      });
    }
  };

  changeMaterial = (materialID, key) => (value) => {
    const {loading, updatingMaterials} = this.state;
    if (loading || updatingMaterials.includes(materialID)) return;
    this.setState({
      door: {
        ...this.state.door,
        materials: [...this.state.door.materials].map((material) =>
          material.id === materialID
            ? {
                ...material,
                [key]:
                  key === 'name' && !!material.material ? material[key] : value,
                updated:
                  key === 'name' && !material.material
                    ? true
                    : material.updated,
              }
            : material
        ),
      },
    });
  };

  updateMaterial = (materialID) => async () => {
    // TODO: I18n
    const {loading, updatingMaterials, door} = this.state;
    const rawMaterial = door.materials.find((r) => r.id === materialID);
    if (loading || updatingMaterials.includes(materialID) || !rawMaterial)
      return;

    const {name, quantity, material: materialObj, updated} = rawMaterial;

    const material = {
      name: updated || updated === undefined ? name : null,
      quantity,
      materialId: updated || !materialObj ? null : materialObj.id,
      version: rawMaterial.version,
    };

    this.setState({
      updatingMaterials: [...this.state.updatingMaterials, materialID],
    });

    try {
      const newMaterial = await updateMaterialApi(
        door.id,
        materialID,
        material
      );
      if (!this.mounted) return;
      await this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== materialID
        ),
        door: this.switchOption('materials', materialID, newMaterial),
      });
      this.syncMaintenanceDoor();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== materialID
        ),
      });
    }
  };

  removeMaterial = (materialID) => async () => {
    // TODO: I18n
    const {loading, updatingMaterials, door} = this.state;
    if (loading || updatingMaterials.includes(materialID)) return;

    this.setState({
      updatingMaterials: [...this.state.updatingMaterials, materialID],
    });

    try {
      await deleteMaterialApi(door.id, materialID);
      if (!this.mounted) return;
      await this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== materialID
        ),
        door: this.removeOption('materials', materialID),
      });
      this.syncMaintenanceDoor();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== materialID
        ),
      });
    }
  };

  onDoor = (door) => this.setState({door});

  save = async () => {
    // TODO: I18n
    const {history, maintenanceDoor, maintenance} = this.props;
    const {loading, door: rawDoor} = this.state;
    if (loading) return;

    const {
      doorSerial,
      electronicsSerial,
      motorSerial,
      repairs,
      materials,
      findingsAndRemarks,
      customerRemarks,
      replacementNeeded,
      workingProperly,
      prepareOffer,
      noMaterials,
      noRepairs,
      checks,
    } = rawDoor;

    const error = {};

    if (
      !checks.every(({status}) =>
        ['acceptable', 'unacceptable', 'notApplicable'].includes(status)
      )
    )
      return alertify.warning('Go through all the checks');
    if (!noMaterials && !materials.length) error.material = 'Select an option';
    if (!noRepairs && !repairs.length) error.repair = 'Select repair';

    if (!!Object.keys(error).length)
      return this.setState({
        door: {...rawDoor, error},
      });

    const door = {
      doorSerial,
      electronicsSerial,
      motorSerial,
      findingsAndRemarks,
      customerRemarks,
      replacementNeeded,
      workingProperly,
      prepareOffer,
      transportLocationId: !!rawDoor.transportLocation
        ? rawDoor.transportLocation.id
        : rawDoor.transportLocation,
      transportDestination: rawDoor.transportDestination,
      signatoryFirstName: rawDoor.signatoryFirstName,
      signatoryLastName: rawDoor.signatoryLastName,
      version: rawDoor.version,
    };

    this.setState({loading: true});

    try {
      const newDoor = await updateDoorApi(rawDoor.id, door, {rel: '*'});
      if (!this.mounted) return;
      await this.setState({door: {...rawDoor, ...door, ...newDoor, error: {}}});
      this.syncMaintenanceDoor();
      if (isDoorComplete(newDoor))
        return history.push(
          confirmationRoute(maintenance.id, maintenanceDoor.id)
        );
      this.setState({loading: false});
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Could not save. Try again.');
      this.setState({loading: false});
    }
  };

  scan = (scan) =>
    this.setState({scan, scanLoading: scan && this.state.scanLoading});

  onScan = async (content) => {
    const {door} = this.state;
    const doorId = ((door || {}).door || {}).id;

    try {
      if (!content || !doorId || this.state.scanLoading) return;
      this.setState({scanLoading: true});

      const friendlyId = new URL(content).pathname
        .split('/')
        .filter(Boolean)
        .pop();
      const labelRef = {
        friendlyId,
        refId: doorId,
        context: 'SERVICE_ITEM',
      };

      await createLabelRefApi(labelRef);
      alertify.info('Label created. Stick it to place to be scanned');
    } catch (error) {
      console.error(error);
      alertify.error('Unable to register QR code. ' + parseServerFault(error));
    }

    this.scan(false);
  };

  render() {
    // TODO: I18n
    const {maintenance, maintenanceDoor, user} = this.props;
    const {
      loading,
      door,
      updatingMaterials,
      updatingRepairs,
      scan,
      scanLoading,
    } = this.state;
    return !door ? null : (
      <Fragment>
        <WorkingLog
          doorSerialLabel="Door serial number"
          electronicsSerialLabel="Electronic serial number"
          motorSerialLabel="Motor serial number"
          repairDescriptionLabel="Repair description"
          replacedMaterialLabel="Required / replaced material"
          findingsRemarksLabel="Findings and remarks by technician"
          customerRemarksLabel="Remarks by Customer"
          replacementNeededLabel="Replacement needed"
          doorsWorkingLabel="Doors are working properly"
          prepareOfferLabel="Prepare offer for customer"
          buttonLabel="Save and proceed"
          noMaterialsLabel="No required materials"
          noRepairsLabel="No additional repairs"
          maintenanceChecksLabel="Maintenance Checks"
          scanLabel={scan ? 'Scanning...' : 'Scan & stick QR'}
          scan={scan}
          toggleScan={this.scan}
          loading={loading}
          resolved={isResolved(maintenanceDoor)}
          assigned={isAssigned(user, maintenance)}
          door={{...door}}
          repairs={this.repairOptions()}
          selectedRepairs={this.selectedRepairs()}
          materials={this.materialOptions()}
          selectedMaterials={this.selectedMaterials()}
          updatingRepairs={updatingRepairs}
          updatingMaterials={updatingMaterials}
          onChange={this.onChange}
          onCheck={this.onCheck}
          addRepair={this.addRepair}
          changeRepair={this.changeRepair}
          updateRepair={this.updateRepair}
          removeRepair={this.removeRepair}
          addMaterial={this.addMaterial}
          changeMaterial={this.changeMaterial}
          updateMaterial={this.updateMaterial}
          removeMaterial={this.removeMaterial}
          onDoor={this.onDoor}
          onSave={this.save}
        />
        <QrScanModal
          loading={scanLoading}
          onScan={this.onScan}
          onClose={this.scan.bind(this, false)}
          visible={!!scan}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
  maintenanceDoor: state.maintenanceDoor.maintenanceDoor,
  materials: state.material.materials,
  repairs: state.repair.repairs,
  user: state.auth.user,
}))(withRouter(WorklogContainer));
