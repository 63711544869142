import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Row} from 'doorson-ui';

import Container from './components/Container';
import Box from './components/Box';
import ButtonRow from './components/ButtonRow';
import ButtonContainer from './components/ButtonContainer';

const MaintenanceDoorActions = ({
  loading,
  error,

  //isSuperUser
  deleteLabel,
  onDelete,
}) => (
  <Container>
    <Box>
      <Row>
        <Column size={1 / 2}></Column>
        <Column size={1 / 2}>
          <ButtonRow>
            <ButtonContainer>
              <Button theme="red" preIcon="delete" onClick={onDelete}>
                {deleteLabel}
              </Button>
            </ButtonContainer>
          </ButtonRow>
        </Column>
      </Row>
    </Box>
  </Container>
);

MaintenanceDoorActions.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  isSuperUser: PropTypes.bool,
  deleteLabel: PropTypes.string,
  onDelete: PropTypes.func,
};

export default MaintenanceDoorActions;
