import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.maintenanceCheck';
import deleteApi from '../../api/delete.api.maintenanceCheck';

// Components
import AdminMaintenanceChecks from '../../components/AdminMaintenanceChecks/AdminMaintenanceChecks';
import UpdateMaintenanceCheckModal from '../../components/UpdateMaintenanceCheckModal/UpdateMaintenanceCheckModal';
import DeleteMaintenanceCheckModal from '../../components/DeleteMaintenanceCheckModal/DeleteMaintenanceCheckModal';

// Lib
import sortByString from '../../../lib/sortByString';

class MaintenanceChecksContainer extends Component {
  static propTypes = {
    maintenanceChecks: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    updateID: null,
    checkItem: '',
    action: '',
    version: 0,
    removeID: null,
    sort: {key: 'checkItem', direction: 'asc'},
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'checkItem',
      label: 'Label',
      span: 3,
      sortable: true,
    },
    {
      key: 'action',
      label: 'Action',
      span: 2,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  showUpdate = (maintenanceCheck) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: maintenanceCheck.id,
      checkItem: maintenanceCheck.checkItem,
      action: maintenanceCheck.action,
      version: maintenanceCheck.version,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSort = (sort) => this.setState({sort});

  update = async () => {
    const {loading, updateID, checkItem, action, version} = this.state;
    if (loading || !updateID) return;

    if (!checkItem.trim().length) return alertify.warning('Insert the label');
    if (!action.trim().length) return alertify.warning('Insert the action');

    this.setState({loading: true});

    try {
      const maintenanceCheck = await updateApi(updateID, {
        checkItem,
        action,
        version,
      });
      const {dispatch, maintenanceChecks} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          maintenanceChecks: [...maintenanceChecks].map((r) =>
            r.id === maintenanceCheck.id ? maintenanceCheck : r
          ),
        })
      );
    } catch (error) {
      alertify.error('MaintenanceCheck could not be updated');
      this.setState({loading: false});
    }
  };

  remove = async () => {
    const {loading, removeID} = this.state;
    if (loading || !removeID) return;

    this.setState({loading: true});

    try {
      await deleteApi(removeID);
      const {dispatch, maintenanceChecks} = this.props;
      this.setState({loading: false, removeID: null});
      dispatch(
        setAct({
          maintenanceChecks: [...maintenanceChecks].filter(
            (r) => r.id !== removeID
          ),
        })
      );
    } catch (error) {
      alertify.error('MaintenanceCheck could not be removed');
      this.setState({loading: false});
    }
  };

  data = () => {
    const {maintenanceChecks} = this.props;
    const {sort} = this.state;
    return !!sort
      ? [...maintenanceChecks].sort(sortByString(sort))
      : maintenanceChecks;
  };

  render() {
    const {loading, sort, updateID, checkItem, action, removeID} = this.state;
    return (
      <Fragment>
        <AdminMaintenanceChecks
          columns={this.columns()}
          sort={sort}
          maintenanceChecks={this.data()}
          onUpdate={this.showUpdate}
          onDelete={this.showDelete}
          onSort={this.onSort}
        />
        <UpdateMaintenanceCheckModal
          title="Update MaintenanceCheck"
          checkItemLabel="Label"
          actionLabel="Action"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          checkItem={checkItem}
          action={action}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
        <DeleteMaintenanceCheckModal
          title="Delete MaintenanceCheck"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this maintenanceCheck?
        </DeleteMaintenanceCheckModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.maintenanceCheck}))(
  MaintenanceChecksContainer
);
