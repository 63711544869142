import React from 'react';
import PropTypes from 'prop-types';

// Assets
import documentIcon from './assets/document.png';

const getIcon = (icon) => {
  switch (icon) {
    case 'document':
      return documentIcon;
    default:
      return documentIcon;
  }
};

const Icon = ({icon, ...props}) => (
  <img src={getIcon(icon)} alt="icon" {...props} />
);

Icon.propTypes = {
  icon: PropTypes.oneOf(['document']),
};

export default Icon;
