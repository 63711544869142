import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Actions
import {set as setAct} from '../../../manufacturer/redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.productType';
import deleteApi from '../../api/delete.api.productType';

// Components
import AdminProductTypes from '../../components/AdminProductTypes/AdminProductTypes';
import UpdateProductTypeModal from '../../components/UpdateProductTypeModal/UpdateProductTypeModal';
import DeleteProductTypeModal from '../../components/DeleteProductTypeModal/DeleteProductTypeModal';

// Routes
import manufacturerRoute from '../../../manufacturer/pages/ManufacturerAdminPage/route';
import manufacturersRoute from '../../../manufacturer/pages/ManufacturersAdminPage/route';

class ProductTypesContainer extends Component {
  static propTypes = {
    manufacturers: PropTypes.array,
    manufacturerID: PropTypes.string,
    productID: PropTypes.string,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    loading: false,
    updateID: null,
    name: '',
    version: 0,
    manufacturer: null,
    product: null,
    removeID: null,
  };

  componentDidMount() {
    const {manufacturers, manufacturerID, productID, history} = this.props;
    const manufacturer = [...manufacturers].find(
      ({id}) => id === manufacturerID
    );
    if (!manufacturer) {
      alertify.warning('Manufacturer does not exist');
      history.replace(manufacturersRoute());
      return;
    }
    const product = [...manufacturer.products].find(({id}) => id === productID);
    if (!product) {
      alertify.warning('Product does not exist');
      history.replace(manufacturerRoute(manufacturerID));
      return;
    }
    this.setState({manufacturer, product});
  }

  // TODO: I18n
  columns = () => [
    {
      key: 'name',
      label: 'Name',
      span: 3,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  showUpdate = (productType) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: productType.id,
      name: productType.name,
      version: productType.version,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  back = () =>
    this.props.history.push(manufacturerRoute(this.props.manufacturerID));

  productTypes = () => {
    const {manufacturers, manufacturerID, productID} = this.props;
    const manufacturer = [...manufacturers].find(
      ({id}) => id === manufacturerID
    );
    if (!manufacturer) return [];
    const product = [...manufacturer.products].find(({id}) => id === productID);
    return !!product ? [...product.productTypes] : [];
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  update = async () => {
    const {manufacturerID, productID} = this.props;
    const {loading, updateID, name, version} = this.state;
    if (loading || !updateID) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const productType = await updateApi(updateID, {name, version});
      const {dispatch, manufacturers} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          manufacturers: [...manufacturers].map((manufacturer) =>
            manufacturer.id === manufacturerID
              ? {
                  ...manufacturer,
                  products: [...manufacturer.products].map((product) =>
                    product.id === productID
                      ? {
                          ...product,
                          productTypes: [...product.productTypes].map((pt) =>
                            pt.id === productType.id ? productType : pt
                          ),
                        }
                      : product
                  ),
                }
              : manufacturer
          ),
        })
      );
    } catch (error) {
      alertify.error('ProductType could not be updated');
      this.setState({loading: false});
    }
  };

  remove = async () => {
    const {manufacturerID, productID} = this.props;
    const {loading, removeID} = this.state;
    if (loading || !removeID) return;

    this.setState({loading: true});

    try {
      await deleteApi(removeID);
      const {dispatch, manufacturers} = this.props;
      this.setState({loading: false, removeID: null});
      dispatch(
        setAct({
          manufacturers: [...manufacturers].map((manufacturer) =>
            manufacturer.id === manufacturerID
              ? {
                  ...manufacturer,
                  products: [...manufacturer.products].map((product) =>
                    product.id === productID
                      ? {
                          ...product,
                          productTypes: [...product.productTypes].filter(
                            (pt) => pt.id !== removeID
                          ),
                        }
                      : product
                  ),
                }
              : manufacturer
          ),
        })
      );
    } catch (error) {
      alertify.error('ProductType could not be removed');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {
      loading,
      updateID,
      name,
      removeID,
      manufacturer,
      product,
    } = this.state;
    return !manufacturer || !product ? null : (
      <Fragment>
        <AdminProductTypes
          columns={this.columns()}
          title={`${manufacturer.name} ${product.name}`}
          productTypes={this.productTypes()}
          onTitle={this.back}
          onUpdate={this.showUpdate}
          onDelete={this.showDelete}
        />
        <UpdateProductTypeModal
          title="Update ProductType"
          nameLabel="Name"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          name={name}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
        <DeleteProductTypeModal
          title="Delete ProductType"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this productType?
        </DeleteProductTypeModal>
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({...state.manufacturer}))(ProductTypesContainer)
);
