import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  toDate,
  format,
  parse,
  getTime,
  setMinutes,
  setHours,
  getMinutes,
  getHours,
} from 'date-fns';
import $ from 'jquery';

// Alert
import {alertify} from 'doorson-ui';

// Components
import Label from './components/Label';
import Input from './components/Input';

// Layout
import validateDateInput from '../../../layout/lib/validateDateInput.lib.layout';

class Date extends Component {
  static propTypes = {
    date: PropTypes.number,
    onChange: PropTypes.func,
  };

  state = {
    date: null,
  };

  showEdit = () => {
    const {date} = this.props;
    this.setState({date: format(toDate(date), 'dd.MM.yyyy')});
  };

  stopEdit = () => this.setState({date: null});

  onChange = ({target: {value: date}}) => this.setState({date: date.trim()});

  onConfirm = () => {
    const {onChange, date: previousDate} = this.props;
    const previousDateObject = toDate(previousDate);
    const {date} = this.state;
    if (!date) return;
    const dateObject = parse(date, 'dd.MM.yyyy', previousDateObject);
    if (dateObject === 'Invalid Date')
      return alertify.warning('Insert a valid date. Format: day.month.year');
    const newDate = setHours(
      setMinutes(dateObject, getMinutes(previousDateObject)),
      getHours(previousDateObject)
    );
    onChange(getTime(newDate));
    this.stopEdit();
  };

  onKeyDown = (e) => {
    validateDateInput(e);
    if (e.key === 'Enter') {
      e.preventDefault();
      this.onConfirm();
      return;
    }
  };

  onInput = (input) => $(input).focus();

  render() {
    const {date: defaultDate} = this.props;
    const {date: editDate} = this.state;
    return editDate !== null ? (
      <Input
        ref={this.onInput}
        value={editDate}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        onBlur={this.onConfirm}
      />
    ) : (
      <Label onDoubleClick={this.showEdit}>
        {format(toDate(defaultDate), 'dd MMM yyyy')}
      </Label>
    );
  }
}

export default Date;
