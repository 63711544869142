import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${darkGrey};
`;

export default Line;
