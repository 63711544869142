import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Activity, Button} from 'doorson-ui';
import NoResults from './components/NoResults';
import MoreContainer from './components/MoreContainer';

const Activities = ({
  loading,
  more,
  activities,
  onActivity,
  onMore,
  noResultsLabel,
  moreLabel,
}) =>
  !activities.length ? (
    <NoResults>{noResultsLabel}</NoResults>
  ) : (
    <Fragment>
      {activities.map((activity) => (
        <Activity
          key={activity.id}
          img={activity.icon}
          subtext={activity.datePosted}
          onClick={onActivity(activity)}
          read={activity.read}
        >
          <span dangerouslySetInnerHTML={{__html: activity.message}} />
        </Activity>
      ))}
      {more && (
        <MoreContainer>
          <Button onClick={onMore} loading={loading} theme="orange">
            {moreLabel}
          </Button>
        </MoreContainer>
      )}
    </Fragment>
  );

Activities.propTypes = {
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  more: PropTypes.bool,
  activities: PropTypes.array,
  onActivity: PropTypes.func,
  onMore: PropTypes.func,
  noResultsLabel: PropTypes.node,
  moreLabel: PropTypes.node,
};

export default Activities;
