import jwt from 'jsonwebtoken';

// Api
import getUserByID from '../../user/api/getByID.api.user';

// Action constants
import {SET_TOKEN} from './constants';

// Other Actions
import {set as setVehicleAct} from '../../vehicle/redux/actions';

// Events
import unauthorizedEvt from '../events/unauthorized.event.auth';

// Constants
import authKey from '../constants/key.const.auth';

// Libraries
import * as localStorage from '../../lib/localStorage';
import * as sessionStorage from '../../lib/sessionStorage';
import authToken from '../lib/token.lib.auth';

// Store
import store from '../../redux/store';

// Sockets
// import socket from '../../sockets/socket';

const checkToken = (token, keepLoggedIn) => async (dispatch) => {
  try {
    const data = await jwt.decode(token);
    const user = await getUserByID(data.user.id, token);
    dispatch(signIn(token, user, keepLoggedIn));
  } catch (error) {
    dispatch(signOut(false));
  }
};

export const init = () => (dispatch) => {
  const sessionToken = sessionStorage.get(authKey);
  const localToken = localStorage.get(authKey);
  const token = sessionToken || localToken || null;
  // const keepLoggedIn = !!localToken;
  const keepLoggedIn = true;
  if (!token)
    return dispatch({
      type: SET_TOKEN,
      data: {
        token: null,
        user: null,
      },
    });
  dispatch(checkToken(token, keepLoggedIn));
};

export const signIn = (token, user, keepLoggedIn = true) => (dispatch) => {
  try {
    (keepLoggedIn ? localStorage : sessionStorage).add(authKey, token);
    authToken.set(token);
    // if (!getState().auth.token) socket.authorize(token);
    dispatch({
      type: SET_TOKEN,
      data: {
        token,
        user,
      },
    });
  } catch (error) {
    dispatch(signOut());
  }
};

export const signOut = () => (dispatch) => {
  localStorage.remove(authKey);
  sessionStorage.remove(authKey);
  authToken.remove();
  // socket.deauthorize();
  dispatch({
    type: SET_TOKEN,
    data: {
      token: null,
      user: null,
    },
  });
  dispatch(setVehicleAct({selectedVehicle: null}));
};

unauthorizedEvt.sub(() => {
  store.dispatch(signOut());
});
