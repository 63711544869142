import React from 'react';
import PropTypes from 'prop-types';

// Components
import Content from './components/Content';

const StatisticsSection = ({children}) => (
  <Content>{children}</Content>
);

StatisticsSection.propTypes = {
  children: PropTypes.node,
};

export default StatisticsSection;
