import React from 'react';
import PropTypes from 'prop-types';

// Components
import {AsyncInput, Button, CheckBox, Column, Input, Modal, Row, Select} from 'doorson-ui';
import Right from './components/Right';
import ButtonContainer from './components/ButtonContainer';

// Libs
import validateSerialNumberInput from '../../../layout/lib/validateSerialNumberInput.lib.layout';

const NewDoorModal = ({
                        title,
                        doorSerialLabel,
                        electronicsSerialLabel,
                        motorSerialLabel,
                        manufacturerLabel,
                        doorVersionLabel,
                        doorTypeLabel,
                        indoorLocationLabel,
                        buildingFloorLabel,
                        redundancyLabel,
                        leafInfoLabel,
                        saveLabel,

                        doorSerial,
                        electronicsSerial,
                        motorSerial,
                        indoorLocation,
                        buildingFloor,
                        redundancy,
                        manufacturer,
                        productId,
                        productTypeId,
                        leafInfo,

                        loading,
                        visible,
                        error,

                        manufacturers,
                        products,
                        productTypes,
                        scanLabel,
                        toggleScan,
                        scanDisabled,
                        onDoorSerial,
                        noSuggestionLabel,
                        searchingForDoor,
                        doorSuggestions,

                        onChange,
                        onComplete,
                        onClose,
                      }) => (
  <Modal title={title} visible={visible} onClose={onClose} size="large">
    <Row margin>
      <Column size={2 / 3}>
        <AsyncInput
          value={doorSerial}
          onChange={onChange('doorSerial', true)}
          error={error.doorSerial}
          disabled={loading}
          suggestions={doorSuggestions}
          onSuggestion={onDoorSerial}
          noSuggestionLabel={noSuggestionLabel}
          displaySuggestions={() => doorSerial.trim().length >= 3}
          searching={searchingForDoor}
          onKeyDown={validateSerialNumberInput}
          required
        >
          {doorSerialLabel}
        </AsyncInput>
      </Column>
      <Column size={1 / 3}>
        <ButtonContainer>
          <Button
            size="normal"
            theme="blue"
            preIcon="qrcode"
            disabled={scanDisabled}
            outline={true}
            onClick={() => toggleScan(!scanDisabled)}>
            {scanLabel}
          </Button>
        </ButtonContainer>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Input
          type="tel"
          value={electronicsSerial}
          onChange={onChange('electronicsSerial', true)}
          error={error.electronicsSerial}
          onKeyDown={validateSerialNumberInput}
          disabled={loading}
          required
        >
          {electronicsSerialLabel}
        </Input>
      </Column>
      <Column size={1 / 3}>
        <Input
          type="tel"
          value={motorSerial}
          onChange={onChange('motorSerial', true)}
          error={error.motorSerial}
          onKeyDown={validateSerialNumberInput}
          disabled={loading}
          required
        >
          {motorSerialLabel}
        </Input>
      </Column>
    </Row>
    <Row margin>
      <Column size={2 / 3}>
        <Select
          value={manufacturer}
          options={manufacturers}
          onChange={onChange('manufacturer')}
          error={error.manufacturer}
          disabled={loading}
          required
        >
          {manufacturerLabel}
        </Select>
      </Column>
    </Row>
    <Row margin>
      <Column size={2 / 3}>
        <Select
          value={productId}
          options={products}
          onChange={onChange('productId')}
          error={error.productId}
          disabled={loading}
          required
        >
          {doorVersionLabel}
        </Select>
      </Column>
    </Row>
    <Row margin>
      <Column size={2 / 3}>
        <Select
          value={productTypeId || ''}
          options={productTypes}
          onChange={onChange('productTypeId')}
          error={error.productTypeId}
          disabled={loading}
          required={
            !productId ||
            (!!productId &&
              !(productTypes.length === 1 && productTypes[0].value === ''))
          }
        >
          {doorTypeLabel}
        </Select>
      </Column>
      <Column size={1 / 3}>
        <Input
          value={leafInfo}
          onChange={onChange('leafInfo', true)}
          error={error.leafInfo}
          disabled={loading}
          required
        >
          {leafInfoLabel}
        </Input>
      </Column>
    </Row>
    <Row margin>
      <Column size={2 / 3}>
        <Input
          value={indoorLocation}
          onChange={onChange('indoorLocation', true)}
          error={error.indoorLocation}
          disabled={loading}
          required
        >
          {indoorLocationLabel}
        </Input>
      </Column>
      <Column size={1 / 3}>
        <Input
          value={buildingFloor}
          onChange={onChange('buildingFloor', true)}
          error={error.buildingFloor}
          disabled={loading}
          required
        >
          {buildingFloorLabel}
        </Input>
      </Column>
    </Row>
    <Row margin>
      <Column>
        <CheckBox
          selected={redundancy}
          onSelect={onChange('redundancy')}
          disabled={loading}
        >
          {redundancyLabel}
        </CheckBox>
      </Column>
    </Row>
    <Row>
      <Column>
        <Right>
          <ButtonContainer>
            <Button
              size="big"
              theme="orange"
              loading={loading}
              onClick={onComplete}
            >
              {saveLabel}
            </Button>
          </ButtonContainer>
        </Right>
      </Column>
    </Row>
  </Modal>
);

NewDoorModal.propTypes = {
  title: PropTypes.string,
  doorSerialLabel: PropTypes.string,
  electronicsSerialLabel: PropTypes.string,
  motorSerialLabel: PropTypes.string,
  manufacturerLabel: PropTypes.string,
  doorVersionLabel: PropTypes.string,
  doorTypeLabel: PropTypes.string,
  indoorLocationLabel: PropTypes.string,
  buildingFloorLabel: PropTypes.string,
  redundancyLabel: PropTypes.string,
  leafInfoLabel: PropTypes.string,
  saveLabel: PropTypes.string,

  doorSerial: PropTypes.string,
  electronicsSerial: PropTypes.string,
  motorSerial: PropTypes.string,
  indoorLocation: PropTypes.string,
  buildingFloor: PropTypes.string,
  redundancy: PropTypes.bool,
  manufacturer: PropTypes.string,
  productId: PropTypes.string,
  productTypeId: PropTypes.string,
  leafInfo: PropTypes.string,

  loading: PropTypes.bool,
  visible: PropTypes.bool,
  error: PropTypes.object,

  manufacturers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),

  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
};

export default NewDoorModal;
