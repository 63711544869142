import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui/lib/Grid/Grid';
import {Input, Select, EditableOption, Textarea, Option} from 'doorson-ui';

const ServiceInfo = ({
  error,
  loading,
  serviceItem,
  materials,
  selectedAccessories,
  updatingAccessories,
  warrantyValidTillDateLabel,
  lastMaintenanceDateLabel,
  accessoryLabel,
  internalRemarksLabel,
  addAccessory,
  removeAccessory,
  onChange,
}) => (
  <Row>
    <Column m={1}>
      <Row margin>
        <Column size={1 / 2}>
          <Input
            type="text"
            value={serviceItem.warrantyValidTillDateStr}
            onChange={onChange('warrantyValidTillDateStr')}
            error={error.warrantyValidTillDateStr}
            disabled={loading}
          >
            {warrantyValidTillDateLabel}
          </Input>
        </Column>
        <Column size={1 / 2}>
          <Input
            type="text"
            value={serviceItem.lastMaintenanceDateStr}
            onChange={onChange('lastMaintenanceDateStr', true)}
            error={error.lastMaintenanceDateStr}
          >
            {lastMaintenanceDateLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 2}>
          <Select
            value=""
            options={materials}
            onChange={addAccessory}
            error={error.accessory}
            disabled={loading}
          >
            {accessoryLabel}
          </Select>
          {selectedAccessories.map(({value, label}, index) => (
            <Option key={value} noMargin={!index}>
              <EditableOption
                type="text"
                value={label}
                onRemove={removeAccessory(value)}
                onChange={() => {}}
                loading={updatingAccessories.includes(value)}
              />
            </Option>
          ))}
        </Column>
        <Column size={1 / 2}>
          <Textarea
            required={false}
            value={serviceItem.internalRemarks}
            type="text"
            minRows={4}
            error={error.internalRemarks}
            disabled={loading}
            onChange={onChange('internalRemarks', true)}
          >
            {internalRemarksLabel}
          </Textarea>
        </Column>
      </Row>
    </Column>
  </Row>
);

ServiceInfo.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  serviceItem: PropTypes.object,
  materials: PropTypes.array,
  selectedAccessories: PropTypes.array,
  updatingAccessories: PropTypes.array,
  warrantyValidTillDateLabel: PropTypes.string,
  lastMaintenanceDateLabel: PropTypes.string,
  accessoryLabel: PropTypes.string,
  internalRemarksLabel: PropTypes.string,
  addAccessory: PropTypes.func,
  removeAccessory: PropTypes.func,
  onChange: PropTypes.func,
};

export default ServiceInfo;
