import {isDate, toDate, format as formatDate} from 'date-fns';

import type from '../type';

const format = (value) => {
  if (!value) return '';
  const dateObj = isDate(value) ? value : toDate(value);
  return formatDate(dateObj, 'd.M.yyyy');
};

const validate = (value) => /^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value);

export default type({type: 'date', format, validate});
