import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// DevicesAdminPage
import DevicesAdminPage from '../DevicesAdminPage/DevicesAdminPage';
import routeDevicesAdminPage from '../DevicesAdminPage/route';

// Consts
import page from './page';

const DevicesAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeDevicesAdminPage()}
        component={DevicesAdminPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default DevicesAdminPages;
