import {profile} from 'f3tch';

// Constants
import url from '../constants/url.const.api';

// Errors
import isApiError from '../errors/isApiError.error.api';

// Profiles
import headers from '../headers/file.header.api';

export const NATIVE = 'NATIVE';
export const JSON = 'JSON';
export const BLOB = 'BLOB';

const fileProfile = ({authorize = true, output = BLOB} = {}) =>
  profile(url)
    .headers(headers({authorize}))
    .responder(async (response) => {
      await isApiError(response);
      if (output !== NATIVE)
        return (await output === JSON) ? response.json() : response.blob();

      return response;
    });
export default fileProfile;
