import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import getWarehouseApi from "../../warehouse/api/getById.api.warehouse";
// Alerts
import {alertify} from 'doorson-ui';

// Components
import ContentLayout from '../../layout/components/ContentLayout/ContentLayout';

import warehouseIcon from '../../warehouse/assets/warehouse-icon.png';
import warehouseOrdersIcon from '../../warehouse/assets/warehouse-orders.png';
import warehouseVehicleIcon from '../../warehouse/assets/warehouse-vehicle-icon.png';
// Containers
// Routes
import warehouseRoute from '../../warehouse/pages/WarehousePage/route';
import warehousesRoute from "../../warehouse/pages/WarehousesPage/route";
import ordersRoute from "../../warehouseOrder/pages/WarehouseOrdersPage/route";
// Roles
import InventoryOption from "../components/InventoryOption/InventoryOption";
import InventoryLoader from "../components/InventoryLoader/InventoryLoader";
import InventoryHeader from "../components/InventoryHeader/InventoryHeader";
import getTodaysVehicleByDriver from "../../vehicle/lib/getTodaysVehicleByDriver.lib.vehicle";

class InventoryContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.object,
    vehicles: PropTypes.array,
  };

  state = {
    loading: true,
    vehicle: null,
    warehouse: null,
    items: []
  };

  menuitems = [
    {
      id: 'warehouses',
      title: 'Warehouses',
      image: warehouseIcon,
      route: warehousesRoute
    },
    {
      id: 'orders',
      title: 'Orders',
      image: warehouseOrdersIcon,
      route: ordersRoute
    }
  ]

  componentDidMount() {
    this.getVehicleWarehouse();
  }

  getVehicleWarehouse = async () => {

    const {user, vehicles} = this.props;
    try {
      const vehicle = getTodaysVehicleByDriver({
        vehicles,
        driverId: user.id,
      });

      if (!!vehicle) {
        const warehouse = !!vehicle.warehouseId ? await getWarehouseApi(vehicle.warehouseId) : null;
        this.setState({loading: false, vehicle, warehouse});
      } else {
        this.setState({loading: false});
      }
    } catch (error) {
      this.setState({loading: false});
      alertify.warning('Unable to get vehicle warehouse');
    }
  };

  vehicleWarehouseItem = () => {
    const {vehicle, warehouse} = this.state;

    return vehicle && warehouse && {
      id: 'vehicleWarehouse',
      title: `${vehicle.licensePlate} (${warehouse.type} - ${warehouse.name})`,
      image: warehouseVehicleIcon,
      route: warehouseRoute.bind(this, warehouse.id)
    }
  }

  onItem = (item) => () => this.props.history.push(item.route());

  items = () => [this.vehicleWarehouseItem(), ...this.menuitems].filter((item) => !!item);

  // TODO: I18n
  renderHeader = () => (
    <Fragment>
      <InventoryHeader>Warehouses & orders</InventoryHeader>
    </Fragment>
  );

  render() {
    const {loading} = this.state;

    return loading ? (
      <InventoryLoader/>
    ) : (
      <Fragment>
        <ContentLayout header={this.renderHeader()}>
          <InventoryOption
            items={this.items()}
            onItem={this.onItem}
          />
        </ContentLayout>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  vehicles: state.vehicle.vehicles
}))(
  withRouter(InventoryContainer)
);
