import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getTime} from 'date-fns';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import erpSearchApi from '../../api/erpSearch.api.material';
import createApi from '../../api/create.api.material';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminMaterialButton from '../../components/NewAdminMaterialButton/NewAdminMaterialButton';
import NewMaterialModal from '../../components/NewMaterialModal/NewMaterialModal';

class NewMaterialContainer extends Component {
  static propTypes = {
    materials: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    searching: false,
    editMode: false,
    search: '',
    code: '',
    name: '',
    unit: '',
    has_guarantee: false,
    materials: [],
    lastSearch: 0,
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSearch = async (search) => {
    const currentSearchTime = getTime(new Date());

    if (search.trim().length < 3)
      return this.setState({
        searching: false,
        search,
        lastSearch: currentSearchTime,
        materials: [],
      });

    this.setState({searching: true, search, lastSearch: currentSearchTime});

    try {
      const materials = await erpSearchApi({search});
      if (this.state.lastSearch > currentSearchTime) return;
      this.setState({searching: false, materials});
    } catch (error) {
      this.setState({searching: false});
    }
  };

  onMaterial = ({material, imported}) => () => {
    const {loading, searching} = this.state;
    if (imported || loading || searching) return;
    this.setState({
      editMode: true,
      code: material.code,
      name: material.name,
      unit: material.unit,
      has_guarantee: false,
    });
  };

  onBack = () => {
    const {loading, searching} = this.state;
    if (loading || searching) return;
    this.setState({editMode: false});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({
      visible: true,
      editMode: false,
      materials: [],
      search: '',
      code: '',
      name: '',
      has_guarantee: false,
    });
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({
      visible: false,
      editMode: false,
      materials: [],
      search: '',
      code: '',
      name: '',
      has_guarantee: false,
    });
  };

  create = async () => {
    // TODO: I18n
    const {loading, visible, code, name, unit, has_guarantee} = this.state;
    if (loading || !visible) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const material = await createApi({
        code,
        name,
        unit,
        warrantyDuration: has_guarantee ? 'P1Y' : null,
      });
      const {dispatch, materials} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(setAct({materials: [...materials, material]}));
    } catch (error) {
      alertify.error('Material could not be created');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {
      loading,
      searching,
      search,
      editMode,
      visible,
      code,
      name,
      unit,
      has_guarantee,
      materials,
    } = this.state;
    return (
      <Fragment>
        <NewAdminMaterialButton onClick={this.show}>
          Import Material
        </NewAdminMaterialButton>
        <NewMaterialModal
          title="New Material"
          nameLabel="Name"
          codeLabel="Code"
          unitLabel="Unit"
          hasGuaranteeLabel="1 Year"
          saveLabel="Save"
          searchLabel="Search for material"
          startSearchLabel="Start searching for materials"
          noResultsLabel="No results"
          visible={visible}
          loading={loading}
          searching={searching}
          editMode={editMode}
          materials={materials}
          search={search}
          code={code}
          name={name}
          unit={unit}
          has_guarantee={has_guarantee}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
          onSearch={this.onSearch}
          onBack={this.onBack}
          onMaterial={this.onMaterial}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.material}))(NewMaterialContainer);
