import React from 'react';

// Components
import NewMaintenanceContractButton from '../../components/NewMaintenanceContractButton/NewMaintenanceContractButton';

const MaintenanceContractsAdminAddonPage = () => (
  <NewMaintenanceContractButton>New Contract</NewMaintenanceContractButton>
);

export default MaintenanceContractsAdminAddonPage;
