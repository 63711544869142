import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const ChecksContainer = styled.div`
  width: 100%;
  background: ${white};
  margin-bottom: 2px;
`;

export default ChecksContainer;
