import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

const VehicleMarker = ({selected, obsolete, children}) => (
  <Container obsolete={obsolete} selected={selected}>{children}</Container>
);

VehicleMarker.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.node,
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export default VehicleMarker;
