import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const Container = styled.div`
  width: 100%;
  padding: 15px 34px;
  border-style: solid none none none;
  border-width: 2px;
  border-color: ${grey};
  display: flex;
  font-size: 12px;
  position: relative;
`;

export default Container;
