import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {CheckBox} from 'doorson-ui';
import {Input} from 'doorson-ui';

const NewVehicleModal = ({
  title,
  makeLabel,
  modelLabel,
  licensePlateLabel,
  labelLabel,
  disabledLabel,
  saveLabel,
  visible,
  loading,
  make,
  model,
  licensePlate,
  label,
  disabled,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="small">
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Input value={make} onChange={onChange('make')} disabled={loading}>
            {makeLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Input value={model} onChange={onChange('model')} disabled={loading}>
            {modelLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Input
            value={licensePlate}
            onChange={onChange('licensePlate')}
            disabled={loading}
          >
            {licensePlateLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Input value={label} onChange={onChange('label')} disabled={loading}>
            {labelLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <CheckBox
            selected={disabled}
            onSelect={onChange('disabled')}
            disabled={loading}
          >
            {disabledLabel}
          </CheckBox>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

NewVehicleModal.propTypes = {
  title: PropTypes.string,
  makeLabel: PropTypes.string,
  modelLabel: PropTypes.string,
  licensePlateLabel: PropTypes.string,
  labelLabel: PropTypes.string,
  disabledLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  make: PropTypes.string,
  model: PropTypes.string,
  licensePlate: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default NewVehicleModal;
