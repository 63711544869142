import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Select} from 'doorson-ui';

const MonthSelect = ({value, children, ...props}) => (
  <Select
    value={value}
    options={[
      {value: 0, label: 'January'},
      {value: 1, label: 'February'},
      {value: 2, label: 'March'},
      {value: 3, label: 'April'},
      {value: 4, label: 'May'},
      {value: 5, label: 'June'},
      {value: 6, label: 'July'},
      {value: 7, label: 'August'},
      {value: 8, label: 'September'},
      {value: 9, label: 'October'},
      {value: 10, label: 'November'},
      {value: 11, label: 'December'},
    ]}
    {...props}
  >
    {children}
  </Select>
);

MonthSelect.propTypes = {
  value: PropTypes.number,
  children: PropTypes.node,
};

export default MonthSelect;
