import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const getUserByIDApi = (id, token = null) => {
  const api = f3tch(`/users/${id}`).profile(profile());

  if (!!token) api.headers({authorization: `Bearer ${token}`});

  return api.get();
};
export default getUserByIDApi;
