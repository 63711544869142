import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createMaterialApi = (material) =>
  f3tch('/materials')
    .profile(profile())
    .body(material)
    .post();
export default createMaterialApi;
