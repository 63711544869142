import styled from 'styled-components';

import {colors} from 'doorson-ui';
const {grey} = colors;

const Header = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid ${grey};
  padding-bottom: 3px;
`;

export default Header;
