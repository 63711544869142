import styled from 'styled-components';

const NotApplicable = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  cursor: not-allowed;
`;

export default NotApplicable;
