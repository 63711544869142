import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getTime, parse} from 'date-fns';

// Api
import updateDoorApi from '../../../door/api/update.api.door';

// Alerts
import {alertify} from 'doorson-ui';

// Components
import EditDoorInfoModal from '../../components/EditDoorInfoModal/EditDoorInfoModal';

// Libs
import prepareDoorForUpdate from '../../../door/lib/prepareForUpdate.lib.door';

class EditDoorInfoContainer extends Component {
  static propTypes = {
    maintenanceDoor: PropTypes.object.isRequired,
    visible: PropTypes.bool,
    label: PropTypes.node,
    valueKey: PropTypes.string,
    onClose: PropTypes.func,
    onComplete: PropTypes.func,
  };

  static INITIAL_STATE = {
    loading: false,
    type: null,
    value: '',
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({...this.constructor.INITIAL_STATE, type: this.type()});
  };

  type = () => {
    const {valueKey} = this.props;
    if (valueKey.endsWith('Date')) return 'date';
    if (valueKey.endsWith('Serial')) return 'serial';
    return 'string';
  };

  onChange = (value) => {
    if (this.state.loading) return;
    this.setState({value});
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  parseValue = (value) => {
    const {type} = this.state;
    return type === 'date'
      ? getTime(parse(value, 'yyyy-MM-dd', new Date()))
      : value;
  };

  save = async () => {
    // TODO: I!*n
    const {visible, maintenanceDoor, valueKey, onComplete} = this.props;
    const {loading, value} = this.state;

    if (loading || !visible || !valueKey) return;

    if (!`${value}`.trim().length) return alertify.warning('Insert value');

    this.setState({loading: true});

    try {
      const door = await updateDoorApi(
        maintenanceDoor.door.id,
        prepareDoorForUpdate({
          ...maintenanceDoor.door,
          [valueKey]: this.parseValue(value),
        })
      );
      onComplete({...maintenanceDoor, door});
    } catch (error) {
      alertify.error('Could not update door info');
      this.setState({loading: false});
    }
  };

  render() {
    const {visible, label} = this.props;
    const {loading, type, value} = this.state;
    return (
      <EditDoorInfoModal
        label={label}
        title="Edit Door Info"
        saveLabel="Save"
        visible={visible}
        loading={loading}
        value={value}
        type={type}
        onChange={this.onChange}
        onClose={this.onClose}
        onSave={this.save}
      />
    );
  }
}

export default EditDoorInfoContainer;
