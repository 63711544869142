import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button} from 'doorson-ui';
import Container from './components/Container';
import Content from './components/Content';

const GenerateMaintenance = ({loading, children, onClick}) => (
  <Container>
    <Content>
      <Button loading={loading} onClick={onClick} theme="grey">
        {children}
      </Button>
    </Content>
  </Container>
);

GenerateMaintenance.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default GenerateMaintenance;
