import styled from 'styled-components';

const ButtonContainer = styled.div`
  width: 130px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
`;

export default ButtonContainer;
