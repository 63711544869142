import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, Text} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {CheckBox} from 'doorson-ui';
import {Modal} from 'doorson-ui';

import Content from './components/Content';
import Wrap from './components/Wrap';
import Materials from './components/Materials';
import Material from './components/Material';

const Close = ({
  loading,
  visible,
  title,
  saveLabel,
  hasGuaranteeLabel,
  children,
  doors,
  onChange,
  onClose,
  onSave,
  onDerecognize,
}) => (
  <Modal title={title} visible={visible} onClose={onClose}>
    <Row margin>
      <Column>
        <Content>{children}</Content>
      </Column>
    </Row>
    <Row margin>
      <Column>
        {doors.map((door) => (
          <Wrap key={door.id}>
            <Row>
              <Column size={2 / 3}>
                {door.door.indoorLocation} {door.door.buildingFloor}
              </Column>
              <Column size={1 / 3}>
                <CheckBox
                  selected={door.hasGuarantee}
                  onSelect={onChange(door.id)}
                  disabled={loading}
                  required
                >
                  {hasGuaranteeLabel}
                </CheckBox>
              </Column>
            </Row>
            {!!door.materials.length && (
              <Materials>
                <Text color="darkerGrey">
                  Specify materials for derecognition
                </Text>
                {door.materials.map((material) => (
                  <Material key={material.id}>
                    <Row>
                      <Column size={7 / 12} offset={1 / 12}>
                        {!!material.material
                          ? material.material.name
                          : material.name}
                      </Column>
                      <Column size={1 / 3}>
                        <CheckBox
                          selected={material.check}
                          onSelect={onDerecognize(door.id, material.id)}
                          disabled={loading || !material.material}
                          required
                        >
                          Bookkeeping
                        </CheckBox>
                      </Column>
                    </Row>
                  </Material>
                ))}
              </Materials>
            )}
          </Wrap>
        ))}
      </Column>
    </Row>
    <Button theme="orange" loading={loading} onClick={onSave} outline>
      {saveLabel}
    </Button>
  </Modal>
);

Close.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  title: PropTypes.string,
  saveLabel: PropTypes.string,
  hasGuaranteeLabel: PropTypes.string,
  children: PropTypes.node,
  doors: PropTypes.array,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDerecognize: PropTypes.func,
};

export default Close;
