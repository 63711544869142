import {v4} from 'uuid';

// Action constants
import {SET} from './constants';

// Api
import listApi from '../api/get.api.order';

// Query
import setQuery from '../../api/lib/query.lib.api';

export const set = (data = {}) => ({type: SET, data});

export const updateOrder = (order) => (dispatch, getState) => {
  const storedOrder = getState().warehouseOrder.warehouseOrder;
  const warehouseOrders = [...getState().warehouseOrder.warehouseOrders].map((ord) =>
    ord.id === order.id ? order : ord
  );
  const newOrder =
    !!storedOrder && storedOrder.id === order.id
      ? order
      : storedOrder;
  dispatch({type: SET, data: {warehouseOrder: newOrder, warehouseOrders}});
};

export const getWarehouseOrders = (data = {}) => async (dispatch, getState) => {
  const apiID = v4();
  const {search, filter, sort, currentUserId, limit} = getState().warehouseOrder;
  const newSearch = data.search !== undefined ? data.search : search;
  const newFilter = data.filter !== undefined ? data.filter : filter;
  const newSort = data.sort !== undefined ? data.sort : sort;
  const newCurrentUserId = data.currentUserId !== undefined ? data.currentUserId : currentUserId;

  dispatch({
    type: SET,
    data: {
      apiID,
      loading: true,
      search: newSearch,
      filter: newFilter,
      sort: newSort,
      offset: 0,
      more: true,
      currentUserId: newCurrentUserId,
      warehouseOrders: [],
    },
  });

  try {

    const queryFilter = [
      newCurrentUserId && newFilter === 'MY_ORDERS' && 'requesterPersonId:EQ:' + newCurrentUserId,
      newFilter === 'DRAFTS' && 'status:EQ:DRAFT',
      newFilter === 'WAITING' && 'status:EQ:REQUESTED',
      newFilter === 'READY' && 'status:EQ:READY_TO_COLLECT',
      newFilter === 'COMPLETED' && 'status:EQ:COMPLETED',
      newFilter === 'TRANSFER' && 'status:EQ:TRANSFER',
      newFilter === 'TRANSFER' && 'targetWarehouseId:ISNOTNULL',
      newFilter === 'DERECOGNITION' && 'status:EQ:TRANSFER',
      newFilter === 'DERECOGNITION' && 'targetWarehouseId:ISNULL',
    ].filter(q => !!q)
    const query = setQuery({
      search: newSearch,
      filter: encodeURIComponent(queryFilter.join(',')),
      order: newSort,
      offset: 0,
      rel: '*',
      limit,
    });
    const warehouseOrders = await listApi(query);
    if (getState().warehouseOrder.apiID !== apiID) return;
    dispatch({
      type: SET,
      data: {
        warehouseOrders,
        loading: false,
        more: warehouseOrders.length === limit,
        offset: limit,
      },
    });
  } catch (error) {
    dispatch({
      type: SET,
      data: {loading: false},
    });
  }
};

export const getMoreWarehouseOrders = () => async (dispatch, getState) => {
  const {
    loading,
    search,
    filter,
    sort,
    limit,
    offset,
    more,
    currentUserId,
    apiID,
    warehouseOrders: oldWarehouseOrders,
  } = getState().warehouseOrder;

  if (!more || loading) return;

  dispatch({
    type: SET,
    data: {
      loading: true,
    },
  });

  try {
    const queryFilter = [
      currentUserId && filter === 'MY_ORDERS' && 'requesterPersonId:EQ:' + currentUserId,
      filter === 'DRAFTS' && 'status:EQ:DRAFT',
      filter === 'WAITING' && 'status:EQ:REQUESTED',
      filter === 'READY' && 'status:EQ:READY_TO_COLLECT',
      filter === 'COMPLETED' && 'status:EQ:COMPLETED',
      filter === 'TRANSFER' && 'status:EQ:TRANSFER',
      filter === 'TRANSFER' && 'targetWarehouseId:ISNOTNULL',
      filter === 'DERECOGNITION' && 'status:EQ:TRANSFER',
      filter === 'DERECOGNITION' && 'targetWarehouseId:ISNULL',
    ].filter(q => !!q)
    const query = setQuery({
      search,
      filter: encodeURIComponent(queryFilter.join(',')),
      order: sort,
      offset,
      limit,
      rel: '*',
    });
    const warehouseOrders = await listApi(query);
    if (getState().warehouseOrder.apiID !== apiID) return;
    dispatch({
      type: SET,
      data: {
        warehouseOrders: [...oldWarehouseOrders, ...warehouseOrders],
        loading: false,
        offset: limit + offset,
        more: warehouseOrders.length === limit,
      },
    });
  } catch (error) {
    dispatch({
      type: SET,
      data: {loading: false},
    });
  }
};
