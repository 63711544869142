import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import createApi from '../../api/create.api.device';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminDeviceButton from '../../components/NewAdminDeviceButton/NewAdminDeviceButton';
import NewDeviceModal from '../../components/NewDeviceModal/NewDeviceModal';

class NewDeviceContainer extends Component {
  static propTypes = {
    devices: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    name: '',
    details: null
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, name: '', details: null});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, name: '', details: null});
  };

  create = async () => {
    // TODO: I18n
    const {loading, visible, name, details} = this.state;
    if (loading || !visible) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const device = await createApi({name, details});
      const {dispatch, devices} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(setAct({devices: [...devices, device]}));
    } catch (error) {
      alertify.error('Device could not be created');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {loading, visible, name, details} = this.state;
    return (
      <Fragment>
        <NewAdminDeviceButton onClick={this.show}>
          New Device
        </NewAdminDeviceButton>
        <NewDeviceModal
          title="New Device"
          nameLabel="Name"
          detailsLabel="Details"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          name={name}
          details={details}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.device}))(NewDeviceContainer);
