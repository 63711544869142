import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createVehicleApi =  (vehicle) =>
  f3tch('/vehicles')
    .profile(profile())
    .body(vehicle)
    .post();
export default createVehicleApi;
