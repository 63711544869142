import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {black} = colors;

const Input = styled.input`
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  display: block;
  margin: 0px;
  padding: 0px;
  border: none;
  background: none;
  font-size: 12px;
  font-weight: 400;
  font-family: inherit;
  outline: none;
  color: ${black};
`;

export default Input;
