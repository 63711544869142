import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Containers
import MaintenanceTabsContainer from '../../containers/MaintenanceTabsContainer/MaintenanceTabsContainer';

// TODO: I18n
const MaintenanceTabsPage = ({match}) => <MaintenanceTabsContainer />;

MaintenanceTabsPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(MaintenanceTabsPage);
