import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value)
    ? value
        .replace(/ +(?= )/g, '')
        .split(' ')
        .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join(' ')
    : '';

const validate = (value) =>
  value
    .split(' ')
    .filter((word) => !!`${word}`)
    .map(
      (word) =>
        word.trim().length > 0 &&
        word.charAt(0) === word.charAt(0).toUpperCase()
    )
    .every((word) => word);

export default type({type: 'string', format, validate});
