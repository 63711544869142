import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/file.profile.api';

const uploadInterventionFileApi = (interventionID, fileName, type, data) =>
    f3tch(`/interventions/${interventionID}/files/${fileName}`)
        .profile(profile({output: 'JSON'}))
        .query({mimeType: type && encodeURI(type)})
        .body(data)
        .post();
export default uploadInterventionFileApi;
