import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const resolveMaintenanceApi = (maintenanceID, query = {rel: '*'}) =>
  f3tch(`/maintenances/${maintenanceID}/resolve`)
    .profile(profile())
    .query(query)
    .put();
export default resolveMaintenanceApi;
