import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import getMaintenanceByIDApi from '../../api/getByID.api.maintenance';

// Alertify
import {alertify} from 'doorson-ui';

// Actions
import {set as setAct} from '../../redux/actions';

// Components
import MaintenancesHeader from '../../components/MaintenancesHeader/MaintenancesHeader';
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import MaintenanceLoader from '../../components/MaintenanceLoader/MaintenanceLoader';
import MaintenanceInfo from '../../components/MaintenanceInfo/MaintenanceInfo';

// Libs
import getName from '../../../customer/lib/getName.lib.customer';
import status from '../../lib/status.lib.maintenance';

// Types
import {date as dateType} from '../../../types';

// Routes
import maintenanceRoute from '../../pages/MaintenancePage/route';
import maintenancesRoute from '../../pages/MaintenancesPage/route';

class MaintenanceContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    subpage: PropTypes.node,
    maintenanceID: PropTypes.string,
    history: PropTypes.object,
    maintenance: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.mounted = true;
    this.getMaintenance();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getMaintenance = async () => {
    // TODO: I18N
    const {maintenanceID, history, dispatch} = this.props;
    try {
      const maintenance = await getMaintenanceByIDApi(maintenanceID);
      if (!this.mounted) return;
      dispatch(setAct({maintenance}));
      this.setState({ loading: false });
    } catch (error) {
      if (!this.mounted) return;
      dispatch(setAct({maintenance: null}));
      alertify.error('Maintenance does not exist');
      history.replace(maintenancesRoute());
    }
  };

  onMaintenance = () => {
    const {history, maintenance} = this.props;
    history.push(maintenanceRoute(maintenance.id));
  };

  renderHeader = () => (
    <Fragment>
      <MaintenancesHeader onClick={this.onMaintenance}>
        {getName(this.props.maintenance.customer)}
      </MaintenancesHeader>
      {this.props.subpage}
    </Fragment>
  );

  render() {
    const {children, maintenance} = this.props;
    const {loading} = this.state;
    return loading || !maintenance ? (
      <MaintenanceLoader />
    ) : (
      <Fragment>
        <ContentLayout header={this.renderHeader()}>
          <MaintenanceInfo
            incidentStatusLabel="Incident status"
            incidentCodeLabel="Incident code"
            invoiceNumberLabel="Invoice number"
            incidentCreatedLabel="Incident created"
            workIssuedLabel="Work order issued by"
            incidentResolvedLabel="Incident resolved by"
            status={status(maintenance)}
            code={maintenance.code}
            invoiceNumber={maintenance.invoiceNumber || '/'}
            created={dateType(maintenance.dc).format()}
            issuedBy="/"
            resolvedBy={
              !!maintenance.resolvedBy
                ? `${maintenance.resolvedBy.firstName} ${maintenance.resolvedBy.lastName}`
                : '/'
            }
          />
          {children}
        </ContentLayout>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
}))(withRouter(MaintenanceContainer));
