import React from 'react';

// Components
import Loader from 'react-loader-spinner';
import Container from './components/Container';

// Styles
import './styles/FullScreenLoader.css';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const FullScreenLoader = () => (
  <Container className="loadingDots">
    <Loader type="BallTriangle" color={orange} height={80} width={80} />
  </Container>
);

export default FullScreenLoader;
