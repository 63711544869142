import styled from 'styled-components';

import {colors} from 'doorson-ui';
const {black, grey, white} = colors;

const Option = styled.div`
  width: 100%;
  padding: 12px;
  cursor: pointer;
  color: ${black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${({selected}) => (selected ? grey : white)};

  &:hover {
    background: ${grey};
  }
`;

export default Option;
