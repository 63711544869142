import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import InterventionsContainer from '../../containers/InterventionsContainer/InterventionsContainer';

// Consts
import page from './page';

const InterventionsPage = () => (
  <Page page={page}>
    <InterventionsContainer />
  </Page>
);

export default InterventionsPage;
