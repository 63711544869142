import styled from 'styled-components';

const LineContainer = styled.div`
  position: relative;
  float: left;
  width: 300px;
  height: 120px;
  padding: 5px;
  user-select: none;
`;

export default LineContainer;
