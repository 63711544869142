import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const PdfIcon = styled.i`
  display: block;
  color: ${orange};
  pointer-events: none;
  font-size: 40px;
`;

export default PdfIcon;
