import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import PreLoader from '../../../layout/components/PreLoader/PreLoader';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import QuickActions from '../QuickActions/QuickActions';
import Tooltip from '../../../layout/components/Tooltip/Tooltip';

import Icon from './components/Icon';
import Container from './components/Container';

// Customer
import getCustomerName from '../../../customer/lib/getName.lib.customer';

// Intervention
import getPriority from '../../priority/getPriority.priority.intervention';

// Types
import {date as dateType} from '../../../types';
import GpsNavigation from '../../../layout/components/GpsNavigation/GpsNavigation';

// TODO: I18N
const Interventions = ({
  loading,
  more,
  scroller,
  assigning,
  user,
  users,
  sort,
  columns,
  dateColumn,
  interventions,
  onIntervention,
  onAction,
  onSort,
  onMore,
}) => (
  <Container>
    <PreLoader
      scroller={scroller}
      loading={loading}
      showLoader={!!interventions.length}
      more={more}
      onMore={onMore}
    >
      <Table columns={columns} loading={loading} sort={sort} onSort={onSort}>
        {(TableRow) =>
          interventions.map((intervention) => (
            <TableRow
              key={intervention.id}
              label={
                intervention.resolved || intervention.resolved
                  ? null
                  : getPriority(intervention).color
              }
              theme={
                intervention.resolved || intervention.closed
                  ? 'greyer'
                  : 'white'
              }
            >
              {(TableCol) => [
                <TableCol key="priority" onClick={() => {}}>
                  {intervention.reportingRemarks && (
                    <Tooltip content={intervention.reportingRemarks}>
                      <Icon className="mdi mdi-information-outline" />
                    </Tooltip>
                  )}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="incident"
                  onClick={() => onIntervention(intervention)}
                >
                  {intervention.code}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key={dateColumn}
                  onClick={() => onIntervention(intervention)}
                >
                  {dateType(intervention[dateColumn]).format()}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="customer"
                  onClick={() => onIntervention(intervention)}
                >
                  {getCustomerName(intervention.customer)}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="location"
                  onClick={() => onIntervention(intervention)}
                >
                  {!!intervention.customerBranch &&
                    !!intervention.customerBranch.branchLocation && (
                      <span>
                        {!!intervention.customerBranch.branchName && (
                          <span>
                            {intervention.customerBranch.branchName}
                            <br />
                          </span>
                        )}
                        {intervention.customerBranch.branchLocation.address}
                        <br />
                        {intervention.customerBranch.branchLocation.city}
                        <GpsNavigation
                          location={intervention.customerBranch.branchLocation}
                        />
                      </span>
                    )}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="doorType"
                  onClick={() => onIntervention(intervention)}
                >
                  {intervention.doors.length > 1
                    ? 'Multiple doors'
                    : intervention.doors.map(({door}, index) =>
                        !!door ? (
                          <div key={door.id}>
                            {door.product.manufacturer.name} {door.product.name}
                          </div>
                        ) : (
                          <div key={index}>Unknown doors</div>
                        )
                      )}
                </TableCol>,
                <TableCol span={1.5} key="action">
                  <QuickActions
                    loading={assigning.includes(intervention.id)}
                    intervention={intervention}
                    priorityColor={getPriority(intervention).color}
                    users={users}
                    user={user}
                    onAction={onAction}
                  />
                </TableCol>,
              ]}
            </TableRow>
          ))
        }
      </Table>
    </PreLoader>
    {!loading && !interventions.length && (
      <NoDataFound>No interventions found</NoDataFound>
    )}
  </Container>
);

Interventions.propTypes = {
  loading: PropTypes.bool,
  more: PropTypes.bool,
  scroller: PropTypes.object,
  assigning: PropTypes.array,
  user: PropTypes.object,
  users: PropTypes.array,
  sort: PropTypes.object,
  dateColumn: PropTypes.string,
  columns: PropTypes.array,
  interventions: PropTypes.array,
  onIntervention: PropTypes.func,
  onSort: PropTypes.func,
  onAction: PropTypes.func,
  onMore: PropTypes.func,
};

export default Interventions;
