import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';

const UpdateMaintenanceCheckModal = ({
  title,
  checkItemLabel,
  actionLabel,
  saveLabel,
  visible,
  loading,
  checkItem,
  action,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose}>
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Input
            value={checkItem}
            onChange={onChange('checkItem')}
            disabled={loading}
          >
            {checkItemLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Input
            value={action}
            onChange={onChange('action')}
            disabled={loading}
          >
            {actionLabel}
          </Input>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

UpdateMaintenanceCheckModal.propTypes = {
  title: PropTypes.string,
  checkItemLabel: PropTypes.string,
  actionLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  checkItem: PropTypes.string,
  action: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default UpdateMaintenanceCheckModal;
