import styled from 'styled-components';

const HoursColumn = styled.div`
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  padding: ${({height}) => `${height / 2}px 0px`};
`;

export default HoursColumn;
