import React from 'react';
import PropTypes from 'prop-types';

// Components
import Search from '../../../layout/components/Search/Search';

const MaintenancesSearch = ({search, onSearch}) => (
  <Search search={search} onSearch={onSearch} />
);

MaintenancesSearch.propTypes = {
  search: PropTypes.string,
  onSearch: PropTypes.func,
};

export default MaintenancesSearch;
