import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Input, Row, Select} from 'doorson-ui';

import Container from './components/Container';
import Box from './components/Box';
import ButtonRow from './components/ButtonRow';
import ButtonContainer from './components/ButtonContainer';
import sortByString from "../../../lib/sortByString";

const InterventionActions = ({
                               loading,
                               error,
                               isSuperUser,
                               assigned,
                               users,
                               priorityLabel,
                               waitForExternalLabel,
                               statusReasonLabel,
                               priority,
                               priorities,
                               statusReason,
                               resolved,
                               onPriority,
                               deleteLabel,
                               onDelete,
                               onChange,
                             }) => (
  <Container>
    <Box>
      {!resolved && (
        <Row>
          <Column size={1 / 2}>
            <Input value={statusReason}
                   onChange={onChange('statusReason')}
                   error={error.statusReason}
                   disabled={loading || !assigned} required>
              {statusReasonLabel}
            </Input>
          </Column>
          <Column size={1 / 2}>
            <ButtonRow>
              <ButtonContainer>
                <Button
                  loading={loading}
                  disabled={loading || !assigned}
                  onClick={onChange('assign')}
                  theme="orange"
                  preIcon="pause-octagon-outline"
                  options={[...users]
                    .sort(sortByString({key: 'firstName', direction: 'asc'}))
                    .map(({id, firstName, lastName}) => ({
                      value: id,
                      label: `${firstName} ${lastName}`,
                    }))}
                  outline>
                  {waitForExternalLabel}
                </Button>
              </ButtonContainer>
            </ButtonRow>
          </Column>
        </Row>)}
      {isSuperUser &&
      <Row>
        <Column size={1 / 2}>
          {!resolved && (
            <Select
              value={priority}
              options={priorities}
              onChange={onPriority}
              disabled={loading}>
              {priorityLabel}
            </Select>
          )}
        </Column>
        <Column size={1 / 2}>
          <ButtonRow>
            <ButtonContainer>
              <Button theme="red" preIcon="delete" onClick={onDelete}>
                {deleteLabel}
              </Button>
            </ButtonContainer>
          </ButtonRow>
        </Column>
      </Row>}
    </Box>
  </Container>
);

InterventionActions.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  isSuperUser: PropTypes.bool,
  assigned: PropTypes.bool,
  users: PropTypes.array,
  priorityLabel: PropTypes.string,
  statusReasonLabel: PropTypes.string,
  priority: PropTypes.any,
  priorities: PropTypes.array,
  resolved: PropTypes.bool,
  statusReason: PropTypes.string,
  onPriority: PropTypes.func,
  deleteLabel: PropTypes.string,
  waitForExternalLabel: PropTypes.string,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
};

export default InterventionActions;
