import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white, darkGrey} = colors;

const DropDown = styled.div`
  width: 120px;
  background: ${white};
  border: 1px solid ${darkGrey};
  position: absolute;
  top: calc(100% + 2px);
  right: 0px;
`;

export default DropDown;
