import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import ImageContainer from './components/ImageContainer';
import Image from './components/Image';

// Libs
import preview from '../../../file/lib/preview.lib.file';

const Folder = ({title, image, onClick}) => (
  <Container onClick={onClick}>
    {title}
    {!!image && (
      <ImageContainer>
        <Image src={preview(image)} />
      </ImageContainer>
    )}
  </Container>
);

Folder.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
};

export default Folder;
