import {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Paths
import infoRoute from '../../pages/MaintenanceDoorInfoPage/route';

class SpecifyMaintenanceDoorSubpageContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
  };

  componentDidMount() {
    const {history, maintenance, maintenanceDoor} = this.props;
    const route = this.getRoute();
    history.replace(route(maintenance.id, maintenanceDoor.id));
  }

  getRoute = () => {
    return infoRoute;
  };

  render() {
    return null;
  }
}

export default withRouter(
  connect((state) => ({
    maintenance: state.maintenance.maintenance,
    maintenanceDoor: state.maintenanceDoor.maintenanceDoor,
  }))(SpecifyMaintenanceDoorSubpageContainer)
);
