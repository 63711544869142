import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Table} from 'doorson-ui';
import Container from './components/Container';
import ButtonContainer from './components/ButtonContainer';
import NewDoor from './components/NewDoor';

const MaintenanceItems = ({
  selectLabel,
  inProgressLabel,
  completedLabel,
  scanLabel,
  addDoorLabel,
  columns,
  doors,
  maintenance,
  onSelect,
  onNewDoor,

  scan,
  toggleScan,
  sort,
  onSort,
  onSearch,
}) => (
  <Container>
    <Table columns={columns} sort={sort} onSort={onSort} onSearch={onSearch}>
      {(TableRow) => [
        ...doors.map((door) => (
          <TableRow
            key={door.id}
            label={door.resolved ? null : 'grey'}
            theme={door.resolved ? 'greyer' : 'white'}
            onClick={onSelect(door)}
          >
            {(TableCol) => [
              <TableCol key="priority" />,
              <TableCol span={1.5} key="code">
                {maintenance.code}
              </TableCol>,
              <TableCol span={1.5} key="serial">
                {door.door.doorSerial}
              </TableCol>,
              <TableCol span={1.5} key="location">
                {door.door.indoorLocation} {door.door.buildingFloor}
              </TableCol>,
              <TableCol span={1.5} key="type">
                {door.door.product.manufacturer.name} {door.door.product.name}
                {!!door.door.productType
                  ? ` ${door.door.productType.name}`
                  : ''}
              </TableCol>,
              <TableCol span={1.5} key="action">
                {!door.resolved && (
                  <ButtonContainer>
                    <Button theme="grey" outline>
                      {"COMPLETED" === door.status ? completedLabel : [...(door.checks || [])].find((c) => !!c.status)
                        ? inProgressLabel
                        : selectLabel}
                    </Button>
                  </ButtonContainer>
                )}
              </TableCol>,
            ]}
          </TableRow>
        )),
        <TableRow key="newDoor">
          {(TableCol) => [
            <TableCol span={7}>
              <NewDoor onClick={onNewDoor}>+ {addDoorLabel}</NewDoor>
            </TableCol>,
            <TableCol span={1.5}>
              <ButtonContainer>
                <Button
                  size="normal"
                  theme="blue"
                  preIcon="qrcode"
                  disabled={scan}
                  outline={true}
                  onClick={() => toggleScan(!scan)}
                >
                  {scanLabel}
                </Button>
              </ButtonContainer>
            </TableCol>,
          ]}
        </TableRow>,
      ]}
    </Table>
  </Container>
);

MaintenanceItems.propTypes = {
  selectLabel: PropTypes.node,
  inProgressLabel: PropTypes.string,
  completedLabel: PropTypes.string,
  scanLabel: PropTypes.string,
  addDoorLabel: PropTypes.node,

  columns: PropTypes.array,
  doors: PropTypes.array,
  maintenance: PropTypes.object,

  onSelect: PropTypes.func,
  onClose: PropTypes.func,

  onNewDoor: PropTypes.func,
  toggleScan: PropTypes.func,
  scan: PropTypes.bool,

  sort: PropTypes.string,
  onSort: PropTypes.func,
};

export default MaintenanceItems;
