import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const resolveInterventionApi = (interventionID, data, query = {rel: '*'}) =>
  f3tch(`/interventions/${interventionID}/sign`)
    .profile(profile())
    .query(query)
    .body(data)
    .put();
export default resolveInterventionApi;
