import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import MaintenancesContainer from '../../containers/MaintenancesContainer/MaintenancesContainer';

// Consts
import page from './page';

const MaintenancesPage = () => (
  <Page page={page}>
    <MaintenancesContainer />
  </Page>
);

export default MaintenancesPage;
