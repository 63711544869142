import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const createMessageApi =(message, query = {}) =>
  f3tch(`/messages`)
    .profile(profile())
    .query(query)
    .body(message)
    .post();
export default createMessageApi;
