import styled from 'styled-components';

const Container = styled.div`
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
`;

export default Container;
