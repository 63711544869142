import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Container, Row, Column} from 'doorson-ui';
import {Tabs} from 'doorson-ui';

import Wrapper from './components/Wrapper';

const MaintenanceDoorTabs = ({tab, tabs, onTab}) => (
  <Wrapper>
    <Container>
      <Row>
        <Column>
          <Tabs tab={tab} tabs={tabs} onTab={onTab} icons />
        </Column>
      </Row>
    </Container>
  </Wrapper>
);

MaintenanceDoorTabs.propTypes = {
  tab: PropTypes.string,
  tabs: PropTypes.array,
  onTab: PropTypes.func,
};

export default MaintenanceDoorTabs;
