import styled from 'styled-components';
import PropTypes from 'prop-types';
import {transitions} from 'polished';

import {colors} from 'doorson-ui';
const {white, darkGrey} = colors;

const Dropdown = styled.div`
  width: 175px;
  position: absolute;
  right: 0px;
  top: calc(100% + 10px);
  background: ${white};
  border: 1px solid ${darkGrey};
  padding: 8px 0px;
  font-size: 13px;
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  transform: ${({visible}) => (visible ? 'none' : 'translateY(-10px)')};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  ${transitions(
    'transform .3s ease-in-out, visibility .3s ease-in-out, opacity .3s ease-in-out'
  )}
`;

Dropdown.propTypes = {
  visible: PropTypes.bool,
};

export default Dropdown;
