import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {v4} from 'uuid';
import {withRouter} from 'react-router-dom';

// Api
import createDoorApi from '../../../door/api/create.api.door';
import updateDoorApi from '../../../door/api/update.api.door';
import updateInterventionDoorApi from '../../api/update.api.interventionDoor';
import createFaultApi from '../../api/createFault.api.interventionDoor';
import updateFaultApi from '../../api/updateFault.api.interventionDoor';
import deleteFaultApi from '../../api/deleteFault.api.interventionDoor';
import createMaterialApi from '../../api/createMaterial.api.interventionDoor';
import updateMaterialApi from '../../api/updateMaterial.api.interventionDoor';
import deleteMaterialApi from '../../api/deleteMaterial.api.interventionDoor';
import createRepairApi from '../../api/createRepair.api.interventionDoor';
import updateRepairApi from '../../api/updateRepair.api.interventionDoor';
import deleteRepairApi from '../../api/deleteRepair.api.interventionDoor';
import listDoorMaterials from '../../../door/api/listMaterial.api.door';

// Actions
import {updateIntervention as updateInterventionAct} from '../../../intervention/redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Libs
import isResolved from '../../../intervention/lib/isResolved.lib.intervention';
import isAssigned from '../../../intervention/lib/isAssigned.lib.intervention';
import isDoorComplete from '../../lib/isDoorComplete.lib.interventionDoor';

// Components
import WorkingLog from '../../components/Workinglog/Workinglog';

// Routes
import confirmationRoute from '../../../intervention/pages/InterventionConfirmationPage/route';
import {getTime} from "date-fns";
import listDoorsApi from "../../../door/api/list.api.door";
import serviceItemRoute from "../../../door/pages/ServiceItemPage/route";
import {dateTime as dateTimeType} from "../../../types";
import parseServerFault from "../../../api/lib/parseServerFault.lib.api";
import QrScanModal from "../../../door/components/QrScanModal/QrScanModal";
import getLabelRefApi from "../../../labelRef/api/getById.api.labelRef";
import createLabelRefApi from "../../../labelRef/api/create.api.labelRef";

class WorklogContainer extends Component {
  static propTypes = {
    intervention: PropTypes.object,
    faults: PropTypes.array,
    repairs: PropTypes.array,
    materials: PropTypes.array,
    manufacturers: PropTypes.array,
    user: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    scan: false,
    scanLoading: false,
    searchingForDoor: false,
    searchDoors: [],
    doors: {},
    door: null,
    updatingFaults: [],
    updatingRepairs: [],
    updatingMaterials: [],
    doorMaterialHistory: []
  };

  lastDoorSearch = 0;

  componentDidMount = async () => {
    this.mounted = true;
    const doors = [...this.props.intervention.doors].reduce(
      (combined, current) => ({
        ...combined,
        [current.id]: {
          ...current,
          manufacturer: !!current.door && !!current.door.product
            ? current.door.product.manufacturer.id
            : '',
          productId: !!current.door && !!current.door.product ? current.door.product.id : null,
          productTypeId:
            current.door && !!current.door.productType
              ? current.door.productType.id
              : null,
          noMaterials: isDoorComplete(current) && !current.materials.length,
          error: {},
        },
      }),
      {}
    );
    const door = Object.keys(doors)[0];

    await this.setState({doors, door});
    await this.getDoorMaterialHistory(((doors[door] || {}).door || {}).id)

    !isResolved(this.props.intervention) && this.searchDoor('')
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  syncIntervention = () => {
    const {intervention, dispatch} = this.props;
    const doors = Object.values(this.state.doors).map(
      ({error, ...door}) => door
    );
    dispatch(updateInterventionAct({...intervention, doors}));
  };

  getDoorMaterialHistory = async (doorId) => {
    const doorMaterialHistory = !!doorId ? await listDoorMaterials(doorId) : [];
    this.setState({doorMaterialHistory});
  }

  onChange = (key, isDoor = false) => (value) => {
    const {loading, doors, door} = this.state;
    if (loading) return;
    const addon = isDoor
      ? {
        door: !!doors[door].door
          ? {...doors[door].door, [key]: value}
          : {[key]: value},
      }
      : {[key]: value};
    if (key === 'indoorLocation') this.searchDoor(value);
    this.setState({
      doors: {
        ...doors,
        [door]: {
          ...doors[door],
          productId: key === 'manufacturer' ? '' : doors[door].productId,
          productTypeId: ['manufacturer', 'productId'].includes(key)
            ? ''
            : doors[door].productTypeId,
          ...addon,
          error: Object.entries(doors[door].error).reduce(
            (combined, [errorKey, value]) =>
              key === errorKey ? combined : {...combined, [errorKey]: value},
            {}
          ),
        },
      },
    });
  };

  manufacturers = () =>
    [...this.props.manufacturers].map(({id, name}) => ({
      value: id,
      label: name,
    }));

  products = () => {
    // TODO: I18n
    const {manufacturers} = this.props;
    const {doors, door: doorID} = this.state;
    const door = doors[doorID];
    const empty = [{value: '', label: 'Select a manufacturer first'}];
    if (!door) return empty;
    const manufacturer = [...manufacturers].find(
      ({id}) => id === door.manufacturer
    );
    return !manufacturer
      ? empty
      : [...manufacturer.products].map(({id, name}) => ({
        value: id,
        label: name,
      }));
  };

  productTypes = () => {
    // TODO: I18n
    const {manufacturers} = this.props;
    const {doors, door: doorID} = this.state;
    const door = doors[doorID];
    const empty = [{value: '', label: 'Select a product first'}];
    if (!door) return empty;
    const manufacturer = [...manufacturers].find(
      ({id}) => id === door.manufacturer
    );
    if (!manufacturer) return empty;
    const product = [...manufacturer.products].find(
      ({id}) => id === door.productId
    );
    return !product
      ? empty
      : !!product.productTypes.length
        ? [...product.productTypes].map(({id, name}) => ({
          value: id,
          label: name,
        }))
        : [{value: '', label: 'Various types'}];
  };

  availableFaults = () => {
    const {faults} = this.props;
    const {doors, door} = this.state;
    const selectedFaults = [...doors[door].faults]
      .filter(({fault}) => !!fault)
      .map(({fault: {id}}) => id);
    return [...faults].filter((fault) => !selectedFaults.includes(fault.id));
  };

  // TODO: I18n
  faultOptions = () => [
    ...[...this.availableFaults()].map((fault) => ({
      value: fault.id,
      label: fault.name,
    })),
    {
      value: 'other',
      label: 'Other',
    },
  ];

  selectedFaults = () => {
    const {doors, door} = this.state;
    return [...doors[door].faults].map(
      ({id, name, fault, updated = false}) => ({
        value: id,
        disabled: !!fault,
        label: !!fault && !updated ? fault.name : name,
      })
    );
  };

  addFault = async (faultID) => {
    // TODO: I18n
    const {loading, updatingFaults, door} = this.state;
    const fault = [...this.availableFaults()].find((r) => r.id === faultID);
    if (
      loading ||
      (faultID !== 'other' && !fault) ||
      updatingFaults.includes(faultID)
    )
      return;

    const doorFaultID = v4();

    const doorFault =
      faultID === 'other'
        ? {
          name: 'other',
        }
        : {
          faultId: fault.id,
        };

    await this.setState({
      updatingFaults: [...this.state.updatingFaults, doorFaultID],
      doors: this.addOption(door, 'faults', {
        id: doorFaultID,
        name: doorFault.name || fault.name,
        fault,
      }),
    });

    try {
      const newFault = await createFaultApi(door, doorFault);
      if (!this.mounted) return;
      await this.setState({
        updatingFaults: [...this.state.updatingFaults].filter(
          (r) => r !== doorFaultID
        ),
        doors: this.switchOption(door, 'faults', doorFaultID, newFault),
      });
      this.syncIntervention();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingFaults: [...this.state.updatingFaults].filter(
          (r) => r !== doorFaultID
        ),
        doors: this.removeOption(door, 'faults', doorFaultID),
      });
    }
  };

  changeFault = (faultID, key) => (value) => {
    const {loading, updatingFaults, door} = this.state;
    if (loading || updatingFaults.includes(faultID)) return;
    this.setState({
      doors: {
        ...this.state.doors,
        [door]: {
          ...this.state.doors[door],
          faults: [...this.state.doors[door].faults].map((fault) =>
            fault.id === faultID
              ? {...fault, [key]: key === 'name' && !!fault.fault ? fault[key] : value, updated: !fault.fault}
              : fault
          ),
        },
      },
    });
  };

  updateFault = (faultID) => async () => {
    // TODO: I18n
    const {loading, updatingFaults, door, doors} = this.state;
    const rawFault = doors[door].faults.find((r) => r.id === faultID);
    if (loading || updatingFaults.includes(faultID) || !rawFault) return;

    const {name, fault: faultObj, updated} = rawFault;

    const fault = {
      name: updated || updated === undefined ? name : null,
      faultId: updated || !faultObj ? null : faultObj.id,
      version: rawFault.version,
    };

    const blankValueError = !fault.name && !fault.faultId && "Check all values. Empty value should be removed.";
    this.setState({doors: {...doors, [door]: {...doors[door], error: {...doors[door].error, fault: blankValueError}}}})
    if (!!blankValueError) return;

    this.setState({
      updatingFaults: [...this.state.updatingFaults, faultID],
    });

    try {
      const newFault = await updateFaultApi(door, faultID, fault);
      if (!this.mounted) return;
      await this.setState({
        updatingFaults: [...this.state.updatingFaults].filter(
          (r) => r !== faultID
        ),
        doors: this.switchOption(door, 'faults', faultID, newFault),
      });
      this.syncIntervention();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingFaults: [...this.state.updatingFaults].filter(
          (r) => r !== faultID
        ),
      });
    }
  };

  removeFault = (faultID) => async () => {
    // TODO: I18n
    const {loading, updatingFaults, door} = this.state;
    if (loading || updatingFaults.includes(faultID)) return;

    this.setState({
      updatingFaults: [...this.state.updatingFaults, faultID],
    });

    try {
      await deleteFaultApi(door, faultID);
      if (!this.mounted) return;
      await this.setState({
        updatingFaults: [...this.state.updatingFaults].filter(
          (r) => r !== faultID
        ),
        doors: this.removeOption(door, 'faults', faultID),
      });
      this.syncIntervention();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingFaults: [...this.state.updatingFaults].filter(
          (r) => r !== faultID
        ),
      });
    }
  };

  availableRepairs = () => {
    const {repairs} = this.props;
    const {doors, door} = this.state;
    const selectedRepairs = [...doors[door].repairs]
      .filter(({repair}) => !!repair)
      .map(({repair: {id}}) => id);
    return [...repairs].filter(
      (repair) => !selectedRepairs.includes(repair.id)
    );
  };

  // TODO: I18n
  repairOptions = () => [
    ...[...this.availableRepairs()].map((repair) => ({
      value: repair.id,
      label: repair.description,
    })),
    {
      value: 'other',
      label: 'Other',
    },
  ];

  selectedRepairs = () => {
    const {doors, door} = this.state;
    return [...doors[door].repairs].map(
      ({id, description, repair, updated = false}) => ({
        value: id,
        disabled: !!repair,
        label: !!repair && !updated ? repair.description : description,
      })
    );
  };

  addOption = (door, option, object) => ({
    ...this.state.doors,
    [door]: {
      ...this.state.doors[door],
      [option]: [...this.state.doors[door][option], object],
    },
  });

  switchOption = (door, option, oldID, object) => ({
    ...this.state.doors,
    [door]: {
      ...this.state.doors[door],
      [option]: [...this.state.doors[door][option]].map((opt) =>
        opt.id === oldID ? object : opt
      ),
    },
  });

  removeOption = (door, option, objectID) => ({
    ...this.state.doors,
    [door]: {
      ...this.state.doors[door],
      [option]: [...this.state.doors[door][option]].filter(
        ({id}) => id !== objectID
      ),
    },
  });

  addRepair = async (repairID) => {
    // TODO: I18n
    const {loading, updatingRepairs, door} = this.state;
    const repair = [...this.availableRepairs()].find((r) => r.id === repairID);
    if (
      loading ||
      (repairID !== 'other' && !repair) ||
      updatingRepairs.includes(repairID)
    )
      return;

    const doorRepairID = v4();

    const doorRepair =
      repairID === 'other'
        ? {
          description: 'other',
        }
        : {
          repairId: repair.id,
        };

    await this.setState({
      updatingRepairs: [...this.state.updatingRepairs, doorRepairID],
      doors: this.addOption(door, 'repairs', {
        id: doorRepairID,
        description: doorRepair.description || repair.description,
        repair,
      }),
    });

    try {
      const newRepair = await createRepairApi(door, doorRepair);
      if (!this.mounted) return;
      await this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== doorRepairID
        ),
        doors: this.switchOption(door, 'repairs', doorRepairID, newRepair),
      });
      this.syncIntervention();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== doorRepairID
        ),
        doors: this.removeOption(door, 'repairs', doorRepairID),
      });
    }
  };

  changeRepair = (repairID, key) => (value) => {
    const {loading, updatingRepairs, door} = this.state;
    if (loading || updatingRepairs.includes(repairID)) return;
    this.setState({
      doors: {
        ...this.state.doors,
        [door]: {
          ...this.state.doors[door],
          repairs: [...this.state.doors[door].repairs].map((repair) =>
            repair.id === repairID
              ? {...repair, [key]: value, updated: true}
              : repair
          ),
        },
      },
    });
  };

  updateRepair = (repairID) => async () => {
    // TODO: I18n
    const {loading, updatingRepairs, door, doors} = this.state;
    const rawRepair = doors[door].repairs.find((r) => r.id === repairID);
    if (loading || updatingRepairs.includes(repairID) || !rawRepair) return;

    const {description, repair: repairObj, updated} = rawRepair;

    const repair = {
      description: updated ? description : null,
      repairId: updated || !repairObj ? null : repairObj.id,
      version: rawRepair.version,
    };

    const blankValueError = !repair.description && !repair.repairId && "Check all values. Empty value should be removed.";
    this.setState({doors: {...doors, [door]: {...doors[door], error: {...doors[door].error, repair: blankValueError}}}})
    if (!!blankValueError) return;

    this.setState({
      updatingRepairs: [...this.state.updatingRepairs, repairID],
    });

    try {
      const newRepair = await updateRepairApi(door, repairID, repair);
      if (!this.mounted) return;
      await this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== repairID
        ),
        doors: this.switchOption(door, 'repairs', repairID, newRepair),
      });
      this.syncIntervention();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== repairID
        ),
      });
    }
  };

  removeRepair = (repairID) => async () => {
    // TODO: I18n
    const {loading, updatingRepairs, door} = this.state;
    if (loading || updatingRepairs.includes(repairID)) return;

    this.setState({
      updatingRepairs: [...this.state.updatingRepairs, repairID],
    });

    try {
      await deleteRepairApi(door, repairID);
      if (!this.mounted) return;
      await this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== repairID
        ),
        doors: this.removeOption(door, 'repairs', repairID),
      });
      this.syncIntervention();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingRepairs: [...this.state.updatingRepairs].filter(
          (r) => r !== repairID
        ),
      });
    }
  };

  availableMaterials = () => {
    const {materials} = this.props;
    const {doors, door} = this.state;
    const selectedMaterials = [...doors[door].materials]
      .filter(({material}) => !!material)
      .map(({material: {id}}) => id);
    return [...materials].filter(
      (material) => !selectedMaterials.includes(material.id)
    );
  };

  // TODO: I18n
  materialOptions = () => [
    ...[...this.availableMaterials()].map((material) => ({
      value: material.id,
      label: material.name,
    })),
    {
      value: 'other',
      label: 'Other',
    },
  ];

  warrantyTitle = (material) => {
    const {doorMaterialHistory} = this.state;
    const {intervention} = this.props;

    if (!material) return '';

    const materialHist = [...doorMaterialHistory].filter(d => d.interventionId !== intervention.id).filter(d => d.materialId === material.id).sort((a, b) => a.date > b.date ? -1 : 1)
    const txt = !!materialHist.find(d => !!d.warrantyTill && d.warrantyTill > Date.now()) ? 'warranty still valid from' : 'last change '
    return !!materialHist.length ? ' (' + txt + ' ' + dateTimeType(materialHist[0].date).format() + ')' : ''
  }

  selectedMaterials = () => {
    const {doors, door} = this.state;

    return [...doors[door].materials].map(
      ({id, name, quantity, material, booked, updated = false}) => ({
        value: id,
        label: (!!material && !updated ? material.name : name) + this.warrantyTitle(material),
        quantity,
        unit: !!material ? material.unit : 'pc',
        guarantee: !!material ? !!material.warrantyDuration : false,
        booked,
      })
    );
  };

  addMaterial = async (materialID) => {
    // TODO: I18n
    const {loading, updatingMaterials, door} = this.state;
    const material = [...this.availableMaterials()].find(
      (r) => r.id === materialID
    );
    if (
      loading ||
      (materialID !== 'other' && !material) ||
      updatingMaterials.includes(materialID)
    )
      return;

    const doorMaterialID = v4();

    const doorMaterial =
      materialID === 'other'
        ? {
          name: 'other',
          quantity: 1,
        }
        : {
          quantity: 1,
          materialId: material.id,
        };

    await this.setState({
      updatingMaterials: [...this.state.updatingMaterials, doorMaterialID],
      doors: this.addOption(door, 'materials', {
        id: doorMaterialID,
        name: doorMaterial.name || material.name,
        quantity: 1,
        material,
      }),
    });

    try {
      const newMaterial = await createMaterialApi(door, doorMaterial);
      if (!this.mounted) return;
      await this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== doorMaterialID
        ),
        doors: this.switchOption(
          door,
          'materials',
          doorMaterialID,
          newMaterial
        ),
      });
      this.syncIntervention();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== doorMaterialID
        ),
        doors: this.removeOption(door, 'materials', doorMaterialID),
      });
    }
  };

  changeMaterial = (materialID, key) => (value) => {
    const {loading, updatingMaterials, door} = this.state;
    if (loading || updatingMaterials.includes(materialID)) return;
    this.setState({
      doors: {
        ...this.state.doors,
        [door]: {
          ...this.state.doors[door],
          materials: [...this.state.doors[door].materials].map((material) =>
            material.id === materialID
              ? {
                ...material,
                [key]: key === 'name' && !!material.material ? material[key] : value,
                updated: key === 'name' && !material.material ? true : material.updated,
              }
              : material
          ),
        },
      },
    });
  };

  updateMaterial = (materialID) => async () => {
    // TODO: I18n
    const {loading, updatingMaterials, door, doors} = this.state;
    const rawMaterial = doors[door].materials.find((r) => r.id === materialID);
    if (loading || updatingMaterials.includes(materialID) || !rawMaterial)
      return;

    const {name, quantity, material: materialObj, updated} = rawMaterial;

    const material = {
      name: updated || updated === undefined ? name : null,
      quantity,
      materialId: updated || !materialObj ? null : materialObj.id,
      version: rawMaterial.version,
    };

    const blankValueError = !material.name && !material.materialId && "Check all values. Empty value should be removed.";
    this.setState({
      doors: {
        ...doors,
        [door]: {...doors[door], error: {...doors[door].error, material: blankValueError}}
      }
    })
    if (!!blankValueError) return;

    this.setState({
      updatingMaterials: [...this.state.updatingMaterials, materialID],
    });

    try {
      const newMaterial = await updateMaterialApi(door, materialID, material);
      if (!this.mounted) return;
      await this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== materialID
        ),
        doors: this.switchOption(door, 'materials', materialID, newMaterial),
      });
      this.syncIntervention();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== materialID
        ),
      });
    }
  };

  removeMaterial = (materialID) => async () => {
    // TODO: I18n
    const {loading, updatingMaterials, door} = this.state;
    if (loading || updatingMaterials.includes(materialID)) return;

    this.setState({
      updatingMaterials: [...this.state.updatingMaterials, materialID],
    });

    try {
      await deleteMaterialApi(door, materialID);
      if (!this.mounted) return;
      await this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== materialID
        ),
        doors: this.removeOption(door, 'materials', materialID),
      });
      this.syncIntervention();
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Something went wrong. Try again.');
      this.setState({
        updatingMaterials: [...this.state.updatingMaterials].filter(
          (r) => r !== materialID
        ),
      });
    }
  };

  onDoor = (door) => this.setState({door});

  searchDoor = async (indoorLocation = '', doorId = null) => {
    const {searchDoors, door} = this.state;
    const doorss = this.state.doors;
    const {intervention: {customerBranch, doors}} = this.props;
    if (!customerBranch || !customerBranch.id)
      return this.setState({
        searchDoors,
        searchingForDoor: false,
      });

    this.setState({searchingForDoor: true});

    this.lastDoorSearch = getTime(new Date());

    try {
      const filter = [
        doorId && `id:EQ:${doorId}`,
        !!indoorLocation &&
        !!indoorLocation.trim().length &&
        `indoorLocation:LIKEIC:%${indoorLocation}%`,
        !doorId && `customerBranchId:EQ:${customerBranch.id}`,
      ].filter((f) => !!f);
      const searchedDoors = await listDoorsApi({
        orderBy: [''],
        filter: encodeURIComponent(filter.join(',')),
        rel: 'door.images',
      });
      if (!this.mounted) return;
      this.setState({
        searchDoors: [...searchedDoors].filter(
          function (e) {
            return [...this].filter(r => (r.door || {}).id === e.id).length < 1 || e.id === doorss[door].door.id;
          },
          doors
        ),
        searchingForDoor: false,
      });
    } catch (error) {
      this.setState({searchingForDoor: false});
      // Do nothing for now
    }
  };

  searchedDoors = () => {
    const {searchDoors} = this.state;
    const options = !searchDoors
      ? []
      : [...searchDoors].map(
        ({id, product, indoorLocation, buildingFloor, productType}) => ({
          value: id,
          label: `${indoorLocation} [${buildingFloor}] ${product.manufacturer.name} ${product.name} ${
            !!productType ? productType.name : ''
          }`,
        })
      );

    return [...options, {
      value: null,
      label: `Create new`,
    }]
  };

  onSearchedDoor = async (doorID) => {
    const {searchDoors, doors, door} = this.state;
    if (!searchDoors) return;
    const searchedDoor = [...searchDoors].find(({id}) => doorID === id);
    if (doorID !== null && (!searchedDoor || !doors[door])) return;
    const {
      findingsAndRemarks,
      customerRemarks,
      internalRemarks,
      replacementNeeded,
      workingProperly,
      prepareOffer,
      version
    } = doors[door];

    const newInterventionDoor = await updateInterventionDoorApi(
      door,
      {
        doorId: doorID,
        findingsAndRemarks,
        customerRemarks,
        internalRemarks,
        replacementNeeded,
        workingProperly,
        prepareOffer,
        version
      },
      {rel: 'door.images'}
    );
    if (!this.mounted) return;

    const newDoor = {
      ...doors[door],
      ...(!!newInterventionDoor.door ? newInterventionDoor : {...newInterventionDoor, door: null}),
      manufacturer: !!newInterventionDoor.door
        ? newInterventionDoor.door.product.manufacturer.id
        : '',
      productId: !!newInterventionDoor.door ? newInterventionDoor.door.product.id : null,
      productTypeId:
        newInterventionDoor.door && !!newInterventionDoor.door.productType
          ? newInterventionDoor.door.productType.id
          : null,
    }

    const newDoors = {
      ...doors,
      [door]: newDoor,
    };

    await this.setState({doors: newDoors});
    if ((doors[door].door || {}).id !== doorID) {
      await this.getDoorMaterialHistory(doorID)
    }
    this.syncIntervention();

    const {intervention: {customerBranch}} = this.props;
    if (!!(searchedDoor || {}).customerBranchId && customerBranch.id !== (searchedDoor || {}).customerBranchId) {
      return alertify.warning('You selected the door from different customer branch, should you change the ownership?');
    }
  };

  save = async () => {
    // TODO: I18n
    const {history, intervention} = this.props;
    const {loading, doors: oldDoors, door: doorID} = this.state;
    const rawInterventionDoor = oldDoors[doorID];
    if (loading || !rawInterventionDoor) return;

    const {
      door: rawDoor,
      faults,
      repairs,
      materials,
      findingsAndRemarks,
      customerRemarks,
      internalRemarks,
      replacementNeeded,
      workingProperly,
      prepareOffer,
      manufacturer,
      productId,
      productTypeId,
      noMaterials,
    } = rawInterventionDoor;

    const productTypes = this.productTypes();
    const error = {};

    if (!manufacturer) error.manufacturer = 'Select an option';
    if (!productId) error.productId = 'Select an option';
    if (
      !productId ||
      (!!productId &&
        !productTypeId &&
        !(productTypes.length === 1 && productTypes[0].value === ''))
    )
      error.productTypeId = 'Select an option';
    if (!rawDoor || !rawDoor.doorSerial || !rawDoor.doorSerial.trim().length)
      error.doorSerial = 'Should not be empty';
    if (
      !rawDoor ||
      !rawDoor.electronicsSerial ||
      !rawDoor.electronicsSerial.trim().length
    )
      error.electronicsSerial = 'Should not be empty';
    if (!rawDoor || !rawDoor.motorSerial || !rawDoor.motorSerial.trim().length)
      error.motorSerial = 'Should not be empty';
    if (
      !rawDoor ||
      !rawDoor.indoorLocation ||
      !rawDoor.indoorLocation.trim().length
    )
      error.indoorLocation = 'Should not be empty';
    if (
      !rawDoor ||
      !rawDoor.buildingFloor ||
      !rawDoor.buildingFloor.trim().length
    )
      error.buildingFloor = 'Should not be empty';
    if (!faults.length) error.fault = 'Select an option';
    if (!repairs.length) error.repair = 'Select an option';
    if (!noMaterials && !materials.length) error.material = 'Select an option';

    if (!!Object.keys(error).length) {
      this.setState({
        doors: {...oldDoors, [doorID]: {...rawInterventionDoor, error}},
      });
      return alertify.warning('Check the form');
    }

    const interventionDoor = {
      findingsAndRemarks,
      customerRemarks,
      internalRemarks,
      replacementNeeded,
      workingProperly,
      prepareOffer,
      version: rawInterventionDoor.version,
    };

    const door = {
      productId,
      productTypeId: productTypeId || null,
      doorSerial: rawDoor.doorSerial,
      electronicsSerial: rawDoor.electronicsSerial,
      motorSerial: rawDoor.motorSerial,
      redundancy: rawDoor.redundancy,
      indoorLocation: rawDoor.indoorLocation,
      buildingFloor: rawDoor.buildingFloor,
      customerBranchId: intervention.customerBranch.id,
    };

    if (!!rawDoor.id) door.version = rawDoor.version;

    this.setState({loading: true});

    try {
      const newDoor = await (!!rawDoor.id
        ? updateDoorApi(rawDoor.id, door)
        : createDoorApi(door));
      interventionDoor.doorId = newDoor.id;
      const newInterventionDoor = await updateInterventionDoorApi(
        doorID,
        interventionDoor
      );
      if (!this.mounted) return;
      const doors = {
        ...this.state.doors,
        [doorID]: {
          ...this.state.doors[doorID],
          ...newInterventionDoor,
          door: {...newDoor},
        },
      };
      await this.setState({doors});
      this.syncIntervention();
      const unfinishedDoor = Object.values(doors).find(
        (d) => !isDoorComplete(d)
      );
      if (!unfinishedDoor)
        return history.push(confirmationRoute(intervention.id));
      this.setState({loading: false, door: unfinishedDoor.id});
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Could not save. Try again.');
      this.setState({loading: false});
    }
  };

  scan = (scan) => this.setState({scan, scanLoading: scan && this.state.scanLoading})

  onScan = async (content) => {

    const doorId = ((this.state.doors[this.state.door] ||{}).door||{}).id;
    const isSearch = !doorId;

    if (isSearch) {
      try {
        if (!content) return;
        this.setState({scanLoading: true})
        const friendlyId = new URL(content).pathname.split('/').filter(Boolean).pop()
        const labelRef = await getLabelRefApi(friendlyId);
        if (labelRef.context !== 'SERVICE_ITEM') {
          alertify.error('This QR code is not assigned to the door.');
        }
        const doorId = labelRef.refId;

        await this.searchDoor(null, doorId);
        await this.onSearchedDoor(doorId);
      } catch (error) {
        console.error(error);
        alertify.error('Unable to find door using QR code. ' + parseServerFault(error));
      }
    } else {
      try {
        if (!content || !doorId) return;
        this.setState({scanLoading: true})

        const friendlyId = new URL(content).pathname.split('/').filter(Boolean).pop()
        const labelRef = {
          friendlyId,
          refId: doorId,
          context: 'SERVICE_ITEM'
        }

        await createLabelRefApi(labelRef);
        alertify.info("Label created. Stick it to place to be scanned")
      } catch (error) {
        console.error(error);
        alertify.error('Unable to register QR code. ' + parseServerFault(error));
      }
    }

    this.scan(false);
  }

  render() {
    // TODO: I18n
    const {intervention, user} = this.props;
    const {
      loading,
      scan,
      scanLoading,
      searchingForDoor,
      doors,
      door,
      updatingFaults,
      updatingMaterials,
      updatingRepairs,
    } = this.state;

    const intDoor = {...doors[door]};

    return !door ? null : (
      <Fragment>
        <WorkingLog
          doorSerialLabel="Door serial number"
          electronicsSerialLabel="Electronic serial number"
          motorSerialLabel="Motor serial number"
          technicianDescriptionLabel="Actual fault as identified by technician"
          repairDescriptionLabel="Repair description"
          replacedMaterialLabel="Required / replaced material"
          findingsRemarksLabel="Findings and remarks by technician"
          customerRemarksLabel="Remarks by Customer"
          internalRemarksLabel="Internal remarks for company reference"
          replacementNeededLabel="Replacement needed"
          doorsWorkingLabel="Doors are working properly"
          prepareOfferLabel="Prepare offer for customer"
          buttonLabel="Save and proceed"
          manufacturerLabel="Manufacturer"
          doorVersionLabel="Door version"
          doorTypeLabel="Door type"
          noMaterialsLabel="No required materials"
          indoorLocationLabel="Door location"
          buildingFloorLabel="Floor"
          redundancyLabel="Redundancy"
          scan={scan}
          toggleScan={this.scan}
          scanLabel={scan ? "Scanning..." : ((intDoor.door || {}).id && "Scan & stick QR") || "Search by QR"}
          serviceItemUrl={(intDoor.door || {}).id && serviceItemRoute((intDoor.door || {}).id)}
          loading={loading}
          resolved={isResolved(intervention)}
          assigned={isAssigned(user, intervention)}
          doors={Object.values(doors)}
          door={intDoor}
          faults={this.faultOptions()}
          selectedFaults={this.selectedFaults()}
          repairs={this.repairOptions()}
          selectedRepairs={this.selectedRepairs()}
          materials={this.materialOptions()}
          selectedMaterials={this.selectedMaterials()}
          manufacturers={this.manufacturers()}
          products={this.products()}
          productTypes={this.productTypes()}
          updatingFaults={updatingFaults}
          updatingRepairs={updatingRepairs}
          updatingMaterials={updatingMaterials}
          onChange={this.onChange}
          addFault={this.addFault}
          changeFault={this.changeFault}
          updateFault={this.updateFault}
          removeFault={this.removeFault}
          addRepair={this.addRepair}
          changeRepair={this.changeRepair}
          updateRepair={this.updateRepair}
          removeRepair={this.removeRepair}
          addMaterial={this.addMaterial}
          changeMaterial={this.changeMaterial}
          updateMaterial={this.updateMaterial}
          removeMaterial={this.removeMaterial}
          onDoor={this.onDoor}
          onSave={this.save}
          doorSearchable={true}
          searchedDoors={this.searchedDoors()}
          noSuggestionLabel="No suggestions found"
          searchingForDoor={searchingForDoor}
          onSearchedDoor={this.onSearchedDoor}
        />
        <QrScanModal
          loading={scanLoading}
          onScan={this.onScan}
          onClose={this.scan.bind(this, false)}
          visible={scan}/>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  intervention: state.intervention.intervention,
  faults: state.fault.faults,
  materials: state.material.materials,
  manufacturers: state.manufacturer.manufacturers,
  repairs: state.repair.repairs,
  user: state.auth.user,
}))(withRouter(WorklogContainer));
