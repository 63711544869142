import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Container, Row} from 'doorson-ui';
import Content from './components/Content';
import Right from "./components/Right";

const ActivityHeader = ({children, onShowMessageModal, sendMessageLabel}) => (
  <Container>
    <Row>
      <Column>
        <Content>{children}</Content>
        <Right>
          <Button
            size="normal"
            theme="blue"
            preIcon="message"
            onClick={onShowMessageModal}>
            {sendMessageLabel}
          </Button>
        </Right>
      </Column>
    </Row>
  </Container>
);

ActivityHeader.propTypes = {
  children: PropTypes.node,
  sendMessageLabel: PropTypes.string,
  onShowMessageModal: PropTypes.func,
};

export default ActivityHeader;
