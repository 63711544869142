import styled from 'styled-components';

const ChartTitle = styled.div`
  width: 50%;
  font-size: 18px;
  padding: 5px;
  color: rgba(0,0,0,0.8);
  position: relative;
  float: left;
  user-select: none;
`;

export default ChartTitle;
