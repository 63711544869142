import styled from 'styled-components';
import {colors} from 'doorson-ui';

const {black} = colors;

const TextBlock = styled.span`
  color: ${({color}) => (color || black)};
`;

export default TextBlock;
