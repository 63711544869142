import styled from 'styled-components';

const PriorityLabel = styled.div`
  width: 100%;
  flex: 1;
  font-size: 12px;
  margin-left: 10px;
`;

export default PriorityLabel;
