import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Api
import getMaintenanceContractByIDApi from '../../api/getByID.api.maintenanceContract';
import generateMaintenanceApi from '../../api/generate.api.maintenanceContrac';
import deleteMaintenanceContractApi from '../../api/delete.api.maintenanceContract';

// Alert
import {alertify} from 'doorson-ui';

// Components
import MaintenanceContractLoader from '../../components/MaintenanceContractLoader/MaintenanceContractLoader';
import MaintenanceContractLayout from '../../components/MaintenanceContractLayout/MaintenanceContractLayout';
import GenerateMaintenance from '../../components/GenerateMaintenance/GenerateMaintenance';
import DeleteMaintenanceContract from '../../components/DeleteMaintenanceContract/DeleteMaintenanceContract';
import MaintenanceContract from '../../components/MaintenanceContract/MaintenanceContract';
import MaintenanceContractCustomerInfo from '../../../customer/components/MaintenanceContractCustomerInfo/MaintenanceContractCustomerInfo';
import BranchSelection from '../../../customerBranch/components/BranchSelection/BranchSelection';

// Routes
import maintenancesRoute from '../../pages/MaintenanceContractsAdminPage/route';
import {connect} from 'react-redux';

class MaintenanceContractContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object,
  };

  state = {
    loading: false,
    generating: false,
    maintenanceContract: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    this.getMaintenanceContract();
  };

  getCountryByCode = (code) =>
    [...this.props.countries].find((country) => country.code === code);

  getMaintenanceContract = async () => {
    // TODO: I18n
    const {id, history} = this.props;
    this.setState({loading: true});

    try {
      const maintenanceContract = await getMaintenanceContractByIDApi(id);
      if (!this.mounted) return;
      this.setState({loading: false, maintenanceContract});
    } catch (error) {
      if (!this.mounted) return;
      alertify.warning('This contract does not exists');
      history.replace(maintenancesRoute());
    }
  };

  generate = async () => {
    // TODO: I18n
    const {generating, maintenanceContract} = this.state;
    if (generating || !maintenanceContract) return;

    this.setState({generating: true});

    try {
      await generateMaintenanceApi(maintenanceContract.id);
      if (!this.mounted) return;
      alertify.success('Maintenance generated');
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Maintenance could not be generated');
    }

    this.setState({generating: false});
  };

  delete = async () => {
    const {history} = this.props;
    const {loading, maintenanceContract} = this.state;

    if (loading || !maintenanceContract) return;

    this.setState({loading: true});

    try {
      await deleteMaintenanceContractApi(maintenanceContract.id);
      history.replace(maintenancesRoute());
    } catch (error) {
      alertify.error('Could not delete the contract');
      this.setState({loading: false});
    }
  };

  render() {
    const {loading, generating, maintenanceContract} = this.state;
    return loading || !maintenanceContract ? (
      <MaintenanceContractLoader />
    ) : (
      <MaintenanceContractLayout
        generate={
          <GenerateMaintenance loading={generating} onClick={this.generate}>
            Generate Maintenances
          </GenerateMaintenance>
        }
        customer={
          <MaintenanceContractCustomerInfo
            companyLabel="Company name"
            registrationNumberLabel="Registration number"
            firstNameLabel="First Name"
            lastNameLabel="Last Name"
            addressLabel="Address"
            zipLabel="Zip Code"
            cityLabel="City"
            countryLabel="Country"
            phoneLabel="Phone"
            emailLabel="Email"
            customer={maintenanceContract.customer}
          />
        }
        contract={
          <MaintenanceContract
            validFromLabel="Valid From"
            validTillLabel="Valid Till"
            periodStartLabel="Period Start"
            periodEndLabel="Period End"
            contract={maintenanceContract}
          />
        }
        branch={
          <BranchSelection
            branches={maintenanceContract.customerBranches}
            getCountryByCode={this.getCountryByCode}
            branchesLabel="Branches"
            noBranchesLabel="This customer doesn't have any branches available. Insert some before continuing"
            selectAllLabel="Select All"
            searchLabel="Search"
            noBranchesFoundLabel="No Branches Found"
          />
        }
        remove={
          <DeleteMaintenanceContract onClick={this.delete}>
            Delete
          </DeleteMaintenanceContract>
        }
      />
    );
  }
}

export default connect((state) => ({
  countries: state.country.countries,
}))(withRouter(MaintenanceContractContainer));
