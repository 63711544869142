import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteRepairApi = (id) =>
  f3tch(`/repairs/${id}`)
    .profile(profile())
    .delete();
export default deleteRepairApi;
