import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const getInvoiceStats = (query = {}) =>
  f3tch('/statistics/invoices')
    .profile(profile())
    .query(query)
    .get();
export default getInvoiceStats;
