import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const DayHour = styled.div`
  width: 100%;
  height: ${({height}) => `${height}px`};
  border-style: none none dashed solid;
  border-width: 1px;
  border-color: ${grey};
`;

export default DayHour;
