import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteInterventionDoorRepairApi = (interventionDoorID, repairID) =>
  f3tch(`/intervention-doors/${interventionDoorID}/repairs/${repairID}`)
    .profile(profile())
    .delete();
export default deleteInterventionDoorRepairApi;
