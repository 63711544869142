import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/file.profile.api';

const previewInterventionApi = (interventionID, query = {rel: '*'}) =>
  f3tch(`/interventions/${interventionID}/preview`)
    .profile(profile())
    .query(query)
    .put();
export default previewInterventionApi;
