import styled from 'styled-components';

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 34px;
  right: 34px;
  z-index: 10;
  width: 176px;
`;

export default ButtonContainer;
