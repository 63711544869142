import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Api
import createWarehouseOrderApi from '../../../warehouseOrder/api/create.api.order';
import createWarehouseTransferOrderApi from '../../../warehouseOrder/api/createTransfer.api.order';
import updateWarehouseOrderApi from '../../../warehouseOrder/api/update.api.order';
import getWarehouseOrderApi from '../../../warehouseOrder/api/get.api.order';

import createWarehouseOrderItemApi from '../../../warehouseOrderItem/api/create.api.orderItem';
// Alert
// Actions
import {getWarehouseItems as getWarehouseItemsAct} from '../../redux/actions';
// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';

// Containers
// Routes
import warehousesRoute from '../../pages/WarehousesPage/route';
import orderRoute from '../../../warehouseOrder/pages/WarehouseOrderPage/route';
import WarehouseIdentsSearch from "../../components/WarehouseIdentsSearch/WarehouseIdentsSearch";
import {alertify} from "doorson-ui";
import WarehouseIdents from "../../components/WarehouseIdents/WarehouseIdents";
import WarehouseOrderButton from "../../../warehouseOrder/components/WarehouseOrderButton/WarehouseOrderButton";
import WarehouseIdentsFilter from "../../components/WarehouseIdentsFilter/WarehouseIdentsFilter";
import ButtonContainer from "../../components/WarehouseIdents/components/ButtonContainer";
import WarehouseIdentsHeader from "../../components/WarehouseIdentsHeader/WarehouseIdentsHeader";
import sortByString from "../../../lib/sortByString";
import getTodaysVehicleByDriver from "../../../vehicle/lib/getTodaysVehicleByDriver.lib.vehicle";
import listWarehousesApi from "../../api/get.api.warehouse";
import getWarehouseName from "../../lib/name.lib.warehouse";
import TransferIdentVehicleModal from "../../components/TransferIdentVehicleModal/TransferIdentVehicleModal";

// Roles

class WarehouseContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    warehouseId: PropTypes.string,
    warehouseItems: PropTypes.array,
    filter: PropTypes.string,
    user: PropTypes.object,
    users: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    warehouse: {},
    selectedVehicle: null,
    transferIdent: null,
    saving: false,
    warehouses: [],
    loading: true,
    search: '',
    qty: {},
    sort: [{key: 'sku', direction: 'asc'}],
    order: null,
    orders: [],
    i: 0
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  init = async () => {
    await this.setState({loading: true})
    const {vehicles, user} = this.props;
    const selectedVehicle = getTodaysVehicleByDriver({vehicles, driverId: user.id});
    await Promise.all([
      this.getWarehouses(),
      this.getDraftOrder(),
      this.getWarehouseItems(),
    ]);

    this.setState({selectedVehicle, loading: false})
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getDraftOrder = async () => {
    const {user, warehouseId} = this.props;

    const filter = encodeURIComponent('status:EQ:DRAFT,requesterPersonId:EQ:' + user.id + ',sourceWarehouseId:EQ:' + warehouseId);

    try {
      const orders = await getWarehouseOrderApi({filter});
      this.setState({orders, i: 0, order: orders.length > 0 ? orders[0] : null});
    } catch (error) {
      alertify.warning('Active order cannot be obtained');
    }
  }

  getWarehouses = async () => {
    const {history, warehouseId} = this.props;
    try {
      const warehouses = await listWarehousesApi({offset: 0, limit: 300});
      this.setState({warehouse: [...warehouses].find(w => w.id === warehouseId) || {}, warehouses});
    } catch (error) {
      alertify.warning('Warehouse does not exist');
      history.replace(warehousesRoute());
    }
  }

  onSearch = (search) => this.setState({search});

  onFilter = (filter) => {
    this.getWarehouseItems({
      filter,
      sort: [
        {key: 'sku', direction: 'asc'},
      ],
    });
  };

  onSort = (sort) => this.setState({sort});

  onChange = (action, item) => async (val) => {

    const {qty} = this.state;
    switch (action) {
      case 'transfer':
        if (!qty[item.code]) {
          alertify.warning('Quantity value is not set');
          break;
        }
        this.setState({transferIdent: {...item, warehouseId: val}})
        break;
      case 'qty':
        this.setState({qty: {...qty, [item.sku]: val}});
        break;
      case 'addToOrder':
        const {order} = this.state;
        const orderItem = {
          whOrderId: order.id,
          sku: item.sku,
          name: item.name,
          qty: qty[item.sku]
        }
        try {
          await createWarehouseOrderItemApi(orderItem);
          this.setState({qty: {...qty, [item.sku]: undefined}});
          alertify.success('Item added to order');
        } catch (error) {
          alertify.warning('Item cannot be added to order');
        }
        break;
      default:
        return;
    }
  };

  getWarehouseItems = async (data) => {
    this.props.dispatch(getWarehouseItemsAct({...data, warehouseId: this.props.warehouseId}));
  }

  onNewOrder = async () => {
    const {warehouseId, user, vehicles} = this.props;

    const vehicle = getTodaysVehicleByDriver({
      vehicles,
      driverId: user.id,
    });

    const targetWarehouseId = (vehicle || {}).warehouseId;

    const orderCreate = {
      sourceWarehouseId: warehouseId,
      targetWarehouseId,
      requesterPersonId: user.id,
      requesterPersonName: `${user.firstName} ${user.lastName}`,
      receiverComment: null
    }

    try {
      const order = await createWarehouseOrderApi(orderCreate);
      this.setState({order});
    } catch (error) {
      alertify.warning('Cannot create new order');
    }
  }

  onCancelOrder = async () => {
    const {order} = this.state;

    if (!order) return;

    const canceledOrder = await updateWarehouseOrderApi(order.id, {...order, status: 'CANCELED'});

    if (!canceledOrder || canceledOrder.status !== 'CANCELED') {
      alertify.warning('Order cannot be canceled');
    } else {
      this.setState({order: null});
      alertify.success('Order successfully canceled');
    }
  }

  onOpenOrder = async () => {
    const {order} = this.state;
    if (!order) return;
    this.props.history.replace(orderRoute(order.id));
  }

  search = (item) => {
    const {search} = this.state;
    return !search || item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  }

  warehouseItems = () => {
    const {qty, sort} = this.state;
    const {warehouseItems, materials, filter} = this.props;

    const list = [...materials].map((m) => {
      const whItem = warehouseItems.find(w => m.code === w.sku);
      return (whItem && {
        ...m,
        sku: m.code,
        name: m.name || whItem.name,
        stock: whItem.stock,
        qty: qty[m.code]
      }) || (filter === 'ALL' && {
        ...m,
        sku: m.code,
        stock: 0,
        qty: qty[m.code]
      })
    }).filter(o => !!o && this.search(o));

    return sort ? list.sort(sortByString(Array.isArray(sort) ? sort[0] : sort)) : list;
  }

  columns = (orderMode) => [
    {
      key: 'sku',
      label: 'SKU',
      sortable: true,
      span: 1.5
    },
    {
      key: 'name',
      label: 'Name',
      span: 4,
    },
    {
      key: 'stock',
      label: 'In Stock',
      span: 1,
      sortable: false,
    },
    {
      key: 'unit',
      label: 'Unit',
      span: 1,
      sortable: false,
    },
    (orderMode || this.transfer()) && {
      key: 'qty',
      label: 'Quantity',
      span: 1.2,
      sortable: false,
    },
    (orderMode || this.transfer()) && {
      key: 'action',
      label: '',
      span: 3.2,
      sortable: false,
    },
  ].filter((a) => !!a);

  filters = () =>
    [
      {
        id: 'IN_STOCK',
        label: 'In stock',
      },
      {
        id: 'ALL',
        label: 'All material',
      },
    ].filter((act) => !!act);

  isDepot = () => this.state.warehouse.type === 'DEPOT';

  isVehicle = () => this.state.warehouse.type !== 'DEPOT';

  onNextOrder = () => {
    const {orders, i} = this.state;
    const next = i + 1 < orders.length ? i + 1 : 0;
    this.setState({order: orders[next], i: next})
  }

  renderHeader = () => {
    const {warehouse, order, orders, i} = this.state;
    const next = i + 1 < orders.length ? i + 1 : 0;

    return (<Fragment>
      <WarehouseIdentsHeader
        name={warehouse.name}
        address={warehouse.address}/>
      <WarehouseIdentsFilter
        filter={this.props.filter}
        filters={this.filters()}
        onFilter={this.onFilter}>
        {!!orders.length && orders.length > 1 && <ButtonContainer>
          <WarehouseOrderButton theme="white"
                                onClick={this.onNextOrder}>
            Switch to {orders[next].id.substr(0, 8)}...
          </WarehouseOrderButton></ButtonContainer>}
        {!!order && <Fragment><ButtonContainer><WarehouseOrderButton theme="white" onClick={this.onCancelOrder}>
          Cancel order
        </WarehouseOrderButton>
        </ButtonContainer>
          <ButtonContainer>
            <WarehouseOrderButton onClick={this.onOpenOrder}>
              View order {order.id.substr(0, 8)}...
            </WarehouseOrderButton>
          </ButtonContainer></Fragment>}
        {!order && this.isDepot() && <ButtonContainer><WarehouseOrderButton onClick={this.onNewOrder}>
          New order
        </WarehouseOrderButton>
        </ButtonContainer>}

      </WarehouseIdentsFilter>
    </Fragment>);
  };

  renderContentLayout = (content) =>
    <ContentLayout header={this.renderHeader()}>
      {content}
    </ContentLayout>

  transfer = () => {
    const {selectedVehicle} = this.state;
    const {warehouseId} = this.props;
    return !!selectedVehicle && selectedVehicle.warehouseId === warehouseId && this.transferOptions().length > 0 && this.isVehicle();
  }

  doTransfer = async () => {
    const {transferIdent, saving, qty} = this.state;
    const {warehouseId, user} = this.props;
    const quantity = qty[transferIdent.code]
    if (!quantity || saving) return;

    this.setState({saving: true})
    try {

      const transferCreate = {
        sourceWarehouseId: warehouseId,
        targetWarehouseId: transferIdent.warehouseId,
        requesterPersonId: user.id,
        requesterPersonName: `${user.firstName} ${user.lastName}`,
        senderComment: null,
        receiverPersonId: null,
        receiverPersonName: null,
        receiverExtType: null,
        receiverExtId: null,
        receiptDate: null,
        materials: [{
          sku: transferIdent.sku,
          name: transferIdent.name,
          qty: quantity,
          lDimension: null,
          wDimension: null,
          hDimension: null,
        }]
      }

      await createWarehouseTransferOrderApi(transferCreate);

      if (!this.mounted) return;
      this.setState({saving: false, transferIdent: null});
    } catch (err) {
      if (!this.mounted) return;
      this.setState({saving: false});
      alertify.error('Could not make a transfer');
    }
  }

  hideTransfer = () => this.setState({transferIdent: null})

  transferOptions = () => [...this.state.warehouses].filter(w => w.type !== 'DEPOT').filter(w => w.id !== this.props.warehouseId).map(w => ({
    value: w.id,
    label: getWarehouseName({warehouse: w, vehicles: this.props.vehicles})
  }))

  render() {
    const {search, loading} = this.props;
    const {order, sort, transferIdent, saving, qty} = this.state;

    return (
      <Fragment>
        <WarehouseIdentsSearch search={search} onSearch={this.onSearch}/>
        <ContentLayout header={this.renderHeader()}>
          <WarehouseIdents
            loading={loading || this.state.loading}
            columns={this.columns(!!order)}
            onSort={this.onSort}
            onChange={this.onChange}
            sort={Array.isArray(sort) ? sort[0] : sort}
            orderMode={!!order}
            transfer={this.transfer()}
            saveLabel="Add to order"
            transferLabel="Transfer to"
            transferOptions={this.transferOptions()}
            warehouseItems={this.warehouseItems()}
          />
        </ContentLayout>
        <TransferIdentVehicleModal
          title="Transfer between vehicles"
          transferLabel="Transfer"
          visible={!!transferIdent}
          loading={saving || loading}
          onClose={this.hideTransfer}
          onAction={this.doTransfer}
        >
          Are you sure you want to
          transfer {qty[(transferIdent || {}).sku]} {(transferIdent || {}).unit} of {(transferIdent || {}).name}?
        </TransferIdentVehicleModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  materials: state.material.materials,
  vehicles: state.vehicle.vehicles,
  ...state.warehouseItem,
}))(withRouter(WarehouseContainer));
