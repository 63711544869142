import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Content = styled.div`
  width: 100%;
  padding: 10px 34px;
  background: ${white};
  position: relative;
`;

export default Content;
