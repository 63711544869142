import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white, orange, transparent} = colors;

const VehicleContainer = styled.div`
  height: 304px;
  width: calc(33.33% - 2px);
  max-width: calc(33.33% - 2px);
  min-width: calc(33.33% - 2px);
  margin: 1px;
  background-color: ${white};
  padding: 10px;
  font-size: 13px;
  border-style: solid;
  border-width: 2px;
  border-color: ${transparent};
  user-select: none;
  cursor: pointer;
  &:hover {
    border-color: ${orange};
  }
`;

export default VehicleContainer;
