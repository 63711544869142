import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey, darkGrey, black} = colors;

const Material = styled.div`
  width: 100%;
  padding: 15px 5px;
  border-style: none none solid none;
  border-width: 1px;
  border-color: ${grey};
  cursor: pointer;
  user-select: none;
  color: ${({imported}) => (imported ? darkGrey : black)};
`;

export default Material;
