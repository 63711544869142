import React from 'react';

// Containers
import Page from '../../containers/Page/Page';

// Components
import Wrapper from '../../components/Wrapper/Wrapper';
import Container from './components/Container';
import Content from './components/Content';
import Icon from './components/Icon';
import Text from './components/Text';

// Page
import page from './page';

const NotFound = () => (
  <Page page={page}>
    <Wrapper>
      <Container>
        <Content>
          <Icon className="mdi mdi-magnify" />
          <Text>Page Not Found</Text>
        </Content>
      </Container>
    </Wrapper>
  </Page>
);

export default NotFound;
