import styled from 'styled-components';

const EntryContainer = styled.div`
  width: 100px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export default EntryContainer;
