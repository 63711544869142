import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';

const Select = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: ${({color}) => `1px solid ${colors[color]}`};
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Select;
