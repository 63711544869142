import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getTime, parse} from 'date-fns';
import {Line, Pie} from 'react-chartjs-2';
import {alertify, Button, Column, Input, Row, Select} from 'doorson-ui';

// Api
import getInterventionStats from '../../api/interventions.api.stats';
import getMaintenanceStats from '../../api/maintenances.api.stats';
import getInvoiceStats from '../../api/invoice.api.stats';
import getRepairStats from '../../api/repairs.api.stats';
import getServiceItemsStats from '../../api/serviceItems.api.stats';
import getTechnicianRealizationStats from '../../api/technicianRealization.api.stats';

// Constants
// Components
import DataBlock from '../../../layout/components/DataBlock/DataBlock';
import StatisticsTile from '../../components/StatisticsTile/StatisticsTile';
import StatisticsHeader from '../../components/StatisticsHeader/StatisticsHeader';
import StatisticsSection from '../../components/StatisticsSection/StatisticsSection';
import StatisticsValueTile from '../../components/StatisticsValueTile/StatisticsValueTile';
import StatisticsPairTile from '../../components/StatisticsPairTile/StatisticsPairTile';
import Legend from '../../components/Legend/Legend';
import MainChart from '../../components/MainChart/MainChart';
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';
import {date as dateType} from '../../../types';
// Containers
// Libs
import parseServerFault from '../../../api/lib/parseServerFault.lib.api';
import Icon from '../../components/Icon/Icon';
import Right from '../../components/Icon/Right';
import download from '../../../file/lib/download.lib.file';
import getMaterialsStats from '../../api/materials.api.stats';

// Style

const getDayMinusOneMonth = () => {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);
  return d;
};

const chartColors = ['#f58320', '#53b0cb',
  '#264c99', '#a52a0d', '#bf7200',
  '#0c7012', '#720072', '#007294',
  '#b72153', '#4c7f00', '#8a2222',
  '#244a6f', '#723372', '#197f72',
  '#7f7f0c', '#4c2699', '#ac5600',
  '#680505', '#4b0c4d', '#256d49',
  '#3f577c', '#2c2e81', '#895619',
  '#10a017', '#8a0e62', '#d30b79',
  '#754227', '#7e930e', '#1f5969',
  '#4c6914', '#8e7b0e', '#084219',
  '#57270c',
];

class StatsContainer extends Component {

  state = {
    loading: true,
    touched: false,
    metric: 'interventions',
    from: dateType(getDayMinusOneMonth()).format(),
    to: dateType(new Date()).format(),
    fromTs: getTime(getDayMinusOneMonth()),
    toTs: getTime(new Date()),
    tick: 'TIME_DAY',
    mainChart: null,
    mainChartLoading: true,
    technicianStats: null,
    repairStats: null,
    materialStats: null,
    serviceItemsStats: null,
    repairWarrantyFilter: 'total',
    repairWarrantyLoading: true,
    materialWarrantyFilter: 'total',
    materialWarrantyLoading: true,
    manufacturers: {},
    products: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.init();

    const manufacturers = [...this.props.manufacturers].reduce((obj, e) => ({
      ...obj,
      [e.id]: e,
    }), {});
    const products = [...this.props.manufacturers].flatMap(m => [...m.products].map(p => ({
      ...p,
      name: m.name + ' ' + p.name,
    }))).reduce((obj, e) => ({
      ...obj,
      [e.id]: e,
    }), {});
    this.setState({products, manufacturers});
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    await Promise.all([
      this.loadMainChart(),
      this.getServiceItemsStats(),
      this.getTechnicianRealization(),
      this.getRepairStats(),
      this.getMaterialStats(),
    ]);

    if (!this.mounted) return;
    this.setState({loading: false});
  };

  loadMainChart = async () => {
    const {metric, tick, fromTs, toTs} = this.state;

    this.setState({mainChartLoading: true});
    try {
      let mainChart = [];
      switch (metric) {
        case 'interventions':
          mainChart = await getInterventionStats({xAxisType: tick, fromTs, toTs});
          break;
        case 'maintenances':
          mainChart = await getMaintenanceStats({xAxisType: tick, fromTs, toTs});
          break;
        case 'invoices':
          mainChart = await getInvoiceStats({xAxisType: tick, fromTs, toTs});
          break;
        default:
          console.error('unknown metric');
      }
      this.setState({
        mainChartLoading: false,
        mainChart,
      });
    } catch (error) {
      this.setState({mainChartLoading: false});
      const errCode = parseServerFault(error);
      alertify.error('Cannot get stat due to ' + (errCode === 'error.invalidParam' ? 'invalid parameters (set lower time interval)' : errCode));
    }

  };

  onSubmit = () => {
    this.state.touched && this.init();
    this.setState({touched: false});
  };

  onChange = (key) => async (val) => {
    await this.setState({
      [key]: val,
      touched: this.state.touched || key === 'tick',
    });

    if (key === 'metric') {
      await this.loadMainChart();
    } else if (key === 'from') {
      const fromTs = (!!val && getTime(parse(val, 'dd.MM.yyyy', new Date()))) || null;
      fromTs && this.setState({fromTs, touched: true});
    } else if (key === 'to') {
      const toTs = (!!val && getTime(parse(val, 'dd.MM.yyyy', new Date()))) || null;
      toTs && this.setState({toTs, touched: true});
    } else if (key === 'repairWarrantyFilter') {
      this.getRepairStats()
    } else if (key === 'materialWarrantyFilter') {
      this.getMaterialStats()
    }
  };

  color = (type, opacity) => {
    switch (type) {
      case 'Interventions':
        return this.orange(opacity);
      case 'Maintenances':
        return this.blue(opacity);
      case 'Invoiceless systems':
        return this.red(opacity);
      default:
        return this.orange(opacity);
    }
  };

  orange = (opacity) => `rgba(245, 131, 32, ${opacity || 1} )`;

  blue = (opacity) => `rgba(83, 176, 203, ${opacity || 1} )`;

  red = (opacity) => `rgba(203, 83, 83, ${opacity || 1} )`;

  getServiceItemsStats = async () => {
    const {fromTs, toTs} = this.state;
    try {
      const serviceItemsStats = await getServiceItemsStats({fromTs, toTs});
      if (!this.mounted) return;
      this.setState({
        serviceItemsStats: {
          ...serviceItemsStats,
          manufacturers: [...serviceItemsStats.manufacturers].sort((a, b) => b.count - a.count),
          products: [...serviceItemsStats.products].sort((a, b) => b.count - a.count),
        },
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({serviceItemsStats: false});
    }
  };

  getRepairStats = async () => {
    const {repairWarrantyFilter, fromTs, toTs, tick} = this.state;
    try {
      await this.setState({repairWarrantyLoading: true});
      const repairStats = await getRepairStats({xAxisType: tick, fromTs, toTs, ...('total' === repairWarrantyFilter ? {} : {underWarranty: repairWarrantyFilter})});
      if (!this.mounted) return;
      this.setState({
        repairStats,
        repairWarrantyLoading: false
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({repairStats: false, repairWarrantyLoading: false});
    }
  };

  getMaterialStats = async () => {
    const {materialWarrantyFilter, fromTs, toTs, tick} = this.state;
    try {
      await this.setState({materialWarrantyLoading: true});
      const materialStats = await getMaterialsStats({xAxisType: tick, fromTs, toTs, ...('total' === materialWarrantyFilter ? {} : {underWarranty: materialWarrantyFilter})});
      if (!this.mounted) return;
      this.setState({
        materialStats,
        materialWarrantyLoading: false
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({materialStats: false, materialWarrantyLoading: false});
    }
  };

  getTechnicianRealization = async () => {
    const {fromTs, toTs, tick} = this.state;
    try {
      const technicianStats = await getTechnicianRealizationStats({
        xAxisType: tick,
        fromTs,
        toTs,
      });
      const {users} = this.props;

      if (!this.mounted) return;
      this.setState({
        technicianStats: [...technicianStats].map(stat => ({...stat, user: [...users].find(u => u.id === stat.parent)})),
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({technicianStats: false});
    }
  };

  ticks = () => [
    {
      value: 'TIME_DAY',
      label: 'Day',
    },
    {
      value: 'TIME_WEEK',
      label: 'Week',
    },
    {
      value: 'TIME_MONTH',
      label: 'Month',
    },
  ];

  chartTitle = (item) => item.datasets[0].label.length > 32 ? item.datasets[0].label.substr(0, 32) + '...' : item.datasets[0].label;

  chartValue = (dataset) => dataset.data.reduce((a, b) => a + b, 0);

  repairs = () => {
    const {repairStats} = this.state;

    const repairChartData = repairStats && [...repairStats.datasets].map((item) => ({
      labels: repairStats.labels, datasets: [{
        label: item.label,
        fill: true,
        borderWidth: 1,
        backgroundColor: [this.orange(0.2)],
        borderColor: [this.orange()],
        data: item.data,
      }],
    }));

    return (repairChartData && [...repairChartData].map((cd, i) => ({
      ...cd,
      id: i,
      chartValue: this.chartValue(cd.datasets[0]),
    })).sort((a, b) => (a.chartValue < b.chartValue ? 1 : -1))) || [];
  };

  materials = () => {
    const {materialStats} = this.state;

    const materialChartData = materialStats && [...materialStats.datasets].map((item) => ({
      labels: materialStats.labels, datasets: [{
        label: item.label,
        fill: true,
        borderWidth: 1,
        backgroundColor: [this.orange(0.2)],
        borderColor: [this.orange()],
        data: item.data,
      }],
    }));

    return (materialChartData && [...materialChartData].map((cd, i) => ({
      ...cd,
      id: i,
      chartValue: this.chartValue(cd.datasets[0]),
    })).sort((a, b) => (a.chartValue < b.chartValue ? 1 : -1))) || [];
  };

  technicians = () => {
    const {technicianStats} = this.state;

    const suggestedMaxCount = technicianStats && [...technicianStats].flatMap(t => Math.max(t.datasets[0].data.reduce((a, b) => a > b ? a : b, 0), t.datasets[1].data.reduce((a, b) => a > b ? a : b, 0))).reduce((a, b) => a > b ? a : b, 0);
    const suggestedMaxTime = technicianStats && [...technicianStats].flatMap(t => t.datasets[2].data.reduce((a, b) => a > b ? a : b, 0)).reduce((a, b) => a > b ? a : b, 0);

    return (technicianStats && [...technicianStats].map(t => ({
      ...t,
      datasets: [{
        ...t.datasets[0],
        yAxisID: 'count',
        backgroundColor: [this.orange(0.2)],
        borderWidth: 1,
        borderColor: [this.orange()],
      },
        {
          ...t.datasets[1],
          yAxisID: 'count',
          backgroundColor: [this.blue(0.2)],
          borderWidth: 1,
          borderColor: [this.blue()],
        },
        {
          ...{...t.datasets[2], label: 'Working hours', data: [...t.datasets[2].data].map(min => min / 60)},
          yAxisID: 'time',
          backgroundColor: ['rgba(112, 181, 0, 0.2)'],
          borderWidth: 1,
          borderColor: ['rgba(112, 181, 0, 1)'],
        },
      ],
      suggestedMaxCount,
      suggestedMaxTime: suggestedMaxTime / 60,
      sumInterventions: t.datasets[0].data.reduce((a, b) => a + b, 0),
      sumMaintenances: t.datasets[1].data.reduce((a, b) => a + b, 0),
      sumHours: Math.round(t.datasets[2].data.reduce((a, b) => a + b, 0) / 60),
      user: `${t.user.firstName} ${t.user.lastName}`,
    }))) || [];
  };

  mainChart = () => {
    const {mainChart, mainChartLoading} = this.state;

    return !mainChartLoading && {
      labels: [...mainChart.labels],
      datasets: [...mainChart.datasets].map(ds => ({
        label: ds.label,
        fill: true,
        borderWidth: 2,
        backgroundColor: [
          this.color(ds.label, 0.2),
        ],
        borderColor: [
          this.color(ds.label, 1),
        ],
        data: ds.data,
      })),
    };
  };

  metrics = () => [
    {label: 'Interventions', value: 'interventions'},
    {label: 'Maintenances', value: 'maintenances'},
    {label: 'Invoiceless systems', value: 'invoices'},
  ];

  warrantyOptions = () => [
    {label: 'No warranty filter', value: 'total'},
    {label: 'Within warranty', value: 'true'},
    {label: 'Outside warranty', value: 'false'},
  ];

  rainbow = (i) => {
    return chartColors[i % chartColors.length];
  };

  transpose = (a) => {
    return a[0].map((_, c) => a.map(r => r[c]));
  };

  techniciansDownload = () => {
    const {technicianStats, from, to, tick} = this.state;
    const yAxis = ['Technician', 'Metric', ...technicianStats[0].labels];
    const tmp = [...technicianStats].flatMap(tech => [[`${tech.user.firstName} ${tech.user.lastName}`, 'Intervention systems', ...tech.datasets[0].data], [`${tech.user.firstName} ${tech.user.lastName}`, 'Maintenance systems', ...tech.datasets[1].data], [`${tech.user.firstName} ${tech.user.lastName}`, 'Logged hours', ...[...tech.datasets[2].data].map(h => parseFloat((h / 60).toFixed(1)))]]);

    const data = this.transpose([yAxis, ...tmp]).map(x => x.join(';')).join('\n');

    download(new Blob(['\uFEFF', 'SEP=;\n', data], {type: 'application/download'}), 'technicians-' + from + '-' + to + '-per' + (this.ticks().find(t => t.value === tick) || {}).label + '.csv');
  };

  render() {
    const {
      loading,
      products,
      manufacturers,
      touched,
      mainChartLoading,
      from,
      to,
      metric,
      tick,
      serviceItemsStats,
      repairWarrantyFilter,
      materialWarrantyFilter,
      repairWarrantyLoading,
      materialWarrantyLoading,
    } = this.state;
    const selectedMetric = [...this.metrics()].find(m => m.value === metric) || {};

    return (<Fragment>
        <StatisticsHeader>
          <Row>
            <Column size={1 / 3}>Door service analytics</Column>
            <Column size={11 / 60}>
              <Input value={from} onChange={this.onChange('from')} disabled={loading}>
                From date
              </Input>
            </Column>
            <Column size={11 / 60}>
              <Input value={to} onChange={this.onChange('to')} disabled={loading}>
                To date
              </Input>
            </Column>
            <Column size={11 / 60}>
              <Select
                value={tick}
                options={this.ticks()}
                onChange={this.onChange('tick')}
                disabled={loading}>
                X axis interval
              </Select>
            </Column>
            <Column size={7 / 60}>
              <Button theme='orange' outline loading={loading} disabled={!touched} onClick={this.onSubmit}>
                Apply
              </Button>
            </Column>
          </Row>
        </StatisticsHeader>
        <DataBlock>
          <Row>
            <Column size={1 / 6}>
              <Select
                value={metric}
                options={this.metrics()}
                onChange={this.onChange('metric')}
                disabled={loading}>
                &nbsp;
              </Select>
            </Column>
          </Row>
          <Row><Column>
            <Legend color={this.color(selectedMetric.label)}>{selectedMetric.label}</Legend>
          </Column></Row>
          <Row>
            <Column>
              <MainChart>
                {(!mainChartLoading && <Line height={250} data={this.mainChart()}
                                             options={{
                                               legend: {
                                                 display: false,
                                               },
                                               tooltips: {
                                                 titleFontFamily: 'Roboto Condensed',
                                                 bodyFontFamily: 'Roboto Condensed',
                                                 xPadding: 10,
                                                 yPadding: 10,
                                                 displayColors: false,
                                               },
                                               scales: {
                                                 xAxes: [{
                                                   ticks: {
                                                     display: false,
                                                   },
                                                 }],
                                               },
                                               responsive: true,
                                               maintainAspectRatio: false,
                                               aspectRatio: 1,
                                             }} />) || <FullScreenLoader />}
              </MainChart>
            </Column>
          </Row>
          <Row>
            <Column>
              <StatisticsSection>Technicians</StatisticsSection>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 2}>
              <Row><Column>
                <Legend color='rgba(83, 176, 203, 1)'>Maintenance</Legend>
                <Legend color='rgba(245, 131, 32, 1)'>Interventions</Legend>
                <Legend color='rgba(112, 181, 0, 1)'>Logged hours</Legend>
              </Column></Row>
              <Row>
                <Column>
                  <Right>
                    <Icon className='mdi mdi-microsoft-excel' onClick={this.techniciansDownload} /></Right>
                </Column>
              </Row>
              <Row>
                <Column>
                  {this.technicians().map(tech => <StatisticsPairTile key={tech.user} title={tech.user}
                                                                      value1={tech.sumMaintenances}
                                                                      value2={tech.sumInterventions}
                                                                      value3={tech.sumHours}
                                                                      chartData={tech} />)}
                </Column>
              </Row>
            </Column>
            <Column size={1 / 2}>
              <Row>
                <Column size={1 / 3}>
                  <Row>
                    <Column>
                      {serviceItemsStats && <Pie data={{
                        labels: [...serviceItemsStats.manufacturers].map(s => (manufacturers[s.manufacturerId] || {}).name),
                        datasets: [{
                          borderWidth: 0.7,
                          data: [...serviceItemsStats.manufacturers].map(s => s.count),
                          backgroundColor: [...serviceItemsStats.manufacturers].map((m, i) => this.rainbow(i)),
                        }],
                      }}
                                                 options={{
                                                   legend: {
                                                     display: false,
                                                   },
                                                   responsive: true,
                                                   maintainAspectRatio: false,
                                                   aspectRatio: 1,
                                                 }} />
                      }
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      {serviceItemsStats && <Pie data={{
                        labels: [...serviceItemsStats.products].map(s => (products[s.productId] || {}).name),
                        datasets: [{
                          borderWidth: 0.7,
                          data: [...serviceItemsStats.products].map(s => s.count),
                          backgroundColor: [...serviceItemsStats.products].map((m, i) => this.rainbow(i)),
                        }],
                      }}
                                                 options={{
                                                   legend: {
                                                     display: false
                                                   },
                                                   responsive: true,
                                                   maintainAspectRatio: false,
                                                   aspectRatio: 1,
                                                 }} />
                      }
                    </Column>
                  </Row>
                </Column>
                <Column size={2 / 3}>
                  {serviceItemsStats && <Pie height={300} data={{
                    datasets: [{
                      borderWidth: 1,
                      data: [serviceItemsStats.intervened, serviceItemsStats.maintained],
                      backgroundColor: [
                        this.orange(),
                        this.blue(),
                      ],
                    }],
                  }}
                                             options={{
                                               tooltips: {
                                                 callbacks: {
                                                   label: function() {
                                                     return '';
                                                   },
                                                 },
                                               },
                                               legend: {
                                                 display: false,
                                               },
                                               responsive: true,
                                               maintainAspectRatio: false,
                                               aspectRatio: 1,
                                             }} />
                  }
                </Column>
              </Row>
              <Row>
                <Column>
                  <StatisticsValueTile
                    title='Total number of systems'>{(serviceItemsStats || {}).total}</StatisticsValueTile>
                  <StatisticsValueTile theme='blue' title='Maintained systems'>
                    {(serviceItemsStats || {}).maintained}
                  </StatisticsValueTile>
                  <StatisticsValueTile theme='orange' title='Intervened systems'>
                    {(serviceItemsStats || {}).intervened}
                  </StatisticsValueTile>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column>
              <StatisticsSection>Repair descriptions</StatisticsSection>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 6}>
              <Select
                value={repairWarrantyFilter}
                options={this.warrantyOptions()}
                onChange={this.onChange('repairWarrantyFilter')}
                disabled={loading}>
                &nbsp;
              </Select>
            </Column>
          </Row>
          <Row>
            {repairWarrantyLoading ? 'Loading, please wait....' : this.repairs()
              .map(repair =>
                <StatisticsTile key={repair.id}
                                title={this.chartTitle(repair)}
                                value={repair.chartValue}
                                chartData={repair} />)}
          </Row>
          <Row>
            <Column>
              <StatisticsSection>Replaced material</StatisticsSection>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 6}>
              <Select
                value={materialWarrantyFilter}
                options={this.warrantyOptions()}
                onChange={this.onChange('materialWarrantyFilter')}
                disabled={loading}>
                &nbsp;
              </Select>
            </Column>
          </Row>
          <Row>
            {materialWarrantyLoading ? 'Loading, please wait....' : this.materials()
              .map(material =>
                <StatisticsTile key={material.id}
                                title={this.chartTitle(material)}
                                value={material.chartValue}
                                chartData={material} />)}
          </Row>
        </DataBlock>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  users: state.user.users,
  manufacturers: state.manufacturer.manufacturers,
}))(
  StatsContainer,
);
