import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import DoorInfoContainer from '../../containers/DoorInfoContainer/DoorInfoContainer';

// TODO: I18n
const MaintenanceDoorInfoPage = ({maintenance, maintenanceDoor, match}) => (
  <SubPage page={match.path}>
    <DoorInfoContainer
      maintenance={maintenance}
      maintenanceDoor={maintenanceDoor}
    />
  </SubPage>
);

MaintenanceDoorInfoPage.propTypes = {
  maintenanceDoor: PropTypes.object,
};

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
  maintenanceDoor: state.maintenanceDoor.maintenanceDoor,
}))(withRouter(MaintenanceDoorInfoPage));
