import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route, Router} from 'react-router-dom';

// Css
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Actions
import {init as initAuthAct} from '../../../auth/redux/actions';
import rum from '../../../api/constants/rum.const.api';

// Components
import {App as DoorsonApp} from 'doorson-ui';
import FullScreen from '../../../layout/components/FullScreen/FullScreen';
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// Containers
import Routes from '../../../routes/containers/Routes/Routes';
import DeviceContainer from '../../../device/containers/DeviceContainer/DeviceContainer';
import SignInContainer from '../../../auth/containers/SignInContainer/SignInContainer';
import ConfigContainer from '../../containers/ConfigContainer/ConfigContainer';

// History
import history from '../../../routes/lib/history.lib.routes';
import {AwsRum} from 'aws-rum-web';
// Sockets
// import socket from '../../../sockets/socket';
// import SOCKET_URI from '../../../sockets/constants/uri.const.socket';
// import '../../../sockets/events.sockets';

class App extends Component {
  static propTypes = {
    initialized: PropTypes.bool,
    token: PropTypes.string,
    user: PropTypes.object,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    try {
      const rumEnv = rum();
      if (rumEnv.enabled) {
        const config = {
          sessionSampleRate: 1,
          guestRoleArn: rumEnv.guestRoleArn,
          identityPoolId: rumEnv.identityPoolId,
          endpoint: rumEnv.endpoint,
          telemetries: ['performance', 'errors', 'http'],
          allowCookies: true,
          enableXRay: false,
        };

        new AwsRum(rumEnv.appId, rumEnv.appVersion, rumEnv.region, config);
        console.log('RUM enabled');
      }
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }

    this.props.dispatch(initAuthAct());
    // socket.connect(SOCKET_URI);
  }

  renderContent() {
    const {initialized, token} = this.props;
    // TODO Loader
    if (!initialized)
      return (
        <FullScreen>
          <FullScreenLoader />
        </FullScreen>
      );
    return !!token ? (
      <DeviceContainer>
        <ConfigContainer>
          <Router history={history} basename='/'>
            <Route component={Routes} />
          </Router>
        </ConfigContainer>
      </DeviceContainer>
    ) : (
      <SignInContainer />
    );
  }

  render() {
    return <DoorsonApp>{this.renderContent()}</DoorsonApp>;
  }
}

export default connect((state) => ({...state.auth}))(App);
