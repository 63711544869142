import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import InterventionActionsContainer from '../../containers/InterventionActionsContainer/InterventionActionsContainer';

// TODO: I18n
const InterventionActionsPage = ({match}) => (
  <SubPage page={match.path}>
    <InterventionActionsContainer />
  </SubPage>
);

InterventionActionsPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(InterventionActionsPage);
