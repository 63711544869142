import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: ${orange};
  margin-bottom: 5px;
  font-weight: 700;
`;

export default Header;
