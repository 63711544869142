import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, CheckBox, Modal, Select, Textarea} from 'doorson-ui';

import Container from './components/Container';
import Loader from './components/Loader';
import ButtonContainer from "./components/ButtonContainer";

const SendMessageModal = ({
                            loading,
                            visible,
                            title,
                            buttonLabel,
                            smsLabel,
                            sms,
                            messageLabel,
                            message,
                            users,
                            userLabel,
                            userId,
                            onChange,
                            onSend,
                            onClose,
                            error
                          }) => (
  <Modal title={title} visible={visible} size="medium" onClose={onClose}>
    {loading ? (
      <Container>
        <Loader className="mdi mdi-loading mdi-spin"/>
      </Container>
    ) : (
      <Fragment>
        <Select
          value={userId}
          options={users}
          onChange={onChange('userId')}
          disabled={loading}>
          {userLabel}
        </Select>
        <Textarea
          value={message}
          onChange={onChange('message')}
          error={error.message}
          disabled={loading}
          minRows={5}
        >
          {messageLabel}
        </Textarea>
        <CheckBox
          selected={sms}
          onSelect={onChange('sms')}
          disabled={loading}>
          {smsLabel}
        </CheckBox>
        <ButtonContainer>
          <Button
            theme="orange"
            size="big"
            onClick={onSend}
            loading={loading}
            disabled={loading}
            postIcon="arrow-right"
          >
            {buttonLabel}
          </Button>
        </ButtonContainer>
      </Fragment>
    )}
  </Modal>
);

SendMessageModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  title: PropTypes.string,
  priority: PropTypes.number,
  buttonLabel: PropTypes.string,
  smsLabel: PropTypes.string,
  sms: PropTypes.bool,
  messageLabel: PropTypes.string,
  message: PropTypes.string,
  users: PropTypes.array,
  userLabel: PropTypes.string,
  userId: PropTypes.string,
  onChange: PropTypes.func,
  onSend: PropTypes.func,
  error: PropTypes.object,
  onClose: PropTypes.func,
};

export default SendMessageModal;
