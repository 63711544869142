import {format} from 'date-fns';

const getTodaysDriverByVehicle = ({vehicle, date = new Date()} = {}) => {
  const today = format(date, 'yyyy-MM-dd');
  if (!vehicle) return null;
  return [...vehicle.rentals].find(
    (rental) => rental.isDriving && rental.dateSelected === today
  );
};
export default getTodaysDriverByVehicle;
