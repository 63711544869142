import styled, {css} from 'styled-components';

const LogoContainer = styled.div`
  width: 100%;
  height: 185px;
  min-height: 185px;
  max-height: 185px;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({small}) =>
    small
      ? css`
          justify-content: center;
        `
      : css`
          padding: 0px 36px;
        `}
`;

export default LogoContainer;
