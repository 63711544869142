import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';

// Components
import {Avatar} from 'doorson-ui';
import {Text} from 'doorson-ui';

import Container from './components/Container';
import Box from './components/Box';
import Menu from './components/Menu';
import Icon from './components/Icon';
import Dropdown from './components/Dropdown';
import Option from './components/Option';
import OptionLink from './components/OptionLink';
import Bg from './components/Bg';

class Header extends Component {
  static propTypes = {
    name: PropTypes.string,
    role: PropTypes.string,
    image: PropTypes.string,
    notifications: PropTypes.number,
    activitiesUrl: PropTypes.string,
    dropdown: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
  };

  static defaultProps = {
    notifications: 0,
  };

  state = {
    open: false,
  };

  handleClickOutside = () => this.setState({open: false});

  toggleOpen = () => this.setState({open: !this.state.open});

  onOptionClick = (onClick) => (e) => {
    if (!onClick) return;
    onClick(e);
  };

  render() {
    const {
      name,
      role,
      image,
      notifications,
      activitiesUrl,
      dropdown,
    } = this.props;
    const {open} = this.state;
    return (
      <Container>
        <Bg />
        <Box>
          <Link to={activitiesUrl}>
            <Avatar initials={name} img={image} badge={notifications} />
          </Link>
        </Box>
        <Box>
          <Menu onClick={this.toggleOpen}>
            {name}
            <br />
            <Text color="darkerGrey">{role}</Text>
            <Icon className={`mdi mdi-chevron-${open ? 'up' : 'down'}`} />
            <Dropdown visible={open}>
              {dropdown.map(({label, url, onClick}, index) =>
                !!url ? (
                  <OptionLink key={index} to={url}>
                    {label}
                  </OptionLink>
                ) : (
                  <Option key={index} onClick={this.onOptionClick(onClick)}>
                    {label}
                  </Option>
                )
              )}
            </Dropdown>
          </Menu>
        </Box>
      </Container>
    );
  }
}

export default onClickOutside(Header);
