import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const closeInterventionApi = (interventionID, doors, query = {rel: '*'}) =>
  f3tch(`/interventions/${interventionID}/close`)
    .profile(profile())
    .query(query)
    .body(doors)
    .put();
export default closeInterventionApi;
