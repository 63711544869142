import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';

const {black} = colors;

const SubHeaderContent = styled.div`
  font-size: 16px;
  display: inline;
  padding-left: 30px;
  color: ${black};
`;

export default SubHeaderContent;
