import styled from 'styled-components';

const DescriptionContainer = styled.div`
  position: relative;
  float: left;
  width: 300px;
  height: 40px;
  padding: 5px;
  user-select: none;
`;

export default DescriptionContainer;
