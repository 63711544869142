import styled from 'styled-components';

import {colors} from 'doorson-ui';
const {darkerGrey} = colors;

const Content = styled.div`
  width: 100%;
  flex: 1;
  ${props => props.disabled ? 'color: '+darkerGrey+';' : ''};
`;

export default Content;
