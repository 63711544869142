import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const Option = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  border-style: ${({border}) => (border ? 'solid none none none' : 'none')};
  border-width: 1px;
  border-color: ${grey};
`;

export default Option;
