import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteRepairApi = (maintenanceDoorID, repairID) =>
  f3tch(`/maintenance-doors/${maintenanceDoorID}/repairs/${repairID}`)
    .profile(profile())
    .delete();
export default deleteRepairApi;
