// Action
import action from '../../activity/actions/action.activity';

// History
import history from '../../routes/lib/history.lib.routes';

// Route
import route from '../pages/InterventionPage/route';

export default action('intervention.open', ({entityId}) => {
  if (!entityId) return;
  history.push(route(entityId));
});
