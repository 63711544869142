import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import assignApi from '../../api/assign.api.maintenance';
import unassignApi from '../../api/unassign.api.maintenance';

// Alert
import {alertify} from 'doorson-ui';

// Components
import MaintenanceQuickView from '../../components/MaintenanceQuickView/MaintenanceQuickView';

// Containers
import CloseMaintenanceContainer from '../CloseMaintenanceContainer/CloseMaintenanceContainer';

// Libs
import isClosed from '../../lib/isClosed.lib.maintenance';
import isResolved from '../../lib/isResolved.lib.maintenance';
import getMaintenanceByIdApi from '../../api/getByID.api.maintenance';

class MaintenanceQuickViewContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    maintenance: PropTypes.object,
    onClose: PropTypes.func,
    user: PropTypes.object,
    users: PropTypes.array,
  };

  state = {
    loading: false,
    maintenance: null,
    closing: null,
  };

  componentDidMount() {
    this.mounted = true;
    if (!!this.props.maintenance) this.init();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.maintenance && !!this.props.maintenance) ||
      (!!prevProps.maintenance &&
        !!this.props.maintenance &&
        prevProps.maintenance.id !== this.props.maintenance.id)
    )
      this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    const {maintenance} = this.props;
    if (!maintenance) return;
    this.setState({maintenance: null});
    try {
      const maintenanceWithReferences = await getMaintenanceByIdApi(maintenance.id);
      this.setState({maintenance: maintenanceWithReferences});
    } catch (e) {
      console.log(e);
      this.setState({maintenance});
    }
  };

  assign = async ({userID, maintenanceID}) => {
    // TODO: I18n
    if (this.state.loading) return;
    this.setState({loading: true});

    try {
      const maintenance = await assignApi(maintenanceID, userID);
      if (!this.mounted) return;
      this.setState({maintenance, loading: false});
    } catch (error) {
      alertify.error('Could not assign');
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  unassign = async ({maintenanceID}) => {
    // TODO: I18n
    const {user} = this.props;
    if (this.state.loading) return;
    this.setState({loading: true});

    try {
      const maintenance = await unassignApi(maintenanceID, user.id);
      if (!this.mounted) return;
      this.setState({loading: false, maintenance});
    } catch (error) {
      alertify.error('Could not unassign');
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  onMaintenanceClose = (maintenance) =>
    this.setState({maintenance, closing: false});

  onAction = (action, {maintenanceID, userID, maintenance}) => {
    const {loading} = this.state;
    if (loading) return;

    switch (action) {
      case 'assign':
        return this.assign({maintenanceID, userID});
      case 'unassign':
        return this.unassign({maintenanceID, userID});
      case 'close':
        return this.showClosingModal(maintenance);
      default:
        return;
    }
  };

  showClosingModal = (maintenance) => this.setState({closing: maintenance});

  hideClosingModal = () => this.setState({closing: false});

  onClose = () => {
    const {visible, onClose} = this.props;
    const {loading, maintenance, closing} = this.state;
    if (!visible || loading || closing) return;
    onClose(maintenance);
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'priority',
      label: 'Priority',
      sortable: true,
    },
    {
      key: 'code',
      label: 'Task code',
      span: 1.5,
    },
    {
      key: 'scheduledFor',
      label: 'Scheduled for',
      span: 1.5,
      sortable: true,
    },
    {
      key: 'customer',
      label: 'Customer',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'location',
      label: 'Location',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'doorType',
      label: 'Door Type',
      span: 3,
    },
  ];

  maintenance = () => {
    const {maintenance} = this.state;
    return !!maintenance
      ? {
          ...maintenance,
          resolved: isResolved(maintenance),
          closed: isClosed(maintenance),
        }
      : null;
  };

  render() {
    const {visible, user, users} = this.props;
    const {loading, closing} = this.state;
    const maintenance = this.maintenance();
    return (
      <Fragment>
        {visible && !!maintenance && (
          <MaintenanceQuickView
            loading={loading}
            maintenance={maintenance}
            columns={this.columns()}
            user={user}
            users={users}
            onClose={this.onClose}
            onAction={this.onAction}
          />
        )}
        <CloseMaintenanceContainer
          visible={!!closing}
          maintenance={closing}
          onClose={this.hideClosingModal}
          onDone={this.onMaintenanceClose}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  users: state.user.users,
}))(MaintenanceQuickViewContainer);
