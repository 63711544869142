import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import AdminLayoutContainer from '../../containers/AdminLayoutContainer/AdminLayoutContainer';

// Consts
import page from './page';

const AdminPage = () => (
  <Page page={page}>
    <AdminLayoutContainer />
  </Page>
);

export default AdminPage;
