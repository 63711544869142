import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const Vehicle = styled.div`
  position: absolute;
  left: 36px;
  bottom: 100px;
  color: ${grey};
  font-size: 12px;
  cursor: pointer;
`;

export default Vehicle;
