import styled from 'styled-components';
import {transitions} from 'polished';

// Colors
import {colors} from 'doorson-ui';
const {charcoal, darkerGrey, white, orange} = colors;

const Container = styled.div`
  width: ${({open}) => (open ? '280px' : '72px')};
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  background: ${({alternateBackground}) =>
    alternateBackground ? darkerGrey : charcoal};
  color: ${white};
  overflow: hidden;
  border-style: none solid none none;
  border-width: 2px;
  border-color: ${orange};
  user-select: none;
  ${transitions('width .3s ease-in-out')};
`;

export default Container;
