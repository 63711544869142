import isString from 'is-string';

import type from '../type';

const format = (currentValue, previousValue = '') => {
  if (!isString(currentValue)) return '';

  const numbers = currentValue.replace(/\D+/g, '');

  if (!previousValue || currentValue.length > previousValue.length) {
    if (numbers.length === 3) return `${numbers}.`;

    if (numbers.length === 6) {
      return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.`;
    }
  }

  if (numbers.length <= 3) return numbers;

  if (numbers.length <= 6) {
    return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}`;
  }

  return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.${numbers.slice(
    6,
    10
  )}`;
};

const validate = (value) => /^\d{3}\.\d{3}\.\d{4}$/.test(value);

export default type({type: 'string', format, validate});
