import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// Components
import Container from './components/Container';
import Content from './components/Content';
import DropDown from './components/DropDown';
import Option from './components/Option';

// Lib
import alertOptions from '../../lib/alertOptions.lib.reminder';
import getAlertLabel from '../../lib/getAlertLabel.lib.reminder';

class AlertDropDown extends Component {
  static propTypes = {
    alertDate: PropTypes.number,
    startDate: PropTypes.number,
    onAlert: PropTypes.func,
  };

  state = {
    visible: false,
  };

  show = () => {
    this.setState({visible: true});
  };

  hide = () => {
    this.setState({visible: false});
  };

  handleClickOutside = () => this.hide();

  onAlert = (value) => () => {
    this.props.onAlert(value);
    this.hide();
  };

  render() {
    const {alertDate, startDate} = this.props;
    const {visible} = this.state;
    return (
      <Container>
        <Content onClick={this.show}>
          {getAlertLabel({alertDate, startDate})}
        </Content>
        <DropDown visible={visible}>
          {alertOptions(startDate).map((option) => (
            <Option
              key={option.value}
              onClick={this.onAlert(option.value)}
              selected={option.value === alertDate}
            >
              {option.label}
            </Option>
          ))}
        </DropDown>
      </Container>
    );
  }
}

export default onClickOutside(AlertDropDown);
