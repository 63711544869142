import {combineReducers} from 'redux';

// Reducers
import activity from '../activity/redux/reducer';
import app from '../app/redux/reducer';
import auth from '../auth/redux/reducer';
import country from '../country/redux/reducer';
import device from '../device/redux/reducer';
import fault from '../fault/redux/reducer';
import reportedFault from '../reportedFault/redux/reducer';
import intervention from '../intervention/redux/reducer';
import serviceItem from '../door/redux/reducer';
import warehouseItem from '../warehouse/redux/reducer';
import warehouseOrder from '../warehouseOrder/redux/reducer';
import language from '../language/redux/reducer';
import layout from '../layout/redux/reducer';
import maintenance from '../maintenance/redux/reducer';
import maintenanceCheck from '../maintenanceCheck/redux/reducer';
import maintenanceDoor from '../maintenanceDoor/redux/reducer';
import manufacturer from '../manufacturer/redux/reducer';
import material from '../material/redux/reducer';
import network from '../network/redux/reducer';
import repair from '../repair/redux/reducer';
import transportLocation from '../transportLocation/redux/reducer';
import user from '../user/redux/reducer';
import vehicle from '../vehicle/redux/reducer';

export default combineReducers({
  activity,
  app,
  auth,
  country,
  device,
  fault,
  reportedFault,
  intervention,
  serviceItem,
  warehouseItem,
  warehouseOrder,
  language,
  layout,
  maintenance,
  maintenanceCheck,
  maintenanceDoor,
  manufacturer,
  material,
  network,
  repair,
  transportLocation,
  user,
  vehicle,
});
