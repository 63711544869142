import styled from 'styled-components';

const Box = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
`;

export default Box;
