import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import Container from './components/Container';
import Icon from './components/Icon';

const AdminMaintenanceChecks = ({
  columns,
  sort,
  maintenanceChecks,
  onUpdate,
  onDelete,
  onSort,
}) => (
  <Container>
    <Table columns={columns} sort={sort} onSort={onSort}>
      {(TableRow) =>
        maintenanceChecks.map((maintenanceCheck) => (
          <TableRow key={maintenanceCheck.id}>
            {(TableCol) => [
              <TableCol smallPadding key="checkItem" span={3}>
                {maintenanceCheck.checkItem}
              </TableCol>,
              <TableCol smallPadding key="action" span={2}>
                {maintenanceCheck.action}
              </TableCol>,
              <TableCol smallPadding key="actions">
                <Icon
                  className="mdi mdi-circle-edit-outline"
                  onClick={onUpdate(maintenanceCheck)}
                />
                <Icon
                  className="mdi mdi-delete"
                  onClick={onDelete(maintenanceCheck.id)}
                />
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
    {!maintenanceChecks.length && (
      <NoDataFound>No maintenance checks found</NoDataFound>
    )}
  </Container>
);

AdminMaintenanceChecks.propTypes = {
  columns: PropTypes.array,
  maintenanceChecks: PropTypes.array,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AdminMaintenanceChecks;
