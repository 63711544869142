import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import Container from './components/Container';
import Heading from './components/Heading';
import Icon from './components/Icon';

const AdminProducts = ({
  columns,
  products,
  title,
  onTitle,
  onProduct,
  onUpdate,
  onDelete,
}) => (
  <Container>
    <Heading onClick={onTitle}>{title}</Heading>
    <Table columns={columns}>
      {(TableRow) =>
        products.map((product) => (
          <TableRow key={product.id}>
            {(TableCol) => [
              <TableCol key="name" span={2}>
                {product.name}
              </TableCol>,
              <TableCol key="code" span={2}>
                {product.code}
              </TableCol>,
              <TableCol key="actions">
                <Icon
                  className="mdi mdi-file-tree"
                  onClick={onProduct(product)}
                />
                <Icon
                  className="mdi mdi-circle-edit-outline"
                  onClick={onUpdate(product)}
                />
                <Icon
                  className="mdi mdi-delete"
                  onClick={onDelete(product.id)}
                />
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
  </Container>
);

AdminProducts.propTypes = {
  columns: PropTypes.array,
  title: PropTypes.string,
  products: PropTypes.array,
  onProduct: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AdminProducts;
