import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Column, Container, Row} from 'doorson-ui';
import Content from "../../../intervention/components/InterventionsHeader/components/Content";

const WarehouseOrderHeader = ({children}) => (
  <Container>
    <Row>
      <Column>
        <Content>{children}</Content>
      </Column>
    </Row>
  </Container>
);

WarehouseOrderHeader.propTypes = {
  children: PropTypes.node,
};

export default WarehouseOrderHeader;
