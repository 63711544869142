import React, {Component} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Components
import {Button} from 'doorson-ui';
import Container from './components/Container';
import Input from './components/Input';
import ButtonContainer from './components/ButtonContainer';
import Loader from './components/Loader';
import UploadLabel from './components/UploadLabel';
import ImageContainer from './components/ImageContainer';
import Image from './components/Image';
import Thumbnails from './components/Thumbnails';
import Thumbnail from './components/Thumbnail';
import ThumbnailImg from './components/ThumbnailImg';

// Libs
import preview from '../../../file/lib/preview.lib.file';

class DoorImage extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    addDisabled: PropTypes.bool,
    image: PropTypes.object,
    images: PropTypes.array,
    onImage: PropTypes.func,
    onThumbnail: PropTypes.func,
    onAdd: PropTypes.func,
  };

  fileUpload = null;

  fileUploadRef = (fileUpload) => {
    this.fileUpload = fileUpload;
  };

  onClick = () => {
    if (!this.fileUpload) return;
    $(this.fileUpload).click();
  };

  render() {
    const {loading, image, images, addDisabled, onImage, onThumbnail, onAdd} = this.props;
    return (
      <Container>
        {loading && (
          <Loader>
            <i className="mdi mdi-loading mdi-spin" />
          </Loader>
        )}
        <Input
          type="file"
          ref={this.fileUploadRef}
          accept="image/*"
          onChange={onAdd}
        />
        {!!image ? (
          <ImageContainer>
            <Image onClick={() => onImage(image)} src={preview(image.preview)} />
          </ImageContainer>
        ) : (
          <UploadLabel onClick={this.onClick}>
            Select an image to upload
          </UploadLabel>
        )}
        {!!images.length && (
          <Thumbnails>
            {images.map((img) => (
              <Thumbnail
                key={img.id}
                selected={!!image && image.id === img.id}
                onClick={onThumbnail(img)}
              >
                <ThumbnailImg src={preview(img.preview)} />
              </Thumbnail>
            ))}
          </Thumbnails>
        )}
        <ButtonContainer>
          <Button preIcon="image" disabled={addDisabled} onClick={this.onClick}>
            Add Image
          </Button>
        </ButtonContainer>
      </Container>
    );
  }
}

export default DoorImage;
