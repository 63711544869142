import React from 'react';
import PropTypes from 'prop-types';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import MaintenanceDoorConfirmationContainer from '../../containers/MaintenanceDoorConfirmationContainer/MaintenanceDoorConfirmationContainer';

// TODO: I18n
const MaintenanceDoorConfirmationPage = ({match}) => (
  <SubPage page={match.path}>
    <MaintenanceDoorConfirmationContainer />
  </SubPage>
);

MaintenanceDoorConfirmationPage.propTypes = {
  match: PropTypes.object,
};

export default MaintenanceDoorConfirmationPage;
