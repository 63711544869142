import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Button} from 'doorson-ui';

import DataBlock from '../../../layout/components/DataBlock/DataBlock';

import ButtonContainer from './components/ButtonContainer';
import PdfContainer from './components/PdfContainer';
import PdfIcon from './components/PdfIcon';
import PdfName from './components/PdfName';
import PdfDownload from './components/PdfDownload';

const PdfInfo = ({
  pdfLabel,
  resolveLabel,
  closeLabel,
  previewLabel,
  pdfWillBeGeneratedLabel,
  sendContractLabel,

  loading,
  pdf,
  resolved,
  isCST,
  assigned,
  closed,
  canClose,

  onDownload,
  onAction,
  onPreview,
  onContract,
}) => (
  <DataBlock label={pdfLabel}>
    <Row>
      <Column size={5 / 8}>
        {!!pdf ? (
          <PdfContainer onClick={onDownload}>
            <PdfIcon className="mdi mdi-file-pdf-box" />
            <PdfName>{pdf.name}</PdfName>
            <PdfDownload className="mdi mdi-download" />
          </PdfContainer>
        ) : (
          pdfWillBeGeneratedLabel
        )}
      </Column>
      <Column size={3 / 8}>
        {resolved && !closed && canClose && (
          <ButtonContainer>
            <Button
              onClick={onAction('close')}
              theme="orange"
              size="big"
              loading={loading}
              outline
            >
              {closeLabel}
            </Button>
          </ButtonContainer>
        )}
        {assigned && !resolved && (
          <ButtonContainer>
            <Button
              theme="blue"
              size="big"
              preIcon="file-find"
              onClick={onPreview}
            >
              {previewLabel}
            </Button>
          </ButtonContainer>
        )}
        {(assigned || isCST) && resolved && (
          <ButtonContainer>
            <Button theme="blue" size="big" onClick={onContract}>
              {sendContractLabel}
            </Button>
          </ButtonContainer>
        )}
        {assigned && !resolved && (
          <ButtonContainer>
            <Button
              onClick={onAction('resolve')}
              theme="orange"
              size="big"
              loading={loading}
              outline
            >
              {resolveLabel}
            </Button>
          </ButtonContainer>
        )}
      </Column>
    </Row>
  </DataBlock>
);

PdfInfo.propTypes = {
  pdfLabel: PropTypes.string,
  resolveLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  previewLabel: PropTypes.string,
  pdfWillBeGeneratedLabel: PropTypes.string,
  sendContractLabel: PropTypes.string,

  pdf: PropTypes.object,
  loading: PropTypes.bool,
  resolved: PropTypes.bool,
  assigned: PropTypes.bool,
  isCST: PropTypes.bool,
  closed: PropTypes.bool,
  canClose: PropTypes.bool,

  onDownload: PropTypes.func,
  onAction: PropTypes.func,
  onPreview: PropTypes.func,
  onContract: PropTypes.func,
};

export default PdfInfo;
