import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkerGrey, black} = colors;

const Content = styled.div`
  width: 100%;
  cursor: pointer;
  font-size: 12px;
  color: ${({inserted}) => (inserted ? black : darkerGrey)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Content;
