import React from 'react';
import PropTypes from 'prop-types';

// Components
import Folder from '../Folder/Folder';
import FolderBackButton from '../FolderBackButton/FolderBackButton';
import NewFolderButton from '../NewFolderButton/NewFolderButton';
import Container from './components/Container';
import FoldersContainer from './components/FoldersContainer';
import FolderContainer from './components/FolderContainer';

const Documentation = ({
  newFolderLabel,
  backLabel,
  canCreateFolder,
  folder,
  folders,
  onBack,
  onNewFolder,
  onFolder,
}) => (
  <Container>
    <FoldersContainer>
      {!!folder && (
        <FolderContainer>
          <FolderBackButton onClick={onBack}>{backLabel}</FolderBackButton>
        </FolderContainer>
      )}
      {folders.map((f) => (
        <FolderContainer key={f.id}>
          <Folder
            title={f.title}
            image={!!f.image ? f.image.preview : ''}
            onClick={onFolder(f)}
          />
        </FolderContainer>
      ))}
      {canCreateFolder && (
        <FolderContainer>
          <NewFolderButton onClick={onNewFolder}>
            {newFolderLabel}
          </NewFolderButton>
        </FolderContainer>
      )}
    </FoldersContainer>
  </Container>
);

Documentation.propTypes = {
  newFolderLabel: PropTypes.node,
  backLabel: PropTypes.node,

  canCreateFolder: PropTypes.bool,
  folder: PropTypes.object,
  folders: PropTypes.array,
  onBack: PropTypes.func,
  onNewFolder: PropTypes.func,
  onFolder: PropTypes.func,
};

export default Documentation;
