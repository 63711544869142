import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const getMaintenanceContractApi = (id) =>
  f3tch(`/maintenance-contracts/${id}`)
    .profile(profile())
    .get();
export default getMaintenanceContractApi;
