import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table, CheckBox, Button, alertify} from 'doorson-ui';
import ButtonContainer from './components/ButtonContainer';
import Container from './components/Container';

const MaintenanceSignMultipleItems = ({
  columns,
  doors,
  maintenance,
  onSelect,
  onSelectAll,
  onSign,
  canChange,
  selected,
}) => (
  <Container>
    <Table columns={columns}>
      {(TableRow) => [
        ...doors.map((door) => (
          <TableRow key={door.id} onClick={canChange ? onSelect(door) : () => alertify.info("You need to assign first.")}>
            {(TableCol) => [
              <TableCol key="select">
                <CheckBox
                  selected={selected.includes(door.id)}
                  onSelect={() => {}}
                  disabled={!canChange}
                />
              </TableCol>,
              <TableCol span={1.5} key="code">
                {maintenance.code}
              </TableCol>,
              <TableCol span={1.5} key="serial">
                {door.door.doorSerial}
              </TableCol>,
              <TableCol span={1.5} key="location">
                {door.door.indoorLocation} {door.door.buildingFloor}
              </TableCol>,
              <TableCol span={1.5} key="type">
                {door.door.product.manufacturer.name} {door.door.product.name}
                {!!door.door.productType
                  ? ` ${door.door.productType.name}`
                  : ''}
              </TableCol>,
            ]}
          </TableRow>
        )),
        <TableRow key="newDoor">
          {(TableCol) => [
            <TableCol key="selectAll">
              <ButtonContainer>
                <Button size="normal" theme="grey" disabled={!canChange} onClick={onSelectAll}>
                  Select all
                </Button>
              </ButtonContainer>
            </TableCol>,
            <TableCol span={4.5} key="empty"></TableCol>,
            <TableCol span={1.5} key="sign">
              <ButtonContainer>
                <Button
                  size="normal"
                  theme="blue"
                  preIcon="pencil"
                  disabled={!selected.length || !canChange}
                  onClick={onSign}
                >
                  Sign Selected
                </Button>
              </ButtonContainer>
            </TableCol>,
          ]}
        </TableRow>,
      ]}
    </Table>
  </Container>
);

MaintenanceSignMultipleItems.propTypes = {
  columns: PropTypes.array,
  doors: PropTypes.array,
  maintenance: PropTypes.object,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSign: PropTypes.func,
  canChange: PropTypes.bool,
  selected: PropTypes.array,
};

export default MaintenanceSignMultipleItems;
