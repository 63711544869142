import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import createApi from '../../api/create.api.transportLocation';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminTransportLocationButton from '../../components/NewAdminTransportLocationButton/NewAdminTransportLocationButton';
import NewTransportLocationModal from '../../components/NewTransportLocationModal/NewTransportLocationModal';

class NewTransportLocationContainer extends Component {
  static propTypes = {
    transportLocations: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    name: '',
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, name: ''});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, name: ''});
  };

  create = async () => {
    // TODO: I18n
    const {loading, visible, name} = this.state;
    if (loading || !visible) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const transportLocation = await createApi({name});
      const {dispatch, transportLocations} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(
        setAct({transportLocations: [...transportLocations, transportLocation]})
      );
    } catch (error) {
      alertify.error('Transport Location could not be created');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {loading, visible, name} = this.state;
    return (
      <Fragment>
        <NewAdminTransportLocationButton onClick={this.show}>
          New Location
        </NewAdminTransportLocationButton>
        <NewTransportLocationModal
          title="New TransportLocation"
          nameLabel="Name"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          name={name}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.transportLocation}))(
  NewTransportLocationContainer
);
