import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const OpenRefresh = styled.div`
  display: block;
  position: absolute;
  right: 36px;
  bottom: 50px;
  color: ${darkGrey};
  font-size: 20px;
  cursor: pointer;
`;

export default OpenRefresh;
