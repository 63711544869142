import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import isOnline from 'is-online';

// Actions
import {set as setAct} from '../../redux/actions';

// Constants
import intervalTime from '../../constants/interval.const.online';

// Components
import Network from '../../components/Network/Network';

class NetworkStatusContainer extends Component {
  static propTypes = {
    online: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    this.checkIfOnline();
  }

  checkIfOnline = async () => {
    const online = await isOnline();
    this.isOnline(online);
    setTimeout(this.checkIfOnline, intervalTime);
  };

  isOnline = (online) => this.props.dispatch(setAct({online}));

  render() {
    const {online} = this.props;
    return <Network online={online} />;
  }
}

export default connect((state) => ({...state.network}))(NetworkStatusContainer);
