import styled from 'styled-components';

// Components
import {colors} from 'doorson-ui';
const {grey} = colors;

const Bg = styled.div`
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0px;
  left: 0px;
  background: ${grey};
  pointer-events: none;
`;

export default Bg;
