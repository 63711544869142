import styled from 'styled-components';

const ButtonContainer = styled.div`
  width: 170px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: inline-block;
`;

export default ButtonContainer;
