import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../../manufacturer/redux/actions';

// Api
import createApi from '../../api/create.api.productType';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminProductTypeButton from '../../components/NewAdminProductTypeButton/NewAdminProductTypeButton';
import NewProductTypeModal from '../../components/NewProductTypeModal/NewProductTypeModal';

class NewProductTypeContainer extends Component {
  static propTypes = {
    manufacturers: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    name: '',
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, name: ''});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, name: ''});
  };

  create = async () => {
    // TODO: I18n
    const {
      match: {
        params: {manufacturerID, productID},
      },
    } = this.props;
    const {loading, visible, name} = this.state;
    if (loading || !visible) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const productType = await createApi({
        name,
        productId: productID,
      });
      const {dispatch, manufacturers} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(
        setAct({
          manufacturers: [...manufacturers].map((manufacturer) =>
            manufacturer.id === manufacturerID
              ? {
                  ...manufacturer,
                  products: [...manufacturer.products].map((product) =>
                    product.id === productID
                      ? {
                          ...product,
                          productTypes: [...product.productTypes, productType],
                        }
                      : product
                  ),
                }
              : manufacturer
          ),
        })
      );
    } catch (error) {
      alertify.error('ProductType could not be created');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {loading, visible, name} = this.state;
    return (
      <Fragment>
        <NewAdminProductTypeButton onClick={this.show}>
          New Product Type
        </NewAdminProductTypeButton>
        <NewProductTypeModal
          title="New ProductType"
          nameLabel="Name"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          name={name}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  ...state.manufacturer,
}))(NewProductTypeContainer);
