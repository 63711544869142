import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteMaintenanceDoorApi = (maintenanceDoorID) =>
  f3tch(`/maintenance-doors/${maintenanceDoorID}`)
    .profile(profile())
    .delete();
export default deleteMaintenanceDoorApi;
