import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 2px 0px 0px;
  user-select: none;
`;

export default Container;
