import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import jwt from 'jsonwebtoken';

// Actions
import {signIn as signInAct} from '../../redux/actions';

// Api
import signinApi from '../../api/signin.api.auth';
import getUserByIDApi from '../../../user/api/getByID.api.user';

// Alerts
import {alertify} from 'doorson-ui';

// Components
import SignIn from '../../components/SignIn/SignIn';

// Constants
import userTypes from '../../constants/userTypes.const.auth';
import setLastUserApi from "../../../device/api/setLastUser.api.device";
import * as localStorage from "../../../lib/localStorage";
import STORAGE_KEY from "../../../device/constants/storageKey.constant.device";
import NOT_APPLICABLE from "../../../device/constants/notApplicable.constant.device";

class SignInContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    username: '',
    password: '',
    type: 'USERS\\',
    keepLoggedIn: true,
  };

  onChange = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  signin = async () => {
    const {dispatch} = this.props;
    const {loading, username, password, keepLoggedIn} = this.state;

    if (loading) return;

    // TODO I18N
    if (!username.trim().length)
      return alertify.warning('Insert your username');
    if (!password.trim().length) return alertify.warning('Insert password');

    this.setState({loading: true});

    try {
      const {access_token: token} = await signinApi({
        username,
        password,
      });
      const {
        user: {id},
      } = await jwt.decode(token);
      const user = await getUserByIDApi(id, token);
      dispatch(signInAct(token, user, keepLoggedIn));
      try {
        //set last device user id on login
        const storedDeviceId = localStorage.get(STORAGE_KEY);
        if (!!storedDeviceId && storedDeviceId !== NOT_APPLICABLE) {
          await setLastUserApi(storedDeviceId, user.id);
        }
      } catch (error) {
        console.error(error);
      }
      // TODO I18N
      alertify.success(`Hello ${user.firstName} ${user.lastName}`);
    } catch (error) {
      // TODO I18N
      alertify.error(error.message || 'Authentication issue');
      this.setState({loading: false, password: ''});
    }
  };

  render() {
    const {loading, username, password, keepLoggedIn, type} = this.state;
    // TODO I18N
    return (
      <SignIn
        loading={loading}
        username={username}
        password={password}
        keepLoggedIn={keepLoggedIn}
        heading="Log in"
        usernameLabel="Your email"
        passwordLabel="Password"
        keepLoggedInLabel="Keep me logged in"
        loginLabel="Log in"
        type={type}
        types={userTypes}
        onChange={this.onChange}
        onSignIn={this.signin}
      />
    );
  }
}

export default connect()(SignInContainer);
