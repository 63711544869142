import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Content from './components/Content';
import DoorsonLogo from './components/DoorsonLogo';
import DoorserviceLogo from './components/DoorserviceLogo';
import Heading from './components/Heading';
import InputContainer from './components/InputContainer';
import ActionContainer from './components/ActionContainer';
import ButtonContainer from './components/ButtonContainer';
import ActionLabel from './components/ActionLabel';

import {Form} from 'doorson-ui';
import {SquareInput as Input} from 'doorson-ui';
import {Button} from 'doorson-ui';

const Device = ({
  loading,
  cancelable,
  device,
  onChange,
  onSave,
  onCancel,
  onNotApplicable,
}) => (
  <Container>
    <Content>
      <Form onSubmit={onSave}>
        <DoorsonLogo logo="doorson" />
        <DoorserviceLogo logo="doorservice" />
        <Heading>Set Device ID</Heading>
        <InputContainer>
          <Input value={device} onChange={onChange} disabled={loading} light>
            Device ID
          </Input>
        </InputContainer>
        {cancelable && (
          <ActionContainer>
            <ActionLabel onClick={onCancel}>
              <i className="mdi mdi-chevron-left" /> Go back
            </ActionLabel>
          </ActionContainer>
        )}
        <ActionContainer>
          <ButtonContainer>
            <Button postIcon="arrow-right" theme="orange" loading={loading}>
              Set
            </Button>
          </ButtonContainer>
          <ActionLabel onClick={onNotApplicable}>I'm on a computer</ActionLabel>
        </ActionContainer>
      </Form>
    </Content>
  </Container>
);

Device.propTypes = {
  loading: PropTypes.bool,
  cancelable: PropTypes.bool,
  device: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Device;
