import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateInterventionApi =  (id, intervention, query = {rel: '*'}) =>
  f3tch(`/interventions/${id}`)
    .profile(profile())
    .query(query)
    .body(intervention)
    .put();
export default updateInterventionApi;
