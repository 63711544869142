import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteInterventionFileApi = (interventionID, fileID) =>
  f3tch(`/interventions/${interventionID}/files/${fileID}`)
    .profile(profile())
    .delete();
export default deleteInterventionFileApi;
