import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
// Consts
import page from './page';
import WarehouseOrdersContainer from "../../containers/WarehouseOrdersContainer/WarehouseOrdersContainer";

const WarehouseOrdersPage = () => (
  <Page page={page}>
    <WarehouseOrdersContainer/>
  </Page>
);

export default WarehouseOrdersPage;
