import styled from 'styled-components';

const Frame = styled.iframe`
  width: 100%;
  height: 80vh;
  display: block;
  margin: 0px;
  paddin: 0px;
  border: none;
  outline: none;
`;

export default Frame;
