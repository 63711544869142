import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white, darkGrey} = colors;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 186px);
  border-style: solid none none none;
  border-width: 1px;
  border-color: ${darkGrey};
  background: ${white};
  display: flex;
  position: relative;
  overflow: scroll;
  user-select: none;
  -webkit-overflow-scrolling: touch;
`;

export default Container;
