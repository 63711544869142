import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import createMaintenanceDoorApi from '../../../maintenanceDoor/api/create.api.maintenanceDoor';

// Actions
import {updateMaintenance as updateMaintenanceAct} from '../../redux/actions';

// Components
import MaintenanceItems from '../../components/MaintenanceItems/MaintenanceItems';

// Containers
import NewDoorContainer from '../../../door/containers/NewDoorContainer/NewDoorContainer';

// Lib
import isResolved from '../../../maintenanceDoor/lib/isResolved.lib.maintenanceDoor';

// Worklog
import doorRoute from '../../../maintenanceDoor/pages/MaintenanceDoorPage/route';

// Assign
import assignRoute from '../../pages/MaintenanceAssignPage/route';
import {alertify} from "doorson-ui";
import getLabelRefApi from "../../../labelRef/api/getById.api.labelRef";
import parseServerFault from "../../../api/lib/parseServerFault.lib.api";
import QrScanModal from "../../../door/components/QrScanModal/QrScanModal";

class MaintenanceItemsContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    sort: null,
    search: {},
    scan: false,
    scanLoading: false,
    doors: [],
    showNewDoorModal: false,
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    const {maintenance, history} = this.props;
    if (!maintenance.assignees.length)
      return history.replace(assignRoute(maintenance.id));
    const doors = this.parseDoors(this.props.maintenance.doors);
    this.setState({doors});
  };

  parseDoors = (doors) =>
    [...doors].map((door) => ({
      ...door,
      resolved: isResolved(door),
    }));

  doors = () => {
    const {sort, search:{searchKey, searchValue}, doors} = this.state;
    const sortValue = (door) => {
      if (sort && sort.key === 'location') return door.door.indoorLocation;
      if (sort && sort.key === 'type')
        return `${door.door.product.manufacturer.name} ${door.door.product.name} ${(door.door.productType || {}).name}`;
      return door.id;
    };

    const filterValue = (door) => {
      if (searchKey === 'location' && !!searchValue) return (door.door.indoorLocation + door.door.buildingFloor).toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
      return true;
    };

    return [...doors].filter(filterValue).sort((a, b) => {
      const sortA = sortValue(a);
      const sortB = sortValue(b);

      if (sortA > sortB) return sort && sort.direction === 'asc' ? 1 : -1;
      if (sortA < sortB) return sort && sort.direction === 'asc' ? -1 : 1;
      return 0;
    });
  };

  columns = () => [
    {
      key: 'priority',
      label: '',
    },
    {
      key: 'code',
      label: 'Task code',
      span: 1.5,
    },
    {
      key: 'serial',
      label: 'Door serial number',
      span: 1.5,
    },
    {
      key: 'location',
      label: 'Door Location',
      span: 1.5,
      searchable: true,
      sortable: true,
    },
    {
      key: 'type',
      label: 'Door Type',
      span: 3,
      sortable: true,
    },
  ];

  onSelect = (door) => () => {
    const {history, maintenance} = this.props;
    history.push(doorRoute(maintenance.id, door.id));
  };

  onSort = (sort) => this.setState({sort});

  onSearch = (search) => this.setState({search});

  showNewDoorModal = () => this.setState({showNewDoorModal: true});

  hideNewDoorModal = () => this.setState({showNewDoorModal: false});

  onNewDoor = () => this.setState({showNewDoorModal: false});

  afterDoor = async (door) => {
    const {maintenance, dispatch} = this.props;
    const maintenanceDoor = await createMaintenanceDoorApi({
      doorId: door.id,
      maintenanceId: maintenance.id,
    }, {rel: '*'});
    const newMaintenance = {
      ...maintenance,
      doors: [...maintenance.doors, maintenanceDoor],
    };
    const doors = this.parseDoors(newMaintenance.doors);
    this.setState({showNewDoorModal: false, doors});
    dispatch(updateMaintenanceAct(newMaintenance));
  };

  scan = (scan) => this.setState({scan, scanLoading: scan && this.state.scanLoading})

  onScan = async (content) => {
    try {
      if (!content) return;
      this.setState({scanLoading: true})

      const friendlyId = new URL(content).pathname.split('/').filter(Boolean).pop()
      const labelRef = await getLabelRefApi(friendlyId);
      if (labelRef.context !== 'SERVICE_ITEM') {
        alertify.error('This QR code is not assigned to the door.');
      }
      const doorId = labelRef.refId;

      const {doors} = this.state;
      const mDoor = [...doors].find(mDoor => mDoor.door.id === doorId);
      if (!mDoor) {
        alertify.error('Door cannot be found in the list above');
      } else {
        alertify.info('Door selected with QR code');
        this.onSelect(mDoor)();
      }
    } catch (error) {
      console.error(error);
      alertify.error('Unable to find door using QR code. ' + parseServerFault(error));
    }
    this.scan(false);
  }

  render() {
    // TODO: I18n
    const {maintenance} = this.props;
    const {sort, scan, scanLoading, showNewDoorModal} = this.state;
    return (
      <Fragment>
        <MaintenanceItems
          selectLabel="Select"
          inProgressLabel="In progress"
          completedLabel="Needs signature"
          addDoorLabel="Add Door"
          scan={scan}
          toggleScan={this.scan}
          scanLabel={scan ? "Scanning..." : "Search by QR"}
          columns={this.columns()}
          maintenance={maintenance}
          doors={this.doors()}
          onSelect={this.onSelect}
          onNewDoor={this.showNewDoorModal}
          sort={sort}
          onSort={this.onSort}
          onSearch={this.onSearch}
        />
        <NewDoorContainer
          visible={showNewDoorModal}
          customerBranchId={maintenance.customerBranch.id}
          afterComplete={this.afterDoor}
          onComplete={this.onNewDoor}
          onClose={this.hideNewDoorModal}
        />
        <QrScanModal
          loading={scanLoading}
          onScan={this.onScan}
          onClose={this.scan.bind(this, false)}
          visible={scan}/>
      </Fragment>
    );
  }
}

export default withRouter(connect()(MaintenanceItemsContainer));
