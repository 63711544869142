import numeral from 'numeral';
import isNumber from 'is-number';
import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value) || isNumber(value) ? numeral(value).format('0,0') : '';

const validate = (value) => value === format(value);

export default type({type: 'string', format, validate});
