import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import Container from './components/Container';
import Icon from './components/Icon';
import {formatDistanceToNow, toDate} from 'date-fns';

const AdminDevices = ({columns, sort, devices, onUpdate, onDelete, onSort}) => (
  <Container>
    <Table columns={columns} sort={sort} onSort={onSort}>
      {(TableRow) =>
        devices.map((device) => (
          <TableRow key={device.id}>
            {(TableCol) => [
              <TableCol smallPadding key="id" span={2}>
                {device.id}
              </TableCol>,
              <TableCol smallPadding key="name" span={2}>
                {device.name}
              </TableCol>,
              <TableCol smallPadding key="lastUser" span={1}>
                {!!device.lastUser
                  ? `${device.lastUser.firstName} ${device.lastUser.lastName}`
                  : '-'}
              </TableCol>,
              <TableCol smallPadding key="lastPosition" span={2}>
                {!!device.lastDeviceLocation ? (
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        'https://www.google.com/maps/@' +
                        device.lastDeviceLocation.latitude +
                        ',' +
                        device.lastDeviceLocation.longitude +
                        ',111a,35y,' +
                        (device.lastDeviceLocation.dir || 0) +
                        'h/data=!3m1!1e3!5m1!1e1'
                      }
                    >
                      @{device.lastDeviceLocation.latitude.toFixed(7)},{' '}
                      {device.lastDeviceLocation.longitude.toFixed(7)}
                    </a>
                    {device.lastDeviceLocation.act &&
                      '(' + device.lastDeviceLocation.act + ')'}
                    <br />
                    {device.lastDeviceLocation.speed || 0} km/h,{' '}
                    {device.lastDeviceLocation.sat || 0} sat
                    <br />
                    <small>
                      {device.lastSeen &&
                        'checked in ' +
                          formatDistanceToNow(toDate(device.lastSeen)) +
                          ' ago'}
                    </small>
                  </span>
                ) : (
                  '-'
                )}
              </TableCol>,
              <TableCol smallPadding key="battery" span={1}>
                {!!device.lastDeviceLocation ? (
                  <span>
                    {device.lastDeviceLocation.batt
                      ? device.lastDeviceLocation.batt + '%'
                      : '-'}
                  </span>
                ) : (
                  '-'
                )}
              </TableCol>,
              <TableCol smallPadding key="actions">
                <Icon
                  className="mdi mdi-circle-edit-outline"
                  onClick={onUpdate(device)}
                />
                <Icon
                  className="mdi mdi-delete"
                  onClick={onDelete(device.id)}
                />
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
    {!devices.length && <NoDataFound>No devices found</NoDataFound>}
  </Container>
);

AdminDevices.propTypes = {
  columns: PropTypes.array,
  devices: PropTypes.array,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AdminDevices;
