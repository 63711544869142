import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkerGrey, black} = colors;

const Input = styled.input`
  width: 100%;
  display: block;
  margin: 0px;
  padding: 0px;
  border: none;
  background: none;
  font-size: ${({large}) => (large ? '15px' : '12px')};
  font-weight: ${({large}) => (large ? 700 : 400)};
  margin-bottom: ${({large}) => (large ? '5px' : '0px')};
  font-family: inherit;
  outline: none;
  color: ${({highlight}) => (highlight ? black : darkerGrey)};
`;

export default Input;
