import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const PriorityItem = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  background: ${({hovered}) => (hovered ? grey : 'none')};
`;

export default PriorityItem;
