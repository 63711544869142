import styled from 'styled-components';

const TabsFix = styled.div`
  width: 128px;
  height: 60px;
  display: block;
  padding-bottom: 20px;
`;

export default TabsFix;
