import styled from 'styled-components';

import {colors} from 'doorson-ui';
const {black, orange} = colors;

const Option = styled.div`
  width: 100%;
  padding: 25px;
  cursor: pointer;
  color: ${black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${orange};
  }
`;

export default Option;
