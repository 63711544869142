import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value) ? value.replace(/[^a-zA-Z\d]/g, '').toUpperCase(' ') : '';

const validate = (value) => /^[A-Z\d]+$/.test(value);

export default type({type: 'string', format, validate});
