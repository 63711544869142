import React, {Component} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Components
import Container from './components/Container';
import Loader from './components/Loader';

export default class PreLoader extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    showLoader: PropTypes.bool,
    more: PropTypes.bool,
    onMore: PropTypes.func,
    scroller: PropTypes.object,
    children: PropTypes.node,
  };

  componentDidMount() {
    $(this.props.scroller).on('scroll', this.scroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scroller !== this.props.scroller) {
      $(prevProps.scroller).off('scroll', this.scroll);
      $(this.props.scroller).on('scroll', this.scroll);
    }
  }

  componentWillUnmount() {
    $(this.props.scroller).off('scroll', this.scroll);
  }

  dom = null;
  pause = false;

  onDom = (dom) => {
    this.dom = dom;
  };

  scroll = () => {
    const {loading, more, scroller, onMore} = this.props;
    if (loading || !more || !this.dom || this.pause) return;
    const dom = $(this.dom);
    const scrollDom = $(scroller);
    const scroll = scrollDom.scrollTop();
    const toScroll = dom.height() - scrollDom.height();
    const load = scroll >= toScroll - scrollDom.height();
    if (!load) return;
    this.pause = true;
    onMore();
    setTimeout(() => {
      this.pause = false;
    }, 500);
  };

  render() {
    const {children, loading, showLoader} = this.props;
    return (
      <Container ref={this.onDom}>
        {children}
        {loading && showLoader && (
          <Loader>
            <i className="mdi mdi-loading mdi-spin" />
          </Loader>
        )}
      </Container>
    );
  }
}
