import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button} from 'doorson-ui';

// Libs
import sortByString from '../../../lib/sortByString';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SYSTEM} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';
import isActive from "../../../user/lib/isActive.lib.user";

// TODO: I18N
const QuickActions = ({
                        loading,
                        intervention,
                        priorityColor,
                        users,
                        user,
                        onAction,
                      }) => {
  const isSuperUser = hasPermission({
    user,
    roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
  });
  if (intervention.closed) return null;
  if (intervention.resolved)
    return (
      <Button
        theme="black"
        onClick={() => (isSuperUser ? onAction('close', {intervention}) : null)}
        loading={loading}
        disabled={!isSuperUser}
      >
        Close
      </Button>
    );
  if (!!intervention.assignees.find((assignee) => assignee.id === user.id))
    return (
      <Button
        loading={loading}
        preIcon="arrow-left"
        onClick={() => onAction('unassign', {interventionID: intervention.id})}
        theme={priorityColor}
      >
        {intervention.status === "WAITFOREXTERNAL" && !!intervention.statusReason ? "Resolve waiting: " + (intervention.statusReason.length > 36 ? intervention.statusReason.substring(0, 36)+"..." : intervention.statusReason) : "Unassign me"}
      </Button>
    );
  if (!!intervention.assignees.length)
    return intervention.assignees.length === 1 ? (
      <Button subtext="Assigned" theme={priorityColor}>
        {intervention.assignees[0].firstName}{' '}
        {intervention.assignees[0].lastName}
      </Button>
    ) : (
      <Button subtext="Assigned" theme={priorityColor}>
        To {intervention.assignees.length} employees
      </Button>
    );
  return isSuperUser ? (
    <Button
      loading={loading}
      onClick={(userID) =>
        onAction('assign', {interventionID: intervention.id, userID})
      }
      theme={priorityColor}
      options={[...users]
        .sort(sortByString({key: 'firstName', direction: 'asc'}))
        .filter(isActive)
        .map(({id, firstName, lastName}) => ({
          value: id,
          label: `${firstName} ${lastName}`,
        }))}
      outline
    >
      Assign task to
    </Button>
  ) : (
    <Button
      loading={loading}
      postIcon="arrow-right"
      onClick={() =>
        onAction('assign', {interventionID: intervention.id, userID: user.id})
      }
      theme={priorityColor}
      outline
    >
      Assign to me
    </Button>
  );
};

QuickActions.propTypes = {
  loading: PropTypes.bool,
  intervention: PropTypes.object,
  priorityColor: PropTypes.string,
  users: PropTypes.array,
  user: PropTypes.object,
  onAction: PropTypes.func,
};

export default QuickActions;
