import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';

const Point = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin: 0px auto 5px auto;
  background: ${({color}) => colors[color]};
`;

export default Point;
