const invalidPswError = {message: 'Invalid password'};
const noRightsError = {message: 'You have no rights to log into this app'};

const isApiError = async (response) => {
  if (response.status >= 500) {
    const rawMessage = await response.text();
    throw new Error(rawMessage);
  }

  if (response.status === 401) throw invalidPswError;
  if (response.status === 403) throw noRightsError;

  if (response.status < 200 || response.status >= 300) {
    const data = await response.json();
    throw data;
  }
};
export default isApiError;
