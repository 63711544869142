import styled from 'styled-components';
import React, {Component} from 'react';
import fileIcon from "../../../lib/fileIcon.lib.file";

const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Icon = styled.div`
  width: 100%;
  height: 100%;
  font-size: 100px;
  display: inline-block;
`;

class Image extends Component {
  render = () => {
    const {preview, type} = this.props;

    return !!type && type.indexOf("image/") !== -1 ? <Picture src={preview}/> :
      <Icon className={fileIcon(type)}/>
  }
}

export default Image;
