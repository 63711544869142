import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, Modal, Button, CheckBox, Select} from 'doorson-ui';

const NewLanguageModal = ({
  title,
  disabledLabel,
  saveLabel,
  visible,
  loading,
  language,
  languages,
  disabled,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="small">
    <Row margin>
      <Column>
        <Select
          value={language}
          options={languages}
          onChange={onChange('language')}
        >
          Language
        </Select>
      </Column>
    </Row>
    <Row margin>
      <Column>
        <CheckBox
          selected={disabled}
          onSelect={onChange('disabled')}
          disabled={loading}
        >
          {disabledLabel}
        </CheckBox>
      </Column>
    </Row>
    <Row>
      <Column>
        <Button loading={loading} onClick={onSave}>
          {saveLabel}
        </Button>
      </Column>
    </Row>
  </Modal>
);

NewLanguageModal.propTypes = {
  title: PropTypes.string,
  disabledLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  language: PropTypes.string,
  languages: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default NewLanguageModal;
