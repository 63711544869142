import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isEmail} from 'validator';

// Api
import resolveApi from '../../api/resolve.api.maintenanceDoor';
import previewApi from '../../api/preview.api.maintenanceDoor';
import updateApi from '../../api/update.api.maintenanceDoor';
import downloadFileApi from '../../../file/api/download.api.file';

// Actions
import {set as setAct} from '../../redux/actions';

// Alertify
import {alertify, colors} from 'doorson-ui';

// Components
import MaintenanceDoorConfirmation from '../../components/MaintenanceDoorConfirmation/MaintenanceDoorConfirmation';
import TechnicianInfo from '../../components/TechnicianInfo/TechnicianInfo';
import CustomerInfo from '../../components/CustomerInfo/CustomerInfo';
import PdfInfo from '../../components/PdfInfo/PdfInfo';
import DocumentSignature from '../../components/DocumentSignature/DocumentSignature';
import Resolve from '../../components/Resolve/Resolve';
import ContractPreviewModal from '../../../file/components/ContractPreviewModal/ContractPreviewModal';

// Containers
import SendContractContainer from '../SendContractContainer/SendContractContainer';

// Libs
import isResolved from '../../lib/isResolved.lib.maintenanceDoor';
import isAssigned from '../../../maintenance/lib/isAssigned.lib.maintenance';
import toBase64 from '../../../file/lib/toBase64.lib.file';
import download from '../../../file/lib/download.lib.file';

// Types
import {apiDate as apiDateType, dateTime as dateTimeType} from '../../../types';
import hasPermission from '../../../user/roles/hasPermission.role.user';
import {CHIEF_SERVICE_TECHNICIAN, SYSTEM} from '../../../user/roles/roles.user';
import styled from 'styled-components';
import getTodaysVehicleByDriver from '../../../vehicle/lib/getTodaysVehicleByDriver.lib.vehicle';

const {red, orange} = colors;

const ContractReceiver = styled.span`
  color: ${(props) =>
    !!props.sent ? 'inherit' : props.sent === null ? orange : red};
`;

class MaintenanceDoorConfirmationContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    maintenanceDoor: PropTypes.object,
    user: PropTypes.object,
    transportLocations: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    init: false,
    loading: false,
    technicianInfoTouched: false,
    error: {},
    dateResolved: apiDateType(new Date()).format(),
    vehicleId: null,
    transportLocationId: null,
    transportDestination: '',
    signatoryFirstName: '',
    signatoryLastName: '',
    technicianSignature: null,
    customerSignature: null,
    visibleSignature: null,
    resolveVisible: false,
    technicianEmail: '',
    customerEmail: '',
    previewVisible: false,
    loadingPreview: false,
    preview: null,
    previewMode: false,
    downloadingContract: false,
    sendContractVisible: false,
  };

  componentDidMount() {
    this.mounted = true;
    const {
      maintenance: {email},
      maintenanceDoor: {
        vehicleId,
        transportLocation,
        transportDestination,
        signatoryFirstName,
        signatoryLastName,
        status,
      },
    } = this.props;

    this.setState({
      init: true,
      vehicleId,
      transportLocationId: !!transportLocation ? transportLocation.id : null,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
      customerEmail: email || '',
      status,
    });
  }

  maintenanceDoorUpdated = false;

  componentWillUnmount() {
    this.mounted = false;
  }

  syncMaintenanceDoor = ({...data} = {}) => {
    const {maintenanceDoor, dispatch} = this.props;
    dispatch(setAct({maintenanceDoor: {...maintenanceDoor, ...data}}));
  };

  onChange =
    (key, sync = true) =>
    (value) => {
      const {loading, error, preview, technicianInfoTouched} = this.state;
      if (loading) return;
      const data = {
        [key]: value,
        preview: sync ? null : preview,
        error: Object.entries(error).reduce(
          (combined, [errorKey, errorValue]) =>
            key === errorKey ? combined : {...combined, [errorKey]: errorValue},
          {}
        ),
        technicianInfoTouched:
          technicianInfoTouched ||
          key === 'loggedTime' ||
          key === 'vehicleId' ||
          key === 'transportLocationId' ||
          key === 'transportDestination',
      };
      this.setState(data);
      if (!sync) return;
      this.maintenanceDoorUpdated = true;
      this.syncMaintenanceDoor(data);
    };

  onChangeEmail = (key) => this.onChange(key, false);

  onSign = (box) => (value) => {
    const {loading} = this.state;
    if (loading) return;
    this.setState({[box]: value, visibleSignature: false});
  };

  openSignatureBox = (box) => () => {
    if (this.state.loading) return;
    this.setState({visibleSignature: box});
  };

  closeSignatureBox = () => {
    this.setState({visibleSignature: null});
  };

  transportLocations = () =>
    [...this.props.transportLocations].map(({id, name}) => ({
      value: id,
      label: name,
    }));

  contractReceivers = () =>
    [...this.props.maintenanceDoor.contractReceivers]
      .filter(({type}) => type === 'email')
      .map(({address, sent}) => (
        <ContractReceiver
          title={
            !!sent ? 'Sent' : sent === null ? 'Sending...' : 'Failed to sent'
          }
          sent={sent}
        >
          {address}
        </ContractReceiver>
      ));

  goToMaintenanceDoorPreview = () => {
    // TODO: I18n
    const {loading, technicianEmail, customerEmail} = this.state;

    if (loading) return;

    const error = {};
    if (!!technicianEmail.trim().length && !isEmail(technicianEmail))
      error.technicianEmail = 'Insert valid email';
    if (!isEmail(customerEmail)) error.customerEmail = 'Insert valid email';
    if (Object.keys(error).length) return this.setState({error});

    this.setState({previewMode: true});
  };

  showSendContractModal = () => {
    if (this.state.loading) return;
    this.setState({sendContractVisible: true});
  };

  hideSendContractModal = () => {
    if (this.state.loading) return;
    this.setState({sendContractVisible: false});
  };

  onSendContract = (maintenanceDoor) => {
    this.setState({sendContractVisible: false});
    this.syncMaintenanceDoor(maintenanceDoor);
  };

  resolve = async () => {
    // TODO: I18n
    const {maintenanceDoor} = this.props;
    const {
      loading,
      vehicleId,
      transportLocationId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
      technicianSignature,
      customerSignature,
      technicianEmail,
      customerEmail,
    } = this.state;

    if (loading) return;

    this.setState({loading: true});

    const companyContractReceivers = !technicianEmail
      ? []
      : [technicianEmail].filter((email) => isEmail(email));
    const customerContractReceivers = !customerEmail
      ? []
      : [customerEmail].filter((email) => isEmail(email));

    try {
      const data = {
        maintenanceDoorIds: [maintenanceDoor.id],
        technicianSignature,
        customerSignature,
        signatoryFirstName,
        signatoryLastName,
        companyContractReceivers,
        customerContractReceivers,
      };

      if (this.maintenanceDoorUpdated) {
        const updatedMaintenanceDoor = await updateApi(
          maintenanceDoor.id,
          {
            vehicleId: vehicleId || null,
            transportLocationId: transportLocationId || null,
            transportDestination,
            signatoryFirstName,
            signatoryLastName,
            status: "COMPLETED",
            findingsAndRemarks: maintenanceDoor.findingsAndRemarks,
            customerRemarks: maintenanceDoor.customerRemarks,
            replacementNeeded: maintenanceDoor.replacementNeeded,
            workingProperly: maintenanceDoor.workingProperly,
            prepareOffer: maintenanceDoor.prepareOffer,
            version: maintenanceDoor.version,
          },
          {rel: '*'}
        );
        this.syncMaintenanceDoor(updatedMaintenanceDoor);
        this.maintenanceDoorUpdated = false;
      }

      const newMaintenanceDoors = await resolveApi(
        maintenanceDoor.maintenanceId,
        data,
        {rel: '*'}
      );
      this.setState({
        loading: false,
        resolveVisible: false,
        technicianInfoTouched: false,
      });
      this.syncMaintenanceDoor(newMaintenanceDoors[0]);
      alertify.success('Resolved successfully');
    } catch (error) {
      alertify.error('Something went wrong. Try again.');
      this.setState({loading: false});
    }
  };

  closeResolve = () => {
    if (this.state.loading) return;
    this.setState({resolveVisible: false});
  };

  combinedMaintenanceDoor = () => {
    const {maintenanceDoor} = this.props;
    const {
      transportLocationId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
    } = this.state;
    return {
      ...maintenanceDoor,
      transportLocationId,
      transportLocation:
        [...this.props.transportLocations].find(
          (tl) => tl.id === transportLocationId
        ) || null,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
    };
  };

  downloadPdf = async () => {
    // TODO: I18n
    const {maintenanceDoor} = this.props;
    const {downloadingContract} = this.state;

    if (downloadingContract || !maintenanceDoor.contract) return;

    this.setState({downloadingContract: true});

    try {
      const file = await downloadFileApi(maintenanceDoor.contract.id, 'BLOB');
      download(file, maintenanceDoor.contract.originalName);
      this.setState({downloadingContract: false});
    } catch (error) {
      this.setState({downloadingContract: false});
      alertify.error('Contract could not be downloaded');
    }
  };

  onSave = async (showPreview = false) => {
    // TODO: I18n
    const {maintenanceDoor} = this.props;
    const {
      loadingPreview,
      preview,
      vehicleId,
      transportLocationId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
      loading,
    } = this.state;

    const error = {};
    if (showPreview) {
      if (!vehicleId || !vehicleId.trim().length)
        error.vehicleId = 'Should not be blank';
      if (!transportLocationId || !transportLocationId.trim().length)
        error.transportLocationId = 'Should not be blank';
      if (!transportDestination || !transportDestination.trim().length)
        error.transportDestination = 'Should not be blank';
      if (!signatoryFirstName || !signatoryFirstName.trim().length)
        error.signatoryFirstName = 'Should not be blank';
      if (!signatoryLastName || !signatoryLastName.trim().length)
        error.signatoryLastName = 'Should not be blank';
      if (Object.keys(error).length) return this.setState({error});

      if (loadingPreview || !!preview)
        return this.setState({previewVisible: true});

      this.setState({loadingPreview: true, previewVisible: true});
    } else {
      if (Object.keys(error).length) return this.setState({error});
    }

    try {
      if (loading) return;
      this.setState({loading: true});
      const newStatus = this.willComplete() ? "COMPLETED" : "OPENED";
      const data = {
        vehicleId,
        transportLocationId,
        transportDestination,
        signatoryFirstName,
        signatoryLastName,
        status: newStatus,
        findingsAndRemarks: maintenanceDoor.findingsAndRemarks,
        customerRemarks: maintenanceDoor.customerRemarks,
        replacementNeeded: maintenanceDoor.replacementNeeded,
        workingProperly: maintenanceDoor.workingProperly,
        prepareOffer: maintenanceDoor.prepareOffer,
        version: maintenanceDoor.version,
      };

      const newMaintenanceDoor = await updateApi(maintenanceDoor.id, data, {
        rel: '*',
      });
      this.setState({ status: newStatus });
      this.syncMaintenanceDoor(newMaintenanceDoor);
      this.maintenanceDoorUpdated = false;
      if (!showPreview) {
        this.setState({technicianInfoTouched: false});
        return;
      }
      const pdfBlob = await previewApi(maintenanceDoor.maintenanceId, [
        maintenanceDoor.id,
      ]);
      const pdf64 = await toBase64(pdfBlob);
      const pdf = pdf64.startsWith('data:application/octet-stream')
        ? pdf64.replace('data:application/octet-stream', 'data:application/pdf')
        : pdf64;
      this.setState({
        loadingPreview: false,
        preview: pdf,
        technicianInfoTouched: false,
      });
    } catch (error) {
      alertify.error('Could not generate report preview. Try again,');
      this.setState({previewVisible: false, loadingPreview: false});
    } finally {
      this.setState({loading: false});
    }
  };

  closePreview = () => this.setState({previewVisible: false});

  beforeResolve = () => {
    // TODO: I18n
    const {
      loading,
      transportLocationId,
      vehicleId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
      technicianSignature,
    } = this.state;

    if (loading) return;

    const error = {};

    if (!vehicleId || !vehicleId.trim().length)
      error.vehicleId = 'Should not be blank';
    if (!transportLocationId || !transportLocationId.trim().length)
      error.transportLocationId = 'Should not be blank';
    if (!transportDestination || !transportDestination.trim().length)
      error.transportDestination = 'Should not be blank';
    if (!signatoryFirstName || !signatoryFirstName.trim().length)
      error.signatoryFirstName = 'Should not be blank';
    if (!signatoryLastName || !signatoryLastName.trim().length)
      error.signatoryLastName = 'Should not be blank';

    if (Object.keys(error).length) {
      this.setState({error});
      return alertify.warning(
        'Make sure you filled out all the required fields'
      );
    }

    if (!technicianSignature) return alertify.warning('Sign the contract');

    this.setState({resolveVisible: true, previewMode: false});
  };

  vehicle = (vehicle) =>
    !vehicle
      ? ''
      : `${vehicle.label} ${vehicle.make} ${vehicle.model} ${vehicle.licensePlate}`;

  vehicles = () => {
    const {vehicles, user} = this.props;
    const currentVehicle = getTodaysVehicleByDriver({
      driverId: user.id,
      vehicles,
    });

    return [
      currentVehicle && {
        value: currentVehicle.id,
        label: this.vehicle(currentVehicle) + ' (Current)',
      },
      ...[...this.props.vehicles]
        .filter((w) => w.id !== (currentVehicle || {}).id)
        .map((w) => ({
          value: w.id,
          label: this.vehicle(w),
        })),
    ].filter((v) => !!v);
  };

  willComplete = () => {
    const {
      transportLocationId,
      vehicleId,
      transportDestination
    } = this.state;

    return transportLocationId && transportLocationId.trim().length && vehicleId && vehicleId.trim().length && transportDestination && transportDestination.trim().length;
  }

  render() {
    const {maintenance, maintenanceDoor, user} = this.props;
    const {
      init,
      loading,
      error,
      vehicleId,
      status,
      transportLocationId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
      technicianSignature,
      customerSignature,
      visibleSignature,
      resolveVisible,
      technicianEmail,
      customerEmail,
      loadingPreview,
      previewVisible,
      preview,
      previewMode,
      sendContractVisible,
      technicianInfoTouched,
    } = this.state;

    const {customerBranch} = maintenance;
    const suggestionEmails = ((customerBranch || {}).emails || []).map(
      (email) => ({value: email, label: email})
    );
    const maintenanceBranchCity = ((customerBranch || {}).branchLocation || {})
      .city;

    return !init ? null : (
      <MaintenanceDoorConfirmation>
        <TechnicianInfo
          techniciansLabel="Technicians info"
          resolvedByLabel="Service resolved by"
          completedAtLabel="Service completed at"
          transportLabel="Transport"
          vehicleLabel="Vehicle"
          signatureLabel="Signature - Technician"
          cityLabel="City"
          toLabel="To"
          destinationLabel="Destination"
          signLabel="Sign Document"
          signedLabel="Document Signed"
          notYetResolvedLabel="Service is not resolved yet"
          loading={loading}
          assigned={isAssigned(user, maintenance)}
          resolved={isResolved(maintenanceDoor)}
          error={error}
          resolvedBy={maintenanceDoor.resolvedBy}
          dateCompleted={
            !!maintenanceDoor.dateResolved
              ? dateTimeType(maintenanceDoor.dateResolved).format()
              : null
          }
          vehicleId={vehicleId}
          vehicle={this.vehicle(
            [...this.props.vehicles].find((v) => v.id === vehicleId)
          )}
          vehicles={this.vehicles()}
          transportLocationId={transportLocationId}
          transportLocation={
            !!maintenanceDoor.transportLocation
              ? maintenanceDoor.transportLocation.name
              : ''
          }
          transportDestination={transportDestination}
          transportDestinations={
            maintenanceBranchCity
              ? [
                  {
                    value: maintenanceBranchCity,
                    label: maintenanceBranchCity,
                  },
                ]
              : []
          }
          transportLocations={this.transportLocations()}
          signed={!!maintenanceDoor.dateResolved || !!technicianSignature}
          onChange={this.onChange}
          onSign={this.openSignatureBox('technicianSignature')}
          saved={!technicianInfoTouched}
          onSave={() => this.onSave(false)}
          saveLabel={ this.willComplete() ? "Save and complete" : "Save technicians info" }
          savedLabel={"COMPLETED" === status ? "Completed" : "Saved"}
        />

        <CustomerInfo
          customerInfoLabel="Customer Info"
          registrationNumberLabel="Registration Number"
          companyNameLabel="Company name"
          addressLabel="Address"
          zipLabel="ZIP Code"
          cityLabel="City"
          branchLabel="Branch name and address"
          phoneLabel="Telephone"
          emailLabel="Email"
          customerLabel="Customer"
          firstNameLabel="First Name"
          lastNameLabel="Last Name"
          signatureLabel="Signature - customer"
          signLabel="Sign Document"
          signedLabel="Document Signed"
          receiversLabel="Contract sent to"
          loading={loading}
          assigned={isAssigned(user, maintenance)}
          resolved={isResolved(maintenanceDoor)}
          error={error}
          customer={maintenance.customer}
          branchName={maintenance.customerBranch.branchName}
          branchLocation={maintenance.customerBranch.branchLocation}
          email={maintenance.email}
          phone={maintenance.phone}
          signatoryFirstName={signatoryFirstName}
          signatoryLastName={signatoryLastName}
          signed={!!maintenanceDoor.dateResolved || !!customerSignature}
          contractReceivers={this.contractReceivers()}
          onChange={this.onChange}
          onSign={this.openSignatureBox('customerSignature')}
        />

        <PdfInfo
          pdfLabel="Pdf Document"
          resolveLabel="Resolve"
          previewLabel="Preview PDF report"
          pdfWillBeGeneratedLabel="PDF will be generated"
          sendContractLabel="Send Report"
          pdf={maintenanceDoor.contract}
          loading={loading}
          assigned={isAssigned(user, maintenance)}
          resolved={isResolved(maintenanceDoor)}
          isCST={hasPermission({
            user,
            roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
          })}
          onDownload={this.downloadPdf}
          onAction={this.beforeResolve}
          onPreview={() => this.onSave(true)}
          onContract={this.showSendContractModal}
        />

        <DocumentSignature
          visible={visibleSignature === 'technicianSignature'}
          title="Technician Signature"
          confirmLabel="Confirm"
          clearLabel="Clear"
          onSign={this.onSign('technicianSignature')}
          onClear={() => this.onSign('technicianSignature')(null)}
          onClose={this.closeSignatureBox}
        />

        <DocumentSignature
          visible={visibleSignature === 'customerSignature'}
          title="Customer Signature"
          confirmLabel="Confirm"
          clearLabel="Clear"
          onSign={this.onSign('customerSignature')}
          onClear={() => this.onSign('customerSignature')(null)}
          onClose={this.closeSignatureBox}
        />

        <Resolve
          loading={loading}
          visible={resolveVisible}
          previewMode={previewMode}
          error={error}
          title="Resolve maintenance"
          technicianEmailLabel="Technician Email"
          customerEmailLabel="Customer Email"
          resolveLabel="Resolve"
          noSignatureLabel="The customer didn't sign the contract"
          maintenance={maintenance}
          maintenanceDoor={this.combinedMaintenanceDoor()}
          technicianEmail={technicianEmail}
          suggestionEmails={suggestionEmails}
          customerEmail={customerEmail}
          signed={!!customerSignature}
          onChange={this.onChangeEmail}
          onClose={this.closeResolve}
          onPreview={this.goToMaintenanceDoorPreview}
          onResolve={this.resolve}
        >
          Specify to whom the contract should be sent to
        </Resolve>

        <ContractPreviewModal
          loading={loadingPreview}
          visible={previewVisible}
          title="Contract Preview"
          preview={preview}
          onClose={this.closePreview}
        />

        <SendContractContainer
          visible={sendContractVisible}
          maintenanceDoor={maintenanceDoor}
          onFinish={this.onSendContract}
          onClose={this.hideSendContractModal}
        />
      </MaintenanceDoorConfirmation>
    );
  }
}

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
  maintenanceDoor: state.maintenanceDoor.maintenanceDoor,
  user: state.auth.user,
  vehicles: state.vehicle.vehicles,
  transportLocations: state.transportLocation.transportLocations,
}))(MaintenanceDoorConfirmationContainer);
