import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Column, Container, Row, Tabs} from 'doorson-ui';
import Content from './components/Content';
import Right from "../WarehouseIdents/components/Right";

const WarehouseIdentsFilter = ({filter, filters, children, onFilter}) => (
  <Container>
    <Row>
      <Column>
        <Content>
          <Tabs tab={filter} tabs={filters} onTab={onFilter}/>
          <Right>
            {children}
          </Right>
        </Content>
      </Column>
    </Row>
  </Container>
);

WarehouseIdentsFilter.propTypes = {
  filter: PropTypes.string,
  filters: PropTypes.array,
  actions: PropTypes.array,
  onFilter: PropTypes.func,
};

export default WarehouseIdentsFilter;
