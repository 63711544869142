import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Alert} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {DatePicker} from 'doorson-ui';
import {Text} from 'doorson-ui';

import Preview from '../Preview/Preview';

import Content from './components/Content';

const Resolve = ({
  loading,
  visible,
  isHistoric,
  previewMode,
  children,
  title,
  signed,
  error,
  technicianEmailLabel,
  customerEmailLabel,
  resolveLabel,
  noSignatureLabel,
  resolvedLabel,
  codeLabel,
  dateResolved,
  technicianEmail,
  customerEmail,
  suggestionEmails,
  intervention,
  code,
  onChange,
  onClose,
  onResolve,
  onPreview,
}) => (
  <Modal visible={visible} title={title} onClose={onClose}>
    {previewMode ? (
      <Fragment>
        <Preview
          customerLabel="Customer"
          customerTypeLabel="Type"
          companyLabel="Company name"
          registrationNumberLabel="Registration number"
          firstNameLabel="First Name"
          lastNameLabel="Last Name"
          addressLabel="Address"
          zipLabel="Zip Code"
          cityLabel="City"
          countryLabel="Country"
          branchLocationLabel="Branch name and address"
          phoneLabel="Phone"
          emailLabel="Email"
          givenDescriptionLabel="Given fault description"
          manufacturerLabel="Manufacturer"
          doorVersionLabel="Door version"
          doorTypeLabel="Door type"
          redundancyLabel="Redundancy"
          indoorLocationLabel="Door location"
          buildingFloorLabel="Building floor"
          doorSerialLabel="Door serial number"
          electronicsSerialLabel="Electronic serial number"
          motorSerialLabel="Motor serial number"
          technicianDescriptionLabel="Actual fault as identified by technician"
          repairDescriptionLabel="Repair description"
          replacedMaterialLabel="Required / replaced material"
          findingsRemarksLabel="Findings and remarks by technician"
          customerRemarksLabel="Remarks by Customer"
          internalRemarksLabel="Internal remarks"
          replacementNeededLabel="Replacement needed"
          doorsWorkingLabel="Doors are working properly"
          prepareOfferLabel="Prepare offer for customer"
          confirmationLabel="Confirmation"
          transportLocationLabel="Transport City"
          destinationLabel="Destination"
          signatoryFirstNameLabel="Signatory First Name"
          signatoryLastNameLabel="Signatory Last Name"
          loading={loading}
          intervention={intervention}
          resolveLabel={resolveLabel}
          onResolve={onResolve}
        />
      </Fragment>
    ) : (
      <Fragment>
        {!signed && (
          <Row margin>
            <Column>
              <Alert type="warning" flat>
                {noSignatureLabel}
              </Alert>
            </Column>
          </Row>
        )}
        <Row margin>
          <Column>
            <Content>{children}</Content>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <Input
              value={technicianEmail}
              onChange={onChange('technicianEmail')}
              error={error.technicianEmail}
              disabled={loading}
            >
              {technicianEmailLabel}
            </Input>
          </Column>
          <Column size={1 / 2}>
            <Input
              value={customerEmail}
              onChange={onChange('customerEmail')}
              error={error.customerEmail}
              disabled={loading}
              required={!isHistoric}
              suggestions={suggestionEmails}
              onSuggestion={(email) => onChange('customerEmail')(email)}
              displaySuggestions={()=> true}
            >
              {customerEmailLabel}
            </Input>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <Content>
              <Text color="darkGrey">{resolvedLabel}</Text>
            </Content>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2} offset={isHistoric ? 0 : 1 / 4}>
            <DatePicker
              value={dateResolved}
              onChange={onChange('dateResolved')}
              disabledDates={[{after: new Date()}]}
            />
          </Column>
          {isHistoric && (
            <Column size={1 / 2}>
              <Input
                value={code}
                onChange={onChange('code')}
                disabled={loading}
              >
                {codeLabel}
              </Input>
            </Column>
          )}
        </Row>
        <Button theme="orange" loading={loading} onClick={onPreview} outline>
          {resolveLabel}
        </Button>
      </Fragment>
    )}
  </Modal>
);

Resolve.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  isHistoric: PropTypes.bool,
  previewMode: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  signed: PropTypes.bool,
  error: PropTypes.object,
  technicianEmailLabel: PropTypes.string,
  customerEmailLabel: PropTypes.string,
  resolveLabel: PropTypes.string,
  resolvedLabel: PropTypes.string,
  codeLabel: PropTypes.string,
  dateResolved: PropTypes.string,
  intervention: PropTypes.object,
  noSignatureLabel: PropTypes.string,
  technicianEmail: PropTypes.string,
  customerEmail: PropTypes.string,
  suggestionEmails: PropTypes.array,
  code: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onResolve: PropTypes.func,
  onPreview: PropTypes.func,
};

export default Resolve;
