import styled from 'styled-components';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0px;
`;

export default LoadingContainer;
