import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {SimpleTable} from 'doorson-ui';
import DataBlock from '../../../layout/components/DataBlock/DataBlock';
import Container from './components/Container';

const Profile = ({
  profileLabel,
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  rolesLabel,
  user,
  roles,
}) => (
  <Container>
    <DataBlock label={profileLabel}>
      <Row>
        <Column size={1 / 2}>
          <SimpleTable
            values={[
              {
                key: firstNameLabel,
                value: user.firstName,
              },
              {
                key: lastNameLabel,
                value: user.lastName,
              },
              {
                key: emailLabel,
                value: user.email,
              },
              {
                key: rolesLabel,
                value: roles,
              },
            ]}
          />
        </Column>
      </Row>
    </DataBlock>
  </Container>
);

Profile.propTypes = {
  profileLabel: PropTypes.string,
  firstNameLabel: PropTypes.string,
  lastNameLabel: PropTypes.string,
  emailLabel: PropTypes.string,
  rolesLabel: PropTypes.string,
  user: PropTypes.object,
  roles: PropTypes.string,
};

export default Profile;
