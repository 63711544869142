import styled from 'styled-components';

const Actions = styled.div`
  width: 100%;
  max-width: 160px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Actions;
