import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {black} = colors;

const EditableTitle = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 5px;
  color: ${black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default EditableTitle;
