import f3tch from 'f3tch';
// Constants
import url from '../../api/constants/warehouseUrl.const.api';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const getWarehouseApi = (id, query = {}) =>
  f3tch(`/erp/warehouses/${id}`).profile(profile({url})).query(query).get();
export default getWarehouseApi;
