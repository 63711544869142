import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {black} = colors;

const Text = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: ${black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default Text;
