import styled from 'styled-components';

const DisabledContainer = styled.div`
  display: flex;
  width: 33.33%;
  max-width: 33.33%;
  min-width: 33.33%;
  position: relative;
`;

export default DisabledContainer;
