import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createInterventionDoorMaterialApi = (interventionDoorID, material) =>
  f3tch(`/intervention-doors/${interventionDoorID}/materials`)
    .profile(profile())
    .body(material)
    .post();
export default createInterventionDoorMaterialApi;
