import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const Loader = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: ${orange};
`;

export default Loader;
