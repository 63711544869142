import styled from 'styled-components';

const Columns = styled.div`
  width: 20%;
  min-width: 20%;
  max-width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Columns;
