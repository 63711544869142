const isDoorComplete = ({door = null, repairs = []} = {}) =>
  !!door &&
  [door.doorSerial, door.electronicsSerial, door.motorSerial, repairs].every(
    (data) => !!data && !!data.length
  );
// export default ({door = null, faults = [], repairs = []} = {}) =>
//   !!door &&
//   [
//     door.doorSerial,
//     door.electronicsSerial,
//     door.motorSerial,
//     faults,
//     repairs,
//   ].every((data) => !!data && !!data.length);
export default isDoorComplete;
