import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  AsyncInput,
  Button,
  Column,
  Form,
  Input,
  Row,
  Textarea,
} from 'doorson-ui';

import Container from './components/Container';
import HorizontalScroll from './components/HorizontalScroll';
import TabContainer from './components/TabContainer';
import Tab from './components/Tab';
import AddTab from './components/AddTab';
import Box from './components/Box';
import Actions from './components/Actions';
import RemoveContainer from './components/RemoveContainer';
import ButtonContainer from './components/ButtonContainer';

const DoorEdit = ({
  loading,
  searchingForReportedFault,
  doors,
  door,
  reportedFaults,
  reportingRemarks,

  givenDescriptionLabel,
  indoorLocationLabel,
  reportingRemarksLabel,
  removeDoorLabel,
  buttonLabel,
  noSuggestionLabel,

  onReportedFault,
  onNewDoor,
  onDoor,
  onChange,
  onConfirm,
  onRemove,
  onRemarksChange,
}) => (
  <Container>
    <HorizontalScroll>
      <TabContainer>
        {doors.map((d) => (
          <Tab
            key={d.id}
            selected={d.id === door.id}
            label={d.indoorLocation}
            onClick={() => onDoor(d.id)}
            error={!!Object.keys(d.error || {}).length}
          />
        ))}
        <AddTab onClick={onNewDoor}>
          <i className="mdi mdi-plus" />
        </AddTab>
      </TabContainer>
    </HorizontalScroll>
    {!!door && (
      <Box>
        <Form onSubmit={onConfirm} enter>
          <Row margin>
            <Column size={2 / 3}>
              <AsyncInput
                value={door.givenDescription}
                onChange={onChange('givenDescription')}
                error={door.error.givenDescription}
                disabled={loading}
                suggestions={reportedFaults}
                onSuggestion={onReportedFault}
                noSuggestionLabel={noSuggestionLabel}
                displaySuggestions={() => true}
                searching={searchingForReportedFault}
                required
              >
                {givenDescriptionLabel}
              </AsyncInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={2 / 3}>
              <Input
                value={door.indoorLocation}
                onChange={onChange('indoorLocation')}
                error={door.error.indoorLocation}
                disabled={loading}
                required
              >
                {indoorLocationLabel}
              </Input>
            </Column>
          </Row>
          {!!onRemarksChange && (
            <Row margin>
              <Column size={2 / 3}>
                <Textarea
                  value={reportingRemarks}
                  onChange={onRemarksChange('reportingRemarks')}
                  disabled={loading}
                >
                  {reportingRemarksLabel}
                </Textarea>
              </Column>
            </Row>
          )}
        </Form>
        <Row>
          <Actions>
            <RemoveContainer>
              {Object.keys(doors).length > 1 && (
                <Button theme="grey" onClick={onRemove} disabled={loading}>
                  {removeDoorLabel}
                </Button>
              )}
            </RemoveContainer>
            <ButtonContainer>
              <Button
                theme="orange"
                size="big"
                onClick={onConfirm}
                loading={loading}
                postIcon="arrow-right"
              >
                {buttonLabel}
              </Button>
            </ButtonContainer>
          </Actions>
        </Row>
      </Box>
    )}
  </Container>
);

DoorEdit.propTypes = {
  loading: PropTypes.bool,
  doors: PropTypes.array,
  door: PropTypes.object,
  reportedFaults: PropTypes.array,
  reportingRemarks: PropTypes.string,

  givenDescriptionLabel: PropTypes.string,
  indoorLocationLabel: PropTypes.string,
  reportingRemarksLabel: PropTypes.string,
  removeDoorLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  noSuggestionLabel: PropTypes.string,
  searchingForReportedFault: PropTypes.bool,

  onReportedFault: PropTypes.func,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  onRemove: PropTypes.func,
  onRemarksChange: PropTypes.func,
};

export default DoorEdit;
