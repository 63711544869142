import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import doorIconBlack from '../../assets/doorIconBlack.png';

// Components
import {
  AsyncInput,
  Button,
  CheckBox,
  colors,
  Column,
  EditableOption,
  Info,
  Input,
  Row,
  Select,
  Textarea,
} from 'doorson-ui';

import Container from './components/Container';
import HorizontalScroll from './components/HorizontalScroll';
import TabContainer from './components/TabContainer';
import Tab from './components/Tab';

import Right from './components/Right';
import ButtonContainer from './components/ButtonContainer';
import Option from './components/Option';
import SmallOption from './components/SmallOption';
import CheckContainer from './components/CheckContainer';
import Check from './components/Check';

// Libs
import validateNumberInput from '../../../layout/lib/validateNumberInput.lib.layout';
import validateSerialNumberInput from '../../../layout/lib/validateSerialNumberInput.lib.layout';
import DataBlock from "../../../layout/components/DataBlock/DataBlock";
import styled from "styled-components";
import {Link as LinkDom} from "react-router-dom";
import {getTime} from "date-fns";
import TextBlock from "../../../layout/components/TextBlock/TextBlock";
import {date as dateType} from "../../../types";

const {green, red} = colors;

const Image = styled.img`
  padding-top: 16px;
  width: 23px;
  display: block;
`;

const Link = styled(LinkDom)`
  display: block;
  padding-bottom: 20px;
`;

const Workinglog = ({
                      doorSerialLabel,
                      electronicsSerialLabel,
                      motorSerialLabel,
                      technicianDescriptionLabel,
                      repairDescriptionLabel,
                      replacedMaterialLabel,
                      findingsRemarksLabel,
                      customerRemarksLabel,
                      internalRemarksLabel,
                      replacementNeededLabel,
                      doorsWorkingLabel,
                      prepareOfferLabel,
                      buttonLabel,
                      manufacturerLabel,
                      doorVersionLabel,
                      doorTypeLabel,
                      noMaterialsLabel,
                      indoorLocationLabel,
                      buildingFloorLabel,
                      redundancyLabel,
                      noSuggestionLabel,

                      loading,
                      serviceItemUrl,
                      doorSearchable,
                      assigned,
                      resolved,
                      doors,
                      door,
                      searchedDoors,
                      searchingForDoor,

                      faults,
                      selectedFaults,
                      repairs,
                      selectedRepairs,
                      materials,
                      selectedMaterials,
                      manufacturers,
                      products,
                      productTypes,

                      updatingFaults,
                      updatingRepairs,
                      updatingMaterials,

                      scan,
                      toggleScan,
                      scanLabel,

                      onChange,
                      addFault,
                      changeFault,
                      updateFault,
                      removeFault,
                      addRepair,
                      changeRepair,
                      updateRepair,
                      removeRepair,
                      addMaterial,
                      changeMaterial,
                      updateMaterial,
                      removeMaterial,
                      onDoor,
                      onSearchedDoor,
                      onSave,
                    }) => (
  <Container>
    <HorizontalScroll>
      <TabContainer>
        {doors
          .sort((a, b) => {
            if (a.id > b.id) return 1;
            else if (a.id < b.id) return -1;
            return 0;
          })
          .map((d) => (
            <Tab
              key={d.id}
              selected={d.id === door.id}
              label={
                !!d.door && d.door.indoorLocation
                  ? `${d.door.indoorLocation} ${d.door.buildingFloor || ''}`
                  : (d.indoorLocation || 'Unknown doors')
              }
              error={!!Object.keys(d.error).length}
              onClick={() => onDoor(d.id)}
            />
          ))}
      </TabContainer>
    </HorizontalScroll>
    <DataBlock label={"Door information"}>
      <Row>
        <Column size={2 / 3} m={1}>
          <Row margin>
            <Column size={22 / 30}>
              {resolved || !assigned ? (
                <Info label={indoorLocationLabel}>
                  {!!door.door ? door.door.indoorLocation : ''}
                </Info>
              ) : (
                <AsyncInput
                  value={!!door.door ? door.door.indoorLocation : ''}
                  onChange={onChange('indoorLocation', true)}
                  error={door.error.indoorLocation}
                  disabled={loading}
                  suggestions={searchedDoors}
                  onSuggestion={onSearchedDoor}
                  noSuggestionLabel={noSuggestionLabel}
                  displaySuggestions={() => doorSearchable}
                  searching={searchingForDoor}
                  required
                >
                  {indoorLocationLabel}
                </AsyncInput>
              )}
            </Column>
            <Column size={1 / 30}>
              {!!serviceItemUrl && <Link to={serviceItemUrl}>
                <Image src={doorIconBlack}/>
              </Link>
              }
            </Column>
            <Column size={7 / 30}>
              {resolved || !assigned ? (
                <Info label={buildingFloorLabel}>
                  {!!door.door ? door.door.buildingFloor : ''}
                </Info>
              ) : (
                <Input
                  value={!!door.door ? door.door.buildingFloor : ''}
                  onChange={onChange('buildingFloor', true)}
                  error={door.error.buildingFloor}
                  disabled={loading}
                  required
                >
                  {buildingFloorLabel}
                </Input>
              )}
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              {resolved || !assigned ? (
                <Info label={manufacturerLabel}>
                  {!!door.door ? door.door.product.manufacturer.name : ''}
                </Info>
              ) : (
                <Select
                  value={door.manufacturer}
                  options={manufacturers}
                  onChange={onChange('manufacturer')}
                  error={door.error.manufacturer}
                  disabled={loading}
                  required
                >
                  {manufacturerLabel}
                </Select>
              )}
            </Column>
            <Column size={1 / 4}>
              <Info noBorder={true}>
                {
                  (!!door.door && !!door.door.warrantyValidTillDate && (door.door.warrantyValidTillDate > getTime(new Date())
                    ? <TextBlock color={green}>Warranty is active
                      till {dateType(door.door.warrantyValidTillDate).format()}.</TextBlock> :
                    <TextBlock color={red}>Warranty expired!</TextBlock>))
                  || 'No warranty info.'}
              </Info>
            </Column>
            <Column size={1 / 4}>
              <ButtonContainer>
                {((door.door||{}).id && <Button
                  size="normal"
                  theme="blue"
                  preIcon="qrcode"
                  disabled={scan || resolved || !assigned}
                  outline={true}
                  onClick={() => toggleScan(!scan)}>
                  {scanLabel}
                </Button>) || <Button
                  size="normal"
                  theme="blue"
                  preIcon="qrcode"
                  disabled={scan || resolved || !assigned}
                  outline={true}
                  onClick={() => toggleScan(!scan)}>
                  {scanLabel}
                </Button>}
              </ButtonContainer>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              {resolved || !assigned ? (
                <Info label={doorVersionLabel}>
                  {!!door.door ? door.door.product.name : ''}
                </Info>
              ) : (
                <Select
                  value={door.productId}
                  options={products}
                  onChange={onChange('productId')}
                  error={door.error.productId}
                  disabled={loading}
                  required
                >
                  {doorVersionLabel}
                </Select>
              )}
            </Column>
            <Column size={1 / 3}>
              {resolved || !assigned ? (
                <Info label={doorTypeLabel}>
                  {!!((door.door || {}).productType) ? door.door.productType.name : ''}
                </Info>
              ) : (
                <Select
                  value={door.productTypeId || ''}
                  options={productTypes}
                  onChange={onChange('productTypeId')}
                  error={door.error.productTypeId}
                  disabled={loading}
                  required={
                    !door.productId ||
                    (!!door.productId &&
                      !(
                        productTypes.length === 1 &&
                        productTypes[0].value === ''
                      ))
                  }
                >
                  {doorTypeLabel}
                </Select>
              )}
            </Column>
            <Column size={1 / 3}>
              <CheckBox
                selected={!!door.door ? door.door.redundancy : false}
                onSelect={onChange('redundancy', true)}
                disabled={loading}
                large
              >
                {redundancyLabel}
              </CheckBox>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              {resolved || !assigned ? (
                <Info label={doorSerialLabel}>
                  {!!door.door ? door.door.doorSerial : ''}
                </Info>
              ) : (
                <Input
                  type="text"
                  value={!!door.door ? door.door.doorSerial : ''}
                  onChange={onChange('doorSerial', true)}
                  error={door.error.doorSerial}
                  disabled={loading}
                  onKeyDown={validateSerialNumberInput}
                  required
                >
                  {doorSerialLabel}
                </Input>
              )}
            </Column>
            <Column size={1 / 3}>
              {resolved || !assigned ? (
                <Info label={electronicsSerialLabel}>
                  {!!door.door ? door.door.electronicsSerial : ''}
                </Info>
              ) : (
                <Input
                  type="text"
                  value={!!door.door ? door.door.electronicsSerial : ''}
                  onChange={onChange('electronicsSerial', true)}
                  error={door.error.electronicsSerial}
                  onKeyDown={validateSerialNumberInput}
                  disabled={loading}
                  required
                >
                  {electronicsSerialLabel}
                </Input>
              )}
            </Column>
            <Column size={1 / 3}>
              {resolved || !assigned ? (
                <Info label={motorSerialLabel}>
                  {!!door.door ? door.door.motorSerial : ''}
                </Info>
              ) : (
                <Input
                  type="text"
                  value={!!door.door ? door.door.motorSerial : ''}
                  onChange={onChange('motorSerial', true)}
                  error={door.error.motorSerial}
                  onKeyDown={validateSerialNumberInput}
                  disabled={loading}
                  required
                >
                  {motorSerialLabel}
                </Input>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
    </DataBlock>
    <DataBlock label={"Repair information"}>
      <Row>
        <Column size={2 / 3} m={1}>
          <Row margin>
            <Column>
              {resolved || !assigned ? (
                <Info label={technicianDescriptionLabel} noBorder>
                  {selectedFaults.map(({value, label}, index) => (
                    <Option key={value} noMargin={!index}>
                      <EditableOption value={label} derecognized readOnly/>
                    </Option>
                  ))}
                </Info>
              ) : (
                <Fragment>
                  <Select
                    value=""
                    options={faults}
                    onChange={addFault}
                    error={door.error.fault}
                    disabled={loading}
                    required
                  >
                    {technicianDescriptionLabel}
                  </Select>
                  {selectedFaults.map(({value, disabled, label}, index) => (
                    <Option key={value} noMargin={!index}>
                      <EditableOption
                        type="text"
                        value={label}
                        onChange={disabled ? () => {
                        } : changeFault(value, 'name')}
                        onRemove={removeFault(value)}
                        onBlur={disabled ? () => {
                        } : updateFault(value)}
                        loading={updatingFaults.includes(value)}
                        derecognized
                      />
                    </Option>
                  ))}
                </Fragment>
              )}
            </Column>
          </Row>
          <Row margin>
            <Column>
              {resolved || !assigned ? (
                <Info label={repairDescriptionLabel} noBorder>
                  {selectedRepairs.map(({value, label}, index) => (
                    <Option key={value} noMargin={!index}>
                      <EditableOption value={label} derecognized readOnly/>
                    </Option>
                  ))}
                </Info>
              ) : (
                <Fragment>
                  <Select
                    value=""
                    options={repairs}
                    onChange={addRepair}
                    error={door.error.repair}
                    disabled={loading}
                    required
                  >
                    {repairDescriptionLabel}
                  </Select>
                  {selectedRepairs.map(({value, disabled, label}, index) => (
                    <Option key={value} noMargin={!index}>
                      <EditableOption
                        type="text"
                        value={label}
                        onChange={disabled ? () => {
                        } : changeRepair(value, 'description')}
                        onRemove={removeRepair(value)}
                        onBlur={disabled ? () => {
                        } : updateRepair(value)}
                        loading={updatingRepairs.includes(value)}
                        derecognized
                      />
                    </Option>
                  ))}
                </Fragment>
              )}
            </Column>
          </Row>
          {!resolved && !!assigned && !selectedMaterials.length && (
            <Fragment>
              <Row margin/>
              <Row margin>
                <Column>
                  <CheckBox
                    selected={door.noMaterials}
                    onSelect={onChange('noMaterials')}
                    disabled={loading || !!selectedMaterials.length}
                  >
                    {noMaterialsLabel}
                  </CheckBox>
                </Column>
              </Row>
            </Fragment>
          )}
          <Row margin={!door.noMaterials || resolved || !assigned}>
            <Column>
              {resolved || !assigned ? (
                <Info label={replacedMaterialLabel} noBorder>
                  {selectedMaterials.map(
                    ({value, label, guarantee, unit, booked, quantity}, index) => (
                      <Option key={value} noMargin={!index}>
                        <EditableOption
                          value={label}
                          guarantee={guarantee}
                          derecognized={booked}
                          readOnly
                        />
                        <SmallOption>
                          <EditableOption
                            value={`${quantity}`}
                            label={unit}
                            readOnly
                          />
                        </SmallOption>
                      </Option>
                    )
                  )}
                </Info>
              ) : (
                !door.noMaterials && (
                  <Fragment>
                    <Select
                      value=""
                      options={materials}
                      onChange={addMaterial}
                      error={door.error.material}
                      disabled={loading}
                      required
                    >
                      {replacedMaterialLabel}
                    </Select>
                    {selectedMaterials.map(
                      ({value, label, guarantee, unit, quantity}, index) => (
                        <Option key={value} noMargin={!index}>
                          <EditableOption
                            type="text"
                            value={label}
                            guarantee={guarantee}
                            onChange={changeMaterial(value, 'name')}
                            onRemove={removeMaterial(value)}
                            onBlur={updateMaterial(value)}
                            loading={updatingMaterials.includes(value)}
                          />
                          <SmallOption>
                            <EditableOption
                              type="number"
                              step=".10"
                              value={`${quantity}`}
                              onKeyDown={validateNumberInput}
                              onChange={changeMaterial(value, 'quantity')}
                              onBlur={updateMaterial(value)}
                              label={unit}
                              disabled={updatingMaterials.includes(value)}
                            />
                          </SmallOption>
                        </Option>
                      )
                    )}
                  </Fragment>
                )
              )}
            </Column>
          </Row>
          <Row margin>
            <Column>
              {resolved || !assigned ? (
                <Info label={findingsRemarksLabel}>
                  {door.findingsAndRemarks || ''}
                </Info>
              ) : (
                <Textarea
                  value={door.findingsAndRemarks}
                  onChange={onChange('findingsAndRemarks')}
                  error={door.error.findingsAndRemarks}
                  disabled={loading}
                >
                  {findingsRemarksLabel}
                </Textarea>
              )}
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              {resolved || !assigned ? (
                <Info label={customerRemarksLabel}>
                  {door.customerRemarks || ''}
                </Info>
              ) : (
                <Input
                  value={door.customerRemarks}
                  onChange={onChange('customerRemarks')}
                  error={door.error.customerRemarks}
                  disabled={loading}
                >
                  {customerRemarksLabel}
                </Input>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
      <Row margin={!resolved}>
        <Column>
          <CheckContainer>
            <Check>
              <CheckBox
                selected={door.replacementNeeded}
                onSelect={onChange('replacementNeeded')}
                disabled={loading || resolved || !assigned}
              >
                {replacementNeededLabel}
              </CheckBox>
            </Check>
            <Check>
              <CheckBox
                selected={door.workingProperly}
                onSelect={onChange('workingProperly')}
                disabled={loading || resolved || !assigned}
              >
                {doorsWorkingLabel}
              </CheckBox>
            </Check>
            <Check>
              <CheckBox
                selected={door.prepareOffer}
                onSelect={onChange('prepareOffer')}
                disabled={loading || resolved || !assigned}
              >
                {prepareOfferLabel}
              </CheckBox>
            </Check>
          </CheckContainer>
        </Column>
      </Row>
    </DataBlock>
    <DataBlock label={"Internal information"}>
      <Row margin>
        <Column size={2 / 3} m={1}>
          {resolved || !assigned ? (
            <Info label={internalRemarksLabel}>
              {door.internalRemarks || ''}
            </Info>
          ) : (
            <Textarea
              value={door.internalRemarks}
              onChange={onChange('internalRemarks')}
              error={door.error.internalRemarks}
              size={3}
              disabled={loading}
            >
              {internalRemarksLabel}
            </Textarea>
          )}
        </Column>
      </Row>
      {!resolved && assigned && (
        <Row>
          <Column>
            <Right>
              <ButtonContainer>
                <Button
                  size="big"
                  theme="orange"
                  loading={
                    loading ||
                    !!updatingFaults.length ||
                    !!updatingMaterials.length ||
                    !!updatingRepairs.length
                  }
                  onClick={onSave}
                >
                  {buttonLabel}
                </Button>
              </ButtonContainer>
            </Right>
          </Column>
        </Row>
      )}
    </DataBlock>
  </Container>
);

Workinglog.propTypes = {
  doorSerialLabel: PropTypes.string,
  electronicsSerialLabel: PropTypes.string,
  motorSerialLabel: PropTypes.string,
  technicianDescriptionLabel: PropTypes.string,
  repairDescriptionLabel: PropTypes.string,
  replacedMaterialLabel: PropTypes.string,
  findingsRemarksLabel: PropTypes.string,
  customerRemarksLabel: PropTypes.string,
  internalRemarksLabel: PropTypes.string,
  replacementNeededLabel: PropTypes.string,
  doorsWorkingLabel: PropTypes.string,
  prepareOfferLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  manufacturerLabel: PropTypes.string,
  doorVersionLabel: PropTypes.string,
  doorTypeLabel: PropTypes.string,
  noMaterialsLabel: PropTypes.string,
  indoorLocationLabel: PropTypes.string,
  buildingFloorLabel: PropTypes.string,
  redundancyLabel: PropTypes.string,
  noSuggestionLabel: PropTypes.string,

  loading: PropTypes.bool,
  serviceItemUrl: PropTypes.string,
  assigned: PropTypes.bool,
  resolved: PropTypes.bool,
  doors: PropTypes.array,
  door: PropTypes.object,

  faults: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  selectedFaults: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  repairs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  selectedRepairs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  selectedMaterials: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      quantity: PropTypes.any,
      guarantee: PropTypes.bool,
    })
  ),
  validWarranties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  manufacturers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  doorSearchable: PropTypes.bool,
  searchingForDoor: PropTypes.bool,
  searchedDoors: PropTypes.array,

  updatingFaults: PropTypes.array,
  updatingRepairs: PropTypes.array,
  updatingMaterials: PropTypes.array,

  scan: PropTypes.bool,
  toggleScan: PropTypes.func,
  scanLabel: PropTypes.string,

  onChange: PropTypes.func,
  addFault: PropTypes.func,
  changeFault: PropTypes.func,
  updateFault: PropTypes.func,
  removeFault: PropTypes.func,
  addRepair: PropTypes.func,
  changeRepair: PropTypes.func,
  updateRepair: PropTypes.func,
  removeRepair: PropTypes.func,
  addMaterial: PropTypes.func,
  changeMaterial: PropTypes.func,
  updateMaterial: PropTypes.func,
  removeMaterial: PropTypes.func,
  onDoor: PropTypes.func,
  onSearchedDoor: PropTypes.func,
  onSave: PropTypes.func,
};

export default Workinglog;
