import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const assignMaintenanceApi = (maintenanceID, assigneeId, query = {rel: '*'}) =>
  f3tch(`/maintenances/${maintenanceID}/assign`)
    .profile(profile())
    .query(query)
    .body({assigneeId})
    .put();
export default assignMaintenanceApi;
