import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Container = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 25px;
  right: 10px;
  background: ${white};
  display: flex;
  align-items: center;
`;

export default Container;
