import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Info} from 'doorson-ui';

// Types
import {date} from '../../../types';

const MaintenanceContract = ({
  validFromLabel,
  validTillLabel,
  periodStartLabel,
  periodEndLabel,

  contract,
}) => (
  <Fragment>
    <Row margin>
      <Column size={2 / 6}>
        <Info label={validFromLabel}>{date(contract.validFrom).format()}</Info>
      </Column>
      <Column size={2 / 6}>
        <Info label={validTillLabel}>
          {!!contract.validTill
            ? date(contract.validTill).format()
            : 'Infinite'}
        </Info>
      </Column>
    </Row>
    <Row>
      <Column size={2 / 6}>
        <Info label={periodStartLabel}>
          {date(contract.periodStart).format()}
        </Info>
      </Column>
      <Column size={2 / 6}>
        <Info label={periodEndLabel}>{date(contract.periodEnd).format()}</Info>
      </Column>
    </Row>
  </Fragment>
);

MaintenanceContract.propTypes = {
  validFromLabel: PropTypes.node,
  validTillLabel: PropTypes.node,
  periodStartLabel: PropTypes.node,
  periodEndLabel: PropTypes.node,

  contract: PropTypes.object,
};

export default MaintenanceContract;
