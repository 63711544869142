import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Content from './components/Content';
import Icon from './components/Icon';

const NewFolderButton = ({children, onClick}) => (
  <Container onClick={onClick}>
    <Content>
      <Icon className="mdi mdi-plus" />
      <br />
      {children}
    </Content>
  </Container>
);

NewFolderButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default NewFolderButton;
