import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// MaterialsAdminPage
import MaterialsAdminPage from '../MaterialsAdminPage/MaterialsAdminPage';
import routeMaterialsAdminPage from '../MaterialsAdminPage/route';

// Consts
import page from './page';

const MaterialsAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeMaterialsAdminPage()}
        component={MaterialsAdminPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default MaterialsAdminPages;
