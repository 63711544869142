import React from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from 'doorson-ui';
import Container from './components/Container';
import Check from './components/Check';

const DisplaySelection = ({visible, display, options, onChange}) => (
  <Container visible={visible}>
    {options.map((option) => (
      <Check key={option.value}>
        <CheckBox
          selected={display.includes(option.value)}
          onSelect={onChange(option.value)}
        >
          {option.label}
        </CheckBox>
      </Check>
    ))}
  </Container>
);

DisplaySelection.propTypes = {
  visible: PropTypes.bool,
  display: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default DisplaySelection;
