import styled from 'styled-components';

const MainChart = styled.div`
  position: relative;
  float: left;
  width: 100%;
  height: 250px;
`;

export default MainChart;
