import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isEmail} from 'validator';
import {getTime, isBefore, parse, startOfDay} from 'date-fns';

// Api
import resolveApi from '../../api/resolve.api.intervention';
import previewApi from '../../api/preview.api.intervention';
import updateApi from '../../api/update.api.intervention';
import downloadFileApi from '../../../file/api/download.api.file';

// Actions
import {updateIntervention as updateInterventionAct} from '../../redux/actions';

// Alertify
import {alertify, colors} from 'doorson-ui';

// Components
import InterventionConfirmation from '../../components/InterventionConfirmation/InterventionConfirmation';
import TechnicianInfo from '../../components/TechnicianInfo/TechnicianInfo';
import CustomerInfo from '../../components/CustomerInfo/CustomerInfo';
import PdfInfo from '../../components/PdfInfo/PdfInfo';
import DocumentSignature from '../../components/DocumentSignature/DocumentSignature';
import Resolve from '../../components/Resolve/Resolve';
import ContractPreviewModal from '../../../file/components/ContractPreviewModal/ContractPreviewModal';

// Containers
import CloseInterventionContainer from '../CloseInterventionContainer/CloseInterventionContainer';
import SendContractContainer from '../SendContractContainer/SendContractContainer';

// Libs
import isResolved from '../../lib/isResolved.lib.intervention';
import isClosed from '../../lib/isClosed.lib.intervention';
import isAssigned from '../../lib/isAssigned.lib.intervention';
import toBase64 from '../../../file/lib/toBase64.lib.file';
import download from '../../../file/lib/download.lib.file';
import getTodaysVehicleByDriver from '../../../vehicle/lib/getTodaysVehicleByDriver.lib.vehicle';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SERVICE_TECHNICIAN, SYSTEM,} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

// Types
import {apiDate as apiDateType, dateTime as dateTimeType} from '../../../types';
import styled from "styled-components";
import {minutesToString, stringToMinutes} from "../../../types/types/helpers/duration.converter";

const {red, orange} = colors;

const ContractReceiver = styled.span`
  color: ${props => !!props.sent ? 'inherit' : (props.sent === null ? orange : red)};
`;

class InterventionConfirmationContainer extends Component {
  static propTypes = {
    intervention: PropTypes.object,
    user: PropTypes.object,
    transportLocations: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    init: false,
    loading: false,
    technicianInfoTouched: false,
    error: {},
    dateResolved: apiDateType(new Date()).format(),
    loggedTime: '00:00',
    vehicleId: '',
    transportLocationId: '',
    transportDestination: '',
    signatoryFirstName: '',
    signatoryLastName: '',
    technicianSignature: null,
    customerSignature: null,
    visibleSignature: null,
    resolveVisible: false,
    technicianEmail: '',
    customerEmail: '',
    code: '',
    previewVisible: false,
    loadingPreview: false,
    preview: null,
    previewMode: false,
    closingVisible: false,
    downloadingContract: false,
    sendContractVisible: false,
  };

  componentDidMount() {
    this.mounted = true;
    const {
      intervention: {
        transportLocation,
        loggedTime,
        vehicleId,
        transportDestination,
        signatoryFirstName,
        signatoryLastName,
        email,
      },
    } = this.props;

    this.setState({
      init: true,
      loggedTime: minutesToString(loggedTime),
      transportLocationId: !!transportLocation ? transportLocation.id : '',
      vehicleId: vehicleId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
      customerEmail: email || '',
    });
  }

  interventionUpdated = false;

  componentWillUnmount() {
    this.mounted = false;
  }

  syncIntervention = ({...data} = {}) => {
    const {intervention, dispatch} = this.props;
    dispatch(updateInterventionAct({...intervention, ...data}));
  };

  onChange = (key, sync = true) => (value) => {
    const {loading, error, preview, technicianInfoTouched} = this.state;
    if (loading) return;
    const data = {
      [key]: value,
      preview: sync ? null : preview,
      error: Object.entries(error).reduce(
        (combined, [errorKey, errorValue]) =>
          key === errorKey ? combined : {...combined, [errorKey]: errorValue},
        {}
      ),
      technicianInfoTouched: technicianInfoTouched || key === 'loggedTime' || key === 'vehicleId' || key === 'transportLocationId' || key === 'transportDestination',
    };

    this.setState(data);
    if (!sync) return;
    this.interventionUpdated = true;
    this.syncIntervention(data);
  };

  onChangeEmail = (key) => this.onChange(key, false);

  onSign = (box) => (value) => {
    const {loading} = this.state;
    if (loading) return;
    this.setState({[box]: value, visibleSignature: false});
  };

  openSignatureBox = (box) => () => {
    if (this.state.loading) return;
    this.setState({visibleSignature: box});
  };

  closeSignatureBox = () => {
    this.setState({visibleSignature: null});
  };

  transportLocations = () =>
    [...this.props.transportLocations].map(({id, name}) => ({
      value: id,
      label: name,
    }));

  contractReceivers = () =>
    [...this.props.intervention.contractReceivers]
      .filter(({type}) => type === 'email')
      .map(({address, sent}) => <ContractReceiver
        title={!!sent ? 'Sent' : (sent === null ? 'Sending...' : 'Failed to sent')}
        sent={sent}>{address}</ContractReceiver>);

  goToInterventionPreview = () => {
    // TODO: I18n
    const {loading, technicianEmail, customerEmail} = this.state;

    if (loading) return;

    const error = {};
    if (!!technicianEmail.trim().length && !isEmail(technicianEmail))
      error.technicianEmail = 'Insert valid email';
    if (!this.isHistoric() && !isEmail(customerEmail))
      error.customerEmail = 'Insert valid email';
    if (Object.keys(error).length) return this.setState({error});

    this.setState({previewMode: true});
  };

  showClosingModal = () => {
    if (this.state.loading) return;
    this.setState({closingVisible: true});
  };

  hideClosingModal = () => {
    if (this.state.loading) return;
    this.setState({closingVisible: false});
  };

  showSendContractModal = () => {
    if (this.state.loading) return;
    this.setState({sendContractVisible: true});
  };

  hideSendContractModal = () => {
    if (this.state.loading) return;
    this.setState({sendContractVisible: false});
  };

  onSendContract = (intervention) => {
    this.setState({sendContractVisible: false});
    this.syncIntervention(intervention);
  };

  resolve = async () => {
    // TODO: I18n
    const {intervention} = this.props;
    const {
      loading,
      dateResolved: rawDateResolved,
      vehicleId,
      transportLocationId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
      technicianSignature,
      customerSignature,
      technicianEmail,
      customerEmail,
      code,
    } = this.state;

    const loggedTime = stringToMinutes(this.state.loggedTime);

    const isHistoric = this.isHistoric();

    const dateResolved = isHistoric
      ? getTime(parse(rawDateResolved, 'yyyy-MM-dd', new Date()))
      : getTime(new Date());

    if (loading) return;

    this.setState({loading: true});

    const companyContractReceivers = !technicianEmail ? [] : [technicianEmail].filter((email) =>
      isEmail(email)
    );
    const customerContractReceivers = !customerEmail ? [] : [customerEmail].filter((email) =>
      isEmail(email)
    );

    try {
      const data = {
        technicianSignature,
        customerSignature,
        companyContractReceivers,
        customerContractReceivers,
        dateResolved,
        loggedTime,
      };

      if (code.trim().length) data.code = code;

      if (this.interventionUpdated) {
        const updatedIntervention = await updateApi(intervention.id, {
          loggedTime,
          vehicleId: vehicleId || null,
          transportLocationId: transportLocationId || null,
          transportDestination,
          signatoryFirstName,
          signatoryLastName,
          priority: intervention.priority,
          version: intervention.version,
        });
        this.setState({technicianInfoTouched: false})
        this.syncIntervention(updatedIntervention);
        this.interventionUpdated = false;
      }

      const newIntervention = await resolveApi(intervention.id, data);
      this.setState({loading: false, resolveVisible: false});
      this.syncIntervention(newIntervention);
      alertify.success('Intervention resolved. Don\'t forget to impress your coworkers with some door pictures.');
    } catch (error) {
      alertify.error('Something went wrong. Try again.');
      this.setState({loading: false});
    }
  };

  onClose = async (intervention) => {
    this.syncIntervention(intervention);
    this.setState({closingVisible: false});
  };

  closeResolve = () => {
    if (this.state.loading) return;
    this.setState({resolveVisible: false});
  };

  combinedIntervention = () => {
    const {intervention} = this.props;
    const {
      loggedTime,
      vehicleId,
      transportLocationId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
    } = this.state;
    return {
      ...intervention,
      vehicleId,
      vehicle: this.vehicle([...this.props.vehicles].find(v => v.id === vehicleId)),
      transportLocationId,
      loggedTime: stringToMinutes(loggedTime),
      transportLocation:
        [...this.props.transportLocations].find(
          (tl) => tl.id === transportLocationId
        ) || null,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
    };
  };

  downloadPdf = async () => {
    // TODO: I18n
    const {intervention} = this.props;
    const {downloadingContract} = this.state;

    if (downloadingContract || !intervention.contract) return;

    this.setState({downloadingContract: true});

    try {
      const file = await downloadFileApi(intervention.contract.id, 'BLOB');
      download(file, intervention.contract.originalName);
      this.setState({downloadingContract: false});
    } catch (error) {
      this.setState({downloadingContract: false});
      alertify.error('Contract could not be downloaded');
    }
  };

  onSave = async (showPreview = false) => {
    // TODO: I18n
    const {intervention} = this.props;
    const {
      loadingPreview,
      preview,
      transportLocationId,
      vehicleId,
      loggedTime,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
    } = this.state;

    const error = {};

    if (stringToMinutes(loggedTime) === null) {
      error.loggedTime = 'Should be in format hh:mm';
    }
    if (showPreview) {
      if (!vehicleId || !vehicleId.trim().length)
        error.vehicleId = 'Should not be blank';
      if (!transportLocationId || !transportLocationId.trim().length)
        error.transportLocationId = 'Should not be blank';
      if (!transportDestination || !transportDestination.trim().length)
        error.transportDestination = 'Should not be blank';
      if (!signatoryFirstName || !signatoryFirstName.trim().length)
        error.signatoryFirstName = 'Should not be blank';
      if (!signatoryLastName || !signatoryLastName.trim().length)
        error.signatoryLastName = 'Should not be blank';

      if (Object.keys(error).length) return this.setState({error});

      if (loadingPreview || !!preview)
        return this.setState({previewVisible: true});

      this.setState({loadingPreview: true, previewVisible: true});
    } else {
      if (Object.keys(error).length) return this.setState({error});
    }

    try {
      const data = {
        loggedTime: stringToMinutes(loggedTime),
        transportLocationId: transportLocationId || null,
        vehicleId: vehicleId || null,
        transportDestination,
        signatoryFirstName,
        signatoryLastName,
        priority: intervention.priority,
        version: intervention.version,
      };

      const newIntervention = await updateApi(intervention.id, data);
      this.setState({technicianInfoTouched: false})
      this.syncIntervention(newIntervention);
      this.interventionUpdated = false;
      if (!showPreview) {
        return;
      }
      const pdfBlob = await previewApi(intervention.id);
      const pdf64 = await toBase64(pdfBlob);
      const pdf = pdf64.startsWith('data:application/octet-stream')
        ? pdf64.replace('data:application/octet-stream', 'data:application/pdf')
        : pdf64;
      this.setState({loadingPreview: false, preview: pdf});
    } catch (error) {
      alertify.error(showPreview ? 'Could not generate preview report. Try again' : 'Could not save intervention. Try again');
      this.setState({previewVisible: false, loadingPreview: false});
    }
  };

  closePreview = () => this.setState({previewVisible: false});

  isHistoric = (date = this.state.dateResolved) =>
    isBefore(parse(date, 'yyyy-MM-dd', new Date()), startOfDay(new Date()));

  onAction = (action) => () => {
    if (this.state.loading) return;
    switch (action) {
      case 'resolve':
        return this.beforeResolve();
      case 'close':
        return this.showClosingModal();
      default:
        return null;
    }
  };

  beforeResolve = () => {
    // TODO: I18n
    const {
      loading,
      loggedTime,
      transportLocationId,
      vehicleId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
      technicianSignature,
    } = this.state;

    if (loading) return;

    const error = {};

    if (!stringToMinutes(loggedTime))
      error.loggedTime = 'Should be set (in format hh:mm)';
    if (!vehicleId || !vehicleId.trim().length)
      error.vehicleId = 'Should not be blank';
    if (!transportLocationId || !transportLocationId.trim().length)
      error.transportLocationId = 'Should not be blank';
    if (!transportDestination || !transportDestination.trim().length)
      error.transportDestination = 'Should not be blank';
    if (!signatoryFirstName || !signatoryFirstName.trim().length)
      error.signatoryFirstName = 'Should not be blank';
    if (!signatoryLastName || !signatoryLastName.trim().length)
      error.signatoryLastName = 'Should not be blank';

    if (Object.keys(error).length) {
      this.setState({error});
      return alertify.warning(
        'Make sure you filled out all the required fields'
      );
    }

    if (!technicianSignature) return alertify.warning('Sign the contract');

    this.setState({resolveVisible: true, previewMode: false});
  };

  vehicle = (vehicle) => !vehicle ? '' : `${vehicle.label} ${vehicle.make} ${vehicle.model} ${vehicle.licensePlate}`

  vehicles = () => {
    const {vehicles, user} = this.props;
    const currentVehicle = getTodaysVehicleByDriver({driverId: user.id, vehicles})

    return [currentVehicle && {
      value: currentVehicle.id,
      label: this.vehicle(currentVehicle) + ' (Current)'
    }, ...[...this.props.vehicles].filter(w => w.id !== (currentVehicle || {}).id).map(w => ({
      value: w.id,
      label: this.vehicle(w)
    }))].filter(v => !!v)
  }

  render() {
    const {intervention, user} = this.props;
    const {
      init,
      loading,
      error,
      dateResolved,
      loggedTime,
      vehicleId,
      transportLocationId,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
      technicianSignature,
      customerSignature,
      code,
      visibleSignature,
      resolveVisible,
      technicianEmail,
      customerEmail,
      loadingPreview,
      previewVisible,
      preview,
      previewMode,
      closingVisible,
      sendContractVisible,
      technicianInfoTouched,
    } = this.state;

    const isHistoric = this.isHistoric();

    const {customerBranch} = intervention;
    const suggestionEmails = ((customerBranch||{}).emails || []).map(email => ({value: email, label: email}))
    const interventionBranchCity = ((customerBranch || {}).branchLocation || {}).city;

    return !init ? null : (
      <InterventionConfirmation>
        <TechnicianInfo
          techniciansLabel="Technicians info"
          resolvedByLabel="Service resolved by"
          completedAtLabel="Service completed at"
          loggedTimeLabel="Logged time (hh:mm)"
          transportLabel="Transport"
          vehicleLabel="Vehicle"
          signatureLabel="Signature - Technician"
          cityLabel="City"
          toLabel="To"
          destinationLabel="Destination"
          signLabel="Sign Document"
          signedLabel="Document Signed"
          notYetResolvedLabel="Service is not resolved yet"
          loading={loading}
          assigned={isAssigned(user, intervention)}
          resolved={isResolved(intervention)}
          error={error}
          resolvedBy={intervention.resolvedBy}
          dateCompleted={
            !!intervention.dateResolved
              ? dateTimeType(intervention.dateResolved).format()
              : null
          }
          loggedTime={loggedTime}
          vehicleId={vehicleId}
          vehicle={this.vehicle([...this.props.vehicles].find(v => v.id === vehicleId))}
          vehicles={this.vehicles()}
          transportLocationId={transportLocationId}
          transportLocation={
            !!intervention.transportLocation
              ? intervention.transportLocation.name
              : ''
          }
          transportDestination={transportDestination}
          transportDestinations={interventionBranchCity ? [{
            value: interventionBranchCity,
            label: interventionBranchCity
          }] : []}
          transportLocations={this.transportLocations()}
          signed={!!intervention.dateResolved || !!technicianSignature}
          onChange={this.onChange}
          onSign={this.openSignatureBox('technicianSignature')}
          saved={!technicianInfoTouched}
          onSave={() => this.onSave(false)}
          saveLabel="Save technicians info"
          savedLabel="Saved"
        />

        <CustomerInfo
          customerInfoLabel="Customer Info"
          registrationNumberLabel="Registration Number"
          companyNameLabel="Company name"
          addressLabel="Address"
          zipLabel="ZIP Code"
          cityLabel="City"
          branchLabel="Branch name and address"
          phoneLabel="Telephone"
          emailLabel="Email"
          customerLabel="Customer"
          firstNameLabel="First Name"
          lastNameLabel="Last Name"
          signatureLabel="Signature - customer"
          signLabel="Sign Document"
          signedLabel="Document Signed"
          receiversLabel="Contract sent to"
          loading={loading}
          assigned={isAssigned(user, intervention)}
          resolved={isResolved(intervention)}
          error={error}
          customer={intervention.customer}
          branchName={intervention.customerBranch.branchName}
          branchLocation={intervention.customerBranch.branchLocation}
          email={intervention.email}
          phone={intervention.phone}
          signatoryFirstName={signatoryFirstName}
          signatoryLastName={signatoryLastName}
          signed={!!intervention.dateResolved || !!customerSignature}
          contractReceivers={this.contractReceivers()}
          onChange={this.onChange}
          onSign={this.openSignatureBox('customerSignature')}
        />

        <PdfInfo
          pdfLabel="Pdf Document"
          resolveLabel="Resolve"
          closeLabel="Close"
          previewLabel="Preview PDF report"
          pdfWillBeGeneratedLabel="PDF will be generated"
          sendContractLabel="Send Contract"
          pdf={intervention.contract}
          loading={loading}
          assigned={isAssigned(user, intervention)}
          resolved={isResolved(intervention)}
          closed={isClosed(intervention)}
          isCST={hasPermission({
            user,
            roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
          })}
          canClose={hasPermission({
            user,
            roles: [CHIEF_SERVICE_TECHNICIAN, SERVICE_TECHNICIAN, SYSTEM],
          })}
          onDownload={this.downloadPdf}
          onAction={this.onAction}
          onPreview={() => this.onSave(true)}
          onContract={this.showSendContractModal}
        />

        <DocumentSignature
          visible={visibleSignature === 'technicianSignature'}
          title="Technician Signature"
          confirmLabel="Confirm"
          clearLabel="Clear"
          onSign={this.onSign('technicianSignature')}
          onClear={() => this.onSign('technicianSignature')(null)}
          onClose={this.closeSignatureBox}
        />

        <DocumentSignature
          visible={visibleSignature === 'customerSignature'}
          title="Customer Signature"
          confirmLabel="Confirm"
          clearLabel="Clear"
          onSign={this.onSign('customerSignature')}
          onClear={() => this.onSign('customerSignature')(null)}
          onClose={this.closeSignatureBox}
        />

        <Resolve
          loading={loading}
          visible={resolveVisible}
          isHistoric={isHistoric}
          previewMode={previewMode}
          error={error}
          title="Resolve incident"
          technicianEmailLabel="Technician Email"
          customerEmailLabel="Customer Email"
          resolveLabel="Resolve"
          noSignatureLabel="The customer didn't sign the contract"
          resolvedLabel="Only for historic data! Set when the incident was resolved."
          codeLabel="Intervention Code"
          intervention={this.combinedIntervention()}
          dateResolved={dateResolved}
          technicianEmail={technicianEmail}
          customerEmail={customerEmail}
          suggestionEmails={suggestionEmails}
          code={code}
          signed={!!customerSignature}
          onChange={this.onChangeEmail}
          onClose={this.closeResolve}
          onPreview={this.goToInterventionPreview}
          onResolve={this.resolve}
        >
          Specify to whom the contract should be sent to
        </Resolve>

        <CloseInterventionContainer
          visible={closingVisible}
          intervention={intervention}
          onClose={this.hideClosingModal}
          onDone={this.onClose}
        />

        <ContractPreviewModal
          loading={loadingPreview}
          visible={previewVisible}
          title="Contract Preview"
          preview={preview}
          onClose={this.closePreview}
        />

        <SendContractContainer
          visible={sendContractVisible}
          intervention={intervention}
          onFinish={this.onSendContract}
          onClose={this.hideSendContractModal}
        />
      </InterventionConfirmation>
    );
  }
}

export default connect((state) => ({
  intervention: state.intervention.intervention,
  user: state.auth.user,
  vehicles: state.vehicle.vehicles,
  transportLocations: state.transportLocation.transportLocations,
}))(InterventionConfirmationContainer);
