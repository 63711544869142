import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import InventoryOptionsContainer from './components/InventoryOptionsContainer';
import InventoryOptionContainer from './components/InventoryOptionContainer';
import InventoryOptionContent from "../InventoryOptionContent/InventoryOptionContent";

const InventoryOption = ({
                           items,
                           onItem,
                         }) => (
  <Container>
    <InventoryOptionsContainer>
      {items.map((i) => (
        <InventoryOptionContainer key={i.id}>
          <InventoryOptionContent
            title={i.title}
            image={i.image}
            onClick={onItem(i)}
          />
        </InventoryOptionContainer>
      ))}
    </InventoryOptionsContainer>
  </Container>
);

InventoryOption.propTypes = {
  items: PropTypes.array,
  onItem: PropTypes.func,
};

export default InventoryOption;
