import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.manufacturer';
import deleteApi from '../../api/delete.api.manufacturer';

// Components
import AdminManufacturers from '../../components/AdminManufacturers/AdminManufacturers';
import UpdateManufacturerModal from '../../components/UpdateManufacturerModal/UpdateManufacturerModal';
import DeleteManufacturerModal from '../../components/DeleteManufacturerModal/DeleteManufacturerModal';

// Lib
import sortByString from '../../../lib/sortByString';

// Routes
import manufacturerRoute from '../../pages/ManufacturerAdminPage/route';

class ManufacturersContainer extends Component {
  static propTypes = {
    manufacturers: PropTypes.array,
    countries: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    loading: false,
    updateID: null,
    name: '',
    country: '',
    version: 0,
    removeID: null,
    sort: {key: 'name', direction: 'asc'},
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'name',
      label: 'Name',
      span: 2,
      sortable: true,
    },
    {
      key: 'country',
      label: 'Country',
      span: 2,
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  showUpdate = (manufacturer) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: manufacturer.id,
      name: manufacturer.name,
      country: manufacturer.country,
      version: manufacturer.version,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  onManufacturer = (manufacturer) => () =>
    this.props.history.push(manufacturerRoute(manufacturer.id));

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSort = (sort) => this.setState({sort});

  countries = () =>
    [...this.props.countries].map(({code, name}) => ({
      value: code,
      label: name,
    }));

  update = async () => {
    const {loading, updateID, name, country, version} = this.state;
    if (loading || !updateID) return;

    if (!name.trim().length) return alertify.warning('Insert the name');
    if (!country.trim().length) return alertify.warning('Insert the country');

    this.setState({loading: true});

    try {
      const manufacturer = await updateApi(updateID, {name, country, version});
      const {dispatch, manufacturers} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          manufacturers: [...manufacturers].map((r) =>
            r.id === manufacturer.id ? manufacturer : r
          ),
        })
      );
    } catch (error) {
      alertify.error('Manufacturer could not be updated');
      this.setState({loading: false});
    }
  };

  remove = async () => {
    const {loading, removeID} = this.state;
    if (loading || !removeID) return;

    this.setState({loading: true});

    try {
      await deleteApi(removeID);
      const {dispatch, manufacturers} = this.props;
      this.setState({loading: false, removeID: null});
      dispatch(
        setAct({
          manufacturers: [...manufacturers].filter((r) => r.id !== removeID),
        })
      );
    } catch (error) {
      alertify.error('Manufacturer could not be removed');
      this.setState({loading: false});
    }
  };

  data = () => {
    const {manufacturers} = this.props;
    const {sort} = this.state;
    return !!sort ? [...manufacturers].sort(sortByString(sort)) : manufacturers;
  };

  render() {
    const {loading, sort, updateID, name, country, removeID} = this.state;
    return (
      <Fragment>
        <AdminManufacturers
          columns={this.columns()}
          sort={sort}
          manufacturers={this.data()}
          onManufacturer={this.onManufacturer}
          onUpdate={this.showUpdate}
          onDelete={this.showDelete}
          onSort={this.onSort}
        />
        <UpdateManufacturerModal
          title="Update Manufacturer"
          nameLabel="Name"
          countryLabel="Country"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          name={name}
          country={country}
          countries={this.countries()}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
        <DeleteManufacturerModal
          title="Delete Manufacturer"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this manufacturer?
        </DeleteManufacturerModal>
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({
    ...state.manufacturer,
    countries: state.country.countries,
  }))(ManufacturersContainer)
);
