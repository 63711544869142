import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateInterventionDoorFaultApi = (interventionDoorID, faultID, fault) =>
  f3tch(`/intervention-doors/${interventionDoorID}/faults/${faultID}`)
    .profile(profile())
    .body(fault)
    .put();
export default updateInterventionDoorFaultApi;
