import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import AssignMaintenanceUsersContainer from '../../../user/containers/AssignMaintenanceUsersContainer/AssignMaintenanceUsersContainer';

// TODO: I18n
const MaintenanceAssignPage = ({match}) => (
  <SubPage page={match.path}>
    <AssignMaintenanceUsersContainer />
  </SubPage>
);

MaintenanceAssignPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(MaintenanceAssignPage);
