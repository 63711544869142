import styled, {css} from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const Container = styled.div`
  width: ${({overlapping}) =>
    !!overlapping ? `calc(${100 / overlapping}% - 1px)` : '100%'};
  position: absolute;
  left: ${({overlapping, overlappingPosition}) =>
    (100 / overlapping) * overlappingPosition}%;
  background: ${grey};
  ${({top, height, selected}) => css`
    top: ${top}px;
    height: ${height}px;
    z-index: ${selected ? 5 : 1};
  `}

  ${({loading}) =>
    !loading
      ? ''
      : css`
          opacity: 0.7;
          pointer-events: none;
        `}
`;

export default Container;
