import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const getCustomerApi = (id, query = {}) =>
  f3tch(`/customers/${id}`)
    .profile(profile())
    .query(query)
    .get();
export default getCustomerApi;
