import f3tch from 'f3tch';

// Profiles
import signinProfile from '../../api/profiles/signin.profile.api';

const signIn = ({username, password}) =>
  f3tch('/oauth/token')
    .profile(signinProfile())
    .body(
      Object.entries({
        username,
        password,
        grant_type: 'password',
      })
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    )
    .post();
export default signIn;
