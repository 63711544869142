import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import createDoorApi from '../../api/create.api.door';
import deleteDoorApi from '../../api/delete.api.door';

// Alerts
import {alertify} from 'doorson-ui';

// Components
import NewDoorModal from '../../components/NewDoorModal/NewDoorModal';
import QrScanModal from "../../components/QrScanModal/QrScanModal";
import searchDoorApi from "../../../door/api/list.api.door";
import getByIdDoorApi from "../../../door/api/getByID.api.door";
import getLabelRefApi from "../../../labelRef/api/getById.api.labelRef";
import parseServerFault from "../../../api/lib/parseServerFault.lib.api";

class NewDoorContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    customerBranchId: PropTypes.string,
    afterComplete: PropTypes.func,
    onComplete: PropTypes.func,
    onClose: PropTypes.func,
    manufacturers: PropTypes.array,
    scanLoading: PropTypes.bool,
    onScan: PropTypes.func,
    scanDisabled: PropTypes.bool,
    onScanClose: PropTypes.func,
    scanVisible: PropTypes.bool,
    scanLabel: PropTypes.string,
    toggleScan: PropTypes.func,
  };

  static defaultProps = {
    afterComplete: async () => {
    },
  };

  static INITIAL_STATE = {
    existingDoor: null,
    scan: false,
    scanLoading: false,
    doorSerial: '',
    electronicsSerial: '',
    motorSerial: '',
    indoorLocation: '',
    buildingFloor: '',
    redundancy: false,
    manufacturer: '',
    productId: '',
    productTypeId: '',
    leafInfo: '',
    doorSuggestions: [],
    error: {},
  };

  state = {
    loading: false,
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({...this.constructor.INITIAL_STATE});
  };

  searchDoor = async (searchKey, search) => {
    if(search.trim().length <= 3) {
      return;
    }
    this.setState({searchingForDoor: true});
    try {
      const doorSuggestions = await searchDoorApi({
        filter: encodeURIComponent(`${searchKey}:LIKEIC:%${search}%`),
      });
      this.setState({
        doorSuggestions,
        searchingForDoor: false,
      });
    } catch (error) {
      this.setState({searchingForDoor: false});
      // Do nothing for now
    }
  }

  onChange = (key) => (val) => {
    const {loading, productId, productTypeId, error, existingDoor} = this.state;
    if (loading) return;

    if(key === 'doorSerial') {
      if (existingDoor) this.onExistingDoor(null);
      this.searchDoor('doorSerial', val);
    }


    this.setState({
      productId: key === 'manufacturer' ? '' : productId,
      productTypeId: ['manufacturer', 'productId'].includes(key)
        ? ''
        : productTypeId,
      [key]: val,
      error: Object.entries(error).reduce(
        (combined, [errorKey, value]) =>
          key === errorKey ? combined : {...combined, [errorKey]: value},
        {}
      ),
    });
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  manufacturers = () =>
    [...this.props.manufacturers].map(({id, name}) => ({
      value: id,
      label: name,
    }));

  products = () => {
    // TODO: I18n
    const {manufacturers} = this.props;
    const {manufacturer: currentManufacturer} = this.state;
    const empty = [{value: '', label: 'Select a manufacturer first'}];
    const manufacturer = [...manufacturers].find(
      ({id}) => id === currentManufacturer
    );
    return !manufacturer
      ? empty
      : [...manufacturer.products].map(({id, name}) => ({
        value: id,
        label: name,
      }));
  };

  productTypes = () => {
    // TODO: I18n
    const {manufacturers} = this.props;
    const {
      manufacturer: currentManufacturer,
      productId: currentProductId,
    } = this.state;
    const empty = [{value: '', label: 'Select a product first'}];
    const manufacturer = [...manufacturers].find(
      ({id}) => id === currentManufacturer
    );
    if (!manufacturer) return empty;
    const product = [...manufacturer.products].find(
      ({id}) => id === currentProductId
    );
    return !product
      ? empty
      : !!product.productTypes.length
        ? [...product.productTypes].map(({id, name}) => ({
          value: id,
          label: name,
        }))
        : [{value: '', label: 'Various types'}];
  };

  create = async () => {
    const {visible, customerBranchId, onComplete} = this.props;
    const {
      loading,
      doorSerial,
      electronicsSerial,
      motorSerial,
      indoorLocation,
      buildingFloor,
      redundancy,
      manufacturer,
      productId,
      productTypeId,
      leafInfo,
      existingDoor,
    } = this.state;

    if (loading || !visible || !customerBranchId) return;

    if(existingDoor) {
      this.setState({loading: true});
      try {
        await this.afterComplete(existingDoor);
        onComplete(existingDoor);
      } catch (error) {
        alertify.error('Could not save. Try again.');
      }
      this.setState({loading: false});
      return;
    }

    const productTypes = this.productTypes();
    const error = {};

    if (!manufacturer) error.manufacturer = 'Select an option';
    if (!productId) error.productId = 'Select an option';
    if (
      !productId ||
      (!!productId &&
        !productTypeId &&
        !(productTypes.length === 1 && productTypes[0].value === ''))
    )
      error.productTypeId = 'Select an option';
    if (!doorSerial || !doorSerial.trim().length)
      error.doorSerial = 'Should not be empty';
    if (!electronicsSerial || !electronicsSerial.trim().length)
      error.electronicsSerial = 'Should not be empty';
    if (!motorSerial || !motorSerial.trim().length)
      error.motorSerial = 'Should not be empty';
    if (!indoorLocation || !indoorLocation.trim().length)
      error.indoorLocation = 'Should not be empty';
    if (!buildingFloor || !buildingFloor.trim().length)
      error.buildingFloor = 'Should not be empty';
    if (!leafInfo || !leafInfo.trim().length) {
      error.leafInfo = 'Should not be empty';
    } else if (leafInfo.length > 16) {
      error.leafInfo = 'Value is too long';
    }

    if (!!Object.keys(error).length) {
      this.setState({
        error,
      });
      return alertify.warning('Check the form');
    }

    const rawDoor = {
      doorSerial,
      electronicsSerial,
      motorSerial,
      indoorLocation,
      buildingFloor,
      redundancy,
      manufacturer,
      productId,
      productTypeId: productTypeId || null,
      leafInfo,
      customerBranchId,
    };

    this.setState({loading: true});

    try {
      const door = await createDoorApi(rawDoor);
      await this.afterComplete(door);
      onComplete(door);
    } catch (error) {
      alertify.error('Could not save. Try again.');
    }

    this.setState({loading: false});
  };

  afterComplete = async (door) => {
    const {afterComplete} = this.props;
    try {
      await afterComplete(door);
    } catch (error) {
      await deleteDoorApi(door.id);
      throw error;
    }
  };

  onExistingDoor = (door) => {
    this.setState({
      existingDoor: door,
      doorSerial: (door || {}).doorSerial || '',
      electronicsSerial: (door || {}).electronicsSerial || '',
      motorSerial: (door || {}).motorSerial || '',
      indoorLocation: (door || {}).indoorLocation || '',
      buildingFloor: (door || {}).buildingFloor || '',
      redundancy: (door || {}).redundancy || false,
      manufacturer: (((door || {}).product||{}).manufacturer||{}).id || '',
      productId: ((door || {}).product||{}).id || '',
      productTypeId: ((door || {}).productType||{}).id || '',
      leafInfo: (door || {}).leafInfo || '',
    })
  }

  doors = () => [...this.state.doorSuggestions].map(door => ({label: '(serial '+door.doorSerial+') '+door.indoorLocation+' '+door.buildingFloor, value: door}));

  scan = (scan) => this.setState({scan, scanLoading: scan && this.state.scanLoading})

  onScan = async (content) => {
    try {
      if (!content) return;
      this.setState({scanLoading: true})

      const friendlyId = new URL(content).pathname.split('/').filter(Boolean).pop()
      const labelRef = await getLabelRefApi(friendlyId);
      if (labelRef.context !== 'SERVICE_ITEM') {
        alertify.error('This QR code is not assigned to the door.');
      }
      const doorId = labelRef.refId;
      if (doorId) {
        const door = await getByIdDoorApi(doorId);
        if(door) {
          this.onExistingDoor(door);
          alertify.info('Door selected using QR code');
        }
      }
    } catch (error) {
      console.error(error);
      alertify.error('Unable to find door using QR code. ' + parseServerFault(error));
    }
    this.scan(false);
  }

  render() {
    const {visible} = this.props;
    const {
      existingDoor,
      loading,
      doorSerial,
      electronicsSerial,
      motorSerial,
      indoorLocation,
      buildingFloor,
      redundancy,
      manufacturer,
      productId,
      productTypeId,
      leafInfo,
      error,
      searchingForDoor,
      scanLoading, scanDisabled, scan
    } = this.state;
    return (
      <Fragment>
        <NewDoorModal
          title="Insert a new door"
          doorSerialLabel="Door serial number"
          electronicsSerialLabel="Electronic serial number"
          motorSerialLabel="Motor serial number"
          manufacturerLabel="Manufacturer"
          doorVersionLabel="Door version"
          doorTypeLabel="Door type"
          indoorLocationLabel="Door location"
          buildingFloorLabel="Floor"
          redundancyLabel="Redundancy"
          leafInfoLabel="Leaf info"
          saveLabel={existingDoor ? "Add existing door to maintenance" : "Create new door and add to maintenance"}
          doorSerial={doorSerial}
          electronicsSerial={electronicsSerial}
          motorSerial={motorSerial}
          indoorLocation={indoorLocation}
          buildingFloor={buildingFloor}
          redundancy={redundancy}
          manufacturer={manufacturer}
          productId={productId}
          productTypeId={productTypeId}
          leafInfo={leafInfo}
          loading={loading}
          visible={visible}
          error={error}
          manufacturers={this.manufacturers()}
          products={this.products()}
          productTypes={this.productTypes()}
          onChange={this.onChange}
          onComplete={this.create}
          onClose={this.onClose}
          scanLabel="Search using QR code"
          toggleScan={this.scan}
          scanDisabled={scanDisabled}
          onDoorSerial={this.onExistingDoor}
          noSuggestionLabel="No results found"
          searchingForDoor={searchingForDoor}
          doorSuggestions={this.doors()}
        />
        <QrScanModal
          loading={scanLoading}
          onScan={this.onScan}
          onClose={this.scan.bind(this, false)}
          visible={scan}/>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  manufacturers: state.manufacturer.manufacturers,
}))(NewDoorContainer);
