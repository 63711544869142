import React from 'react';
import PropTypes from 'prop-types';

// Components
import GoogleMap from 'google-map-react';
import Container from './components/Container';

const Maps = ({apiKey, center, zoom, markers, children, styles, onClick}) => (
  <Container>
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={center}
      onChildClick={onClick}
      bootstrapURLKeys={{key: apiKey}}
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        styles,
      }}
    >
      {markers}
    </GoogleMap>
    {children}
  </Container>
);

Maps.propTypes = {
  apiKey: PropTypes.string,
  center: PropTypes.array,
  zoom: PropTypes.number,
  markers: PropTypes.node,
  children: PropTypes.node,
  styles: PropTypes.array,
  onClick: PropTypes.func,
};

export default Maps;
