/**
 * @description :: Filling an array with numbers
 * @param {Number} start :: The starting number
 * @param {Function} end :: Function that determinates the end
 * @param {Number} step :: The incremental step
 * @param {Array} array :: The array to fill
 * @return {Array} :: Array of generated numbers
 */
const fill = (start, end, step = 1, array = []) => {
  if (start > end) return array;

  array = [...array, start];

  const value = start + step;

  // if ((step >= 0 && start >= end) || (step <= 0 && start <= end)) throw Error('You created an infinite loop')

  return fill(value, end, step, array);
};

export default fill;
