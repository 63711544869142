import React, {Component} from 'react';

// Api
import listApi from '../../api/list.api.materialTranslation';
import updateApi from '../../api/update.api.materialTranslation';

// Containers
import GeneralTranslationContainer from '../../../translation/containers/GeneralTranslationContainer/GeneralTranslationContainer';

class MaterialTranslationsContainer extends Component {
  render() {
    return (
      <GeneralTranslationContainer
        listApi={listApi}
        updateApi={updateApi}
        idAttribute="materialId"
        translationAttribute="name"
        displayAttribute="name"
      />
    );
  }
}

export default MaterialTranslationsContainer;
