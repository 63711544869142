import {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Paths
import assignRoute from '../../pages/MaintenanceAssignPage/route';
import itemsRoute from '../../pages/MaintenanceItemsPage/route';

class SpecifyMaintenanceSubpageContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
  };

  componentDidMount() {
    const {
      history,
      match: {
        params: {id},
      },
    } = this.props;
    const route = this.getRoute();
    history.replace(route(id));
  }

  getRoute = () => {
    const {maintenance} = this.props;
    if (!maintenance.assignees.length) return assignRoute;
    return itemsRoute;
  };

  render() {
    return null;
  }
}

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
}))(withRouter(SpecifyMaintenanceSubpageContainer));
