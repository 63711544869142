import isFunction from 'is-function';

import typeValidation from './validate.types';
import types from './types.types';
import required from './required.types';

const type = ({type = null, format = null, validate = null} = {}) => {
  if (!types.includes(type))
    throw new Error(
      `Invalid type '${type}'. Has to be one of: ${types.join(', ')}`
    );

  if (!isFunction(format))
    throw new Error('format argument has to be a function');

  if (!isFunction(validate))
    throw new Error('validate argument has to be a function');

  const fn = (...args) => ({
    required: () => required(type, ...args),
    format: () => format(...args),
    validate: () =>
      args.length > 0 && typeValidation(type, args[0]) && validate(...args),
  });
  fn.type = type;
  fn.format = format;
  fn.validate = validate;
  return fn;
};
export default type;
