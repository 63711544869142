import React, {Component} from 'react';

// Api
import listTranslationsApi from '../../api/list.api.translation';
import updateTranslationApi from '../../api/update.api.translation';

// Containers
import GeneralTranslationContainer from '../GeneralTranslationContainer/GeneralTranslationContainer';

class TranslationsContainer extends Component {
  render() {
    return (
      <GeneralTranslationContainer
        listApi={listTranslationsApi}
        updateApi={updateTranslationApi}
        idAttribute="translationKey"
        translationAttribute="content"
        displayAttribute="translationKey"
      />
    );
  }
}

export default TranslationsContainer;
