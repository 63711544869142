import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getTime} from 'date-fns';
import {withRouter} from 'react-router-dom';

// Assets
import ping from '../../assets/ping.mp3';

// Actions
import {set as setAct} from '../../redux/actions';

// Notification
import {notify, alertify} from 'doorson-ui';

// Constants
import API_LIMIT from '../../constants/apiLimit.constant.activity';

// Activity Actions
import executeActivity from '../../actions/execute.activity';

// Api
import getUnreadApi from '../../api/getUnread.api.activity';
import listApi from '../../api/list.api.activity';

// Libs
import parseMessage from '../../lib/parseMessage.lib.activity';

// Route
import activityRoute from '../../pages/ActivitiesPage/route';

class ActivitySyncContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
    activities: PropTypes.array,
    lastUpdate: PropTypes.number,
    more: PropTypes.bool,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  static TIMEOUT = 1000 * 5;

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  audio = new Audio(ping);

  init = () => {
    this.syncActivities();
  };

  syncActivities = async () => {
    const {dispatch} = this.props;
    if (!this.mounted) return;
    dispatch(setAct({loading: true}));
    await this.numberOfUnreadActivities();
    await this.listOfActivities();
    dispatch(setAct({loading: false, lastUpdate: getTime(new Date())}));
    setTimeout(this.syncActivities, this.constructor.TIMEOUT);
  };

  numberOfUnreadActivities = async () => {
    const {dispatch, user} = this.props;
    try {
      const {unread} = await getUnreadApi(user.id);
      dispatch(setAct({unreadActivities: unread}));
    } catch (error) {
      // Do nothing. Just don't fucking fail
    }
  };

  listOfActivities = async () => {
    const {user, lastUpdate, dispatch, more} = this.props;
    try {
      const query = {order: 'dc DESC', offset: 0, limit: API_LIMIT};
      if (!!lastUpdate) query.filter = `dc:GT:${lastUpdate}`;
      const newActivities = await listApi(user.id, query);
      if (!!lastUpdate) this.showNotification(newActivities);
      const activities = [...newActivities, ...this.props.activities];
      dispatch(
        setAct({
          activities,
          more: !lastUpdate ? newActivities.length === API_LIMIT : more,
        })
      );
    } catch (error) {
      // Do nothing. Just don't fucking fail
    }
  };

  showNotification = (activities) => {
    // TODO: I18n
    const {history} = this.props;
    if (!activities.length) return;
    this.playPing();
    if (activities.length > 1)
      return notify({
        icon: 'alert-circle-outline',
        children: 'You got multiple notifications',
        theme: 'orange',
        primaryAction: {
          label: 'Show',
          onClick: () => history.push(activityRoute()),
        },
      });
    const [activity] = activities;
    notify({
      img: activity.icon,
      icon: 'alert-circle-outline',
      children: (
        <span dangerouslySetInnerHTML={{__html: parseMessage(activity)}} />
      ),
      primaryAction: {
        label: 'Show',
        onClick: () => executeActivity(activity),
      },
    });
  };

  playPing = async () => {
    try {
      await this.audio.play();
    } catch (error) {
      alertify.warning('Enable sound autoplay');
    }
  };

  render() {
    return null;
  }
}

export default connect((state) => ({
  user: state.auth.user,
  activities: state.activity.activities,
  lastUpdate: state.activity.lastUpdate,
  more: state.activity.more,
}))(withRouter(ActivitySyncContainer));
