import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import ProfileContainer from '../../containers/ProfileContainer/ProfileContainer';

// Consts
import page from './page';

const ProfilePage = () => (
  <Page page={page}>
    <ProfileContainer />
  </Page>
);

export default ProfilePage;
