import PropTypes from 'prop-types';
import styled from 'styled-components';
import {transitions} from 'polished';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 50px;
  z-index: 10000000000000000;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  ${transitions('visibility .3s ease-in-out, opacity .3s ease-in-out')};
`;

Container.propTypes = {
  visible: PropTypes.bool,
};

export default Container;
