import styled from 'styled-components';
//Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const VehicleNo = styled.span`
  font-weight: bold;
  font-size: 25px;
  color: ${({hovered}) => (hovered ? orange : '')};
`;

export default VehicleNo;
