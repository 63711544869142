import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/externalJson.profile.api';

// Google Key
import apiKey from '../constants/googleApiKey.const.maps';

const geocoding = (address) =>
  f3tch('https://maps.googleapis.com/maps/api/geocode/json')
    .profile(profile())
    .query({
      address: address.split(' ').join('+'),
      key: apiKey(),
    })
    .get();
export default geocoding;
