import styled from 'styled-components';

const Scroller = styled.div`
  width: 100%;
  overflow: auto;
  padding: 15px;
  -webkit-overflow-scrolling: touch;
`;

export default Scroller;
