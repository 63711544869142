import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';
import {Info} from 'doorson-ui';
import {CheckBox} from 'doorson-ui';

const UpdateMaterialModal = ({
  title,
  codeLabel,
  nameLabel,
  unitLabel,
  hasGuaranteeLabel,
  saveLabel,
  visible,
  loading,
  code,
  name,
  unit,
  has_guarantee,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose}>
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Input value={name} onChange={onChange('name')} disabled={loading}>
            {nameLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 2}>
          <Info label={codeLabel}>{code}</Info>
        </Column>
        <Column size={1 / 2}>
          <Info label={unitLabel}>{unit}</Info>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <CheckBox
            selected={has_guarantee}
            onSelect={onChange('has_guarantee')}
            disabled={loading}
          >
            {hasGuaranteeLabel}
          </CheckBox>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

UpdateMaterialModal.propTypes = {
  title: PropTypes.string,
  codeLabel: PropTypes.string,
  nameLabel: PropTypes.string,
  unitLabel: PropTypes.string,
  hasGuaranteeLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  code: PropTypes.string,
  name: PropTypes.string,
  unit: PropTypes.string,
  hasGuarantee: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default UpdateMaterialModal;
