import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// RepairsAdminPage
import RepairsAdminPage from '../RepairsAdminPage/RepairsAdminPage';
import routeRepairsAdminPage from '../RepairsAdminPage/route';

// Consts
import page from './page';

const RepairsAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeRepairsAdminPage()}
        component={RepairsAdminPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default RepairsAdminPages;
