import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import PreLoader from '../../../layout/components/PreLoader/PreLoader';

import Container from './components/Container';

const GeneralTranslationsTable = ({
  loading,
  more,
  scroller,
  sort,
  columns,
  translations,
  displayAttribute,
  onTranslation,
  onMore,
  onSort,
}) => (
  <Container>
    <PreLoader
      scroller={scroller}
      loading={loading}
      showLoader={!!translations.length}
      more={more}
      onMore={onMore}
    >
      <Table columns={columns} loading={loading} sort={sort} onSort={onSort}>
        {(TableRow) =>
          translations.map((translation) => (
            <TableRow key={translation.id} onClick={onTranslation(translation)}>
              {(TableCol) => [
                <TableCol key="key" span={2} smallPadding>
                  {translation[displayAttribute]}
                </TableCol>,
                <TableCol key="translations" smallPadding>
                  {translation.languages.length}
                </TableCol>,
              ]}
            </TableRow>
          ))
        }
      </Table>
    </PreLoader>
    {!loading && !translations.length && (
      <NoDataFound>No translations found</NoDataFound>
    )}
  </Container>
);

GeneralTranslationsTable.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  displayAttribute: PropTypes.string,
  translations: PropTypes.array,
  onTranslation: PropTypes.func,
};

export default GeneralTranslationsTable;
