import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import createApi from '../../api/create.api.repair';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminRepairButton from '../../components/NewAdminRepairButton/NewAdminRepairButton';
import NewRepairModal from '../../components/NewRepairModal/NewRepairModal';

class NewRepairContainer extends Component {
  static propTypes = {
    repairs: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    description: '',
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, description: ''});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, description: ''});
  };

  create = async () => {
    // TODO: I18n
    const {loading, visible, description} = this.state;
    if (loading || !visible) return;

    if (!description.trim().length)
      return alertify.warning('Insert the description');

    this.setState({loading: true});

    try {
      const repair = await createApi({description});
      const {dispatch, repairs} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(setAct({repairs: [...repairs, repair]}));
    } catch (error) {
      alertify.error('Repair could not be created');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {loading, visible, description} = this.state;
    return (
      <Fragment>
        <NewAdminRepairButton onClick={this.show}>
          New Repair
        </NewAdminRepairButton>
        <NewRepairModal
          title="New Repair"
          descriptionLabel="Description"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          description={description}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.repair}))(NewRepairContainer);
