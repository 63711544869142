import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteDocumentationApi = (documentationID, fileID) =>
  f3tch(`/documentation-folders/${documentationID}/files/${fileID}`)
    .profile(profile())
    .delete();
export default deleteDocumentationApi;
