import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Content = styled.div`
  width: 100%;
  padding: 15px 0px 5px 0px;
  background: ${white};
`;

export default Content;
