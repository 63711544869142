import React from 'react';
import PropTypes from 'prop-types';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import MaintenanceDoorAttachmentsContainer from '../../../file/containers/MaintenanceDoorAttachmentsContainer/MaintenanceDoorAttachmentsContainer';

// TODO: I18n
const MaintenanceDoorAttachmentsPage = ({match}) => (
  <SubPage page={match.path}>
    <MaintenanceDoorAttachmentsContainer />
  </SubPage>
);

MaintenanceDoorAttachmentsPage.propTypes = {
  match: PropTypes.object,
};

export default MaintenanceDoorAttachmentsPage;
