import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createMaintenanceCheckApi = (maintenanceCheck) =>
  f3tch('/maintenance-checks')
    .profile(profile())
    .body(maintenanceCheck)
    .post();
export default createMaintenanceCheckApi;
