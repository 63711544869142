import {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'doorson-ui/lib/Button/Button';

// Local components
import ServiceItemHistoryList from '../../../components/ServiceItemHistoryList/ServiceItemHistoryList';
import ButtonContainer from './ButtonContainer';

const ServiceItemHistory = ({
  loading,
  historyColumns,
  history,
  createButtonLabel,
  onNew,
  onHistoryItem,
  type,
}) => (
  <Fragment>
    <ServiceItemHistoryList
      columns={historyColumns}
      onItem={onHistoryItem('intervention')}
      loading={history.loading}
      items={history[type]}
    />
    <ButtonContainer>
      <Button
        size="normal"
        theme="orange"
        outline
        loading={loading}
        onClick={onNew}
      >
        {createButtonLabel}
      </Button>
    </ButtonContainer>
  </Fragment>
);

ServiceItemHistory.propTypes = {
  loading: PropTypes.bool,
  historyColumns: PropTypes.array,
  history: PropTypes.object,
  createButtonLabel: PropTypes.string,
  onNew: PropTypes.func,
  onHistoryItem: PropTypes.func,
  type: PropTypes.string,
};

export default ServiceItemHistory;
