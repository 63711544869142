import geocodingApi from './geocoding.api.maps';

const EMPTY_POINT = {
  longitude: null,
  latitude: null,
};

const getParsedCoordinates = async (address) => {
  try {
    const {results, status} = await geocodingApi(address);
    if (status !== 'OK' || !results.length) return EMPTY_POINT;
    const [result] = results;
    if (!result || !result.geometry || !result.geometry.location)
      return EMPTY_POINT;
    const {lat, lng} = result.geometry.location;
    return {
      longitude: lng,
      latitude: lat,
    };
  } catch (error) {
    return EMPTY_POINT;
  }
};
export default getParsedCoordinates;
