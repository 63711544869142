import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';

const Priority = styled.div`
  width: 100%;
  height: 4px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: ${({color}) => colors[color]};
`;

export default Priority;
