import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import NewManufacturerContainer from '../../containers/NewManufacturerContainer/NewManufacturerContainer';
import NewProductContainer from '../../../product/containers/NewProductContainer/NewProductContainer';
import NewProductTypeContainer from '../../../productType/containers/NewProductTypeContainer/NewProductTypeContainer';

// Routes
import manufacturersRoute from '../ManufacturersAdminPage/route';
import manufacturerRoute from '../ManufacturerAdminPage/route';
import productRoute from '../../../product/pages/ProductAdminPage/route';

const ManufacturersAdminAddonPage = () => (
  <Switch>
    <Route
      path={manufacturersRoute()}
      component={NewManufacturerContainer}
      exact
    />
    <Route path={manufacturerRoute()} component={NewProductContainer} exact />
    <Route path={productRoute()} component={NewProductTypeContainer} exact />
  </Switch>
);

export default ManufacturersAdminAddonPage;
