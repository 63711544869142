import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  getTime,
  parse,
  isBefore,
  startOfMonth,
  endOfMonth,
  setMonth,
} from 'date-fns';
import {withRouter} from 'react-router-dom';

// Api
import createMaintenanceContractApi from '../../api/create.api.maintenanceContract';
import searchCustomerApi from '../../../customer/api/list.api.customer';

// Alert
import {alertify} from 'doorson-ui';

// Components
import MaintenanceContractCustomerEdit from '../../../customer/components/MaintenanceContractCustomerEdit/MaintenanceContractCustomerEdit';

// Routes
import maintenanceContractsRoute from '../../pages/MaintenanceContractsAdminPage/route';
import {connect} from 'react-redux';

class NewMaintenanceContractContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    loading: false,
    error: {},
    customer: null,
    search: '',
    validFrom: '',
    validTill: '',
    periodStart: '',
    periodEnd: '',
    type: 'business',
    companies: [],
    branches: [],
    searching: false,
  };

  lastCompanySearch = 0;

  onChange = (key) => (value) => {
    const {loading} = this.state;
    if (loading) return;

    const error = Object.entries(this.state.error).reduce(
      (combined, [errorKey, value]) =>
        key === errorKey ? combined : {...combined, [errorKey]: value},
      {}
    );
    const state = {
      [key]: value,
      error,
    };

    if (key === 'search') this.searchCompany(value);

    if (key === 'type') {
      state.error = {};
      state.search = '';
      state.companies = [];
      state.branches = [];
      state.customer = null;
    }

    this.setState(state);
  };

  getCountryByCode = (code) =>
    [...this.props.countries].find((country) => country.code === code);

  searchCompany = async (search) => {
    const {type} = this.state;
    if (search.trim().length < 3)
      return this.setState({companies: [], searching: false});

    this.setState({searching: true});

    const lastCompanySearch = getTime(new Date());
    this.lastCompanySearch = lastCompanySearch;

    const searchKey = type === 'business' ? 'company' : 'first_name';
    try {
      const companies = await searchCustomerApi({
        rel: 'customerBranches',
        filter: encodeURIComponent(
          `${searchKey}:LIKEIC:%${search}%,id:ISNOTNULL`
        ),
      });
      if (lastCompanySearch !== this.lastCompanySearch) return;
      this.setState({
        companies: [...companies].filter(({id}) => !!id),
        searching: false,
      });
    } catch (error) {
      // Do nothing for now
    }
  };

  types = () => [
    {
      value: 'business',
      label: 'Business customer',
    },
    {
      value: 'private',
      label: 'Private customer',
    },
  ];

  companies = () =>
    [...this.state.companies].map(
      ({
        type,
        company,
        id,
        firstName,
        lastName,
        location: {
          address,
          zip,
          city,
          countryCode,
        },
      }) => ({
        value: id,
        label:
          type === 'business'
            ? company
            : `${firstName} ${lastName}, ${address}, ${zip} ${city}, ${countryCode}`,
      })
    );

  onCompany = (id) => {
    const customer = [...this.state.companies].find((c) => c.id === id);
    if (!customer) return;

    this.setState({customer, branches: []});
  };

  onBranch = (branch) => () => {
    if (this.state.loading) return;
    const {branches} = this.state;
    this.setState({
      branches: branches.includes(branch.id)
        ? [...branches].filter((branchID) => branchID !== branch.id)
        : [...branches, branch.id],
    });
  };

  onBranches = (branches) => {
    if (this.state.loading) return;
    this.setState({branches});
  };

  create = async () => {
    // TODO: I18n
    // TODO: Error management
    const {history} = this.props;
    const {
      loading,
      customer,
      validFrom,
      validTill,
      periodStart: periodStartMonth,
      periodEnd: periodEndMonth,
      branches,
    } = this.state;
    if (loading) return;

    if (!customer) return alertify.warning('Select a customer');
    if (!branches.length) return alertify.warning('Select branches');

    const error = {};
    if (!validFrom) error.validFrom = 'Select a date';
    if (!periodStartMonth) error.periodStart = 'Select a date';
    if (!periodEndMonth) error.periodEnd = 'Select a date';

    if (Object.keys(error).length) {
      this.setState({error});
      return alertify.warning(
        'Make sure you filled out all the required fields'
      );
    }

    const periodStart = startOfMonth(setMonth(new Date(), periodStartMonth));
    const periodEnd = endOfMonth(setMonth(new Date(), periodEndMonth));

    if (
      !!validTill &&
      isBefore(
        parse(validTill, 'yyyy-MM-dd', new Date()),
        parse(validFrom, 'yyyy-MM-dd', new Date())
      )
    )
      return alertify.warning('Valid till can not be before valid from');

    if (isBefore(periodEnd, periodStart))
      return alertify.warning(
        'Period end date can not be before period start date'
      );

    this.setState({loading: true});

    const contract = {
      status: true,
      validFrom: getTime(parse(validFrom, 'yyyy-MM-dd', new Date())),
      validTill: !!validTill
        ? getTime(parse(validTill, 'yyyy-MM-dd', new Date()))
        : null,
      periodStart: getTime(periodStart),
      periodEnd: getTime(periodEnd),
      customerId: customer.id,
      customerBranchIds: branches,
    };

    try {
      await createMaintenanceContractApi(contract);
      history.push(maintenanceContractsRoute());
    } catch (error) {
      alertify.error(
        'Could not create an maintenanceContract incident. Try again'
      );
      this.setState({loading: false, priorityVisible: false});
    }
  };

  render() {
    // TODO: I18N
    const {
      loading,
      error,
      validFrom,
      validTill,
      periodStart,
      periodEnd,
      search,
      customer,
      branches,
      type,
      searching,
    } = this.state;

    return (
      <MaintenanceContractCustomerEdit
        searchLabel="Search for Customer"
        companyLabel="Company name"
        registrationNumberLabel="Registration number"
        firstNameLabel="First Name"
        lastNameLabel="Last Name"
        addressLabel="Address"
        zipLabel="Zip Code"
        cityLabel="City"
        countryLabel="Country"
        buttonLabel="Create"
        noSuggestionLabel="No suggestions found"
        validFromLabel="Valid From"
        validTillLabel="Valid Till"
        periodStartLabel="Period Start"
        periodEndLabel="Period End"
        phoneLabel="Phone"
        emailLabel="Email"
        branchesLabel="Branches"
        noBranchesLabel="This customer doesn't have any branches available. Insert some before continuing"
        selectAllLabel="Select All"
        searchBranchLabel="Search"
        noBranchesFoundLabel="No Branches Found"
        loading={loading}
        types={this.types()}
        companies={this.companies()}
        error={error}
        type={type}
        validFrom={validFrom}
        validTill={validTill}
        periodStart={periodStart}
        periodEnd={periodEnd}
        search={search}
        customer={customer}
        branches={branches}
        searching={searching}
        onChange={this.onChange}
        onConfirm={this.create}
        onCompany={this.onCompany}
        onBranch={this.onBranch}
        getCountryByCode={this.getCountryByCode}
        onBranches={this.onBranches}
      />
    );
  }
}

export default connect((state) => ({
  countries: state.country.countries,
}))(withRouter(NewMaintenanceContractContainer));
