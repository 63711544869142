import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateFaultApi = (id, fault) =>
  f3tch(`/faults/${id}`)
    .profile(profile())
    .body(fault)
    .put();
export default updateFaultApi;
