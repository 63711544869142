import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {v4} from 'uuid';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import listCustomersApi from '../../api/list.api.customer';
import deleteApi from '../../api/delete.api.customer';

// Components
import Search from '../../../layout/components/Search/Search';
import AdminCustomers from '../../components/AdminCustomers/AdminCustomers';
// import DeleteCustomerModal from '../../components/DeleteCustomerModal/DeleteCustomerModal';

// Routes
import customerRoute from '../../pages/CustomerAdminPage/route';

// Query
import setQuery from '../../../api/lib/query.lib.api';

class CustomersContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    scroller: PropTypes.object,
  };

  static LIMIT = 30;

  state = {
    loading: false,
    removeID: null,
    customers: [],
    search: '',
    sort: null,
    more: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiID = null;

  init = () => {
    this.getCustomers();
  };

  getCustomers = async ({
    search = this.state.search,
    sort = this.state.sort,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;

    const {LIMIT} = this.constructor;

    this.setState({
      search,
      sort,
      loading: true,
      offset: 0,
      more: true,
      customers: [],
    });

    try {
      const query = setQuery({
        order: sort,
        offset: 0,
        limit: LIMIT,
      });
      if (!!search.trim().length)
        query.filter = encodeURIComponent(
          `company:LIKEIC:%${search}%,id:ISNOTNULL`
        );
      const customers = await listCustomersApi(query);
      if (this.apiID !== apiID) return;

      this.setState({
        customers,
        loading: false,
        more: customers.length === LIMIT,
        offset: LIMIT,
      });
    } catch (error) {
      this.setState({loading: false, customers: []});
    }
  };

  getMoreCustomers = async () => {
    const apiID = this.apiID;
    const {
      loading,
      search,
      sort,
      offset,
      more,
      customers: oldCustomers,
    } = this.state;

    const {LIMIT} = this.constructor;

    if (!more || loading) return;

    this.setState({loading: true});

    try {
      const query = setQuery({
        search,
        order: sort,
        offset,
        limit: LIMIT,
      });
      const customers = await listCustomersApi(query);
      if (this.apiID !== apiID) return;
      this.setState({
        customers: [...oldCustomers, ...customers],
        loading: false,
        offset: LIMIT + offset,
        more: customers.length === LIMIT,
      });
    } catch (error) {
      this.setState({loading: false});
    }
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'name',
      label: 'Name',
      span: 2,
    },
    {
      key: 'address',
      label: 'Address',
      span: 2,
    },
    {
      key: 'type',
      label: 'Type',
      span: 2,
    },
    // {
    //   key: 'actions',
    //   label: 'Actions',
    //   span: 1,
    // },
  ];

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  selectCustomer = (customer) => () =>
    this.props.history.push(customerRoute(customer.id));

  onSearch = (search) => this.getCustomers({search});

  onSort = (sort) => this.getCustomers({sort});

  onMore = () => this.getMoreCustomers();

  remove = async () => {
    const {loading, removeID} = this.state;
    if (loading || !removeID) return;

    this.setState({loading: true});

    try {
      await deleteApi(removeID);
      this.setState({
        loading: false,
        removeID: null,
        customers: [...this.state.customers].filter(({id}) => id !== removeID),
      });
    } catch (error) {
      alertify.error('Customer could not be removed');
      this.setState({loading: false});
    }
  };

  render() {
    const {scroller} = this.props;
    const {loading, customers, sort, more, search} = this.state;
    return (
      <Fragment>
        <Search search={search} onSearch={this.onSearch} />
        <AdminCustomers
          loading={loading}
          more={more}
          scroller={scroller}
          sort={sort}
          columns={this.columns()}
          customers={customers}
          onCustomer={this.selectCustomer}
          onSort={this.onSort}
          onMore={this.onMore}
        />
        {/* <DeleteCustomerModal
          title="Delete Customer"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this customer?
        </DeleteCustomerModal> */}
      </Fragment>
    );
  }
}

export default connect((state) => ({scroller: state.layout.scroller}))(
  withRouter(CustomersContainer)
);
