import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import DocumentationContainer from '../../containers/DocumentationContainer/DocumentationContainer';

// Consts
import page from './page';

const DocumentationPage = () => (
  <Page page={page}>
    <DocumentationContainer />
  </Page>
);

export default DocumentationPage;
