import styled from 'styled-components';
import PropTypes from 'prop-types';

const ValueContainer = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0, 0.5);
  float: left;
  padding: 12px 0px;
  font-size: 22px;
  font-weight: 800;
  color: ${props => (props.theme === 'blue' ? 'rgba(83, 176, 203, 1)' : (props.theme === 'orange' ? 'rgba(245, 131, 32, 1)' : 'rgba(0,0,0,0.8)'))};
  user-select: none;
`;

ValueContainer.propTypes = {
  theme: PropTypes.string,
};

export default ValueContainer;
