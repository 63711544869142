import React from 'react';
import PropTypes from 'prop-types';

// Components
// Colors
import {Button, Input, Table} from 'doorson-ui';
import Container from './components/Container';
import ButtonContainer from "./components/ButtonContainer";

const WarehouseIdents = ({loading, saving, orderMode, columns, sort, warehouseItems, onChange, onSort, saveLabel, transferLabel, transfer, transferOptions}) => (
  <Container>
    <Table columns={columns} loading={loading} sort={sort} size={orderMode ? 'small' : 'large'} onSort={onSort}>
      {(TableRow) =>
        warehouseItems.map((item) => (
          <TableRow key={item.sku}>
            {(TableCol) => [
              <TableCol smallPadding span={1.5} key="sku">
                {item.sku}
              </TableCol>,
              <TableCol smallPadding span={4} key="name">
                {item.name}
              </TableCol>,
              <TableCol smallPadding key="stock">
                {item.stock}
              </TableCol>,
              <TableCol smallPadding key="unit">
                {item.unit}
              </TableCol>,
              (orderMode || transfer) && <TableCol smallPadding span={1.2} key="qty">
                <Input
                  value={item.qty}
                  onChange={onChange('qty', item)}
                  disabled={loading}
                  placeholder={item.unit}
                >&nbsp;</Input>
              </TableCol>,
              orderMode && <TableCol span={2.7} key="action">
                <Button theme="orange" onClick={onChange('addToOrder', item)} loading={loading}>{saveLabel}</Button>
              </TableCol>,
              !orderMode && transfer && <TableCol smallPadding span={3.2} key="action">
                <ButtonContainer><Button theme="orange"
                        size="big"
                        options={transferOptions}
                        loading={loading || saving}
                        disabled={saving || transferOptions.length === 0}
                        onClick={onChange('transfer', item)}>
                  {transferLabel}
                </Button></ButtonContainer>
              </TableCol>,
            ].filter(f => !!f)}
          </TableRow>
        ))
      }
    </Table>
  </Container>
);

WarehouseIdents.propTypes = {
  columns: PropTypes.array,
  transferLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  orderMode: PropTypes.bool,
  transfer: PropTypes.bool,
  transferOptions: PropTypes.array,
  warehouseItems: PropTypes.array,
  onChange: PropTypes.func,
};

export default WarehouseIdents;
