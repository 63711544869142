import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white, darkGrey} = colors;

const Caret = styled.div`
  position: absolute;
  top: 50%;
  ${({left}) => (left ? 'right' : 'left')}: 0px;
  transform: ${({left}) =>
    left
      ? 'translateY(calc(-50% + 22px)) rotate(-90deg)'
      : 'translateY(calc(-50% - 22px)) rotate(90deg)'};
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    top: 25%;
    left: 12px;
    border-top: 10px solid ${darkGrey};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  &::after {
    content: '';
    position: absolute;
    top: 25%;
    left: 13px;
    border-top: 9px solid ${white};
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
  }
`;

export default Caret;
