import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Containers
import MaintenanceDoorTabsContainer from '../../containers/MaintenanceDoorTabsContainer/MaintenanceDoorTabsContainer';

// TODO: I18n
const MaintenanceDoorTabsPage = ({match}) => <MaintenanceDoorTabsContainer />;

MaintenanceDoorTabsPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(MaintenanceDoorTabsPage);
