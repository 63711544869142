import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {AsyncInput} from 'doorson-ui';
import {Input} from 'doorson-ui';
import {Select} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {CheckBox} from 'doorson-ui';
import {Form} from 'doorson-ui';

import Wrapper from './components/Wrapper';
import Box from './components/Box';
import TypeContainer from './components/TypeContainer';
import Type from './components/Type';
import Right from './components/Right';
import ButtonContainer from './components/ButtonContainer';

// Libs
import validateNumberInput from '../../../layout/lib/validateNumberInput.lib.layout';

const CustomerEdit = ({
  companyLabel,
  registrationNumberLabel,
  vatNumberLabel,
  firstNameLabel,
  lastNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  branchNameLabel,
  branchAddressLabel,
  branchZipLabel,
  branchCityLabel,
  branchCountryLabel,
  phoneLabel,
  emailLabel,
  buttonLabel,
  sameAsAddressLabel,
  noSuggestionLabel,
  editBranchLabel,

  types,
  countries,
  companies,
  branches,

  loading,
  error,
  type,
  customer,
  company,
  registrationNumber,
  vatNumber,
  firstName,
  lastName,
  address,
  zip,
  city,
  country,
  sameAsAddress,
  branchName,
  branchAddress,
  branchZip,
  branchCity,
  branchCountry,
  phone,
  email,
  searchKey,
  customerSelected,
  customerBranchSelected,
  searchingForCustomer,

  onChange,
  onConfirm,
  onCompany,
  onBranch,
  showUpdateBranch,
}) => (
  <Fragment>
    <TypeContainer>
      {types.map(({value, label}) => (
        <Type
          key={value}
          selected={value === type}
          onClick={() => onChange('type')(value)}
        >
          {label}
        </Type>
      ))}
    </TypeContainer>
    <Wrapper>
      <Box>
        <Form onSubmit={onConfirm}>
          {type === 'business' ? (
            <Row margin>
              <Column size={1 / 2}>
                <AsyncInput
                  value={company}
                  onChange={onChange('company')}
                  error={error.company}
                  disabled={loading}
                  suggestions={companies}
                  onSuggestion={onCompany}
                  noSuggestionLabel={noSuggestionLabel}
                  displaySuggestions={() =>
                    company.trim().length >= 3 && searchKey === 'company'
                  }
                  searching={searchingForCustomer}
                  required
                >
                  {companyLabel}
                </AsyncInput>
              </Column>
              <Column size={1 / 4}>
                <AsyncInput
                  value={registrationNumber}
                  onChange={onChange('registrationNumber')}
                  error={error.registrationNumber}
                  suggestions={companies}
                  onSuggestion={onCompany}
                  noSuggestionLabel={noSuggestionLabel}
                  displaySuggestions={() =>
                    registrationNumber.trim().length >= 10 &&
                    searchKey === 'registrationNumber'
                  }
                  searching={searchingForCustomer}
                  disabled={loading}
                  required
                >
                  {registrationNumberLabel}
                </AsyncInput>
              </Column>
              <Column size={1 / 4}>
                <Input
                  value={vatNumber}
                  onChange={onChange('vatNumber')}
                  error={error.vatNumber}
                  disabled={loading}
                  required
                >
                  {vatNumberLabel}
                </Input>
              </Column>
            </Row>
          ) : (
            <Row margin>
              <Column size={1 / 3}>
                <Input
                  value={firstName}
                  onChange={onChange('firstName')}
                  error={error.firstName}
                  disabled={loading}
                  required
                >
                  {firstNameLabel}
                </Input>
              </Column>
              <Column size={1 / 3}>
                <Input
                  value={lastName}
                  onChange={onChange('lastName')}
                  error={error.lastName}
                  disabled={loading}
                  required
                >
                  {lastNameLabel}
                </Input>
              </Column>
              <Column size={1 / 3}>
                <Input
                  value={vatNumber}
                  onChange={onChange('vatNumber')}
                  error={error.vatNumber}
                  disabled={loading}
                >
                  {vatNumberLabel}
                </Input>
              </Column>
            </Row>
          )}
          <Row margin>
            <Column size={2 / 3}>
              <Input
                value={address}
                onChange={onChange('address')}
                error={error.address}
                disabled={loading || customerSelected}
                required
              >
                {addressLabel}
              </Input>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Input
                value={zip}
                onChange={onChange('zip')}
                error={error.zip}
                disabled={loading || customerSelected}
                required
              >
                {zipLabel}
              </Input>
            </Column>
            <Column size={1 / 3}>
              <Input
                value={city}
                onChange={onChange('city')}
                error={error.city}
                disabled={loading || customerSelected}
                required
              >
                {cityLabel}
              </Input>
            </Column>
            <Column size={1 / 3}>
              <Select
                value={country}
                options={countries}
                onChange={onChange('country')}
                error={error.country}
                disabled={loading || customerSelected}
                required
              >
                {countryLabel}
              </Select>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <CheckBox
                selected={sameAsAddress}
                onSelect={onChange('sameAsAddress')}
                disabled={loading || !!(customer || {}).id}>
                {sameAsAddressLabel}
              </CheckBox>
            </Column>
          </Row>
          <Row margin>
            <Column size={2 / 3}>
              <Input
                value={branchName}
                onChange={onChange('branchName')}
                error={error.branchName}
                disabled={loading || sameAsAddress}
                suggestions={branches}
                onSuggestion={onBranch}
                noSuggestionLabel={noSuggestionLabel}
                displaySuggestions={() => customerSelected}
                required
              >
                {branchNameLabel}
              </Input>
            </Column>
            {customerBranchSelected && <Column size={1 / 3}>
              <ButtonContainer>
                <Button
                  size="normal"
                  theme="blue"
                  preIcon="account-edit-outline"
                  outline={true}
                  onClick={(e) => {e.preventDefault(); showUpdateBranch()}}>
                  {editBranchLabel}
                </Button>
              </ButtonContainer>
            </Column>}
          </Row>
          <Row margin>
            <Column size={2 / 3}>
              <Input
                value={branchAddress}
                onChange={onChange('branchAddress')}
                error={error.branchAddress}
                disabled={loading || sameAsAddress || customerBranchSelected}
                required
              >
                {branchAddressLabel}
              </Input>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Input
                value={branchZip}
                onChange={onChange('branchZip')}
                error={error.branchZip}
                disabled={loading || sameAsAddress || customerBranchSelected}
                required
              >
                {branchZipLabel}
              </Input>
            </Column>
            <Column size={1 / 3}>
              <Input
                value={branchCity}
                onChange={onChange('branchCity')}
                error={error.branchCity}
                disabled={loading || sameAsAddress || customerBranchSelected}
                required
              >
                {branchCityLabel}
              </Input>
            </Column>
            <Column size={1 / 3}>
              <Select
                value={branchCountry}
                options={countries}
                onChange={onChange('branchCountry')}
                error={error.branchCountry}
                disabled={loading || sameAsAddress || customerBranchSelected}
                required
              >
                {branchCountryLabel}
              </Select>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Input
                type="tel"
                value={phone}
                onChange={onChange('phone')}
                error={error.phone}
                disabled={loading}
                onKeyDown={validateNumberInput}
              >
                {phoneLabel}
              </Input>
            </Column>
            <Column size={1 / 3}>
              <Input
                type="email"
                value={email}
                onChange={onChange('email')}
                error={error.email}
                disabled={loading}
              >
                {emailLabel}
              </Input>
            </Column>
          </Row>
          <Row>
            <Column>
              <Right>
                <ButtonContainer>
                  <Button size="big" theme="orange" loading={loading}>
                    {buttonLabel}
                  </Button>
                </ButtonContainer>
              </Right>
            </Column>
          </Row>
        </Form>
      </Box>
    </Wrapper>
  </Fragment>
);

CustomerEdit.propTypes = {
  companyLabel: PropTypes.node,
  registrationNumberLabel: PropTypes.node,
  vatNumberLabel: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  branchNameLabel: PropTypes.node,
  branchAddressLabel: PropTypes.node,
  branchZipLabel: PropTypes.node,
  branchCityLabel: PropTypes.node,
  branchCountryLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,
  buttonLabel: PropTypes.node,
  sameAsAddressLabel: PropTypes.node,
  noSuggestionLabel: PropTypes.node,
  editBranchLabel: PropTypes.node,

  types: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),

  loading: PropTypes.bool,
  error: PropTypes.object,
  type: PropTypes.string,
  customer: PropTypes.object,
  company: PropTypes.string,
  registrationNumber: PropTypes.string,
  vatNumber: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  sameAsAddress: PropTypes.bool,
  branchName: PropTypes.string,
  branchAddress: PropTypes.string,
  branchZip: PropTypes.string,
  branchCity: PropTypes.string,
  branchCountry: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  searchKey: PropTypes.string,
  customerSelected: PropTypes.bool,
  customerBranchSelected: PropTypes.bool,
  searchingForCustomer: PropTypes.bool,

  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  onCompany: PropTypes.func,
  onBranch: PropTypes.func,
  showUpdateBranch: PropTypes.func,
};

export default CustomerEdit;
