import styled from 'styled-components';

const DaysContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  padding-top: ${({height}) => `${height}px`};
`;

export default DaysContainer;
