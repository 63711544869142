import numeral from 'numeral';

import type from '../type';

const format = (input) =>
  !input ? '' : numeral(`${input}`.replace(/[^0-9]/g, '')).format('0,0');

const validate = (value) =>
  /(?=.)^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?$/.test(value);

export default type({type: 'string', format, validate});
