import styled from 'styled-components';

const Img = styled.img`
  position: absolute;
  width: 80%;
  max-width: 215px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export default Img;
