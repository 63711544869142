import React from 'react';
import PropTypes from 'prop-types';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import WorklogContainer from '../../containers/WorklogContainer/WorklogContainer';

// TODO: I18n
const MaintenanceDoorWorklogPage = ({match}) => (
  <SubPage page={match.path}>
    <WorklogContainer />
  </SubPage>
);

MaintenanceDoorWorklogPage.propTypes = {
  match: PropTypes.object,
};

export default MaintenanceDoorWorklogPage;
