import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import createApi from '../../api/create.api.vehicle';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminVehicleButton from '../../components/NewAdminVehicleButton/NewAdminVehicleButton';
import NewVehicleModal from '../../components/NewVehicleModal/NewVehicleModal';
import listWarehousesApi from "../../../warehouse/api/get.api.warehouse";

class NewVehicleContainer extends Component {
  static propTypes = {
    vehicles: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
    visible: false,
    make: '',
    model: '',
    licensePlate: '',
    warehouseId: '',
    label: '',
    warehouses: [],
    disabled: false,
  };

  componentDidMount() {
    this.getWarehouses();
  }

  getWarehouses = async () => {
    try {
      const warehouses = await listWarehousesApi({offset: 0, limit: 300});
      this.setState({warehouses, loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  }

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({
      visible: true,
      make: '',
      model: '',
      licensePlate: '',
      warehouseId: '',
      label: '',
      disabled: false,
    });
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({
      visible: false,
      make: '',
      model: '',
      licensePlate: '',
      warehouseId: '',
      label: '',
      disabled: false,
    });
  };

  create = async () => {
    // TODO: I18n
    const {
      loading,
      visible,
      make,
      model,
      licensePlate,
      warehouseId,
      label,
      disabled,
    } = this.state;
    if (loading || !visible) return;

    if (!make.trim().length) return alertify.warning('Insert the make');
    if (!model.trim().length) return alertify.warning('Insert the model');
    if (!licensePlate.trim().length)
      return alertify.warning('Insert the license plate');
    if (!label.trim().length) return alertify.warning('Insert the label');

    this.setState({loading: true});

    try {
      const vehicle = await createApi({
        make,
        model,
        licensePlate,
        warehouseId: warehouseId || null,
        label,
        disabled,
      });
      const {dispatch, vehicles} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(setAct({vehicles: [...vehicles, vehicle]}));
    } catch (error) {
      alertify.error('Vehicle could not be created');
      this.setState({loading: false});
    }
  };

  warehouses = () => [{value: null, label: ''},
    ...[...this.state.warehouses].map(({id, name}) => ({
      value: id,
      label: name,
    }))];

  render() {
    // TODO: I18n
    const {
      loading,
      visible,
      make,
      model,
      licensePlate,
      warehouseId,
      label,
      disabled,
    } = this.state;
    return (
      <Fragment>
        <NewAdminVehicleButton onClick={this.show}>
          New Vehicle
        </NewAdminVehicleButton>
        <NewVehicleModal
          title="New Vehicle"
          makeLabel="Make"
          modelLabel="Model"
          licensePlateLabel="License Plate"
          labelLabel="Label"
          warehouseLabel="Warehouse"
          disabledLabel="Disabled"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          make={make}
          model={model}
          licensePlate={licensePlate}
          warehouseId={warehouseId}
          label={label}
          disabled={disabled}
          warehouses={this.warehouses()}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.vehicle}))(NewVehicleContainer);
