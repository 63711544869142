/**
 * @description :: Getting a value from localStorage
 * @param {String} key :: The key for getting a value from localStorage
 * @return {any} :: The value from localStorage
 */
export const get = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    /* eslint-disable  no-console */
    console.error(`Could not get "${key}" from localStorage.`);
    /* eslint-enable  no-console */
    return null;
  }
};

/**
 * @description :: Adding a value into localStorage
 * @param {String} key :: The key for saving a value to localStorage
 * @param {String} value :: The value to be saved into localStorage
 */
export const add = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    /* eslint-disable  no-console */
    console.error(`Could not save "${key}" to localStorage.`);
    /* eslint-enable  no-console */
  }
};

/**
 * @description :: Removing a value from localStorage
 * @param {String} key :: The key of the value to be removed from localStorage
 */
export const remove = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    /* eslint-disable  no-console */
    console.error(`Could not remove "${key}" from localStorage.`);
    /* eslint-enable  no-console */
  }
};
