import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import AttachmentsContainer from '../../../file/containers/AttachmentsContainer/AttachmentsContainer';

// TODO: I18n
const InterventionAttachmentsPage = ({match}) => (
  <SubPage page={match.path}>
    <AttachmentsContainer />
  </SubPage>
);

InterventionAttachmentsPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(InterventionAttachmentsPage);
