import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Container, Row, Column, Button, Tabs} from 'doorson-ui';
import Content from './components/Content';
import Link from './components/Link';

const MaintenancesFilter = ({newLabel, newUrl, filter, filters, onFilter}) => (
  <Container>
    <Row>
      <Column>
        <Content>
          <Tabs tab={filter} tabs={filters} onTab={onFilter} />
          <Link to={newUrl}>
            <Button>{newLabel}</Button>
          </Link>
        </Content>
      </Column>
    </Row>
  </Container>
);

MaintenancesFilter.propTypes = {
  newLabel: PropTypes.node,
  newUrl: PropTypes.string,
  filter: PropTypes.string,
  filters: PropTypes.array,
  onFilter: PropTypes.func,
};

export default MaintenancesFilter;
