import styled from 'styled-components';

// Components
import Logo from '../../../../layout/components/Logo/Logo';

const DoorsonLogo = styled(Logo)`
  display: block;
  height: 24px;
  margin-bottom: ${({nomargin}) => (nomargin ? '0px' : '20px')};
`;

export default DoorsonLogo;
