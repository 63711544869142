import styled from 'styled-components';
import {Link} from 'react-router-dom';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Documentation = styled(Link)`
  display: block;
  position: absolute;
  left: 36px;
  bottom: 50px;
  color: ${white};
  font-size: 14px;
`;

export default Documentation;
