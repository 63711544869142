import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Row,
  Column,
  AsyncInput,
  Button,
  Form,
  DatePicker,
  Info, Input,
} from 'doorson-ui';
import BranchSelection from '../../../customerBranch/components/BranchSelection/BranchSelection';

import Wrapper from './components/Wrapper';
import Box from './components/Box';
import Right from './components/Right';
import ButtonContainer from './components/ButtonContainer';
import TypeContainer from './components/TypeContainer';
import Type from './components/Type';
import BranchesContainer from './components/BranchesContainer';

const NewMaintenance = ({
  searchLabel,
  companyLabel,
  registrationNumberLabel,
  firstNameLabel,
  lastNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  buttonLabel,
  noSuggestionLabel,
  scheduledForLabel,
  phoneLabel,
  emailLabel,
  branchesLabel,
  noBranchesLabel,
  selectAllLabel,
  searchBranchLabel,
  noBranchesFoundLabel,
  newBranchLabel,

  companies,
  types,

  loading,
  error,
  type,
  scheduledFor,
  search,
  customer,
  branch,
  doorId,
  email,
  searchingForCustomer,

  getCountryByCode,
  onChange,
  onConfirm,
  onCompany,
  onBranch,
  onNewBranch,
}) => (
  <Fragment>
    <TypeContainer>
      {types.map(({value, label}) => (
        <Type
          key={value}
          selected={value === type}
          onClick={() => onChange('type')(value)}
        >
          {label}
        </Type>
      ))}
    </TypeContainer>
    <Wrapper>
      <Box>
        <Form onSubmit={onConfirm}>
          {
            <Row margin>
              <Column size={1 / 2}>
                <AsyncInput
                  value={search}
                  onChange={onChange('search')}
                  disabled={loading}
                  suggestions={companies}
                  onSuggestion={onCompany}
                  noSuggestionLabel={noSuggestionLabel}
                  displaySuggestions={() => search.trim().length >= 3}
                  searching={searchingForCustomer}
                  required
                >
                  {searchLabel}
                </AsyncInput>
              </Column>
            </Row>
          }
          {type === 'business' ? (
            <Row margin>
              <Column size={1 / 3}>
                <Info label={companyLabel}>
                  {!!customer ? customer.company : ''}
                </Info>
              </Column>
              <Column size={1 / 3}>
                <Info label={registrationNumberLabel}>
                  {!!customer ? customer.registrationNumber : ''}
                </Info>
              </Column>
            </Row>
          ) : (
            <Row margin>
              <Column size={1 / 3}>
                <Info label={firstNameLabel}>
                  {!!customer ? customer.firstName : ''}
                </Info>
              </Column>
              <Column size={1 / 3}>
                <Info label={lastNameLabel}>
                  {!!customer ? customer.lastName : ''}
                </Info>
              </Column>
            </Row>
          )}
          <Row margin>
            <Column size={2 / 3}>
              <Info label={addressLabel}>
                {!!customer ? customer.location.address : ''}
              </Info>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Info label={zipLabel}>
                {!!customer ? customer.location.zip : ''}
              </Info>
            </Column>
            <Column size={1 / 3}>
              <Info label={cityLabel}>
                {!!customer ? customer.location.city : ''}
              </Info>
            </Column>
            <Column size={1 / 3}>
              <Info label={countryLabel}>
                {!!customer ? (getCountryByCode(customer.location.countryCode)||{}).name : ''}
              </Info>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Info label={phoneLabel}>
                {!!customer ? customer.phone || '' : ''}
              </Info>
            </Column>
            <Column size={1 / 3}>
              <Input
                type="email"
                value={email}
                onChange={onChange('email')}
                disabled={loading}
                error={error.email}
              >
                {emailLabel}
              </Input>
            </Column>
          </Row>
          {!!customer && !doorId && (
            <Row margin>
              <Column>
                <BranchesContainer>
                  <BranchSelection
                    branches={customer.branches}
                    selected={!!branch ? [branch.id] : []}
                    onSelect={onBranch}
                    branchesLabel={branchesLabel}
                    newBranchLabel={newBranchLabel}
                    noBranchesLabel={noBranchesLabel}
                    selectAllLabel={selectAllLabel}
                    searchLabel={searchBranchLabel}
                    noBranchesFoundLabel={noBranchesFoundLabel}
                    onNewBranch={onNewBranch}
                    getCountryByCode={getCountryByCode}
                  />
                </BranchesContainer>
              </Column>
            </Row>
          )}
          <Row margin>
            <Column size={2 / 6}>
              <Info
                label={scheduledForLabel}
                error={error.scheduledFor}
                noBorder
              >
                <DatePicker
                  key="scheduledFor"
                  value={scheduledFor}
                  onChange={onChange('scheduledFor')}
                />
              </Info>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <Right>
                <ButtonContainer>
                  <Button size="big" theme="orange" loading={loading}>
                    {buttonLabel}
                  </Button>
                </ButtonContainer>
              </Right>
            </Column>
          </Row>
        </Form>
      </Box>
    </Wrapper>
  </Fragment>
);

NewMaintenance.propTypes = {
  companyLabel: PropTypes.node,
  registrationNumberLabel: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,
  buttonLabel: PropTypes.node,
  noSuggestionLabel: PropTypes.node,
  scheduledForLabel: PropTypes.node,
  validTillLabel: PropTypes.node,
  periodStartLabel: PropTypes.node,
  periodEndLabel: PropTypes.node,
  branchesLabel: PropTypes.node,
  noBranchesLabel: PropTypes.node,
  selectAllLabel: PropTypes.node,
  searchBranchLabel: PropTypes.node,
  noBranchesFoundLabel: PropTypes.node,
  newBranchLabel: PropTypes.node,

  companies: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  types: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  branch: PropTypes.object,
  email: PropTypes.string,

  loading: PropTypes.bool,
  error: PropTypes.object,
  type: PropTypes.string,
  scheduledFor: PropTypes.string,
  validTill: PropTypes.string,
  periodStart: PropTypes.string,
  periodEnd: PropTypes.string,
  customer: PropTypes.object,
  doorId: PropTypes.string,
  searchingForCustomer: PropTypes.bool,

  getCountryByCode: PropTypes.func,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  onCompany: PropTypes.func,
  onBranch: PropTypes.func,
  onNewBranch: PropTypes.func,
};

export default NewMaintenance;
