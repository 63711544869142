import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';

const Priority = styled.div`
  width: 8px;
  height: 8px;
  background: ${({color}) => colors[color]};
`;

export default Priority;
