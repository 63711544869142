import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Row,
  Column,
  AsyncInput,
  Button,
  Form,
  DatePicker,
  Info,
} from 'doorson-ui';
import BranchSelection from '../../../customerBranch/components/BranchSelection/BranchSelection';
import MonthSelect from '../../../layout/components/MonthSelect/MonthSelect';

import Wrapper from './components/Wrapper';
import Box from './components/Box';
import Right from './components/Right';
import ButtonContainer from './components/ButtonContainer';
import TypeContainer from './components/TypeContainer';
import Type from './components/Type';
import BranchesContainer from './components/BranchesContainer';

const MaintenanceContractCustomerEdit = ({
  searchLabel,
  companyLabel,
  registrationNumberLabel,
  firstNameLabel,
  lastNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  buttonLabel,
  noSuggestionLabel,
  validFromLabel,
  validTillLabel,
  periodStartLabel,
  periodEndLabel,
  phoneLabel,
  emailLabel,
  branchesLabel,
  noBranchesLabel,
  selectAllLabel,
  searchBranchLabel,
  noBranchesFoundLabel,

  companies,
  types,

  loading,
  error,
  type,
  validFrom,
  validTill,
  periodStart,
  periodEnd,
  search,
  customer,
  branches,
  searching,

  onChange,
  onConfirm,
  onCompany,
  getCountryByCode,
  onBranches,
}) => (
  <Fragment>
    <TypeContainer>
      {types.map(({value, label}) => (
        <Type
          key={value}
          selected={value === type}
          onClick={() => onChange('type')(value)}
        >
          {label}
        </Type>
      ))}
    </TypeContainer>
    <Wrapper>
      <Box>
        <Form onSubmit={onConfirm}>
          {
            <Row margin>
              <Column size={1 / 2}>
                <AsyncInput
                  value={search}
                  onChange={onChange('search')}
                  disabled={loading}
                  suggestions={companies}
                  onSuggestion={onCompany}
                  noSuggestionLabel={noSuggestionLabel}
                  displaySuggestions={() => search.trim().length >= 3}
                  searching={searching}
                  required
                >
                  {searchLabel}
                </AsyncInput>
              </Column>
            </Row>
          }
          {type === 'business' ? (
            <Row margin>
              <Column size={1 / 3}>
                <Info label={companyLabel}>
                  {!!customer ? customer.company : ''}
                </Info>
              </Column>
              <Column size={1 / 3}>
                <Info label={registrationNumberLabel}>
                  {!!customer ? customer.registrationNumber : ''}
                </Info>
              </Column>
            </Row>
          ) : (
            <Row margin>
              <Column size={1 / 3}>
                <Info label={firstNameLabel}>
                  {!!customer ? customer.firstName : ''}
                </Info>
              </Column>
              <Column size={1 / 3}>
                <Info label={lastNameLabel}>
                  {!!customer ? customer.lastName : ''}
                </Info>
              </Column>
            </Row>
          )}
          <Row margin>
            <Column size={2 / 3}>
              <Info label={addressLabel}>
                {!!customer ? customer.location.address : ''}
              </Info>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Info label={zipLabel}>
                {!!customer ? customer.location.zip : ''}
              </Info>
            </Column>
            <Column size={1 / 3}>
              <Info label={cityLabel}>
                {!!customer ? customer.location.city : ''}
              </Info>
            </Column>
            <Column size={1 / 3}>
              <Info label={countryLabel}>
                {!!customer ? customer.location.countryCode : ''}
              </Info>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Info label={phoneLabel}>
                {!!customer ? customer.phone || '' : ''}
              </Info>
            </Column>
            <Column size={1 / 3}>
              <Info label={emailLabel}>
                {!!customer ? customer.email || '' : ''}
              </Info>
            </Column>
          </Row>
          {!!customer && (
            <Row margin>
              <Column>
                <BranchesContainer>
                  <BranchSelection
                    branches={customer.branches}
                    selected={branches}
                    onSelect={onBranches}
                    branchesLabel={branchesLabel}
                    noBranchesLabel={noBranchesLabel}
                    selectAllLabel={selectAllLabel}
                    searchLabel={searchBranchLabel}
                    noBranchesFoundLabel={noBranchesFoundLabel}
                    getCountryByCode={getCountryByCode}
                    selectable
                  />
                </BranchesContainer>
              </Column>
            </Row>
          )}
          <Row margin>
            <Column size={2 / 6}>
              <Info label={validFromLabel} error={error.validFrom} noBorder>
                <DatePicker
                  value={validFrom}
                  onChange={onChange('validFrom')}
                />
              </Info>
            </Column>
            <Column size={2 / 6}>
              <Info label={validTillLabel} error={error.validTill} noBorder>
                <DatePicker
                  value={validTill}
                  onChange={onChange('validTill')}
                />
              </Info>
            </Column>
          </Row>
          <Row margin>
            <Column size={2 / 6}>
              <MonthSelect
                value={periodStart}
                onChange={onChange('periodStart')}
                error={error.periodStart}
              >
                {periodStartLabel}
              </MonthSelect>
            </Column>
            <Column size={2 / 6}>
              <MonthSelect
                value={periodEnd}
                onChange={onChange('periodEnd')}
                error={error.periodEnd}
              >
                {periodEndLabel}
              </MonthSelect>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <Right>
                <ButtonContainer>
                  <Button size="big" theme="orange" loading={loading}>
                    {buttonLabel}
                  </Button>
                </ButtonContainer>
              </Right>
            </Column>
          </Row>
        </Form>
      </Box>
    </Wrapper>
  </Fragment>
);

MaintenanceContractCustomerEdit.propTypes = {
  companyLabel: PropTypes.node,
  registrationNumberLabel: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,
  buttonLabel: PropTypes.node,
  noSuggestionLabel: PropTypes.node,
  validFromLabel: PropTypes.node,
  validTillLabel: PropTypes.node,
  periodStartLabel: PropTypes.node,
  periodEndLabel: PropTypes.node,
  branchesLabel: PropTypes.node,
  noBranchesLabel: PropTypes.node,
  selectAllLabel: PropTypes.node,
  searchBranchLabel: PropTypes.node,
  noBranchesFoundLabel: PropTypes.node,

  companies: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  types: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  branches: PropTypes.array,

  loading: PropTypes.bool,
  error: PropTypes.object,
  type: PropTypes.string,
  validFrom: PropTypes.string,
  validTill: PropTypes.string,
  periodStart: PropTypes.string,
  periodEnd: PropTypes.string,
  customer: PropTypes.object,
  searching: PropTypes.bool,

  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  onCompany: PropTypes.func,
  getCountryByCode: PropTypes.func,
  onBranches: PropTypes.func,
};

export default MaintenanceContractCustomerEdit;
