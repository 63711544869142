import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/externalJson.profile.api';

// Google Key
import apiKey from '../constants/googleApiKey.const.maps';

const reverseGeoCoding = async (latlng) => {
  const {results} = await f3tch(
    'https://maps.googleapis.com/maps/api/geocode/json'
  )
    .profile(profile())
    .query({
      latlng,
      key: apiKey(),
    })
    .get();

  if (!results.length) return;
  return results[0].formatted_address;
};
export default reverseGeoCoding;
