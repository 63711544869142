import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import deleteBranchApi from '../../api/delete.api.customerBranch';
import updateBranchApi from '../../api/update.api.customerBranch';
import getParsedCoodrinatesApi from '../../../maps/api/getParsedCoodrinates.api.maps';

// Alerts
import {alertify} from 'doorson-ui';

// Components
import UpdateCustomerBranchModal from '../../components/UpdateCustomerBranchModal/UpdateCustomerBranchModal';

class UpdateCustomerBranchContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    branch: PropTypes.object,
    onComplete: PropTypes.func,
    onDelete: PropTypes.func,
    onClose: PropTypes.func,
  };

  state = {
    saving: false,
    deleting: false,
    branchName: '',
    address: '',
    zip: '',
    city: '',
    country: {},
    error: {},
  };

  componentDidMount() {
    if (this.props.visible && !!this.props.branch) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible && !!this.props.branch)
      this.init();
  }

  init = () => {
    const {branch} = this.props;
    this.setState({
      saving: false,
      branchName: branch.branchName,
      address: branch.branchLocation.address,
      zip: branch.branchLocation.zip,
      city: branch.branchLocation.city,
      countryCode: branch.branchLocation.countryCode,
      error: {},
    });
  };

  countries = () =>
    [...this.props.countries].map(({code, name}) => ({
      value: code,
      label: name,
    }));

  getCountryByCode = (code) =>
    [...this.props.countries].find((country) => country.code === code);

  onClose = () => {
    if (this.state.saving || this.state.deleting) return;
    this.props.onClose();
  };

  onChange = (key) => (val) => {
    const {saving, deleting, error: oldError} = this.state;
    if (saving || deleting) return;
    const error = Object.entries(oldError).reduce(
      (combined, [errorKey, value]) =>
        key === errorKey ? combined : {...combined, [errorKey]: value},
      {}
    );
    this.setState({[key]: val, error});
  };

  save = async () => {
    const {visible, branch, onComplete} = this.props;
    const {
      saving,
      deleting,
      branchName,
      address,
      zip,
      city,
      countryCode,
    } = this.state;

    if (saving || deleting || !visible || !branch) return;

    // TODO: I18N
    const error = {};

    if (!branchName.trim().length) error.branchName = 'Should not be empty';
    if (!address.trim().length) error.address = 'Should not be empty';
    if (!zip.trim().length) error.zip = 'Should not be empty';
    if (!city.trim().length) error.city = 'Should not be empty';
    if (!countryCode) error.countryCode = 'Should not be empty';

    if (!!Object.keys(error).length) {
      this.setState({error});
      alertify.warning('Insert all the required customer info properly');
      return;
    }

    this.setState({saving: true});

    const rawCustomerBranch = {
      branchName,
      branchLocation: {
        address,
        zip,
        city,
        countryCode,
      },
      version: branch.version,
    };

    const country = this.getCountryByCode(countryCode);

    const coordinates = await getParsedCoodrinatesApi(
      `${address}, ${zip} ${city}, ${country.name}`
    );

    rawCustomerBranch.branchLocation.geo = coordinates;

    try {
      const customerBranch = await updateBranchApi(
        branch.id,
        rawCustomerBranch
      );
      this.setState({
        saving: false,
      });
      onComplete(customerBranch);
    } catch (error) {
      alertify.error('Could not save branch. Try again');
      this.setState({saving: false});
    }
  };

  delete = async () => {
    const {branch, onDelete} = this.props;
    const {saving, deleting} = this.state;
    if (saving || deleting) return;

    this.setState({deleting: true});

    try {
      await deleteBranchApi(branch.id);
      this.setState({deleting: false});
      onDelete(branch);
    } catch (error) {
      alertify.error('Could not delete branch');
      this.setState({deleting: false});
    }
  };

  render() {
    const {visible} = this.props;
    const {
      error,
      branchName,
      saving,
      deleting,
      address,
      zip,
      city,
      countryCode,
    } = this.state;
    return (
      <UpdateCustomerBranchModal
        title="Update Branch"
        branchNameLabel="Branch Name"
        addressLabel="Address"
        zipLabel="Zip Code"
        cityLabel="City"
        countryLabel="Country"
        saveLabel="Save"
        deleteLabel="Delete"
        visible={visible}
        saving={saving}
        deleting={deleting}
        error={error}
        branchName={branchName}
        address={address}
        zip={zip}
        city={city}
        countryCode={countryCode}
        countries={this.countries()}
        onChange={this.onChange}
        onClose={this.onClose}
        onSave={this.save}
        deleteEnabled={!!this.props.onDelete}
        onDelete={this.delete}
      />
    );
  }
}

export default connect((state) => ({
  countries: state.country.countries,
}))(UpdateCustomerBranchContainer);
