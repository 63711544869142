import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';

// Actions
import {set as setLayoutAct} from '../../../layout/redux/actions';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import AdminHeader from '../../components/AdminHeader/AdminHeader';
import AdminPages from '../../components/AdminPages/AdminPages';

// MaintenanceContractsAdminPages
import MaintenanceContractsAdminPages
  from '../../../maintenanceContract/pages/MaintenanceContractsAdminPages/MaintenanceContractsAdminPages';
import MaintenanceContractsAdminAddonPage
  from '../../../maintenanceContract/pages/MaintenanceContractsAdminPages/MaintenanceContractsAdminAddonPage';
import routeMaintenanceContractsAdminPages
  from '../../../maintenanceContract/pages/MaintenanceContractsAdminPages/route';
import pageMaintenanceContractsAdminPages from '../../../maintenanceContract/pages/MaintenanceContractsAdminPages/page';

// MaintenanceChecksAdminPages
import MaintenanceChecksAdminPages
  from '../../../maintenanceCheck/pages/MaintenanceChecksAdminPages/MaintenanceChecksAdminPages';
import MaintenanceChecksAdminAddonPage
  from '../../../maintenanceCheck/pages/MaintenanceChecksAdminPages/MaintenanceChecksAdminAddonPage';
import routeMaintenanceChecksAdminPages from '../../../maintenanceCheck/pages/MaintenanceChecksAdminPages/route';
import pageMaintenanceChecksAdminPages from '../../../maintenanceCheck/pages/MaintenanceChecksAdminPages/page';

// CustomersAdminPages
import CustomersAdminPages from '../../../customer/pages/CustomersAdminPages/CustomersAdminPages';
import CustomersAdminAddonPage from '../../../customer/pages/CustomersAdminPages/CustomersAdminAddonPage';
import routeCustomersAdminPages from '../../../customer/pages/CustomersAdminPages/route';
import pageCustomersAdminPages from '../../../customer/pages/CustomersAdminPages/page';

// FaultsAdminPages
import FaultsAdminPages from '../../../fault/pages/FaultsAdminPages/FaultsAdminPages';
import FaultsAdminAddonPage from '../../../fault/pages/FaultsAdminPages/FaultsAdminAddonPage';
import routeFaultsAdminPages from '../../../fault/pages/FaultsAdminPages/route';
import pageFaultsAdminPages from '../../../fault/pages/FaultsAdminPages/page';

import ReportedFaultsAdminPages from '../../../reportedFault/pages/ReportedFaultsAdminPages/ReportedFaultsAdminPages';
import ReportedFaultsAdminAddonPage
  from '../../../reportedFault/pages/ReportedFaultsAdminPages/ReportedFaultsAdminAddonPage';
import pageReportedFaultsAdminPages from '../../../reportedFault/pages/ReportedFaultsAdminPages/page';
import routeReportedFaultsAdminPages from '../../../reportedFault/pages/ReportedFaultsAdminPage/route';

// LanguagesAdminPages
import LanguagesAdminPages from '../../../language/pages/LanguagesAdminPages/LanguagesAdminPages';
import LanguagesAdminAddonPage from '../../../language/pages/LanguagesAdminPages/LanguagesAdminAddonPage';
import routeLanguagesAdminPages from '../../../language/pages/LanguagesAdminPages/route';
import pageLanguagesAdminPages from '../../../language/pages/LanguagesAdminPages/page';

// MaterialsAdminPages
import MaterialsAdminPages from '../../../material/pages/MaterialsAdminPages/MaterialsAdminPages';
import MaterialsAdminAddonPage from '../../../material/pages/MaterialsAdminPages/MaterialsAdminAddonPage';
import routeMaterialsAdminPages from '../../../material/pages/MaterialsAdminPages/route';
import pageMaterialsAdminPages from '../../../material/pages/MaterialsAdminPages/page';

// MaterialTranslationsAdminPage
import MaterialTranslationsAdminPage
  from '../../../materialTranslation/pages/MaterialTranslationsAdminPage/MaterialTranslationsAdminPage';
import routeMaterialTranslationsAdminPage from '../../../materialTranslation/pages/MaterialTranslationsAdminPage/route';
import pageMaterialTranslationsAdminPage from '../../../materialTranslation/pages/MaterialTranslationsAdminPage/page';

// RepairsAdminPages
import RepairsAdminPages from '../../../repair/pages/RepairsAdminPages/RepairsAdminPages';
import RepairsAdminAddonPage from '../../../repair/pages/RepairsAdminPages/RepairsAdminAddonPage';
import routeRepairsAdminPages from '../../../repair/pages/RepairsAdminPages/route';
import pageRepairsAdminPages from '../../../repair/pages/RepairsAdminPages/page';

// VehiclesAdminPages
import VehiclesAdminPages from '../../../vehicle/pages/VehiclesAdminPages/VehiclesAdminPages';
import VehiclesAdminAddonPage from '../../../vehicle/pages/VehiclesAdminPages/VehiclesAdminAddonPage';
import routeVehiclesAdminPages from '../../../vehicle/pages/VehiclesAdminPages/route';
import pageVehiclesAdminPages from '../../../vehicle/pages/VehiclesAdminPages/page';

// ManufacturersAdminPages
import ManufacturersAdminPages from '../../../manufacturer/pages/ManufacturersAdminPages/ManufacturersAdminPages';
import ManufacturersAdminAddonPage
  from '../../../manufacturer/pages/ManufacturersAdminPages/ManufacturersAdminAddonPage';
import routeManufacturersAdminPages from '../../../manufacturer/pages/ManufacturersAdminPages/route';
import pageManufacturersAdminPages from '../../../manufacturer/pages/ManufacturersAdminPages/page';

// TranslationsAdminPages
import TranslationsAdminPages from '../../../translation/pages/TranslationsAdminPages/TranslationsAdminPages';
import routeTranslationsAdminPages from '../../../translation/pages/TranslationsAdminPages/route';
import pageTranslationsAdminPages from '../../../translation/pages/TranslationsAdminPages/page';

// TransportLocationsAdminPages
import TransportLocationsAdminPages
  from '../../../transportLocation/pages/TransportLocationsAdminPages/TransportLocationsAdminPages';
import TransportLocationsAdminAddonPage
  from '../../../transportLocation/pages/TransportLocationsAdminPages/TransportLocationsAdminAddonPage';
import routeTransportLocationsAdminPages from '../../../transportLocation/pages/TransportLocationsAdminPages/route';
import pageTransportLocationsAdminPages from '../../../transportLocation/pages/TransportLocationsAdminPages/page';

// DevicesAdminPages
import DevicesAdminPages from '../../../device/pages/DevicesAdminPages/DevicesAdminPages';
import DevicesAdminAddonPage from '../../../device/pages/DevicesAdminPages/DevicesAdminAddonPage';
import routeDevicesAdminPages from '../../../device/pages/DevicesAdminPages/route';
import pageDevicesAdminPages from '../../../device/pages/DevicesAdminPages/page';

// InterventionsPage
import routeInterventionsPage from '../../../intervention/pages/InterventionsPage/route';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SYSTEM} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

// Helpers
const route = {
  [pageMaintenanceContractsAdminPages]: routeMaintenanceContractsAdminPages,
  [pageMaintenanceChecksAdminPages]: routeMaintenanceChecksAdminPages,
  [pageCustomersAdminPages]: routeCustomersAdminPages,
  [pageFaultsAdminPages]: routeFaultsAdminPages,
  [pageReportedFaultsAdminPages]: routeReportedFaultsAdminPages,
  [pageLanguagesAdminPages]: routeLanguagesAdminPages,
  [pageRepairsAdminPages]: routeRepairsAdminPages,
  [pageMaterialsAdminPages]: routeMaterialsAdminPages,
  [pageMaterialTranslationsAdminPage]: routeMaterialTranslationsAdminPage,
  [pageVehiclesAdminPages]: routeVehiclesAdminPages,
  [pageManufacturersAdminPages]: routeManufacturersAdminPages,
  [pageTranslationsAdminPages]: routeTranslationsAdminPages,
  [pageTransportLocationsAdminPages]: routeTransportLocationsAdminPages,
  [pageDevicesAdminPages]: routeDevicesAdminPages,
};

class AdminLayoutContainer extends Component {
  static propTypes = {
    page: PropTypes.string,
    history: PropTypes.object,
    dispatch: PropTypes.func,
    user: PropTypes.object,
  };

  state = {
    inited: false,
  };

  componentDidMount() {
    const {user, history} = this.props;
    if (
      !hasPermission({
        user,
        roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
      })
    ) {
      history.replace(routeInterventionsPage());
      return;
    }

    this.setState({inited: true});
  }

  componentWillUnmount() {
    this.props.dispatch(setLayoutAct({subpage: null}));
  }

  pages = () => [
    {
      id: pageCustomersAdminPages,
      label: 'Customers',
    },
    {
      id: pageDevicesAdminPages,
      label: 'Devices',
    },
    {
      id: pageFaultsAdminPages,
      label: 'Faults',
    },
    {
      id: pageLanguagesAdminPages,
      label: 'Languages',
    },
    {
      id: pageMaintenanceChecksAdminPages,
      label: 'Maintenance Checks',
    },
    {
      id: pageManufacturersAdminPages,
      label: 'Manufacturers',
    },
    {
      id: pageMaterialsAdminPages,
      label: 'Materials',
    },
    {
      id: pageMaterialTranslationsAdminPage,
      label: 'Material Translations',
    },
    {
      id: pageMaintenanceContractsAdminPages,
      label: 'Maintenance Contracts',
    },
    {
      id: pageRepairsAdminPages,
      label: 'Repairs',
    },
    {
      id: pageReportedFaultsAdminPages,
      label: 'Reported Faults',
    },
    {
      id: pageTranslationsAdminPages,
      label: 'Static Translations',
    },
    {
      id: pageTransportLocationsAdminPages,
      label: 'Transport Locations',
    },
    {
      id: pageVehiclesAdminPages,
      label: 'Vehicles',
    },
  ];

  onPage = (page) => this.props.history.push(route[page]());

  renderHeader = () => (
    <Fragment>
      <AdminHeader>Administration</AdminHeader>
      <AdminPages
        page={this.props.page}
        pages={this.pages()}
        onPage={this.onPage}
      >
        <Switch>
          <Route
            path={routeMaintenanceContractsAdminPages()}
            component={MaintenanceContractsAdminAddonPage}
            exact
          />
          <Route
            path={routeMaintenanceChecksAdminPages()}
            component={MaintenanceChecksAdminAddonPage}
            exact
          />
          <Route
            path={routeCustomersAdminPages()}
            component={CustomersAdminAddonPage}
            exact
          />
          <Route
            path={routeFaultsAdminPages()}
            component={FaultsAdminAddonPage}
            exact
          />
          <Route
            path={routeReportedFaultsAdminPages()}
            component={ReportedFaultsAdminAddonPage}
            exact
          />
          <Route
            path={routeRepairsAdminPages()}
            component={RepairsAdminAddonPage}
            exact
          />
          <Route
            path={routeMaterialsAdminPages()}
            component={MaterialsAdminAddonPage}
            exact
          />
          <Route
            path={routeVehiclesAdminPages()}
            component={VehiclesAdminAddonPage}
            exact
          />
          <Route
            path={routeManufacturersAdminPages()}
            component={ManufacturersAdminAddonPage}
          />
          <Route
            path={routeTransportLocationsAdminPages()}
            component={TransportLocationsAdminAddonPage}
          />
          <Route
            path={routeDevicesAdminPages()}
            component={DevicesAdminAddonPage}
          />
          <Route
            path={routeLanguagesAdminPages()}
            component={LanguagesAdminAddonPage}
          />
        </Switch>
      </AdminPages>
    </Fragment>
  );

  render() {
    // TODO: I18n
    const {inited} = this.state;
    return !inited ? null : (
      <Fragment>
        <ContentLayout header={this.renderHeader()}>
          <Switch>
            <Route
              path={routeMaintenanceContractsAdminPages()}
              component={MaintenanceContractsAdminPages}
            />
            <Route
              path={routeMaintenanceChecksAdminPages()}
              component={MaintenanceChecksAdminPages}
            />
            <Route
              path={routeCustomersAdminPages()}
              component={CustomersAdminPages}
            />
            <Route
              path={routeFaultsAdminPages()}
              component={FaultsAdminPages}
            />
            <Route
              path={routeReportedFaultsAdminPages()}
              component={ReportedFaultsAdminPages}
            />
            <Route
              path={routeRepairsAdminPages()}
              component={RepairsAdminPages}
            />
            <Route
              path={routeMaterialsAdminPages()}
              component={MaterialsAdminPages}
            />
            <Route
              path={routeMaterialTranslationsAdminPage()}
              component={MaterialTranslationsAdminPage}
            />
            <Route
              path={routeVehiclesAdminPages()}
              component={VehiclesAdminPages}
            />
            <Route
              path={routeManufacturersAdminPages()}
              component={ManufacturersAdminPages}
            />
            <Route
              path={routeTransportLocationsAdminPages()}
              component={TransportLocationsAdminPages}
            />
            <Route
              path={routeLanguagesAdminPages()}
              component={LanguagesAdminPages}
            />
            <Route
              path={routeTranslationsAdminPages()}
              component={TranslationsAdminPages}
            />
            <Route
              path={routeDevicesAdminPages()}
              component={DevicesAdminPages}
            />
          </Switch>
        </ContentLayout>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  page: state.layout.subpage,
  user: state.auth.user,
}))(withRouter(AdminLayoutContainer));
