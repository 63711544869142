import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal} from 'doorson-ui';
import {SimpleSignature} from 'doorson-ui';

const DocumentSimpleSignature = ({
  visible,
  title,
  confirmLabel,
  closeLabel,
  onSign,
  onClear,
  onClose,
}) => (
  <Modal size="large" title={title} visible={visible} onClose={onClose}>
    <SimpleSignature
      confirm={confirmLabel}
      close={closeLabel}
      onSign={onSign}
      onClear={onClear}
    />
  </Modal>
);

DocumentSimpleSignature.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  confirmLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  onSign: PropTypes.func,
  onClear: PropTypes.func,
  onClose: PropTypes.func,
};

export default DocumentSimpleSignature;
