import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Components
import Profile from '../../components/Profile/Profile';

// Libs
import displayRole from '../../../user/roles/display.role.user';

class ProfileContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
    dispatch: PropTypes.func,
  };

  render() {
    const {user} = this.props;
    return !user ? null : (
      <Profile
        profileLabel="Profile"
        firstNameLabel="First Name"
        lastNameLabel="Last Name"
        emailLabel="Email"
        rolesLabel="Roles"
        user={user}
        roles={displayRole(user)}
      />
    );
  }
}

export default connect((state) => ({user: state.auth.user}))(ProfileContainer);
