import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import PreLoader from '../../../layout/components/PreLoader/PreLoader';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';

import Container from './components/Container';
import GpsNavigation from "../../../layout/components/GpsNavigation/GpsNavigation";
import getCustomerName from "../../../customer/lib/getName.lib.customer";
import {format, toDate} from "date-fns";


// TODO: I18N
const ServiceItems = ({
                        loading,
                        more,
                        scroller,
                        sort,
                        columns,
                        serviceItems,
                        onServiceItem,
                        onSort,
                        onMore,
                      }) => (
  <Container>
    <PreLoader
      scroller={scroller}
      loading={loading}
      showLoader={!!serviceItems.length}
      more={more}
      onMore={onMore}
    >
      {<Table columns={columns} loading={loading} sort={sort} onSort={onSort}>
        {(TableRow) =>
          serviceItems.map((serviceItem) => (
            <TableRow
              key={serviceItem.id}>
              {(TableCol) => [
                <TableCol
                  key="doorSerial"
                  onClick={() => onServiceItem(serviceItem)}
                >
                  {serviceItem.doorSerial}
                </TableCol>,
                <TableCol
                  key="doorType"
                  onClick={() => onServiceItem(serviceItem)}
                >
                  {`${serviceItem.product.manufacturer.name} ${serviceItem.product.name}`}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="customer"
                  onClick={() => onServiceItem(serviceItem)}
                >
                  {getCustomerName(serviceItem.customer)}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="customerBranch"
                  onClick={() => onServiceItem(serviceItem)}
                >
                  {!!serviceItem.customerBranch && !!serviceItem.customerBranch.branchLocation && (
                    <span>
                      {!!serviceItem.customerBranch.branchName && (
                        <span>
                          {serviceItem.customerBranch.branchName}
                          <br/>
                        </span>
                      )}
                      {serviceItem.customerBranch.branchLocation.address}
                      <br/>
                      {serviceItem.customerBranch.branchLocation.city}
                      <GpsNavigation location={serviceItem.customerBranch.branchLocation}/>
                    </span>
                  )}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="indoorLocation"
                  onClick={() => onServiceItem(serviceItem)}
                > {serviceItem.indoorLocation}</TableCol>,
                <TableCol
                  key="lastMaintenanceDate"
                  onClick={() => onServiceItem(serviceItem)}
                > {serviceItem.lastMaintenanceDate && format(toDate(serviceItem.lastMaintenanceDate), 'dd.MM.yyyy')}</TableCol>,
                <TableCol
                  key="distance"
                  onClick={() => onServiceItem(serviceItem)}
                > {((serviceItem.distance && ((serviceItem.distance/1000).toFixed(2) + ' km away')) || '-')}</TableCol>,
              ]}
            </TableRow>
          ))
        }
      </Table>}
    </PreLoader>
    {!loading && !serviceItems.length && (
      <NoDataFound>No Doors found</NoDataFound>
    )}
  </Container>
);

ServiceItems.propTypes = {
  loading: PropTypes.bool,
  more: PropTypes.bool,
  scroller: PropTypes.object,
  sort: PropTypes.object,
  columns: PropTypes.array,
  serviceItems: PropTypes.array,
  onServiceItem: PropTypes.func,
  onSort: PropTypes.func,
  onMore: PropTypes.func,
};

export default ServiceItems;
