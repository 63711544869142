import styled from 'styled-components';
import {Link} from 'react-router-dom';

const Container = styled(Link)`
  width: 180px;
  display: block;
  margin: 0px;
  padding: 0px;
`;

export default Container;
