import React from 'react';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// Containers
import MaterialTranslationsContainer from '../../containers/MaterialTranslationsContainer/MaterialTranslationsContainer';

// Consts
import page from './page';

const MaterialTranslationsAdminPage = () => (
  <SubPage page={page}>
    <MaterialTranslationsContainer />
  </SubPage>
);

export default MaterialTranslationsAdminPage;
