import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// MaintenanceContractsAdminPage
import MaintenanceContractsAdminPage from '../MaintenanceContractsAdminPage/MaintenanceContractsAdminPage';
import routeMaintenanceContractsAdminPage from '../MaintenanceContractsAdminPage/route';

// MaintenanceContractPage
import MaintenanceContractPage from '../MaintenanceContractPage/MaintenanceContractPage';
import routeMaintenanceContractPage from '../MaintenanceContractPage/route';

// NewMaintenanceContractPage
import NewMaintenanceContractPage from '../NewMaintenanceContractPage/NewMaintenanceContractPage';
import routeNewMaintenanceContractPage from '../NewMaintenanceContractPage/route';

// Consts
import page from './page';

const MaintenanceContractsAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeMaintenanceContractsAdminPage()}
        component={MaintenanceContractsAdminPage}
        exact
      />
      <Route
        path={routeNewMaintenanceContractPage()}
        component={NewMaintenanceContractPage}
        exact
      />
      <Route
        path={routeMaintenanceContractPage()}
        component={MaintenanceContractPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default MaintenanceContractsAdminPages;
