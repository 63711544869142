import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Point from './components/Point';
import Select from './components/Select';

const Marker = ({selected, color}) => (
  <Container>
    <Point selected={selected} color={color} />
    {selected && <Select color={color} />}
  </Container>
);

Marker.propTypes = {
  selected: PropTypes.bool,
  color: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export default Marker;
