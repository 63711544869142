import {subMinutes, subHours, subDays, toDate, getTime} from 'date-fns';

const alertOptions = (startDate) => {
  const date = toDate(startDate);
  return [
    {
      value: null,
      label: 'Not Set',
    },
    {
      value: getTime(date),
      label: 'At time of event',
    },
    {
      value: getTime(subMinutes(date, 5)),
      label: '5 minutes before',
    },
    {
      value: getTime(subMinutes(date, 30)),
      label: '30 minutes before',
    },
    {
      value: getTime(subHours(date, 1)),
      label: '1 hour before',
    },
    {
      value: getTime(subDays(date, 1)),
      label: '1 day before',
    },
    {
      value: getTime(subDays(date, 2)),
      label: '2 days before',
    },
  ];
};
export default alertOptions;
