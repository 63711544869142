import styled from 'styled-components';

const FolderContainer = styled.div`
  width: 33.33%;
  max-width: 33.33%;
  min-width: 33.33%;
  padding: 1px;
`;

export default FolderContainer;
