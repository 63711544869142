import {profile} from 'f3tch';

// Constants
import url from '../constants/url.const.api';

// Errors
import isApiError from '../errors/isApiError.error.api';

// Profiles
import headers from '../headers/form.header.api';

const signIn = () =>
  profile(url)
    .headers(headers({authorize: false}))
    .responder(async (response) => {
      await isApiError(response);
      const data = await response.json();
      return data;
    });
export default signIn;
