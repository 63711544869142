import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateMaterialApi = (maintenanceDoorID, materialID, material) =>
  f3tch(`/maintenance-doors/${maintenanceDoorID}/materials/${materialID}`)
    .profile(profile())
    .body(material)
    .put();
export default updateMaterialApi;
