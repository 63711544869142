import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button} from 'doorson-ui';

// Libs
import sortByString from '../../../lib/sortByString';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SYSTEM,} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';
import isActive from "../../../user/lib/isActive.lib.user";

// TODO: I18N
const QuickActions = ({
                        loading,
                        maintenance,
                        priorityColor,
                        users,
                        user,
                        onAction,
                      }) => {
  const isSuperUser = hasPermission({
    user,
    roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
  });
  if (maintenance.closed) return null;
  if (maintenance.resolved)
    return (
      <Button
        theme="black"
        onClick={() => (isSuperUser ? onAction('close', {maintenance}) : null)}
        loading={loading}
        disabled={!isSuperUser}
      >
        Close
      </Button>
    );
  if (!!maintenance.assignees.find((assignee) => assignee.id === user.id))
    return (
      <Button
        loading={loading}
        preIcon="arrow-left"
        onClick={() => onAction('unassign', {maintenanceID: maintenance.id})}
        theme={priorityColor}
      >
        Unassign me
      </Button>
    );
  if (!!maintenance.assignees.length)
    return maintenance.assignees.length === 1 ? (
      <Button subtext="Assigned" theme={priorityColor}>
        {maintenance.assignees[0].firstName} {maintenance.assignees[0].lastName}
      </Button>
    ) : (
      <Button subtext="Assigned" theme={priorityColor}>
        To {maintenance.assignees.length} employees
      </Button>
    );
  return isSuperUser ? (
    <Button
      loading={loading}
      onClick={(userID) =>
        onAction('assign', {maintenanceID: maintenance.id, userID})
      }
      theme={priorityColor}
      options={[...users]
        .sort(sortByString({key: 'firstName', direction: 'asc'}))
        .filter(isActive)
        .map(({id, firstName, lastName}) => ({
          value: id,
          label: `${firstName} ${lastName}`,
        }))}
      outline
    >
      Assign task to
    </Button>
  ) : (
    <Button
      loading={loading}
      postIcon="arrow-right"
      onClick={() =>
        onAction('assign', {maintenanceID: maintenance.id, userID: user.id})
      }
      theme={priorityColor}
      outline
    >
      Assign to me
    </Button>
  );
};

QuickActions.propTypes = {
  loading: PropTypes.bool,
  maintenance: PropTypes.object,
  priorityColor: PropTypes.string,
  users: PropTypes.array,
  user: PropTypes.object,
  onAction: PropTypes.func,
};

export default QuickActions;
