import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const sendContractInterventionApi = (interventionID, contractReceivers, query = {rel: '*'}) =>
  f3tch(`/interventions/${interventionID}/send-contract`)
    .profile(profile())
    .query(query)
    .body({contractReceivers})
    .put();
export default sendContractInterventionApi;
