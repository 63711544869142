import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Form, Input, Modal, Row} from 'doorson-ui';

const NewReportedFaultModal = ({
                                 title,
                                 nameLabel,
                                 saveLabel,
                                 visible,
                                 loading,
                                 name,
                                 onChange,
                                 onClose,
                                 onSave,
                               }) => (
  <Modal visible={visible} title={title} onClose={onClose} size="small">
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Input value={name} onChange={onChange('name')} disabled={loading}>
            {nameLabel}
          </Input>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

NewReportedFaultModal.propTypes = {
  title: PropTypes.string,
  nameLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default NewReportedFaultModal;
