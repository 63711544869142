import styled from 'styled-components';

const Thumbnails = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
`;

export default Thumbnails;
