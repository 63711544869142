import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
// import Icon from './components/Icon';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import PreLoader from '../../../layout/components/PreLoader/PreLoader';

import Container from './components/Container';

// Lib
import getName from '../../lib/getName.lib.customer';

const AdminCustomers = ({
                          loading,
                          more,
                          scroller,
                          sort,
                          columns,
                          customers,
                          onCustomer,
                          onSort,
                          onMore,
                        }) => (
  <Container>
    <PreLoader
      scroller={scroller}
      loading={loading}
      showLoader={!!customers.length}
      more={more}
      onMore={onMore}
    >
      <Table columns={columns} loading={loading} sort={sort} onSort={onSort}>
        {(TableRow) =>
          customers.map((customer) => (
            <TableRow key={customer.id} onClick={onCustomer(customer)}>
              {(TableCol) => [
                <TableCol smallPadding key="name" span={2}>
                  {getName(customer)}
                </TableCol>,
                <TableCol smallPadding key="address" span={2}>
                  {customer.location.address}
                  <br/>
                  {customer.location.zip} {customer.location.city}
                  <br/>
                  {customer.location.countryCode}
                </TableCol>,
                <TableCol smallPadding key="type" span={2}>
                  {customer.type}
                </TableCol>,
              ]}
            </TableRow>
          ))
        }
      </Table>
    </PreLoader>
    {!loading && !customers.length && (
      <NoDataFound>No customers found</NoDataFound>
    )}
  </Container>
);

AdminCustomers.propTypes = {
  columns: PropTypes.array,
  customers: PropTypes.array,
  onCustomer: PropTypes.func,
  loading: PropTypes.bool,
  more: PropTypes.bool,
  scroller: PropTypes.object,
  sort: PropTypes.object,
  onSort: PropTypes.func,
  onMore: PropTypes.func,
};

export default AdminCustomers;
