import {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Alertify
import {alertify} from 'doorson-ui';

// Actions
import {set as setAct} from '../../redux/actions';

// Routes
import maintenanceRoute from '../../pages/MaintenanceDoorPage/route';

class MaintenanceDoorContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    maintenanceDoorID: PropTypes.string,
    history: PropTypes.object,
    maintenance: PropTypes.object,
    maintenanceDoor: PropTypes.object,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    this.mounted = true;
    this.getMaintenanceDoor();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  redirect = () => {
    // TODO: I18N
    const {history, maintenanceID} = this.props;
    alertify.warning('This door does not exists');
    history.replace(maintenanceRoute(maintenanceID));
  };

  getMaintenanceDoor = async () => {
    const {maintenanceDoorID, maintenance, dispatch} = this.props;
    const maintenanceDoor = !maintenance
      ? null
      : [...maintenance.doors].find((door) => door.id === maintenanceDoorID);
    if (!maintenanceDoor) return this.redirect();
    dispatch(setAct({maintenanceDoor}));
  };

  render() {
    const {children, maintenance, maintenanceDoor} = this.props;
    return !maintenance || !maintenanceDoor ? null : children;
  }
}

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
  maintenanceDoor: state.maintenanceDoor.maintenanceDoor,
}))(withRouter(MaintenanceDoorContainer));
