import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button} from 'doorson-ui';
import Container from './components/Container';
import ButtonContainer from './components/ButtonContainer';
import Label from './components/Label';

const MaintenanceResolve = ({
  resolveLabel,
  resolvedLabel,
  closeLabel,
  closedLabel,
  notAssignedLabel,

  loading,
  canClose,
  canResolve,
  resolved,
  closed,
  onResolve,
  onClose,
}) => (
  <Container>
    {closed ? (
      <Label>{closedLabel}</Label>
    ) : resolved ? (
      canClose ? (
        <ButtonContainer>
          <Button theme="orange" loading={loading} onClick={onClose}>
            {closeLabel}
          </Button>
        </ButtonContainer>
      ) : (
        <Label>{resolvedLabel}</Label>
      )
    ) : canResolve ? (
      <ButtonContainer>
        <Button theme="orange" loading={loading} onClick={onResolve}>
          {resolveLabel}
        </Button>
      </ButtonContainer>
    ) : (
      <Label>{notAssignedLabel}</Label>
    )}
  </Container>
);

MaintenanceResolve.propTypes = {
  resolvedLabel: PropTypes.node,
  resolveLabel: PropTypes.node,
  closedLabel: PropTypes.node,
  closeLabel: PropTypes.node,
  notAssignedLabel: PropTypes.node,

  loading: PropTypes.bool,
  canClose: PropTypes.bool,
  canResolve: PropTypes.bool,
  resolved: PropTypes.bool,
  closed: PropTypes.bool,
  onResolve: PropTypes.func,
  onClose: PropTypes.func,
};

export default MaintenanceResolve;
