import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import Dropzone from 'react-dropzone';
import {Row, Column} from 'doorson-ui';
import {Button} from 'doorson-ui';

import Container from './components/Container';
import Content from './components/Content';
import UploadLabel from './components/UploadLabel';
import LoadingContainer from './components/LoadingContainer';
import Loader from './components/Loader';
import UpdateContainer from './components/UpdateContainer';
import ImageContainer from './components/ImageContainer';
import Image from './components/Image';
import UpdateContent from './components/UpdateContent';

class SimpleUploader extends Component {
  static propTypes = {
    reset: PropTypes.bool,
    loading: PropTypes.bool,
    label: PropTypes.string,
    removeLabel: PropTypes.string,
    file: PropTypes.object,
    accept: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    accept: 'image/*',
  };

  state = {
    preview: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.reset && this.props.reset) this.setState({preview: null});
  }

  onFile = ([file]) => {
    const {loading, onChange} = this.props;
    if (loading) return;
    onChange(file);
    this.setState({preview: URL.createObjectURL(file)});
  };

  onCancel = () => {
    const {loading, onChange} = this.props;
    if (loading) return;
    onChange(null);
    this.setState({preview: null});
  };

  render() {
    const {loading, accept, label, file, removeLabel} = this.props;
    const {preview} = this.state;
    return (
      <Container>
        <Content>
          {!file ? (
            <Dropzone
              onDrop={this.onFile}
              accept={accept}
              multiple={false}
              capture="environment"
            >
              {({getRootProps, getInputProps}) => (
                <UploadLabel {...getRootProps()}>
                  <input {...getInputProps()} />
                  {label}
                </UploadLabel>
              )}
            </Dropzone>
          ) : (
            <UpdateContainer>
              <ImageContainer>
                <Image src={preview} />
              </ImageContainer>
              <UpdateContent>
                <Row>
                  <Column size={1 / 2}>
                    <Button
                      theme="grey"
                      onClick={this.onCancel}
                      disabled={loading}
                      outline
                    >
                      {removeLabel}
                    </Button>
                  </Column>
                </Row>
              </UpdateContent>
            </UpdateContainer>
          )}
          {loading && (
            <LoadingContainer>
              <Loader className="mdi mdi-loading mdi-spin" />
            </LoadingContainer>
          )}
        </Content>
      </Container>
    );
  }
}

export default SimpleUploader;
