import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {getTime} from 'date-fns';
import {isEmail} from 'validator';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import createApi from '../../api/create.api.customer';
import searchCustomerApi from '../../api/list.api.customer';
import createCustomerBranchApi from '../../../customerBranch/api/create.api.customerBranch';
import getParsedCoodrinatesApi from '../../../maps/api/getParsedCoodrinates.api.maps';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminCustomerButton from '../../components/NewAdminCustomerButton/NewAdminCustomerButton';
import NewCustomerModal from '../../components/NewCustomerModal/NewCustomerModal';

// Routes
import customerRoute from '../../pages/CustomerAdminPage/route';

class NewCustomerContainer extends Component {
  static propTypes = {
    countries: PropTypes.array,
    history: PropTypes.object,
  };

  static CUSTOMER_ATTRIBUTES = {
    error: {},
    customer: null,
    firstName: '',
    lastName: '',
    company: '',
    type: 'business',
    registrationNumber: '',
    vatNumber: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    phone: '',
    email: '',
    customers: [],
    searchKey: null,
    searchingForCustomer: false,
  };

  lastSearch = 0;

  state = {
    loading: false,
    visible: false,
    ...this.constructor.CUSTOMER_ATTRIBUTES,
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    if (key === 'type')
      return this.setState({
        ...this.constructor.CUSTOMER_ATTRIBUTES,
        type: val,
      });
    const error = Object.entries(this.state.error).reduce(
      (combined, [errorKey, value]) =>
        key === errorKey ? combined : {...combined, [errorKey]: value},
      {}
    );
    const state = {[key]: val, error};

    if (['company', 'registrationNumber'].includes(key)) this.search(val, key);

    state.customer = [
      'company',
      'registrationNumber',
      'firstName',
      'lastName',
    ].includes(key)
      ? null
      : this.state.customer;

    this.setState(state);
  };

  search = async (search, searchKey) => {
    const treshold = searchKey === 'registrationNumber' ? 10 : 3;

    this.setState({searchKey, searchingForCustomer: true});

    if (search.trim().length < treshold)
      return this.setState({customers: [], searchingForCustomer: false});

    const lastSearch = getTime(new Date());
    this.lastSearch = lastSearch;
    try {
      const customers = await searchCustomerApi({
        filter: encodeURIComponent(`${searchKey}:LIKEIC:%${search}%,id:ISNULL`),
      });
      if (lastSearch !== this.lastSearch) return;
      this.setState({
        customers: [...customers].filter(({id}) => !id),
        searchingForCustomer: false,
      });
    } catch (error) {
      this.setState({searchingForCustomer: false});
      // Do nothing for now
    }
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, ...this.constructor.CUSTOMER_ATTRIBUTES});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, description: ''});
  };

  getCountryByCode = (code) =>
    [...this.props.countries].find((country) => country.code === code);

  customers = () =>
    [...this.state.customers]
      .filter(({registrationNumber}) => !!registrationNumber)
      .map(({company, registrationNumber}) => ({
        value: registrationNumber,
        label: company,
      }));

  types = () => [
    {
      value: 'business',
      label: 'Business customer',
    },
    {
      value: 'private',
      label: 'Private customer',
    },
  ];

  countries = () =>
    [...this.props.countries].map(({code, name}) => ({
      value: code,
      label: name,
    }));

  onSearchedCustomer = (registrationNumber) => {
    const customer = [...this.state.customers].find(
      (c) => c.registrationNumber === registrationNumber
    );
    if (!customer) return;

    this.setState({
      error: {},
      customer: customer,
      company: customer.company,
      registrationNumber: customer.registrationNumber,
      address: customer.location.address,
      zip: customer.location.zip,
      city: customer.location.city,
      country: customer.location.countryCode,
    });
  };

  save = async () => {
    const {
      loading,
      customer: searchedCustomer,
      firstName,
      lastName,
      company,
      type,
      registrationNumber,
      vatNumber,
      address,
      zip,
      city,
      country: countryCode,
      phone,
      email,
    } = this.state;

    if (loading) return;

    const country = this.getCountryByCode(countryCode);
    if (!country) return;

    if (!!searchedCustomer && !!searchedCustomer.id)
      return alertify.warning('This customer already exits');

    // TODO: I18N
    const error = {};
    if (type === 'business' && !company.trim().length)
      error.company = 'Should not be empty';
    if (type === 'business' && !registrationNumber.trim().length)
      error.registrationNumber = 'Should not be empty';

    if (type === 'private' && !firstName.trim().length)
      error.firstName = 'Should not be empty';
    if (type === 'private' && !lastName.trim().length)
      error.lastName = 'Should not be empty';

    if (!address.trim().length) error.address = 'Should not be empty';
    if (!zip.trim().length) error.zip = 'Should not be empty';
    if (!city.trim().length) error.city = 'Should not be empty';
    if (!countryCode.trim().length) error.country = 'Should not be empty';
    if (!phone.trim().length) error.phone = 'Should not be empty';
    if (!!email && !isEmail(email))
      error.email = 'Should be a valid email address';
    if (vatNumber && vatNumber.match(/([A-Z]{2})[0-9]{8}/) === null) {
      error.vatNumber = 'VAT number must be of format SI12345678';
    }

    if (!!Object.keys(error).length) {
      this.setState({error});
      alertify.warning('Insert all the required customer info properly');
      return;
    }

    this.setState({loading: true});

    const rawCustomer =
      type === 'business'
        ? {
            company,
            type,
            registrationNumber,
            vatNumber,
            phone,
            email,
            location: {
              address,
              zip,
              city,
              countryCode,
            },
          }
        : {
            firstName,
            lastName,
            vatNumber,
            type,
            phone,
            email,
            location: {
              address,
              zip,
              city,
              countryCode,
            },
          };

    const customerBranch = {
      branchName: 'HQ',
      branchLocation: {
        address,
        zip,
        city,
        countryCode,
      },
    };

    const coordinates = await getParsedCoodrinatesApi(
      `${address}, ${zip} ${city}, ${country.name}`
    );

    rawCustomer.location.geo = coordinates;
    customerBranch.branchLocation.geo = coordinates;

    try {
      const customer = await createApi(rawCustomer);
      customerBranch.customerId = customer.id;
      await createCustomerBranchApi(customerBranch);
      this.props.history.push(customerRoute(customer.id));
    } catch (error) {
      alertify.error('Could not create a customer. Try again');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {
      loading,
      visible,
      error,
      type,
      company,
      registrationNumber,
      vatNumber,
      firstName,
      lastName,
      address,
      zip,
      city,
      country,
      phone,
      email,
      customer,
      searchKey,
      searchingForCustomer,
    } = this.state;
    return (
      <Fragment>
        <NewAdminCustomerButton onClick={this.show}>
          New Customer
        </NewAdminCustomerButton>
        <NewCustomerModal
          title="New Customer"
          companyLabel="Company name"
          registrationNumberLabel="Registration number"
          vatNumberLabel="VAT number"
          firstNameLabel="First Name"
          lastNameLabel="Last Name"
          addressLabel="Address"
          zipLabel="Zip Code"
          cityLabel="City"
          countryLabel="Country"
          phoneLabel="Phone"
          emailLabel="Email"
          noSuggestionLabel="No suggestions found"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          types={this.types()}
          customers={this.customers()}
          error={error}
          type={type}
          company={company}
          registrationNumber={registrationNumber}
          vatNumber={vatNumber}
          firstName={firstName}
          lastName={lastName}
          address={address}
          zip={zip}
          city={city}
          country={country}
          phone={phone}
          email={email}
          searchKey={searchKey}
          searchingForCustomer={searchingForCustomer}
          countries={this.countries()}
          customerSelected={!!customer}
          onSearchedCustomer={this.onSearchedCustomer}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.save}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  countries: state.country.countries,
}))(withRouter(NewCustomerContainer));
