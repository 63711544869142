import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Table = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background: ${white};
`;

export default Table;
