import React from 'react';
import PropTypes from 'prop-types';
//components
import VehicleInfo from './components/VehicleInfo';
import VehicleInfoDisabled from './components/VehicleInfoContainer';
import Img from './components/Img';
import VehicleNo from './components/VehicleNo';
import {Button} from 'doorson-ui';
//containers
import Container from './components/Container';
import VehicleContainer from './components/VehicleContainer';
import DisabledContainer from './components/DisabledContainer';
import DisabledVehicleContainer from './components/DisabledVehicleContainer';
import VehicleImageContainer from './components/VehicleImageContainer';
import VehicleNoContainer from './components/VehicleNoContainer';
import ButtonContainer from './components/ButtonContainer';
import ButtonAbsoluteContainer from './components/ButtonAbsoluteContainer';
// assets
import vehicleImg from './../../assets/select-vehicle.png';
import VehicleInfoContainer from './components/VehicleInfoContainer';

const SelectVehicle = ({onClick, vehicles}) => (
  <Container>
    <VehicleContainer onClick={() => onClick(null)}>
      In the office
    </VehicleContainer>
    {vehicles.map((vehicle) =>
      !!vehicle.rentals.length
        ? vehicleDisabled(vehicle, onClick)
        : vehicleEnabled(vehicle, onClick)
    )}
  </Container>
);

const vehicleEnabled = (vehicle, onClick) => {
  return (
    <VehicleContainer key={vehicle.id} onClick={() => onClick(vehicle)}>
      <VehicleInfo>
        {vehicle.make} {vehicle.model}
      </VehicleInfo>
      <VehicleInfo bold>{vehicle.licensePlate}</VehicleInfo>
      <VehicleImageContainer>
        <Img src={vehicleImg} />
        <VehicleNoContainer>
          <VehicleNo>{vehicle.label}</VehicleNo>
        </VehicleNoContainer>
      </VehicleImageContainer>
    </VehicleContainer>
  );
};

const vehicleDisabled = (vehicle, onClick) => {
  return (
    <DisabledContainer key={vehicle.id}>
      <DisabledVehicleContainer key={vehicle.id} selected={vehicle.selected}>
        <VehicleInfoContainer>
          <div>
            <VehicleInfo>
              {vehicle.make} {vehicle.model}
            </VehicleInfo>
            <VehicleInfo bold>{vehicle.licensePlate}</VehicleInfo>
          </div>
          <div>
            <VehicleInfoDisabled>Driven by</VehicleInfoDisabled>
            {vehicle.rentals.map((rental) => (
              <VehicleInfoDisabled key={rental.id}>
                {rental.commuter.firstName} {rental.commuter.lastName}
              </VehicleInfoDisabled>
            ))}
          </div>
        </VehicleInfoContainer>
        <VehicleImageContainer imgUrl={vehicleImg}>
          <Img src={vehicleImg} />
          <VehicleNoContainer>
            <VehicleNo selected={vehicle.selected}>{vehicle.label}</VehicleNo>
          </VehicleNoContainer>
        </VehicleImageContainer>
      </DisabledVehicleContainer>
      <ButtonAbsoluteContainer>
        <ButtonContainer>
          <Button
            outline
            size="normal"
            theme="charcoal"
            hoverColor="orange"
            onClick={() => onClick(vehicle)}
          >
            I am passenger
          </Button>
        </ButtonContainer>
      </ButtonAbsoluteContainer>
    </DisabledContainer>
  );
};

SelectVehicle.propTypes = {
  onClick: PropTypes.func,
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      licensePlate: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool,
      rentals: PropTypes.array,
    })
  ),
};

export default SelectVehicle;
