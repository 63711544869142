import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, Textarea} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';

const UpdateDeviceModal = ({
  title,
  nameLabel,
  detailsLabel,
  saveLabel,
  visible,
  loading,
  name,
  details,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="small">
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Input value={name} onChange={onChange('name')} disabled={loading}>
            {nameLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Textarea
            required={false}
            value={details}
            type="text"
            minRows={3}
            error={null}
            disabled={loading}
            onChange={onChange('details')}>
            {detailsLabel}
          </Textarea>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

UpdateDeviceModal.propTypes = {
  title: PropTypes.string,
  nameLabel: PropTypes.string,
  detailsLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  details: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default UpdateDeviceModal;
