import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const PdfDownload = styled.i`
  display: block;
  color: ${darkGrey};
  pointer-events: none;
  font-size: 20px;
`;

export default PdfDownload;
