import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createMaintenanceContractApi = (maintenanceContract) =>
  f3tch('/maintenance-contracts')
    .profile(profile())
    .body(maintenanceContract)
    .post();
export default createMaintenanceContractApi;
