import styled from 'styled-components';

// Components
import Icon from '../../../../layout/components/Icon/Icon';

const DocumentIcon = styled(Icon)`
  width: 100%;
`;

export default DocumentIcon;
