import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  border: 2px dashed ${grey};
  padding: 4px;
`;

export default ImageContainer;
