import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkerGrey, black} = colors;

const Versions = styled.div`
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  color: ${({alternateColor}) => (alternateColor ? black : darkerGrey)};
  font-size: 10px;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Versions;
