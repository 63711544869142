import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';
import {Select} from 'doorson-ui';

const UpdateCustomerBranchModal = ({
  title,
  branchNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  saveLabel,
  deleteLabel,
  deleteEnabled,

  countries,

  visible,
  saving,
  deleting,
  error,
  branchName,
  address,
  zip,
  city,
  countryCode,

  onChange,
  onClose,
  onSave,
  onDelete,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="large">
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Input
            value={branchName}
            onChange={onChange('branchName')}
            error={error.branchName}
            disabled={saving || deleting}
            required
          >
            {branchNameLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Input
            value={address}
            onChange={onChange('address')}
            error={error.address}
            disabled={saving || deleting}
            required
          >
            {addressLabel}
          </Input>
        </Column>
      </Row>
      <Row>
        <Column size={1 / 3}>
          <Input
            value={zip}
            onChange={onChange('zip')}
            error={error.zip}
            disabled={saving || deleting}
            required
          >
            {zipLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Input
            value={city}
            onChange={onChange('city')}
            error={error.city}
            disabled={saving || deleting}
            required
          >
            {cityLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Select
            value={countryCode}
            options={countries}
            onChange={onChange('country')}
            error={error.country}
            disabled={saving || deleting}
            required
          >
            {countryLabel}
          </Select>
        </Column>
      </Row>
    </Form>
    <Row>
      <Column size={1 / 4}>
        {!!deleteEnabled && <Button
          theme="grey"
          loading={deleting}
          disabled={saving}
          onClick={onDelete}
        >
          {deleteLabel}
        </Button>}
      </Column>
      <Column size={3 / 4}>
        <Button disabled={deleting} loading={saving} onClick={onSave}>
          {saveLabel}
        </Button>
      </Column>
    </Row>
  </Modal>
);

UpdateCustomerBranchModal.propTypes = {
  title: PropTypes.string,
  branchNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  saveLabel: PropTypes.string,
  deleteLabel: PropTypes.string,

  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),

  visible: PropTypes.bool,
  saving: PropTypes.bool,
  deleting: PropTypes.bool,
  error: PropTypes.object,
  branchName: PropTypes.string,
  address: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  countryCode: PropTypes.string,

  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default UpdateCustomerBranchModal;
