import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const Loader = styled.div`
  width: 100%;
  padding: 10px 0px;
  text-align: center;
  color: ${orange};
  font-size: 30px;
`;

export default Loader;
