import {useEffect} from 'react';

const useOutsideClickAndMouseLeave = (refs, onClose, buffer = 0) => {
  useEffect(() => {
    const handleMouseMoveEvent = (event) => {
      const isOutsideBuffer = (rect) => {
        return (
          event.clientX < rect.left - buffer ||
          event.clientX > rect.right + buffer ||
          event.clientY < rect.top - buffer ||
          event.clientY > rect.bottom + buffer
        );
      };

      const isOutside = refs.every((ref) => {
        const rect = ref.current?.getBoundingClientRect();
        return (
          rect && !ref.current.contains(event.target) && isOutsideBuffer(rect)
        );
      });

      if (isOutside) {
        onClose();
      }
    };

    const handleMouseDownEvent = (event) => {
      const isClickOutside = refs.every((ref) => {
        return !ref.current.contains(event.target);
      });

      if (isClickOutside) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleMouseDownEvent);
    document.addEventListener('mousemove', handleMouseMoveEvent);

    return () => {
      document.removeEventListener('mousedown', handleMouseDownEvent);
      document.removeEventListener('mousemove', handleMouseMoveEvent);
    };
  }, [refs, onClose, buffer]);
};

export default useOutsideClickAndMouseLeave;
