import numeral from 'numeral';

import type from '../type';

const format = (input, previous) => {
  if (!input) return '';

  const value = `${input}`.replace(/[^0-9.]/g, '');
  const [number, decimals] = value.split('.');

  if (value.includes('.')) {
    if (!decimals) return `${numeral(number).format('0,0')}.`;

    const newValue = `${number}.${decimals}`;

    if (decimals.length === 1) return numeral(newValue).format('0,0.0');

    const previousValue = `${previous}`.replace(/[^0-9.]/g, '');

    if (previousValue.includes('.')) {
      const prevdec = previousValue.split('.')[1];
      if (prevdec.length === 2) return previousValue;
    }

    return numeral(newValue).format('0,0.00');
  }

  return numeral(number).format('0,0');
};

const validate = (value) =>
  /(?=.)^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/.test(value);

export default type({type: 'string', format, validate});
