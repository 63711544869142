import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateInterventionDoorMaterialApi = (interventionDoorID, materialID, material) =>
  f3tch(`/intervention-doors/${interventionDoorID}/materials/${materialID}`)
    .profile(profile())
    .body(material)
    .put();
export default updateInterventionDoorMaterialApi;
