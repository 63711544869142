import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import createApi from '../../api/create.api.fault';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminFaultButton from '../../components/NewAdminFaultButton/NewAdminFaultButton';
import NewFaultModal from '../../components/NewFaultModal/NewFaultModal';

class NewFaultContainer extends Component {
  static propTypes = {
    faults: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    name: '',
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, name: ''});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, name: ''});
  };

  create = async () => {
    // TODO: I18n
    const {loading, visible, name} = this.state;
    if (loading || !visible) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const fault = await createApi({name});
      const {dispatch, faults} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(setAct({faults: [...faults, fault]}));
    } catch (error) {
      alertify.error('Fault could not be created');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {loading, visible, name} = this.state;
    return (
      <Fragment>
        <NewAdminFaultButton onClick={this.show}>New Fault</NewAdminFaultButton>
        <NewFaultModal
          title="New Fault"
          nameLabel="Name"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          name={name}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.fault}))(NewFaultContainer);
