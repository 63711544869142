import {profile} from 'f3tch';

// Errors
import isApiError from '../errors/isApiError.error.api';

const externalJson = () =>
  profile()
    .bodyParser((data) => JSON.stringify(data))
    .responder(async (response) => {
      await isApiError(response);
      const data = await response.json();
      return data;
    });
export default externalJson;
