import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const NewDoor = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  cursor: pointer;
  color: ${orange};
`;

export default NewDoor;
