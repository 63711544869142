import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 25px;
  right: 36px;
  z-index: 100;
  display: flex;
  align-items: center;
  user-select: none;
`;

export default Container;
