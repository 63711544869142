import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import CustomerInfo from '../../../customer/components/CustomerInfo/CustomerInfo';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// TODO: I18n
const InterventionCustomerPage = ({intervention, match}) => (
  <SubPage page={match.path}>
    <CustomerInfo
      customerTypeLabel="Type"
      companyLabel="Company name"
      registrationNumberLabel="Registration number"
      firstNameLabel="First Name"
      lastNameLabel="Last Name"
      addressLabel="Address"
      zipLabel="Zip Code"
      cityLabel="City"
      countryLabel="Country"
      branchLocationLabel="Branch name and address"
      phoneLabel="Phone"
      emailLabel="Email"
      type={intervention.customer.type}
      company={intervention.customer.company}
      registrationNumber={intervention.customer.registrationNumber}
      firstName={intervention.customer.firstName}
      lastName={intervention.customer.lastName}
      address={intervention.customer.location.address}
      zip={intervention.customer.location.zip}
      city={intervention.customer.location.city}
      country={intervention.customer.location.countryCode}
      branchLocation={`${intervention.customerBranch.branchName}, ${intervention.customerBranch.branchLocation.address}, ${intervention.customerBranch.branchLocation.zip} ${intervention.customerBranch.branchLocation.city}, ${intervention.customerBranch.branchLocation.countryCode}`}
      phone={intervention.phone}
      email={intervention.email}
    />
  </SubPage>
);

InterventionCustomerPage.propTypes = {
  intervention: PropTypes.object,
};

export default connect((state) => ({
  intervention: state.intervention.intervention,
}))(withRouter(InterventionCustomerPage));
