// Action constants
import {SET} from './constants';

// Actions
import {updateMaintenance} from '../../maintenance/redux/actions';

export const set = (data = {}) => (dispatch, getState) => {
  dispatch({type: SET, data});
  if (!data.maintenanceDoor) return;
  const maintenance = getState().maintenance.maintenance;
  if (!maintenance) return;
  const newMaintenance = {
    ...maintenance,
    doors: [...maintenance.doors].map((door) =>
      door.id === data.maintenanceDoor.id ? {...data.maintenanceDoor} : door
    ),
  };
  dispatch(updateMaintenance(newMaintenance));
};
