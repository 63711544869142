import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {getTime} from 'date-fns';

// charts
import {Bar} from 'react-chartjs-2';

// components
import {Row, Column} from 'doorson-ui/lib/Grid/Grid';
import {alertify, Input, Button, Select} from 'doorson-ui';

// local components
import Legend from '../../../stats/components/Legend/Legend';
import MainChart from '../../../stats/components/MainChart/MainChart';
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// api
import getEnergyStats from '../../../stats/api/energy.api.stats';
import getOpeningsStats from '../../../stats/api/openings.api.stats';

// error
import parseServerFault from '../../../api/lib/parseServerFault.lib.api';

import {date as dateType} from '../../../types';

const getDayMinusOneMonth = () => {
  const utc = Date.now();
  const d = new Date(utc);
  d.setMonth(d.getMonth() - 1);
  return d;
};

const getUTCTimestamp = (dateString) => {
  const datePattern = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
  if (!datePattern.test(dateString)) {
    return null;
  }

  const [day, month, year] = dateString.split('.');
  const utcDate = new Date(Date.UTC(year, month - 1, day));
  return utcDate.getTime();
};

class DoorCloudContainer extends Component {
  state = {
    touched: false,
    metric: 'energy',
    from: dateType(getDayMinusOneMonth()).format(),
    to: dateType(new Date()).format(),
    fromTs: getTime(getDayMinusOneMonth()),
    toTs: Date.now(),
    tick: 'TIME_DAY',
    mainChart: null,
    loading: true,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    await this.loadMainChart();
  };

  loadMainChart = async () => {
    const {serviceItem} = this.props;
    const {metric, tick, fromTs, toTs} = this.state;

    this.setState({loading: true});
    try {
      let mainChart = [];
      switch (metric) {
        case 'energy':
          const energyStats = await getEnergyStats({
            serviceItemId: serviceItem?.id,
            xAxisType: tick,
            fromTs,
            toTs,
          });
          mainChart = energyStats?.data;
          break;
        case 'openings':
          const openingStats = await getOpeningsStats({
            serviceItemId: serviceItem?.id,
            xAxisType: tick,
            fromTs,
            toTs,
          });
          mainChart = openingStats?.data;
          break;
        default:
          console.error('unknown metric');
      }
      this.setState({
        loading: false,
        mainChart,
      });
    } catch (error) {
      this.setState({loading: false});
      const errCode = parseServerFault(error);
      alertify.error(
        'Cannot get stat due to ' +
          (errCode === 'error.invalidParam'
            ? 'invalid parameters (set lower time interval)'
            : errCode)
      );
    }
  };

  metrics = () => [
    {label: 'Energy', value: 'energy'},
    {label: 'Openings', value: 'openings'},
  ];

  onSubmit = () => {
    this.state.touched && this.init();
    this.setState({touched: false});
  };

  onChange = (key) => async (val) => {
    await this.setState({
      [key]: val,
      touched: this.state.touched || key === 'tick',
    });

    if (key === 'metric') {
      await this.loadMainChart();
    } else if (key === 'from') {
      const fromTs = (!!val && getUTCTimestamp(val)) || null;
      fromTs && this.setState({fromTs, touched: true});
    } else if (key === 'to') {
      const toTs = (!!val && getUTCTimestamp(val)) || null;
      toTs && this.setState({toTs, touched: true});
    }
  };

  color = (type, opacity) => {
    switch (type) {
      case 'Energy':
        return this.orange(opacity);
      case 'Openings':
        return this.blue(opacity);
      default:
        return this.orange(opacity);
    }
  };

  orange = (opacity) => `rgba(245, 131, 32, ${opacity || 1} )`;

  blue = (opacity) => `rgba(83, 176, 203, ${opacity || 1} )`;

  ticks = () => [
    {
      value: 'TIME_HOUR',
      label: 'Hour',
    },
    {
      value: 'TIME_DAY',
      label: 'Day',
    },
    {
      value: 'TIME_WEEK',
      label: 'Week',
    },
    {
      value: 'TIME_MONTH',
      label: 'Month',
    },
    {
      value: 'TIME_YEAR',
      label: 'Year',
    },
  ];

  chartTitle = (item) =>
    item.datasets[0].label.length > 32
      ? item.datasets[0].label.substr(0, 32) + '...'
      : item.datasets[0].label;

  chartValue = (dataset) => dataset.data.reduce((a, b) => a + b, 0);

  mainChart = () => {
    const {mainChart, loading} = this.state;

    return (
      !loading && {
        labels: [...mainChart.labels],
        datasets: [...mainChart.datasets].map((ds) => ({
          label: ds.label,
          fill: true,
          borderWidth: 2,
          backgroundColor: this.color(ds.label, 0.2),
          borderColor: this.color(ds.label, 1),
          data: ds.data,
        })),
      }
    );
  };

  render() {
    const {metric, loading, from, to, tick, touched} = this.state;
    const selectedMetric =
      [...this.metrics()].find((m) => m.value === metric) || {};

    return (
      <Fragment>
        <Row>
          <Column size={11 / 60}>
            <Input
              value={from}
              onChange={this.onChange('from')}
              disabled={loading}
            >
              From date
            </Input>
          </Column>
          <Column size={11 / 60}>
            <Input value={to} onChange={this.onChange('to')} disabled={loading}>
              To date
            </Input>
          </Column>
          <Column size={11 / 60}>
            <Select
              value={tick}
              options={this.ticks()}
              onChange={this.onChange('tick')}
              disabled={loading}
            >
              X axis interval
            </Select>
          </Column>
          <Column size={8 / 60}>
            <Button
              theme="orange"
              outline
              loading={loading}
              disabled={!touched}
              onClick={this.onSubmit}
            >
              Apply
            </Button>
          </Column>
        </Row>
        <Row>
          <Column size={1 / 6}>
            <Select
              value={metric}
              options={this.metrics()}
              onChange={this.onChange('metric')}
              disabled={loading}
            >
              &nbsp;
            </Select>
          </Column>
        </Row>
        <Row>
          <Column>
            <Legend color={this.color(selectedMetric.label)}>
              {selectedMetric.label}
            </Legend>
          </Column>
        </Row>
        <Row>
          <Column>
            <MainChart>
              {(!loading && (
                <Bar
                  height={250}
                  data={this.mainChart()}
                  options={{
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      titleFontFamily: 'Roboto Condensed',
                      bodyFontFamily: 'Roboto Condensed',
                      xPadding: 10,
                      yPadding: 10,
                      displayColors: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: 1,
                  }}
                />
              )) || <FullScreenLoader />}
            </MainChart>
          </Column>
        </Row>
      </Fragment>
    );
  }
}

DoorCloudContainer.propTypes = {
  serviceItem: PropTypes.object,
};

export default DoorCloudContainer;
