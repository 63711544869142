import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

// Colors
import {colors} from 'doorson-ui';
const {white, orange, black} = colors;

const Page = styled(Link)`
  width: 100%;
  height: 94px;
  display: block;
  display: flex;
  align-items: center;
  border-style: ${({last}) => (last ? 'solid none' : 'solid none none none')};
  border-width: 2px;
  border-color: ${white};
  color: ${({alternatecolor, selected}) =>
    selected ? (alternatecolor ? black : orange) : white};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  ${({small}) =>
    small
      ? css`
          justify-content: center;
        `
      : css`
          padding: 0px 36px;
        `}
`;

export default Page;
