import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import Container from './components/Container';
import Icon from './components/Icon';

const AdminFaults = ({columns, sort, faults, onUpdate, onDelete, onSort}) => (
  <Container>
    <Table columns={columns} sort={sort} onSort={onSort}>
      {(TableRow) =>
        faults.map((fault) => (
          <TableRow key={fault.id}>
            {(TableCol) => [
              <TableCol smallPadding key="name" span={3}>
                {fault.name}
              </TableCol>,
              <TableCol smallPadding key="actions">
                <Icon
                  className="mdi mdi-circle-edit-outline"
                  onClick={onUpdate(fault)}
                />
                <Icon className="mdi mdi-delete" onClick={onDelete(fault.id)} />
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
    {!faults.length && <NoDataFound>No faults found</NoDataFound>}
  </Container>
);

AdminFaults.propTypes = {
  columns: PropTypes.array,
  sort: PropTypes.object,
  faults: PropTypes.array,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onSort: PropTypes.func,
};

export default AdminFaults;
