import styled from 'styled-components';

const PdfContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
`;

export default PdfContainer;
