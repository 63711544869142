import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const Icon = styled.div`
  display: inline-block;
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
  color: ${darkGrey};
`;

export default Icon;
