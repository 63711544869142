import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import {Line} from "react-chartjs-2";
import ChartTitle from "./components/ChartTitle";
import ChartValueContainer from "./components/ChartValueContainer";
import LineContainer from "./components/LineContainer";
import DescriptionContainer from "./components/DescriptionContainer";

const StatisticsPairTile = ({
                              title,
                              value1,
                              value2,
                              value3,
                              chartData,
                            }) =>
  <Container>
    <DescriptionContainer>
      <ChartTitle>{title}</ChartTitle>
      <ChartValueContainer value1={value1} value2={value2} value3={value3}/>
    </DescriptionContainer>
    <LineContainer>
      <Line data={chartData}
            options={{
              bezierCurve: true,
              legend: {
                display: false
              },
              elements: {
                point: {
                  radius: 1
                }
              },
              scales: {
                xAxes: [{
                  ticks: {
                    display: false
                  },
                  gridLines: {
                    display: false
                  }
                }],
                yAxes: [{
                  id: 'count',
                  ticks: {
                    suggestedMax: chartData.suggestedMaxCount,
                    fontColor: 'rgba(0,0,0,0.4)',
                    callback: function (value, index, values) {
                      return value === values[0] || (!!values.length && value === values[values.length - 1]) ? value : '';
                    }
                  },
                  gridLines: {
                    display: false
                  },
                  type: 'linear',
                  position: 'left',
                },
                  {
                    id: 'time',
                    ticks: {
                      suggestedMax: chartData.suggestedMaxTime,
                      fontColor: 'rgba(0,0,0,0.4)',
                      callback: function (value, index, values) {
                        return value === values[0] || (!!values.length && value === values[values.length - 1]) ? value : '';
                      }
                    },
                    gridLines: {
                      display: false
                    },
                    type: 'linear',
                    position: 'right',
                  }]
              }
            }}/>
    </LineContainer>
  </Container>
;

StatisticsPairTile.propTypes = {
  title: PropTypes.string,
  value1: PropTypes.number,
  value2: PropTypes.number,
  value3: PropTypes.number,
  chartData: PropTypes.object,
};

export default StatisticsPairTile;
