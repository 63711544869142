import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

// local components
import Container from './components/Container';
import Popup from './components/Popup';

// hooks
import useOutsideClickAndMouseLeave from './hooks/useOutsideClickAndMouseLeave';

const Tooltip = ({
  content,
  children,
  horizontalPosition = 'right',
  verticalPosition = 'top',
  block,
  width = 'auto',
  maxWidth = '200px',
  buffer = 0,
} = {}) => {
  const [open, setOpen] = useState(false);
  const PopupRef = useRef(null);
  const ContainerRef = useRef(null);

  useOutsideClickAndMouseLeave(
    [PopupRef, ContainerRef],
    () => setOpen(false),
    buffer
  );

  return (
    <Container
      block={block}
      onClick={() => setOpen(true)}
      ref={ContainerRef}
      onMouseEnter={() => setOpen(true)}
    >
      {children}
      <Popup
        visible={open}
        width={width}
        maxWidth={maxWidth}
        horizontalPosition={horizontalPosition}
        verticalPosition={verticalPosition}
        ref={PopupRef}
      >
        {content}
      </Popup>
    </Container>
  );
};

Tooltip.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  verticalPosition: PropTypes.oneOf(['top', 'center', 'bottom']),
  block: PropTypes.bool,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  buffer: PropTypes.number,
};

export default Tooltip;
