import React from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from './components/Icon';

const ShowOrEdit = ({editable, value, valueCheck, onEdit}) =>
  !editable || valueCheck(value) ? (
    value
  ) : (
    <Icon className="mdi mdi-pencil" onClick={onEdit} />
  );

ShowOrEdit.propTypes = {
  editable: PropTypes.bool,
  value: PropTypes.any,
  valueCheck: PropTypes.func,
  onEdit: PropTypes.func,
};

ShowOrEdit.defaultProps = {
  valueCheck: (value) =>
    value !== null &&
    value !== undefined &&
    value !== '' &&
    !['/', '\\', '-'].includes(value),
};

export default ShowOrEdit;
