import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import infoApi from '../../api/info.api.app';

// Actions
import {set as setAct} from '../../redux/actions';

class VersionContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    children: PropTypes.node,
  };

  componentDidMount() {
    this.getApiInfo();
  }

  getApiInfo = async () => {
    try {
      const info = await infoApi();
      this.props.dispatch(setAct({apiVersion: info.appVersion}));
    } catch (error) {
      // Do nothing
    }
  };

  render() {
    return this.props.children || null;
  }
}

export default connect()(VersionContainer);
