export const sortByStr = ({a, b, key = 'name', direction = 'asc'} = {}) => {
  const asc = direction === 'asc';
  if (a[key] < b[key]) {
    return asc ? -1 : 1;
  }
  if (a[key] > b[key]) {
    return asc ? 1 : -1;
  }
  return 0;
};

const sortByString = ({key = 'name', direction = 'asc'}) => (a, b) =>
  sortByStr({a, b, key, direction});
export default sortByString;
