import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {black} = colors;

const Content = styled.div`
  width: 100%;
  margin-top: 8px;
  font-size: 24px;
  color: ${black};
`;

export default Content;
