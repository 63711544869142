import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const Action = styled.i`
  display: block;
  color: ${darkGrey};
  cursor: pointer;
  font-size: 26px;
`;

export default Action;
