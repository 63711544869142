import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Api
import createApi from '../../api/create.api.documentation';

// Alerts
import {alertify} from 'doorson-ui';

// Components
import NewDocumentationModal from '../../components/NewDocumentationModal/NewDocumentationModal';

// Lib
import toBase64 from '../../../file/lib/toBase64.lib.file';

// Roles
import {
  CHIEF_SERVICE_TECHNICIAN,
  DOCUMENTATION_ADMIN,
  EMPLOYEE,
  SERVICE_TECHNICIAN,
  SYSTEM,
} from '../../../user/roles/roles.user';

class NewDocumentationContainer extends Component {
  static propTypes = {
    documentationID: PropTypes.string,
    visible: PropTypes.bool,
    onComplete: PropTypes.func,
    onClose: PropTypes.func,
  };

  static INITIAL_STATE = {
    title: '',
    image: null,
    canRead: false,
    canCreate: false,
    canDelete: false,
    canCreateFiles: false,
    canDeleteFiles: false,
  };

  state = {
    loading: false,
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => this.setState({...this.constructor.INITIAL_STATE});

  change = (key) => (value) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  close = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  create = async () => {
    // TODO: I18n
    const {documentationID, onComplete, visible} = this.props;
    const {
      loading,
      title,
      image: rawImage,
      canRead,
      canCreate,
      canDelete,
      canCreateFiles,
      canDeleteFiles,
    } = this.state;

    if (loading || !visible) return;

    if (!title.trim().length) return alertify.warning('Insert folder title');
    if (!rawImage) return alertify.warning('Select folder image');

    this.setState({loading: true});

    try {
      const image = await toBase64(rawImage);
      const privileges = [
        {
          role: CHIEF_SERVICE_TECHNICIAN,
          read: true,
          create: true,
          delete: true,
          filesCreate: true,
          filesDelete: true,
        },
        {
          role: DOCUMENTATION_ADMIN,
          read: true,
          create: true,
          delete: true,
          filesCreate: true,
          filesDelete: true,
        },
        {
          role: SYSTEM,
          read: true,
          create: true,
          delete: true,
          filesCreate: true,
          filesDelete: true,
        },
        {
          role: SERVICE_TECHNICIAN,
          read: canRead,
          create: canCreate,
          delete: canDelete,
          filesCreate: canCreateFiles,
          filesDelete: canDeleteFiles,
        },
        {
          role: EMPLOYEE,
          read: canRead,
          create: canCreate,
          delete: canDelete,
          filesCreate: canCreateFiles,
          filesDelete: canDeleteFiles,
        },
      ];
      const folder = await createApi({
        title,
        image,
        parentFolderId: !!documentationID ? documentationID : null,
        privileges,
      });
      folder.privileges = privileges;
      onComplete(folder);
    } catch (error) {
      alertify.error('Could not create folder');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {visible} = this.props;
    const {
      loading,
      title,
      image,
      canRead,
      canCreate,
      canDelete,
      canCreateFiles,
      canDeleteFiles,
    } = this.state;
    return (
      <NewDocumentationModal
        visible={visible}
        loading={loading}
        newDocumentationLabel="New Folder"
        titleLabel="Folder Name"
        imageLabel="Folder Image"
        saveLabel="Create"
        title={title}
        image={image}
        canRead={canRead}
        canCreate={canCreate}
        canDelete={canDelete}
        canCreateFiles={canCreateFiles}
        canDeleteFiles={canDeleteFiles}
        onClose={this.close}
        onChange={this.change}
        onSave={this.create}
      />
    );
  }
}

export default NewDocumentationContainer;
