import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import createApi from '../../api/create.api.manufacturer';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminManufacturerButton from '../../components/NewAdminManufacturerButton/NewAdminManufacturerButton';
import NewManufacturerModal from '../../components/NewManufacturerModal/NewManufacturerModal';

class NewManufacturerContainer extends Component {
  static propTypes = {
    manufacturers: PropTypes.array,
    countries: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    name: '',
    country: '',
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, name: '', country: ''});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, name: '', country: ''});
  };

  countries = () =>
    [...this.props.countries].map(({code, name}) => ({
      value: code,
      label: name,
    }));

  create = async () => {
    // TODO: I18n
    const {loading, visible, name, country} = this.state;
    if (loading || !visible) return;

    if (!name.trim().length) return alertify.warning('Insert the name');
    if (!country.trim().length) return alertify.warning('Insert the country');

    this.setState({loading: true});

    try {
      const manufacturer = await createApi({name, country});
      const {dispatch, manufacturers} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(setAct({manufacturers: [...manufacturers, manufacturer]}));
    } catch (error) {
      alertify.error('Manufacturer could not be created');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {loading, visible, name, country} = this.state;
    return (
      <Fragment>
        <NewAdminManufacturerButton onClick={this.show}>
          New Manufacturer
        </NewAdminManufacturerButton>
        <NewManufacturerModal
          title="New Manufacturer"
          nameLabel="Name"
          countryLabel="Country"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          name={name}
          countries={this.countries()}
          country={country}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  ...state.manufacturer,
  countries: state.country.countries,
}))(NewManufacturerContainer);
