import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.material';
import deleteApi from '../../api/delete.api.material';

// Components
import AdminMaterials from '../../components/AdminMaterials/AdminMaterials';
import UpdateMaterialModal from '../../components/UpdateMaterialModal/UpdateMaterialModal';
import DeleteMaterialModal from '../../components/DeleteMaterialModal/DeleteMaterialModal';

// Lib
import sortByString from '../../../lib/sortByString';

class MaterialsContainer extends Component {
  static propTypes = {
    materials: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    updateID: null,
    code: '',
    name: '',
    unit: '',
    version: 0,
    has_guarantee: false,
    removeID: null,
    sort: {key: 'code', direction: 'asc'},
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'code',
      label: 'Code',
      span: 1,
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      span: 2,
      sortable: true,
    },
    {
      key: 'unit',
      label: 'Unit',
      span: 1,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  showUpdate = (material) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: material.id,
      code: material.code,
      name: material.name,
      unit: material.unit,
      version: material.version,
      has_guarantee: !!material.warrantyDuration,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSort = (sort) => this.setState({sort});

  update = async () => {
    const {
      loading,
      updateID,
      code,
      name,
      unit,
      has_guarantee,
      version,
    } = this.state;
    if (loading || !updateID) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const material = await updateApi(updateID, {
        code,
        name,
        unit,
        version,
        warrantyDuration: has_guarantee ? 'P1Y' : null,
      });
      const {dispatch, materials} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          materials: [...materials].map((r) =>
            r.id === material.id ? material : r
          ),
        })
      );
    } catch (error) {
      alertify.error('Material could not be updated');
      this.setState({loading: false});
    }
  };

  remove = async () => {
    const {loading, removeID} = this.state;
    if (loading || !removeID) return;

    this.setState({loading: true});

    try {
      await deleteApi(removeID);
      const {dispatch, materials} = this.props;
      this.setState({loading: false, removeID: null});
      dispatch(
        setAct({materials: [...materials].filter((r) => r.id !== removeID)})
      );
    } catch (error) {
      alertify.error('Material could not be removed');
      this.setState({loading: false});
    }
  };

  data = () => {
    const {materials} = this.props;
    const {sort} = this.state;
    return !!sort ? [...materials].sort(sortByString(sort)) : materials;
  };

  render() {
    const {
      loading,
      updateID,
      sort,
      code,
      name,
      unit,
      has_guarantee,
      removeID,
    } = this.state;
    return (
      <Fragment>
        <AdminMaterials
          columns={this.columns()}
          sort={sort}
          materials={this.data()}
          onUpdate={this.showUpdate}
          onDelete={this.showDelete}
          onSort={this.onSort}
        />
        <UpdateMaterialModal
          title="Update Material"
          codeLabel="Code"
          nameLabel="Name"
          unitLabel="Unit"
          hasGuaranteeLabel="1 Year"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          code={code}
          name={name}
          unit={unit}
          has_guarantee={has_guarantee}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
        <DeleteMaterialModal
          title="Delete Material"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this material?
        </DeleteMaterialModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.material}))(MaterialsContainer);
