const validateDate = (e) => {
  const availableKeys = [
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Tab',
    'Enter',
    '.',
  ];
  if (!(!isNaN(e.key) || availableKeys.includes(e.key))) {
    e.preventDefault();
    return false;
  }
  return true;
};
export default validateDate;
