import styled from 'styled-components';
import {transitions} from 'polished';

// Colors
import {colors} from 'doorson-ui';
const {white, red} = colors;

const Container = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 1000000000000;
  background: ${red};
  color: ${white};
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  opacity: ${({online}) => (online ? 0 : 1)};
  visibility: ${({online}) => (online ? 'none' : 'visible')};
  pointer-events: ${({online}) => (online ? 'none' : 'auto')};
  transform: ${({online}) => (online ? 'translateY(100%)' : 'none')};
  ${transitions(
    'transform .3s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out'
  )};
`;

export default Container;
