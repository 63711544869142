import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const Icon = styled.i`
  display: block;
  font-size: 24px;
  color: ${orange};
  margin-right: 10px;
`;

export default Icon;
