import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/file.profile.api';

const uploadMaintenaceDoorFileApi = (maintenanceDoorID, fileName, type, data) =>
  f3tch(`/maintenance-doors/${maintenanceDoorID}/files/${fileName}`)
    .profile(profile({output: 'JSON'}))
    .query({mimeType: type && encodeURI(type)})
    .body(data)
    .post();
export default uploadMaintenaceDoorFileApi;
