import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  addWeeks,
  subWeeks,
  getTime,
  startOfWeek,
  endOfWeek,
  format,
} from 'date-fns';

// Api
import createReminderApi from '../../api/create.api.reminder';
import deleteReminderApi from '../../api/delete.api.reminder';
import getReminderByUserIDApi from '../../api/getByUserID.api.reminder';
import updateReminderApi from '../../api/update.api.reminder';
import getParsedCoordinatesApi from '../../../maps/api/getParsedCoodrinates.api.maps';

// Alert
import {alertify} from 'doorson-ui';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import RemindersHeader from '../../components/RemindersHeader/RemindersHeader';
import WeekPicker from '../../components/WeekPicker/WeekPicker';
import Reminders from '../../components/Reminders/Reminders';

// Types
import {date as dateType} from '../../../types';

class RemindersContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  state = {
    loading: true,
    date: new Date(),
    reminders: [],
    updating: [],
    removing: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    this.getReminders({skipLoading: true});
  };

  weekFrame = (date) => ({
    startDate: startOfWeek(date, {weekStartsOn: 1}),
    endDate: endOfWeek(date, {weekStartsOn: 1}),
  });

  getReminders = async ({date = this.state.date, skipLoading = false} = {}) => {
    const {user} = this.props;

    if (!skipLoading && this.state.loading) return;
    this.setState({loading: true, date});

    const {startDate, endDate} = this.weekFrame(date);

    try {
      const reminders = await getReminderByUserIDApi(user.id, {
        startDate: getTime(startDate),
        endDate: getTime(endDate),
      });
      if (!this.mounted) return;
      this.setState({loading: false, reminders});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  createReminder = async ({title, startDate, endDate} = {}) => {
    // TODO: I18n
    const {user} = this.props;
    try {
      const reminder = await createReminderApi({
        title,
        label: '',
        location: '',
        geo: null,
        ownerUserId: user.id,
        invitees: [],
        startDate: getTime(startDate),
        endDate: getTime(endDate),
        alertDate: null,
        priority: 2,
        notes: '',
      });
      if (!this.mounted) return null;
      this.setState({reminders: [...this.state.reminders, reminder]});
      return reminder;
    } catch (error) {
      if (!this.mounted) return null;
      alertify.error('Reminder could not be created');
      return null;
    }
  };

  updateReminder = async (reminder) => {
    // TODO: I18n
    const {updating} = this.state;
    const currentReminder = [...this.state.reminders].find(
      ({id}) => id === reminder.id
    );
    if (!currentReminder || updating.includes(reminder.id)) return;

    const undoUpdating = () =>
      [...this.state.updating].filter((update) => update !== reminder.id);

    const {
      id,
      ownerUserId,
      startDate,
      endDate,
      alertDate,
      ...reminderToUpdate
    } = reminder;

    this.setState({updating: [...updating, reminder.id]});

    try {
      const geo =
        !reminder.location || !`${reminder.location}`.trim().length
          ? null
          : !!reminder.location &&
            reminder.location.trim().length &&
            currentReminder.location !== reminder.location
          ? await getParsedCoordinatesApi(reminder.location)
          : reminder.geo;
      reminderToUpdate.geo = !!geo && !!geo.latitude && !!geo.longitude ? geo : null;
      const newReminder = await updateReminderApi(id, {
        ...reminderToUpdate,
        title: reminderToUpdate.title || currentReminder.title,
        startDate: getTime(startDate),
        endDate: getTime(endDate),
        alertDate: getTime(alertDate),
      });
      if (!this.mounted) return;
      const reminders = [...this.state.reminders].map((existingReminder) =>
        existingReminder.id === newReminder.id ? newReminder : existingReminder
      );
      this.setState({reminders, updating: undoUpdating()});
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Could not update the reminder');
      this.setState({updating: undoUpdating()});
    }
  };

  removeReminder = async (reminderID) => {
    // TODO: I18n
    const {loading, removing} = this.state;
    if (loading || removing.includes(reminderID)) return;
    const undoRemoving = () =>
      [...this.state.removing].filter((id) => id !== reminderID);

    this.setState({removing: [...removing, reminderID]});

    try {
      await deleteReminderApi(reminderID);
      if (!this.mounted) return;
      this.setState({
        removung: undoRemoving(),
        reminders: [...this.state.reminders].filter(
          ({id}) => id !== reminderID
        ),
      });
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Could not remove reminder');
      this.setState({removing: undoRemoving()});
    }
  };

  onNextWeek = () => this.getReminders({date: addWeeks(this.state.date, 1)});

  onPreviousWeek = () =>
    this.getReminders({date: subWeeks(this.state.date, 1)});

  // TODO: I18n
  renderHeader = () => {
    const {date} = this.state;
    const {startDate, endDate} = this.weekFrame(date);
    return (
      <Fragment>
        <RemindersHeader>Reminders</RemindersHeader>
        <WeekPicker
          weekLabel="Week"
          week={format(date, 'I')}
          startDate={dateType(startDate).format()}
          endDate={dateType(endDate).format()}
          onNext={this.onNextWeek}
          onPrevious={this.onPreviousWeek}
        />
      </Fragment>
    );
  };

  render() {
    const {loading, reminders, date, updating, removing} = this.state;
    const {startDate, endDate} = this.weekFrame(date);
    return (
      <ContentLayout header={this.renderHeader()}>
        <Reminders
          loading={loading}
          reminders={reminders}
          startDate={startDate}
          endDate={endDate}
          highlight={new Date()}
          updating={updating}
          removing={removing}
          onCreate={this.createReminder}
          onUpdate={this.updateReminder}
          onDelete={this.removeReminder}
        />
      </ContentLayout>
    );
  }
}

export default connect((state) => ({user: state.auth.user}))(
  RemindersContainer
);
