import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const waitForExternalInterventionApi =  (interventionID, assigneeId, statusReason, query = {rel: '*'}) =>
  f3tch(`/interventions/${interventionID}/wait-for-external`)
    .profile(profile())
    .query(query)
    .body({assigneeId, statusReason})
    .put();
export default waitForExternalInterventionApi;
