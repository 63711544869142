import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  line-height: 22px;
`;

export default Content;
