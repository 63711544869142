import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal} from 'doorson-ui';
import Frame from './components/Frame';
import LoadingContainer from './components/LoadingContainer';
import Loader from './components/Loader';

const ContractPreviewModal = ({loading, visible, title, preview, onClose}) => (
  <Modal
    visible={visible}
    size={!!preview ? 'full' : 'medium'}
    title={title}
    onClose={onClose}
  >
    {loading || !preview ? (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    ) : (
      <Frame src={preview} />
    )}
  </Modal>
);

ContractPreviewModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  title: PropTypes.string,
  preview: PropTypes.string,
  onClose: PropTypes.func,
};

export default ContractPreviewModal;
