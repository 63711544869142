import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import Container from './components/Container';
import Heading from './components/Heading';
import Icon from './components/Icon';

const AdminProductTypes = ({
  columns,
  productTypes,
  title,
  onTitle,
  onUpdate,
  onDelete,
}) => (
  <Container>
    <Heading onClick={onTitle}>{title}</Heading>
    <Table columns={columns}>
      {(TableRow) =>
        productTypes.map((productType) => (
          <TableRow key={productType.id}>
            {(TableCol) => [
              <TableCol key="name" span={3}>
                {productType.name}
              </TableCol>,
              <TableCol key="actions">
                <Icon
                  className="mdi mdi-circle-edit-outline"
                  onClick={onUpdate(productType)}
                />
                <Icon
                  className="mdi mdi-delete"
                  onClick={onDelete(productType.id)}
                />
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
  </Container>
);

AdminProductTypes.propTypes = {
  columns: PropTypes.array,
  title: PropTypes.string,
  productTypes: PropTypes.array,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AdminProductTypes;
