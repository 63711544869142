import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createLabelRefApi =(labelRef, query = {}) =>
  f3tch('/label-refs')
    .profile(profile())
    .query(query)
    .body(labelRef)
    .post();
export default createLabelRefApi;
