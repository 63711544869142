import React from 'react';

// Containers
import CustomerContainer from '../../containers/CustomerContainer/CustomerContainer';

const CustomerAdminPage = ({match}) => (
  <CustomerContainer id={match.params.id} />
);

export default CustomerAdminPage;
