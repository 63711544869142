import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/file.profile.api';

const uploadDoorFileApi =  (doorID, fileName, data) =>
  f3tch(`/doors/${doorID}/files/${fileName}`)
    .profile(profile({output: 'JSON'}))
    .body(data)
    .post();
export default uploadDoorFileApi;
