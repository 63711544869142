import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 15px 0px;
`;

export default Content;
