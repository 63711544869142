import styled from 'styled-components';

import {colors} from 'doorson-ui';
const {grey} = colors;

const User = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-style: none none solid none;
  border-width: 1px;
  border-color: ${grey};
  padding: 20px;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({loading, disabled}) =>
    loading || disabled ? 'none' : 'auto'};
  opacity: ${({loading}) => (loading ? 0.4 : 1)};
`;

export default User;
