import styled from 'styled-components';

// Components
import Logo from '../../../../layout/components/Logo/Logo';

const DoorserviceLogo = styled(Logo)`
  display: block;
  height: 13px;
`;

export default DoorserviceLogo;
