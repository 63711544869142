import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';

const UpdateProductModal = ({
  title,
  nameLabel,
  codeLabel,
  saveLabel,
  visible,
  loading,
  name,
  code,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="small">
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Input value={name} onChange={onChange('name')} disabled={loading}>
            {nameLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Input value={code} onChange={onChange('code')} disabled={loading}>
            {codeLabel}
          </Input>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

UpdateProductModal.propTypes = {
  title: PropTypes.string,
  nameLabel: PropTypes.string,
  codeLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  code: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default UpdateProductModal;
