import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Modal, Row} from 'doorson-ui';

const DeleteReportedFaultModal = ({
                                    title,
                                    children,
                                    deleteLabel,
                                    visible,
                                    loading,
                                    onClose,
                                    onDelete,
                                  }) => (
  <Modal visible={visible} title={title} onClose={onClose} size="small">
    <Row margin>
      <Column>{children}</Column>
    </Row>
    <Row>
      <Column>
        <Button onClick={onDelete} loading={loading}>
          {deleteLabel}
        </Button>
      </Column>
    </Row>
  </Modal>
);

DeleteReportedFaultModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  deleteLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DeleteReportedFaultModal;
