import React from 'react';

// Containers
import MaintenanceContractContainer from '../../containers/MaintenanceContractContainer/MaintenanceContractContainer';

const MaintenanceContractAdminPage = ({match}) => (
  <MaintenanceContractContainer id={match.params.id} />
);

export default MaintenanceContractAdminPage;
