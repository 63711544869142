import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {colors} from "doorson-ui";

const {greyer} = colors;
// Components

const StyledIcon = styled.a`
  cursor: pointer;
  color: ${greyer};
  font-size: 20px;
  margin: 0 7px;
  display: ${props => props.disabled ? 'none' : 'inherit'};
`;

const GpsNavigation = ({
                         location
                       }) => {
  const {geo} = location || {};
  const {longitude, latitude} = geo || {};
  const link = longitude && latitude && "https://www.google.com/maps/dir//" + latitude + "," + longitude+"/data=!3m1!1e3!5m1!1e1";
  return <StyledIcon href={link} onClick={(e) => e.stopPropagation()} title="open directions" disabled={!(longitude && latitude)} className="mdi mdi-van-utility"/>
};

GpsNavigation.propTypes = {
  location: PropTypes.object,
};

export default GpsNavigation;
