import styled from 'styled-components';

const Destination = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 350px;
  flex: 1;
  padding-right: 50px;
`;

export default Destination;
