import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const ClosedRefresh = styled.div`
  display: block;
  cursor: pointer;
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%);
  color: ${darkGrey};
  font-size: 30px;
  cursor: pointer;
`;

export default ClosedRefresh;
