import styled from 'styled-components';

const HeadingContainer = styled.div`
  padding: 40px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default HeadingContainer;
