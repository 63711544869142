import styled from 'styled-components';

const DaysColumn = styled.div`
  width: calc(100% / 7);
  min-width: calc(100% / 7);
  max-width: calc(100% / 7);
  position: relative;
`;

export default DaysColumn;
