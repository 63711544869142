import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Info, Input, Label, Row, Select} from 'doorson-ui';

import DataBlock from '../../../layout/components/DataBlock/DataBlock';

import ButtonContainer from './components/ButtonContainer';
import InputContainer from './components/InputContainer';
import City from './components/City';
import To from './components/To';
import Destination from './components/Destination';
import validateTimeInput from "../../../layout/lib/validateTimeInput.lib.layout";
import Vehicle from "./components/Vehicle";

const TechnicianInfo = ({
                          techniciansLabel,
                          resolvedByLabel,
                          completedAtLabel,
                          loggedTimeLabel,
                          vehicleLabel,
                          transportLabel,
                          signatureLabel,
                          cityLabel,
                          toLabel,
                          destinationLabel,
                          signLabel,
                          signedLabel,
                          notYetResolvedLabel,
                          saveLabel,
                          savedLabel,

                          loading,
                          assigned,
                          resolved,
                          error,
                          resolvedBy,
                          dateCompleted,
                          loggedTime,
                          vehicleId,
                          vehicle,
                          transportLocationId,
                          transportLocation,
                          transportDestination,
                          transportDestinations,
                          signed,
                          saved,

                          transportLocations,
                          vehicles,

                          onChange,
                          onSign,
                          onSave,
                        }) => (
  <DataBlock label={techniciansLabel}>
    <Row margin>
      <Column size={1 / 6}>
        <Label label={resolvedByLabel}>
          {!!resolvedBy
            ? `${resolvedBy.firstName} ${resolvedBy.lastName}`
            : notYetResolvedLabel}
        </Label>
      </Column>
      <Column size={1 / 6}>
        <Label label={completedAtLabel}>
          {dateCompleted || notYetResolvedLabel}
        </Label>
      </Column>
      <Column size={2 / 8}>
        {resolved || !assigned ? (
          <Info label={loggedTimeLabel}>{loggedTime}</Info>
        ) : (
          <Fragment>
            <Input
              type="text"
              onKeyDown={validateTimeInput}
              value={loggedTime}
              onChange={onChange('loggedTime')}
              error={error.loggedTime}
              disabled={loading}
              required
            >
              {loggedTimeLabel}
            </Input>
          </Fragment>
        )}
      </Column>
      <Column size={1 / 24}>
      </Column>
      {!resolved && assigned && (
        <Column size={2 / 8}>
          <ButtonContainer>
            <Button
              theme={saved ? 'darkGrey' : 'orange'}
              size="big"
              onClick={onSave}
              disabled={saved || loading}
              outline
            >{saved ? savedLabel : saveLabel}
            </Button>
          </ButtonContainer>
        </Column>
      )}
    </Row>
    <Row>
      <Column size={8 / 24}>
        <Label label={transportLabel}>
          <InputContainer>
            <Vehicle>
              {resolved || !assigned ? (
                <Info label={vehicleLabel}>{vehicle}</Info>
              ) : (
                <Select
                  value={vehicleId}
                  options={vehicles}
                  onChange={onChange('vehicleId')}
                  error={error.vehicleId}
                  disabled={loading}
                  required
                >
                  {vehicleLabel}
                </Select>
              )}
            </Vehicle>
          </InputContainer>
        </Label>
      </Column>
      <Column size={7 / 24}>
      </Column>
      {!resolved && assigned && (
        <Column size={1 / 3}>
          <Label label={signatureLabel}>
            <ButtonContainer>
              <Button
                theme={signed ? 'darkGrey' : 'orange'}
                size="big"
                onClick={onSign}
                disabled={loading}
                outline
              >
                {signed ? signedLabel : signLabel}
              </Button>
            </ButtonContainer>
          </Label>
        </Column>
      )}
    </Row>
    <Row>
      <Column size={15 / 24}>
          <InputContainer>
            <City>
              {resolved || !assigned ? (
                <Info label={cityLabel}>{transportLocation}</Info>
              ) : (
                <Select
                  value={transportLocationId}
                  options={transportLocations}
                  onChange={onChange('transportLocationId')}
                  error={error.transportLocationId}
                  disabled={loading}
                  required
                >
                  {cityLabel}
                </Select>
              )}
            </City>
            <To resolved={resolved}>{toLabel}</To>
            <Destination>
              {resolved || !assigned ? (
                <Info label={destinationLabel}>{transportDestination}</Info>
              ) : (
                <Input
                  value={transportDestination}
                  onChange={onChange('transportDestination')}
                  error={error.transportDestination}
                  onSuggestion={onChange('transportDestination')}
                  suggestions={transportDestinations}
                  displaySuggestions={() => true}
                  disabled={loading}
                  required
                >
                  {destinationLabel}
                </Input>
              )}
            </Destination>
          </InputContainer>
      </Column>
    </Row>
  </DataBlock>
);

TechnicianInfo.propTypes = {
  techniciansLabel: PropTypes.string,
  resolvedByLabel: PropTypes.string,
  completedAtLabel: PropTypes.string,
  vehicleLabel: PropTypes.string,
  transportLabel: PropTypes.string,
  signatureLabel: PropTypes.string,
  cityLabel: PropTypes.string,
  toLabel: PropTypes.string,
  destinationLabel: PropTypes.string,
  signLabel: PropTypes.string,
  signedLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  savedLabel: PropTypes.string,
  notYetResolvedLabel: PropTypes.string,

  loading: PropTypes.bool,
  assigned: PropTypes.bool,
  resolved: PropTypes.bool,
  error: PropTypes.object,
  resolvedBy: PropTypes.object,
  dateCompleted: PropTypes.string,
  vehicleId: PropTypes.string,
  vehicle: PropTypes.string,
  transportLocationId: PropTypes.string,
  transportLocation: PropTypes.string,
  vehicles: PropTypes.array,
  transportDestinations: PropTypes.array,
  transportDestination: PropTypes.string,
  signed: PropTypes.bool,
  saved: PropTypes.bool,

  transportLocations: PropTypes.array,

  onChange: PropTypes.func,
  onSign: PropTypes.func,
  onSave: PropTypes.func,
};

export default TechnicianInfo;
