import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import EntryContainer from './components/EntryContainer';
import EntryContent from './components/EntryContent';
import Point from './components/Point';
import BigPoint from './components/BigPoint';
import Label from './components/Label';
import Title from './components/Title';

const MapLegend = ({priorityLabel, vehicleLabel, priorities}) => (
  <Container>
    <Title>{priorityLabel}</Title>
    {priorities.map((priority) => (
      <EntryContainer key={priority.key}>
        <EntryContent>
          <Point color={priority.color} />
          <Label>{priority.label}</Label>
        </EntryContent>
      </EntryContainer>
    ))}
    <EntryContainer>
      <EntryContent>
        <BigPoint>JDo</BigPoint>
        <Label>Technician</Label>
      </EntryContent>
    </EntryContainer>
  </Container>
);

MapLegend.propTypes = {
  priorityLabel: PropTypes.node,
  vehicleLabel: PropTypes.node,

  priorities: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      color: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default MapLegend;
