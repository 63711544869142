import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Input, Button, CheckBox} from 'doorson-ui';
import SimpleUploader from '../../../file/components/SimpleUploader/SimpleUploader';
import ButtonContainer from './components/ButtonContainer';

const NewDocumentationModal = ({
  visible,
  loading,
  canRead,
  canCreate,
  canDelete,
  canCreateFiles,
  canDeleteFiles,

  newDocumentationLabel,
  titleLabel,
  imageLabel,
  saveLabel,

  title,
  image,

  onClose,
  onChange,
  onSave,
}) => (
  <Modal title={newDocumentationLabel} visible={visible} onClose={onClose}>
    <Row margin>
      <Column>
        <Input value={title} onChange={onChange('title')} disabled={loading}>
          {titleLabel}
        </Input>
      </Column>
    </Row>
    <Row margin>
      <Column>
        <SimpleUploader
          reset={visible}
          loading={loading}
          label={imageLabel}
          file={image}
          removeLabel="Remove"
          onChange={onChange('image')}
        />
      </Column>
    </Row>
    <Row margin>
      <Column>Can other users:</Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <CheckBox
          selected={canRead}
          onSelect={onChange('canRead')}
          disabled={loading}
        >
          Read Folder
        </CheckBox>
      </Column>
      <Column size={1 / 3}>
        <CheckBox
          selected={canCreate}
          onSelect={onChange('canCreate')}
          disabled={loading}
        >
          Create Folders
        </CheckBox>
      </Column>
      <Column size={1 / 3}>
        <CheckBox
          selected={canDelete}
          onSelect={onChange('canDelete')}
          disabled={loading}
        >
          Delete Folder
        </CheckBox>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <CheckBox
          selected={canCreateFiles}
          onSelect={onChange('canCreateFiles')}
          disabled={loading}
        >
          Create Files
        </CheckBox>
      </Column>
      <Column size={1 / 3}>
        <CheckBox
          selected={canDeleteFiles}
          onSelect={onChange('canDeleteFiles')}
          disabled={loading}
        >
          Delete Files
        </CheckBox>
      </Column>
    </Row>
    <Row>
      <Column>
        <ButtonContainer>
          <Button theme="orange" loading={loading} onClick={onSave}>
            {saveLabel}
          </Button>
        </ButtonContainer>
      </Column>
    </Row>
  </Modal>
);

NewDocumentationModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  canRead: PropTypes.bool,
  canCreate: PropTypes.bool,
  canDelete: PropTypes.bool,
  canCreateFiles: PropTypes.bool,
  canDeleteFiles: PropTypes.bool,

  newDocumentationLabel: PropTypes.string,
  titleLabel: PropTypes.string,
  imageLabel: PropTypes.string,
  saveLabel: PropTypes.string,

  title: PropTypes.string,
  image: PropTypes.object,

  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default NewDocumentationModal;
