import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Content from './components/Content';
import Icon from './components/Icon';
import Text from './components/Text';

const WeekPicker = ({
  weekLabel,
  week,
  startDate,
  endDate,
  onPrevious,
  onNext,
}) => (
  <Container>
    <Content>
      <Icon className="mdi mdi-chevron-left" onClick={onPrevious} />
      <Text>
        {weekLabel} {week}, {startDate} - {endDate}
      </Text>
      <Icon className="mdi mdi-chevron-right" onClick={onNext} />
    </Content>
  </Container>
);

WeekPicker.propTypes = {
  weekLabel: PropTypes.node,
  week: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
};

export default WeekPicker;
