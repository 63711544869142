import styled from 'styled-components';

const SmallOption = styled.div`
  width: 120px;
  max-width: 120px;
  min-width: 120px;
  margin-left: 4px;
`;

export default SmallOption;
