import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createProductApi = (product) =>
  f3tch('/products')
    .profile(profile())
    .body(product)
    .post();
export default createProductApi;
