import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getTime, parse} from 'date-fns';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import createMaintenanceApi from '../../api/create.api.maintenance';
import searchCustomerApi from '../../../customer/api/list.api.customer';
import createCustomerBranchApi from '../../../customerBranch/api/create.api.customerBranch';
import getParsedCoodrinatesApi from '../../../maps/api/getParsedCoodrinates.api.maps';
import getServiceItemByIdApi from '../../../door/api/getByID.api.door';
import getByIDApiCustomerBranch from '../../../customerBranch/api/getByID.api.customerBranch';
import getByIDApiCustomer from '../../../customer/api/getByID.api.customer';

// Alert
import {alertify} from 'doorson-ui';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import NewMaintenance from '../../components/NewMaintenance/NewMaintenance';
import NewMaintenanceHeader from '../../components/NewMaintenanceHeader/NewMaintenanceHeader';
import NewCustomerBranchModal from '../../../customerBranch/components/NewCustomerBranchModal/NewCustomerBranchModal';

// Routes
import maintenancesRoute from '../../pages/MaintenancesPage/route';
import {isEmail} from "validator";

class NewMaintenanceContractContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    error: {},
    customer: null,
    search: '',
    scheduledFor: '',
    type: 'business',
    companies: [],
    branch: null,
    doorId: null,
    searchingForCustomer: false,

    savingBranch: false,
    branchModalVisible: false,
    branchName: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    email: '',
    branchError: {},
  };

  lastCompanySearch = 0;

  async componentDidMount() {
    this.mounted = true;

    const { serviceItemId } = Object.fromEntries(new URLSearchParams(this.props.location.search));

    if (!!serviceItemId) {
      const serviceItem = await getServiceItemByIdApi(serviceItemId);
      if (!serviceItem) {
        alertify.warning('Given door does not exist');
      } else {
        const { customerBranchId } = serviceItem;
        const branch = await getByIDApiCustomerBranch(customerBranchId)
        const { customerId } = branch;
        const company = await getByIDApiCustomer(customerId, {rel: '*'})

        this.setState({
          doorId: serviceItemId,
          companies: [company],
          branch,
          customer: {
            ...company
          },
        });

      }
    }

  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onChange = (key) => (value) => {
    const {loading} = this.state;
    if (loading) return;

    const error = Object.entries(this.state.error).reduce(
      (combined, [errorKey, value]) =>
        key === errorKey ? combined : {...combined, [errorKey]: value},
      {}
    );
    const state = {
      [key]: value,
      error,
    };

    if (key === 'search') this.searchCompany(value);

    if (key === 'type') {
      state.error = {};
      state.search = '';
      state.companies = [];
      state.branch = null;
      state.customer = null;
    }

    this.setState(state);
  };

  onBranchChange = (key) => (val) => {
    const {loading, savingBranch} = this.state;
    if (loading || savingBranch) return;
    this.setState({[key]: val});
  };

  searchCompany = async (search) => {
    const {type} = this.state;
    if (search.trim().length < 3)
      return this.setState({companies: [], searchingForCustomer: false});

    this.setState({searchingForCustomer: true});

    const lastCompanySearch = getTime(new Date());
    this.lastCompanySearch = lastCompanySearch;

    const searchKey = type === 'business' ? 'company' : 'first_name';
    try {
      const companies = await searchCustomerApi({
        rel: 'customerBranches',
        filter: encodeURIComponent(
          `${searchKey}:LIKEIC:%${search}%,id:ISNOTNULL`
        ),
      });
      if (lastCompanySearch !== this.lastCompanySearch) return;
      this.setState({
        companies: [...companies].filter(({id}) => !!id),
        searchingForCustomer: false,
      });
    } catch (error) {
      this.setState({searchingForCustomer: false});
    }
  };

  types = () => [
    {
      value: 'business',
      label: 'Business customer',
    },
    {
      value: 'private',
      label: 'Private customer',
    },
  ];

  companies = () =>
    [...this.state.companies].map(
      ({
        type,
        company,
        id,
        firstName,
        lastName,
        location: {
          address,
          zip,
          city,
          countryCode
        },
      }) => ({
        value: id,
        label:
          type === 'business'
            ? company
            : `${firstName} ${lastName}, ${address}, ${zip} ${city}, ${countryCode}`,
      })
    );

  onCompany = (id) => {
    const customer = [...this.state.companies].find((c) => c.id === id);
    if (!customer) return;

    this.setState({customer, email: customer.email, branch: null});
  };

  onBranch = (branch) => {
    if (this.state.loading) return;
    this.setState({branch});
  };

  getCountryByCode = (code) =>
    [...this.props.countries].find((country) => country.code === code);

  countries = () =>
    [...this.props.countries].map(({code, name}) => ({
      value: code,
      label: name,
    }));

  showBranchModal = () =>
    this.setState({
      branchModalVisible: true,
      savingBranch: false,
      branchName: '',
      address: '',
      zip: '',
      city: '',
      country: '',
      branchError: {},
    });

  hideBranchModal = () => {
    if (this.state.savingBranch) return;
    this.setState({branchModalVisible: false});
  };

  create = async () => {
    // TODO: I18n
    // TODO: Error management
    const {history} = this.props;
    const {loading, customer, scheduledFor, branch, email, doorId} = this.state;
    if (loading) return;

    if (!customer) return alertify.warning('Select a customer');
    if (!branch) return alertify.warning('Select a branch');

    const error = {};
    if (!scheduledFor) error.scheduledFor = 'Select a date';

    if (!!email && !isEmail(email))
      error.email = 'Should be a valid email address';

    if (Object.keys(error).length) {
      this.setState({error});
      return alertify.warning(
        'Make sure you filled out all the required fields'
      );
    }

    this.setState({loading: true});

    const maintenance = {
      // priority: 2,
      scheduledFor: getTime(parse(scheduledFor, 'yyyy-MM-dd', new Date())),
      customerId: customer.id,
      customerBranchId: branch.id,
      email,
      doors: !!doorId ? [{doorId}]: []
      // createdBy: user.id,
    };

    try {
      await createMaintenanceApi(maintenance);
      history.push(maintenancesRoute());
    } catch (error) {
      alertify.error('Could not create an maintenance incident. Try again');
      this.setState({loading: false});
    }
  };

  saveBranch = async () => {
    const {
      customer,
      loading,
      savingBranch,
      branchName,
      address,
      zip,
      city,
      country: countryCode,
    } = this.state;

    if (loading || savingBranch) return;

    const country = this.getCountryByCode(countryCode);

    // TODO: I18N
    const error = {};

    if (!branchName.trim().length) error.branchName = 'Should not be empty';
    if (!address.trim().length) error.address = 'Should not be empty';
    if (!zip.trim().length) error.zip = 'Should not be empty';
    if (!city.trim().length) error.city = 'Should not be empty';
    if (!countryCode.trim().length || !country)
      error.country = 'Should not be empty';

    if (!!Object.keys(error).length) {
      this.setState({branchError: error});
      alertify.warning('Insert all the required customer info properly');
      return;
    }

    this.setState({savingBranch: true});

    const rawCustomerBranch = {
      branchName,
      branchLocation: {
        address,
        zip,
        city,
        countryCode,
      },
      customerId: customer.id,
    };

    const coordinates = await getParsedCoodrinatesApi(
      `${address}, ${zip} ${city}, ${country.name}`
    );

    rawCustomerBranch.branchLocation.geo = coordinates;

    try {
      const customerBranch = await createCustomerBranchApi(rawCustomerBranch);
      this.setState({
        savingBranch: false,
        branchModalVisible: false,
        customer: {
          ...customer,
          branches: [...customer.branches, customerBranch],
        },
      });
    } catch (error) {
      alertify.error('Could not create a branch. Try again');
      this.setState({savingBranch: false});
    }
  };

  renderHeader = () => (
    <NewMaintenanceHeader>New Maintenance</NewMaintenanceHeader>
  );

  render() {
    // TODO: I18N
    const {
      loading,
      error,
      scheduledFor,
      search,
      customer,
      branch,
      doorId,
      email,
      type,
      searchingForCustomer,

      savingBranch,
      branchModalVisible,
      branchName,
      address,
      zip,
      city,
      country,
      branchError,
    } = this.state;

    return (
      <ContentLayout header={this.renderHeader()}>
        <NewMaintenance
          searchLabel="Search for Customer"
          companyLabel="Company name"
          registrationNumberLabel="Registration number"
          firstNameLabel="First Name"
          lastNameLabel="Last Name"
          addressLabel="Address"
          zipLabel="Zip Code"
          cityLabel="City"
          countryLabel="Country"
          buttonLabel="Create"
          noSuggestionLabel="No suggestions found"
          scheduledForLabel="Scheduled for"
          phoneLabel="Phone"
          emailLabel="Email"
          branchesLabel="Branches"
          noBranchesLabel="This customer doesn't have any branches available. Insert some before continuing"
          selectAllLabel="Select All"
          searchBranchLabel="Search"
          noBranchesFoundLabel="No Branches Found"
          newBranchLabel="New Branch"
          loading={loading}
          types={this.types()}
          companies={this.companies()}
          getCountryByCode={this.getCountryByCode}
          error={error}
          type={type}
          scheduledFor={scheduledFor}
          search={search}
          customer={customer}
          branch={branch}
          doorId={doorId}
          email={email}
          searchingForCustomer={searchingForCustomer}
          onNewBranch={this.showBranchModal}
          onChange={this.onChange}
          onConfirm={this.create}
          onCompany={this.onCompany}
          onBranch={this.onBranch}
        />
        <NewCustomerBranchModal
          title="New Branch"
          branchNameLabel="Branch Name"
          addressLabel="Address"
          zipLabel="Zip Code"
          cityLabel="City"
          countryLabel="Country"
          saveLabel="Save"
          visible={branchModalVisible}
          loading={savingBranch}
          error={branchError}
          branchName={branchName}
          address={address}
          zip={zip}
          city={city}
          country={country}
          countries={this.countries()}
          onChange={this.onBranchChange}
          onClose={this.hideBranchModal}
          onSave={this.saveBranch}
        />
      </ContentLayout>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  countries: state.country.countries,
}))(withRouter(NewMaintenanceContractContainer));
