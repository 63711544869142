import React from 'react';

// Components
import NewTransportLocationContainer from '../../containers/NewTransportLocationContainer/NewTransportLocationContainer';

const TransportLocationsAdminAddonPage = () => (
  <NewTransportLocationContainer />
);

export default TransportLocationsAdminAddonPage;
