import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 206px);
  padding: 1px;
  display: flex;
  user-select: none;
`;

export default Container;
