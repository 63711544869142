import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Avatar} from 'doorson-ui';

import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

import Container from './components/Container';
import Content from './components/Content';
import Header from './components/Header';
import Box from './components/Box';
import User from './components/User';
import Name from './components/Name';

const AssignUser = ({
  availableLabel,
  assignedLabel,
  noPermissionLabel,

  loading,
  updating,
  hasPermission,
  resolved,
  availableUsers,
  assignees,

  onAssign,
  onUnassign,
}) =>
  !hasPermission || resolved ? (
    <Content full>
      <Header>{assignedLabel}</Header>
      <Box>
        {assignees.map((user) => (
          <User
            loading={updating.includes(user.key) ? 1 : 0}
            key={user.key}
            onClick={() => null}
            disabled
          >
            <Avatar
              theme="grey"
              initials={user.label}
              loading={updating.includes(user.key)}
            />
            <Name>{user.label}</Name>
          </User>
        ))}
      </Box>
    </Content>
  ) : loading ? (
    <Container>
      <FullScreenLoader />
    </Container>
  ) : (
    <Container>
      <Content>
        <Header>{availableLabel}</Header>
        <Box>
          {availableUsers.map((user) => (
            <User
              loading={updating.includes(user.key) ? 1 : 0}
              key={user.key}
              onClick={() => onAssign(user.key)}
            >
              <Avatar
                theme="grey"
                initials={user.label}
                loading={updating.includes(user.key)}
              />
              <Name>{user.label}</Name>
            </User>
          ))}
        </Box>
      </Content>
      <Content>
        <Header>{assignedLabel}</Header>
        <Box>
          {assignees.map((user) => (
            <User
              loading={updating.includes(user.key) ? 1 : 0}
              key={user.key}
              onClick={() => onUnassign(user.key)}
            >
              <Avatar
                theme="orange"
                initials={user.label}
                loading={updating.includes(user.key)}
              />
              <Name>{user.label}</Name>
            </User>
          ))}
        </Box>
      </Content>
    </Container>
  );

AssignUser.propTypes = {
  availableLabel: PropTypes.string,
  assignedLabel: PropTypes.string,
  noPermissionLabel: PropTypes.string,

  loading: PropTypes.bool,
  updating: PropTypes.array,
  hasPermission: PropTypes.bool,
  resolved: PropTypes.bool,

  availableUsers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  onAssign: PropTypes.func,
  onUnassign: PropTypes.func,
};

export default AssignUser;
