import {v4} from 'uuid';

// Action constants
import {SET} from './constants';

// Api
import getWarehouseIdentStockApi from '../api/get.api.warehouseIdentStock';

// Query
import setQuery from '../../api/lib/query.lib.api';

export const set = (data = {}) => ({type: SET, data});

export const getWarehouseItems = (data = {}) => async (dispatch, getState) => {
  const apiID = v4();
  const {search, filter, limit} = getState().warehouseItem;
  const {warehouseId} = data;
  const newSearch = data.search !== undefined ? data.search : search;
  const newFilter = data.filter !== undefined ? data.filter : filter;

  dispatch({
    type: SET,
    data: {
      apiID,
      loading: true,
      search: newSearch,
      filter: newFilter,
      warehouse: warehouseId,
      offset: 0,
      more: true,
      warehouseItems: [],
    },
  });

  try {
    const query = setQuery({
      search: newSearch,
      status: newFilter,
      warehouseId,
      offset: 0,
      rel: '*',
      limit,
    });
    const warehouseItems = await getWarehouseIdentStockApi(query);

    if (getState().warehouseItem.apiID !== apiID) return;
    dispatch({
      type: SET,
      data: {
        warehouseItems,
        loading: false,
        more: warehouseItems.length === limit,
        offset: limit,
      },
    });
  } catch (error) {
    dispatch({
      type: SET,
      data: {loading: false},
    });
  }
};
