import React from 'react';
import PropTypes from 'prop-types';

// Component
import {Button} from 'doorson-ui';
import Container from './components/Container';

// Route
import route from '../../pages/NewMaintenanceContractPage/route';

const NewMaintenanceContractButton = ({children, onClick}) => (
  <Container to={route()}>
    <Button onClick={onClick}>{children}</Button>
  </Container>
);

NewMaintenanceContractButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default NewMaintenanceContractButton;
