import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateMaterialApi = (id, material) =>
  f3tch(`/materials/${id}`)
    .profile(profile())
    .body(material)
    .put();
export default updateMaterialApi;
