import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateManufacturerApi = (id, manufacturer) =>
  f3tch(`/manufacturers/${id}`)
    .profile(profile())
    .body(manufacturer)
    .put();
export default updateManufacturerApi;
