import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createRepairApi = (maintenanceDoorID, repair) =>
  f3tch(`/maintenance-doors/${maintenanceDoorID}/repairs`)
    .profile(profile())
    .body(repair)
    .post();
export default createRepairApi;
