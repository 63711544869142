import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/file.profile.api';

const previewMaintenanceDoorApi = (maintenanceId, maintenanceDoorIds = []) =>
  f3tch(`/maintenances/${maintenanceId}/doors/preview`)
    .profile(profile())
    .query({maintenanceDoorIds})
    .put();
export default previewMaintenanceDoorApi;
