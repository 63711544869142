import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Container, Row, Column} from 'doorson-ui';
import Content from './components/Content';

const StatisticsHeader = ({children}) => (
  <Container>
    <Row>
      <Column>
        <Content>{children}</Content>
      </Column>
    </Row>
  </Container>
);

StatisticsHeader.propTypes = {
  children: PropTypes.node,
};

export default StatisticsHeader;
