import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {CheckBox} from 'doorson-ui';
import {Label} from 'doorson-ui';

import Header from './components/Header';

const Preview = ({
  customerLabel,
  customerTypeLabel,
  companyLabel,
  registrationNumberLabel,
  firstNameLabel,
  lastNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  branchLocationLabel,
  phoneLabel,
  emailLabel,

  manufacturerLabel,
  doorVersionLabel,
  doorTypeLabel,
  redundancyLabel,

  doorSerialLabel,
  electronicsSerialLabel,
  motorSerialLabel,
  findingsRemarksLabel,
  customerRemarksLabel,
  replacementNeededLabel,
  doorsWorkingLabel,
  prepareOfferLabel,

  confirmationLabel,
  transportLocationLabel,
  destinationLabel,
  signatoryFirstNameLabel,
  signatoryLastNameLabel,

  loading,
  maintenance,
  maintenanceDoor,
  resolveLabel,
  onResolve,
}) => (
  <Fragment>
    <Row margin>
      <Column>
        <Header>{customerLabel}</Header>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Label label={customerTypeLabel}>{maintenance.customer.type}</Label>
      </Column>
    </Row>
    {maintenance.customer.type === 'business' ? (
      <Row margin>
        <Column size={1 / 3}>
          <Label label={companyLabel}>{maintenance.customer.company}</Label>
        </Column>
        <Column size={1 / 3}>
          <Label label={registrationNumberLabel}>
            {maintenance.customer.registrationNumber}
          </Label>
        </Column>
      </Row>
    ) : (
      <Row margin>
        <Column size={1 / 3}>
          <Label label={firstNameLabel}>{maintenance.customer.firstName}</Label>
        </Column>
        <Column size={1 / 3}>
          <Label label={lastNameLabel}>{maintenance.customer.lastName}</Label>
        </Column>
      </Row>
    )}
    <Row margin>
      <Column size={2 / 3}>
        <Label label={addressLabel}>
          {maintenance.customer.location.address}
        </Label>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Label label={zipLabel}>{maintenance.customer.location.zip}</Label>
      </Column>
      <Column size={1 / 3}>
        <Label label={cityLabel}>{maintenance.customer.location.city}</Label>
      </Column>
      <Column size={1 / 3}>
        <Label label={countryLabel}>
          {maintenance.customer.location.countryCode}
        </Label>
      </Column>
    </Row>
    {!!maintenance.customerBranch && (
      <Row margin>
        <Column size={2 / 3}>
          <Label label={branchLocationLabel}>
            {maintenance.customerBranch.branchName},{' '}
            {maintenance.customerBranch.branchLocation.address},{' '}
            {maintenance.customerBranch.branchLocation.zip}{' '}
            {maintenance.customerBranch.branchLocation.city},{' '}
            {maintenance.customerBranch.branchLocation.countryCode}
          </Label>
        </Column>
      </Row>
    )}
    <Row margin>
      <Column size={1 / 3}>
        <Label label={phoneLabel}>{maintenance.phone || ''}</Label>
      </Column>
      <Column size={1 / 3}>
        <Label label={emailLabel}>{maintenance.email || ''}</Label>
      </Column>
    </Row>
    <Row margin>
      <Column>
        <Header>
          {maintenanceDoor.door.indoorLocation}{' '}
          {maintenanceDoor.door.buildingFloor}
        </Header>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Label label={manufacturerLabel}>
          {maintenanceDoor.door.product.manufacturer.name}
        </Label>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Label label={doorVersionLabel}>
          {maintenanceDoor.door.product.name}
        </Label>
      </Column>
      <Column size={1 / 3}>
        <Label label={doorTypeLabel}>
          {!!maintenanceDoor.door.productType
            ? maintenanceDoor.door.productType.name || ''
            : ''}
        </Label>
      </Column>
      <Column size={1 / 3}>
        <Label label={redundancyLabel}>
          {maintenanceDoor.door.redundancy ? 'Yes' : 'No'}
        </Label>
      </Column>
    </Row>
    {/* <Row margin>
      <Column size={2 / 3}>
        <Label label={indoorLocationLabel}>
          {maintenanceDoor.door.indoorLocation}
        </Label>
      </Column>
      <Column size={1 / 3}>
        <Label label={buildingFloorLabel}>
          {maintenanceDoor.door.buildingFloor}
        </Label>
      </Column>
    </Row> */}
    <Row margin>
      <Column size={2 / 9}>
        <Label label={doorSerialLabel}>{maintenanceDoor.door.doorSerial}</Label>
      </Column>
      <Column size={2 / 9}>
        <Label label={electronicsSerialLabel}>
          {maintenanceDoor.door.electronicsSerial}
        </Label>
      </Column>
      <Column size={2 / 9}>
        <Label label={motorSerialLabel}>
          {maintenanceDoor.door.motorSerial}
        </Label>
      </Column>
    </Row>
    <Row margin>
      <Column size={2 / 3}>
        <Label label={findingsRemarksLabel}>
          {maintenanceDoor.findingsAndRemarks || ''}
        </Label>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Label label={customerRemarksLabel}>
          {maintenanceDoor.customerRemarks || ''}
        </Label>
      </Column>
    </Row>
    <Row margin>
      <Column>
        <CheckBox
          selected={maintenanceDoor.replacementNeeded}
          onSelect={() => {}}
          disabled
        >
          {replacementNeededLabel}
        </CheckBox>
      </Column>
    </Row>
    <Row margin>
      <Column>
        <CheckBox
          selected={maintenanceDoor.workingProperly}
          onSelect={() => {}}
          disabled
        >
          {doorsWorkingLabel}
        </CheckBox>
      </Column>
    </Row>
    <Row margin>
      <Column>
        <CheckBox
          selected={maintenanceDoor.prepareOffer}
          onSelect={() => {}}
          disabled
        >
          {prepareOfferLabel}
        </CheckBox>
      </Column>
    </Row>
    <Row margin>
      <Column>
        <Header>{confirmationLabel}</Header>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 2}>
        <Label label={transportLocationLabel}>
          {maintenanceDoor.transportLocation.name}
        </Label>
      </Column>
      <Column size={1 / 2}>
        <Label label={destinationLabel}>
          {maintenanceDoor.transportDestination}
        </Label>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 2}>
        <Label label={signatoryFirstNameLabel}>
          {maintenanceDoor.signatoryFirstName}
        </Label>
      </Column>
      <Column size={1 / 2}>
        <Label label={signatoryLastNameLabel}>
          {maintenanceDoor.signatoryLastName}
        </Label>
      </Column>
    </Row>
    <Button theme="orange" loading={loading} onClick={onResolve} outline>
      {resolveLabel}
    </Button>
  </Fragment>
);

Preview.propTypes = {
  customerLabel: PropTypes.node,
  customerTypeLabel: PropTypes.node,
  companyLabel: PropTypes.node,
  registrationNumberLabel: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  branchLocationLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,

  manufacturerLabel: PropTypes.node,
  doorVersionLabel: PropTypes.node,
  doorTypeLabel: PropTypes.node,
  redundancyLabel: PropTypes.node,

  doorSerialLabel: PropTypes.node,
  electronicsSerialLabel: PropTypes.node,
  motorSerialLabel: PropTypes.node,
  findingsRemarksLabel: PropTypes.node,
  customerRemarksLabel: PropTypes.node,
  replacementNeededLabel: PropTypes.node,
  doorsWorkingLabel: PropTypes.node,
  prepareOfferLabel: PropTypes.node,

  confirmationLabel: PropTypes.node,
  transportLocationLabel: PropTypes.node,
  destinationLabel: PropTypes.node,
  signatoryFirstNameLabel: PropTypes.node,
  signatoryLastNameLabel: PropTypes.node,

  loading: PropTypes.bool,
  maintenance: PropTypes.object,
  maintenanceDoor: PropTypes.object,
  resolveLabel: PropTypes.string,
  onResolve: PropTypes.func,
};

export default Preview;
