import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui/lib/Grid/Grid';
import {AsyncInput, Input} from 'doorson-ui';

const OwnershipAndLocationInformation = ({
  error,
  loading,
  searchingForCustomer,
  serviceItem,
  customerName,
  customers,
  branchName,
  branches,
  noSuggestionLabel,
  customerNameLabel,
  branchNameLabel,
  indoorLocationLabel,
  buildingFloorLabel,
  onCustomer,
  onBranch,
  onChange,
}) => (
  <Row>
    <Column m={1}>
      <Row margin>
        <Column>
          <AsyncInput
            value={customerName}
            onChange={onChange('customerName')}
            error={error.customer}
            disabled={loading}
            suggestions={customers}
            onSuggestion={onCustomer}
            noSuggestionLabel={noSuggestionLabel}
            displaySuggestions={() => customerName.trim().length >= 3}
            searching={searchingForCustomer}
            required
          >
            {customerNameLabel}
          </AsyncInput>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Input
            value={branchName}
            onChange={onChange('branchName')}
            error={error.branchName}
            disabled={loading}
            suggestions={branches}
            onSuggestion={onBranch}
            noSuggestionLabel={noSuggestionLabel}
            displaySuggestions={() => branchName.trim().length >= 3}
            required
          >
            {branchNameLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column size={2 / 3}>
          <Input
            value={serviceItem.indoorLocation}
            onChange={onChange('indoorLocation', true)}
            error={error.indoorLocation}
            disabled={loading}
            required
          >
            {indoorLocationLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Input
            value={serviceItem.buildingFloor}
            onChange={onChange('buildingFloor', true)}
            error={error.buildingFloor}
            disabled={loading}
            required
          >
            {buildingFloorLabel}
          </Input>
        </Column>
      </Row>
    </Column>
  </Row>
);

OwnershipAndLocationInformation.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  searchingForCustomer: PropTypes.bool,
  serviceItem: PropTypes.object,
  customerName: PropTypes.string,
  customers: PropTypes.array,
  branchName: PropTypes.string,
  branches: PropTypes.array,
  noSuggestionLabel: PropTypes.string,
  customerNameLabel: PropTypes.string,
  branchNameLabel: PropTypes.string,
  indoorLocationLabel: PropTypes.string,
  buildingFloorLabel: PropTypes.string,
  onCustomer: PropTypes.func,
  onBranch: PropTypes.func,
  onChange: PropTypes.func,
};

export default OwnershipAndLocationInformation;
