import React from 'react';
import PropTypes from 'prop-types';

// Components
// Colors
import {Table} from 'doorson-ui';
import Container from './components/Container';
import {date as dateType} from "../../../types";

const WarehouseOrders = ({columns, sort, orders, onSort, onOrder}) => (
  <Container>
    <Table columns={columns} sort={sort} size='large' onSort={onSort}>
      {(TableRow) =>
        orders.map((item) => (
          <TableRow key={item.id}>
            {(TableCol) => [
              <TableCol smallPadding key="id" onClick={() => onOrder(item)}>
                {item.id.substr(0, 13)}...
              </TableCol>,
              <TableCol smallPadding span={2} key="requesterPersonId" onClick={() => onOrder(item)}>
                {item.requesterPerson}
              </TableCol>,
              <TableCol smallPadding span={2} key="sourceWarehouseId" onClick={() => onOrder(item)}>
                {item.sourceWarehouse}
              </TableCol>,
              <TableCol smallPadding span={2} key="targetWarehouseId" onClick={() => onOrder(item)}>
                {item.targetWarehouse}
              </TableCol>,
              <TableCol smallPadding key="dc" onClick={() => onOrder(item)}>
                {dateType(item.dc).format()}
              </TableCol>,
              <TableCol smallPadding key="collectEstTime" onClick={() => onOrder(item)}>
                {dateType(item.collectEstTime).format()}
              </TableCol>,
              <TableCol smallPadding key="status" onClick={() => onOrder(item)}>
                {item.status}
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
  </Container>
);

WarehouseOrders.propTypes = {
  sort: PropTypes.object,
  onSort: PropTypes.func,
  columns: PropTypes.array,
  onOrder: PropTypes.func,
  orders: PropTypes.array,
};

export default WarehouseOrders;
