import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// MaintenanceChecksAdminPage
import MaintenanceChecksAdminPage from '../MaintenanceChecksAdminPage/MaintenanceChecksAdminPage';
import routeMaintenanceChecksAdminPage from '../MaintenanceChecksAdminPage/route';

// Consts
import page from './page';

const MaintenanceChecksAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeMaintenanceChecksAdminPage()}
        component={MaintenanceChecksAdminPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default MaintenanceChecksAdminPages;
