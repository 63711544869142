import styled from 'styled-components';

const VehicleNoContainer = styled.div`
  position: absolute;
  top: 40%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
`;

export default VehicleNoContainer;
