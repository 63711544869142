import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createFaultApi = (fault) =>
  f3tch('/reported-faults')
    .profile(profile())
    .body(fault)
    .post();
export default createFaultApi;
