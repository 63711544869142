import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {black, white} = colors;

const Point = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin: 0px auto 5px auto;
  background: ${black};
  color: ${white};
  font-size: 11px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Point;
