import isNumber from 'is-number';

import type from '../type';

const format = (value) =>
  !!value || value === 0 || value === '0'
    ? `${value}`.replace(/[^0-9.]/g, '') * 1
    : null;

const validate = (value) => isNumber(value);

export default type({type: 'number', format, validate});
