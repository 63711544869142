import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateProductTypeApi =  (id, productType) =>
  f3tch(`/product-types/${id}`)
    .profile(profile())
    .body(productType)
    .put();
export default updateProductTypeApi;
