import PropTypes from 'prop-types';

// Local components
import DoorImageContainer from '../../DoorImageContainer/DoorImageContainer';

// Components
import UploadContainer from '../../../../maintenanceDoor/components/DoorInfo/components/UploadContainer';

const ServiceItemImage = ({serviceItem, onChange}) => (
  <UploadContainer>
    <DoorImageContainer
      canAddPictures={true}
      door={serviceItem}
      onChange={onChange}
    />
  </UploadContainer>
);

ServiceItemImage.propTypes = {
  serviceItem: PropTypes.object,
  onChange: PropTypes.func,
};

export default ServiceItemImage;
