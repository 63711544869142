import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';

import Wrapper from './components/Wrapper';
import Box from './components/Box';

const MaintenanceContractLayout = ({
  generate,
  customer,
  contract,
  branch,
  remove,
}) => (
  <Wrapper>
    <Box>
      <Row margin>
        <Column>{generate}</Column>
      </Row>
      <Row margin>
        <Column>{customer}</Column>
      </Row>
      <Row margin>
        <Column>{contract}</Column>
      </Row>
      <Row margin>
        <Column>{branch}</Column>
      </Row>
      <Row>
        <Column>{remove}</Column>
      </Row>
    </Box>
  </Wrapper>
);

MaintenanceContractLayout.propTypes = {
  generate: PropTypes.node,
  customer: PropTypes.node,
  contract: PropTypes.node,
  branch: PropTypes.node,
  remove: PropTypes.node,
};

export default MaintenanceContractLayout;
