import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {updateMaintenance as updateMaintenanceAct} from '../../../maintenance/redux/actions';

// Api
import assignApi from '../../../maintenance/api/assign.api.maintenance';
import unassignApi from '../../../maintenance/api/unassign.api.maintenance';

// Alertify
import {alertify} from 'doorson-ui';

// Components
import AssignUser from '../../components/AssignUser/AssignUser';

// Libs
import isResolved from '../../../maintenance/lib/isResolved.lib.maintenance';
import isActive from '../../../user/lib/isActive.lib.user';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SERVICE_TECHNICIAN, SYSTEM} from '../../roles/roles.user';
import hasPermission from '../../roles/hasPermission.role.user';

class AssignMaintenanceUsersContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    dispatch: PropTypes.func,
    users: PropTypes.array,
    user: PropTypes.object,
  };

  state = {
    loading: true,
    updating: [],
    availableUsers: [],
    assignees: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getAvailableUsers();
    this.setAssignees();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  sortUser = (a, b) => {
    const name = (user) =>
      !!user.label
        ? user.label
        : `${user.firstName} ${user.lastName}`.toUpperCase();
    const nameA = name(a);
    const nameB = name(b);
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  };

  getAvailableUsers = async () => {
    const {users} = this.props;
    const availableUsers = [...users].sort(this.sortUser).filter(isActive);
    this.setState({availableUsers, loading: false});
  };

  setAssignees = () => {
    const {maintenance} = this.props;
    const assignees = [...maintenance.assignees].sort(this.sortUser);
    this.setState({assignees});
  };

  availableUsers = () => {
    const {availableUsers, assignees} = this.state;
    return [...availableUsers].reduce(
      (combined, available) =>
        !assignees.find(({id}) => available.id === id) &&
        !combined.find(({id}) => available.id === id)
          ? [...combined, available]
          : combined,
      []
    );
  };

  formatUsers = (users) =>
    [...users]
      .map((user) => ({
        key: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }))
      .sort(this.sortUser);

  assign = async (userID) => {
    // TODO: I18n
    const {maintenance: oldMaintenance} = this.props;
    const {loading, updating, availableUsers} = this.state;
    const user = [...availableUsers].find(({id}) => id === userID);
    if (
      loading ||
      isResolved(oldMaintenance) ||
      updating.includes(userID) ||
      !user
    )
      return;

    this.setState({updating: [...this.state.updating, userID]});

    const stopLoading = () =>
      [...this.state.updating].filter((up) => up !== userID);

    try {
      const maintenance = await assignApi(oldMaintenance.id, userID);
      const updatedAvailableUsers = [...this.state.availableUsers].filter(
        ({id}) => id !== userID
      );
      const updatedAssignees = [...this.state.assignees, {...user}];
      const newUpdateObjects = stopLoading();
      this.props.dispatch(updateMaintenanceAct(maintenance));
      if (!this.mounted) return;
      this.setState({
        availableUsers: updatedAvailableUsers,
        assignees: updatedAssignees,
        updating: newUpdateObjects,
      });
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Could not assign user');
      this.setState({updating: stopLoading()});
    }
  };

  unassign = async (userID) => {
    // TODO: I18n
    const {maintenance: oldMaintenance} = this.props;
    const {loading, updating, assignees} = this.state;
    const user = [...assignees].find(({id}) => id === userID);
    if (
      loading ||
      isResolved(oldMaintenance) ||
      updating.includes(userID) ||
      !user
    )
      return;

    this.setState({updating: [...this.state.updating, userID]});

    const stopLoading = () =>
      [...this.state.updating].filter((up) => up !== userID);

    try {
      const maintenance = await unassignApi(oldMaintenance.id, userID);
      const updatedAssignees = [...this.state.assignees].filter(
        ({id}) => id !== userID
      );
      const updatedAvailableUsers = [...this.state.availableUsers, {...user}];
      const newUpdateObjects = stopLoading();
      this.props.dispatch(updateMaintenanceAct(maintenance));
      if (!this.mounted) return;
      this.setState({
        availableUsers: updatedAvailableUsers,
        assignees: updatedAssignees,
        updating: newUpdateObjects,
      });
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Could not unassign user');
      this.setState({updating: stopLoading()});
    }
  };

  render() {
    // TODO: I18n
    const {user, maintenance} = this.props;
    const {loading, updating, assignees} = this.state;
    return (
      <AssignUser
        loading={loading}
        updating={updating}
        resolved={isResolved(maintenance)}
        hasPermission={hasPermission({
          user,
          roles: [SERVICE_TECHNICIAN, CHIEF_SERVICE_TECHNICIAN, SYSTEM],
        })}
        availableLabel="Available"
        assignedLabel="Assigned"
        noPermissionLabel="You don't have permission to perform this"
        availableUsers={this.formatUsers(this.availableUsers())}
        assignees={this.formatUsers(assignees)}
        onAssign={this.assign}
        onUnassign={this.unassign}
      />
    );
  }
}

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
  users: state.user.users,
  user: state.auth.user,
}))(AssignMaintenanceUsersContainer);
