import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Api
import reverseGeocodingApi from '../../../maps/api/reverseGeocoding.api.maps';

// Components
import DeviceQuickView from '../../components/DeviceQuickView/DeviceQuickView';

class DeviceQuickViewContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    device: PropTypes.object,
    onClose: PropTypes.func,
  };

  state = {
    loadingLocation: true,
    device: null,
    location: null,
  };

  componentDidMount() {
    this.mounted = true;
    if (!!this.props.device) this.init();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.device && !!this.props.device) ||
      (!!prevProps.device &&
        !!this.props.device &&
        prevProps.device.id !== this.props.device.id)
    )
      this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    const {device} = this.props;
    if (!device) return;
    await this.setState({device});
    this.getLocation(device);
  };

  getLocation = async (device) => {
    try {
      const location = await reverseGeocodingApi(
        `${device.lastDeviceLocation.latitude},${device.lastDeviceLocation.longitude}`
      );
      if (!this.props.device || device.id !== this.props.device.id) return;
      this.setState({loadingLocation: false, location});
    } catch (error) {
      if (!this.props.device || device.id !== this.props.device.id) return;
      this.setState({loadingLocation: false, location: null});
    }
  };

  onClose = () => {
    const {visible, onClose} = this.props;
    const {device} = this.state;
    if (!device || !visible) return;
    onClose(device);
  };

  render() {
    const {visible} = this.props;
    const {device, loadingLocation, location} = this.state;
    return visible && !!device ? (
      <DeviceQuickView
        device={device}
        loadingLocation={loadingLocation}
        location={location}
        onClose={this.onClose}
      />
    ) : null;
  }
}

export default DeviceQuickViewContainer;
