import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const TopBar = styled.div`
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid ${grey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px;
`;

export default TopBar;
