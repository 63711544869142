import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const Delete = styled.i`
  font-size: 16px;
  cursor: pointer;
  color: ${darkGrey};
  cursor: pointer;
`;

export default Delete;
