import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal} from 'doorson-ui';

import Container from './components/Container';
import Loader from './components/Loader';
import Scanner from "react-webcam-qr-scanner";

const QrScanModal = ({
                       loading,
                       visible,
                       title,
                       onScan,
                       onStop,
                       onClose,
                     }) => (
  <Modal title={title} visible={visible} size="small" onClose={onClose}>
    {loading ? (
      <Container>
        <Loader className="mdi mdi-loading mdi-spin"/>
      </Container>
    ) : (
      <Fragment>
        {visible && <Scanner
          onDecode={(e) => onScan(e.data)}
          onScannerLoad={() => {
          }}
          constraints={{
            audio: false,
            video: {
              facingMode: "environment"
            }
          }}
          style={{width: 290, height: 290}}
        />}
      </Fragment>
    )}
  </Modal>
);

QrScanModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  title: PropTypes.string,
  onScan: PropTypes.func,
  onStop: PropTypes.func,
  onClose: PropTypes.func,
};

export default QrScanModal;
