import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import deleteApi from '../../api/delete.api.maintenanceDoor';
// Components
import MaintenanceDoorActions from '../../components/MaintenanceDoorActions/MaintenanceDoorActions';

// Routes
import maintenanceDoorRoute from '../../pages/MaintenanceDoorPage/route';
import maintenanceRoute from '../../../maintenance/pages/MaintenancePage/route';

// Roles
import {
  CHIEF_SERVICE_TECHNICIAN,
  SERVICE_TECHNICIAN,
  SYSTEM,
} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';
import DeleteMaintenanceDoorModal from '../../components/DeleteMaintenanceDoorModal/DeleteMaintenanceDoorModal';
import {updateMaintenance as updateMaintenanceAct} from '../../../maintenance/redux/actions';

class DoorActionsContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    maintenanceDoor: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    inited: false,
    deleting: false,
    deleteConfirmationVisible: false,
    error: {},
  };

  componentDidMount() {
    const {maintenanceDoor, user, history} = this.props;
    if (
      !hasPermission({
        user,
        roles: [CHIEF_SERVICE_TECHNICIAN, SERVICE_TECHNICIAN, SYSTEM],
      })
    )
      return history.replace(maintenanceDoorRoute(maintenanceDoor.id));

    this.setState({inited: true});
  }

  delete = async () => {
    // TODO: I18n
    const {maintenanceDoor, maintenance, history} = this.props;
    const {deleting} = this.state;

    if (deleting) return;

    try {
      await deleteApi(maintenanceDoor.id);
      this.props.dispatch(
        updateMaintenanceAct({
          ...maintenance,
          doors: [...maintenance.doors].filter(
            (d) => d.id !== maintenanceDoor.id
          ),
        })
      );
      history.push(maintenanceRoute(maintenance.id));
      alertify.success('Door removed from maintenance');
    } catch (error) {
      alertify.error('Door could not be removed form maintenance');
      this.setState({deleting: false});
    }
  };

  showDeleteConfirm = () => {
    if (this.state.deleting) return;
    this.setState({deleteConfirmationVisible: true});
  };

  hideDeleteConfirm = () => {
    if (this.state.deleting) return;
    this.setState({deleteConfirmationVisible: false});
  };

  render() {
    // TODO: I18n
    const {user} = this.props;
    const {inited, error, deleting, loading, deleteConfirmationVisible} =
      this.state;

    const isSuperUser = hasPermission({
      user,
      roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
    });

    return !inited ? null : (
      <Fragment>
        <MaintenanceDoorActions
          loading={loading}
          error={error}
          isSuperUser={isSuperUser}
          deleteLabel="Remove Door from maintenance"
          onDelete={this.showDeleteConfirm}
        />
        <DeleteMaintenanceDoorModal
          loading={deleting}
          visible={deleteConfirmationVisible}
          title="Delete Door from maintenance"
          deleteLabel="Delete"
          onDelete={this.delete}
          onClose={this.hideDeleteConfirm}
        >
          Are you sure you want to remove this door from maintenance?
        </DeleteMaintenanceDoorModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
  maintenanceDoor: state.maintenanceDoor.maintenanceDoor,
  user: state.auth.user,
  users: state.user.users,
}))(withRouter(DoorActionsContainer));
