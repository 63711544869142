import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';
import {Select} from 'doorson-ui';

const NewCustomerBranchModal = ({
  title,
  branchNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  saveLabel,

  countries,

  visible,
  loading,
  error,
  branchName,
  address,
  zip,
  city,
  country,

  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="large">
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Input
            value={branchName}
            onChange={onChange('branchName')}
            error={error.branchName}
            disabled={loading}
            required
          >
            {branchNameLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Input
            value={address}
            onChange={onChange('address')}
            error={error.address}
            disabled={loading}
            required
          >
            {addressLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 3}>
          <Input
            value={zip}
            onChange={onChange('zip')}
            error={error.zip}
            disabled={loading}
            required
          >
            {zipLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Input
            value={city}
            onChange={onChange('city')}
            error={error.city}
            disabled={loading}
            required
          >
            {cityLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Select
            value={country}
            options={countries}
            onChange={onChange('country')}
            error={error.country}
            disabled={loading}
            required
          >
            {countryLabel}
          </Select>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

NewCustomerBranchModal.propTypes = {
  title: PropTypes.string,
  branchNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  saveLabel: PropTypes.string,

  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),

  visible: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object,
  branchName: PropTypes.string,
  address: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,

  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default NewCustomerBranchModal;
