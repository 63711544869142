import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${orange};
  background: rgba(255, 255, 255, 0.9);
  font-size: 40px;
`;

export default Loader;
