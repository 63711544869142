import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export default Content;
