import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createCustomerApi = (customer) =>
  f3tch('/customers')
    .profile(profile())
    .body(customer)
    .post();
export default createCustomerApi;
