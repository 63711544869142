import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Box = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  flex: 1;
  -webkit-overflow-scrolling: touch;
  background: ${white};
`;

export default Box;
