import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {black, orange} = colors;

const Icon = styled.i`
  font-size: 16px;
  color: ${({selected}) => (selected ? orange : black)};
  margin-left: 4px;
`;

export default Icon;
