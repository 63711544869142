import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createDoorAccessoryApi = (doorId, accessory, query = {rel: '*'}) =>
  f3tch(`/doors/${doorId}/accessories`)
    .profile(profile())
    .query(query)
    .body(accessory).post();
export default createDoorAccessoryApi;
