import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createTransportLocationApi =  (transportLocation) =>
  f3tch('/transport-locations')
    .profile(profile())
    .body(transportLocation)
    .post();
export default createTransportLocationApi;
