import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import PreLoader from '../../../layout/components/PreLoader/PreLoader';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import QuickActions from '../QuickActions/QuickActions';

import Container from './components/Container';

// Customer
import getCustomerName from '../../../customer/lib/getName.lib.customer';

// Maintenance
import getPriority from '../../priority/getPriority.priority.maintenance';

// Types
import {date as dateType} from '../../../types';
import GpsNavigation from "../../../layout/components/GpsNavigation/GpsNavigation";

// TODO: I18N
const Maintenances = ({
                        loading,
                        more,
                        scroller,
                        assigning,
                        user,
                        users,
                        sort,
                        columns,
                        dateColumn,
                        maintenances,
                        onMaintenance,
                        onAction,
                        onSort,
                        onMore,
                      }) => (
  <Container>
    <PreLoader
      scroller={scroller}
      loading={loading}
      showLoader={!!maintenances.length}
      more={more}
      onMore={onMore}
    >
      <Table columns={columns} loading={loading} sort={sort} onSort={onSort}>
        {(TableRow) =>
          maintenances.map((maintenance) => (
            <TableRow
              key={maintenance.id}
              label={
                maintenance.resolved || maintenance.closed
                  ? null
                  : getPriority(maintenance).color
              }
              theme={
                maintenance.resolved || maintenance.closed ? 'greyer' : 'white'
              }
            >
              {(TableCol) => [
                <TableCol
                  key="priority"
                  onClick={() => onMaintenance(maintenance)}
                />,
                <TableCol
                  span={1.5}
                  key="code"
                  onClick={() => onMaintenance(maintenance)}
                >
                  {maintenance.code}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key={dateColumn}
                  onClick={() => onMaintenance(maintenance)}
                >
                  {dateType(maintenance[dateColumn]).format()}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="customer"
                  onClick={() => onMaintenance(maintenance)}
                >
                  {getCustomerName(maintenance.customer)}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="location"
                  onClick={() => onMaintenance(maintenance)}
                >
                  {!!maintenance.customerBranch.branchLocation && (
                    <span>
                      {!!maintenance.customerBranch.branchName && (
                        <span>
                          {maintenance.customerBranch.branchName}
                          <br/>
                        </span>
                      )}
                      {maintenance.customerBranch.branchLocation.address}
                      <br/>
                      {maintenance.customerBranch.branchLocation.city}
                      <GpsNavigation location={maintenance.customerBranch.branchLocation}/>
                    </span>
                  )}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="doorType"
                  onClick={() => onMaintenance(maintenance)}
                >
                  {maintenance.doors.length > 1
                    ? 'Multiple doors'
                    : maintenance.doors.map((door) => (
                      <div key={door.id}>
                        {door.door.product.manufacturer.name}{' '}
                        {door.door.product.name}
                      </div>
                    ))}
                </TableCol>,
                <TableCol span={1.5} key="action">
                  <QuickActions
                    loading={assigning.includes(maintenance.id)}
                    maintenance={maintenance}
                    priorityColor={getPriority(maintenance).color}
                    users={users}
                    user={user}
                    onAction={onAction}
                  />
                </TableCol>,
              ]}
            </TableRow>
          ))
        }
      </Table>
    </PreLoader>
    {!loading && !maintenances.length && (
      <NoDataFound>No maintenances found</NoDataFound>
    )}
  </Container>
);

Maintenances.propTypes = {
  loading: PropTypes.bool,
  more: PropTypes.bool,
  scroller: PropTypes.object,
  assigning: PropTypes.array,
  user: PropTypes.object,
  users: PropTypes.array,
  sort: PropTypes.object,
  columns: PropTypes.array,
  dateColumn: PropTypes.string,
  maintenances: PropTypes.array,
  onMaintenance: PropTypes.func,
  onSort: PropTypes.func,
  onAction: PropTypes.func,
  onMore: PropTypes.func,
};

export default Maintenances;
