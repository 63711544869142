import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
// Consts
import page from './page';
import InventoryContainer from "../../containers/InventoryContainer";

const InventoryPage = () => (
  <Page page={page}>
    <InventoryContainer/>
  </Page>
);

export default InventoryPage;
