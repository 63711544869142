import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import NewMaintenanceContainer from '../../containers/NewMaintenanceContainer/NewMaintenanceContainer';

// Consts
import page from './page';

const NewMaintenancePage = () => (
  <Page page={page}>
    <NewMaintenanceContainer />
  </Page>
);

export default NewMaintenancePage;
