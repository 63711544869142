import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

const Network = ({online}) => (
  <Container online={online}>No Internet Connection</Container>
);

Network.propTypes = {
  online: PropTypes.bool,
};

export default Network;
