import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Alerts
import {alertify} from 'doorson-ui';

// Actions
import {updateIntervention as updateInterventionAct} from '../../redux/actions';

// Api
import updateApi from '../../api/update.api.intervention';
import deleteApi from '../../api/delete.api.intervention';
import waitForExternalApi from '../../api/waitForExternal.api.intervention';

// Components
import InterventionActions from '../../components/InterventionActions/InterventionActions';
import DeleteInterventionModal from '../../components/DeleteInterventionModal/DeleteInterventionModal';

// Libs
import isResolved from '../../lib/isResolved.lib.intervention';
import isAssigned from '../../lib/isAssigned.lib.intervention';

// Routes
import interventionRoute from '../../pages/InterventionPage/route';
import interventionsRoute from '../../pages/InterventionsPage/route';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SERVICE_TECHNICIAN, SYSTEM} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

class InterventionActionsContainer extends Component {
  static propTypes = {
    intervention: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    inited: false,
    deleting: false,
    saving: false,
    deleteConfirmationVisible: false,
    statusReason: '',
    error: {},
  };

  componentDidMount() {
    const {intervention, user, history} = this.props;
    if (
      !hasPermission({
        user,
        roles: [CHIEF_SERVICE_TECHNICIAN, SERVICE_TECHNICIAN, SYSTEM],
      })
    )
      return history.replace(interventionRoute(intervention.id));

    this.setState({inited: true, statusReason: intervention.statusReason});
  }

  syncIntervention = ({...data} = {}) => {
    const {intervention, dispatch} = this.props;
    dispatch(updateInterventionAct({...intervention, ...data}));
  };

  delete = async () => {
    // TODO: I18n
    const {intervention, history} = this.props;
    const {deleting} = this.state;

    if (deleting) return;

    try {
      await deleteApi(intervention.id);
      history.push(interventionsRoute());
      alertify.success('Intervention deleted');
    } catch (error) {
      alertify.error('Intervention could not be deleted');
      this.setState({deleting: false});
    }
  };

  showDeleteConfirm = () => {
    if (this.state.deleting) return;
    this.setState({deleteConfirmationVisible: true});
  };

  hideDeleteConfirm = () => {
    if (this.state.deleting) return;
    this.setState({deleteConfirmationVisible: false});
  };

  onChange = (key) => async (val) => {
    if (this.state.saving) return;
    if (key === 'assign') {
      const {statusReason} = this.state;
      if (!statusReason) {
        this.setState({error: {...this.state.error, statusReason: 'Should not be empty'}})
        return;
      }

      try {
        this.setState({saving: true})
        const {intervention} = this.props;
        const newIntervention = await waitForExternalApi(intervention.id, val, statusReason);
        this.syncIntervention(newIntervention);
        alertify.success('Intervention is now waiting for external');
      } catch (e) {
        alertify.error('Intervention could not be updated');
        console.log(e);
      } finally {
        this.setState({saving: false})
      }
    } else {
      this.setState({[key]: val, error: {...this.state.error, [key]: null}});
    }
  }

  onPriority = async (stringPriority) => {
    const priority = stringPriority * 1;
    if (this.state.saving) return;

    this.setState({saving: true});

    const oldIntervention = {...this.props.intervention};
    const tempIntervention = {...oldIntervention, priority};

    this.syncIntervention(tempIntervention);

    const {
      id,
      version,
      transportLocation,
      transportDestination,
      signatoryFirstName,
      signatoryLastName,
    } = this.props.intervention;

    try {
      const intervention = await updateApi(id, {
        priority,
        version,
        transportLocationId: !!transportLocation ? transportLocation.id : null,
        transportDestination,
        signatoryFirstName,
        signatoryLastName,
      });
      this.syncIntervention(intervention);
    } catch (error) {
      alertify.error('Could not change the priority');
      this.syncIntervention(oldIntervention);
    }

    this.setState({saving: false});
  };

  // TODO: I18n
  priorities = () => [
    {
      value: 1,
      label: 'Low',
    },
    {
      value: 2,
      label: 'Medium',
    },
    {
      value: 3,
      label: 'High',
    },
  ];

  render() {
    // TODO: I18n
    const {intervention, user, users} = this.props;
    const {inited, error, deleting, saving, deleteConfirmationVisible, statusReason} = this.state;
    const isSuperUser = hasPermission({
      user,
      roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
    })
    return !inited ? null : (
      <Fragment>
        <InterventionActions
          loading={saving}
          error={error}
          isSuperUser={isSuperUser}
          assigned={isAssigned(user, intervention)}
          users={users}
          priorityLabel="Priority"
          deleteLabel="Delete Intervention"
          waitForExternalLabel="Wait for external and assign"
          statusReasonLabel="Waiting for external reason"
          statusReason={statusReason}
          priority={intervention.priority}
          priorities={this.priorities()}
          resolved={isResolved(intervention)}
          onPriority={this.onPriority}
          onDelete={this.showDeleteConfirm}
          onChange={this.onChange}
        />
        <DeleteInterventionModal
          loading={deleting}
          visible={deleteConfirmationVisible}
          title="Delete Intervention"
          deleteLabel="Delete"
          onDelete={this.delete}
          onClose={this.hideDeleteConfirm}
        >
          Are you sure you want to delete this intervention?
        </DeleteInterventionModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  intervention: state.intervention.intervention,
  user: state.auth.user,
  users: state.user.users,
}))(withRouter(InterventionActionsContainer));
