import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const unassignInterventionApi = (interventionID, assigneeId, query = {rel: '*'}) =>
  f3tch(`/interventions/${interventionID}/unassign`)
    .profile(profile())
    .query(query)
    .body({assigneeId})
    .put();
export default unassignInterventionApi;
