import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkerGrey} = colors;

const Name = styled.div`
  font-size: 20px;
  color: ${darkerGrey};
`;

export default Name;
