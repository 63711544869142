import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isEmail} from 'validator';

// Api
import sendContractApi from '../../api/sendContract.api.intervention';

// Alertify
import {alertify} from 'doorson-ui';

// Components
import SendContractModal from '../../components/SendContractModal/SendContractModal';

class SendContractContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    intervention: PropTypes.object,
    onFinish: PropTypes.func,
    onClose: PropTypes.func,
  };

  state = {
    loading: false,
    receiver: '',
  };

  onChange = (receiver) => {
    if (this.state.loading) return;
    this.setState({receiver});
  };

  onClose = () => {
    if (this.state.loading) return;
    this.setState({receiver: ''});
    this.props.onClose();
  };

  save = async () => {
    // TODO: I18n
    const {
      intervention: {id},
      onFinish,
    } = this.props;
    const {loading, receiver} = this.state;

    if (loading) return;

    if (!isEmail(receiver))
      return alertify.warning('Enter a valid email address');

    this.setState({loading: true});

    try {
      const intervention = await sendContractApi(id, [receiver]);
      this.setState({loading: false, receiver: ''});
      onFinish(intervention);
    } catch (error) {
      alertify.error('Contract could not be sent');
      this.setState({loading: false});
    }
  };

  render() {
    const {visible} = this.props;
    const {loading, receiver} = this.state;
    return (
      <SendContractModal
        title="Send Contract"
        children="Who is the contract receiver?"
        emailLabel="Email"
        saveLabel="Send"
        visible={visible}
        loading={loading}
        receiver={receiver}
        onChange={this.onChange}
        onClose={this.onClose}
        onSave={this.save}
      />
    );
  }
}

export default SendContractContainer;
