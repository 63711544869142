const rum = () => ({
    enabled: 'true' === `${process.env.REACT_APP_RUM_ENABLED}`,
    guestRoleArn: `${process.env.REACT_APP_RUM_GUEST_ROLE_ARN}`,
    identityPoolId: `${process.env.REACT_APP_RUM_IDENTITY_POOL_ID}`,
    endpoint: `${process.env.REACT_APP_RUM_ENDPOINT}`,
    appId: `${process.env.REACT_APP_RUM_APP_ID}`,
    appVersion: `${process.env.REACT_APP_RUM_APP_VERSION}`,
    region: `${process.env.REACT_APP_RUM_REGION}`,
});
export default rum;
