import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Image from './components/Image';

const Preview = ({visible, image, onClose}) => (
  <Container visible={visible} onClick={onClose}>
    <Image src={image} />
  </Container>
);

Preview.propTypes = {
  visible: PropTypes.bool,
  image: PropTypes.string,
  onClose: PropTypes.func,
};

export default Preview;
