import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Column, Container, Row} from 'doorson-ui';
import Content from './components/Content';
import Right from "./components/Right";

const ServiceItemsHeader = ({children, onClick, disabled, scan, scanLabel}) => (
  <Container>
    <Row>
      <Column>
        <Content onClick={onClick}>{children}</Content>
        <Right>
          <Button
            size="normal"
            theme="blue"
            preIcon="qrcode"
            disabled={disabled}
            outline={true}
            onClick={scan}>
            {scanLabel}
          </Button>
        </Right>
      </Column>
    </Row>
  </Container>
);

ServiceItemsHeader.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  scan: PropTypes.func,
  scanLabel: PropTypes.string
};

export default ServiceItemsHeader;
