import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Actions
import {set as setAct} from '../../../manufacturer/redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.product';
import deleteApi from '../../api/delete.api.product';

// Components
import AdminProducts from '../../components/AdminProducts/AdminProducts';
import UpdateProductModal from '../../components/UpdateProductModal/UpdateProductModal';
import DeleteProductModal from '../../components/DeleteProductModal/DeleteProductModal';

// Routes
import manufacturersRoute from '../../../manufacturer/pages/ManufacturersAdminPage/route';
import productRoute from '../../pages/ProductAdminPage/route';

class ProductsContainer extends Component {
  static propTypes = {
    manufacturers: PropTypes.array,
    manufacturerID: PropTypes.string,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    loading: false,
    updateID: null,
    name: '',
    code: '',
    version: 0,
    manufacturer: null,
    removeID: null,
  };

  componentDidMount() {
    const {manufacturers, manufacturerID, history} = this.props;
    const manufacturer = [...manufacturers].find(
      ({id}) => id === manufacturerID
    );
    if (!manufacturer) {
      alertify.warning('Manufacturer does not exist');
      history.replace(manufacturersRoute());
      return;
    }
    this.setState({manufacturer});
  }

  // TODO: I18n
  columns = () => [
    {
      key: 'name',
      label: 'Name',
      span: 2,
    },
    {
      key: 'code',
      label: 'Code',
      span: 2,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  showUpdate = (product) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: product.id,
      name: product.name,
      code: product.code,
      version: product.version,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  back = () => this.props.history.push(manufacturersRoute());

  products = () => {
    const {manufacturers, manufacturerID} = this.props;
    const manufacturer = [...manufacturers].find(
      ({id}) => id === manufacturerID
    );
    return !!manufacturer ? [...manufacturer.products] : [];
  };

  onProduct = (product) => () =>
    this.props.history.push(
      productRoute(this.props.manufacturerID, product.id)
    );

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  update = async () => {
    const {loading, updateID, name, code, version} = this.state;
    if (loading || !updateID) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const product = await updateApi(updateID, {name, code, version});
      const {dispatch, manufacturers} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          manufacturers: [...manufacturers].map((manufacturer) =>
            manufacturer.id === product.manufacturer.id
              ? {
                  ...manufacturer,
                  products: [...manufacturer.products].map((p) =>
                    p.id === product.id ? product : p
                  ),
                }
              : manufacturer
          ),
        })
      );
    } catch (error) {
      alertify.error('Product could not be updated');
      this.setState({loading: false});
    }
  };

  remove = async () => {
    const {manufacturerID} = this.props;
    const {loading, removeID} = this.state;
    if (loading || !removeID) return;

    this.setState({loading: true});

    try {
      await deleteApi(removeID);
      const {dispatch, manufacturers} = this.props;
      this.setState({loading: false, removeID: null});
      dispatch(
        setAct({
          manufacturers: [...manufacturers].map((manufacturer) =>
            manufacturer.id === manufacturerID
              ? {
                  ...manufacturer,
                  products: [...manufacturer.products].filter(
                    (p) => p.id !== removeID
                  ),
                }
              : manufacturer
          ),
        })
      );
    } catch (error) {
      alertify.error('Product could not be removed');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {loading, updateID, name, code, removeID, manufacturer} = this.state;
    return !manufacturer ? null : (
      <Fragment>
        <AdminProducts
          columns={this.columns()}
          title={manufacturer.name}
          products={this.products()}
          onTitle={this.back}
          onProduct={this.onProduct}
          onUpdate={this.showUpdate}
          onDelete={this.showDelete}
        />
        <UpdateProductModal
          title="Update Product"
          nameLabel="Name"
          codeLabel="Code"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          name={name}
          code={code}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
        <DeleteProductModal
          title="Delete Product"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this product?
        </DeleteProductModal>
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({...state.manufacturer}))(ProductsContainer)
);
