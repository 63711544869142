import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Button} from 'doorson-ui';

const DeleteMaintenanceModal = ({
  visible,
  loading,
  children,
  title,
  deleteLabel,
  onDelete,
  onClose,
}) => (
  <Modal title={title} visible={visible} onClose={onClose} size="small">
    <Row margin>
      <Column>{children}</Column>
    </Row>
    <Row>
      <Column>
        <Button theme="red" loading={loading} onClick={onDelete}>
          {deleteLabel}
        </Button>
      </Column>
    </Row>
  </Modal>
);

DeleteMaintenanceModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.node,
  deleteLabel: PropTypes.node,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
};

export default DeleteMaintenanceModal;
