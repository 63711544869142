import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import DocumentationContainer from '../../containers/DocumentationContainer/DocumentationContainer';

// Consts
import page from './page';

const DocumentationSubPage = ({
  match: {
    params: {id},
  },
}) => (
  <Page page={page}>
    <DocumentationContainer documentationID={id} />
  </Page>
);

export default DocumentationSubPage;
