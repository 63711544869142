import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteMaterialApi = (maintenanceDoorID, materialID) =>
  f3tch(`/maintenance-doors/${maintenanceDoorID}/materials/${materialID}`)
    .profile(profile())
    .delete();
export default deleteMaterialApi;
