import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Container = styled.div`
  width: 100%;
  height: 300px;
  padding: 15px;
  background: ${white};
  cursor: pointer;
  font-size: 15px;
  user-select: none;
  position: relative;
`;

export default Container;
