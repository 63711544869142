import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteInterventionDoorApi = (interventionDoorId) =>
  f3tch(`/intervention-doors/${interventionDoorId}`)
    .profile(profile())
    .delete();
export default deleteInterventionDoorApi;
