import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import MaintenanceActionsContainer from '../../containers/MaintenanceActionsContainer/MaintenanceActionsContainer';

// TODO: I18n
const MaintenanceActionsPage = ({match}) => (
  <SubPage page={match.path}>
    <MaintenanceActionsContainer />
  </SubPage>
);

MaintenanceActionsPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(MaintenanceActionsPage);
