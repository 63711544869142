import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {formatDistanceToNow, toDate} from 'date-fns';

// Actions
import {readActivities as readActivitiesAct, set as setAct,} from '../../redux/actions';

// Activity Actions
import executeActivity from '../../actions/execute.activity';

// Api
import listActivitiesApi from '../../api/list.api.activity';
import readAllActivitiesApi from '../../api/readAll.api.activity';
import createMessage from '../../api/create.api.message';

// Constants
import API_LIMIT from '../../constants/apiLimit.constant.activity';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import ActivityLoader from '../../components/ActivityLoader/ActivityLoader';
import ActivityHeader from '../../components/ActivityHeader/ActivityHeader';
import Activities from '../../components/Activities/Activities';

// Libs
import parseMessage from '../../lib/parseMessage.lib.activity';
import SendMessageModal from "../../components/SendMessageModal/SendMessageModal";
import {alertify} from "doorson-ui";

class ActivitiesContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool,
    activities: PropTypes.array,
    unreadActivities: PropTypes.number,
    lastUpdate: PropTypes.number,
    more: PropTypes.bool,
  };

  state = {
    loadingMore: false,
    activities: [],
    loadedActivities: [],
    more: true,
    page: 1,
    messageModalVisible: false,
    message: {},
    messageLoading: false,
    error: {}
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastUpdate !== this.props.lastUpdate) this.setActivities();
  }

  componentWillUnmount() {
    this.props.dispatch(readActivitiesAct());
  }

  init = () => {
    this.setActivities();
    this.readActivities();
  };

  setActivities = () => {
    const {activities} = this.props;
    const {loadedActivities} = this.state;
    this.setState({
      activities: this.parseActivities([...activities, ...loadedActivities]),
    });
  };

  readActivities = async () => {
    const {user, dispatch} = this.props;
    try {
      await readAllActivitiesApi(user.id);
      dispatch(setAct({unreadActivities: 0}));
    } catch (error) {
      console.error(error)
    }
  };

  parseActivities = (activities) =>
    [...activities].map((activity) => ({
      ...activity,
      icon: !!activity.icon
        ? activity.icon
        : 'https://www.w3schools.com/w3images/avatar2.png',
      message: parseMessage(activity),
      datePosted: formatDistanceToNow(toDate(activity.dc)),
    }));

  // TODO: I18n
  renderHeader = () => {
    return <ActivityHeader
      onShowMessageModal={this.onShowMessageModal}
      sendMessageLabel="Send message">Activity</ActivityHeader>;
  }

  onActivity = (activity) => () => executeActivity(activity);

  onShowMessageModal = () => this.setState({messageModalVisible: true})

  closeMessageModal = () => this.setState({messageModalVisible: false, messageLoading:false, message: {}})

  sendMessage = async () => {
    const {messageLoading, message} = this.state;

    if (messageLoading) return;
    this.setState({messageLoading: true});

    const error = {}
    if (!message.message)
      error.message = 'Missing message';
    if (!message.userId)
      error.userId = 'Select recipient user';

    if (!!Object.keys(error).length) {
      this.setState({error});
      alertify.warning('Insert all the required data properly');
      return;
    }

    const create = {
      receiverUserId: message.userId,
      message: message.message,
    }

    try {
      await createMessage(create, {sms: !!message.sms});
      alertify.info('Message sent successfully');
    } catch (error) {
      alertify.error('Cannot send a message. Try again later.');
      console.error(error);
    }
    this.closeMessageModal();
  }

  users = () => {
    const {users, user} = this.props;
    return [...users].filter(u => !u.dismissedSince && u.id !== user.id).map(a => ({
      value: a.id,
      label: a.firstName + ' ' + a.lastName
    }));
  }

  onChange = (key) => (val) => this.setState({message: {...this.state.message, [key]: val}})

  onMore = async () => {
    const {loading, user} = this.props;
    const {loadingMore, more, page: oldPage} = this.state;

    if (loading || !more || loadingMore) return;

    const page = oldPage + 1;
    this.setState({loadingMore: true, page});

    try {
      const newlyFetchedActivities = await listActivitiesApi(user.id, {
        limit: API_LIMIT,
        offset: (page - 1) * API_LIMIT,
        order: 'dc DESC',
      });
      const loadedActivities = [
        ...this.state.loadedActivities,
        ...newlyFetchedActivities,
      ];
      const newActivities = [...this.props.activities, ...loadedActivities];
      this.setState({
        loadingMore: false,
        more: !!newlyFetchedActivities.length,
        activities: this.parseActivities(newActivities),
        loadedActivities,
      });
    } catch (error) {
      this.setState({loadingMore: false});
    }
  };

  render() {
    const {loading, more: previousMore} = this.props;
    const {loadingMore, activities, more, messageModalVisible, message, messageLoading, error} = this.state;
    return loading && !activities.length ? (
      <ActivityLoader/>
    ) : (
      <ContentLayout header={this.renderHeader()}>
        <Activities
          loading={loading || loadingMore}
          more={previousMore && more}
          activities={activities}
          onActivity={this.onActivity}
          onMore={this.onMore}
          noResultsLabel="No activities found"
          moreLabel="Load More"
        />
        <SendMessageModal
          title="Send message"
          visible={messageModalVisible}
          messageLoading={messageLoading}
          buttonLabel="Send"
          smsLabel="Include sms"
          sms={message.sms}
          userLabel="Select user"
          userId={message.userId}
          users={this.users()}
          messageLabel="Message"
          message={message.message}
          onChange={this.onChange}
          error={error}
          onSend={this.sendMessage}
          onClose={this.closeMessageModal}
        />
      </ContentLayout>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  users: state.user.users,
  ...state.activity
}))(
  ActivitiesContainer
);
