import {format} from 'date-fns';

const getTodaysVehicleByDriver = ({vehicles = [], driverId = null, date = new Date()} = {}) => {
  const today = format(date, 'yyyy-MM-dd');
  const vehicle = [...vehicles].find(
    ({rentals}) =>
      !!rentals.find(
        (rental) =>
          rental.commuter.id === driverId && rental.dateSelected === today
      )
  );
  if (!vehicle) return null;
  const rental = [...vehicle.rentals].find(
    (rental) => rental.commuter.id === driverId && rental.dateSelected === today
  );
  return {...vehicle, rental};
};
export default getTodaysVehicleByDriver;
