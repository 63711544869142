import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Info} from 'doorson-ui';

const MaintenanceContractCustomerInfo = ({
  companyLabel,
  registrationNumberLabel,
  firstNameLabel,
  lastNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  phoneLabel,
  emailLabel,

  customer,
}) => (
  <Fragment>
    {customer.type === 'business' ? (
      <Row margin>
        <Column size={2 / 3}>
          <Info label={companyLabel}>{!!customer ? customer.company : ''}</Info>
        </Column>
        <Column size={1 / 3}>
          <Info label={registrationNumberLabel}>
            {!!customer ? customer.registrationNumber : ''}
          </Info>
        </Column>
      </Row>
    ) : (
      <Row margin>
        <Column size={1 / 2}>
          <Info label={firstNameLabel}>
            {!!customer ? customer.firstName : ''}
          </Info>
        </Column>
        <Column size={1 / 2}>
          <Info label={lastNameLabel}>
            {!!customer ? customer.lastName : ''}
          </Info>
        </Column>
      </Row>
    )}
    <Row margin>
      <Column size={2 / 3}>
        <Info label={addressLabel}>
          {!!customer ? customer.location.address : ''}
        </Info>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Info label={zipLabel}>{!!customer ? customer.location.zip : ''}</Info>
      </Column>
      <Column size={1 / 3}>
        <Info label={cityLabel}>
          {!!customer ? customer.location.city : ''}
        </Info>
      </Column>
      <Column size={1 / 3}>
        <Info label={countryLabel}>
          {!!customer ? customer.location.countryCode : ''}
        </Info>
      </Column>
    </Row>
    <Row>
      <Column size={1 / 3}>
        <Info label={phoneLabel}>{!!customer ? customer.phone || '' : ''}</Info>
      </Column>
      <Column size={1 / 3}>
        <Info label={emailLabel}>{!!customer ? customer.email || '' : ''}</Info>
      </Column>
    </Row>
  </Fragment>
);

MaintenanceContractCustomerInfo.propTypes = {
  registrationNumberLabel: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,

  customer: PropTypes.object,
};

export default MaintenanceContractCustomerInfo;
