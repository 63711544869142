import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Column, Container, Row} from 'doorson-ui';
import Content from "./components/Content";
import SubHeaderContent from "./components/SubHeaderContent";

const WarehouseIdentsHeader = ({name, address}) => (
  <Container>
    <Row>
      <Column>
        <Content>{name}<SubHeaderContent>{address}</SubHeaderContent></Content>
      </Column>
    </Row>
  </Container>
);

WarehouseIdentsHeader.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string
};

export default WarehouseIdentsHeader;
