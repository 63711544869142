import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import WorklogContainer from '../../../interventionDoor/container/WorklogContainer/WorklogContainer';

// TODO: I18n
const InterventionWorkLogPage = ({match}) => (
  <SubPage page={match.path}>
    <WorklogContainer />
  </SubPage>
);

InterventionWorkLogPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(InterventionWorkLogPage);
