import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/file.profile.api';

const downloadFileApi = (id, output = 'NATIVE') =>
  f3tch(`/files/${id}`)
    .profile(profile({output}))
    .get();
export default downloadFileApi;
