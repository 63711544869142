import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.transportLocation';
import deleteApi from '../../api/delete.api.transportLocation';

// Components
import AdminTransportLocations from '../../components/AdminTransportLocations/AdminTransportLocations';
import UpdateTransportLocationModal from '../../components/UpdateTransportLocationModal/UpdateTransportLocationModal';
import DeleteTransportLocationModal from '../../components/DeleteTransportLocationModal/DeleteTransportLocationModal';

// Lib
import sortByString from '../../../lib/sortByString';

class TransportLocationsContainer extends Component {
  static propTypes = {
    transportLocations: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    updateID: null,
    name: '',
    version: 0,
    removeID: null,
    sort: {key: 'name', direction: 'asc'},
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'name',
      label: 'Name',
      span: 3,
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  showUpdate = (transportLocation) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: transportLocation.id,
      name: transportLocation.name,
      version: transportLocation.version,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSort = (sort) => this.setState({sort});

  update = async () => {
    const {loading, updateID, name, version} = this.state;
    if (loading || !updateID) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const transportLocation = await updateApi(updateID, {name, version});
      const {dispatch, transportLocations} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          transportLocations: [...transportLocations].map((r) =>
            r.id === transportLocation.id ? transportLocation : r
          ),
        })
      );
    } catch (error) {
      alertify.error('Transport Location could not be updated');
      this.setState({loading: false});
    }
  };

  remove = async () => {
    const {loading, removeID} = this.state;
    if (loading || !removeID) return;

    this.setState({loading: true});

    try {
      await deleteApi(removeID);
      const {dispatch, transportLocations} = this.props;
      this.setState({loading: false, removeID: null});
      dispatch(
        setAct({
          transportLocations: [...transportLocations].filter(
            (r) => r.id !== removeID
          ),
        })
      );
    } catch (error) {
      alertify.error('TransportLocation could not be removed');
      this.setState({loading: false});
    }
  };

  data = () => {
    const {transportLocations} = this.props;
    const {sort} = this.state;
    return !!sort
      ? [...transportLocations].sort(sortByString(sort))
      : transportLocations;
  };

  render() {
    const {loading, sort, updateID, name, removeID} = this.state;
    return (
      <Fragment>
        <AdminTransportLocations
          columns={this.columns()}
          sort={sort}
          transportLocations={this.data()}
          onUpdate={this.showUpdate}
          onDelete={this.showDelete}
          onSort={this.onSort}
        />
        <UpdateTransportLocationModal
          title="Update TransportLocation"
          nameLabel="Name"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          name={name}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
        <DeleteTransportLocationModal
          title="Delete TransportLocation"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this location?
        </DeleteTransportLocationModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.transportLocation}))(
  TransportLocationsContainer
);
