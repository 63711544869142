import * as chiefServiceTechnician from './roles/chiefServiceTechnician.role.user';
import * as documentationAdmin from './roles/documentationAdmin.role.user';
import * as employee from './roles/employee.role.user';
import * as serviceTechnician from './roles/serviceTechnician.role.user';
import * as system from './roles/system.role.user';

const formattedRoles = {
  [chiefServiceTechnician.role]: chiefServiceTechnician.label,
  [documentationAdmin.role]: documentationAdmin.label,
  [employee.role]: employee.label,
  [serviceTechnician.role]: serviceTechnician.label,
  [system.role]: system.label,
  default: 'Unknown',
};

const displayRole = ({roles = []} = {}) =>
  [...roles]
    .map(({role}) => formattedRoles[role] || formattedRoles.default)
    .join(', ');
export default displayRole;
