/**
 * @description :: Getting a value from sessionStorage
 * @param {String} key :: The key for getting a value from sessionStorage
 * @return {any} :: The value from sessionStorage
 */
export const get = (key) => {
  try {
    return sessionStorage.getItem(key);
  } catch (error) {
    /* eslint-disable  no-console */
    console.error(`Could not get "${key}" from sessionStorage.`);
    /* eslint-enable  no-console */
    return null;
  }
};

/**
 * @description :: Adding a value into sessionStorage
 * @param {String} key :: The key for saving a value to sessionStorage
 * @param {String} value :: The value to be saved into sessionStorage
 */
export const add = (key, value) => {
  try {
    sessionStorage.setItem(key, value);
  } catch (error) {
    /* eslint-disable  no-console */
    console.error(`Could not save "${key}" to sessionStorage.`);
    /* eslint-enable  no-console */
  }
};

/**
 * @description :: Removing a value from sessionStorage
 * @param {String} key :: The key of the value to be removed from sessionStorage
 */
export const remove = (key) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    /* eslint-disable  no-console */
    console.error(`Could not remove "${key}" from sessionStorage.`);
    /* eslint-enable  no-console */
  }
};
