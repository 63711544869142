import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const resolveMaintenanceDoorApi = (maintenanceId, data, query = {}) =>
  f3tch(`/maintenances/${maintenanceId}/doors/sign`)
    .profile(profile())
    .query(query)
    .body(data)
    .put();
export default resolveMaintenanceDoorApi;
