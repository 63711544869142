import styled from 'styled-components';
import {colors} from 'doorson-ui';
const {white} = colors;

const DisabledVehicleContainer = styled.div`
  height: 304px;
  width: 100%;
  background-color: ${white};
  margin-left: 1px;
  padding: 10px;
  margin-bottom: 2px;
  opacity: 0.3;
`;

export default DisabledVehicleContainer;
