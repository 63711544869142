import {v4} from 'uuid';

// Action constants
import {SET} from './constants';

// Api
import listApi from '../api/list.api.door';

// Query
import setQuery from '../../api/lib/query.lib.api';
import getCustomerBranchByIdApi from "../../customerBranch/api/getByID.api.customerBranch";
import getCustomerByIdApi from "../../customer/api/getByID.api.customer";

export const set = (data = {}) => ({type: SET, data});

export const getServiceItems = (data = {}) => async (dispatch, getState) => {
  const apiID = v4();
  const {search, filter, sort, limit, doorCustomers, doorBranches} = getState().serviceItem;
  const newSearch = data.search !== undefined ? data.search : search;
  const newFilter = data.filter !== undefined ? data.filter : filter;
  const newSort = data.sort !== undefined ? data.sort : sort;
  const currentPosition = newSort && newSort.key === 'distance' && data.currentPosition ? data.currentPosition : null;

  dispatch({
    type: SET,
    data: {
      apiID,
      loading: true,
      search: newSearch,
      filter: newFilter,
      sort: newSort,
      currentPosition,
      offset: 0,
      more: true,
      serviceItems: [],
    },
  });

  try {
    const query = setQuery({
      search: newSearch,
      status: newFilter,
      order: newSort,
      geopoint: currentPosition,
      offset: 0,
      limit,
    });
    const serviceItems = await listApi(query);

    const newDoorBranches = new Set([...serviceItems].filter(item => !doorBranches[item.customerBranchId]).map(item => item.customerBranchId));
    const branches = {
      ...doorBranches, ...[...await Promise.all([...newDoorBranches].map(id => getCustomerBranchByIdApi(id)))].reduce((obj, e) => ({
        ...obj,
        [e.id]: e
      }), {})
    };
    const newDoorCustomers = new Set(Object.keys(branches).map(id => branches[id]).filter(branch => !doorCustomers[branch.customerId]).map(branch => branch.customerId));
    const customers = {
      ...doorCustomers, ...[...await Promise.all([...newDoorCustomers].map(id => getCustomerByIdApi(id)))].reduce((obj, e) => ({
        ...obj,
        [e.id]: e
      }), {})
    };
    if (getState().serviceItem.apiID !== apiID) return;
    dispatch({
      type: SET,
      data: {
        serviceItems: [...serviceItems].map(item => ({
          ...item,
          customer: customers[(branches[item.customerBranchId] || {}).customerId],
          customerBranch: branches[item.customerBranchId]
        })),
        doorBranches: branches,
        doorCustomers: customers,
        loading: false,
        more: serviceItems.length === limit,
        offset: limit,
      },
    });
  } catch (error) {
    dispatch({
      type: SET,
      data: {loading: false},
    });
  }
};

export const getMoreServiceItems = () => async (dispatch, getState) => {
  const {
    loading,
    search,
    filter,
    sort,
    limit,
    offset,
    more,
    doorCustomers,
    doorBranches,
    currentPosition,
    apiID,
    serviceItems: oldServiceItems,
  } = getState().serviceItem;

  if (!more || loading) return;

  dispatch({
    type: SET,
    data: {
      loading: true,
    },
  });

  try {
    const query = setQuery({
      search,
      status: filter,
      order: sort,
      geopoint: currentPosition,
      offset,
      limit
    });
    const serviceItems = await listApi(query);

    const newDoorBranches = new Set([...serviceItems].filter(item => !doorBranches[item.customerBranchId]).map(item => item.customerBranchId));
    const branches = {
      ...doorBranches, ...[...await Promise.all([...newDoorBranches].map(id => getCustomerBranchByIdApi(id)))].reduce((obj, e) => ({
        ...obj,
        [e.id]: e
      }), {})
    };
    const newDoorCustomers = new Set(Object.keys(branches).map(id => branches[id]).filter(branch => !doorCustomers[branch.customerId]).map(branch => branch.customerId));
    const customers = {
      ...doorCustomers, ...[...await Promise.all([...newDoorCustomers].map(id => getCustomerByIdApi(id)))].reduce((obj, e) => ({
        ...obj,
        [e.id]: e
      }), {})
    };

    if (getState().serviceItem.apiID !== apiID) return;
    dispatch({
      type: SET,
      data: {
        serviceItems: [...oldServiceItems, ...[...serviceItems].map(item => ({
          ...item,
          customer: customers[(branches[item.customerBranchId] || {}).customerId],
          customerBranch: branches[item.customerBranchId]
        }))],
        loading: false,
        offset: limit + offset,
        more: serviceItems.length === limit,
      },
    });
  } catch (error) {
    dispatch({
      type: SET,
      data: {loading: false},
    });
  }
};
