import React from 'react';

// Containers
import ProductsContainer from '../../../product/containers/ProductsContainer/ProductsContainer';

const ManufacturerAdminPage = ({
  match: {
    params: {manufacturerID},
  },
}) => <ProductsContainer manufacturerID={manufacturerID} />;

export default ManufacturerAdminPage;
