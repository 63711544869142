import styled from 'styled-components';

const Thumbnail = styled.div`
  width: 80px;
  height: 80px;
  cursor: pointer;
  opacity: ${({selected}) => (selected ? 0.7 : 1)};
  margin: 5px 0px 0px 5px;
`;

export default Thumbnail;
