import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createCustomerBranchApi = (customerBranch) =>
  f3tch('/customer-branches')
    .profile(profile())
    .body(customerBranch)
    .post();
export default createCustomerBranchApi;
