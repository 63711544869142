import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey, white} = colors;

const TabContainer = styled.div`
  border-style: solid none;
  border-width: 2px;
  border-color: ${grey};
  background: ${white};
  display: flex;
  justify-content: flex-start;
`;

export default TabContainer;
