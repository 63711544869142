import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey, orange, white, black} = colors;

const Branch = styled.div`
  cursor: pointer;
  background: ${({selected}) => (selected ? orange : grey)};
  color: ${({selected}) => (selected ? white : black)};
  user-select: none;
  margin-right: 15px;
  padding: 14px;
  white-space: nowrap;
  border-radius: 5px;
`;

export default Branch;
