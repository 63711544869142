import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// Components
import {Table} from 'doorson-ui';
import QuickActions from '../QuickActions/QuickActions';
import Container from './components/Container';

// Customer
import getCustomerName from '../../../customer/lib/getName.lib.customer';

// Maintenance
import getPriority from '../../priority/getPriority.priority.maintenance';

// Types
import {date as dateType} from '../../../types';
import GpsNavigation from "../../../layout/components/GpsNavigation/GpsNavigation";

class MaintenanceQuickView extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    maintenance: PropTypes.object,
    columns: PropTypes.array,
    user: PropTypes.object,
    users: PropTypes.array,
    onClose: PropTypes.func,
    onAction: PropTypes.func,
  };

  handleClickOutside = () => this.props.onClose();

  render() {
    const {loading, maintenance, columns, user, users, onAction} = this.props;
    return (
      <Container>
        <Table columns={columns} headless>
          {(TableRow) => (
            <TableRow
              key={maintenance.id}
              label={
                maintenance.resolved || maintenance.closed
                  ? null
                  : getPriority(maintenance).color
              }
              theme={
                maintenance.resolved || maintenance.closed ? 'greyer' : 'white'
              }
            >
              {(TableCol) => [
                <TableCol smallPadding key="priority"/>,
                <TableCol smallPadding span={1.5} key="code">
                  {maintenance.code}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="dc">
                  {dateType(maintenance.scheduledFor).format() || ''}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="customer">
                  {getCustomerName(maintenance.customer)}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="location">
                  {!!maintenance.customerBranch.branchLocation && (
                    <span>
                      {!!maintenance.customerBranch.branchName && (
                        <span>
                          {maintenance.customerBranch.branchName}
                          <br/>
                        </span>
                      )}
                      {maintenance.customerBranch.branchLocation.address}
                      <br/>
                      {maintenance.customerBranch.branchLocation.city}
                      <GpsNavigation location={maintenance.customerBranch.branchLocation}/>
                    </span>
                  )}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="doorType">
                  {(maintenance.doors || {}).length > 1
                    ? 'Multiple doors'
                    : (maintenance.doors || []).map((door, index) => (
                      !!door ? (<div key={door.id}>
                        {door.door.product.manufacturer.name}{' '}
                        {door.door.product.name}
                      </div>) : (
                        <div key={index}>Unknown doors</div>
                      )
                    ))}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="action">
                  <QuickActions
                    loading={loading}
                    maintenance={maintenance}
                    priorityColor={getPriority(maintenance).color}
                    users={users}
                    user={user}
                    onAction={onAction}
                  />
                </TableCol>,
              ]}
            </TableRow>
          )}
        </Table>
      </Container>
    );
  }
}

export default onClickOutside(MaintenanceQuickView);
