import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';

const NewRepairModal = ({
  title,
  descriptionLabel,
  saveLabel,
  visible,
  loading,
  description,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="small">
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Input
            value={description}
            onChange={onChange('description')}
            disabled={loading}
          >
            {descriptionLabel}
          </Input>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

NewRepairModal.propTypes = {
  title: PropTypes.string,
  descriptionLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  description: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default NewRepairModal;
