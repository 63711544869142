import {profile} from 'f3tch';

// Constants
import profileUrl from '../constants/url.const.api';

// Errors
import isApiError from '../errors/isApiError.error.api';
import isUnauthorized from '../errors/isUnauthorized.error.api';

// Profiles
import headers from '../headers/json.header.api';

const jsonNoResponse = ({authorize = true, url = profileUrl} = {}) =>
  profile(url)
    .headers(headers({authorize}))
    .bodyParser((data) => JSON.stringify(data))
    .responder(async (response) => {
      isUnauthorized(response);
      await isApiError(response);
    });
export default jsonNoResponse;
