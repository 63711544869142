import React from 'react';

// Containers
import ServiceItemContainer from '../../containers/ServiceItemContainer/ServiceItemContainer';

const ServiceItemPage = ({match}) => (
  <ServiceItemContainer id={match.params.id} />
);

export default ServiceItemPage;
