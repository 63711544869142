import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.language';
// import deleteApi from '../../api/delete.api.language';

// Components
import AdminLanguages from '../../components/AdminLanguages/AdminLanguages';
import UpdateLanguageModal from '../../components/UpdateLanguageModal/UpdateLanguageModal';
import DeleteLanguageModal from '../../components/DeleteLanguageModal/DeleteLanguageModal';

// Lib
import sortByString from '../../../lib/sortByString';

class LanguagesContainer extends Component {
  static propTypes = {
    languages: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    updateID: null,
    code: '',
    name: '',
    disabled: false,
    version: 0,
    removeID: null,
    sort: {key: 'name', direction: 'asc'},
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'code',
      label: 'Code',
      span: 1,
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      span: 2,
      sortable: true,
    },
    {
      key: 'disabled',
      label: 'Disabled',
      span: 1,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  showUpdate = (language) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: language.code,
      code: language.code,
      name: language.name,
      disabled: language.disabled,
      version: language.version,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSort = (sort) => this.setState({sort});

  update = async () => {
    const {loading, updateID, code, name, disabled, version} = this.state;
    if (loading || !updateID) return;

    if (!code.trim().length) return alertify.warning('Insert the code');
    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const language = await updateApi({
        code,
        name,
        disabled,
        version,
      });
      const {dispatch, languages} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          languages: [...languages].map((r) =>
            r.code === language.code ? language : r
          ),
        })
      );
    } catch (error) {
      alertify.error('Language could not be updated');
      this.setState({loading: false});
    }
  };

  remove = async () => {
    // const {loading, removeID} = this.state;
    // if (loading || !removeID) return;
    // this.setState({loading: true});
    // try {
    //   await deleteApi(removeID);
    //   const {dispatch, languages} = this.props;
    //   this.setState({loading: false, removeID: null});
    //   dispatch(
    //     setAct({
    //       languages: [...languages].filter((r) => r.code !== removeID),
    //     })
    //   );
    // } catch (error) {
    //   alertify.error('Language could not be removed');
    //   this.setState({loading: false});
    // }
  };

  data = () => {
    const {languages} = this.props;
    const {sort} = this.state;
    return !!sort ? [...languages].sort(sortByString(sort)) : languages;
  };

  render() {
    const {
      loading,
      sort,
      updateID,
      code,
      name,
      disabled,
      removeID,
    } = this.state;
    return (
      <Fragment>
        <AdminLanguages
          columns={this.columns()}
          sort={sort}
          languages={this.data()}
          onUpdate={this.showUpdate}
          onDelete={this.showDelete}
          onSort={this.onSort}
        />
        <UpdateLanguageModal
          title="Update Language"
          codeLabel="Code"
          nameLabel="Name"
          disabledLabel="Disabled"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          code={code}
          name={name}
          disabled={disabled}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
        <DeleteLanguageModal
          title="Delete Language"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this language?
        </DeleteLanguageModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.language}))(LanguagesContainer);
