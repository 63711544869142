import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import MaintenanceTabs from '../../components/MaintenanceTabs/MaintenanceTabs';

// Alertify
import {alertify} from 'doorson-ui';

// Routes
import customerRoute from '../../pages/MaintenanceCustomerPage/route';
import assignRoute from '../../pages/MaintenanceAssignPage/route';
import itemsRoute from '../../pages/MaintenanceItemsPage/route';
import signMultipleItemsRoute from '../../pages/MaintenanceSignMultipleItemsPage/route';
import resolveRoute from '../../pages/MaintenanceResolvePage/route';
import actionsRoute from '../../pages/MaintenanceActionsPage/route';

// Libs
import isResolved from '../../lib/isResolved.lib.maintenance';
import isClosed from '../../lib/isClosed.lib.maintenance';
import areDoorsComplete from '../../../maintenanceDoor/lib/areDoorsComplete.lib.maintenanceDoor';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SYSTEM} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

// Helpers
const routes = {
  [actionsRoute()]: actionsRoute,
  [assignRoute()]: assignRoute,
  [customerRoute()]: customerRoute,
  [itemsRoute()]: itemsRoute,
  [resolveRoute()]: resolveRoute,
  [signMultipleItemsRoute()]: signMultipleItemsRoute,
};

class MaintenanceTabsContainer extends Component {
  static propTypes = {
    page: PropTypes.string,
    history: PropTypes.object,
    maintenance: PropTypes.object,
    user: PropTypes.object,
  };

  onTab = (tab) => {
    // TODO: I18n
    const {history, maintenance} = this.props;
    if (tab === itemsRoute() && !maintenance.assignees.length) {
      alertify.info('Assign somebody to this maintenance first');
      return history.replace(assignRoute(maintenance.id));
    }
    if (tab === resolveRoute() && !areDoorsComplete(maintenance)) {
      alertify.info('Resolve all doors first');
      return history.replace(
        !!maintenance.assignees.length
          ? itemsRoute(maintenance.id)
          : assignRoute(maintenance.id)
      );
    }
    history.push(routes[tab](maintenance.id));
  };

  tabs = () => {
    // TODO: I18N
    const {maintenance, user} = this.props;
    return [
      {
        id: customerRoute(),
        label: 'Customer Info',
        done: true,
      },
      {
        id: assignRoute(),
        label: 'Assign',
        done: !!maintenance.assignees.length,
      },
      {
        id: itemsRoute(),
        label: 'Items',
        done:
          isResolved(maintenance) ||
          isClosed(maintenance) ||
          areDoorsComplete(maintenance),
      },
      {
        id: signMultipleItemsRoute(),
        label: 'Sign Multiple',
        done: isResolved(maintenance) || isClosed(maintenance),
      },
      {
        id: resolveRoute(),
        label: 'Finish',
        done: isClosed(maintenance),
      },
      hasPermission({
        user,
        roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
      }) && {
        id: actionsRoute(),
        done: false,
        icon: 'dots-horizontal',
      },
    ].filter((tab) => !!tab);
  };

  render() {
    return (
      <MaintenanceTabs
        tab={this.props.page}
        tabs={this.tabs()}
        onTab={this.onTab}
      />
    );
  }
}

export default connect((state) => ({
  page: state.layout.subpage,
  maintenance: state.maintenance.maintenance,
  user: state.auth.user,
}))(withRouter(MaintenanceTabsContainer));
