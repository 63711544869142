import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Api
import assignApi from '../../api/assign.api.intervention';
import unassignApi from '../../api/unassign.api.intervention';

// Alert
import {alertify} from 'doorson-ui';

// Actions
import {
  getInterventions as getInterventionsAct,
  getMoreInterventions as getMoreInterventionsAct,
  updateIntervention as updateInterventionAct,
} from '../../redux/actions';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import InterventionsSearch from '../../components/InterventionsSearch/InterventionsSearch';
import InterventionsHeader from '../../components/InterventionsHeader/InterventionsHeader';
import InterventionsFilter from '../../components/InterventionsFilter/InterventionsFilter';
import Interventions from '../../components/Interventions/Interventions';

// Containers
import CloseInterventionContainer from '../CloseInterventionContainer/CloseInterventionContainer';

// Libs
import isClosed from '../../lib/isClosed.lib.intervention';
import isResolved from '../../lib/isResolved.lib.intervention';

// New Intervention
import newInterventionRoute from '../../pages/NewInterventionPage/route';

// Routes
import interventionRoute from '../../pages/InterventionPage/route';

// Roles
import {
  CHIEF_SERVICE_TECHNICIAN,
  SERVICE_TECHNICIAN,
  SYSTEM,
} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

class InterventionsContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    more: PropTypes.bool,
    search: PropTypes.string,
    interventions: PropTypes.array,
    filter: PropTypes.string,
    sort: PropTypes.array,
    user: PropTypes.object,
    users: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    assigning: [],
    closing: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.getInterventions();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  syncIntervention = (intervention) =>
    this.props.dispatch(updateInterventionAct(intervention));

  filterAssigning = (interventionID) =>
    [...this.state.assigning].filter((a) => a !== interventionID);

  onSearch = (search) => this.getInterventions({search});

  onFilter = (filter) => {
    const defaultSort =
      filter === 'RESOLVED'
        ? 'dateResolved'
        : filter === 'CLOSED'
        ? 'dateClosed'
        : 'priority';
    this.getInterventions({
      filter,
      sort: [
        {key: defaultSort, direction: 'desc'},
        {key: 'dc', direction: 'desc'},
      ],
    });
  };

  onSort = (sort) => this.getInterventions({sort});

  onIntervention = (intervention) =>
    this.props.history.push(interventionRoute(intervention.id));

  assign = async ({userID, interventionID}) => {
    // TODO: I18n
    this.setState({assigning: [...this.state.assigning, interventionID]});

    try {
      const intervention = await assignApi(interventionID, userID);
      this.syncIntervention(intervention);
      if (!this.mounted) return;
      this.setState({assigning: this.filterAssigning(interventionID)});
    } catch (error) {
      alertify.error('Could not assign');
      if (!this.mounted) return;
      this.setState({assigning: this.filterAssigning(interventionID)});
    }
  };

  unassign = async ({interventionID}) => {
    const {user} = this.props;
    // TODO: I18n
    this.setState({assigning: [...this.state.assigning, interventionID]});

    try {
      const intervention = await unassignApi(interventionID, user.id);
      this.syncIntervention(intervention);
      if (!this.mounted) return;
      this.setState({assigning: this.filterAssigning(interventionID)});
    } catch (error) {
      alertify.error('Could not unassign');
      if (!this.mounted) return;
      this.setState({assigning: this.filterAssigning(interventionID)});
    }
  };

  onClose = (intervention) => {
    this.syncIntervention(intervention);
    this.setState({closing: false});
  };

  onAction = (action, {interventionID, userID, intervention}) => {
    const {assigning} = this.state;
    if (assigning.includes(interventionID)) return;

    switch (action) {
      case 'assign':
        return this.assign({interventionID, userID});
      case 'unassign':
        return this.unassign({interventionID, userID});
      case 'close':
        return this.showClosingModal(intervention);
      default:
        return;
    }
  };

  showClosingModal = (intervention) => this.setState({closing: intervention});

  hideClosingModal = () => this.setState({closing: false});

  getInterventions = async (data) =>
    this.props.dispatch(getInterventionsAct(data));

  onMore = () => this.props.dispatch(getMoreInterventionsAct());

  interventions = () =>
    [...this.props.interventions].map((intervention) => ({
      ...intervention,
      resolved: isResolved(intervention),
      closed: isClosed(intervention),
    }));

  // TODO I18N
  // filters = () => [
  //   {
  //     id: 'OPENED',
  //     label: 'Open',
  //   },
  //   {
  //     id: 'NOTASSIGNED',
  //     label: 'Not Assigned',
  //   },
  //   {
  //     id: 'ASSIGNED',
  //     label: 'Assigned',
  //   },
  //   {
  //     id: 'ASSIGNEDTOME',
  //     label: 'Assigned To Me',
  //   },
  //   {
  //     id: 'NOTRESOLVED',
  //     label: 'Not Resolved',
  //   },
  //   {
  //     id: 'RESOLVED',
  //     label: 'Resolved',
  //   },
  //   {
  //     id: 'CLOSED',
  //     label: 'Closed',
  //   },
  // ];
  filters = () =>
    [
      {
        id: 'OPENED',
        label: 'Open',
      },
      {
        id: 'ASSIGNEDTOME',
        label: 'Assigned to me',
      },
      {
        id: 'ASSIGNED',
        label: 'Assigned',
      },
      {
        id: 'RESOLVED',
        label: 'Resolved',
      },
      hasPermission({
        user: this.props.user,
        roles: [SERVICE_TECHNICIAN, CHIEF_SERVICE_TECHNICIAN, SYSTEM],
      }) && {
        id: 'CLOSED',
        label: 'Closed',
      },
    ].filter((act) => !!act);

  columns = (filter) => [
    {
      key: 'priority',
      label: 'Priority',
      sortable: true,
    },
    {
      key: 'incident',
      label: 'Incident',
      span: 1.5,
    },
    {
      key:
        filter === 'RESOLVED'
          ? 'dateResolved'
          : filter === 'CLOSED'
          ? 'dateClosed'
          : 'dc',
      label:
        filter === 'RESOLVED'
          ? 'Date Resolved'
          : filter === 'CLOSED'
          ? 'Date Closed'
          : 'Date Created',
      span: 1.5,
      sortable: true,
    },
    {
      key: 'customer',
      label: 'Customer',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'location',
      label: 'Location',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'doorType',
      label: 'Door Type',
      span: 3,
    },
  ];

  renderHeader = () => (
    <Fragment>
      <InterventionsHeader>Interventional Incidents</InterventionsHeader>
      <InterventionsFilter
        newLabel="New"
        newUrl={newInterventionRoute()}
        filter={this.props.filter}
        filters={this.filters()}
        onFilter={this.onFilter}
      />
    </Fragment>
  );

  render() {
    const {
      loading,
      user,
      users,
      search,
      sort,
      filter,
      scroller,
      more,
    } = this.props;
    const {assigning, closing} = this.state;
    const dateColumn =
      filter === 'RESOLVED'
        ? 'dateResolved'
        : filter === 'CLOSED'
        ? 'dateClosed'
        : 'dc';
    return (
      <Fragment>
        <InterventionsSearch search={search} onSearch={this.onSearch} />
        <ContentLayout header={this.renderHeader()}>
          <Interventions
            loading={loading}
            more={more}
            scroller={scroller}
            assigning={assigning}
            user={user}
            users={users}
            sort={Array.isArray(sort) ? sort[0] : sort}
            dateColumn={dateColumn}
            columns={this.columns(filter)}
            interventions={this.interventions()}
            onIntervention={this.onIntervention}
            onSort={this.onSort}
            onAction={this.onAction}
            onMore={this.onMore}
          />
        </ContentLayout>
        <CloseInterventionContainer
          visible={!!closing}
          intervention={closing}
          onClose={this.hideClosingModal}
          onDone={this.onClose}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  users: state.user.users,
  scroller: state.layout.scroller,
  ...state.intervention,
}))(withRouter(InterventionsContainer));
