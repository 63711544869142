import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import getInterventionByIDApi from '../../api/getByID.api.intervention';
import assignApi from '../../api/assign.api.intervention';
import unassignApi from '../../api/unassign.api.intervention';

// Alert
import {alertify} from 'doorson-ui';

// Components
import InterventionQuickView from '../../components/InterventionQuickView/InterventionQuickView';

// Containers
import CloseInterventionContainer from '../CloseInterventionContainer/CloseInterventionContainer';

// Libs
import isClosed from '../../lib/isClosed.lib.intervention';
import isResolved from '../../lib/isResolved.lib.intervention';

class InterventionQuickViewContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    intervention: PropTypes.object,
    onClose: PropTypes.func,
    user: PropTypes.object,
    users: PropTypes.array,
  };

  state = {
    loading: false,
    intervention: null,
    closing: null,
  };

  componentDidMount() {
    this.mounted = true;
    if (!!this.props.intervention) this.init();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.intervention && !!this.props.intervention) ||
      (!!prevProps.intervention &&
        !!this.props.intervention &&
        prevProps.intervention.id !== this.props.intervention.id)
    )
      this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    const {intervention} = this.props;
    if (!intervention) return;
    try {
      const interventionWithReferences = await getInterventionByIDApi(intervention.id);
      this.setState({intervention: interventionWithReferences});
    } catch (e) {
      console.log(e);
      this.setState({intervention});
    }
  };

  assign = async ({userID, interventionID}) => {
    // TODO: I18n
    if (this.state.loading) return;
    this.setState({loading: true});

    try {
      const intervention = await assignApi(interventionID, userID);
      if (!this.mounted) return;
      this.setState({intervention, loading: false});
    } catch (error) {
      alertify.error('Could not assign');
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  unassign = async ({interventionID}) => {
    // TODO: I18n
    const {user} = this.props;
    if (this.state.loading) return;
    this.setState({loading: true});

    try {
      const intervention = await unassignApi(interventionID, user.id);
      if (!this.mounted) return;
      this.setState({loading: false, intervention});
    } catch (error) {
      alertify.error('Could not unassign');
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  onInterventionClose = (intervention) =>
    this.setState({intervention, closing: false});

  onAction = (action, {interventionID, userID, intervention}) => {
    const {loading} = this.state;
    if (loading) return;

    switch (action) {
      case 'assign':
        return this.assign({interventionID, userID});
      case 'unassign':
        return this.unassign({interventionID, userID});
      case 'close':
        return this.showClosingModal(intervention);
      default:
        return;
    }
  };

  showClosingModal = (intervention) => this.setState({closing: intervention});

  hideClosingModal = () => this.setState({closing: false});

  onClose = () => {
    const {visible, onClose} = this.props;
    const {loading, intervention, closing} = this.state;
    if (!visible || loading || closing) return;
    onClose(intervention);
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'priority',
      label: 'Priority',
      sortable: true,
    },
    {
      key: 'incident',
      label: 'Incident',
      span: 1.5,
    },
    {
      key: 'dc',
      label: 'Date Created',
      span: 1.5,
      sortable: true,
    },
    {
      key: 'customer',
      label: 'Customer',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'location',
      label: 'Location',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'doorType',
      label: 'Door Type',
      span: 3,
    },
  ];

  intervention = () => {
    const {intervention} = this.state;
    return !!intervention
      ? {
        ...intervention,
        resolved: isResolved(intervention),
        closed: isClosed(intervention),
      }
      : null;
  };

  render() {
    const {visible, user, users} = this.props;
    const {loading, closing} = this.state;
    const intervention = this.intervention();
    return (
      <Fragment>
        {visible && !!intervention && (
          <InterventionQuickView
            loading={loading}
            intervention={intervention}
            columns={this.columns()}
            user={user}
            users={users}
            onClose={this.onClose}
            onAction={this.onAction}
          />
        )}
        <CloseInterventionContainer
          visible={!!closing}
          intervention={closing}
          onClose={this.hideClosingModal}
          onDone={this.onInterventionClose}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  users: state.user.users,
}))(InterventionQuickViewContainer);
