import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createInterventionApi =  (intervention, query = {rel: '*'}) =>
  f3tch('/interventions')
    .profile(profile())
    .query(query)
    .body(intervention)
    .post();
export default createInterventionApi;
