import React from 'react';

// Containers
import WarehouseOrderContainer from "../../containers/WarehouseOrderContainer/WarehouseOrderContainer";

const WarehouseOrderPage = ({match}) => (
  <WarehouseOrderContainer id={match.params.id}/>
);

export default WarehouseOrderPage;
