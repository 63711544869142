// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  loading: true,
  search: '',
  filter: 'MY_ORDERS',
  sort: [{
    key: 'dc',
    direction: 'desc',
  }],
  offset: 0,
  limit: 25,
  more: true,
  apiID: null,
};

const reducer = (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
