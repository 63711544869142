import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import Dropzone from 'react-dropzone';
import {Button, Column, Row, SquareInput} from 'doorson-ui';

import Container from './components/Container';
import Content from './components/Content';
import UploadLabel from './components/UploadLabel';
import LoadingContainer from './components/LoadingContainer';
import Loader from './components/Loader';
import UpdateContainer from './components/UpdateContainer';
import ImageContainer from './components/ImageContainer';
import Image from './components/Image';
import UpdateContent from './components/UpdateContent';

class Uploader extends Component {
  static propTypes = {
    uploading: PropTypes.bool,
    label: PropTypes.string,
    fileNameLabel: PropTypes.string,
    uploadLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    onUpload: PropTypes.func,
    accept: PropTypes.string,
  };

  static defaultProps = {
    accept: 'image/*',
  };

  state = {
    file: null,
    preview: null,
    name: '',
  };

  onFile = ([file]) =>
    this.setState({file, name: file.name, preview: URL.createObjectURL(file)});

  onCancel = (checkUpload = true) => {
    if (checkUpload && this.props.uploading) return;
    this.setState({file: null, preview: null, name: ''});
  };

  onRename = (name) => {
    if (this.props.uploading) return;
    this.setState({name});
  };

  onUpload = () => {
    const {uploading, onUpload} = this.props;
    const {file, name} = this.state;
    if (uploading) return;
    onUpload({file, name, reset: () => this.onCancel(false)});
  };

  render() {
    const {
      uploading,
      accept,
      label,
      fileNameLabel,
      uploadLabel,
      cancelLabel,
    } = this.props;
    const {file, name, preview} = this.state;
    return (
      <Container>
        <Content>
          {!file ? (
            <Dropzone
              onDrop={this.onFile}
              accept={accept}
              multiple={false}
              capture="environment"
            >
              {({getRootProps, getInputProps}) => (
                <UploadLabel {...getRootProps()}>
                  <input {...getInputProps()} />
                  {label}
                </UploadLabel>
              )}
            </Dropzone>
          ) : (
            <UpdateContainer>
              <ImageContainer>
                <Image type={(file || {}).type} preview={preview}/>
              </ImageContainer>
              <UpdateContent>
                <Row>
                  <Column>
                    <SquareInput
                      value={name}
                      onChange={this.onRename}
                      disabled={uploading}
                    >
                      {fileNameLabel}
                    </SquareInput>
                  </Column>
                </Row>
                <Row>
                  <Column size={1 / 2}>
                    <Button
                      theme="orange"
                      onClick={this.onUpload}
                      disabled={uploading}
                      outline
                    >
                      {uploadLabel}
                    </Button>
                  </Column>
                  <Column size={1 / 2}>
                    <Button
                      theme="grey"
                      onClick={this.onCancel}
                      disabled={uploading}
                      outline
                    >
                      {cancelLabel}
                    </Button>
                  </Column>
                </Row>
              </UpdateContent>
            </UpdateContainer>
          )}
          {uploading && (
            <LoadingContainer>
              <Loader className="mdi mdi-loading mdi-spin"/>
            </LoadingContainer>
          )}
        </Content>
      </Container>
    );
  }
}

export default Uploader;
