import React from 'react';

// Components
import Container from './components/Container';
import styled from "styled-components";
import PropTypes from "prop-types";

const Dot = styled.div`
  height: 12px;
  width: 12px;
  margin-right: 10px;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
`;

const Legend = ({
                  color,
                  children
                }) =>
  <Container>
    <Dot color={color}/>
    {children}
  </Container>
;

Legend.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
};

export default Legend;
