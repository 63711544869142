import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Info} from 'doorson-ui';
import PageContent from '../../../layout/components/PageContent/PageContent';

const CustomerInfo = ({
  customerTypeLabel,
  companyLabel,
  registrationNumberLabel,
  firstNameLabel,
  lastNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  branchLocationLabel,
  phoneLabel,
  emailLabel,

  type,
  company,
  registrationNumber,
  firstName,
  lastName,
  address,
  zip,
  city,
  country,
  branchLocation,
  phone,
  email,
}) => (
  <PageContent>
    <Row margin>
      <Column size={1 / 3}>
        <Info label={customerTypeLabel}>{type}</Info>
      </Column>
    </Row>
    {type === 'business' ? (
      <Row margin>
        <Column size={1 / 3}>
          <Info label={companyLabel}>{company}</Info>
        </Column>
        <Column size={1 / 3}>
          <Info label={registrationNumberLabel}>{registrationNumber}</Info>
        </Column>
      </Row>
    ) : (
      <Row margin>
        <Column size={1 / 3}>
          <Info label={firstNameLabel}>{firstName}</Info>
        </Column>
        <Column size={1 / 3}>
          <Info label={lastNameLabel}>{lastName}</Info>
        </Column>
      </Row>
    )}
    <Row margin>
      <Column size={2 / 3}>
        <Info label={addressLabel}>{address}</Info>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Info label={zipLabel}>{zip}</Info>
      </Column>
      <Column size={1 / 3}>
        <Info label={cityLabel}>{city}</Info>
      </Column>
      <Column size={1 / 3}>
        <Info label={countryLabel}>{country}</Info>
      </Column>
    </Row>
    {type === 'business' && (
      <Row margin>
        <Column size={2 / 3}>
          <Info label={branchLocationLabel}>{branchLocation}</Info>
        </Column>
      </Row>
    )}
    <Row>
      <Column size={1 / 3}>
        <Info label={phoneLabel}>{phone}</Info>
      </Column>
      <Column size={1 / 3}>
        <Info label={emailLabel}>{email}</Info>
      </Column>
    </Row>
  </PageContent>
);

CustomerInfo.propTypes = {
  customerTypeLabel: PropTypes.node,
  companyLabel: PropTypes.node,
  registrationNumberLabel: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  branchLocationLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,

  type: PropTypes.string,
  company: PropTypes.string,
  registrationNumber: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  branchLocation: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
};

export default CustomerInfo;
