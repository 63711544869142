import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import statuses from '../../lib/status.lib.warehouseOrder';

// Api
import listWarehousesApi from "../../../warehouse/api/get.api.warehouse";


// Alerts
import {alertify} from 'doorson-ui';

// Components
// Containers
// Routes
import WarehouseOrders from "../../components/WarehouseOrders/WarehouseOrders";
import WarehouseOrderLoader from "../../components/WarehouseOrderLoader/WarehouseOrderLoader";
import ContentLayout from "../../../layout/components/ContentLayout/ContentLayout";
import WarehouseOrderHeader from "../../components/WarehouseOrderHeader/WarehouseOrderHeader";
import orderRoute from "../../pages/WarehouseOrderPage/route";
import WarehouseOrdersFilter from "../../components/WarehouseOrdersFilter/WarehouseOrdersFilter";
import {
  getMoreWarehouseOrders as getMoreWarehouseOrdersAct,
  getWarehouseOrders as getWarehouseOrdersAct
} from "../../redux/actions";

class WarehouseOrdersContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  state = {
    loading: false,
    warehouses: [],
    error: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    const {user} = this.props;
    this.setState({loading: true});
    await Promise.all([
      this.loadWarehouses(),
      this.getOrders({currentUserId: user.id}),
    ]);
    this.setState({loading: false})
  };

  getOrders = async (data) =>
    this.props.dispatch(getWarehouseOrdersAct(data));

  onMore = () => this.props.dispatch(getMoreWarehouseOrdersAct());

  loadWarehouses = async () => {
    try {
      const warehouses = await listWarehousesApi();
      this.setState({warehouses})
    } catch (error) {
      alertify.warning('Unable to load warehouses');
    }
  }

  onSort = (sort) => this.getOrders({sort});

  onFilter = (filter) => {
    this.getOrders({
      filter,
      sort: [
        {key: 'dc', direction: 'desc'},
      ],
    });
  };

  orders = () => {
    const {users, warehouseOrders} = this.props;
    return [...warehouseOrders].map(order => {
      const requesterPerson = [...users].find(u => u.id === order.requesterPersonId)
      return {
        ...order,
        sourceWarehouse: this.warehouse(order.sourceWarehouseId).name,
        targetWarehouse: this.warehouse(order.targetWarehouseId).name,
        requesterPerson: requesterPerson ? `${requesterPerson.firstName} ${requesterPerson.lastName}` : order.requesterPersonName,
        status: ([...statuses()].find(s => s.value === order.status) || {}).label || order.status
      }
    })
  }

  warehouse = (id) => [...this.state.warehouses].find(w => w.id === id) || {}

  columns = () => [
    {
      key: 'id',
      label: 'Id',
      sortable: false,
      span: 1
    },
    {
      key: 'requesterPersonId',
      label: 'Order person',
      span: 2,
      sortable: false,
    },
    {
      key: 'sourceWarehouseId',
      label: 'Order source',
      span: 2,
      sortable: false,
    },
    {
      key: 'targetWarehouseId',
      label: 'Order destination',
      span: 2,
      sortable: false,
    },
    {
      key: 'dc',
      label: 'Date created',
      span: 1,
      sortable: true,
    },
    {
      key: 'collectEstTime',
      label: 'Estimated date to collect',
      span: 1,
      sortable: true,
    },
    {
      key: 'status',
      label: 'Status',
      span: 1,
      sortable: false,
    },
  ];

  filters = () =>
    [
      {
        id: 'MY_ORDERS',
        label: 'My orders',
      },
      {
        id: 'DRAFTS',
        label: 'Drafts',
      },
      {
        id: 'WAITING',
        label: 'Waiting',
      },
      {
        id: 'READY',
        label: 'Ready',
      },
      {
        id: 'COMPLETED',
        label: 'Completed',
      },
      {
        id: 'TRANSFER',
        label: 'Vehicle transfers',
      },
      {
        id: 'DERECOGNITION',
        label: 'Derecognitions',
      },
    ].filter((act) => !!act);

  onOrder = (order) =>
    this.props.history.push(orderRoute(order.id));

  renderHeader = () => (
    <Fragment>
      <WarehouseOrderHeader>Warehouse orders</WarehouseOrderHeader>
      <WarehouseOrdersFilter
        filter={this.props.filter}
        filters={this.filters()}
        onFilter={this.onFilter}
      />
    </Fragment>
  );

  render() {
    const {loading, sort} = this.props;

    return loading || this.state.loading ? (
      <WarehouseOrderLoader/>
    ) : (
      <Fragment>
        <ContentLayout header={this.renderHeader()}>
          <WarehouseOrders
            columns={this.columns()}
            sort={Array.isArray(sort) ? sort[0] : sort}
            onSort={this.onSort}
            onOrder={this.onOrder}
            orders={this.orders()}
          />
        </ContentLayout>
      </Fragment>
    );
  }

}

export default connect((state) => ({
  user: state.auth.user,
  users: state.user.users,
  ...state.warehouseOrder,
}))(withRouter(WarehouseOrdersContainer));
