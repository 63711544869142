// Action constants
import {SET} from './constants';

export const set = (data = {}) => ({type: SET, data});

export const setDeviceId = (deviceId) => ({
  type: SET,
  data: {deviceId},
});

export const setDeviceIdAndHide = (deviceId) => ({
  type: SET,
  data: {deviceId, displayDevicePage: false},
});

export const show = () => ({
  type: SET,
  data: {displayDevicePage: true},
});

export const hide = () => ({
  type: SET,
  data: {displayDevicePage: false},
});
