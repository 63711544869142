import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Route, Switch} from 'react-router-dom';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import MaintenanceContainer from '../../containers/MaintenanceContainer/MaintenanceContainer';

// Consts
import page from './page';

// MaintenanceCustomer
import MaintenanceCustomerPage from '../MaintenanceCustomerPage/MaintenanceCustomerPage';
import routeMaintenanceCustomer from '../MaintenanceCustomerPage/route';

// MaintenanceAssign
import MaintenanceAssignPage from '../MaintenanceAssignPage/MaintenanceAssignPage';
import routeMaintenanceAssign from '../MaintenanceAssignPage/route';

// MaintenanceItems
import MaintenanceItemsPage from '../MaintenanceItemsPage/MaintenanceItemsPage';
import routeMaintenanceItems from '../MaintenanceItemsPage/route';

// MaintenanceSignMultipleItems
import MaintenanceSignMultipleItemsPage from '../MaintenanceSignMultipleItemsPage/MaintenanceSignMultipleItemsPage';
import routeMaintenanceSignMultipleItems from '../MaintenanceSignMultipleItemsPage/route';

// MaintenanceDoor
import MaintenanceDoorPage from '../../../maintenanceDoor/pages/MaintenanceDoorPage/MaintenanceDoorPage';
import routeMaintenanceDoor from '../../../maintenanceDoor/pages/MaintenanceDoorPage/route';

// MaintenanceActions
import MaintenanceActionsPage from '../MaintenanceActionsPage/MaintenanceActionsPage';
import routeMaintenanceActions from '../MaintenanceActionsPage/route';

// MaintenanceResolve
import MaintenanceResolvePage from '../MaintenanceResolvePage/MaintenanceResolvePage';
import routeMaintenanceResolve from '../MaintenanceResolvePage/route';

// MaintenanceTabs
import MaintenanceTabsPage from '../MaintenanceTabsPage/MaintenanceTabsPage';

// MaintenanceDoorTabs
import MaintenanceDoorTabsPage from '../../../maintenanceDoor/pages/MaintenanceDoorTabsPage/MaintenanceDoorTabsPage';
import routeMaintenanceDoorTabs from '../../../maintenanceDoor/pages/MaintenanceDoorTabsPage/route';

// SpecifyMaintenanceSubpage
import SpecifyMaintenanceSubpageContainer from '../../containers/SpecifyMaintenanceSubpageContainer/SpecifyMaintenanceSubpageContainer';

const MaintenancePage = ({match}) => (
  <Page page={page}>
    <MaintenanceContainer
      maintenanceID={match.params.id}
      subpage={
        <Switch>
          <Route
            path={routeMaintenanceDoorTabs()}
            component={MaintenanceDoorTabsPage}
          />
          <Route component={MaintenanceTabsPage} />
        </Switch>
      }
    >
      <Switch>
        <Route
          path={routeMaintenanceCustomer()}
          component={MaintenanceCustomerPage}
          exact
        />
        <Route
          path={routeMaintenanceAssign()}
          component={MaintenanceAssignPage}
          exact
        />
        <Route
          path={routeMaintenanceItems()}
          component={MaintenanceItemsPage}
          exact
        />
        <Route
          path={routeMaintenanceSignMultipleItems()}
          component={MaintenanceSignMultipleItemsPage}
          exact
        />
        <Route
          path={routeMaintenanceResolve()}
          component={MaintenanceResolvePage}
          exact
        />
        <Route
          path={routeMaintenanceActions()}
          component={MaintenanceActionsPage}
          exact
        />
        <Route path={routeMaintenanceDoor()} component={MaintenanceDoorPage} />
        <Route component={SpecifyMaintenanceSubpageContainer} />
      </Switch>
    </MaintenanceContainer>
  </Page>
);

MaintenancePage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(MaintenancePage);
