import styled from 'styled-components';

const ChartValueContainer = styled.div`
  position: relative;
  width: 15%;
  float: left;
  text-align: right;
  padding: 5px;
  font-size: 20px;
  font-weight: 800;
  color: rgba(0,0,0,0.8);
  user-select: none;
`;

export default ChartValueContainer;
