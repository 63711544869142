import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createMaintenanceApi = (maintenance, query = {rel: '*'}) =>
  f3tch('/maintenances')
    .profile(profile())
    .query(query)
    .body(maintenance)
    .post();
export default createMaintenanceApi;
