import {isEmail} from 'validator';
import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value) ? value.toLowerCase().replace(/\s/g, '') : null;

const validate = (value) => isEmail(value);

export default type({type: 'string', format, validate});
