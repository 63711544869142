import styled from 'styled-components';
import {transitions} from 'polished';

const Container = styled.div`
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 36px;
  height: 90px;
  display: flex;
  align-items: center;
  transform: translateX(${({open}) => (open ? '280px' : '72px')});
  ${transitions('transform .3s ease-in-out')}
`;

export default Container;
