// Action constants
import {SET} from './constants';

export const set = (data = {}) => ({type: SET, data});

export const startVehicleUpdate = () => (dispatch, getState) => {
  const {selectedVehicle} = getState().vehicle;
  dispatch(set({selectedVehicle: null, previousChoice: {...selectedVehicle}}));
};

export const cancelVehicleUpdate = () => (dispatch, getState) => {
  const {previousChoice} = getState().vehicle;
  dispatch(set({selectedVehicle: {...previousChoice}, previousChoice: null}));
};
