import {v4} from 'uuid';

// Action constants
import {SET} from './constants';

// Api
import listApi from '../api/list.api.intervention';

// Query
import setQuery from '../../api/lib/query.lib.api';

export const set = (data = {}) => ({type: SET, data});

export const updateIntervention = (intervention) => (dispatch, getState) => {
  const storedIntervention = getState().intervention.intervention;
  const interventions = [...getState().intervention.interventions].map((int) =>
    int.id === intervention.id ? intervention : int
  );
  const newIntervention =
    !!storedIntervention && storedIntervention.id === intervention.id
      ? intervention
      : storedIntervention;
  dispatch({type: SET, data: {intervention: newIntervention, interventions}});
};

export const getInterventions = (data = {}) => async (dispatch, getState) => {
  const apiID = v4();
  const {search, filter, sort, limit} = getState().intervention;
  const newSearch = data.search !== undefined ? data.search : search;
  const newFilter = data.filter !== undefined ? data.filter : filter;
  const newSort = data.sort !== undefined ? data.sort : sort;

  dispatch({
    type: SET,
    data: {
      apiID,
      loading: true,
      search: newSearch,
      filter: newFilter,
      sort: newSort,
      offset: 0,
      more: true,
      interventions: [],
    },
  });

  try {
    const query = setQuery({
      search: newSearch,
      status: newFilter,
      order: newSort,
      offset: 0,
      rel: ['customer','customerBranch', 'doors', 'assignees'],
      limit,
    });
    const interventions = await listApi(query);
    if (getState().intervention.apiID !== apiID) return;
    dispatch({
      type: SET,
      data: {
        interventions,
        loading: false,
        more: interventions.length === limit,
        offset: limit,
      },
    });
  } catch (error) {
    dispatch({
      type: SET,
      data: {loading: false},
    });
  }
};

export const getMoreInterventions = () => async (dispatch, getState) => {
  const {
    loading,
    search,
    filter,
    sort,
    limit,
    offset,
    more,
    apiID,
    interventions: oldInterventions,
  } = getState().intervention;

  if (!more || loading) return;

  dispatch({
    type: SET,
    data: {
      loading: true,
    },
  });

  try {
    const query = setQuery({
      search,
      status: filter,
      order: sort,
      offset,
      limit,
      rel: '*',
    });
    const interventions = await listApi(query);
    if (getState().intervention.apiID !== apiID) return;
    dispatch({
      type: SET,
      data: {
        interventions: [...oldInterventions, ...interventions],
        loading: false,
        offset: limit + offset,
        more: interventions.length === limit,
      },
    });
  } catch (error) {
    dispatch({
      type: SET,
      data: {loading: false},
    });
  }
};
