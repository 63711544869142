import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {format} from 'date-fns';

// Api
import getRentalsApi from '../../api/getRentals.api.vehicle';
import rentApi from '../../api/rent.api.vehicle';
import unrentApi from '../../api/unrent.api.vehicle';
import getVehiclesApi from '../../api/get.api.vehicle';

// Actions
import {
  set as setAct,
  cancelVehicleUpdate as cancelVehicleUpdateAct,
} from '../../redux/actions';

// Components
import {Button} from 'doorson-ui';
import Loader from '../../components/Loader/Loader';
import VehicleSearch from '../../components/VehicleSearch/VehicleSearch';
import SelectVehicle from '../../components/SelectVehicle/SelectVehicle';
import HeadingContainer from './../../components/SelectVehicle/components/HeadingContainer';
import BackButtonContainer from './../../components/SelectVehicle/components/BackButtonContainer';

// Alertify
import {alertify} from 'doorson-ui';

class SelectVehicleContainer extends Component {
  static propTypes = {
    loaded: PropTypes.bool,
    vehicles: PropTypes.array,
    selectedVehicle: PropTypes.object,
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    search: '',
  };

  componentDidMount() {
    this.getSelectedVehicle();
  }

  today = () => format(new Date(), 'yyyy-MM-dd');

  emptyVehicle = () => ({licensePlate: 'an office chair'});

  vehicles = () => {
    const {vehicles, previousChoice} = this.props;
    const {search} = this.state;
    const filteredVehicles = !search.trim().length
      ? [...vehicles]
      : [...vehicles].filter((vehicle) =>
          vehicle.licensePlate.toLowerCase().includes(search.toLowerCase())
        );
    return !!previousChoice
      ? [...filteredVehicles].filter((v) => v.id !== previousChoice.id)
      : filteredVehicles;
  };

  getSelectedVehicle = async () => {
    const {user, dispatch} = this.props;
    const date = this.today();
    try {
      const selectedVehicles = await getRentalsApi({
        commuter: user.id,
        dateSelected: date,
        order: 'dc desc',
      });
      const selectedVehicle = [...selectedVehicles].find(
        (vehicle) =>
          !!vehicle.commuter &&
          vehicle.commuter.id === user.id &&
          vehicle.dateSelected === date
      );
      if (!!selectedVehicle)
        dispatch(
          setAct({
            selectedVehicle: selectedVehicle.vehicle || this.emptyVehicle(),
          })
        );
      this.getVehicles();
    } catch (error) {
      this.getVehicles();
    }
  };

  getVehicles = async () => {
    // TODO: I18N
    try {
      const vehicles = await getVehiclesApi({offset: 0, limit: 200});
      this.props.dispatch(setAct({loaded: true, vehicles}));
    } catch (error) {
      alertify.error('Something went wrong. Re-launch the app.');
      this.props.dispatch(setAct({loaded: true}));
    }
  };

  onSelect = async (vehicle) => {
    // TODO: I18N
    const {user, previousChoice, dispatch} = this.props;
    const date = this.today();
    if (this.state.loading) return;

    this.setState({loading: true});
    try {
      if (!!previousChoice) {
        const allRentals = await getRentalsApi({
          commuter: user.id,
          dateSelected: date,
          order: 'dc desc',
        });
        const rentals = [...allRentals].filter(
          (rental) =>
            rental.commuter.id === user.id && rental.dateSelected === date
        );
        await Promise.all(rentals.map((rental) => unrentApi(rental.id)));
      }
      await rentApi({
        dateSelected: this.today(),
        isDriving: !!vehicle ? !vehicle.rentals.length : false,
        commuterId: user.id,
        vehicleId: !!vehicle ? vehicle.id : null,
      });
      const vehicles = await getVehiclesApi();
      dispatch(
        setAct({
          selectedVehicle: vehicle || this.emptyVehicle(),
          vehicles,
          previousChoice: null,
        })
      );
    } catch (error) {
      alertify.error('Could not rent the vehicle.');
    }
    this.setState({loading: false});
  };

  onSearch = (search) => {
    if (this.state.loading) return;
    this.setState({search});
  };

  onBack = () => {
    this.props.dispatch(cancelVehicleUpdateAct());
  };

  render() {
    // TODO: I18N
    const {selectedVehicle, previousChoice, children, loaded} = this.props;
    const {search, loading} = this.state;
    return !!selectedVehicle ? (
      children
    ) : !loaded || loading ? (
      <Loader />
    ) : (
      <Fragment>
        <VehicleSearch search={search} onSearch={this.onSearch} />
        <HeadingContainer>
          Select your vehicle
          {!!previousChoice && (
            <BackButtonContainer>
              <Button onClick={this.onBack}>Back</Button>
            </BackButtonContainer>
          )}
        </HeadingContainer>
        <SelectVehicle vehicles={this.vehicles()} onClick={this.onSelect} />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  loaded: state.vehicle.loaded,
  vehicles: state.vehicle.vehicles,
  selectedVehicle: state.vehicle.selectedVehicle,
  previousChoice: state.vehicle.previousChoice,
  user: state.auth.user,
}))(SelectVehicleContainer);
