import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Container, Row, Column, Button} from 'doorson-ui';
import Content from './components/Content';
import Actions from './components/Actions';
import Edit from './components/Edit';
import Delete from './components/Delete';
import Name from './components/Name';

const DocumentationActions = ({
  editLabel,
  deleteLabel,

  folder,
  editable,
  deletable,
  onDelete,
  onEdit,
}) => (
  <Container>
    <Row>
      <Column>
        <Content>
          <div>
            {!!folder && (
              <Name>
                <i className="mdi mdi-folder" /> {folder}
              </Name>
            )}
          </div>
          <Actions>
            {editable && (
              <Edit>
                <Button onClick={onEdit}>{editLabel}</Button>
              </Edit>
            )}
            {deletable && (
              <Delete>
                <Button onClick={onDelete}>{deleteLabel}</Button>
              </Delete>
            )}
          </Actions>
        </Content>
      </Column>
    </Row>
  </Container>
);

DocumentationActions.propTypes = {
  editLabel: PropTypes.node,
  deleteLabel: PropTypes.node,

  folder: PropTypes.string,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default DocumentationActions;
