import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white, black} = colors;

const AddTab = styled.div`
  width: 44px;
  min-width: 44px;
  max-width: 44px;
  height: 44px;
  background: ${white};
  color: ${black};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
`;

export default AddTab;
