import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// Components
import {Table} from 'doorson-ui';
import QuickActions from '../QuickActions/QuickActions';
import Container from './components/Container';

// Customer
import getCustomerName from '../../../customer/lib/getName.lib.customer';

// Intervention
import getPriority from '../../priority/getPriority.priority.intervention';

// Types
import {date as dateType} from '../../../types';
import GpsNavigation from "../../../layout/components/GpsNavigation/GpsNavigation";

class InterventionQuickView extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    intervention: PropTypes.object,
    columns: PropTypes.array,
    user: PropTypes.object,
    users: PropTypes.array,
    onClose: PropTypes.func,
    onAction: PropTypes.func,
  };

  handleClickOutside = () => this.props.onClose();

  render() {
    const {loading, intervention, columns, user, users, onAction} = this.props;
    return (
      <Container>
        <Table columns={columns} headless>
          {(TableRow) => (
            <TableRow
              key={intervention.id}
              label={
                intervention.resolved || intervention.resolved
                  ? null
                  : getPriority(intervention).color
              }
              theme={
                intervention.resolved || intervention.closed
                  ? 'greyer'
                  : 'white'
              }
            >
              {(TableCol) => [
                <TableCol smallPadding key="priority"/>,
                <TableCol smallPadding span={1.5} key="incident">
                  {intervention.code}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="dc">
                  {dateType(intervention.dc).format()}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="customer">
                  {getCustomerName(intervention.customer)}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="location">
                  {!!intervention.customerBranch &&
                  !!intervention.customerBranch.branchLocation && (
                    <span>
                        {!!intervention.customerBranch.branchName && (
                          <span>
                            {intervention.customerBranch.branchName}
                            <br/>
                          </span>
                        )}
                      {intervention.customerBranch.branchLocation.address}
                      <br/>
                      {intervention.customerBranch.branchLocation.city}
                      <GpsNavigation location={intervention.customerBranch.branchLocation}/>
                      </span>
                  )}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="doorType">
                  {intervention.doors.length > 1
                    ? 'Multiple doors'
                    : intervention.doors.map(({door}, index) =>
                      !!door ? (
                        <div key={door.id}>
                          {door.product.manufacturer.name} {door.product.name}
                        </div>
                      ) : (
                        <div key={index}>Unknown doors</div>
                      )
                    )}
                </TableCol>,
                <TableCol smallPadding span={1.5} key="action">
                  <QuickActions
                    loading={loading}
                    intervention={intervention}
                    priorityColor={getPriority(intervention).color}
                    users={users}
                    user={user}
                    onAction={onAction}
                  />
                </TableCol>,
              ]}
            </TableRow>
          )}
        </Table>
      </Container>
    );
  }
}

export default onClickOutside(InterventionQuickView);
