import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const Wrap = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${grey};
`;

export default Wrap;
