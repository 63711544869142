import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkerGrey} = colors;

const NoDataFound = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${darkerGrey};
  font-size: 14px;
`;

export default NoDataFound;
