import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import createApi from '../../api/create.api.maintenanceCheck';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminMaintenanceCheckButton from '../../components/NewAdminMaintenanceCheckButton/NewAdminMaintenanceCheckButton';
import NewMaintenanceCheckModal from '../../components/NewMaintenanceCheckModal/NewMaintenanceCheckModal';

class NewMaintenanceCheckContainer extends Component {
  static propTypes = {
    maintenanceChecks: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    checkItem: '',
    action: '',
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, checkItem: '', action: ''});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, checkItem: '', action: ''});
  };

  create = async () => {
    // TODO: I18n
    const {loading, visible, checkItem, action} = this.state;
    if (loading || !visible) return;

    if (!checkItem.trim().length)
      return alertify.warning('Insert the check item');

    if (!action.trim().length) return alertify.warning('Insert the action');

    this.setState({loading: true});

    try {
      const maintenanceCheck = await createApi({checkItem, action});
      const {dispatch, maintenanceChecks} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(
        setAct({maintenanceChecks: [...maintenanceChecks, maintenanceCheck]})
      );
    } catch (error) {
      alertify.error('MaintenanceCheck could not be created');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {loading, visible, checkItem, action} = this.state;
    return (
      <Fragment>
        <NewAdminMaintenanceCheckButton onClick={this.show}>
          New Maintenance Check
        </NewAdminMaintenanceCheckButton>
        <NewMaintenanceCheckModal
          title="New Maintenance Check"
          checkItemLabel="Label"
          actionLabel="Action"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          checkItem={checkItem}
          action={action}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.maintenanceCheck}))(
  NewMaintenanceCheckContainer
);
