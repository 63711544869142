import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Heading from './components/Heading';

const DataBlock = ({label, children}) => (
  <Container>
    <Heading>{label}</Heading>
    {children}
  </Container>
);

DataBlock.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export default DataBlock;
