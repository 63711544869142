import isNumber from 'is-number';

import type from '../type';

const format = (value) =>
  !!value || value === 0 || value === '0'
    ? `${value}`.replace(/[^0-9]/g, '')
    : '';

const validate = (value) => isNumber(value) && /^\d*$/.test(value);

export default type({type: 'string', format, validate});
