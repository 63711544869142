import styled from 'styled-components';

const OptionAction = styled.i`
  display: block;
  margin-left: 5px;
  cursor: pointer;
  font-size: 18px;
`;

export default OptionAction;
