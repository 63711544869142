import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

class Page extends Component {
  static propTypes = {
    page: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    const {page, dispatch} = this.props;
    dispatch(setAct({page}));
  }

  render() {
    const {children} = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

export default connect()(Page);
