import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 40px;
  position: relative;
  border: 1px solid ${grey};
  border-radius: 5px;
  overflow: hidden;
`;

export default Container;
