const getFileIcon = (mimetype) => {
  switch (mimetype) {
    case 'text/plain':
    case 'application/vnd.oasis.opendocument.text':
      return 'mdi mdi-format-text'
    case 'application/zip':
    case 'application/vnd.rar':
    case 'application/x-bzip':
    case 'application/x-bzip2':
    case 'application/gzip':
    case 'application/x-7z-compressed':
    case 'application/x-tar':
    case 'application/x-gzip':
      return 'mdi mdi-zip-box';

    case 'application/pdf':
      return 'mdi mdi-file-pdf';

    case 'application/vnd.android.package-archive':
      return 'mdi mdi-cellphone-android'

    case 'application/acad':
    case 'application/clariscad':
    case 'application/dxf':
    case 'application/x-dwf':
      return 'mdi mdi-file-cad';

    case 'application/pkcs8':
    case 'application/pkcs10':
    case 'application/x-pkcs12':
    case 'application/pkix-cert':
    case 'application/pkix-crl':
    case 'application/pkcs7-mime':
    case 'application/x-x509-ca-cert':
    case 'application/x-x509-user-cert':
    case 'application/x-pkcs7-crl':
    case 'application/x-pem-file':
    case 'application/x-pkcs7-certificates':
    case 'application/x-pkcs7-certreqresp':
      return 'mdi mdi-file-certificate';

    case 'application/octet-stream':
    case 'application/x-macbinary':
    case 'application/macbinary':
      return 'mdi mdi-matrix'

    case 'text/csv':
      return 'mdi mdi-file-delimited';

    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'mdi mdi-file-excel';

    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'mdi mdi-file-word';

    case 'audio/mpeg':
    case 'audio/wav':
    case 'audio/webm':
    case 'audio/3gpp':
    case 'audio/3gpp2':
    case 'audio/x-m4a':
      return 'mdi mdi-file-music'

    case 'video/x-msvideo':
    case 'video/mpeg':
    case 'video/ogg':
    case 'video/mp2t':
    case 'video/webm':
    case 'video/3gpp':
    case 'video/3gpp2':
    case 'video/mp4':
      return 'mdi mdi-file-video'

    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'mdi mdi-file-powerpoint';

    case 'application/xml':
    case 'text/xml':
    case 'font/otf':
    case 'text/css':
    case 'application/json':
    case 'text/javascript':
      return 'mdi mdi-file-code';

    case 'text/x-java-properties':
    case 'application/x-yaml':
    case 'text/x-yaml':
      return 'mdi mdi-file-settings';

    case 'application/java-archive':
      return 'mdi mdi-memory';

    default:
      return 'mdi mdi-file'
  }
}
export default getFileIcon;
