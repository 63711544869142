import styled from 'styled-components';

const Name = styled.div`
  width: 100%;
  font-size: 14px;
  margin-left: 10px;
  flex: 1;
`;

export default Name;
