import styled from 'styled-components';
import {transitions} from 'polished';

// Colors
import {colors} from 'doorson-ui';
const {white, black} = colors;

const Container = styled.div`
  position: absolute;
  bottom: 25px;
  left: 10px;
  z-index: 10px;
  background: ${black};
  color: ${white};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  opacity: ${({visible}) => (visible ? 1 : 0)};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  ${transitions('opacity .2s ease-in-out visibility .2s ease-in-out')};
`;

export default Container;
