import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteInterventionDoorFaultApi = (interventionDoorID, faultID) =>
  f3tch(`/intervention-doors/${interventionDoorID}/faults/${faultID}`)
    .profile(profile())
    .delete();
export default deleteInterventionDoorFaultApi;
