import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createRepairApi = (repair) =>
  f3tch('/repairs')
    .profile(profile())
    .body(repair)
    .post();
export default createRepairApi;
