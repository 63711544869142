import styled from 'styled-components';

const Content = styled.div`
  width: ${({full}) => (full ? '100%' : '50%')};
  min-width: ${({full}) => (full ? '100%' : '50%')};
  max-width: ${({full}) => (full ? '100%' : '50%')};
  height: 100%;
  padding: 1px;
  display: flex;
  flex-direction: column;
`;

export default Content;
