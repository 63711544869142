import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  float: left;
  width: 310px;
  height: 180px;
  margin: 10px 0;
  padding: 0 10px 0 5px;
  border-right: 1px solid rgba(0,0,0, 0.2);
  user-select: none;
`;

export default Container;
