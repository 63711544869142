import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import ActivitiesContainer from '../../containers/ActivitiesContainer/ActivitiesContainer';

// Consts
import page from './page';

const ActivitiesPage = () => (
  <Page page={page}>
    <ActivitiesContainer />
  </Page>
);

export default ActivitiesPage;
