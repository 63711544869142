import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {CheckBox} from 'doorson-ui';
import {Label} from 'doorson-ui';

import Header from './components/Header';

const Preview = ({
  customerLabel,
  customerTypeLabel,
  companyLabel,
  registrationNumberLabel,
  firstNameLabel,
  lastNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  branchLocationLabel,
  phoneLabel,
  emailLabel,

  givenDescriptionLabel,
  manufacturerLabel,
  doorVersionLabel,
  doorTypeLabel,
  redundancyLabel,
  indoorLocationLabel,
  buildingFloorLabel,

  doorSerialLabel,
  electronicsSerialLabel,
  motorSerialLabel,
  technicianDescriptionLabel,
  repairDescriptionLabel,
  replacedMaterialLabel,
  findingsRemarksLabel,
  customerRemarksLabel,
  internalRemarksLabel,
  replacementNeededLabel,
  doorsWorkingLabel,
  prepareOfferLabel,

  confirmationLabel,
  transportLocationLabel,
  destinationLabel,
  signatoryFirstNameLabel,
  signatoryLastNameLabel,

  loading,
  intervention,
  resolveLabel,
  onResolve,
}) => (
  <Fragment>
    <Row margin>
      <Column>
        <Header>{customerLabel}</Header>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Label label={customerTypeLabel}>{intervention.customer.type}</Label>
      </Column>
    </Row>
    {intervention.customer.type === 'business' ? (
      <Row margin>
        <Column size={1 / 3}>
          <Label label={companyLabel}>{intervention.customer.company}</Label>
        </Column>
        <Column size={1 / 3}>
          <Label label={registrationNumberLabel}>
            {intervention.customer.registrationNumber}
          </Label>
        </Column>
      </Row>
    ) : (
      <Row margin>
        <Column size={1 / 3}>
          <Label label={firstNameLabel}>
            {intervention.customer.firstName}
          </Label>
        </Column>
        <Column size={1 / 3}>
          <Label label={lastNameLabel}>{intervention.customer.lastName}</Label>
        </Column>
      </Row>
    )}
    <Row margin>
      <Column size={2 / 3}>
        <Label label={addressLabel}>
          {intervention.customer.location.address}
        </Label>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 3}>
        <Label label={zipLabel}>{intervention.customer.location.zip}</Label>
      </Column>
      <Column size={1 / 3}>
        <Label label={cityLabel}>{intervention.customer.location.city}</Label>
      </Column>
      <Column size={1 / 3}>
        <Label label={countryLabel}>
          {intervention.customer.location.countryCode}
        </Label>
      </Column>
    </Row>
    {!!intervention.customerBranch && (
      <Row margin>
        <Column size={2 / 3}>
          <Label label={branchLocationLabel}>
            {intervention.customerBranch.branchName},{' '}
            {intervention.customerBranch.branchLocation.address},{' '}
            {intervention.customerBranch.branchLocation.zip}{' '}
            {intervention.customerBranch.branchLocation.city},{' '}
            {intervention.customerBranch.branchLocation.countryCode}
          </Label>
        </Column>
      </Row>
    )}
    <Row margin>
      <Column size={1 / 3}>
        <Label label={phoneLabel}>{intervention.phone}</Label>
      </Column>
      <Column size={1 / 3}>
        <Label label={emailLabel}>{intervention.email || ''}</Label>
      </Column>
    </Row>
    {intervention.doors.map((door) => (
      <Fragment key={door.id}>
        <Row margin>
          <Column>
            <Header>
              {door.door.indoorLocation} {door.door.buildingFloor}
            </Header>
          </Column>
        </Row>
        <Row margin>
          <Column size={2 / 3}>
            <Label label={givenDescriptionLabel}>{door.givenDescription}</Label>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 3}>
            <Label label={manufacturerLabel}>
              {door.door.product.manufacturer.name}
            </Label>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 3}>
            <Label label={doorVersionLabel}>{door.door.product.name}</Label>
          </Column>
          <Column size={1 / 3}>
            <Label label={doorTypeLabel}>
              {!!door.door.productType ? door.door.productType.name || '' : ''}
            </Label>
          </Column>
          <Column size={1 / 3}>
            <Label label={redundancyLabel}>
              {door.door.redundancy ? 'Yes' : 'No'}
            </Label>
          </Column>
        </Row>
        <Row margin>
          <Column size={2 / 3}>
            <Label label={indoorLocationLabel}>
              {door.door.indoorLocation}
            </Label>
          </Column>
          <Column size={1 / 3}>
            <Label label={buildingFloorLabel}>{door.door.buildingFloor}</Label>
          </Column>
        </Row>
        <Row margin>
          <Column size={2 / 9}>
            <Label label={doorSerialLabel}>{door.door.doorSerial}</Label>
          </Column>
          <Column size={2 / 9}>
            <Label label={electronicsSerialLabel}>
              {door.door.electronicsSerial}
            </Label>
          </Column>
          <Column size={2 / 9}>
            <Label label={motorSerialLabel}>{door.door.motorSerial}</Label>
          </Column>
        </Row>
        <Row margin>
          <Column size={2 / 3}>
            <Label label={technicianDescriptionLabel}>
              {door.faults.map(({id, fault, name}) => (
                <div key={id}>{!!fault ? fault.name : name}</div>
              ))}
            </Label>
          </Column>
        </Row>
        <Row margin>
          <Column size={2 / 3}>
            <Label label={repairDescriptionLabel}>
              {door.repairs.map(({id, repair, description}) => (
                <div key={id}>
                  {!!repair ? repair.description : description}
                </div>
              ))}
            </Label>
          </Column>
        </Row>
        <Row margin>
          <Column size={2 / 3}>
            <Label label={replacedMaterialLabel}>
              {door.materials.map(
                ({id, material, name, warrantyTill, quantity}) => (
                  <div key={id}>
                    {quantity}x {!!material ? material.name : name}{' '}
                    {warrantyTill && '(G)'}
                  </div>
                )
              )}
            </Label>
          </Column>
        </Row>
        <Row margin>
          <Column size={2 / 3}>
            <Label label={findingsRemarksLabel}>
              {door.findingsAndRemarks || ''}
            </Label>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 3}>
            <Label label={customerRemarksLabel}>
              {door.customerRemarks || ''}
            </Label>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <CheckBox
              selected={door.replacementNeeded}
              onSelect={() => {}}
              disabled
            >
              {replacementNeededLabel}
            </CheckBox>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <CheckBox
              selected={door.workingProperly}
              onSelect={() => {}}
              disabled
            >
              {doorsWorkingLabel}
            </CheckBox>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <CheckBox selected={door.prepareOffer} onSelect={() => {}} disabled>
              {prepareOfferLabel}
            </CheckBox>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 3}>
            <Label label={internalRemarksLabel}>
              {door.internalRemarks || ''}
            </Label>
          </Column>
        </Row>
      </Fragment>
    ))}
    <Row margin>
      <Column>
        <Header>{confirmationLabel}</Header>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 2}>
        <Label label={transportLocationLabel}>
          {intervention.transportLocation.name}
        </Label>
      </Column>
      <Column size={1 / 2}>
        <Label label={destinationLabel}>
          {intervention.transportDestination}
        </Label>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 2}>
        <Label label={signatoryFirstNameLabel}>
          {intervention.signatoryFirstName}
        </Label>
      </Column>
      <Column size={1 / 2}>
        <Label label={signatoryLastNameLabel}>
          {intervention.signatoryLastName}
        </Label>
      </Column>
    </Row>
    <Button theme="orange" loading={loading} onClick={onResolve} outline>
      {resolveLabel}
    </Button>
  </Fragment>
);

Preview.propTypes = {
  customerLabel: PropTypes.node,
  customerTypeLabel: PropTypes.node,
  companyLabel: PropTypes.node,
  registrationNumberLabel: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  branchLocationLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,

  givenDescriptionLabel: PropTypes.node,
  manufacturerLabel: PropTypes.node,
  doorVersionLabel: PropTypes.node,
  doorTypeLabel: PropTypes.node,
  redundancyLabel: PropTypes.node,
  indoorLocationLabel: PropTypes.node,
  buildingFloorLabel: PropTypes.node,

  doorSerialLabel: PropTypes.node,
  electronicsSerialLabel: PropTypes.node,
  motorSerialLabel: PropTypes.node,
  technicianDescriptionLabel: PropTypes.node,
  repairDescriptionLabel: PropTypes.node,
  replacedMaterialLabel: PropTypes.node,
  findingsRemarksLabel: PropTypes.node,
  customerRemarksLabel: PropTypes.node,
  internalRemarksLabel: PropTypes.node,
  replacementNeededLabel: PropTypes.node,
  doorsWorkingLabel: PropTypes.node,
  prepareOfferLabel: PropTypes.node,

  confirmationLabel: PropTypes.node,
  transportLocationLabel: PropTypes.node,
  destinationLabel: PropTypes.node,
  signatoryFirstNameLabel: PropTypes.node,
  signatoryLastNameLabel: PropTypes.node,

  loading: PropTypes.bool,
  intervention: PropTypes.object,
  resolveLabel: PropTypes.string,
  onResolve: PropTypes.func,
};

export default Preview;
