import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import listDocumentationApi from '../../api/list.api.documentation';
import getDocumentationByIDApi from '../../api/getByID.api.documentation';
import deleteDocumentationApi from '../../api/delete.api.documentation';

// Alerts
import {alertify} from 'doorson-ui';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import DocumentationLoader from '../../components/DocumentationLoader/DocumentationLoader';
import DocumentationHeader from '../../components/DocumentationHeader/DocumentationHeader';
import DocumentationActions from '../../components/DocumentationActions/DocumentationActions';
import Documentation from '../../components/Documentation/Documentation';

// Containers
import NewDocumentationContainer from '../../containers/NewDocumentationContainer/NewDocumentationContainer';
import EditDocumentationImageContainer from '../../containers/EditDocumentationImageContainer/EditDocumentationImageContainer';
import DocumentationAttachmentsContainer from '../../../file/containers/DocumentationAttachmentsContainer/DocumentationAttachmentsContainer';

// Routes
import documentationRoute from '../../pages/DocumentationPage/route';
import documentationSubRoute from '../../pages/DocumentationSubPage/route';

// Roles
import {
  CHIEF_SERVICE_TECHNICIAN,
  DOCUMENTATION_ADMIN,
  SYSTEM,
} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

class DocumentationContainer extends Component {
  static propTypes = {
    documentationID: PropTypes.string,
    history: PropTypes.object,
    user: PropTypes.object,
  };

  static defaultProps = {
    documentationID: null,
  };

  state = {
    loading: true,
    documentation: null,
    folders: [],
    newFolderModalVisible: false,
    editImageVisible: false,
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.documentationID !== this.props.documentationID) this.init();
  }

  apiID = null;

  init = () => {
    this.getDocumentation();
  };

  getDocumentation = async () => {
    // TODO: I18n
    const {history, documentationID} = this.props;
    const apiID = v4();
    this.apiID = apiID;

    this.setState({loading: true});

    try {
      const data = await (!!documentationID
        ? this.getChildDocumentation(documentationID)
        : this.getRootDocumentation());
      if (this.apiID !== apiID) return;
      this.setState({loading: false, ...data});
    } catch (error) {
      alertify.warning('Documentation folder does not exist');
      history.replace(documentationRoute());
    }
  };

  getRootDocumentation = async () => {
    const documents = await listDocumentationApi({
      rel: 'documentationFolder.privileges',
    });
    return {documentation: null, folders: documents};
  };

  getChildDocumentation = async (documentationID) => {
    const documentation = await getDocumentationByIDApi(documentationID, {
      rel: 'documentationFolder.privileges',
    });
    return {
      documentation,
      folders: [...documentation.subfolders],
    };
  };

  delete = async () => {
    // TODO: I18n
    const {history} = this.props;
    const {loading, documentation} = this.state;
    if (loading || !this.deletableFolder()) return;

    this.setState({loading: true});

    try {
      await deleteDocumentationApi(documentation.id);
      history.replace(
        !!documentation.parentFolder
          ? documentationSubRoute(documentation.parentFolder.id)
          : documentationRoute()
      );
    } catch (error) {
      alertify.error('Could not delete folder');
      this.setState({loading: false});
    }
  };

  back = () => {
    const {history} = this.props;
    const {documentation} = this.state;
    if (!documentation) return;
    history.push(
      !!documentation.parentFolder
        ? documentationSubRoute(documentation.parentFolder.id)
        : documentationRoute()
    );
  };

  showNewFolderModal = () => this.setState({newFolderModalVisible: true});

  hideNewFolderModal = () => this.setState({newFolderModalVisible: false});

  showEditImageModal = () => this.setState({editImageVisible: true});

  hideEditImageModal = () => this.setState({editImageVisible: false});

  hasPrivilege = ({privileges, user, privilege}) => {
    const userPrivileges = [...(privileges || [])].filter(
      ({role}) => !!user.roles.find((userRole) => userRole.role === role)
    );
    return !![...userPrivileges].find((permission) => permission[privilege]);
  };

  canCreateFiles = () =>
    !!this.state.documentation &&
    this.hasPrivilege({
      privileges: this.state.documentation.privileges,
      user: this.props.user,
      privilege: 'filesCreate',
    });

  deletableFiles = () =>
    !!this.state.documentation &&
    this.hasPrivilege({
      privileges: this.state.documentation.privileges,
      user: this.props.user,
      privilege: 'filesDelete',
    });

  readableFolder = (folder) =>
    this.hasPrivilege({
      privileges: folder.privileges,
      user: this.props.user,
      privilege: 'read',
    });

  deletableFolder = () =>
    !!this.state.documentation &&
    !this.state.documentation.subfolders.length &&
    this.hasPrivilege({
      privileges: this.state.documentation.privileges,
      user: this.props.user,
      privilege: 'delete',
    });

  editableFolder = () =>
    !!this.state.documentation &&
    this.hasPrivilege({
      privileges: this.state.documentation.privileges,
      user: this.props.user,
      privilege: 'delete',
    });

  canCreateFolder = () => {
    const {user} = this.props;
    const {documentation} = this.state;
    return !documentation
      ? hasPermission({
          user: user,
          roles: [CHIEF_SERVICE_TECHNICIAN, DOCUMENTATION_ADMIN, SYSTEM],
        })
      : this.hasPrivilege({
          privileges: documentation.privileges,
          user,
          privilege: 'create',
        });
  };

  onNewFolder = (folder) =>
    this.setState({
      newFolderModalVisible: false,
      folders: [...this.state.folders, folder],
    });

  onEditFolder = (folder) =>
    this.setState({
      editImageVisible: false,
      folders: [...this.state.folders].map((f) =>
        f.id === folder.id ? folder : f
      ),
    });

  onFolder = (folder) => () =>
    this.props.history.push(documentationSubRoute(folder.id));

  onFileChange = (files) => {
    const {documentation} = this.state;
    if (!documentation) return;
    this.setState({
      documentation: {
        ...documentation,
        files: [...files],
      },
    });
  };

  folders = () =>
    [...this.state.folders].filter((folder) => this.readableFolder(folder));

  // TODO: I18n
  renderHeader = () => (
    <Fragment>
      <DocumentationHeader>Software & Documentation</DocumentationHeader>
      <DocumentationActions
        editLabel="Change Folder Image"
        deleteLabel="Delete Folder"
        folder={
          !!this.state.documentation ? this.state.documentation.title : null
        }
        editable={this.editableFolder()}
        deletable={this.deletableFolder()}
        onDelete={this.delete}
        onEdit={this.showEditImageModal}
      />
    </Fragment>
  );

  render() {
    const {documentationID} = this.props;
    const {
      loading,
      newFolderModalVisible,
      documentation,
      editImageVisible,
    } = this.state;
    return loading ? (
      <DocumentationLoader />
    ) : (
      <Fragment>
        <ContentLayout header={this.renderHeader()}>
          <Documentation
            editFolderLabel="Change Folder Image"
            newFolderLabel="New Folder"
            backLabel="Back"
            canCreateFolder={this.canCreateFolder()}
            folder={documentation}
            folders={this.folders()}
            onNewFolder={this.showNewFolderModal}
            onEdit={this.onEdit}
            onFolder={this.onFolder}
            onBack={this.back}
          />
          {!!documentation && (
            <DocumentationAttachmentsContainer
              canCreate={this.canCreateFiles()}
              deletable={this.deletableFiles()}
              documentation={documentation}
              onFileChange={this.onFileChange}
            />
          )}
        </ContentLayout>
        <NewDocumentationContainer
          documentationID={documentationID}
          visible={newFolderModalVisible}
          onComplete={this.onNewFolder}
          onClose={this.hideNewFolderModal}
        />
        <EditDocumentationImageContainer
          folder={documentation}
          visible={editImageVisible}
          onComplete={this.onEditFolder}
          onClose={this.hideEditImageModal}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({user: state.auth.user}))(
  withRouter(DocumentationContainer)
);
