import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// CustomersAdminPage
import CustomersAdminPage from '../CustomersAdminPage/CustomersAdminPage';
import routeCustomersAdminPage from '../CustomersAdminPage/route';

// CustomerAdminPage
import CustomerAdminPage from '../CustomerAdminPage/CustomerAdminPage';
import routeCustomerAdminPage from '../CustomerAdminPage/route';

// Consts
import page from './page';

const CustomersAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeCustomersAdminPage()}
        component={CustomersAdminPage}
        exact
      />
      <Route
        path={routeCustomerAdminPage()}
        component={CustomerAdminPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default CustomersAdminPages;
