import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import $ from 'jquery';
import {
  isAfter,
  isEqual,
  toDate,
  getTime,
  setHours,
  getHours,
  setMinutes,
  getMinutes,
} from 'date-fns';

// Components
import {Row, Column, alertify} from 'doorson-ui';
import EntryPriorities from '../EntryPriorities/EntryPriorities';
import AlertDropDown from '../AlertDropDown/AlertDropDown';
import Invitees from '../Invitees/Invitees';
import Date from '../Date/Date';
import Time from '../Time/Time';

import Container from './components/Container';
import LoadingContainer from './components/LoadingContainer';
import Priority from './components/Priority';
import Content from './components/Content';
import Title from './components/Title';
import Info from './components/Info';
import InfoBox from './components/InfoBox';
import Caret from './components/Caret';
import TitleFlex from './components/TitleFlex';
import EditableTitle from './components/EditableTitle';
import EditableInfo from './components/EditableInfo';
import Line from './components/Line';
import Input from './components/Input';
import PriorityContainer from './components/PriorityContainer';
import PriorityFlex from './components/PriorityFlex';
import DeleteContainer from './components/DeleteContainer';
import DeleteFlex from './components/DeleteFlex';
import Delete from './components/Delete';
import Flex from './components/Flex';
import Alert from './components/Alert';
import AlertRow from './components/AlertRow';

// Constants
import HOUR_HEIGHT from '../../constants/hourHeight.constant.reminder';

// Lib
import timeToNumber from '../../lib/timeToNumber.lib.reminder';

class Entry extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    reminder: PropTypes.object,
    selected: PropTypes.bool,
    left: PropTypes.bool,
    overlapping: PropTypes.array,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
  };

  state = {
    editing: null,
    prioritiesVisible: false,
  };

  componentDidMount() {
    if (this.props.selected) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) this.init();
  }

  init = () => this.setState({editing: null});

  startEditing = (editing) => this.setState({editing});

  stopEditing = () => this.setState({editing: null});

  onEdit = (editing) => () => this.startEditing(editing);

  onChange = (key) => ({target: {value: val}}) =>
    this.changeReminder({[key]: val});

  onAlertDateChange = (val) => this.changeReminder({alertDate: val});

  onInvitees = (invitees) => this.changeReminder({invitees});

  onStartDate = (date) => {
    const {reminder} = this.props;
    const startDateObject = toDate(date);
    const endDateObject = toDate(reminder.endDate);
    const endDate = getTime(
      setHours(
        setMinutes(startDateObject, getMinutes(endDateObject)),
        getHours(endDateObject)
      )
    );
    if (
      isEqual(startDateObject, toDate(endDate)) ||
      isAfter(startDateObject, toDate(endDate))
    )
      return alertify.warning('Start date can not be after end date');
    if (!reminder.alertDate)
      return this.changeReminder({startDate: date, endDate});
    const alertDelta = reminder.startDate - reminder.alertDate;
    const alertDate = date - alertDelta;
    this.changeReminder({startDate: date, endDate, alertDate});
  };

  onEndDate = (date) => {
    const {reminder} = this.props;
    if (isAfter(toDate(reminder.startDate), toDate(date)))
      return alertify.warning('End date can not be before start date');
    this.changeReminder({endDate: date});
  };

  changeReminder = (data = {}) => {
    const {reminder, selected, onChange} = this.props;
    if (!selected) return;
    onChange({...reminder, ...data});
  };

  onRef = (dom) => {
    $(dom).focus();
    $(dom).select();
  };

  coordinates = () => {
    const {reminder} = this.props;
    const top = timeToNumber(reminder.startDate) * HOUR_HEIGHT;
    const height = timeToNumber(reminder.endDate) * HOUR_HEIGHT - top;
    return {top, height};
  };

  onPriority = (priority) => () => {
    const {reminder, selected, onChange} = this.props;
    if (!selected) return;
    onChange({...reminder, priority});
    this.hidePriorities();
  };

  // TODO: I18n
  priorities = () => [
    {
      value: 1,
      color: 'darkGrey',
      label: 'Low Priority',
    },
    {
      value: 2,
      color: 'blue',
      label: 'Medium Priority',
    },
    {
      value: 3,
      color: 'orange',
      label: 'High Priority',
    },
  ];

  priorityColor = () => {
    const {reminder} = this.props;
    const priority = this.priorities().find(
      ({value}) => reminder.priority === value
    );
    return !!priority ? priority.color : 'grey';
  };

  prioritiesVisible = () => this.setState({prioritiesVisible: true});

  hidePriorities = () => this.setState({prioritiesVisible: false});

  handleClickOutside = () => {
    const {selected, onClose} = this.props;
    if (!selected) return;
    this.hidePriorities();
    onClose();
  };

  // TODO: I18n
  render() {
    const {
      loading,
      left,
      reminder,
      overlapping,
      selected,
      onClick,
      onDelete,
    } = this.props;
    const {editing, prioritiesVisible} = this.state;
    const {top, height} = this.coordinates();
    const overlappingPosition =
      [...overlapping].findIndex((r) => r.id === reminder.id) || 0;
    return (
      <Container
        overlapping={overlapping.length}
        overlappingPosition={overlappingPosition}
        selected={selected}
        top={top}
        height={height}
        loading={loading ? 1 : 0}
      >
        <Content onClick={onClick}>
          <Title>{reminder.title}</Title>
          {!!reminder.notes && reminder.notes}
        </Content>
        <Priority color={this.priorityColor()} />
        {selected && (
          <Info left={left}>
            <InfoBox>
              <PriorityContainer>
                <TitleFlex>
                  {editing === 'title' ? (
                    <Input
                      ref={this.onRef}
                      value={reminder[editing] || ''}
                      onChange={this.onChange('title')}
                      placeholder="Title"
                      highlight
                      large
                    />
                  ) : (
                    <EditableTitle onDoubleClick={this.onEdit('title')}>
                      {reminder.title || 'Title'}
                    </EditableTitle>
                  )}
                </TitleFlex>
                <PriorityFlex>
                  <EntryPriorities
                    visible={prioritiesVisible}
                    color={this.priorityColor()}
                    priorities={this.priorities()}
                    onPriority={this.onPriority}
                    onShow={this.prioritiesVisible}
                    onClose={this.hidePriorities}
                  />
                </PriorityFlex>
              </PriorityContainer>
              {editing === 'location' ? (
                <Input
                  ref={this.onRef}
                  value={reminder[editing] || ''}
                  onChange={this.onChange('location')}
                  placeholder="Add Location"
                />
              ) : (
                <EditableInfo onDoubleClick={this.onEdit('location')}>
                  {reminder.location || 'Add Location'}
                </EditableInfo>
              )}
            </InfoBox>
            <Line />
            <InfoBox>
              <AlertRow>
                <Row>
                  <Column size={2 / 5}>
                    <Date
                      date={reminder.startDate}
                      onChange={this.onStartDate}
                    />
                  </Column>
                  <Column size={3 / 5}>
                    <Time
                      startDate={reminder.startDate}
                      endDate={reminder.endDate}
                      onStartDateChange={this.onStartDate}
                      onEndDateChange={this.onEndDate}
                    />
                  </Column>
                </Row>
              </AlertRow>
              <AlertRow>
                <Alert>
                  <Row>
                    <Column size={2 / 5}>Alert:</Column>
                    <Column size={3 / 5}>
                      <AlertDropDown
                        alertDate={reminder.alertDate}
                        startDate={reminder.startDate}
                        onAlert={this.onAlertDateChange}
                      />
                    </Column>
                  </Row>
                </Alert>
              </AlertRow>
            </InfoBox>
            <Line />
            <InfoBox>
              <Invitees
                invitees={reminder.invitees}
                onChange={this.onInvitees}
              />
            </InfoBox>
            <Line />
            <InfoBox>
              <DeleteContainer>
                <Flex>
                  {editing === 'notes' ? (
                    <Input
                      ref={this.onRef}
                      value={reminder[editing] || ''}
                      onChange={this.onChange('notes')}
                      placeholder="Add notes"
                      highlight
                    />
                  ) : (
                    <EditableInfo
                      onDoubleClick={this.onEdit('notes')}
                      inserted={!!reminder.notes}
                    >
                      {reminder.notes || 'Add notes'}
                    </EditableInfo>
                  )}
                </Flex>
                <DeleteFlex>
                  <Delete
                    className="mdi mdi-delete-outline"
                    onDoubleClick={onDelete}
                  />
                </DeleteFlex>
              </DeleteContainer>
            </InfoBox>
            <Caret left={left} />
          </Info>
        )}
        {loading && <LoadingContainer>...</LoadingContainer>}
      </Container>
    );
  }
}

export default onClickOutside(Entry);
