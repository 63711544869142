import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: ${grey};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoadingContainer;
