import React from 'react';

// Components
import Container from './components/Container';
import ValueContainer from "./components/ValueContainer";
import DescriptionContainer from "./components/DescriptionContainer";
import PropTypes from "prop-types";

const StatisticsValueTile = ({
                               title,
                               children,
                               theme,
                             }) =>
  <Container>
    <DescriptionContainer>
      {title}
    </DescriptionContainer>
    <ValueContainer theme={theme}>
      {children}
    </ValueContainer>
  </Container>
;

StatisticsValueTile.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.string,
};

export default StatisticsValueTile;
