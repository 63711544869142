import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Row,
  Column,
  Modal,
  Form,
  Button,
  // Input,
  CheckBox,
  Info,
} from 'doorson-ui';

const UpdateLanguageModal = ({
  title,
  codeLabel,
  nameLabel,
  disabledLabel,
  saveLabel,
  visible,
  loading,
  code,
  name,
  disabled,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="small">
    <Form onSubmit={onSave}>
      <Row margin>
        <Column>
          <Info label={codeLabel}>{code}</Info>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <Info label={nameLabel}>{name}</Info>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <CheckBox
            selected={disabled}
            onSelect={onChange('disabled')}
            disabled={loading}
          >
            {disabledLabel}
          </CheckBox>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

UpdateLanguageModal.propTypes = {
  title: PropTypes.string,
  codeLabel: PropTypes.string,
  nameLabel: PropTypes.string,
  disabledLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  code: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default UpdateLanguageModal;
