import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import RemindersContainer from '../../containers/RemindersContainer/RemindersContainer';

// Consts
import page from './page';

const RemindersPage = () => (
  <Page page={page}>
    <RemindersContainer />
  </Page>
);

export default RemindersPage;
