import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Api
import listMaintenanceContractsApi from '../../api/list.api.maintenanceContract';

// Components
import MaintenanceContracts from '../../components/MaintenanceContracts/MaintenanceContracts';

// Query
import setQuery from '../../../api/lib/query.lib.api';

// Routes
import maintenanceRoute from '../../pages/MaintenanceContractPage/route';

class MaintenanceContractsContainer extends Component {
  static propTypes = {
    scroller: PropTypes.object,
    history: PropTypes.object,
  };

  static LIMIT = 30;

  state = {
    loading: false,
    maintenanceContracts: [],
    search: '',
    sort: null,
    more: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiID = null;

  init = () => {
    this.getContracts();
  };

  getContracts = async ({
    search = this.state.search,
    sort = this.state.sort,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;

    const {LIMIT} = this.constructor;

    this.setState({
      search,
      sort,
      loading: true,
      offset: 0,
      more: true,
      maintenanceContracts: [],
    });

    try {
      const query = setQuery({
        search,
        order: sort,
        offset: 0,
        limit: LIMIT,
      });
      const maintenanceContracts = await listMaintenanceContractsApi(query);
      if (this.apiID !== apiID) return;

      this.setState({
        maintenanceContracts,
        loading: false,
        more: maintenanceContracts.length === LIMIT,
        offset: LIMIT,
      });
    } catch (error) {
      this.setState({loading: false, maintenanceContracts: []});
    }
  };

  getMoreContracts = async () => {
    const apiID = this.apiID;
    const {
      loading,
      search,
      sort,
      offset,
      more,
      maintenanceContracts: oldMaintenanceContracts,
    } = this.state;

    const {LIMIT} = this.constructor;

    if (!more || loading) return;

    this.setState({loading: true});

    try {
      const query = setQuery({
        search,
        order: sort,
        offset,
        limit: LIMIT,
      });
      const maintenanceContracts = await listMaintenanceContractsApi(query);
      if (this.apiID !== apiID) return;
      this.setState({
        maintenanceContracts: [
          ...oldMaintenanceContracts,
          ...maintenanceContracts,
        ],
        loading: false,
        offset: LIMIT + offset,
        more: maintenanceContracts.length === LIMIT,
      });
    } catch (error) {
      this.setState({loading: false});
    }
  };

  columns = () => [
    {
      key: 'customer',
      label: 'Customer',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'period',
      label: 'Period',
    },
    {
      key: 'valid',
      label: 'Valid',
    },
  ];

  onMaintenanceContract = (contract) => () => {
    const {history} = this.props;
    history.push(maintenanceRoute(contract.id));
  };

  onSort = (sort) => this.getContracts({sort});

  onMore = () => this.getMoreContracts();

  render() {
    // TODO: I18n
    const {scroller} = this.props;
    const {loading, maintenanceContracts, sort, more} = this.state;
    return (
      <MaintenanceContracts
        openLabel="Open"
        closedLabel="Closed"
        loading={loading}
        more={more}
        scroller={scroller}
        sort={sort}
        columns={this.columns()}
        maintenanceContracts={maintenanceContracts}
        onMaintenanceContract={this.onMaintenanceContract}
        onSort={this.onSort}
        onMore={this.onMore}
      />
    );
  }
}

export default connect((state) => ({scroller: state.layout.scroller}))(
  withRouter(MaintenanceContractsContainer)
);
