import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import updateApi from '../../api/update.api.language';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminLanguageButton from '../../components/NewAdminLanguageButton/NewAdminLanguageButton';
import NewLanguageModal from '../../components/NewLanguageModal/NewLanguageModal';

// Languages
import languageList from 'iso-639-1';

class NewLanguageContainer extends Component {
  static propTypes = {
    languages: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    language: '',
    disabled: false,
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, language: '', disabled: false});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, language: '', disabled: false});
  };

  create = async () => {
    // TODO: I18n
    const {loading, visible, language, disabled} = this.state;
    if (loading || !visible) return;

    const actualLanguage = [...this.languages()].find(
      (lng) => lng.value === language
    );

    if (!actualLanguage) return alertify.warning('Select language');

    this.setState({loading: true});

    try {
      const language = await updateApi({
        code: actualLanguage.value,
        name: actualLanguage.label,
        disabled,
      });
      const {dispatch, languages} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(setAct({languages: [...languages, language]}));
    } catch (error) {
      alertify.error('Language could not be created');
      this.setState({loading: false});
    }
  };

  languages = () => {
    const {languages} = this.props;
    const codes = languageList.getAllCodes();
    return [...codes]
      .filter((code) => !languages.find((lng) => lng.code === code))
      .map((code) => ({
        value: code,
        label: languageList.getName(code),
      }));
  };

  render() {
    // TODO: I18n
    const {loading, visible, language, disabled} = this.state;
    return (
      <Fragment>
        <NewAdminLanguageButton onClick={this.show}>
          New Language
        </NewAdminLanguageButton>
        <NewLanguageModal
          title="New Language"
          disabledLabel="Disabled"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          language={language}
          disabled={disabled}
          languages={this.languages()}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.language}))(NewLanguageContainer);
