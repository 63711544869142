import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {black} = colors;

const Input = styled.input`
  width: 200px;
  display: block;
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 15px;
  font-family: inherit;
  color: ${black};
  background: none;
`;

export default Input;
