import React from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox, colors, Column, Info, Row} from 'doorson-ui';

import Container from './components/Container';
import HorizontalScroll from './components/HorizontalScroll';
import TabContainer from './components/TabContainer';
import Tab from './components/Tab';
import Box from './components/Box';
import AddTab from "../DoorEdit/components/AddTab";
import UploadContainer from "../../../maintenanceDoor/components/DoorInfo/components/UploadContainer";
import DoorImageContainer from "../../../door/containers/DoorImageContainer/DoorImageContainer";
import {getTime} from "date-fns";
import TextBlock from "../../../layout/components/TextBlock/TextBlock";
import {date as dateType} from "../../../types";
const {green, red} = colors;

const DoorInfo = ({
                    doors,
                    door,

                    givenDescriptionLabel,
                    manufacturerLabel,
                    doorVersionLabel,
                    doorTypeLabel,
                    redundancyLabel,
                    indoorLocationLabel,
                    buildingFloorLabel,
                    canDelete,
                    canAddPictures,

                    onDoor,
                    onDoorChange,
                    onDelete,
                    onNewDoor,
                  }) => (
  <Container>
    <HorizontalScroll>
      <TabContainer>
        {doors.map((d) => (
          <Tab
            key={d.id}
            selected={d.id === door.id}
            label={
              !!d.door && (!!d.door.indoorLocation || d.door.buildingFloor)
                ? `${d.door.indoorLocation} ${d.door.buildingFloor}`
                : `${d.indoorLocation}`
            }
            onClick={() => onDoor(d)}
            canDelete={canDelete}
            onDelete={(e) => {
              e.stopPropagation();
              onDelete(d);
            }}
          />
        ))}
        <AddTab onClick={onNewDoor}>
          <i className="mdi mdi-plus"/>
        </AddTab>
      </TabContainer>
    </HorizontalScroll>
    <Box>
      <Row>
        <Column size={1 / 2}>
          <Row margin>
            <Column size={2 / 3}>
              <Info label={givenDescriptionLabel}>{door.givenDescription}</Info>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Info label={manufacturerLabel}>
                {!!door.door ? door.door.product.manufacturer.name : ''}
              </Info>
            </Column>
            <Column size={1 / 2}>
              <Info noBorder={true}>
                {
                  (!!door.door && !!door.door.warrantyValidTillDate && (door.door.warrantyValidTillDate > getTime(new Date())
                    ? <TextBlock color={green}>Warranty is active
                      till {dateType(door.door.warrantyValidTillDate).format()}.</TextBlock> :
                    <TextBlock color={red}>Warranty expired!</TextBlock>))
                  || 'No warranty info.'}
              </Info>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Info label={doorVersionLabel}>
                {!!door.door ? door.door.product.name : ''}
              </Info>
            </Column>
            <Column size={1 / 3}>
              <Info label={doorTypeLabel}>
                {!!door.door && !!door.door.productType
                  ? door.door.productType.name || ''
                  : ''}
              </Info>
            </Column>
            <Column size={1 / 3}>
              <CheckBox
                selected={!!door.door ? door.door.redundancy : false}
                onSelect={() => null}
                disabled
                large
              >
                {redundancyLabel}
              </CheckBox>
            </Column>
          </Row>
          <Row margin>
            <Column size={2 / 3}>
              <Info label={indoorLocationLabel}>
                {door.indoorLocation || ''}
              </Info>
            </Column>
            <Column size={1 / 3}>
              <Info label={buildingFloorLabel}>
                {!!door.door ? door.door.buildingFloor : ''}
              </Info>
            </Column>
          </Row>
        </Column>
        <Column size={1 / 2}>
          <UploadContainer>
            <DoorImageContainer canAddPictures={canAddPictures} door={door.door} onChange={onDoorChange}/>
          </UploadContainer>
        </Column>
      </Row>
    </Box>
  </Container>
);

DoorInfo.propTypes = {
  doors: PropTypes.array,
  door: PropTypes.object,

  givenDescriptionLabel: PropTypes.string,
  manufacturerLabel: PropTypes.string,
  doorVersionLabel: PropTypes.string,
  doorTypeLabel: PropTypes.string,
  redundancyLabel: PropTypes.string,
  indoorLocationLabel: PropTypes.string,
  buildingFloorLabel: PropTypes.string,
  canDelete: PropTypes.bool,
  canAddPictures: PropTypes.bool,

  onDoor: PropTypes.func,
  onDelete: PropTypes.func,
  onNewDoor: PropTypes.func,
};

export default DoorInfo;
