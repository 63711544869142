import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const Input = styled.input`
  width: 190px;
  height: 24px;
  margin: 0px;
  padding: 0px 4px;
  display: block;
  outline: none;
  border: none;
  font-family: inherit;
  background: ${grey};
  border-radius: 5px;
`;

export default Input;
