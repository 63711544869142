import styled from 'styled-components';
import {transitions} from 'polished';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Container = styled.div`
  position: absolute;
  bottom: 25px;
  left: 10px;
  z-index: 9px;
  background: ${white};
  padding: 10px 5px;
  display: flex;
  opacity: ${({visible}) => (visible ? 1 : 0)};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  pointer-events: ${({visible}) => (visible ? 'auto' : 'nonte')};
  ${transitions('opacity .2s ease-in-out visibility .2s ease-in-out')};
`;

export default Container;
