import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Container
import MapsContainer from '../../containers/MapsContainer/MapsContainer';

// Consts
import page from './page';

const MapsPage = () => (
  <Page page={page}>
    <MapsContainer />
  </Page>
);

export default MapsPage;
