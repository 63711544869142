import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import {alertify} from 'doorson-ui';

// Api
import updateApi from '../../api/update.api.device';
import deleteApi from '../../api/delete.api.device';

// Components
import AdminDevices from '../../components/AdminDevices/AdminDevices';
import UpdateDeviceModal from '../../components/UpdateDeviceModal/UpdateDeviceModal';
import DeleteDeviceModal from '../../components/DeleteDeviceModal/DeleteDeviceModal';

// Lib
import sortByString from '../../../lib/sortByString';

class DevicesContainer extends Component {
  static propTypes = {
    devices: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    updateID: null,
    name: '',
    version: 0,
    removeID: null,
    sort: {key: 'name', direction: 'asc'},
  };

  // TODO: I18n
  columns = () => [
    {
      key: 'device',
      label: 'ID',
      span: 2,
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      span: 2,
      sortable: true,
    },
    {
      key: 'lastUser',
      label: 'Last User',
      span: 1,
    },
    {
      key: 'lastPosition',
      label: 'Last Position',
      span: 2,
    },
    {
      key: 'battery',
      label: 'Battery',
      span: 1,
    },
    {
      key: 'actions',
      label: 'Actions',
      span: 1,
    },
  ];

  showUpdate = (device) => () => {
    if (this.state.loading) return;
    this.setState({
      updateID: device.id,
      name: device.name,
      details: device.details,
      version: device.version,
    });
  };

  hideUpdate = () => {
    if (this.state.loading) return;
    this.setState({updateID: null});
  };

  showDelete = (id) => () => {
    if (this.state.loading) return;
    this.setState({removeID: id});
  };

  hideDelete = () => {
    if (this.state.loading) return;
    this.setState({removeID: null});
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSort = (sort) => this.setState({sort});

  update = async () => {
    const {loading, updateID, name, details, version} = this.state;
    if (loading || !updateID) return;

    if (!name.trim().length) return alertify.warning('Insert the name');

    this.setState({loading: true});

    try {
      const device = await updateApi(updateID, {name, details, version});
      const {dispatch, devices} = this.props;
      this.setState({loading: false, updateID: null});
      dispatch(
        setAct({
          devices: [...devices].map((r) => (r.id === device.id ? device : r)),
        })
      );
    } catch (error) {
      alertify.error('Device could not be updated');
      this.setState({loading: false});
    }
  };

  remove = async () => {
    const {loading, removeID} = this.state;
    if (loading || !removeID) return;

    this.setState({loading: true});

    try {
      await deleteApi(removeID);
      const {dispatch, devices} = this.props;
      this.setState({loading: false, removeID: null});
      dispatch(
        setAct({devices: [...devices].filter((r) => r.id !== removeID)})
      );
    } catch (error) {
      alertify.error('Device could not be removed');
      this.setState({loading: false});
    }
  };

  data = () => {
    const {devices} = this.props;
    const {sort} = this.state;
    return !!sort ? [...devices].sort(sortByString(sort)) : devices;
  };

  render() {
    const {loading, sort, updateID, name, details, removeID} = this.state;
    return (
      <Fragment>
        <AdminDevices
          columns={this.columns()}
          sort={sort}
          devices={this.data()}
          onUpdate={this.showUpdate}
          onDelete={this.showDelete}
          onSort={this.onSort}
        />
        <UpdateDeviceModal
          title="Update Device"
          nameLabel="Name"
          detailsLabel="Details"
          saveLabel="Save"
          visible={!!updateID}
          loading={loading}
          name={name}
          details={details}
          onChange={this.onChange}
          onClose={this.hideUpdate}
          onSave={this.update}
        />
        <DeleteDeviceModal
          title="Delete Device"
          deleteLabel="Delete"
          visible={!!removeID}
          loading={loading}
          onClose={this.hideDelete}
          onDelete={this.remove}
        >
          Are you sure you want to delete this device?
        </DeleteDeviceModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.device}))(DevicesContainer);
