import alertOptions from './alertOptions.lib.reminder';

const getAlert =  ({alertDate, startDate}) => {
  const options = alertOptions(startDate);
  const option = [...options].find((opt) => opt.value === alertDate);
  return (
    option || {
      value: null,
      label: 'Not Set',
    }
  );
};
export default getAlert;
