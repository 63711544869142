import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const Icon = styled.i`
  color: ${darkGrey};
  cursor: pointer;
`;

export default Icon;
