import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import MaintenanceSignMultipleItemsContainer from '../../containers/MaintenanceSignMultipleItemsContainer/MaintenanceSignMultipleItemsContainer';

// TODO: I18n
const MaintenanceItemsPage = ({maintenance, match}) => (
  <SubPage page={match.path}>
    <MaintenanceSignMultipleItemsContainer maintenance={maintenance} />
  </SubPage>
);

MaintenanceItemsPage.propTypes = {
  maintenance: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(
  connect((state) => ({
    maintenance: state.maintenance.maintenance,
  }))(MaintenanceItemsPage)
);
