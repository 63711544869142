import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/file.profile.api';

const uploadDocumentationFileApi = (documentationID, fileName, type, data) =>
  f3tch(`/documentation-folders/${documentationID}/files/${fileName}`)
    .profile(profile({output: 'JSON'}))
    .query({mimeType: type && encodeURI(type)})
    .body(data)
    .post();
export default uploadDocumentationFileApi;
