import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createInterventionDoorApi = (interventionId, interventionDoor) =>
  f3tch(`/intervention-doors`)
    .profile(profile())
    .query({interventionId})
    .body(interventionDoor)
    .post();
export default createInterventionDoorApi;
