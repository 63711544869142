import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Button} from 'doorson-ui';
import SimpleUploader from '../../../file/components/SimpleUploader/SimpleUploader';
import Current from './components/Current';
import Img from './components/Img';
import ButtonContainer from './components/ButtonContainer';

// Libs
import preview from '../../../file/lib/preview.lib.file';

const EditDocumentationImageModal = ({
  visible,
  loading,

  title,
  imageLabel,
  saveLabel,
  removeLabel,

  folder,
  image,

  onClose,
  onChange,
  onSave,
}) => (
  <Modal title={title} visible={visible} onClose={onClose}>
    {!!folder && !!folder.image && (
      <Row margin>
        <Column>
          <Current>
            <Img src={preview(folder.image.preview)} />
          </Current>
        </Column>
      </Row>
    )}
    <Row margin>
      <Column>
        <SimpleUploader
          reset={visible}
          loading={loading}
          label={imageLabel}
          file={image}
          removeLabel="Remove"
          onChange={onChange('image')}
        />
      </Column>
    </Row>
    <Row>
      <Column>
        <ButtonContainer>
          <Button
            theme={!!image ? 'orange' : 'black'}
            loading={loading}
            onClick={onSave}
          >
            {!!image ? saveLabel : removeLabel}
          </Button>
        </ButtonContainer>
      </Column>
    </Row>
  </Modal>
);

EditDocumentationImageModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,

  title: PropTypes.string,
  imageLabel: PropTypes.string,
  saveLabel: PropTypes.string,

  folder: PropTypes.object,
  image: PropTypes.object,

  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default EditDocumentationImageModal;
