import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch, withRouter} from 'react-router-dom';

// Containers
import MaintenanceDoorContainer from '../../containers/MaintenanceDoorContainer/MaintenanceDoorContainer';

// MaintenanceDoorInfo
import MaintenanceDoorInfoPage from '../MaintenanceDoorInfoPage/MaintenanceDoorInfoPage';
import routeMaintenanceDoorInfo from '../MaintenanceDoorInfoPage/route';

// MaintenanceDoorWorklog
import MaintenanceDoorWorklogPage from '../MaintenanceDoorWorklogPage/MaintenanceDoorWorklogPage';
import routeMaintenanceDoorWorklog from '../MaintenanceDoorWorklogPage/route';

// MaintenanceDoorConfirmation
import MaintenanceDoorConfirmationPage from '../MaintenanceDoorConfirmationPage/MaintenanceDoorConfirmationPage';
import routeMaintenanceDoorConfirmation from '../MaintenanceDoorConfirmationPage/route';

// MaintenanceDoorAttachments
import MaintenanceDoorAttachmentsPage from '../MaintenanceDoorAttachmentsPage/MaintenanceDoorAttachmentsPage';
import routeMaintenanceDoorAttachments from '../MaintenanceDoorAttachmentsPage/route';

import MaintenanceDoorActionsPage from '../MaintenanceDoorActionsPage/MaintenanceDoorActionsPage';
import routeMaintenanceDoorActions from '../MaintenanceDoorActionsPage/route';

// SpecifyMaintenanceDoorSubpage
import SpecifyMaintenanceDoorSubpageContainer
  from '../../containers/SpecifyMaintenanceDoorSubpageContainer/SpecifyMaintenanceDoorSubpageContainer';

const MaintenanceDoorPage = ({match}) => (
  <MaintenanceDoorContainer
    maintenanceID={match.params.id}
    maintenanceDoorID={match.params.doorID}
  >
    <Switch>
      <Route
        path={routeMaintenanceDoorInfo()}
        component={MaintenanceDoorInfoPage}
        exact
      />
      <Route
        path={routeMaintenanceDoorWorklog()}
        component={MaintenanceDoorWorklogPage}
        exact
      />
      <Route
        path={routeMaintenanceDoorConfirmation()}
        component={MaintenanceDoorConfirmationPage}
        exact
      />
      <Route
        path={routeMaintenanceDoorAttachments()}
        component={MaintenanceDoorAttachmentsPage}
        exact
      />
      <Route
        path={routeMaintenanceDoorActions()}
        component={MaintenanceDoorActionsPage}
        exact
      />
      <Route component={SpecifyMaintenanceDoorSubpageContainer}/>
    </Switch>
  </MaintenanceDoorContainer>
);

MaintenanceDoorPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(MaintenanceDoorPage);
