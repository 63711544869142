import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {Input} from 'doorson-ui';
import {Info} from 'doorson-ui';
import {Label} from 'doorson-ui';
import {SimpleTable} from 'doorson-ui';

import DataBlock from '../../../layout/components/DataBlock/DataBlock';

import ButtonContainer from './components/ButtonContainer';

const CustomerInfo = ({
  customerInfoLabel,
  registrationNumberLabel,
  companyNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  branchLabel,
  phoneLabel,
  emailLabel,
  customerLabel,
  firstNameLabel,
  lastNameLabel,
  signatureLabel,
  signLabel,
  signedLabel,
  receiversLabel,

  loading,
  resolved,
  assigned,
  error,
  customer,
  branchName,
  branchLocation,
  email,
  phone,
  signatoryFirstName,
  signatoryLastName,
  signed,
  contractReceivers,

  onChange,
  onSign,
}) => (
  <DataBlock label={customerInfoLabel}>
    <Row>
      <Column size={15 / 24}>
        <SimpleTable
          values={[
            customer.type === 'business'
              ? {
                  key: registrationNumberLabel,
                  value: customer.registrationNumber,
                }
              : {
                  key: firstNameLabel,
                  value: customer.firstName,
                },
            customer.type === 'business'
              ? {
                  key: companyNameLabel,
                  value: customer.company,
                }
              : {
                  key: lastNameLabel,
                  value: customer.lastName,
                },
            {
              key: addressLabel,
              value: customer.location.address,
            },
            {
              key: zipLabel,
              value: customer.location.zip,
            },
            {
              key: cityLabel,
              value: customer.location.city,
            },
            customer.type === 'business' && {
              key: branchLabel,
              value: `${branchName}, ${branchLocation.address}, ${branchLocation.zip} ${branchLocation.city}`,
            },
            {
              key: emailLabel,
              value: email || '',
            },
            {
              key: phoneLabel,
              value: phone,
            },
            resolved && {
              key: receiversLabel,
              value: !!contractReceivers.length
                ? [...contractReceivers].map((r, i) =>
                  <Fragment>{r}{i !== contractReceivers.length - 1 && ", "}</Fragment>)
                : '',
            },
          ].filter((v) => !!v)}
        />
      </Column>
      <Column size={1 / 3}>
        <Row margin>
          <Column>
            <Label label={customerLabel}>
              <Row>
                <Column size={1 / 2}>
                  {resolved || !assigned ? (
                    <Info label={firstNameLabel}>{signatoryFirstName}</Info>
                  ) : (
                    <Input
                      value={signatoryFirstName}
                      onChange={onChange('signatoryFirstName')}
                      error={error.signatoryFirstName}
                      disabled={loading}
                      required
                    >
                      {firstNameLabel}
                    </Input>
                  )}
                </Column>
                <Column size={1 / 2}>
                  {resolved || !assigned ? (
                    <Info label={lastNameLabel}>{signatoryLastName}</Info>
                  ) : (
                    <Input
                      value={signatoryLastName}
                      onChange={onChange('signatoryLastName')}
                      error={error.signatoryLastName}
                      disabled={loading}
                      required
                    >
                      {lastNameLabel}
                    </Input>
                  )}
                </Column>
              </Row>
            </Label>
          </Column>
        </Row>
        {!resolved && assigned && (
          <Row>
            <Column>
              <Label label={signatureLabel}>
                <ButtonContainer>
                  <Button
                    theme={signed ? 'darkGrey' : 'orange'}
                    size="big"
                    onClick={onSign}
                    disabled={loading}
                    outline
                  >
                    {signed ? signedLabel : signLabel}
                  </Button>
                </ButtonContainer>
              </Label>
            </Column>
          </Row>
        )}
      </Column>
    </Row>
  </DataBlock>
);

CustomerInfo.propTypes = {
  customerInfoLabel: PropTypes.string,
  registrationNumberLabel: PropTypes.string,
  companyNameLabel: PropTypes.string,
  addressLabel: PropTypes.string,
  zipLabel: PropTypes.string,
  cityLabel: PropTypes.string,
  branchLabel: PropTypes.string,
  phoneLabel: PropTypes.string,
  emailLabel: PropTypes.string,
  customerLabel: PropTypes.string,
  firstNameLabel: PropTypes.string,
  lastNameLabel: PropTypes.string,
  signatureLabel: PropTypes.string,
  signLabel: PropTypes.string,
  signedLabel: PropTypes.string,
  receiversLabel: PropTypes.string,

  loading: PropTypes.bool,
  resolved: PropTypes.bool,
  assigned: PropTypes.bool,
  error: PropTypes.object,
  customer: PropTypes.object,
  branchName: PropTypes.string,
  branchLocation: PropTypes.object,
  email: PropTypes.string,
  phone: PropTypes.string,
  signatoryFirstName: PropTypes.string,
  signatoryLastName: PropTypes.string,
  signed: PropTypes.bool,
  contractReceivers: PropTypes.array,

  onChange: PropTypes.func,
  onSign: PropTypes.func,
};

export default CustomerInfo;
