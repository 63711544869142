import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../../manufacturer/redux/actions';

// Api
import createApi from '../../api/create.api.product';

// Alerts
import {alertify} from 'doorson-ui';

// Component
import NewAdminProductButton from '../../components/NewAdminProductButton/NewAdminProductButton';
import NewProductModal from '../../components/NewProductModal/NewProductModal';

class NewProductContainer extends Component {
  static propTypes = {
    manufacturers: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    name: '',
    code: '',
  };

  onChange = (key) => (val) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  show = () => {
    if (this.state.loading) return;
    this.setState({visible: true, name: '', code: ''});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false, name: '', code: ''});
  };

  create = async () => {
    // TODO: I18n
    const {
      match: {
        params: {manufacturerID},
      },
    } = this.props;
    const {loading, visible, name, code} = this.state;
    if (loading || !visible) return;

    if (!name.trim().length) return alertify.warning('Insert the name');
    if (!code.trim().length) return alertify.warning('Insert the code');

    this.setState({loading: true});

    try {
      const product = await createApi({
        name,
        code,
        manufacturerId: manufacturerID,
      });
      const {dispatch, manufacturers} = this.props;
      this.setState({loading: false, visible: false});
      dispatch(
        setAct({
          manufacturers: [...manufacturers].map((manufacturer) =>
            manufacturer.id === product.manufacturer.id
              ? {...manufacturer, products: [...manufacturer.products, product]}
              : manufacturer
          ),
        })
      );
    } catch (error) {
      alertify.error('Product could not be created');
      this.setState({loading: false});
    }
  };

  render() {
    // TODO: I18n
    const {loading, visible, name, code} = this.state;
    return (
      <Fragment>
        <NewAdminProductButton onClick={this.show}>
          New Product
        </NewAdminProductButton>
        <NewProductModal
          title="New Product"
          nameLabel="Name"
          codeLabel="Code"
          saveLabel="Save"
          visible={visible}
          loading={loading}
          name={name}
          code={code}
          onChange={this.onChange}
          onClose={this.hide}
          onSave={this.create}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  ...state.manufacturer,
}))(NewProductContainer);
