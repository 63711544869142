const validateSerial = (e) => {
  const availableKeys = [
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Tab',
    '/',
  ];
  if (!(!isNaN(e.key) || availableKeys.includes(e.key))) {
    e.preventDefault();
    return false;
  }
};
export default validateSerial;
