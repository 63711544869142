import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const Hour = styled.div`
  width: 100%;
  height: ${({height}) => `${height}px`};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${darkGrey};
  font-size: 12px;
  padding-right: 25px;
`;

export default Hour;
