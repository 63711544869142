import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Components
import Loader from '../../components/Loader/Loader';

// Libs
import wait from '../../../lib/wait';

// Country
import {set as setCountriesAct} from '../../../country/redux/actions';

// Fault
import {set as setFaultsAct} from '../../../fault/redux/actions';
import listFaultsApi from '../../../fault/api/list.api.fault';

// Reported Fault
import {set as setReportedFaultsAct} from '../../../reportedFault/redux/actions';
import listReportedFaultsApi from '../../../reportedFault/api/list.api.reportedFault';

// Language
import {set as setLanguagesAct} from '../../../language/redux/actions';
import listLanguagesApi from '../../../language/api/list.api.language';

// MaintenanceCheck
import {set as setMaintenanceChecksAct} from '../../../maintenanceCheck/redux/actions';
import listMaintenanceChecksApi from '../../../maintenanceCheck/api/list.api.maintenanceCheck';

// Manufacturer
import {set as setManufacturersAct} from '../../../manufacturer/redux/actions';
import listManufacturersApi from '../../../manufacturer/api/list.api.manufacturer';

// Material
import {set as setMaterialsAct} from '../../../material/redux/actions';
import listMaterialsApi from '../../../material/api/list.api.material';

// Repair
import {set as setRepairsAct} from '../../../repair/redux/actions';
import listRepairsApi from '../../../repair/api/list.api.repair';

// TransportLocation
import {set as setTransportLocationsAct} from '../../../transportLocation/redux/actions';
import listTransportLocationsApi from '../../../transportLocation/api/list.api.transportLocation';

// Device
import {set as setDevicesAct} from '../../../device/redux/actions';
import listDevicesApi from '../../../device/api/list.api.device';

// User
import {set as setUsersAct} from '../../../user/redux/actions';
import listUsersApi from '../../../user/api/list.api.user';

class SyncContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    isSignedIn: PropTypes.bool,
    countriesLoaded: PropTypes.bool,
    maintenanceChecksLoaded: PropTypes.bool,
    manufacturersLoaded: PropTypes.bool,
    materialsLoaded: PropTypes.bool,
    repairsLoaded: PropTypes.bool,
    languagesLoaded: PropTypes.bool,
    devicesLoaded: PropTypes.bool,
    usersLoaded: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    this.setCountries();
    this.sync();
  }

  sync = async () => {
    if (!this.props.isSignedIn) return;
    this.getLanguages();
    this.getMaintenanceChecks();
    this.getManufacturers();
    this.getMaterials();
    this.getRepairs();
    this.getTransportLocations();
    this.getFaults();
    this.getReportedFaults();
    this.getUsers();
    this.getDevices();
    await wait(120000);
    this.sync();
  };

  setCountries = async () => {
    const {dispatch} = this.props;
    try {
      const countries = [{
        code: 'SI',
        name: 'Slovenia'
      }, {
        code: 'IT',
        name: 'Italy'
      }, {
        code: 'HR',
        name: 'Croatia'
      }, {
        code: 'AT',
        name: 'Austria'
      }
      ]
      dispatch(setCountriesAct({countries, loaded: true}));
    } catch (error) {
      dispatch(setCountriesAct({loaded: true}));
    }
  };

  getLanguages = async () => {
    const {dispatch} = this.props;
    try {
      const languages = await listLanguagesApi({offset: 0, limit: 300});
      dispatch(setLanguagesAct({languages, loaded: true}));
    } catch (error) {
      dispatch(setLanguagesAct({loaded: true}));
    }
  };

  getMaintenanceChecks = async () => {
    const {dispatch} = this.props;
    try {
      const maintenanceChecks = await listMaintenanceChecksApi({offset: 0, limit: 300});
      dispatch(setMaintenanceChecksAct({maintenanceChecks, loaded: true}));
    } catch (error) {
      dispatch(setMaintenanceChecksAct({loaded: true}));
    }
  };

  getManufacturers = async () => {
    const {dispatch} = this.props;
    try {
      const manufacturers = await listManufacturersApi({offset: 0, limit: 100});
      dispatch(setManufacturersAct({manufacturers, loaded: true}));
    } catch (error) {
      dispatch(setManufacturersAct({loaded: true}));
    }
  };

  getMaterials = async () => {
    const {dispatch} = this.props;
    try {
      const materials = await listMaterialsApi({offset: 0, limit: 1000});
      dispatch(setMaterialsAct({materials, loaded: true}));
    } catch (error) {
      dispatch(setMaterialsAct({loaded: true}));
    }
  };

  getRepairs = async () => {
    const {dispatch} = this.props;
    try {
      const query = {order: 'description ASC', offset: 0, limit: 1000};
      const repairs = await listRepairsApi(query);
      dispatch(setRepairsAct({repairs, loaded: true}));
    } catch (error) {
      dispatch(setRepairsAct({loaded: true}));
    }
  };

  getTransportLocations = async () => {
    const {dispatch} = this.props;
    try {
      const transportLocations = await listTransportLocationsApi({offset: 0, limit: 100});
      dispatch(setTransportLocationsAct({transportLocations, loaded: true}));
    } catch (error) {
      dispatch(setTransportLocationsAct({loaded: true}));
    }
  };

  getFaults = async () => {
    const {dispatch} = this.props;
    try {
      const query = {order: 'name ASC', offset: 0, limit: 1000};
      const faults = await listFaultsApi(query);
      dispatch(setFaultsAct({faults, loaded: true}));
    } catch (error) {
      dispatch(setFaultsAct({loaded: true}));
    }
  };

  getReportedFaults = async () => {
    const {dispatch} = this.props;
    try {
      const query = {order: 'name ASC', offset: 0, limit: 1000};
      const reportedFaults = await listReportedFaultsApi(query);
      dispatch(setReportedFaultsAct({reportedFaults, loaded: true}));
    } catch (error) {
      dispatch(setReportedFaultsAct({loaded: true}));
    }
  };

  getDevices = async () => {
    const {dispatch} = this.props;
    try {
      const devices = await listDevicesApi({offset: 0, limit: 300});
      dispatch(setDevicesAct({devices, loaded: true}));
    } catch (error) {
      dispatch(setDevicesAct({loaded: true}));
    }
  };

  getUsers = async ({users = [], limit = 200, offset = 0} = {}) => {
    const {dispatch} = this.props;
    try {
      const newUsers = await listUsersApi({
        limit,
        offset,
        filter: 'roles.role:EQ:serviceTechnician',
      });
      const combinedUsers = [...users, ...newUsers];
      if (!newUsers.length)
        return dispatch(setUsersAct({users: combinedUsers, loaded: true}));
      this.getUsers({users: combinedUsers, limit, offset: offset + limit});
    } catch (error) {
      dispatch(setUsersAct({loaded: true}));
    }
  };

  render() {
    const {
      children,
      countriesLoaded,
      languagesLoaded,
      maintenanceChecksLoaded,
      manufacturersLoaded,
      materialsLoaded,
      repairsLoaded,
      transportLocationsLoaded,
      faultsLoaded,
      reportedFaultsLoaded,
      usersLoaded,
      devicesLoaded,
    } = this.props;
    return !countriesLoaded ||
    !languagesLoaded ||
    !maintenanceChecksLoaded ||
    !manufacturersLoaded ||
    !materialsLoaded ||
    !repairsLoaded ||
    !transportLocationsLoaded ||
    !faultsLoaded ||
    !reportedFaultsLoaded ||
    !devicesLoaded ||
    !usersLoaded ? (
      <Loader/>
    ) : (
      children
    );
  }
}

export default connect((state) => ({
  countriesLoaded: state.country.loaded,
  maintenanceChecksLoaded: state.maintenanceCheck.loaded,
  manufacturersLoaded: state.manufacturer.loaded,
  materialsLoaded: state.material.loaded,
  repairsLoaded: state.repair.loaded,
  transportLocationsLoaded: state.transportLocation.loaded,
  languagesLoaded: state.language.loaded,
  faultsLoaded: state.fault.loaded,
  reportedFaultsLoaded: state.reportedFault.loaded,
  devicesLoaded: state.device.loaded,
  usersLoaded: state.user.loaded,
  isSignedIn: !!state.auth.token,
}))(SyncContainer);
