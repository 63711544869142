import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createInterventionDoorRepairApi = (interventionDoorID, repair) =>
  f3tch(`/intervention-doors/${interventionDoorID}/repairs`)
    .profile(profile())
    .body(repair)
    .post();
export default createInterventionDoorRepairApi;
