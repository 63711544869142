import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAction} from '../../redux/actions';
import {signOut as signOutAct} from '../../../auth/redux/actions';
import {startVehicleUpdate as startVehicleUpdateAct} from '../../../vehicle/redux/actions';
import {show as showDevicePageAct} from '../../../device/redux/actions';

// Components
import Layout from '../../components/Layout/Layout';
import Navigation from '../../components/Navigation/Navigation';
import Header from '../../components/Header/Header';

// Containers
import SelectVehicleContainer from '../../../vehicle/containers/SelectVehicleContainer/SelectVehicleContainer';
import SyncContainer from '../../../sync/containers/SyncContainer/SyncContainer';
import VersionContainer from '../../../app/containers/VersionContainer/VersionContainer';
import ActivitySyncContainer from '../../../activity/containers/ActivitySyncContainer/ActivitySyncContainer';
import NetworkStatusContainer from '../../../network/containers/NetworkStatusContainer/NetworkStatusContainer';

// Lib
import isProduction from '../../../app/lib/isProduction.lib.app';

// Constants
import MENU_KEY from '../../constants/menuKey.const.layout';

// Interventions
import interventionsRoute from '../../../intervention/pages/InterventionsPage/route';
import INTERVENTIONS_PAGE from '../../../intervention/pages/InterventionsPage/page';

// Intervention
import INTERVENTION_PAGE from '../../../intervention/pages/InterventionPage/page';

// Intervention
import NEW_INTERVENTION_PAGE from '../../../intervention/pages/NewInterventionPage/page';

// Maintenances
import maintenancesRoute from '../../../maintenance/pages/MaintenancesPage/route';
import MAINTENANCES_PAGE from '../../../maintenance/pages/MaintenancesPage/page';

import serviceItemsRoute from '../../../door/pages/ServiceItemsPage/route';
import SERVICE_ITEMS_PAGE from '../../../door/pages/ServiceItemsPage/page';

import inventoryRoute from '../../../inventory/pages/InventoryPage/route';
import INVENTORY_PAGE from '../../../inventory/pages/InventoryPage/page';

// Maintenance
import MAINTENANCE_PAGE from '../../../maintenance/pages/MaintenancePage/page';

// Maintenance
import NEW_MAINTENANCE_PAGE from '../../../maintenance/pages/NewMaintenancePage/page';

// Documentation
import documentationRoute from '../../../documentation/pages/DocumentationPage/route';

// Activities
import activitiesRoute from '../../../activity/pages/ActivitiesPage/route';

// Profile
import profileRoute from '../../../profile/pages/ProfilePage/route';

// Reminders
import remindersRoute from '../../../reminder/pages/RemindersPage/route';

// Maps
import mapsRoute from '../../../maps/pages/MapsPage/route';

// Stats
import statsRoute from '../../../stats/pages/StatsPage/route';

// Admin
import adminRoute from '../../../admin/pages/AdminPage/route';

// Libs
import * as localStorage from '../../../lib/localStorage';
import displayRole from '../../../user/roles/display.role.user';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SYSTEM} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

class LayoutContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
    navigationOpen: PropTypes.bool,
    page: PropTypes.string,
    selectedVehicle: PropTypes.object,
    apiVersion: PropTypes.string,
    appVersion: PropTypes.string,
    unreadActivities: PropTypes.number,
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  toggleNavigation = () => {
    const {navigationOpen, dispatch} = this.props;
    localStorage.add(MENU_KEY, !navigationOpen);
    dispatch(setAction({navigationOpen: !navigationOpen}));
  };

  signout = () => {
    this.props.dispatch(signOutAct());
  };

  onVehicle = () => this.props.dispatch(startVehicleUpdateAct());

  refresh = () => window.location.reload();

  onDevice = () => this.props.dispatch(showDevicePageAct());

  render() {
    const {
      user,
      children,
      navigationOpen,
      page,
      selectedVehicle,
      apiVersion,
      appVersion,
      unreadActivities,
    } = this.props;
    // TODO: I18N
    return (
      <Layout navigationOpen={navigationOpen}>
        <Navigation
          production={isProduction()}
          navigationOpen={navigationOpen}
          hideMenu={!selectedVehicle}
          refresh={this.refresh}
          page={page}
          pages={[
            {
              name: 'Interventions',
              highlight: [
                INTERVENTIONS_PAGE,
                INTERVENTION_PAGE,
                NEW_INTERVENTION_PAGE,
              ],
              url: interventionsRoute(),
            },
            {
              name: 'Maintenance',
              highlight: [
                MAINTENANCES_PAGE,
                MAINTENANCE_PAGE,
                NEW_MAINTENANCE_PAGE,
              ],
              url: maintenancesRoute(),
            },
            {
              name: 'Door catalog',
              highlight: [
                SERVICE_ITEMS_PAGE,
              ],
              url: serviceItemsRoute(),
            },
            {
              name: 'Inventory',
              highlight: [
                INVENTORY_PAGE,
              ],
              url: inventoryRoute(),
            },
          ]}
          documentationLabel="Documentation"
          documentationUrl={documentationRoute()}
          vehicleLabel="I'm driving"
          apiVersion={apiVersion}
          appVersion={appVersion}
          licensePlate={!!selectedVehicle ? selectedVehicle.licensePlate : ''}
          onSlide={this.toggleNavigation}
          onVehicle={this.onVehicle}
        />
        <Header
          name={`${user.firstName} ${user.lastName}`}
          role={displayRole(user)}
          image={user.image}
          notifications={unreadActivities}
          activitiesUrl={activitiesRoute()}
          dropdown={
            !selectedVehicle
              ? [
                {
                  label: 'Log out',
                  onClick: this.signout,
                },
              ]
              : [
                hasPermission({
                  user,
                  roles: [CHIEF_SERVICE_TECHNICIAN],
                }) && {
                  label: 'Reminders',
                  url: remindersRoute(),
                },
                {
                  label: 'Maps',
                  url: mapsRoute(),
                },
                {
                  label: 'Stats',
                  url: statsRoute(),
                },
                hasPermission({
                  user,
                  roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
                }) && {
                  label: 'Administration',
                  url: adminRoute(),
                },
                {
                  label: 'Profile',
                  url: profileRoute(),
                },
                hasPermission({
                  user,
                  roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
                }) && {
                  label: 'Change Device',
                  onClick: this.onDevice,
                },
                {
                  label: 'Log out',
                  onClick: this.signout,
                },
              ].filter((a) => !!a)
          }
        />
        <SelectVehicleContainer>
          <SyncContainer>
            {children}
            <ActivitySyncContainer/>
          </SyncContainer>
        </SelectVehicleContainer>
        <VersionContainer/>
        <NetworkStatusContainer/>
      </Layout>
    );
  }
}

export default connect((state) => ({
  ...state.layout,
  selectedVehicle: state.vehicle.selectedVehicle,
  user: state.auth.user,
  apiVersion: state.app.apiVersion,
  appVersion: state.app.appVersion,
  unreadActivities: state.activity.unreadActivities,
}))(LayoutContainer);
