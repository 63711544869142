import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const listMaterialDoorApi = (doorId, query = {}) =>
  f3tch(`/doors/${doorId}/materials`)
    .profile(profile())
    .query(query)
    .get();
export default listMaterialDoorApi;
