import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// Api
import uploadApi from '../../../file/api/uploadDoor.api.file';

// Alertify
import {alertify} from 'doorson-ui';

// Components
import DoorImage from '../../components/DoorImage/DoorImage';

// Lib
import toBase64 from '../../../file/lib/toBase64.lib.file';
import FullScreenImage from '../../components/FullScreenImage/DoorImage';

class DoorImageContainer extends Component {
  static propTypes = {
    door: PropTypes.object,
    canAddPictures : PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {}
  };

  state = {
    loading: false,
    image: null,
    fullScreenImage: null
  };

  componentDidMount() {
    this.mounted = true;
    if (!!this.props.door) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((!prevProps.door || prevProps.door !== this.props.door) && !!this.props.door) this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    const [img] = this.props.door.images;
    const image = img || null;
    this.setState({image});
  };

  onUpload = async ({
    target: {
      files: [file],
    },
  }) => {
    // TODO: I18n
    const {door, onChange} = this.props;
    const {loading} = this.state;
    if (loading) return;

    if (!file) return alertify.warning('Please select a file');

    this.setState({loading: true});

    try {
      const fileString = await toBase64(file);
      const image = await uploadApi(door.id, file.name, fileString);
      onChange({...door, images: [image, ...door.images]});
      if (!this.mounted) return;
      this.setState({loading: false, image});
      alertify.success('Image was successfully uploaded');
    } catch (error) {
      if (!this.mounted) return;
      console.error(error)
      alertify.error('Image could not be uploaded');
      this.setState({loading: false});
    }
  };

  toggleFullScreen = (image) => this.setState({fullScreenImage: image})

  onImage = (image) => () => this.setImage(image);

  setImage = (image) => this.setState({image});

  render() {
    const {door, canAddPictures} = this.props;
    const {loading, image, fullScreenImage} = this.state;
    return !door ? null : (
      <Fragment>
        <DoorImage
          loading={loading}
          addDisabled={!canAddPictures}
          image={image}
          images={door.images}
          onImage={this.toggleFullScreen}
          onThumbnail={this.onImage}
          onAdd={this.onUpload}
        />
        {!!fullScreenImage && <FullScreenImage loading={loading} onClose={() => this.toggleFullScreen(null)} image={fullScreenImage} />}
      </Fragment>
    );
  }
}

export default DoorImageContainer;
