import React from 'react';
import PropTypes from 'prop-types';

// Component
import {Button} from 'doorson-ui';
import Container from './components/Container';

const NewAdminMaterialButton = ({children, onClick}) => (
  <Container>
    <Button onClick={onClick}>{children}</Button>
  </Container>
);

NewAdminMaterialButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default NewAdminMaterialButton;
