import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateMaintenanceCheckApi = (id, maintenanceCheck) =>
  f3tch(`/maintenance-checks/${id}`)
    .profile(profile())
    .body(maintenanceCheck)
    .put();
export default updateMaintenanceCheckApi;
