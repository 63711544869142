import styled from 'styled-components';

const ButtonContainer = styled.div`
  width: 167px;
  max-width: 167px;
  min-width: 167px;
  margin-right: 25px;
`;

export default ButtonContainer;
