import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import statuses from '../../lib/status.lib.warehouseOrder';

// Api
import getWarehouseOrderByIdApi from '../../api/getById.api.order';
import deleteWarehouseOrderApi from '../../../warehouseOrderItem/api/delete.api.orderItem';
import listWarehousesApi from "../../../warehouse/api/get.api.warehouse";
import updateWarehouseOrderApi from '../../api/update.api.order';
import getWarehouseOrderItems from '../../../warehouseOrderItem/api/get.api.orderItem';


// Alerts
import {alertify} from 'doorson-ui';

// Components
// Containers
// Routes
import ordersRoute from '../../pages/WarehouseOrdersPage/route';
import warehouseRoute from '../../../warehouse/pages/WarehousePage/route';
import WarehouseOrder from "../../components/WarehouseOrder/WarehouseOrder";
import WarehouseOrderItems from "../../components/WarehouseOrderItems/WarehouseOrderItems";
import getWarehouseName from "../../../warehouse/lib/name.lib.warehouse";

class WarehouseOrderContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object,
  };

  state = {
    loading: false,
    saving: false,
    touched: false,
    order: {},

    warehouses: [],
    orderItems: [],
    error: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {

    this.setState({loading: true});
    await Promise.all([this.loadOrder(),
      this.loadWarehouses(),
    ]);
    this.setState({loading: false})
  };

  loadOrder = async () => {
    const {id, history} = this.props;
    try {
      const order = await getWarehouseOrderByIdApi(id, {rel: '*'});
      const orderItems = await getWarehouseOrderItems({filter: 'whOrderId:EQ:' + id});
      this.setState({order, orderItems})
    } catch (error) {
      alertify.warning('Order does not exists');
      history.replace(ordersRoute());
    }
  }

  loadWarehouses = async () => {
    try {
      const warehouses = await listWarehousesApi();
      this.setState({warehouses})
    } catch (error) {
      alertify.warning('Unable to load warehouses');
    }
  }

  onChange = (key) => (val) => {
    const {saving} = this.state;
    if (saving) return;

    this.setState({touched: true, order: {...this.state.order, [key]: val}});
  };

  onBack = () => this.props.history.push(warehouseRoute((this.state.order || {}).sourceWarehouseId));

  onItemDelete = (item) => async () => {
    const {saving} = this.state;
    if (saving) return;

    this.setState({saving: true});
    try {
      const {id} = item;
      await deleteWarehouseOrderApi(id);
      if (!this.mounted) return;
      this.setState({saving: false, orderItems: [...this.state.orderItems].filter((o) => o.id !== id)});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({saving: false});
      alertify.error('Could not update order');
    }
  };

  saveOrder = async (status) => {
    const {saving, order} = this.state;
    const {user} = this.props;

    if (saving) return;

    const error = {};

    if (status !== 'CANCELED') {
      if (!order.sourceWarehouseId)
        error.sourceWarehouseId = 'Should not be empty';
      if (!order.targetWarehouseId)
        error.targetWarehouseId = 'Should not be empty';
    }

    this.setState({error})
    if (!!Object.keys(error).length) {
      alertify.warning('Insert all the required info properly');
      return;
    }

    const receiverPersonId = status === 'COMPLETED' ? user.id : status.receiverPersonId;
    const receiverPersonName = status === 'COMPLETED' ? `${user.firstName} ${user.lastName}` : status.receiverPersonName;

    this.setState({saving: true});
    try {
      const {id, ...orderToUpdate} = order;
      const newOrder = await updateWarehouseOrderApi(id, {
        ...orderToUpdate,
        status,
        receiverPersonId,
        receiverPersonName
      });
      if (!this.mounted) return;
      this.setState({saving: false, touched: false, order: newOrder});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({saving: false});
      alertify.error('Could not save order info');
    }
  };

  editEnabled = () => this.state.order.status === 'DRAFT'

  inReceiving = () => ['READY_TO_COLLECT', 'IN_TRANSIT', 'COMPLETED', 'REJECTED'].indexOf(this.state.order.status) !== -1

  saveOptions = () => {
    const {order, touched} = this.state;

    switch (order.status) {
      case 'DRAFT':
        return [
          (touched && {value: 'DRAFT', label: 'Save as draft'}),
          {value: 'REQUESTED', label: (touched ? 'Save & ' : '') + 'Make order'},
          {value: 'CANCELED', label: (touched ? 'Save & ' : '') + 'Cancel order'},
        ].filter(v => !!v);
      case 'REPLENISHMENT':
      case 'REQUESTED':
        return [
          {value: 'DRAFT', label: 'Change order'},
          {value: 'CANCELED', label: 'Cancel order'},
        ].filter(v => !!v);
      case 'PICK_PACK':
        return [];
      case 'READY_TO_COLLECT':
        return [
          {value: 'COMPLETED', label: 'Accept order (complete)'},
          {value: 'REJECTED', label: 'Reject order'},
        ].filter(v => !!v);
      default:
        return [];
    }
  }

  orderItems = () => [...this.state.orderItems]

  sourceWarehouses = () => [...this.state.warehouses, {
    name: '',
    value: null
  }].filter((w) => w.id !== this.state.order.targetWarehouseId).map((w) => ({
    label: getWarehouseName({warehouse: w, vehicles: this.props.vehicles}),
    value: w.id
  }))

  targetWarehouses = () => [...this.state.warehouses, {
    name: '',
    value: null
  }].filter((w) => w.id !== this.state.order.sourceWarehouseId).map((w) => ({
    label: getWarehouseName({warehouse: w, vehicles: this.props.vehicles}),
    value: w.id
  }));

  columns = () => [
    {
      key: 'sku',
      label: 'SKU',
      sortable: true,
      span: 2
    },
    {
      key: 'name',
      label: 'Name',
      span: 4,
    },
    {
      key: 'qty',
      label: 'Quantity',
      span: 1,
      sortable: false,
    },
    {
      key: 'status',
      label: 'Status',
      span: 2,
      sortable: false,
    },
    {
      key: 'senderComment',
      label: 'Sender Comment',
      span: 5,
      sortable: false,
    },
  ];

  render() {
    const {
      loading,
      saving,
      order,
      error,
    } = this.state;

    const orderItemsComponent = (
      <WarehouseOrderItems
        columns={this.columns()}
        onSort={this.onSort}
        editEnabled={this.editEnabled()}
        onItemDelete={this.onItemDelete}
        orderItems={this.orderItems()}
      />);

    return (
      <Fragment>
        <WarehouseOrder
          loading={loading}
          saving={saving}
          order={order}
          saveLabel="Options"
          backLabel="Back to store"
          orderLabel="Order request info"
          orderItemsLabel="Order items"
          orderReceiveLabel="Order receive info"
          statusLabel="Status"
          orderNumberLabel="Order number"
          sourceWarehouseLabel="Order from warehouse"
          targetWarehouseLabel="To warehouse"
          requesterPersonLabel="Order person"
          senderPersonLabel="Sender person"
          receiverPersonLabel="Received by"
          collectTimeLabel="Estimated time to collect"
          carrierNameLabel="Carrier name"
          carrierTrackingCodeLabel="Carrier tracking code"
          senderCommentLabel="Sender remarks"
          receiverCommentLabel="Receiver remarks"
          createdDateLabel="Order date"
          createdTimeLabel="Order time"
          noSuggestionLabel="No results"

          orderDispatchLabel="Order dispatch info"

          orderItemsComponent={orderItemsComponent}
          editEnabled={this.editEnabled()}
          inReceiving={this.inReceiving()}
          statuses={statuses()}
          saveOptions={this.saveOptions()}
          sourceWarehouses={this.sourceWarehouses()}
          targetWarehouses={this.targetWarehouses()}

          error={error}
          onBack={this.onBack}
          onChange={this.onChange}
          onStatusSave={this.saveOrder}
          onSave={this.saveOrder}
        />
      </Fragment>
    );
  }

}

export default connect((state) => ({
  user: state.auth.user,
  vehicles: state.vehicle.vehicles,
}))(withRouter(WarehouseOrderContainer));
