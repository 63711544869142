import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Content from './components/Content';

const PageContent = ({children}) => (
  <Container>
    <Content>{children}</Content>
  </Container>
);

PageContent.propTypes = {
  children: PropTypes.node,
};

export default PageContent;
