import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import getCustomerByIDApi from '../../api/getByID.api.customer';
import updateCustomerApi from '../../api/update.api.customer';
import createCustomerBranchApi from '../../../customerBranch/api/create.api.customerBranch';
import getParsedCoodrinatesApi from '../../../maps/api/getParsedCoodrinates.api.maps';

// Alerts
import {alertify} from 'doorson-ui';

// Components
import CustomerAdmin from '../../components/CustomerAdmin/CustomerAdmin';
import NewCustomerBranchModal from '../../../customerBranch/components/NewCustomerBranchModal/NewCustomerBranchModal';

// Containers
import UpdateCustomerBranchContainer
  from '../../../customerBranch/containers/UpdateCustomerBranchContainer/UpdateCustomerBranchContainer';

// Routes
import customersPage from '../../pages/CustomersAdminPage/route';

class CustomerContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    countries: PropTypes.array,
    history: PropTypes.object,
  };

  state = {
    loading: false,
    customer: null,
    saving: false,
    savingBranch: false,
    branchModalVisible: false,
    branchName: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    error: {},

    showUpdateBranchModal: false,
    updateBranch: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    const {id, history} = this.props;
    this.setState({loading: true});

    try {
      const customer = await getCustomerByIDApi(id, {rel: '*'});
      this.setState({loading: false, customer});
    } catch (error) {
      alertify.warning('Customer does not exists');
      history.replace(customersPage());
    }
  };

  showBranchModal = () =>
    this.setState({
      branchModalVisible: true,
      saving: false,
      savingBranch: false,
      branchName: '',
      address: '',
      zip: '',
      city: '',
      country: '',
      error: {},
    });

  hideBranchModal = () => {
    if (this.state.savingBranch) return;
    this.setState({branchModalVisible: false});
  };

  showUpdateBranch = (branch) =>
    this.setState({showUpdateBranchModal: true, updateBranch: branch});

  hideUpdateBranch = () => this.setState({showUpdateBranchModal: false});

  branchUpdated = (branch) => {
    const {customer} = this.state;
    this.setState({
      showUpdateBranchModal: false,
      customer: {
        ...customer,
        branches: [...customer.branches].map((customerBranch) =>
          customerBranch.id === branch.id ? branch : customerBranch
        ),
      },
    });
  };

  branchDeleted = (branch) => {
    const {customer} = this.state;
    this.setState({
      showUpdateBranchModal: false,
      customer: {
        ...customer,
        branches: [...customer.branches].filter(
          (customerBranch) => customerBranch.id !== branch.id
        ),
      },
    });
  };

  getCountryByCode = (code) =>
    [...this.props.countries].find((country) => country.code === code);

  countries = () =>
    [...this.props.countries].map(({code, name}) => ({
      value: code,
      label: name,
    }));

  onCustomerChange = (key, location = false) => (val) => {
    const {saving, savingBranch, customer} = this.state;
    if (saving || savingBranch || !customer) return;
    if (key === 'country') {
      const country = this.getCountryByCode(val);
      if (!country) return;
      this.setState({
        customer: {
          ...customer,
          location: {...customer.location, country: {...country}},
        },
      });
      return;
    }
    const newCustomer = location
      ? {...customer, location: {...customer.location, [key]: val}}
      : {...customer, [key]: val};
    this.setState({customer: newCustomer});
  };

  onChange = (key) => (val) => {
    const {saving, savingBranch} = this.state;
    if (saving || savingBranch) return;
    this.setState({[key]: val});
  };

  saveCustomer = async () => {
    const {saving, savingBranch, customer} = this.state;

    if (saving || savingBranch) return;

    const error = {};
    if (customer.type === 'business' && !customer.company.trim().length)
      error.company = 'Should not be empty';
    if (
      customer.type === 'business' &&
      !customer.registrationNumber.trim().length
    )
      error.registrationNumber = 'Should not be empty';
    if (
      customer.type === 'business' &&
      !customer.location.address.trim().length
    )
      error.address = 'Should not be empty';
    if (customer.type === 'business' && !customer.location.zip.trim().length)
      error.zip = 'Should not be empty';
    if (customer.type === 'business' && !customer.location.city.trim().length)
      error.city = 'Should not be empty';
    if (customer.type === 'business' && !customer.location.countryCode)
      error.countryCode = 'Should not be empty';

    if (customer.type === 'private' && !customer.firstName.trim().length)
      error.firstName = 'Should not be empty';
    if (customer.type === 'private' && !customer.lastName.trim().length)
      error.lastName = 'Should not be empty';
    if (!customer.phone || !customer.phone.trim().length)
      error.phone = 'Should not be empty';
    if (customer.vatNumber && customer.vatNumber.match(/([A-Z]{2})[0-9]{8}/) === null) {
      error.vatNumber = 'VAT number must be of format SI12345678';
    }

    this.setState({ error })
    if (!!Object.keys(error).length) {
      alertify.warning('Insert all the required customer info properly');
      return;
    }

    this.setState({saving: true});

    try {
      const {id, branches, ...customerToUpdate} = customer;
      const geo = await getParsedCoodrinatesApi(
        `${customer.location.address}, ${customer.location.zip} ${customer.location.city}, ${customer.location.countryCode}`
      );
      customerToUpdate.location.geo = geo;
      const newCustomer = await updateCustomerApi(id, customerToUpdate, {rel: '*'});
      if (!this.mounted) return;
      this.setState({saving: false, customer: newCustomer});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({saving: false});
      alertify.error('Could not save customer info');
    }
  };

  saveBranch = async () => {
    const {
      customer,
      saving,
      savingBranch,
      branchName,
      address,
      zip,
      city,
      country: countryCode,
    } = this.state;

    if (saving || savingBranch) return;

    const country = this.getCountryByCode(countryCode);
    if (!country) return;

    // TODO: I18N
    const error = {};

    if (!branchName.trim().length) error.branchName = 'Should not be empty';
    if (!address.trim().length) error.address = 'Should not be empty';
    if (!zip.trim().length) error.zip = 'Should not be empty';
    if (!city.trim().length) error.city = 'Should not be empty';
    if (!countryCode.trim().length) error.country = 'Should not be empty';

    if (!!Object.keys(error).length) {
      this.setState({error});
      alertify.warning('Insert all the required customer info properly');
      return;
    }

    this.setState({savingBranch: true});

    const rawCustomerBranch = {
      branchName,
      branchLocation: {
        address,
        zip,
        city,
        countryCode,
      },
      customerId: customer.id,
    };

    const coordinates = await getParsedCoodrinatesApi(
      `${address}, ${zip} ${city}, ${country.name}`
    );

    rawCustomerBranch.branchLocation.geo = coordinates;

    try {
      const customerBranch = await createCustomerBranchApi(rawCustomerBranch);
      this.setState({
        savingBranch: false,
        branchModalVisible: false,
        customer: {
          ...customer,
          branches: [...customer.branches, customerBranch],
        },
      });
    } catch (error) {
      alertify.error('Could not create a branch. Try again');
      this.setState({savingBranch: false});
    }
  };

  render() {
    const {
      loading,
      saving,
      customer,
      savingBranch,
      branchModalVisible,
      branchName,
      address,
      zip,
      city,
      country,
      error,

      showUpdateBranchModal,
      updateBranch,
    } = this.state;
    return (
      <Fragment>
        <CustomerAdmin
          loading={loading}
          saving={saving}
          customer={customer}
          customerTypeLabel="Type"
          registrationNumberLabel="Registration Number"
          vatNumberLabel="VAT Number"
          companyLabel="Company name"
          addressLabel="Address"
          zipLabel="ZIP Code"
          cityLabel="City"
          countryLabel="Country"
          phoneLabel="Telephone"
          emailLabel="Email"
          firstNameLabel="First Name"
          lastNameLabel="Last Name"
          newBranchLabel="New Branch"
          branchesLabel="Branches"
          noBranchesLabel="This customer doesn't have any branches available."
          searchLabel="Search"
          noBranchesFoundLabel="No Branches Found"
          countries={this.countries()}
          onBranch={this.showUpdateBranch}
          onNewBranch={this.showBranchModal}
          onChange={this.onCustomerChange}
          error={error}
          getCountryByCode={this.getCountryByCode}
          onSave={this.saveCustomer}
        />
        <NewCustomerBranchModal
          title="New Branch"
          branchNameLabel="Branch Name"
          addressLabel="Address"
          zipLabel="Zip Code"
          cityLabel="City"
          countryLabel="Country"
          saveLabel="Save"
          visible={branchModalVisible}
          loading={savingBranch}
          error={error}
          branchName={branchName}
          address={address}
          zip={zip}
          city={city}
          country={country}
          countries={this.countries()}
          onChange={this.onChange}
          onClose={this.hideBranchModal}
          onSave={this.saveBranch}
        />
        <UpdateCustomerBranchContainer
          visible={showUpdateBranchModal}
          branch={updateBranch}
          onComplete={this.branchUpdated}
          onDelete={this.branchDeleted}
          onClose={this.hideUpdateBranch}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  countries: state.country.countries,
}))(withRouter(CustomerContainer));
