import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateDocumentationImageApi = (id, image) =>
  f3tch(`/documentation-folders/${id}/cover-image`)
    .profile(profile())
    .body({image})
    .put();
export default updateDocumentationImageApi;
