import styled from 'styled-components';

const FoldersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1px;
`;

export default FoldersContainer;
