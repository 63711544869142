import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteMaintenanceContractApi = (id) =>
  f3tch(`/maintenance-contracts/${id}`)
    .profile(profile())
    .delete();
export default deleteMaintenanceContractApi;
