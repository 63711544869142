import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers

// Consts
import page from './page';
import WarehousesContainer from "../../containers/WarehousesContainer/WarehousesContainer";

const WarehousesPage = () => (
  <Page page={page}>
    <WarehousesContainer/>
  </Page>
);

export default WarehousesPage;
