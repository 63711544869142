import styled from 'styled-components';
import {transitions} from 'polished';

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  transform: ${({visible, small}) =>
    visible ? 'none' : !small ? 'translateX(100%)' : 'translateX(-100%)'};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  ${transitions('transform .3s ease-in-out, opacity .3s ease-in-out')};
`;

export default Content;
