import React, {forwardRef} from 'react';
import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {grey} = colors;

const StyledIcon = styled.i`
  display: block;
  font-size: 50px;
  display: inline-block;
  margin-bottom: 6px;
  color: ${grey};
`;

const Content = styled.div`
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: ${grey};
  padding: 20px;
  border: 2px dashed ${grey};
  outline: none;
  user-select: none;
`;

const UploadLabel = forwardRef(({children, ...props}, ref) => (
  <Content {...props} ref={ref}>
    <StyledIcon className="mdi mdi-file-document-box-plus-outline" />
    <br />
    {children}
  </Content>
));

export default UploadLabel;
