import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import DoorActionsContainer from "../../containers/MaintenanceDoorActionsContainer/DoorActionsContainer";

// TODO: I18n
const MaintenanceDoorActionsPage = ({match}) => (
  <SubPage page={match.path}>
    <DoorActionsContainer/>
  </SubPage>
);

MaintenanceDoorActionsPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(MaintenanceDoorActionsPage);
