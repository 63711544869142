import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Button} from 'doorson-ui';

import Container from './components/Container';
import Box from './components/Box';
import ButtonRow from './components/ButtonRow';
import ButtonContainer from './components/ButtonContainer';

const MaintenanceActions = ({deleteLabel, onDelete}) => (
  <Container>
    <Box>
      <Row>
        <Column>
          <ButtonRow>
            <ButtonContainer>
              <Button theme="red" preIcon="delete" onClick={onDelete}>
                {deleteLabel}
              </Button>
            </ButtonContainer>
          </ButtonRow>
        </Column>
      </Row>
    </Box>
  </Container>
);

MaintenanceActions.propTypes = {
  deleteLabel: PropTypes.string,
  onDelete: PropTypes.func,
};

export default MaintenanceActions;
