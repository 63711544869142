import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setMaintenanceDoorAct} from '../../../maintenanceDoor/redux/actions';

// Api
import updateDoorApi from '../../../door/api/update.api.door';

// Components
import DoorInfo from '../../components/DoorInfo/DoorInfo';

// Containers
import EditDoorInfoContainer from '../EditDoorInfoContainer/EditDoorInfoContainer';

// Libs
import isAssigned from '../../../maintenance/lib/isAssigned.lib.maintenance';
import prepareDoorForUpdate from '../../../door/lib/prepareForUpdate.lib.door';

// Types
import {date} from '../../../types';

// Alert
import {alertify} from 'doorson-ui';
import serviceItemRoute from "../../../door/pages/ServiceItemPage/route";

class DoorInfoContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    maintenanceDoor: PropTypes.object,
    user: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    savingInline: false,
    editVisible: false,
    editLabel: null,
    editKey: null,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  showEdit = (key, label) => () => {
    if (this.state.loading || !this.editable()) return;
    this.setState({
      editVisible: true,
      editKey: key,
      editLabel: label,
    });
  };

  hideEdit = () => this.setState({editVisible: false});

  inlineSave = (key) => async (value) => {
    const {maintenanceDoor, dispatch} = this.props;
    const {savingInline} = this.state;
    if (savingInline) return;

    try {
      const door = await updateDoorApi(
        maintenanceDoor.door.id,
        prepareDoorForUpdate({
          ...maintenanceDoor.door,
          [key]: value,
        })
      );
      dispatch(
        setMaintenanceDoorAct({maintenanceDoor: {...maintenanceDoor, door}})
      );
      if (!this.mounted) return;
      this.setState({savingInline: false});
    } catch (error) {
      if (!this.mounted) return;
      alertify.error('Could not store '+key);
    }
  };

  editComplete = (maintenanceDoor) => {
    this.props.dispatch(setMaintenanceDoorAct({maintenanceDoor}));
    this.setState({editVisible: false});
  };

  formatIfExists = (value, formatter) =>
    !!value ? formatter(value).format() : '';

  doorType = () => {
    const {maintenanceDoor} = this.props;
    const product = maintenanceDoor.door.product.name;
    const productType = !!maintenanceDoor.door.productType
      ? maintenanceDoor.door.productType.name
      : null;
    return !!productType ? `${product} ${productType}` : product;
  };

  editable = () => {
    const {maintenance, user} = this.props;
    return isAssigned(user, maintenance);
  };

  onDoorChange = (door) => {
    if (!this.mounted) return;
    const {maintenanceDoor, dispatch} = this.props;
    maintenanceDoor.door = door;
    dispatch(setMaintenanceDoorAct({maintenanceDoor}));
  };

  render() {
    const {maintenanceDoor} = this.props;
    const {editVisible, editLabel, editKey} = this.state;
    return (
      <Fragment>
        <DoorInfo
          title="Door Info"
          manufacturerLabel="Manufacturer"
          doorTypeLabel="Door type"
          manufactureDateLabel="Manufacture date"
          installationDateLabel="Installation Date"
          doorLocationLabel="Door location"
          lastMaintenanceDateLabel="Last maintenance date"
          warrantyDateLabel="Warranty date"
          doorSerialLabel="Door serial number"
          electronicsSerialLabel="Electronic serial number"
          motorSerialLabel="Motor serial number"
          redundancyLabel="Redundancy"
          leafInfoLabel="Leaf info"
          manufacturer={maintenanceDoor.door.product.manufacturer.name}
          serviceItemUrl={(maintenanceDoor.door || {}).id && serviceItemRoute((maintenanceDoor.door || {}).id)}
          doorType={this.doorType()}
          doorLocation={`${maintenanceDoor.door.indoorLocation} ${maintenanceDoor.door.buildingFloor}`}
          manufactureDate={this.formatIfExists(
            maintenanceDoor.door.manufactureDate,
            date
          )}
          installationDate={this.formatIfExists(
            maintenanceDoor.door.installationDate,
            date
          )}
          lastMaintenanceDate={this.formatIfExists(
            maintenanceDoor.door.lastMaintenanceDate,
            date
          )}
          warrantyDate={this.formatIfExists(
            maintenanceDoor.door.warrantyValidTillDate,
            date
          )}
          doorSerial={maintenanceDoor.door.doorSerial}
          electronicsSerial={maintenanceDoor.door.electronicsSerial}
          motorSerial={maintenanceDoor.door.motorSerial}
          redundancy={maintenanceDoor.door.redundancy}
          leafInfo={maintenanceDoor.door.leafInfo || ''}
          door={maintenanceDoor.door}
          onDoorChange={this.onDoorChange}
          editable={this.editable()}
          onEdit={this.showEdit}
          onInlineSave={this.inlineSave}
        />
        <EditDoorInfoContainer
          maintenanceDoor={maintenanceDoor}
          visible={editVisible}
          label={editLabel}
          valueKey={editKey}
          onClose={this.hideEdit}
          onComplete={this.editComplete}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({user: state.auth.user}))(DoorInfoContainer);
