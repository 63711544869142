import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {connect} from 'react-redux';
import {getTime} from 'date-fns';
import {withRouter} from 'react-router-dom';
import {isEmail} from 'validator';

// Api
import createInterventionApi from '../../api/create.api.intervention';
import searchCustomerApi from '../../../customer/api/list.api.customer';
import getParsedCoodrinatesApi from '../../../maps/api/getParsedCoodrinates.api.maps';
import updateCustomerApi from '../../../customer/api/update.api.customer';
import updateCustomerBranchApi from '../../../customerBranch/api/update.api.customerBranch';
import getDevicesByLocation from '../../api/getDevicesByLocation.api.device';
import getServiceItemByIdApi from '../../../door/api/getByID.api.door';
import getByIDApiCustomerBranch from '../../../customerBranch/api/getByID.api.customerBranch';
import getByIDApiCustomer from '../../../customer/api/getByID.api.customer';

// Alert
import {alertify} from 'doorson-ui';

// Components
import ContentLayout from '../../../layout/components/ContentLayout/ContentLayout';
import InterventionsHeader from '../../components/InterventionsHeader/InterventionsHeader';
import NewInterventionTabs from '../../components/NewInterventionTabs/NewInterventionTabs';
import InterventionInfo from '../../components/InterventionInfo/InterventionInfo';
import PriorityModal from '../../components/PriorityModal/PriorityModal';
import CustomerEdit from '../../../customer/components/CustomerEdit/CustomerEdit';
import DoorEdit from '../../../interventionDoor/components/DoorEdit/DoorEdit';

// Priorities
import {priorities} from '../../lib/priority.lib.intervention';

// Routes
import interventionsRoute from '../../pages/InterventionsPage/route';

// Types
import {date as dateType} from '../../../types';
import isActive from '../../../user/lib/isActive.lib.user';
import UpdateCustomerBranchContainer from '../../../customerBranch/containers/UpdateCustomerBranchContainer/UpdateCustomerBranchContainer';

class NewInterventionContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
    countries: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  static newDoor = (id = v4()) => ({
    [id]: {
      id,
      time: getTime(new Date()),
      error: {},
      givenDescription: '',
      indoorLocation: '',
      door: null,
    },
  });

  static existingDoor = (id = v4(), serviceItem) => ({
    [id]: {
      id,
      time: getTime(new Date()),
      error: {},
      givenDescription: '',
      indoorLocation: serviceItem.indoorLocation,
      door: serviceItem,
    },
  });

  state = {
    loading: false,
    tab: 'customer',
    error: {},
    customer: null,
    customerBranch: null,
    customerStepCompleted: false,
    firstName: '',
    lastName: '',
    company: '',
    type: 'business',
    registrationNumber: '',
    vatNumber: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    phone: '',
    email: '',
    sameAsAddress: false,
    branchName: '',
    branchAddress: '',
    branchZip: '',
    branchCity: '',
    branchCountry: '',
    doors: {},
    door: null,
    assigneeId: null,
    priority: null,
    priorityVisible: false,
    companies: [],
    branches: [],
    searchingAssignees: [],
    searchKey: null,
    searchingForCustomer: false,
    showUpdateBranchModal: false,
    reportingRemarks: '',
  };

  async componentDidMount() {
    this.mounted = true;

    const interventionDoorId = v4();
    const {serviceItemId} = Object.fromEntries(
      new URLSearchParams(this.props.location.search)
    );

    if (!!serviceItemId) {
      const serviceItem = await getServiceItemByIdApi(serviceItemId);
      if (!serviceItem) {
        alertify.warning('Given door does not exist');
      } else {
        const {customerBranchId} = serviceItem;
        const branch = await getByIDApiCustomerBranch(customerBranchId);
        const {customerId} = branch;
        const company = await getByIDApiCustomer(customerId);

        this.setState({
          companies: [company],
          branches: [branch],
          doors: {
            ...NewInterventionContainer.existingDoor(
              interventionDoorId,
              serviceItem
            ),
          },
          door: interventionDoorId,
          customer: company,
          company: company.company,
          registrationNumber: company.registrationNumber,
          address: !!company.location ? company.location.address : '',
          zip: !!company.location ? company.location.zip : '',
          city: !!company.location ? company.location.city : '',
          country: !!company.location ? company.location.countryCode : '',
          customerBranch: branch,
          branchName: branch.branchName,
          branchAddress: branch.branchLocation.address,
          branchZip: branch.branchLocation.zip,
          branchCity: branch.branchLocation.city,
          branchCountry: branch.branchLocation.countryCode,
        });
      }
    } else {
      this.setState({
        doors: {...NewInterventionContainer.newDoor(interventionDoorId)},
        door: interventionDoorId,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  lastCompanySearch = 0;

  onChange = (key) => (value) => {
    const {loading} = this.state;
    if (loading || !this.mounted) return;
    if (key === 'type')
      return this.setState({
        type: value,
        error: {},
        customer: null,
        customerBranch: null,
        customerStepCompleted: false,
        firstName: '',
        lastName: '',
        company: '',
        registrationNumber: '',
        vatNumber: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        phone: '',
        email: '',
        sameAsAddress: false,
        branchName: '',
        branchAddress: '',
        branchZip: '',
        branchCity: '',
        branchCountry: '',
        companies: [],
        searchingAssignees: [],
        searchKey: null,
        searchingForCustomer: false,
      });
    const error = Object.entries(this.state.error).reduce(
      (combined, [errorKey, value]) =>
        key === errorKey ? combined : {...combined, [errorKey]: value},
      {}
    );
    const state = {[key]: value, customerStepCompleted: false, error};

    if (key === 'priority') {
      if (!!value && value > 2) {
        this.searchAssignees();
      } else {
        state.assigneeId = null;
      }
    }

    if (['company', 'registrationNumber'].includes(key))
      this.searchCompany(value, key);

    if (key === 'branchName') this.searchBranch(value);

    if (key === 'sameAsAddress' && value) {
      state.branchName =
        this.state.type === 'business'
          ? this.state.company
          : `${this.state.firstName} ${this.state.lastName}`;
      state.branchAddress = this.state.address;
      state.branchZip = this.state.zip;
      state.branchCity = this.state.city;
      state.branchCountry = this.state.country;
    }

    if (
      ['branchAddress', 'branchZip', 'branchCity', 'branchCountry'].includes(
        key
      )
    )
      state.sameAsAddress = false;

    if (
      ['address', 'zip', 'city', 'country'].includes(key) &&
      this.state.sameAsAddress
    )
      state[`branch${`${key.charAt(0).toUpperCase()}${key.slice(1)}`}`] = value;

    if (key === 'company' && this.state.sameAsAddress) state.branchName = value;

    state.customer = [
      'company',
      'registrationNumber',
      'firstName',
      'lastName',
    ].includes(key)
      ? null
      : this.state.customer;

    state.customerBranch = [
      'company',
      'registrationNumber',
      'firstName',
      'lastName',
      'branchName',
    ].includes(key)
      ? null
      : this.state.customerBranch;

    this.setState(state);
  };

  onDoorChange = (key) => (value) => {
    const {loading, doors, door} = this.state;
    if (loading || !this.mounted) return;
    this.setState({
      doors: {
        ...doors,
        [door]: {
          ...doors[door],
          [key]: value,
          error: Object.entries(doors[door].error).reduce(
            (combined, [errorKey, value]) =>
              key === errorKey ? combined : {...combined, [errorKey]: value},
            {}
          ),
        },
      },
    });
  };

  addDoor = () => {
    const {loading, doors} = this.state;
    if (loading) return;
    const door = this.constructor.newDoor();
    this.setState({doors: {...doors, ...door}});
  };

  removeDoor = () => {
    const {loading, door} = this.state;
    if (loading || !door || Object.keys(this.state.doors).length <= 1) return;
    const doors = Object.entries({...this.state.doors}).reduce(
      (combined, [key, value]) =>
        key === door ? combined : {...combined, [key]: value},
      {}
    );
    this.setState({doors, door: Object.keys(doors)[0]});
  };

  searchAssignees = async () => {
    const {branchAddress, branchCity, branchZip, branchCountry} = this.state;
    const countryName =
      (this.getCountryByCode(branchCountry) || {}).name || branchCountry;
    const {users} = this.props;

    try {
      const geo = await getParsedCoodrinatesApi(
        `${branchAddress}, ${branchZip} ${branchCity}, ${countryName}`
      );

      const userLocations = await getDevicesByLocation(geo);

      const assignees = [...users].filter(isActive).map((u) => ({
        ...u,
        kmAway: ([...userLocations].find((ul) => ul.userId === u.id) || {})
          .kmAway,
      }));
      const searchingAssignees = assignees.sort(
        (a, b) =>
          (a.kmAway === undefined ? 9999 : a.kmAway) -
          (b.kmAway === undefined ? 9999 : b.kmAway)
      );
      this.setState({searchingAssignees});
    } catch (e) {
      console.error(e);
      alertify.warning('Cannot get nearest users');
      this.setState({searchingAssignees: []});
    }
  };

  assignees = () => {
    const {searchingAssignees} = this.state;
    return [...searchingAssignees].map((a) => ({
      value: a.id,
      label:
        a.firstName +
        ' ' +
        a.lastName +
        (a.kmAway ? ' - ' + a.kmAway + ' km away' : ''),
    }));
  };

  searchCompany = async (search, key) => {
    const {searchKey, treshold} = (() => {
      switch (key) {
        case 'registrationNumber':
          return {searchKey: 'registrationNumber', treshold: 10};
        default:
          return {searchKey: 'company', treshold: 3};
      }
    })();

    this.setState({searchKey: key, searchingForCustomer: true});

    if (search.trim().length < treshold)
      return this.setState({companies: [], searchingForCustomer: false});

    const lastCompanySearch = getTime(new Date());
    this.lastCompanySearch = lastCompanySearch;
    try {
      const companies = await searchCustomerApi({
        rel: 'customerBranches',
        filter: encodeURIComponent(`${searchKey}:LIKEIC:%${search}%`),
      });
      if (lastCompanySearch !== this.lastCompanySearch) return;
      const filteredCompanies = [...companies].filter(
        ({location}) => !!location
      );
      this.setState({
        companies: filteredCompanies,
        searchingForCustomer: false,
      });
    } catch (error) {
      this.setState({searchingForCustomer: false});
      // Do nothing for now
    }
  };

  searchBranch = (branch) => {
    const {customer} = this.state;
    const branches =
      !!customer && !!customer.branches
        ? [...customer.branches].filter(({branchName, branchLocation}) =>
            this.branchDisplay({branchName, branchLocation})
              .toLowerCase()
              .includes(branch.toLowerCase())
          )
        : [];
    this.setState({branches});
  };

  branchDisplay = ({branchName, branchLocation: {address, city, zip}}) =>
    `${branchName}, ${address}, ${zip} ${city}`;

  countries = () =>
    [...this.props.countries].map(({code, name}) => ({
      value: code,
      label: name,
    }));

  getCountryByCode = (code) =>
    [...this.props.countries].find((country) => country.code === code);

  types = () => [
    {
      value: 'business',
      label: 'Business customer',
    },
    {
      value: 'private',
      label: 'Private customer',
    },
  ];

  tabs = () => {
    const {customerStepCompleted} = this.state;
    // TODO: I18N
    return [
      {
        id: 'customer',
        label: 'Customer Info',
        done: customerStepCompleted,
      },
      {
        id: 'door',
        label: 'Door Info',
        done: false,
      },
      {
        id: 'assign',
        label: 'Assign',
        done: false,
      },
      {
        id: 'workLog',
        label: 'Work Log',
        done: false,
      },
      {
        id: 'confirmation',
        label: 'Final Confirmation',
        done: false,
      },
      {
        id: 'attachments',
        label: 'Attachments',
        done: false,
        icon: 'paperclip',
      },
    ];
  };

  doors = () => Object.values(this.state.doors).sort((a, b) => a.time - b.time);

  companies = () =>
    [...this.state.companies]
      .filter(({registrationNumber}) => !!registrationNumber)
      .map(({company, registrationNumber}) => ({
        value: registrationNumber,
        label: company,
      }));

  branches = () =>
    [...this.state.branches].map(({id, branchName, branchLocation}) => ({
      value: id,
      label: this.branchDisplay({branchName, branchLocation}),
    }));

  closePriority = () => {
    if (this.state.loading) return;
    this.setState({priorityVisible: false});
  };

  onTab = (tab) => {
    const {customerStepCompleted} = this.state;
    if (!customerStepCompleted || !['customer', 'door'].includes(tab)) return;

    if ('door' === tab) {
      const {
        customer,
        branchName: typedBranchName,
        branchAddress,
        branchZip,
        branchCity,
        branchCountry: branchCountryCode,
      } = this.state;

      if (
        !!customer &&
        !!customer.id &&
        [...customer.branches].find(
          ({branchLocation, branchName}) =>
            branchLocation.address.toLowerCase() ===
              branchAddress.toLocaleLowerCase() &&
            branchLocation.zip.toLocaleLowerCase() ===
              branchZip.toLocaleLowerCase() &&
            branchLocation.city.toLocaleLowerCase() ===
              branchCity.toLocaleLowerCase() &&
            branchLocation.countryCode === branchCountryCode &&
            branchName.toLocaleLowerCase() !==
              typedBranchName.toLocaleLowerCase()
        ) &&
        ![...customer.branches].find(
          ({branchLocation, branchName}) =>
            branchLocation.address.toLowerCase() ===
              branchAddress.toLocaleLowerCase() &&
            branchLocation.zip.toLocaleLowerCase() ===
              branchZip.toLocaleLowerCase() &&
            branchLocation.city.toLocaleLowerCase() ===
              branchCity.toLocaleLowerCase() &&
            branchLocation.countryCode === branchCountryCode &&
            branchName.toLocaleLowerCase() ===
              typedBranchName.toLocaleLowerCase()
        )
      ) {
        alertify.warning(
          'Branch with different name already exists on same address, new branch will be created!'
        );
      }
    }

    this.setState({tab});
  };

  onDoor = async (door) => {
    if (this.state.loading) return;
    await this.setState({door});
  };

  onCompany = (registrationNumber) => {
    const {sameAsAddress} = this.state;
    const company = [...this.state.companies].find(
      (c) => c.registrationNumber === registrationNumber
    );
    if (!company) return;
    const state = {
      customer: company,
      customerBranch: null,
      company: company.company,
      registrationNumber: company.registrationNumber,
      vatNumber: company.vatNumber,
      address: !!company.location ? company.location.address : '',
      zip: !!company.location ? company.location.zip : '',
      city: !!company.location ? company.location.city : '',
      country: !!company.location ? company.location.countryCode : '',
      branchName: '',
      branchAddress: '',
      branchZip: '',
      branchCity: '',
      branchCountry: '',
      branches: [],
      phone: company.phone,
      email: company.email,
    };

    if (!company.id && sameAsAddress) {
      state.branchName = state.company;
      state.branchAddress = state.address;
      state.branchZip = state.zip;
      state.branchCity = state.city;
      state.branchCountry = state.country;
    } else {
      state.sameAsAddress = !company.id && sameAsAddress;
    }

    this.setState(state);
  };

  onBranch = (branchID) => {
    const {customer} = this.state;
    if (!customer || !customer.branches) return;
    const branch = customer.branches.find(({id}) => `${id}` === `${branchID}`);
    if (!branch) return;
    this.setState({
      customerBranch: branch,
      branchName: branch.branchName,
      branchAddress: branch.branchLocation.address,
      branchZip: branch.branchLocation.zip,
      branchCity: branch.branchLocation.city,
      branchCountry: branch.branchLocation.countryCode,
    });
    if (!(branch.branchLocation || {}).geo) {
      alertify.warning(
        'Invalid customer branch information, please update accordingly.'
      );
    }
  };

  onCustomer = async () => {
    const {
      loading,
      customerStepCompleted,
      firstName,
      lastName,
      company,
      type,
      registrationNumber,
      vatNumber,
      address,
      zip,
      city,
      country,
      email,
      branchName,
      branchAddress,
      branchZip,
      branchCity,
      branchCountry,
    } = this.state;

    if (loading) return;

    if (customerStepCompleted) return this.onTab('door');

    // TODO: I18N
    const error = {};
    if (type === 'business' && !company.trim().length)
      error.company = 'Should not be empty';
    if (type === 'business' && !registrationNumber.trim().length)
      error.registrationNumber = 'Should not be empty';
    if (type === 'business' && !branchAddress.trim().length)
      error.branchAddress = 'Should not be empty';
    if (type === 'business' && !branchZip.trim().length)
      error.branchZip = 'Should not be empty';
    if (type === 'business' && !branchCity.trim().length)
      error.branchCity = 'Should not be empty';
    if (type === 'business' && !branchCountry.trim().length)
      error.branchCountry = 'Should not be empty';

    if (type === 'private' && !firstName.trim().length)
      error.firstName = 'Should not be empty';
    if (type === 'private' && !lastName.trim().length)
      error.lastName = 'Should not be empty';

    if (!branchName.trim().length) error.branchName = 'Should not be empty';
    if (!address.trim().length) error.address = 'Should not be empty';
    if (!zip.trim().length) error.zip = 'Should not be empty';
    if (!city.trim().length) error.city = 'Should not be empty';
    if (!country.trim().length) error.country = 'Should not be empty';
    if (!!email && !isEmail(email))
      error.email = 'Should be a valid email address';
    if (vatNumber && vatNumber.match(/([A-Z]{2})[0-9]{8}/) === null) {
      error.vatNumber = 'VAT number must be of format SI12345678';
    }

    if (!!Object.keys(error).length) {
      this.setState({error});
      alertify.warning('Insert all the required customer info properly');
      return;
    }

    await this.setState({customerStepCompleted: true, error: {}});
    this.onTab('door');
  };

  checkDoor = (door) => {
    // TODO: I18N
    const error = {};

    if (!door.givenDescription.trim().length)
      error.givenDescription = 'Should not be empty';
    if (!door.indoorLocation.trim().length)
      error.indoorLocation = 'Should not be empty';

    return {...door, error};
  };

  checkDoors = () => {
    // TODO: I18N
    const {doors: rawDoors, loading} = this.state;

    if (loading) return;

    const checkedDoors = Object.values(rawDoors).map(this.checkDoor);
    const hasError = !!checkedDoors.find(
      ({error}) => !!Object.keys(error).length
    );

    if (hasError) {
      const formattedDoors = [...checkedDoors].reduce(
        (combined, current) => ({...combined, [current.id]: current}),
        {}
      );
      this.setState({doors: formattedDoors});
      return alertify.warning('Check all the inserted door data');
    }

    this.setState({priorityVisible: true});
  };

  createIntervention = async () => {
    // TODO: I18n
    // TODO: Error management
    const {history, user} = this.props;
    const {
      loading,
      customer,
      customerBranch: selectedCustomerBranch,
      firstName,
      lastName,
      company,
      type,
      registrationNumber,
      vatNumber,
      address,
      zip,
      city,
      country: countryCode,
      phone,
      email,
      branchName,
      priority,
      assigneeId,
      branchAddress,
      branchZip,
      branchCity,
      branchCountry: branchCountryCode,
      doors: rawDoors,
      reportingRemarks,
    } = this.state;
    if (loading) return;

    this.setState({loading: true});

    const country = this.getCountryByCode(countryCode);
    const branchCountry = this.getCountryByCode(branchCountryCode);

    const doors = Object.values(rawDoors).map(
      ({id, time, error, door, doors, ...rawDoor}) =>
        !!door ? {...rawDoor, doorId: door.id} : rawDoor
    );

    const rawIntervention = {
      priority,
      createdBy: user.id,
      doors,
      phone,
      email,
      assigneeId,
    };

    const customerBranch = !!selectedCustomerBranch
      ? selectedCustomerBranch
      : !!customer && !!customer.id
      ? [...customer.branches].find(
          ({branchLocation, branchName: tBranchName}) =>
            branchLocation.address.toLowerCase() ===
              branchAddress.toLocaleLowerCase() &&
            branchLocation.zip.toLocaleLowerCase() ===
              branchZip.toLocaleLowerCase() &&
            branchLocation.city.toLocaleLowerCase() ===
              branchCity.toLocaleLowerCase() &&
            branchLocation.countryCode === branchCountryCode &&
            tBranchName.toLocaleLowerCase() === branchName.toLocaleLowerCase()
        )
      : null;

    if (!!customer && !!customer.id && !!customerBranch && customerBranch.id) {
      rawIntervention.customerBranchId = customerBranch.id;
    } else {
      rawIntervention.customerBranch = {
        branchName,
        branchLocation: {
          address: branchAddress,
          zip: branchZip,
          city: branchCity,
          countryCode: branchCountryCode,
        },
      };
    }

    if (!!reportingRemarks) rawIntervention.reportingRemarks = reportingRemarks;

    if (!!customer && !!customer.id) rawIntervention.customerId = customer.id;
    else
      rawIntervention.customer =
        type === 'business'
          ? {
              company,
              type,
              registrationNumber,
              vatNumber,
              phone,
              email,
              location: {
                address,
                zip,
                city,
                countryCode,
              },
            }
          : {
              firstName,
              lastName,
              vatNumber,
              type,
              phone,
              email,
              location: {
                address,
                zip,
                city,
                countryCode,
              },
            };

    if (!!rawIntervention.customer) {
      const customerCoordinates = await getParsedCoodrinatesApi(
        `${address}, ${zip} ${city}, ${country.name}`
      );
      if (customerCoordinates.longitude !== null)
        rawIntervention.customer.location.geo = customerCoordinates;
    }

    if (!!rawIntervention.customerBranch) {
      const branchCoordinates = await getParsedCoodrinatesApi(
        `${branchAddress}, ${branchZip} ${branchCity}, ${branchCountry.name}`
      );
      if (branchCoordinates.longitude !== null)
        rawIntervention.customerBranch.branchLocation.geo = branchCoordinates;
    }

    try {
      await Promise.all([
        this.updateCustomerGeo(customer),
        this.updateCustomerBranchGeo(customerBranch),
      ]);
      await createInterventionApi(rawIntervention);
      history.push(interventionsRoute());
    } catch (error) {
      alertify.error('Could not create an intervention incident. Try again');
      this.setState({loading: false, priorityVisible: false});
    }
  };

  updateCustomerGeo = async (customer) => {
    if (!customer || !customer.id || !customer.location) return;

    try {
      const geo = await getParsedCoodrinatesApi(
        `${customer.location.address}, ${customer.location.zip} ${customer.location.city}, ${customer.location.countryCode}`
      );
      if (
        !geo ||
        ((customer.location.geo || {}).latitude === geo.latitude &&
          (customer.location.geo || {}).longitude === geo.longitude)
      ) {
        return;
      }

      await updateCustomerApi(customer.id, {
        firstName: customer.firstName,
        lastName: customer.lastName,
        company: customer.company,
        registrationNumber: customer.registrationNumber,
        vatNumber: customer.vatNumber,
        email: customer.email,
        phone: customer.phone,
        version: customer.version,
        location: {
          geo,
          address: customer.location.address,
          zip: customer.location.zip,
          city: customer.location.city,
          countryCode: customer.location.countryCode,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  updateCustomerBranchGeo = async (customerBranch) => {
    if (!customerBranch || !customerBranch.id || !customerBranch.branchLocation)
      return;

    try {
      const geo = await getParsedCoodrinatesApi(
        `${customerBranch.branchLocation.address}, ${customerBranch.branchLocation.zip} ${customerBranch.branchLocation.city}, ${customerBranch.branchLocation.countryCode}`
      );
      if (
        !geo ||
        ((customerBranch.branchLocation.geo || {}).latitude === geo.latitude &&
          (customerBranch.branchLocation.geo || {}).longitude === geo.longitude)
      ) {
        return;
      }

      await updateCustomerBranchApi(customerBranch.id, {
        branchName: customerBranch.branchName,
        version: customerBranch.version,
        branchLocation: {
          geo,
          address: customerBranch.branchLocation.address,
          zip: customerBranch.branchLocation.zip,
          city: customerBranch.branchLocation.city,
          countryCode: customerBranch.branchLocation.countryCode,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  onReportedFault = async (reportedFaultId) => {
    const {reportedFaults} = this.props;
    const fault = [...reportedFaults].find((f) => f.id === reportedFaultId);
    if (!!fault) {
      this.onDoorChange('givenDescription')(fault.name);
    }
  };

  reportedFaults = () => {
    // TODO: I18n
    const {reportedFaults} = this.props;
    return [...reportedFaults].map(({id, name}) => ({
      value: id,
      label: name,
    }));
  };

  showUpdateBranch = () => this.setState({showUpdateBranchModal: true});

  hideUpdateBranch = () => this.setState({showUpdateBranchModal: false});

  branchUpdated = (branch) => {
    const {customer} = this.state;
    this.setState({
      showUpdateBranchModal: false,
      customerBranch: branch,
      branchName: branch.branchName,
      branchAddress: branch.branchLocation.address,
      branchZip: branch.branchLocation.zip,
      branchCity: branch.branchLocation.city,
      branchCountry: branch.branchLocation.countryCode,
      customer: {
        ...customer,
        branches: [...customer.branches].map((customerBranch) =>
          customerBranch.id === branch.id ? branch : customerBranch
        ),
      },
    });
  };

  renderTabs = () => {
    // TODO: I18N
    const {
      loading,
      error,
      tab,
      type,
      company,
      registrationNumber,
      vatNumber,
      firstName,
      lastName,
      address,
      zip,
      city,
      country,
      sameAsAddress,
      branchName,
      branchAddress,
      branchZip,
      branchCity,
      branchCountry,
      phone,
      email,
      doors,
      door,
      searchKey,
      customer,
      customerBranch,
      searchingForCustomer,
      showUpdateBranchModal,
      reportingRemarks,
    } = this.state;

    switch (tab) {
      case 'customer':
        return (
          <Fragment>
            <CustomerEdit
              companyLabel="Company name"
              registrationNumberLabel="Registration number"
              vatNumberLabel="VAT number"
              firstNameLabel="First Name"
              lastNameLabel="Last Name"
              addressLabel="Address"
              zipLabel="Zip Code"
              cityLabel="City"
              countryLabel="Country"
              branchNameLabel="Branch Name"
              branchAddressLabel="Branch Address"
              branchZipLabel="Branch Zip Code"
              branchCityLabel="Branch City"
              branchCountryLabel="Branch Country"
              sameAsAddressLabel="Same as default Address"
              phoneLabel="Phone"
              emailLabel="Email"
              buttonLabel="Enter door info"
              editBranchLabel="Edit branch address"
              noSuggestionLabel="No suggestions found"
              loading={loading}
              types={this.types()}
              companies={this.companies()}
              branches={this.branches()}
              error={error}
              type={type}
              company={company}
              customer={customer}
              registrationNumber={registrationNumber}
              vatNumber={vatNumber}
              firstName={firstName}
              lastName={lastName}
              address={address}
              zip={zip}
              city={city}
              country={country}
              sameAsAddress={sameAsAddress}
              branchName={branchName}
              branchAddress={branchAddress}
              branchZip={branchZip}
              branchCity={branchCity}
              branchCountry={branchCountry}
              phone={phone}
              email={email}
              countries={this.countries()}
              searchKey={searchKey}
              customerSelected={!!customer}
              customerBranchSelected={!!customerBranch}
              searchingForCustomer={searchingForCustomer}
              onChange={this.onChange}
              onConfirm={this.onCustomer}
              onCompany={this.onCompany}
              onBranch={this.onBranch}
              showUpdateBranch={this.showUpdateBranch}
            />
            <UpdateCustomerBranchContainer
              visible={!!customerBranch && showUpdateBranchModal}
              branch={customerBranch}
              onComplete={this.branchUpdated}
              onClose={this.hideUpdateBranch}
            />
          </Fragment>
        );

      case 'door':
        return (
          <DoorEdit
            loading={loading}
            doors={this.doors()}
            door={doors[door]}
            reportingRemarks={reportingRemarks}
            reportedFaults={this.reportedFaults()}
            searchingForReportedFault={false}
            onReportedFault={this.onReportedFault}
            givenDescriptionLabel="Given fault description"
            indoorLocationLabel="Door location"
            reportingRemarksLabel="Reporting remarks"
            removeDoorLabel="Remove door"
            buttonLabel="Create incident"
            onNewDoor={this.addDoor}
            onDoor={this.onDoor}
            onChange={this.onDoorChange}
            onConfirm={this.checkDoors}
            onRemove={this.removeDoor}
            onRemarksChange={this.onChange}
          />
        );

      default:
        return 'Invalid';
    }
  };

  renderHeader = () => (
    <Fragment>
      <InterventionsHeader>New Interventional Incident</InterventionsHeader>
      <NewInterventionTabs
        tab={this.state.tab}
        tabs={this.tabs()}
        onTab={this.onTab}
      />
    </Fragment>
  );

  render() {
    // TODO: I18N
    const {user} = this.props;
    const {loading, priorityVisible, priority, assigneeId} = this.state;

    return (
      <Fragment>
        <ContentLayout header={this.renderHeader()}>
          <InterventionInfo
            incidentStatusLabel="Incident status"
            incidentCodeLabel="Incident code"
            invoiceNumberLabel="Invoice number"
            incidentCreatedLabel="Incident created"
            workIssuedLabel="Work order issued by"
            incidentResolvedLabel="Incident resolved by"
            status="/"
            code="/"
            invoiceNumber="/"
            created={dateType(new Date()).format()}
            issuedBy={`${user.firstName} ${user.lastName}`}
            resolvedBy="/"
          />
          {this.renderTabs()}
        </ContentLayout>
        <PriorityModal
          title="Priority"
          visible={priorityVisible}
          loading={loading}
          buttonLabel="Create"
          priorityLabel="Select priority"
          priorities={priorities}
          priority={priority}
          assigneeLabel="Select assignee"
          assigneeId={assigneeId}
          assignees={this.assignees()}
          onPriorityChange={this.onChange('priority')}
          onAssigneeChange={this.onChange('assigneeId')}
          showAssignee={!!priority && priority > 2}
          createDisabled={
            priority === null ||
            (!!priority && priority > 2 && assigneeId === null)
          }
          onCreate={this.createIntervention}
          onClose={this.closePriority}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  user: state.auth.user,
  users: state.user.users,
  countries: state.country.countries,
  reportedFaults: state.reportedFault.reportedFaults,
}))(withRouter(NewInterventionContainer));
