import React from 'react';

// Containers
import ProductTypesContainer from '../../../productType/containers/ProductTypesContainer/ProductTypesContainer';

const ProductAdminPage = ({
  match: {
    params: {manufacturerID, productID},
  },
}) => (
  <ProductTypesContainer
    manufacturerID={manufacturerID}
    productID={productID}
  />
);

export default ProductAdminPage;
