import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

// Components
import {SimpleTable, Button, Row, Column} from 'doorson-ui';
import PageContent from '../../../layout/components/PageContent/PageContent';
import DataBlock from '../../../layout/components/DataBlock/DataBlock';
import ButtonContainer from './components/ButtonContainer';

const MaintenanceCustomerInfo = ({
  title,
  registrationNumberLabel,
  firstNameLabel,
  lastNameLabel,
  companyLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  phoneLabel,
  emailLabel,
  nextLabel,

  type,
  registrationNumber,
  firstName,
  lastName,
  company,
  address,
  zip,
  city,
  phone,
  email,

  nextUrl,
}) => (
  <PageContent>
    <Row>
      <Column size={1 / 3}>
        <DataBlock label={title}>
          <SimpleTable
            values={[
              type === 'business'
                ? {
                    key: registrationNumberLabel,
                    value: registrationNumber,
                  }
                : {
                    key: firstNameLabel,
                    value: firstName,
                  },
              type === 'business'
                ? {
                    key: companyLabel,
                    value: company,
                  }
                : {
                    key: lastNameLabel,
                    value: lastName,
                  },
              {
                key: addressLabel,
                value: address,
              },
              {
                key: zipLabel,
                value: zip,
              },
              {
                key: cityLabel,
                value: city,
              },
              {
                key: phoneLabel,
                value: phone,
              },
              {
                key: emailLabel,
                value: email || '',
              },
            ]}
          />
        </DataBlock>
      </Column>
    </Row>
    <ButtonContainer>
      <Link to={nextUrl}>
        <Button theme="orange">{nextLabel}</Button>
      </Link>
    </ButtonContainer>
  </PageContent>
);

MaintenanceCustomerInfo.propTypes = {
  title: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  registrationNumberLabel: PropTypes.node,
  companyLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,
  nextLabel: PropTypes.node,

  type: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  registrationNumber: PropTypes.string,
  company: PropTypes.string,
  address: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,

  nextUrl: PropTypes.string,
};

export default MaintenanceCustomerInfo;
