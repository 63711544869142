import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  float: left;
  width: 175px;
  height: 90px;
  padding: 15px 0;
  margin: 5px 10px;
  user-select: none;
`;

export default Container;
