import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Roles
import {SERVICE_TECHNICIAN, CHIEF_SERVICE_TECHNICIAN, SYSTEM} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

// Components
import DoorInfo from '../../components/DoorInfo/DoorInfo';
import {v4} from "uuid";
import {getTime} from "date-fns";
import DoorEdit from "../../components/DoorEdit/DoorEdit";
import createApi from '../../api/create.api.interventionDoor';
import deleteInterventionDoorApi from '../../api/delete.api.interventionDoor';
import isResolved from '../../../intervention/lib/isResolved.lib.intervention';
import isClosed from '../../../intervention/lib/isClosed.lib.intervention';
import isAssigned from '../../../intervention/lib/isAssigned.lib.intervention';
import {alertify} from "doorson-ui";
import {updateIntervention} from "../../../intervention/redux/actions";

class DoorInfoContainer extends Component {
  static propTypes = {
    intervention: PropTypes.object,
    dispatch: PropTypes.func,
    user: PropTypes.object,
  };

  state = {
    door: null,
    interventionDoor: null,
  };

  static newInterventionDoor = (id = v4()) => ({
    id,
    time: getTime(new Date()),
    error: {},
    givenDescription: '',
    indoorLocation: '',
    door: null,
    doors: [],
  });

  componentDidMount() {
    this.mounted = true;
    const door = [...this.props.intervention.doors][0];
    this.setState({door});
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onDoor = (door) => this.setState({door});

  onDelete = async (door) => {
    const {dispatch} = this.props;
    const {door: selectedDoor} = this.state;
    try {
      this.setState({loading: true})
      await deleteInterventionDoorApi(door.id)
      const newDoors = [...this.props.intervention.doors].filter(d => d.id !== door.id);
      if (selectedDoor.id === door.id) {
        this.setState({door: newDoors[0]});
      }
      dispatch(updateIntervention({
        ...this.props.intervention,
        doors: newDoors
      }));
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({loading: false})
    }
  }

  addDoor = () => {
    const {loading} = this.state;
    const {intervention} = this.props;
    if (loading) return;

    if (isResolved(intervention) || isClosed(intervention)) {
      alertify.error('Cannot add more items to resolved intervention');
      return;
    }

    const interventionDoor = this.constructor.newInterventionDoor();
    this.setState({interventionDoor});
  };

  onDoorChange = (key) => (value) => {
    const {loading, interventionDoor} = this.state;

    if (loading || !this.mounted) return;
    this.setState({
      interventionDoor: {
        ...interventionDoor,
        [key]: value,
        error: Object.entries(interventionDoor.error).reduce(
          (combined, [errorKey, value]) =>
            key === errorKey ? combined : {...combined, [errorKey]: value},
          {}
        ),
      },
    });
  };

  onDoorImageAdd = (door) => {
    if (!this.mounted) return;
    const {intervention, dispatch} = this.props;
    dispatch(updateIntervention({
      ...intervention,
      doors: [...intervention.doors].map(id => id.door.id === door.id ? ({...id, door}) : id)
    }));
  };

  checkDoor = (door) => {
    // TODO: I18N
    const error = {};

    if (!door.givenDescription.trim().length)
      error.givenDescription = 'Should not be empty';
    if (!door.indoorLocation.trim().length)
      error.indoorLocation = 'Should not be empty';

    return {...door, error};
  };

  createDoor = async () => {
    // TODO: I18N
    const {intervention: {id}, dispatch} = this.props;
    const {interventionDoor, loading} = this.state;

    if (loading) return;

    const newInterventionDoor = this.checkDoor(interventionDoor);
    const hasError = !!Object.keys(newInterventionDoor.error).length;
    if (hasError) {
      this.setState({interventionDoor: newInterventionDoor})
      return alertify.warning('Check all the inserted door data');
    }
    this.setState({loading: true})
    try {
      const newInterventionDoor = await createApi(
        id,
        interventionDoor
      );

      this.setState({interventionDoor: null, door: newInterventionDoor})
      dispatch(updateIntervention({
        ...this.props.intervention,
        doors: [...this.props.intervention.doors, newInterventionDoor]
      }));
    } finally {
      this.setState({loading: false});
    }
  };

  removeDoor = () => {
    const door = [...this.props.intervention.doors][0];
    this.setState({interventionDoor: null, door})
  }

  render() {
    const {intervention, user} = this.props;
    const {door, interventionDoor, loading} = this.state;
    return !door && !interventionDoor ? null :
      (!!interventionDoor ?
        (<DoorEdit
          loading={loading}
          doors={[...intervention.doors, interventionDoor]}
          door={interventionDoor}
          givenDescriptionLabel="Given fault description"
          indoorLocationLabel="Door location"
          removeDoorLabel="Remove door"
          buttonLabel="Add door"
          onNewDoor={this.addDoor}
          onDoor={this.onDoor}
          onChange={this.onDoorChange}
          onConfirm={this.createDoor}
          onRemove={this.removeDoor}
        />)
        : (
          <DoorInfo
            door={door}
            doors={intervention.doors}
            onDoor={this.onDoor}
            onDelete={this.onDelete}
            canAddPictures={isAssigned(user, intervention)}
            onDoorChange={this.onDoorImageAdd}
            canDelete={!isResolved(intervention) && !isClosed(intervention) && intervention.doors.length > 1 && hasPermission({
              user,
              roles: [isAssigned(user, intervention) ? SERVICE_TECHNICIAN : null, CHIEF_SERVICE_TECHNICIAN, SYSTEM].filter(t => !!t)
            })}
            onNewDoor={this.addDoor}
            givenDescriptionLabel="Given fault description"
            manufacturerLabel="Manufacturer"
            doorVersionLabel="Door version"
            doorTypeLabel="Door type"
            redundancyLabel="Redundancy"
            indoorLocationLabel="Door location"
            buildingFloorLabel="Building floor"
          />
        ));
  }
}

export default DoorInfoContainer;
