import {isDate, parse, format as formatDate} from 'date-fns';

import type from '../type';

const format = (value) => {
  if (!value) return '';
  const dateObj = isDate(value) ? value : parse(value);
  return formatDate(dateObj, 'yyyy-MM-ddTHH:mm:ssZ');
};

const validate = () => true;

export default type({type: 'date', format, validate});
