// TODO: I18n
const statuses = () => [
  {value: 'DRAFT', label: 'Draft'},
  {value: 'REQUESTED', label: 'Requested'},
  {value: 'PICK_PACK', label: 'Pick & Pack'},
  {value: 'REPLENISHMENT', label: 'Replenishment (external order)'},
  {value: 'READY_TO_COLLECT', label: 'Ready to collect'},
  {value: 'IN_TRANSIT', label: 'In transit (Carrier)'},
  {value: 'COMPLETED', label: 'Completed'},
  {value: 'REJECTED', label: 'Rejected by acceptor'},
  {value: 'CANCELED', label: 'Canceled by requester'},
  {value: 'TRANSFER', label: 'Transfer'},
]
export default statuses;
