import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {SimpleTable, Row, Column, CheckBox} from 'doorson-ui';
import PageContent from '../../../layout/components/PageContent/PageContent';
import DataBlock from '../../../layout/components/DataBlock/DataBlock';
import ShowOrEdit from '../../components/ShowOrEdit/ShowOrEdit';
import UploadContainer from './components/UploadContainer';

// Containers
import DoorImageContainer from '../../../door/containers/DoorImageContainer/DoorImageContainer';
import doorIconBlack from "../../../interventionDoor/assets/doorIconBlack.png";
import styled from "styled-components";
import {Link as LinkDom} from "react-router-dom";

const Link = styled(LinkDom)`
  display: inline-flex;
  padding-left: 12px;
`;

const Image = styled.img`
  width: 23px;
  display: block;
`;

const DoorInfo = ({
  title,
  manufacturerLabel,
  doorTypeLabel,
  manufactureDateLabel,
  installationDateLabel,
  doorLocationLabel,
  lastMaintenanceDateLabel,
  warrantyDateLabel,
  doorSerialLabel,
  electronicsSerialLabel,
  motorSerialLabel,
  redundancyLabel,
  leafInfoLabel,

  manufacturer,
  serviceItemUrl,
  doorType,
  manufactureDate,
  installationDate,
  doorLocation,
  lastMaintenanceDate,
  warrantyDate,
  doorSerial,
  electronicsSerial,
  motorSerial,
  redundancy,
  leafInfo,

  door,
  onDoorChange,

  editable,
  onEdit,
  onInlineSave,
}) => (
  <PageContent>
    <Row>
      <Column size={1 / 2}>
        <DataBlock label={title}>
          <SimpleTable
            large
            values={[
              {
                key: manufacturerLabel,
                value: <Fragment>{manufacturer}<Link to={serviceItemUrl}>
                  <Image src={doorIconBlack}/>
                </Link></Fragment>,
              },
              {
                key: doorTypeLabel,
                value: doorType,
              },
              {
                key: manufactureDateLabel,
                value: (
                  <ShowOrEdit
                    editable={editable}
                    value={manufactureDate}
                    onEdit={onEdit('manufactureDate', manufactureDateLabel)}
                  />
                ),
              },
              {
                key: installationDateLabel,
                value: (
                  <ShowOrEdit
                    editable={editable}
                    value={installationDate}
                    onEdit={onEdit('installationDate', installationDateLabel)}
                  />
                ),
              },
              {
                key: doorLocationLabel,
                value: doorLocation,
              },
              {
                key: lastMaintenanceDateLabel,
                value: lastMaintenanceDate || '',
              },
              {
                key: warrantyDateLabel,
                value: (
                  <ShowOrEdit
                    editable={editable}
                    value={warrantyDate}
                    onEdit={onEdit('warrantyValidTillDate', warrantyDateLabel)}
                  />
                ),
              },
              {
                key: doorSerialLabel,
                value: (
                  <ShowOrEdit
                    editable={editable}
                    value={doorSerial}
                    onEdit={onEdit('doorSerial', doorSerialLabel)}
                  />
                ),
              },
              {
                key: electronicsSerialLabel,
                value: (
                  <ShowOrEdit
                    editable={editable}
                    value={electronicsSerial}
                    onEdit={onEdit('electronicsSerial', electronicsSerialLabel)}
                  />
                ),
              },
              {
                key: motorSerialLabel,
                value: (
                  <ShowOrEdit
                    editable={editable}
                    value={motorSerial}
                    onEdit={onEdit('motorSerial', motorSerialLabel)}
                  />
                ),
              },
              {
                key: leafInfoLabel,
                value: (
                  <ShowOrEdit
                    editable={editable}
                    value={leafInfo}
                    onEdit={onEdit('leafInfo', leafInfoLabel)}
                  />
                ),
              },
              {
                key: redundancyLabel,
                value: (
                  <CheckBox selected={redundancy} onSelect={onInlineSave('redundancy')} />
                ),
              },
            ]}
          />
        </DataBlock>
      </Column>
      <Column size={1 / 2}>
        <UploadContainer>
          <DoorImageContainer canAddPictures={editable} door={door} onChange={onDoorChange} />
        </UploadContainer>
      </Column>
    </Row>
  </PageContent>
);

DoorInfo.propTypes = {
  title: PropTypes.node,
  manufacturerLabel: PropTypes.node,
  doorTypeLabel: PropTypes.node,
  manufactureDateLabel: PropTypes.node,
  installationDateLabel: PropTypes.node,
  doorLocationLabel: PropTypes.node,
  lastMaintenanceDateLabel: PropTypes.node,
  warrantyDateLabel: PropTypes.node,
  doorSerialLabel: PropTypes.node,
  electronicsSerialLabel: PropTypes.node,
  motorSerialLabel: PropTypes.node,
  leafInfoLabel: PropTypes.node,

  manufacturer: PropTypes.string,
  serviceItemUrl: PropTypes.string,
  doorType: PropTypes.string,
  manufactureDate: PropTypes.string,
  installationDate: PropTypes.string,
  doorLocation: PropTypes.string,
  lastMaintenanceDate: PropTypes.string,
  warrantyDate: PropTypes.string,
  doorSerial: PropTypes.string,
  electronicsSerial: PropTypes.string,
  motorSerial: PropTypes.string,
  leafInfo: PropTypes.string,

  door: PropTypes.object,
  onDoorChange: PropTypes.func,

  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  onInlineSave: PropTypes.func,
};

export default DoorInfo;
