import {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Libs
import isWorklogComplete from '../../../interventionDoor/lib/isWorklogComplete.lib.interventionDoor';

// Paths
import assignRoute from '../../pages/InterventionAssignPage/route';
import workLogRoute from '../../pages/InterventionWorkLogPage/route';
import confirmationRoute from '../../pages/InterventionConfirmationPage/route';

class SpecifyInterventionSubpageContainer extends Component {
  static propTypes = {
    intervention: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
  };

  componentDidMount() {
    const {
      history,
      match: {
        params: {id},
      },
    } = this.props;
    const route = this.getRoute();
    history.replace(route(id));
  }

  getRoute = () => {
    const {intervention} = this.props;
    if (!intervention.assignees.length) return assignRoute;
    if (!isWorklogComplete(intervention)) return workLogRoute;
    return confirmationRoute;
  };

  render() {
    return null;
  }
}

export default connect((state) => ({
  intervention: state.intervention.intervention,
}))(withRouter(SpecifyInterventionSubpageContainer));
