import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

// Components

const Container = styled.div`
  position: relative;
  width: 50%;
  float: left;
  text-align: right;
  padding: 5px 0;
  font-size: 20px;
  font-weight: 800;
  color: rgba(0,0,0,0.8);
  user-select: none;
`;

const Blue = styled.div`
  color: rgba(83, 176, 203, 1);
  padding-right: 10px;
  display: inline;
`;

const Orange = styled.div`
  color: rgba(245, 131, 32, 1);
  padding-left: 10px;
  padding-right: 10px;
  display: inline;
`;

const Green = styled.div`
  color: rgba(112, 181, 0, 1);
  padding-left: 10px;
  display: inline;
`;

const ChartValueContainer = ({value1, value2, value3}) => (
  <Container>
    <Blue>{value1}</Blue>|<Orange>{value2}</Orange>|<Green>{value3}</Green>
  </Container>
);

ChartValueContainer.propTypes = {
  children: PropTypes.node,
};

export default ChartValueContainer;
