import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const listUsersApi = (query = {}) =>
  f3tch('/users')
    .profile(profile())
    .query(query)
    .get();
export default listUsersApi;
