import styled from 'styled-components';

const Input = styled.input`
  width: 0px;
  height: 0px;
  visibility: hidden;
  border: none;
  background: none;
  padding: 0px;
  margin: 0px;
`;

export default Input;
