import styled from 'styled-components';

const DayLabelContainer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  top: 1px;
  left: 0px;
  z-index: 50;
  padding-left: 80px;
`;

export default DayLabelContainer;
