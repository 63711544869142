import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import fileIcon from '../../../lib/fileIcon.lib.file';

// Components
const Picture = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

const Icon = styled.div`
  width: 100%;
  height: 100%;
  font-size: 100px;
  display: inline-block;
  opacity: 0.3;
`;

const Image = ({src, type}) =>
  !!type && type.indexOf("image/") !== -1 ? <Picture src={src}/> :
    <Icon className={fileIcon(type)}/>;

Image.propTypes = {
  src: PropTypes.string,
  type: PropTypes.string,
};

export default Image;
