import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import ServiceItemsContainer from '../../containers/ServiceItemsContainer/ServiceItemsContainer';

// Consts
import page from './page';

const ServiceItemsPage = () => (
  <Page page={page}>
    <ServiceItemsContainer />
  </Page>
);

export default ServiceItemsPage;
