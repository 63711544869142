import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// VehiclesAdminPage
import VehiclesAdminPage from '../VehiclesAdminPage/VehiclesAdminPage';
import routeVehiclesAdminPage from '../VehiclesAdminPage/route';

// Consts
import page from './page';

const VehiclesAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeVehiclesAdminPage()}
        component={VehiclesAdminPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default VehiclesAdminPages;
