import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const Heading = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  color: ${orange};
  text-transform: uppercase;
  margin-bottom: 25px;
`;

export default Heading;
