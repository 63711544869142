import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateTransportLocationApi = (id, transportLocation) =>
  f3tch(`/transport-locations/${id}`)
    .profile(profile())
    .body(transportLocation)
    .put();
export default updateTransportLocationApi;
