import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

const MapLoader = ({visible}) => (
  <Container visible={visible}>
    <i className="mdi mdi-loading mdi-spin" />
  </Container>
);

MapLoader.propTypes = {
  visible: PropTypes.bool,
};

export default MapLoader;
