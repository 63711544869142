import React from 'react';
import PropTypes from 'prop-types';

// Components
// Colors
import {Table} from 'doorson-ui';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import Container from './components/Container';
import Icon from "./components/Icon";


const WarehouseOrderItems = ({editEnabled, columns, sort, orderItems, onItemDelete, onSort}) => (
  <Container>
    <Table columns={columns} sort={sort} theme="grey" size='large' onSort={onSort}>
      {(TableRow) =>
        orderItems.map((item) => (
          <TableRow theme="white" fontSize="12px" key={item.id}>
            {(TableCol) => [
              <TableCol smallPadding span={2} key="sku">
                {item.sku}
              </TableCol>,
              <TableCol smallPadding span={4} key="name">
                {item.name}
              </TableCol>,
              <TableCol smallPadding key="qty">
                {item.qty}
              </TableCol>,
              <TableCol smallPadding span={2} key="status">
                {item.status}
              </TableCol>,
              <TableCol smallPadding span={4} key="senderComment">
                {item.senderComment}
              </TableCol>,
              <TableCol smallPadding key="action">
                {editEnabled && <Icon className="mdi mdi-trash-can-outline" onClick={onItemDelete(item)}/>}
              </TableCol>
            ]}
          </TableRow>
        ))
      }
    </Table>
    {!orderItems.length && <NoDataFound>Order has no items</NoDataFound>}
  </Container>
);

WarehouseOrderItems.propTypes = {
  editEnabled: PropTypes.bool,
  sort: PropTypes.object,
  onItemDelete: PropTypes.func,
  onSort: PropTypes.func,
  columns: PropTypes.array,
  saveLabel: PropTypes.string,
  orderItems: PropTypes.array,
  onChange: PropTypes.func,
};

export default WarehouseOrderItems;
