import styled, {css} from 'styled-components';

// animation
import {transitions} from 'polished';

// colors
import {white} from 'doorson-ui/lib/style/colors';

// constants
const PADDING = '5px';

// helpers
const setHorizontalPosition = ({horizontalPosition}) => {
  const positions = {
    left: css`
      right: calc(100% + ${PADDING});
    `,
    center: css`
      left: 50%;
      transform: translateX(-50%);
    `,
    right: css`
      left: calc(20% + ${PADDING});
    `,
  };
  return positions[horizontalPosition] || positions.right;
};

const setVerticalPosition = ({verticalPosition}) => {
  const positions = {
    top: css`
      bottom: calc(100% + ${PADDING});
    `,
    center: css`
      top: 50%;
      transform: translateY(-50%);
    `,
    bottom: css`
      top: calc(100% + ${PADDING});
    `,
  };
  return positions[verticalPosition] || positions.center;
};

const setTrianglePosition = ({horizontalPosition, verticalPosition}) => {
  if (verticalPosition === 'top' && horizontalPosition === 'right') {
    return css`
      bottom: -5px;
      left: 7px;
      border-width: 5px 5px 0;
      border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    `;
  }

  if (verticalPosition === 'top' && horizontalPosition === 'left') {
    return css`
      bottom: -5px;
      right: 7px;
      border-width: 5px 5px 0;
      border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    `;
  }

  if (verticalPosition === 'bottom' && horizontalPosition === 'right') {
    return css`
      top: -5px;
      left: 10px;
      border-width: 0 5px 5px;
      border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
    `;
  }

  if (verticalPosition === 'bottom' && horizontalPosition === 'left') {
    return css`
      top: -5px;
      right: 10px;
      border-width: 0 5px 5px;
      border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
    `;
  }

  if (verticalPosition === 'bottom') {
    return css`
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
    `;
  }

  if (verticalPosition === 'top') {
    return css`
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px 5px 0 5px;
      border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    `;
  }
};

const Popup = styled.div`
  width: ${({width}) => width};
  max-width: ${({maxWidth}) => maxWidth};
  word-wrap: break-word;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: ${white};
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  user-select: none;
  cursor: default;
  ${({visible}) =>
    visible
      ? css`
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        `}
  ${setHorizontalPosition}
  ${setVerticalPosition};
  ${({horizontalPosition, verticalPosition}) =>
    [horizontalPosition, verticalPosition].every(
      (position) => position === 'center'
    )
      ? css`
          transform: translate(-50%, -50%);
        `
      : ''};
  ${transitions('visibility .2s ease-in-out, opacity .2s ease-in-out')}

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    ${setTrianglePosition}
  }
`;

export default Popup;
