import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Alerts
import {alertify} from 'doorson-ui';

// Actions
import {updateMaintenance as updateMaintenanceAct} from '../../redux/actions';

// Api
import deleteApi from '../../api/delete.api.maintenance';

// Components
import MaintenanceActions from '../../components/MaintenanceActions/MaintenanceActions';
import DeleteMaintenanceModal from '../../components/DeleteMaintenanceModal/DeleteMaintenanceModal';

// Routes
import maintenanceRoute from '../../pages/MaintenancePage/route';
import maintenancesRoute from '../../pages/MaintenancesPage/route';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SYSTEM} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

class MaintenanceActionsContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    inited: false,
    deleting: false,
    deleteConfirmationVisible: false,
  };

  componentDidMount() {
    const {maintenance, user, history} = this.props;
    if (
      !hasPermission({
        user,
        roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
      })
    )
      return history.replace(maintenanceRoute(maintenance.id));

    this.setState({inited: true});
  }

  syncMaintenance = ({...data} = {}) => {
    const {maintenance, dispatch} = this.props;
    dispatch(updateMaintenanceAct({...maintenance, ...data}));
  };

  delete = async () => {
    // TODO: I18n
    const {maintenance, history} = this.props;
    const {deleting} = this.state;

    if (deleting) return;

    try {
      await deleteApi(maintenance.id);
      history.push(maintenancesRoute());
      alertify.success('Maintenance deleted');
    } catch (error) {
      alertify.error('Maintenance could not be deleted');
      this.setState({deleting: false});
    }
  };

  showDeleteConfirm = () => {
    if (this.state.deleting) return;
    this.setState({deleteConfirmationVisible: true});
  };

  hideDeleteConfirm = () => {
    if (this.state.deleting) return;
    this.setState({deleteConfirmationVisible: false});
  };

  render() {
    // TODO: I18n
    const {inited, deleting, deleteConfirmationVisible} = this.state;
    return !inited ? null : (
      <Fragment>
        <MaintenanceActions
          deleteLabel="Delete Maintenance"
          onDelete={this.showDeleteConfirm}
        />
        <DeleteMaintenanceModal
          loading={deleting}
          visible={deleteConfirmationVisible}
          title="Delete Maintenance"
          deleteLabel="Delete"
          onDelete={this.delete}
          onClose={this.hideDeleteConfirm}
        >
          Are you sure you want to delete this maintenance?
        </DeleteMaintenanceModal>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
  user: state.auth.user,
}))(withRouter(MaintenanceActionsContainer));
