import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white, charcoal} = colors;

const Heading = styled.div`
  width: 100%;
  margin-bottom: 4px;
  background: ${white};
  padding: 10px 15px;
  color: ${charcoal};
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;

export default Heading;
