import React from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox, Table} from 'doorson-ui';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import Container from './components/Container';
import Icon from './components/Icon';

const AdminVehicles = ({columns, sort, vehicles, onUpdate, onSort}) => (
  <Container>
    <Table columns={columns} sort={sort} onSort={onSort}>
      {(TableRow) =>
        vehicles.map((vehicle) => (
          <TableRow key={vehicle.id}>
            {(TableCol) => [
              <TableCol smallPadding key="make">
                {vehicle.make}
              </TableCol>,
              <TableCol smallPadding key="model">
                {vehicle.model}
              </TableCol>,
              <TableCol smallPadding key="licensePlate">
                {vehicle.licensePlate}
              </TableCol>,
              <TableCol smallPadding key="label">
                {vehicle.label}
              </TableCol>,
              <TableCol smallPadding key="warehouse">
                {vehicle.warehouse}
              </TableCol>,
              <TableCol smallPadding key="disabled">
                <CheckBox selected={vehicle.disabled} onSelect={() => null}/>
              </TableCol>,
              <TableCol smallPadding key="actions">
                <Icon
                  className="mdi mdi-circle-edit-outline"
                  onClick={onUpdate(vehicle)}
                />
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
    {!vehicles.length && <NoDataFound>No vehicles found</NoDataFound>}
  </Container>
);

AdminVehicles.propTypes = {
  columns: PropTypes.array,
  vehicles: PropTypes.array,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AdminVehicles;
