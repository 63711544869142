import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Content = styled.div`
  background: ${white};
  height: 38px;
  pdding: 0px 25px;
  display: flex;
  align-items: center;
`;

export default Content;
