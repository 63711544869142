import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import MaintenanceResolveContainer from '../../containers/MaintenanceResolveContainer/MaintenanceResolveContainer';

// TODO: I18n
const MaintenanceResolvePage = ({maintenance, match}) => (
  <SubPage page={match.path}>
    <MaintenanceResolveContainer maintenance={maintenance} />
  </SubPage>
);

MaintenanceResolvePage.propTypes = {
  maintenance: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(
  connect((state) => ({
    maintenance: state.maintenance.maintenance,
  }))(MaintenanceResolvePage)
);
