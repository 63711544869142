import styled from 'styled-components';

const ChecksHeader = styled.div`
  width: 100%;
  padding: 50px 34px 20px 34px;
  text-transform: uppercase;
  font-weight: 700;
`;

export default ChecksHeader;
