import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Components
import Container from './components/Container';
import Icon from './components/Icon';
import Input from './components/Input';

// Libs
import wait from '../../../lib/wait';

class Search extends Component {
  static propTypes = {
    navigationOpen: PropTypes.bool,
    search: PropTypes.string,
    onSearch: PropTypes.func,
    treshold: PropTypes.number,
  };

  static defaultProps = {
    treshold: 200,
  };

  state = {
    value: this.props.search,
  };

  onChange = async (newValue) => {
    const {treshold, onSearch} = this.props;
    await this.setState({value: newValue});
    await wait(treshold);
    if (this.state.value === newValue) onSearch(newValue);
  };

  render() {
    const {navigationOpen} = this.props;
    const {value} = this.state;
    return (
      <Container open={navigationOpen}>
        <Icon className="mdi mdi-magnify" />
        <Input
          value={value}
          onChange={(e) => this.onChange(e.target.value)}
          placeholder="Search"
        />
      </Container>
    );
  }
}

export default connect((state) => ({
  navigationOpen: state.layout.navigationOpen,
}))(Search);
