import styled from 'styled-components';
import {Link as LinkDom} from 'react-router-dom';

const Link = styled(LinkDom)`
  width: 128px;
  display: block;
  padding-bottom: 20px;
`;

export default Link;
