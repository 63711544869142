import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Container, Row, Column} from 'doorson-ui';
import {Tabs} from 'doorson-ui';
import Content from './components/Content';
import Addon from './components/Addon';

const AdminPages = ({children, page, pages, onPage}) => (
  <Container>
    <Row>
      <Column>
        <Content>
          <Tabs tab={page} tabs={pages} onTab={onPage} showScrollbar />
          <Addon>{children}</Addon>
        </Content>
      </Column>
    </Row>
  </Container>
);

AdminPages.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
  pages: PropTypes.array,
  onPage: PropTypes.func,
};

export default AdminPages;
