import React from 'react';

// Components
import Container from './components/Container';
import {Line} from "react-chartjs-2";
import ChartTitle from "./components/ChartTitle";
import ChartValueContainer from "./components/ChartValueContainer";
import LineContainer from "./components/LineContainer";
import DescriptionContainer from "./components/DescriptionContainer";
import PropTypes from "prop-types";

const StatisticsTile = ({
                          title,
                          value,
                          chartData,
                        }) =>
  <Container>
    <DescriptionContainer>
      <ChartTitle>{title}</ChartTitle>
      <ChartValueContainer>{value}</ChartValueContainer>
    </DescriptionContainer>
    <LineContainer>
      <Line data={chartData}
            options={{
              bezierCurve: true,
              legend: {
                display: false
              },
              elements: {
                point: {
                  radius: 1
                }
              },
              scales: {
                xAxes: [{
                  ticks: {
                    display: false
                  },
                  gridLines: {
                    display: false
                  }
                }],
                yAxes: [{
                  ticks: {
                    fontColor: 'rgba(0,0,0,0.4)',
                    callback: function (value, index, values) {
                      return value === values[0] || (!!values.length && value === values[values.length - 1]) ? value : '';
                    }
                  },
                  gridLines: {
                    display: false
                  }
                }]
              }
            }}/>
    </LineContainer>
  </Container>
;

StatisticsTile.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  chartData: PropTypes.object,
};

export default StatisticsTile;
