import authToken from '../../auth/lib/token.lib.auth';

const jsonHeader =  ({authorize = true} = {}) => {
  const token = authToken.get();
  const h = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (!!token && authorize) h.authorization = `Bearer ${token}`;
  return h;
};
export default jsonHeader;
