const deviceLabel = (device, initials = true) => {
  //if (!!device.vehicle) return device.vehicle.label;
  if (device.lastUser) {
    const {firstName, lastName} = device.lastUser;
    if (firstName || lastName)
      return (firstName ? firstName[0] : "") + (lastName && lastName.length > 1 ? lastName[0] + lastName[1] : "");
  }
  return "?";
};
export default deviceLabel;
