import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateMaintenanceDoorApi = (id, maintenanceDoor, query = {}) =>
  f3tch(`/maintenance-doors/${id}`)
    .profile(profile())
    .query(query)
    .body(maintenanceDoor)
    .put();
export default updateMaintenanceDoorApi;
