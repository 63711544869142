import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

const Layout = ({navigationOpen, children}) => (
  <Container open={navigationOpen}>{children}</Container>
);

Layout.propTypes = {
  navigationOpen: PropTypes.bool,
  children: PropTypes.node,
};

export default Layout;
