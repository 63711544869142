const query = ({order = null, ...query} = {}) => {
  const actualQuery = Object.entries(query)
    .filter(
      ([key, value]) =>
        value !== undefined && value !== null && !!`${value}`.trim().length
    )
    .reduce((combined, [key, value]) => ({...combined, [key]: value}), {});
  if (!!order && Array.isArray(order))
    actualQuery.order = [...order].map(o => `${o.key} ${o.direction}`).join(',');
  else if (!!order) actualQuery.order = `${order.key} ${order.direction}`;
  return actualQuery;
};
export default query;
