import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// Components
import Container from './components/Container';
import Table from './components/Table';
import Column from './components/Column';
import Content from './components/Content';
import Header from './components/Header';
import Text from './components/Text';

class DeviceQuickView extends Component {
  static propTypes = {
    device: PropTypes.object,
    loadingLocation: PropTypes.bool,
    location: PropTypes.string,
    onClose: PropTypes.func,
  };

  handleClickOutside = () => this.props.onClose();

  render() {
    const {device, loadingLocation, location} = this.props;
    return (
      <Container>
        <Table>
          <Column>
            <Content>
              <Header>Technician</Header>
              <Text>
                {device.lastUser.firstName} {device.lastUser.lastName}
              </Text>
            </Content>
          </Column>
          <Column>
            <Content>
              <Header>Device</Header>
              <Text>{device.name}</Text>
            </Content>
          </Column>
          <Column>
            <Content>
              <Header>Vehicle</Header>
              <Text>{!!device.vehicle ? device.vehicle.label : '-'}</Text>
            </Content>
          </Column>
          <Column>
            <Content>
              <Header>Licence Plate</Header>
              <Text>
                {!!device.vehicle ? device.vehicle.licensePlate : '-'}
              </Text>
            </Content>
          </Column>
          <Column>
            <Content>
              <Header>Location</Header>
              <Text>{loadingLocation ? 'Loading..' : location || '-'}</Text>
            </Content>
          </Column>
        </Table>
      </Container>
    );
  }
}

export default onClickOutside(DeviceQuickView);
