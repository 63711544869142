import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';

const {green} = colors;

const Percent = styled.span`
    display: block;
    color: ${green};
    cursor: pointer;
    position: relative;
    top: -22px;
    left: 0px;
    text-align: center;
    font-size: 10px;
`;

export default Percent;
