import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import Container from './components/Container';
import Icon from './components/Icon';

const AdminRepairs = ({columns, sort, repairs, onUpdate, onDelete, onSort}) => (
  <Container>
    <Table columns={columns} sort={sort} onSort={onSort}>
      {(TableRow) =>
        repairs.map((repair) => (
          <TableRow key={repair.id}>
            {(TableCol) => [
              <TableCol smallPadding key="description" span={3}>
                {repair.description}
              </TableCol>,
              <TableCol smallPadding key="actions">
                <Icon
                  className="mdi mdi-circle-edit-outline"
                  onClick={onUpdate(repair)}
                />
                <Icon
                  className="mdi mdi-delete"
                  onClick={onDelete(repair.id)}
                />
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
    {!repairs.length && <NoDataFound>No repairs found</NoDataFound>}
  </Container>
);

AdminRepairs.propTypes = {
  columns: PropTypes.array,
  repairs: PropTypes.array,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AdminRepairs;
