import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Api
import closeApi from '../../api/close.api.intervention';

// Alertify
import {alertify} from 'doorson-ui';

// Components
import Close from '../../components/Close/Close';

class CloseInterventionContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    intervention: PropTypes.object,
    onClose: PropTypes.func,
    onDone: PropTypes.func,
  };

  state = {
    loading: false,
    doors: [],
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    const doors = [...this.props.intervention.doors].map((door) => ({
      ...door,
      has_guarantee: false,
      materials: [...door.materials].map((material) => ({
        ...material,
        check: !!material.material,
      })),
    }));
    this.setState({doors});
  };

  onChange = (doorID) => (has_guarantee) => {
    const {loading, doors} = this.state;
    if (loading) return;
    this.setState({
      doors: [...doors].map((door) =>
        doorID === door.id ? {...door, has_guarantee} : door
      ),
    });
  };

  onDerecognize = (doorID, materialID) => (check) => {
    const {loading, doors} = this.state;
    if (loading) return;
    this.setState({
      doors: [...doors].map((door) =>
        doorID === door.id
          ? {
              ...door,
              materials: [...door.materials].map((material) =>
                material.id === materialID ? {...material, check} : material
              ),
            }
          : door
      ),
    });
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  onSave = async () => {
    // TODO: I18n
    const {loading, doors: rawDoors} = this.state;
    if (loading) return;

    this.setState({loading: true});

    const doors = [...rawDoors].map(({id, has_guarantee, materials}) => ({
      doorId: id,
      hasGuarantee: has_guarantee,
      bookInterventionDoorMaterialIds: [...materials]
        .filter(({check}) => check)
        .map(({id}) => id),
    }));

    try {
      const intervention = await closeApi(this.props.intervention.id, doors);
      this.props.onDone(intervention);
    } catch (error) {
      alertify.error('Could not close intervention');
    }

    this.setState({loading: false});
  };

  render() {
    // TODO: I18n
    const {visible} = this.props;
    const {doors, loading} = this.state;
    return (
      <Close
        title="Close Intervention"
        visible={visible}
        loading={loading}
        saveLabel="Close"
        hasGuaranteeLabel="Invoice included"
        doors={doors}
        onChange={this.onChange}
        onDerecognize={this.onDerecognize}
        onClose={this.onClose}
        onSave={this.onSave}
      >
        Specify which doors have guarantee
      </Close>
    );
  }
}

export default CloseInterventionContainer;
