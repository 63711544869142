import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import NoDataFound from '../../../layout/components/NoDataFound/NoDataFound';
import Container from './components/Container';
import Icon from './components/Icon';

// TODO: Has guarantee
const AdminMaterials = ({
  columns,
  sort,
  materials,
  onUpdate,
  onDelete,
  onSort,
}) => (
  <Container>
    <Table columns={columns} sort={sort} onSort={onSort}>
      {(TableRow) =>
        materials.map((material) => (
          <TableRow key={material.id}>
            {(TableCol) => [
              <TableCol smallPadding key="code">
                {material.code}
              </TableCol>,
              <TableCol smallPadding key="name" span={2}>
                {material.name}
              </TableCol>,
              <TableCol smallPadding key="unit">
                {material.unit}
              </TableCol>,
              <TableCol smallPadding key="actions">
                <Icon
                  className="mdi mdi-circle-edit-outline"
                  onClick={onUpdate(material)}
                />
                <Icon
                  className="mdi mdi-delete"
                  onClick={onDelete(material.id)}
                />
              </TableCol>,
            ]}
          </TableRow>
        ))
      }
    </Table>
    {!materials.length && <NoDataFound>No materials found</NoDataFound>}
  </Container>
);

AdminMaterials.propTypes = {
  columns: PropTypes.array,
  materials: PropTypes.array,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AdminMaterials;
