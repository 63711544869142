import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const rentVehicleApi = (rent) =>
  f3tch('/vehicle-rentals/')
    .profile(profile())
    .body(rent)
    .post();
export default rentVehicleApi;
