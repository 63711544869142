import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, ExplicitSwitch, CheckBox} from 'doorson-ui';
import Container from './components/Container';
import Position from './components/Position';
import Content from './components/Content';
import Label from './components/Label';
import Action from './components/Action';
import NotApplicable from './components/NotApplicable';
import ValidityContainer from './components/ValidityContainer';

// Lib
import convertStatus from '../../../maintenanceDoorCheck/lib/convertStatus.lib.maintenanceDoorCheck';

const MaintenanceCheck = ({
  position,
  status,
  label,
  action,
  disabled,
  onChange,
}) => (
  <Container>
    <Position>{position}</Position>
    <Content disabled={disabled}>
      <Label>{label}</Label>
      <Row>
        <Column size={1 / 3}>
          <Action>{action}</Action>
        </Column>
        <Column size={2 / 3}>
          <ExplicitSwitch
            value={status === 'notApplicable' ? null : convertStatus(status)}
            onChange={(s) => disabled ? {} : onChange(convertStatus(s))}
            disabled={disabled}
          />
        </Column>
      </Row>
    </Content>
    {status === 'notApplicable' && <NotApplicable />}
    <ValidityContainer>
      <CheckBox
        selected={status === 'notApplicable'}
        onSelect={(selected) => onChange(selected ? 'notApplicable' : null)}
        disabled={disabled}
      >
        Not Applicable
      </CheckBox>
    </ValidityContainer>
  </Container>
);

MaintenanceCheck.propTypes = {
  position: PropTypes.number,
  status: PropTypes.string,
  label: PropTypes.node,
  action: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default MaintenanceCheck;
