import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import getConfigsApi from '../../api/configs.api.app';

// Actions
import {set as setAct} from '../../redux/actions';

// Components
import FullScreen from '../../../layout/components/FullScreen/FullScreen';
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

class ConfigContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    try {
      const configs = await getConfigsApi();
      this.props.dispatch(setAct({configs}));
      this.setState({loading: false});
    } catch (error) {
      // Do nothing
    }
  };

  render() {
    const {children} = this.props;
    const {loading} = this.state;
    return loading ? (
      <FullScreen>
        <FullScreenLoader />
      </FullScreen>
    ) : (
      children
    );
  }
}

export default connect()(ConfigContainer);
