import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white, darkGrey} = colors;

const Info = styled.div`
  width: 220px;
  padding: 5px 0px;
  border: 1px solid ${darkGrey};
  background: ${white};
  position: absolute;
  top: 50%;
  ${({left}) => (left ? 'right' : 'left')}: calc(100% + 10px);
  transform: translateY(-50%);
`;

export default Info;
