import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white, black, orange} = colors;

const Container = styled.div`
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  padding: 0px 1px;
`;

const Content = styled.div`
  width: 100%;
  line-height: 44px;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  user-select: none;
  color: ${({selected}) => (selected ? orange : black)};
  background: ${white};
`;

const Type = ({children, selected, onClick}) => (
  <Container>
    <Content selected={selected} onClick={onClick}>
      {children}
    </Content>
  </Container>
);

Type.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Type;
