import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Box = styled.div`
  width: 100%;
  padding: 34px;
  background: ${white};
`;

export default Box;
