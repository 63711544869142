import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Column, Container, Row, Tabs} from 'doorson-ui';
import Content from './components/Content';
import TabsFix from "./components/TabsFix";

const WarehouseOrdersFilter = ({filter, filters, onFilter}) => (
  <Container>
    <Row>
      <Column>
        <Content>
          <Tabs tab={filter} tabs={filters} onTab={onFilter}/>
          <TabsFix/>
        </Content>
      </Column>
    </Row>
  </Container>
);

WarehouseOrdersFilter.propTypes = {
  filter: PropTypes.string,
  filters: PropTypes.array,
  onFilter: PropTypes.func,
};

export default WarehouseOrdersFilter;
