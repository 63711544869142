import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Info, Button, Select, Textarea} from 'doorson-ui';

const GeneralTranslationModal = ({
  visible,
  loading,
  language,
  languages,
  displayAttribute,
  translationAttribute,
  translation,
  onChange,
  onClose,
  onSave,
  onLanguage,
}) => (
  <Modal title="Translate" visible={visible} onClose={onClose}>
    {!!translation && (
      <Fragment>
        <Row margin>
          <Column>
            <Info label="Translation">{translation[displayAttribute]}</Info>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <Select value={language} onChange={onLanguage} options={languages}>
              Language
            </Select>
          </Column>
        </Row>
        <Row margin>
          <Column margin>
            <Textarea
              value={translation.translate[translationAttribute]}
              onChange={onChange}
              maxRows={7}
            >
              Content
            </Textarea>
          </Column>
        </Row>
        <Row>
          <Column size={1 / 3} offset={1 / 3}>
            <Button loading={loading} onClick={onSave}>
              Save
            </Button>
          </Column>
        </Row>
      </Fragment>
    )}
  </Modal>
);

GeneralTranslationModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  language: PropTypes.string,
  languages: PropTypes.array,
  idAttribute: PropTypes.string,
  translationAttribute: PropTypes.string,
  translation: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onLanguage: PropTypes.func,
};

export default GeneralTranslationModal;
