import React from 'react';
import PropTypes from 'prop-types';

// Component
import {Button} from 'doorson-ui';
import Container from './components/Container';

const WarehouseOrderButton = ({children, onClick, theme, options}) => (
  <Container>
    <Button theme={theme || 'orange'} outline options={options} onClick={onClick}>{children}</Button>
  </Container>
);

WarehouseOrderButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  options: PropTypes.array,
};

export default WarehouseOrderButton;
