import styled from 'styled-components';

// Components
import Logo from '../../../../layout/components/Logo/Logo';

const DoorsonLogo = styled(Logo)`
  display: block;
  height: 35px;
  margin-bottom: 40px;
`;

export default DoorsonLogo;
