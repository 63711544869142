import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 0px 1px;
  display: flex;
  flex-wrap: wrap;
`;

export default Container;
