import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange} = colors;

const Icon = styled.i`
  position: absolute;
  top: 50%;
  right: 0px;
  color: ${orange};
  pointer-events: none;
  transform: translateY(-50%);
  font-size: 18px;
`;

export default Icon;
