import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {black, greyer, white, orange} = colors;

const Container = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: ${props => props.obsolete ? greyer : black};
  color: ${white};
  border: 2px solid ${({selected}) => (selected ? orange : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 700;
`;

export default Container;
