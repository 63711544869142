import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import uploadApi from '../../api/uploadMaintenanceDoor.api.file';
import downloadApi from '../../api/download.api.file';
import deleteApi from '../../api/deleteMaintenanceDoor.api.file';

// Actions
import {set as setAct} from '../../../maintenanceDoor/redux/actions';

// Components
import Attachments from '../../components/Attachments/Attachments';
import DeleteFileModal from '../../components/DeleteFileModal/DeleteFileModal';
import Uploader from '../../components/Uploader/Uploader';
import Preview from '../../components/Preview/Preview';

// Lib
import toBase64 from '../../lib/toBase64.lib.file';
import download from '../../lib/download.lib.file';
import sortByString from '../../../lib/sortByString';

// Alertify
import {alertify} from 'doorson-ui';

class MaintenanceDoorAttachmentsContainer extends Component {
  static propTypes = {
    maintenanceDoor: PropTypes.object,
  };

  state = {
    uploading: null,
    sort: null,
    updating: [],
    deleteID: null,
    preview: false,
    previewImage: null,
  };

  syncMaintenanceDoor = (data = {}) => {
    const {maintenanceDoor, dispatch} = this.props;
    dispatch(setAct({maintenanceDoor: {...maintenanceDoor, ...data}}));
  };

  columns = () => {
    // TODO: I18n
    return [
      {
        key: 'image',
        sortable: false,
      },
      {
        key: 'name',
        label: 'Name',
        sortable: true,
      },
      {
        key: 'size',
        label: 'Size',
        sortable: true,
      },
      {
        key: 'dateModified',
        label: 'Date Modified',
        sortable: true,
      },
      {
        key: 'actions',
        sortable: false,
      },
    ];
  };

  files = () => {
    const {sort} = this.state;
    const asc = !!sort && sort.direction === 'asc';
    const sorter = {
      name: sortByString({
        key: 'name',
        direction: !!sort ? sort.direction : 'asc',
      }),
      size: (a, b) =>
        asc ? a.sizeBytes - b.sizeBytes : b.sizeBytes - a.sizeBytes,
      dateModified: (a, b) =>
        asc ? a.dateModified - b.dateModified : b.dateModified - a.dateModified,
      default: (a, b) => a - b,
    };
    return !sort
      ? [...this.props.maintenanceDoor.files]
      : [...this.props.maintenanceDoor.files].sort(
          sorter[sort.key] || sorter.default
        );
  };

  onUpload = async ({file, name, reset}) => {
    // TODO: I18n
    const {maintenanceDoor} = this.props;
    const {uploading} = this.state;
    if (uploading !== null) return;

    if (!file) return alertify.warning('Please select a file');
    if (!name.trim().length) return alertify.warning('Insert a file name');

    this.setState({uploading: 0});

    try {
      const {type} = file;
      const newFile = await uploadApi(maintenanceDoor.id, name, type, file);
      this.syncMaintenanceDoor({files: [...maintenanceDoor.files, newFile]});
      this.setState({uploading: null});
      reset();
      alertify.success('File was successfully uploaded');
    } catch (error) {
      alertify.error('File could not be uploaded');
      this.setState({uploading: null});
    }
  };

  showDeleteModal = (fileID) => {
    const {updating} = this.state;
    if (!!updating.length) return;
    this.setState({deleteID: fileID});
  };

  hideDeleteModal = () => {
    const {updating} = this.state;
    if (!!updating.length) return;
    this.setState({deleteID: null});
  };

  onPreview = (fileID) => async () => {
    const {updating} = this.state;

    if (!fileID || [...updating].find(i => i.id === fileID)) return;

    this.setState({updating: [...updating, {id: fileID}]});

    const doneUpdating = () => [...this.state.updating].filter((u) => u.id !== fileID);

    try {
      const file = await downloadApi(fileID);
      const image = await toBase64(file);
      this.setState({
        updating: doneUpdating(),
        preview: true,
        previewImage: image,
      });
    } catch (error) {
      this.setState({updating: doneUpdating()});
      alertify.error('File preview failed');
    }
  };

  onDownload = (fileID) => async () => {
    const {maintenanceDoor} = this.props;
    const {updating} = this.state;

    const fileObject = [...maintenanceDoor.files].find((f) => f.id === fileID);
    if (!fileID || !fileObject || [...updating].find(i => i.id === fileID)) return;

    this.setState({updating: [...updating, {id: fileID}]});

    const doneUpdating = (percent = null) =>
      percent !== null ? [...this.state.updating].map((u) => u.id === fileID ? {
        id: u.id, percent
      } : u) : [...this.state.updating].filter((u) => u.id !== fileID);

    try {
      const file = await downloadApi(fileID);
      const total = Number(file.headers.get('Content-Length'));

      const reader = file.body.getReader();
      const onDone = (percent) => this.setState({updating: doneUpdating(percent)});

      let bytesReceived = 0;
      let result = [];
      reader.read().then(function processDownload({done, value}) {
        if (done) {
          download(new Blob(result, {type: 'application/download'}), fileObject.originalName);
          onDone();
          return;
        }
        bytesReceived += value.length;
        result.push(value);
        onDone(Math.round(bytesReceived / total * 100));

        return reader.read().then(processDownload);
      });

    } catch (error) {
      this.setState({updating: doneUpdating()});
      alertify.error('File could not be downloaded');
    }
  };

  onRemove = (fileID) => () => this.showDeleteModal(fileID);

  onSort = (sort) => this.setState({sort});

  remove = async () => {
    // TODO: I18n
    const {maintenanceDoor} = this.props;
    const {updating, deleteID} = this.state;

    if (!deleteID || [...updating].find(i => i.id === deleteID)) return;

    this.setState({updating: [...updating, {id: deleteID}]});

    const doneUpdating = () =>
      [...this.state.updating].filter((u) => u.id !== deleteID);

    try {
      await deleteApi(maintenanceDoor.id, deleteID);
      this.syncMaintenanceDoor({
        files: [...maintenanceDoor.files].filter(
          (file) => file.id !== deleteID
        ),
      });
      this.setState({updating: doneUpdating(), deleteID: null});
      alertify.success('File deleted');
    } catch (error) {
      this.setState({updating: doneUpdating()});
      alertify.error('File could not be deleted');
    }
  };

  hidePreview = () => this.setState({preview: false});

  render() {
    const {
      uploading,
      sort,
      updating,
      deleteID,
      preview,
      previewImage,
    } = this.state;
    return (
      <Fragment>
        <Uploader
          accept={null}
          uploading={uploading !== null}
          onUpload={this.onUpload}
          label="Select files to upload"
          fileNameLabel="File Name"
          uploadLabel="Upload"
          cancelLabel="Cancel"
        />
        <Attachments
          columns={this.columns()}
          files={this.files()}
          updating={updating}
          sort={sort}
          onPreview={this.onPreview}
          onDownload={this.onDownload}
          onRemove={this.onRemove}
          onSort={this.onSort}
        />
        <DeleteFileModal
          title="Delete file"
          visible={!!deleteID}
          deleteLabel="delete"
          loading={!![...updating].find(i => i.id === deleteID)}
          onClose={this.hideDeleteModal}
          onDelete={this.remove}
        >
          Are you sure you want to delete this file?
        </DeleteFileModal>
        <Preview
          visible={preview}
          image={previewImage}
          onClose={this.hidePreview}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  maintenanceDoor: state.maintenanceDoor.maintenanceDoor,
}))(MaintenanceDoorAttachmentsContainer);
