import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Actions
import {updateMaintenance as updateMaintenanceAct} from '../../redux/actions';

// Api
import resolveMaintenanceApi from '../../api/resolve.api.maintenance';

// Alertify
import {alertify} from 'doorson-ui';

// Components
import MaintenanceResolve from '../../components/MaintenanceResolve/MaintenanceResolve';

// Containers
import CloseMaintenanceContainer from '../CloseMaintenanceContainer/CloseMaintenanceContainer';

// Lib
import isResolved from '../../lib/isResolved.lib.maintenance';
import isClosed from '../../lib/isClosed.lib.maintenance';
import isAssigned from '../../lib/isAssigned.lib.maintenance';
import areDoorsComplete from '../../../maintenanceDoor/lib/areDoorsComplete.lib.maintenanceDoor';

// Routes
import maintenancesRoute from '../../pages/MaintenancesPage/route';
import maintenanceItemsRoute from '../../pages/MaintenanceItemsPage/route';

// Roles
import {CHIEF_SERVICE_TECHNICIAN, SYSTEM} from '../../../user/roles/roles.user';
import hasPermission from '../../../user/roles/hasPermission.role.user';

class MaintenanceResolveContainer extends Component {
  static propTypes = {
    maintenance: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    closing: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    // TODO: I18n
    const {maintenance, history} = this.props;
    if (!maintenance) return history.replace(maintenancesRoute());
    if (!areDoorsComplete(maintenance)) {
      alertify.warning('Complete all doors first');
      return history.replace(maintenanceItemsRoute(maintenance.id));
    }
  };

  resolve = async () => {
    // TODO: I18n
    const {maintenance, dispatch} = this.props;
    const {loading} = this.state;

    if (loading || !maintenance || isResolved(maintenance)) return;

    this.setState({loading: true});

    try {
      const newMaintenance = await resolveMaintenanceApi(maintenance.id);
      alertify.success('Maintenance resolved');
      if (!this.mounted) return;
      this.setState({loading: false});
      dispatch(updateMaintenanceAct(newMaintenance));
    } catch (error) {
      alertify.error('Could not resolve maintenance');
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  showClose = () => this.setState({closing: true});

  hideClose = () => this.setState({closing: false});

  onClose = () => {
    const {history} = this.props;
    alertify.success('Maintenance closed');
    history.push(maintenancesRoute());
  };

  canClose = () =>
    hasPermission({
      user: this.props.user,
      roles: [CHIEF_SERVICE_TECHNICIAN, SYSTEM],
    });

  canResolve = () => isAssigned(this.props.user, this.props.maintenance);

  render() {
    const {maintenance} = this.props;
    const {loading, closing} = this.state;
    return (
      <Fragment>
        <MaintenanceResolve
          resolveLabel="Resolve"
          resolvedLabel="This incident is already resolved"
          closeLabel="Close"
          closedLabel="This incident is already closed"
          notAssignedLabel="You are not assigned to this incident"
          loading={loading}
          resolved={isResolved(maintenance)}
          canResolve={this.canResolve()}
          closed={isClosed(maintenance)}
          canClose={this.canClose()}
          onResolve={this.resolve}
          onClose={this.showClose}
        />
        <CloseMaintenanceContainer
          visible={closing}
          maintenance={maintenance}
          onClose={this.hideClose}
          onDone={this.onClose}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({user: state.auth.user}))(
  withRouter(MaintenanceResolveContainer)
);
