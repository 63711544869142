import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const createMaintenanceDoorApi = (maintenanceDoor, query = {}) =>
  f3tch('/maintenance-doors')
    .profile(profile())
    .query(query)
    .body(maintenanceDoor).post();
export default createMaintenanceDoorApi;
