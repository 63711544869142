import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey, grey} = colors;

const UploadLabel = styled.div`
  width: 100%;
  height: 200px;
  border: 2px dashed ${grey};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${darkGrey};
`;

export default UploadLabel;
