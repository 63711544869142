import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';

const Point = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({color}) => colors[color]};
  opacity: ${({selected}) => selected ? '1' : '0.7'};
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Point;
