import styled from 'styled-components';

const UploadContainer = styled.div`
  width: 100%;
  max-width: 425px;
  margin: 60px auto 0px auto;
  display: block;
`;

export default UploadContainer;
