import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const Heading = styled.div`
  width: 100%;
  margin-bottom: 30px;
  color: ${white};
  font-size: 30px;
`;

export default Heading;
