import styled from 'styled-components';

const Label = styled.div`
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  cursor: pointer;
`;

export default Label;
