import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
// Consts
import page from './page';
import WarehouseContainer from "../../containers/WarehouseContainer/WarehouseContainer";

const WarehousePage = ({match}) => (
  <Page page={page}>
    <WarehouseContainer warehouseId={match.params.id}/>
  </Page>
);

export default WarehousePage;
