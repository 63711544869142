import React from 'react';

// Components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';
import Container from './components/Container';

const DocumentationLoader = () => (
  <Container>
    <FullScreenLoader />
  </Container>
);

export default DocumentationLoader;
