import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {white} = colors;

const ActionLabel = styled.div`
  margin-right: 25px;
  cursor: pointer;
  color: ${white};
`;

export default ActionLabel;
