import styled from 'styled-components';

const Position = styled.div`
  width: 30px;
  max-width: 30px;
  min-width: 30px;
  font-weight: 700;
`;

export default Position;
