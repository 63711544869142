import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {orange, white} = colors;

const NewBranch = styled.div`
  width: 90px;
  margin-left: 15px;
  height: 24px;
  display: flex;
  text-transform: uppercase;
  background: ${orange};
  color: ${white};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  font-weight: 500;
`;

export default NewBranch;
