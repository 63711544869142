import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, CheckBox, Column, EditableOption, Info, Input, Row, Select,} from 'doorson-ui';
import MaintenanceCheck from '../../../maintenanceCheck/components/MaintenanceCheck/MaintenanceCheck';

import Container from './components/Container';
import Box from './components/Box';
import Right from './components/Right';
import ButtonContainer from './components/ButtonContainer';
import Option from './components/Option';
import SmallOption from './components/SmallOption';
import CheckContainer from './components/CheckContainer';
import Check from './components/Check';
import ChecksContainer from './components/ChecksContainer';
import ChecksHeader from './components/ChecksHeader';

// Libs
import validateNumberInput from '../../../layout/lib/validateNumberInput.lib.layout';
import DataBlock from "../../../layout/components/DataBlock/DataBlock";

const Workinglog = ({
                      repairDescriptionLabel,
                      replacedMaterialLabel,
                      findingsRemarksLabel,
                      customerRemarksLabel,
                      replacementNeededLabel,
                      doorsWorkingLabel,
                      prepareOfferLabel,
                      buttonLabel,
                      noMaterialsLabel,
                      noRepairsLabel,
                      maintenanceChecksLabel,
                      scan,
                      toggleScan,
                      scanLabel,

                      loading,
                      assigned,
                      resolved,
                      door,

                      repairs,
                      selectedRepairs,
                      materials,
                      selectedMaterials,

                      updatingRepairs,
                      updatingMaterials,

                      onChange,
                      onCheck,
                      addRepair,
                      changeRepair,
                      updateRepair,
                      removeRepair,
                      addMaterial,
                      changeMaterial,
                      updateMaterial,
                      removeMaterial,
                      onSave,
                    }) => (
  <Container>
    <ChecksContainer>
      <ChecksHeader>{maintenanceChecksLabel}</ChecksHeader>
      {door.checks.map((check, index) => (
        <MaintenanceCheck
          key={check.id}
          position={index + 1}
          status={check.status}
          label={check.check.checkItem}
          action={check.check.action}
          disabled={!assigned || resolved}
          onChange={onCheck(check.id)}
        />
      ))}
    </ChecksContainer>
    <Box>
      <Row>
        <Column size={2 / 3} m={1}>
          <Row margin>
            <Column>
              {!resolved && !!assigned && !selectedRepairs.length && (
                <Fragment>
                  <Row margin/>
                  <Row margin>
                    <Column>
                      <CheckBox
                        selected={door.noRepairs}
                        onSelect={onChange('noRepairs')}
                        disabled={loading || !!selectedRepairs.length}
                      >
                        {noRepairsLabel}
                      </CheckBox>
                    </Column>
                  </Row>
                </Fragment>
              )}
              <Row margin={!door.noRepairs || resolved || !assigned}>
                <Column>
                  {resolved || !assigned ? (
                    <Info label={repairDescriptionLabel} noBorder>
                      {selectedRepairs.map(({value, label}, index) => (
                        <Option key={value} noMargin={!index}>
                          <EditableOption value={label} derecognized readOnly/>
                        </Option>
                      ))}
                    </Info>
                  ) : (
                    !door.noRepairs && (
                      <Fragment>
                        <Select
                          value=""
                          options={repairs}
                          onChange={addRepair}
                          error={door.error.repair}
                          disabled={loading}
                          required
                        >
                          {repairDescriptionLabel}
                        </Select>
                        {selectedRepairs.map(({value, disabled, label}, index) => (
                          <Option key={value} noMargin={!index}>
                            <EditableOption
                              type="text"
                              value={label}
                              onChange={disabled ? () => {
                              } : changeRepair(value, 'description')}
                              onRemove={removeRepair(value)}
                              onBlur={disabled ? () => {
                              } : updateRepair(value)}
                              loading={updatingRepairs.includes(value)}
                              derecognized
                            />
                          </Option>
                        ))}
                      </Fragment>
                    )
                  )}
                </Column>
              </Row>
            </Column>
          </Row>
          {!resolved && !!assigned && !selectedMaterials.length && (
            <Fragment>
              <Row margin/>
              <Row margin>
                <Column>
                  <CheckBox
                    selected={door.noMaterials}
                    onSelect={onChange('noMaterials')}
                    disabled={loading || !!selectedMaterials.length}
                  >
                    {noMaterialsLabel}
                  </CheckBox>
                </Column>
              </Row>
            </Fragment>
          )}
          <Row margin={!door.noMaterials || resolved || !assigned}>
            <Column>
              {resolved || !assigned ? (
                <Info label={replacedMaterialLabel} noBorder>
                  {selectedMaterials.map(
                    ({value, label, guarantee, unit, booked, quantity}, index) => (
                      <Option key={value} noMargin={!index}>
                        <EditableOption
                          value={label}
                          guarantee={guarantee}
                          derecognized={booked}
                          readOnly
                        />
                        <SmallOption>
                          <EditableOption
                            value={`${quantity}`}
                            label={unit}
                            readOnly
                          />
                        </SmallOption>
                      </Option>
                    )
                  )}
                </Info>
              ) : (
                !door.noMaterials && (
                  <Fragment>
                    <Select
                      value=""
                      options={materials}
                      onChange={addMaterial}
                      error={door.error.material}
                      disabled={loading}
                      required
                    >
                      {replacedMaterialLabel}
                    </Select>
                    {selectedMaterials.map(
                      ({value, label, guarantee, unit, quantity}, index) => (
                        <Option key={value} noMargin={!index}>
                          <EditableOption
                            type="text"
                            value={label}
                            guarantee={guarantee}
                            onChange={changeMaterial(value, 'name')}
                            onRemove={removeMaterial(value)}
                            onBlur={updateMaterial(value)}
                            loading={updatingMaterials.includes(value)}
                          />
                          <SmallOption>
                            <EditableOption
                              type="number"
                              step=".10"
                              value={`${quantity}`}
                              onKeyDown={validateNumberInput}
                              onChange={changeMaterial(value, 'quantity')}
                              onBlur={updateMaterial(value)}
                              label={unit}
                              disabled={updatingMaterials.includes(value)}
                            />
                          </SmallOption>
                        </Option>
                      )
                    )}
                  </Fragment>
                )
              )}
            </Column>
          </Row>
          <Row margin>
            <Column>
              {resolved || !assigned ? (
                <Info label={findingsRemarksLabel}>
                  {door.findingsAndRemarks || ''}
                </Info>
              ) : (
                <Input
                  value={door.findingsAndRemarks}
                  onChange={onChange('findingsAndRemarks')}
                  error={door.error.findingsAndRemarks}
                  disabled={loading}
                >
                  {findingsRemarksLabel}
                </Input>
              )}
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              {resolved || !assigned ? (
                <Info label={customerRemarksLabel}>
                  {door.customerRemarks || ''}
                </Info>
              ) : (
                <Input
                  value={door.customerRemarks}
                  onChange={onChange('customerRemarks')}
                  error={door.error.customerRemarks}
                  disabled={loading}
                >
                  {customerRemarksLabel}
                </Input>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
      <Row margin={!resolved}>
        <Column>
          <CheckContainer>
            <Check>
              <CheckBox
                selected={door.replacementNeeded}
                onSelect={onChange('replacementNeeded')}
                disabled={loading || resolved || !assigned}
              >
                {replacementNeededLabel}
              </CheckBox>
            </Check>
            <Check>
              <CheckBox
                selected={door.workingProperly}
                onSelect={onChange('workingProperly')}
                disabled={loading || resolved || !assigned}
              >
                {doorsWorkingLabel}
              </CheckBox>
            </Check>
            <Check>
              <CheckBox
                selected={door.prepareOffer}
                onSelect={onChange('prepareOffer')}
                disabled={loading || resolved || !assigned}
              >
                {prepareOfferLabel}
              </CheckBox>
            </Check>
          </CheckContainer>
        </Column>
      </Row>
      <Row>
        <DataBlock label="Door tools">
          <ButtonContainer><Button
            size="normal"
            theme="blue"
            preIcon="qrcode"
            disabled={scan || resolved || !assigned}
            outline={true}
            onClick={() => toggleScan(!scan)}>
            {scanLabel}
          </Button></ButtonContainer>
        </DataBlock>
      </Row>
      {!resolved && assigned && (
        <Row>
          <Column>
            <Right>
              <ButtonContainer>
                <Button
                  size="big"
                  theme="orange"
                  loading={
                    loading ||
                    !!updatingMaterials.length ||
                    !!updatingRepairs.length
                  }
                  onClick={onSave}
                >
                  {buttonLabel}
                </Button>
              </ButtonContainer>
            </Right>
          </Column>
        </Row>
      )}
    </Box>
  </Container>
);

Workinglog.propTypes = {
  doorSerialLabel: PropTypes.string,
  electronicsSerialLabel: PropTypes.string,
  motorSerialLabel: PropTypes.string,
  technicianDescriptionLabel: PropTypes.string,
  repairDescriptionLabel: PropTypes.string,
  replacedMaterialLabel: PropTypes.string,
  findingsRemarksLabel: PropTypes.string,
  customerRemarksLabel: PropTypes.string,
  replacementNeededLabel: PropTypes.string,
  doorsWorkingLabel: PropTypes.string,
  prepareOfferLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  manufacturerLabel: PropTypes.string,
  doorVersionLabel: PropTypes.string,
  doorTypeLabel: PropTypes.string,
  noMaterialsLabel: PropTypes.string,
  noRepairsLabel: PropTypes.string,
  maintenanceChecksLabel: PropTypes.string,
  scan: PropTypes.bool,
  toggleScan: PropTypes.func,
  scanLabel: PropTypes.string,

  loading: PropTypes.bool,
  assigned: PropTypes.bool,
  resolved: PropTypes.bool,
  door: PropTypes.object,

  repairs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  selectedRepairs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  selectedMaterials: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      quantity: PropTypes.any,
      guarantee: PropTypes.bool,
    })
  ),

  updatingRepairs: PropTypes.array,
  updatingMaterials: PropTypes.array,

  onChange: PropTypes.func,
  onCheck: PropTypes.func,
  addRepair: PropTypes.func,
  changeRepair: PropTypes.func,
  updateRepair: PropTypes.func,
  removeRepair: PropTypes.func,
  addMaterial: PropTypes.func,
  changeMaterial: PropTypes.func,
  updateMaterial: PropTypes.func,
  removeMaterial: PropTypes.func,
  onDoor: PropTypes.func,
  onSave: PropTypes.func,
};

export default Workinglog;
