import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Containers
import SubPage from '../../../layout/containers/SubPage/SubPage';
import MaintenanceCustomerContainer from '../../containers/MaintenanceCustomerContainer/MaintenanceCustomerContainer';

// TODO: I18n
const MaintenanceCustomerPage = ({maintenance, match}) => (
  <SubPage page={match.path}>
    <MaintenanceCustomerContainer maintenance={maintenance} />
  </SubPage>
);

MaintenanceCustomerPage.propTypes = {
  maintenance: PropTypes.object,
};

export default connect((state) => ({
  maintenance: state.maintenance.maintenance,
}))(withRouter(MaintenanceCustomerPage));
