import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import SubPage from '../../../layout/containers/SubPage/SubPage';

// TranslationsAdminPage
import TranslationsAdminPage from '../TranslationsAdminPage/TranslationsAdminPage';
import routeTranslationsAdminPage from '../TranslationsAdminPage/route';

// Consts
import page from './page';

const TranslationsAdminPages = () => (
  <SubPage page={page}>
    <Switch>
      <Route
        path={routeTranslationsAdminPage()}
        component={TranslationsAdminPage}
        exact
      />
    </Switch>
  </SubPage>
);

export default TranslationsAdminPages;
