import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column} from 'doorson-ui';
import {Modal} from 'doorson-ui';
import {Form} from 'doorson-ui';
import {Button} from 'doorson-ui';
import {AsyncInput} from 'doorson-ui';
import {Input} from 'doorson-ui';
import {Select} from 'doorson-ui';

import TypeContainer from './components/TypeContainer';
import Type from './components/Type';

// Libs
import validateNumberInput from '../../../layout/lib/validateNumberInput.lib.layout';

const NewCustomerModal = ({
  title,
  companyLabel,
  registrationNumberLabel,
  vatNumberLabel,
  firstNameLabel,
  lastNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  phoneLabel,
  emailLabel,
  noSuggestionLabel,
  saveLabel,

  types,
  countries,
  customers,

  visible,
  loading,
  error,
  type,
  company,
  registrationNumber,
  vatNumber,
  firstName,
  lastName,
  address,
  zip,
  city,
  country,
  phone,
  email,
  searchKey,
  searchingForCustomer,
  customerSelected,

  onChange,
  onSearchedCustomer,
  onClose,
  onSave,
}) => (
  <Modal visible={visible} title={title} onClose={onClose} size="large">
    <TypeContainer>
      {types.map(({value, label}) => (
        <Type
          key={value}
          selected={value === type}
          onClick={() => onChange('type')(value)}
        >
          {label}
        </Type>
      ))}
    </TypeContainer>
    <Form onSubmit={onSave}>
      {type === 'business' ? (
        <Row margin>
          <Column size={1 / 3}>
            <AsyncInput
              value={company}
              onChange={onChange('company')}
              error={error.company}
              disabled={loading}
              suggestions={customers}
              onSuggestion={onSearchedCustomer}
              noSuggestionLabel={noSuggestionLabel}
              displaySuggestions={() =>
                company.trim().length >= 3 && searchKey === 'company'
              }
              searching={searchingForCustomer}
              required
            >
              {companyLabel}
            </AsyncInput>
          </Column>
          <Column size={1 / 3}>
            <AsyncInput
              value={registrationNumber}
              onChange={onChange('registrationNumber')}
              error={error.registrationNumber}
              suggestions={customers}
              onSuggestion={onSearchedCustomer}
              noSuggestionLabel={noSuggestionLabel}
              displaySuggestions={() =>
                registrationNumber.trim().length >= 10 &&
                searchKey === 'registrationNumber'
              }
              disabled={loading}
              searching={searchingForCustomer}
              required
            >
              {registrationNumberLabel}
            </AsyncInput>
          </Column>
          <Column size={1 / 3}>
            <Input
              value={vatNumber}
              onChange={onChange('vatNumber')}
              error={error.vatNumber}
              disabled={loading}
              required
            >
              {vatNumberLabel}
            </Input>
          </Column>
        </Row>
      ) : (
        <Row margin>
          <Column size={1 / 3}>
            <Input
              value={firstName}
              onChange={onChange('firstName')}
              error={error.firstName}
              disabled={loading}
              required
            >
              {firstNameLabel}
            </Input>
          </Column>
          <Column size={1 / 3}>
            <Input
              value={lastName}
              onChange={onChange('lastName')}
              error={error.lastName}
              disabled={loading}
              required
            >
              {lastNameLabel}
            </Input>
          </Column>
          <Column size={1 / 3}>
            <Input
              value={vatNumber}
              onChange={onChange('vatNumber')}
              error={error.vatNumber}
              disabled={loading}
            >
              {vatNumberLabel}
            </Input>
          </Column>
        </Row>
      )}
      <Row margin>
        <Column size={2 / 3}>
          <Input
            value={address}
            onChange={onChange('address')}
            error={error.address}
            disabled={loading || customerSelected}
            required
          >
            {addressLabel}
          </Input>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 3}>
          <Input
            value={zip}
            onChange={onChange('zip')}
            error={error.zip}
            disabled={loading || customerSelected}
            required
          >
            {zipLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Input
            value={city}
            onChange={onChange('city')}
            error={error.city}
            disabled={loading || customerSelected}
            required
          >
            {cityLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Select
            value={country}
            options={countries}
            onChange={onChange('country')}
            error={error.country}
            disabled={loading || customerSelected}
            required
          >
            {countryLabel}
          </Select>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 3}>
          <Input
            type="tel"
            value={phone}
            onChange={onChange('phone')}
            error={error.phone}
            disabled={loading}
            onKeyDown={validateNumberInput}
            required
          >
            {phoneLabel}
          </Input>
        </Column>
        <Column size={1 / 3}>
          <Input
            type="email"
            value={email}
            onChange={onChange('email')}
            error={error.email}
            disabled={loading}
          >
            {emailLabel}
          </Input>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button loading={loading}>{saveLabel}</Button>
        </Column>
      </Row>
    </Form>
  </Modal>
);

NewCustomerModal.propTypes = {
  title: PropTypes.string,
  companyLabel: PropTypes.node,
  registrationNumberLabel: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,
  noSuggestionLabel: PropTypes.node,
  saveLabel: PropTypes.string,

  types: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),

  visible: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object,
  type: PropTypes.string,
  company: PropTypes.string,
  registrationNumber: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  searchKey: PropTypes.string,
  searchingForCustomer: PropTypes.bool,
  customerSelected: PropTypes.bool,

  onSearchedCustomer: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default NewCustomerModal;
