import styled from 'styled-components';

// Colors
import {colors} from 'doorson-ui';
const {darkGrey} = colors;

const NoResults = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: ${darkGrey};
  user-select: none;
  padding: 100px 0px;
`;

export default NoResults;
