import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Container, Row, Column, Input, Info, Select, Button} from 'doorson-ui';
import BranchSelection from '../../../customerBranch/components/BranchSelection/BranchSelection';
import CustomerAdminLoader from '../CustomerAdminLoader/CustomerAdminLoader';
import Content from './components/Content';
import SaveContainer from './components/SaveContainer';

// Libs
import validateNumberInput from '../../../layout/lib/validateNumberInput.lib.layout';

const CustomerAdmin = ({
  loading,
  saving,
  error,
  customer,
  countries,

  customerTypeLabel,
  companyLabel,
  registrationNumberLabel,
  vatNumberLabel,
  firstNameLabel,
  lastNameLabel,
  addressLabel,
  zipLabel,
  cityLabel,
  countryLabel,
  phoneLabel,
  emailLabel,
  newBranchLabel,

  branchesLabel,
  noBranchesLabel,
  searchLabel,
  noBranchesFoundLabel,

  onChange,
  onSave,
  onNewBranch,
  getCountryByCode,
  onBranch,
}) =>
  loading || !customer ? (
    <CustomerAdminLoader />
  ) : (
    <Content>
      <Container>
        <Row margin>
          <Column size={1 / 2}>
            <Info label={customerTypeLabel}>{customer.type}</Info>
          </Column>
        </Row>
        {customer.type === 'business' ? (
          <Row margin>
            <Column size={1 / 2}>
              <Input
                value={customer.company}
                onChange={onChange('company')}
                disabled={saving}
                error={error.company}
                required
              >
                {companyLabel}
              </Input>
            </Column>
            <Column size={1 / 4}>
              <Input
                value={customer.registrationNumber}
                onChange={onChange('registrationNumber')}
                disabled={saving}
                error={error.registrationNumber}
                required
              >
                {registrationNumberLabel}
              </Input>
            </Column>
            <Column size={1 / 4}>
              <Input
                value={customer.vatNumber}
                onChange={onChange('vatNumber')}
                disabled={saving}
                error={error.vatNumber}
                required
              >
                {vatNumberLabel}
              </Input>
            </Column>
          </Row>
        ) : (
          <Row margin>
            <Column size={1 / 3}>
              <Input
                value={customer.firstName}
                onChange={onChange('firstName')}
                disabled={saving}
                error={error.firstName}
                required
              >
                {firstNameLabel}
              </Input>
            </Column>
            <Column size={1 / 3}>
              <Input
                value={customer.lastName}
                onChange={onChange('lastName')}
                disabled={saving}
                error={error.lastName}
                required
              >
                {lastNameLabel}
              </Input>
            </Column>
            <Column size={1 / 3}>
              <Input
                value={customer.vatNumber}
                onChange={onChange('vatNumber')}
                disabled={saving}
                error={error.vatNumber}
              >
                {vatNumberLabel}
              </Input>
            </Column>
          </Row>
        )}
        <Row margin>
          <Column>
            <Input
              value={customer.location.address}
              onChange={onChange('address', true)}
              disabled={saving}
              error={error.address}
              required
            >
              {addressLabel}
            </Input>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <Input
              value={customer.location.zip}
              onChange={onChange('zip', true)}
              disabled={saving}
              error={error.zip}
              required
            >
              {zipLabel}
            </Input>
          </Column>
          <Column size={1 / 2}>
            <Input
              value={customer.location.city}
              onChange={onChange('city', true)}
              disabled={saving}
              error={error.city}
              required
            >
              {cityLabel}
            </Input>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <Select
              value={customer.location.countryCode}
              options={countries}
              onChange={onChange('countryCode', true)}
              disabled={saving}
              error={error.countryCode}
              required
            >
              {countryLabel}
            </Select>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <Input
              value={customer.phone}
              onChange={onChange('phone')}
              disabled={saving}
              error={error.phone}
              onKeyDown={validateNumberInput}
              required
            >
              {phoneLabel}
            </Input>
          </Column>
          <Column size={1 / 2}>
            <Input
              value={customer.email}
              onChange={onChange('email')}
              error={error.email}
              disabled={saving}
            >
              {emailLabel}
            </Input>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <SaveContainer>
              <Button loading={saving} theme="orange" onClick={onSave}>
                Save Customer
              </Button>
            </SaveContainer>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <BranchSelection
              branches={customer.branches}
              branchesLabel={branchesLabel}
              noBranchesLabel={noBranchesLabel}
              searchLabel={searchLabel}
              noBranchesFoundLabel={noBranchesFoundLabel}
              newBranchLabel={newBranchLabel}
              onNewBranch={onNewBranch}
              getCountryByCode={getCountryByCode}
              onSelect={onBranch}
            />
          </Column>
        </Row>
      </Container>
    </Content>
  );

CustomerAdmin.propTypes = {
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  customer: PropTypes.object,
  countries: PropTypes.array,
  error: PropTypes.object,

  customerTypeLabel: PropTypes.node,
  companyLabel: PropTypes.node,
  registrationNumberLabel: PropTypes.node,
  vatNumberLabel: PropTypes.node,
  firstNameLabel: PropTypes.node,
  lastNameLabel: PropTypes.node,
  addressLabel: PropTypes.node,
  zipLabel: PropTypes.node,
  cityLabel: PropTypes.node,
  countryLabel: PropTypes.node,
  branchLocationLabel: PropTypes.node,
  phoneLabel: PropTypes.node,
  emailLabel: PropTypes.node,

  branchesLabel: PropTypes.node,
  noBranchesLabel: PropTypes.node,
  searchLabel: PropTypes.node,
  noBranchesFoundLabel: PropTypes.node,

  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onNewBranch: PropTypes.func,
  getCountryByCode: PropTypes.func,
  onBranch: PropTypes.func,
};

export default CustomerAdmin;
