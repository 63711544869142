import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {
  hide as hideDevicePageAct,
  setDeviceId as setDeviceIdAct,
  setDeviceIdAndHide as setDeviceIdAndHideAct,
} from '../../redux/actions';

// Api
import getDeviceByID from '../../api/getByID.api.device';
import setLastUserApi from '../../api/setLastUser.api.device';

// Alert
import {alertify} from 'doorson-ui';

// Constants
import STORAGE_KEY from '../../constants/storageKey.constant.device';
import NOT_APPLICABLE from '../../constants/notApplicable.constant.device';

// Components
import Device from '../../components/Device/Device';

// Lib
import * as localStorage from '../../../lib/localStorage';

class DeviceContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    displayDevicePage: PropTypes.bool,
    deviceId: PropTypes.string,
    dispatch: PropTypes.func,
    user: PropTypes.object,
  };

  state = {
    inited: false,
    loading: false,
    device: '',
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.displayDevicePage && this.props.displayDevicePage)
      this.init();
  }

  init = () => {
    const {dispatch} = this.props;
    const storedDeviceId = localStorage.get(STORAGE_KEY);
    const device =
      !!storedDeviceId && storedDeviceId !== NOT_APPLICABLE
        ? storedDeviceId
        : '';
    this.setState({inited: true, loading: false, device});
    dispatch(setDeviceIdAct(storedDeviceId || ''));
  };

  validate = async () => {
    const {dispatch, user} = this.props;
    const {inited, loading, device} = this.state;
    if (!inited || loading) return;

    if (!device.trim().length) return alertify.warning('Insert a device id');

    try {
      await getDeviceByID(device);
      await setLastUserApi(device, user.id);
      localStorage.add(STORAGE_KEY, device);
      dispatch(setDeviceIdAndHideAct(device));
    } catch (error) {
      alertify.error('Wrong device id');
    }
  };

  cancel = () => {
    const {deviceId, dispatch} = this.props;
    const {loading} = this.state;
    if (loading || !deviceId) return;
    dispatch(hideDevicePageAct());
  };

  change = (device) => {
    if (this.state.loading) return;
    this.setState({device});
  };

  notApplicable = () => {
    const {dispatch} = this.props;
    localStorage.add(STORAGE_KEY, NOT_APPLICABLE);
    dispatch(setDeviceIdAndHideAct(NOT_APPLICABLE));
  };

  render() {
    const {displayDevicePage, deviceId, children} = this.props;
    const {inited, loading, device} = this.state;
    return !inited ? null : displayDevicePage || !deviceId ? (
      <Device
        loading={loading}
        cancelable={!!deviceId}
        device={device}
        onChange={this.change}
        onSave={this.validate}
        onCancel={this.cancel}
        onNotApplicable={this.notApplicable}
      />
    ) : (
      children
    );
  }
}

export default connect((state) => ({
  displayDevicePage: state.device.displayDevicePage,
  deviceId: state.device.deviceId,
  user: state.auth.user,
}))(DeviceContainer);
