import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button} from 'doorson-ui';
import Container from './components/Container';
import Content from './components/Content';

const DeleteMaintenanceContract = ({children, onClick}) => (
  <Container>
    <Content>
      <Button onClick={onClick} theme="grey">
        {children}
      </Button>
    </Content>
  </Container>
);

DeleteMaintenanceContract.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default DeleteMaintenanceContract;
