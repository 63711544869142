import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table} from 'doorson-ui';
import PreLoader from '../../../layout/components/PreLoader/PreLoader';

import Container from './components/Container';
import GpsNavigation from "../../../layout/components/GpsNavigation/GpsNavigation";
import getCustomerName from "../../../customer/lib/getName.lib.customer";
import {format, toDate} from "date-fns";


// TODO: I18N
const ServiceItemHistoryList = ({
                                  loading,
                                  more,
                                  scroller,
                                  sort,
                                  columns,
                                  items,
                                  onItem,
                                  onSort,
                                  onMore,
                                }) => (
  <Container>
    <PreLoader
      scroller={scroller}
      loading={loading}
      showLoader={!!items.length}
      more={more}
      onMore={onMore}
    >
      <Table columns={columns} loading={loading} sort={sort} onSort={onSort}>
        {(TableRow) =>
          items.map((item) => (
            <TableRow
              key={item.id}>
              {(TableCol) => [
                <TableCol
                  key="id"
                  onClick={() => onItem(item)}>
                  {item.id}
                </TableCol>,
                <TableCol
                  key="dateResolved"
                  onClick={() => onItem(item)}
                >{item.dateResolved && format(toDate(item.dateResolved), 'dd.MM.yyyy')}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="customer"
                  onClick={() => onItem(item)}
                >
                  {getCustomerName(item.customer)}
                </TableCol>,
                <TableCol
                  span={1.5}
                  key="customerBranch"
                  onClick={() => onItem(item)}
                >
                  {!!item.customerBranch && !!item.customerBranch.branchLocation && (
                    <span>
                      {!!item.customerBranch.branchName && (
                        <span>
                          {item.customerBranch.branchName}
                          <br/>
                        </span>
                      )}
                      {item.customerBranch.branchLocation.address}
                      <br/>
                      {item.customerBranch.branchLocation.city}
                      <GpsNavigation location={item.customerBranch.branchLocation}/>
                    </span>
                  )}
                </TableCol>,
              ]}
            </TableRow>
          ))
        }
      </Table>
    </PreLoader>
  </Container>
);

ServiceItemHistoryList.propTypes = {
  loading: PropTypes.bool,
  more: PropTypes.bool,
  scroller: PropTypes.object,
  sort: PropTypes.object,
  columns: PropTypes.array,
  items: PropTypes.array,
  onItem: PropTypes.func,
  onSort: PropTypes.func,
  onMore: PropTypes.func,
};

export default ServiceItemHistoryList;
