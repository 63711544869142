// Action constants
import {SET} from './constants';

export const set = (data = {}) => ({type: SET, data});

export const readActivities = () => (dispatch, getState) => {
  const {activities} = getState().activity;
  const read = [...activities].map((activity) => ({...activity, read: true}));
  dispatch(set({activities: read}));
};
