import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/jsonNoResponse.profile.api';

const deleteInterventionDoorMaterialApi = (interventionDoorID, materialID) =>
  f3tch(`/intervention-doors/${interventionDoorID}/materials/${materialID}`)
    .profile(profile())
    .delete();
export default deleteInterventionDoorMaterialApi;
