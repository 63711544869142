import React from 'react';
import PropTypes from 'prop-types';

// Assets
import doorservice from './assets/doorservice.svg';
import doorson from './assets/doorson.svg';
import d from './assets/d.svg';

const getLogo = (logo) => {
  switch (logo) {
    case 'doorservice':
      return doorservice;
    case 'doorson':
      return doorson;
    case 'd':
      return d;
    default:
      return doorson;
  }
};

const Logo = ({logo, ...props}) => (
  <img src={getLogo(logo)} alt="logo" {...props} />
);

Logo.propTypes = {
  logo: PropTypes.oneOf(['doorson', 'doorservice', 'd']),
};

export default Logo;
