import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Icon
import doorIconBlack from '../../../assets/doorIconBlack.png';
import doorIconOrange from '../../../assets/doorIconOrange.png';

// Colors
import {colors} from 'doorson-ui';
const {white, black, orange, red, grey} = colors;

// Components
const Container = styled.div`
  width: 330px;
  min-width: 330px;
  max-width: 330px;
  height: 44px;
  border-style: none solid none none;
  border-width: 2px;
  border-color: ${grey};
  background: ${white};
  color: ${({selected}) => (selected ? orange : black)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
`;

const Image = styled.img`
  width: 23px;
  display: block;
`;

const Label = styled.div`
  margin-left: 10px;
`;

const Error = styled.i`
  display: block;
  font-size: 20px;
  color: ${red};
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(calc(-50% + 2px));
`;

const Tab = ({selected, label, error, onClick}) => (
  <Container selected={selected} onClick={onClick}>
    {error && <Error className="mdi mdi-alert" />}
    <Image src={selected ? doorIconOrange : doorIconBlack} />
    {!!label && <Label>{label}</Label>}
  </Container>
);

Tab.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Tab;
